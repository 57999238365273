import React from 'react'

const EnergyConsumption = () => {
  return (
    <g className="illus-energyconsumption">
      <mask id="energyconsumption-path-1-inside-1" fill="white">
        <rect x="3" y="113.578" width="60" height="49.9206" rx="1.46484" />
      </mask>
      <rect
        x="3"
        y="113.578"
        width="60"
        height="49.9206"
        rx="1.46484"
        fill="white"
        stroke="#E4EBF2"
        strokeWidth="10.2539"
        mask="url(#energyconsumption-path-1-inside-1)"
      />
      <rect
        x="22.0243"
        y="128.262"
        width="21.9512"
        height="19.8214"
        fill="#E4EBF2"
      />
      <mask id="energyconsumption-path-3-inside-2" fill="white">
        <rect x="31.5366" y="71" width="31.4634" height="28.631" rx="1.46484" />
      </mask>
      <rect
        x="31.5366"
        y="71"
        width="31.4634"
        height="28.631"
        rx="1.46484"
        fill="white"
        stroke="#E4EBF2"
        strokeWidth="10.2539"
        mask="url(#energyconsumption-path-3-inside-2)"
      />
      <rect
        x="41.7805"
        y="80.543"
        width="11.7073"
        height="10.2778"
        fill="#E4EBF2"
      />
      <mask id="energyconsumption-path-5-inside-3" fill="white">
        <rect x="340" y="104" width="50.1973" height="77.6967" rx="0.872997" />
      </mask>
      <rect
        x="340"
        y="104"
        width="50.1973"
        height="77.6967"
        rx="0.872997"
        fill="white"
        stroke="#E4EBF2"
        strokeWidth="6.11098"
        mask="url(#energyconsumption-path-5-inside-3)"
      />
      <rect
        x="351.174"
        y="117.41"
        width="27.4994"
        height="50.1973"
        fill="#E4EBF2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.0645557 246.014C-0.697834 236.818 5.58452 234.327 3.56566 224.127C1.54789 213.928 -2.94138 211.127 6.64685 203.318C6.64685 203.318 10.9332 200.159 10.9954 195.925C11.0696 190.857 19.6729 184.692 25.6891 187.872C31.7054 191.053 30.4925 196.314 25.7219 204.92C20.9512 213.526 25.8713 214.838 26.9347 217.534C27.997 220.231 30.3464 223.019 25.4067 229.71C20.4659 236.401 26.8802 244.159 22.6985 250.55C22.6985 250.55 19 255.298 11.2408 256.032C10.8471 256.069 0.828036 255.21 0.0645557 246.014Z"
        fill="#E4EBF2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2741 269.028C13.4857 268.967 13.6165 268.752 13.5675 268.535C7.26329 240.597 16.9017 201.894 16.9999 201.507C17.0566 201.283 16.9191 201.056 16.6934 200.999C16.4676 200.942 16.2386 201.078 16.1818 201.303C16.0837 201.693 6.39619 240.588 12.7451 268.718C12.7953 268.943 13.021 269.085 13.249 269.035C13.2566 269.033 13.2653 269.031 13.2741 269.028Z"
        fill="#BEC8CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.3147 217.645C13.3376 217.639 13.3605 217.63 13.3823 217.619C13.5917 217.517 13.6789 217.267 13.5764 217.059C13.5524 217.011 11.1584 212.116 10.1026 207.904C10.0459 207.679 9.81682 207.543 9.59105 207.598C9.3631 207.653 9.22785 207.881 9.28348 208.105C10.3622 212.405 12.7944 217.377 12.8195 217.426C12.91 217.613 13.1216 217.701 13.3147 217.645Z"
        fill="#BEC8CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9846 229.138C12.0424 229.121 12.098 229.092 12.146 229.05C14.2063 227.241 19.4242 223.791 19.4765 223.756C19.6707 223.628 19.723 223.369 19.5943 223.176C19.4656 222.983 19.205 222.929 19.0097 223.06C18.7938 223.202 13.6905 226.576 11.5876 228.423C11.4131 228.575 11.3967 228.841 11.5516 229.013C11.6629 229.139 11.833 229.182 11.9846 229.138Z"
        fill="#BEC8CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.4105 245.021C18.4105 245.021 18.0135 241.067 21.7927 243.08C21.7927 243.08 17.8837 225.79 28.2987 227.081C30.0951 227.304 31.117 227.701 32.151 228.828C33.5504 230.352 34.3204 232.81 32.6691 236.347C29.3643 243.417 26.4631 246.163 27.8831 249.908C29.3021 253.654 28.8233 254.726 23.1365 259.082C22.7449 259.382 19.2296 253.06 18.4105 245.021Z"
        fill="#CED5DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.1532 269.267C24.3604 269.225 24.4935 269.024 24.4509 268.818C22.0503 257.198 25.5035 237.623 25.9441 236.766C26.0673 236.594 26.0215 236.366 25.8492 236.244C25.678 236.121 25.4315 236.17 25.3082 236.341C24.596 237.324 21.3719 257.697 23.7005 268.972C23.7431 269.176 23.946 269.31 24.1532 269.267Z"
        fill="#BEC8CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.5605 254.143C23.7164 254.078 23.7813 253.918 23.705 253.786C23.6871 253.755 21.905 250.633 21.1191 247.947C21.0769 247.804 20.9064 247.717 20.7384 247.752C20.5687 247.787 20.468 247.932 20.5094 248.075C21.3124 250.817 23.1229 253.988 23.1415 254.02C23.2089 254.138 23.3664 254.195 23.5101 254.159C23.5272 254.155 23.5442 254.15 23.5605 254.143Z"
        fill="#BEC8CE"
      />
      <ellipse
        cx="19.0165"
        cy="279.732"
        rx="14.5793"
        ry="13.7472"
        fill="#E4EBF2"
      />
      <path
        d="M364.32 280.867C364.32 280.867 364.25 273.036 363.504 269.269C362.757 265.503 360.471 261.455 360.471 261.455"
        stroke="#505548"
        strokeWidth="1.77036"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M371.681 254.995C371.681 262.365 362.89 268.339 352.046 268.339C344.527 268.339 337.994 265.466 334.696 261.247C337.206 260.795 339.053 260.057 338.974 259.492C338.884 258.857 336.389 258.683 333.399 259.103C333.387 259.105 333.375 259.107 333.362 259.108C332.745 257.813 332.412 256.43 332.412 254.995C332.412 254.954 332.412 254.914 332.413 254.873C333.36 255.095 334.64 255.232 336.048 255.232C338.967 255.232 341.334 254.645 341.334 253.921C341.334 253.197 338.967 252.61 336.048 252.61C334.753 252.61 333.567 252.726 332.648 252.917C334.117 246.534 342.242 241.65 352.046 241.65C362.89 241.65 371.681 247.625 371.681 254.995Z"
        fill="#013C25"
      />
      <path
        d="M380.342 252.076C380.342 252.076 377.409 261.386 376.846 266.14C376.283 270.893 377.441 276.573 377.441 276.573"
        stroke="#004F30"
        strokeWidth="2.21295"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M375.764 269.377C388.182 272.817 401.8 263.729 406.181 249.079C410.561 234.429 404.045 219.765 391.626 216.325C379.208 212.885 365.59 221.973 361.209 236.623C356.829 251.272 363.345 265.937 375.764 269.377ZM391.961 243.411C394.492 243.166 396.478 242.205 396.396 241.265C396.314 240.324 394.196 239.76 391.664 240.005C389.133 240.249 387.148 241.21 387.229 242.151C387.311 243.092 389.43 243.656 391.961 243.411Z"
        fill="url(#energyconsumption-paint0_linear)"
      />
      <path
        d="M362.377 242.23C362.377 242.23 365.494 252.035 367.072 256.554C368.649 261.073 370.906 270.999 370.906 270.999"
        stroke="#005534"
        strokeWidth="2.21295"
        strokeLinecap="round"
      />
      <ellipse
        cx="359.402"
        cy="221.2"
        rx="23.2771"
        ry="31.2657"
        transform="rotate(-8 359.402 221.2)"
        fill="url(#energyconsumption-paint1_linear)"
      />
      <mask
        id="energyconsumption-mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="335"
        y="190"
        width="48"
        height="63"
      >
        <ellipse
          cx="359.402"
          cy="221.2"
          rx="23.2771"
          ry="31.2657"
          transform="rotate(-8 359.402 221.2)"
          fill="white"
        />
      </mask>
      <g mask="url(#energyconsumption-mask0)">
        <ellipse
          cx="373.869"
          cy="219.06"
          rx="16.5967"
          ry="53.5275"
          transform="rotate(-8 373.869 219.06)"
          fill="url(#energyconsumption-paint2_linear)"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M358.908 268.738H380.937L377.687 302.816H362.331L358.908 268.738Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M374.534 268.738H380.936L379.705 302.816H373.887L374.534 268.738Z"
        fill="#E7ECEF"
      />
      <rect
        x="94.2405"
        width="214.548"
        height="85.8191"
        rx="5.36369"
        fill="url(#energyconsumption-paint3_linear)"
      />
      <rect
        x="103.046"
        y="61.8828"
        width="199.034"
        height="13.0598"
        fill="#383838"
      />
      <rect
        x="104.523"
        y="70.0645"
        width="196.079"
        height="9.9531"
        fill="white"
      />
      <g style={{ mixBlendMode: 'multiply' }}>
        <rect
          x="104.523"
          y="70.0645"
          width="196.079"
          height="3.76837"
          fill="#CFCFCF"
        />
      </g>
      <path
        d="M274.322 46.6241H302.079V33.2148H274.322L269.449 39.9195L274.322 46.6241Z"
        fill="#BEC8CE"
      />
      <rect
        x="103.046"
        y="38.3223"
        width="182.726"
        height="3.83249"
        fill="#BEC8CE"
      />
      <path
        opacity="0.25"
        d="M319.703 171.64H83L115.535 64.127H289.023L319.703 171.64Z"
        fill="url(#energyconsumption-paint4_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.203 274.361H306.311L318 301.03H85L102.203 274.361Z"
        fill="#E7ECEF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M117.529 202.337C117.529 198.704 120.475 195.758 124.109 195.758H201.502C204.227 195.758 206.437 197.967 206.437 200.692V248.503H152.427H117.529V202.337Z"
        fill="url(#energyconsumption-paint5_linear)"
      />
      <path
        d="M206.437 202.337C206.437 198.704 209.382 195.758 213.016 195.758H288.765C292.398 195.758 295.344 198.704 295.344 202.337V248.503H206.437V202.337Z"
        fill="url(#paint6_linear)"
      />
      <rect
        x="282.173"
        y="222.131"
        width="20.5804"
        height="49.4484"
        rx="6.57944"
        fill="url(#energyconsumption-paint7_linear)"
      />
      <rect
        x="107.651"
        y="222.131"
        width="20.5804"
        height="49.4484"
        rx="6.57944"
        fill="url(#energyconsumption-paint8_linear)"
      />
      <path
        d="M117.529 251.785C117.529 248.151 120.475 245.205 124.109 245.205H288.765C292.398 245.205 295.344 248.151 295.344 251.785V271.578H117.529V251.785Z"
        fill="url(#energyconsumption-paint9_linear)"
      />
      <path
        d="M117.529 260.029C117.529 256.395 120.475 253.449 124.109 253.449H288.765C292.398 253.449 295.344 256.395 295.344 260.029V271.58H117.529V260.029Z"
        fill="url(#energyconsumption-paint10_linear)"
      />
      <rect
        x="117.529"
        y="271.578"
        width="179.461"
        height="6.59312"
        rx="1.64486"
        fill="#05243B"
      />
      <rect
        x="281.349"
        y="274.875"
        width="9.05539"
        height="10.7138"
        rx="1.64486"
        fill="#05243B"
      />
      <rect
        x="124.115"
        y="274.875"
        width="9.05539"
        height="10.7138"
        rx="1.64486"
        fill="#05243B"
      />
      <path
        d="M207.042 245.627L208.906 254.85V270.755"
        stroke="#236CC0"
        strokeWidth="1.64486"
        strokeLinecap="square"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M238.489 208.919C238.09 207.206 239.536 205.601 241.279 205.829C247.15 206.599 258.794 207.986 266.459 207.986C272.641 207.986 276.93 207.525 279.432 207.14C280.654 206.952 281.786 208.077 281.543 209.29C280.567 214.176 278.709 224.528 279.409 229.451C280.084 234.204 281.189 241.247 281.749 244.786C281.922 245.879 280.99 246.83 279.892 246.688C277.531 246.382 273.893 245.946 272.175 245.919C268.021 245.856 264.831 246.971 261.514 246.984C257.638 246.999 253.625 245.919 250.977 245.919C246.938 245.919 242.751 246.332 240.167 246.644C238.976 246.787 237.989 245.671 238.293 244.511C239.442 240.12 241.654 230.981 241.497 226.208C241.353 221.802 239.586 213.626 238.489 208.919Z"
        fill="url(#energyconsumption-paint11_radial)"
      />
      <mask
        id="energyconsumption-mask1"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="238"
        y="205"
        width="44"
        height="42"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M238.489 208.919C238.09 207.206 239.536 205.601 241.279 205.829C247.15 206.599 258.794 207.986 266.459 207.986C272.641 207.986 276.93 207.525 279.432 207.14C280.654 206.952 281.786 208.077 281.543 209.29C280.567 214.176 278.709 224.528 279.409 229.451C280.084 234.204 281.189 241.247 281.749 244.786C281.922 245.879 280.99 246.83 279.892 246.688C277.531 246.382 273.893 245.946 272.175 245.919C268.021 245.856 264.831 246.971 261.514 246.984C257.638 246.999 253.625 245.919 250.977 245.919C246.938 245.919 242.751 246.332 240.167 246.644C238.976 246.787 237.989 245.671 238.293 244.511C239.442 240.12 241.654 230.981 241.497 226.208C241.353 221.802 239.586 213.626 238.489 208.919Z"
          fill="white"
        />
      </mask>
      <g mask="url(#energyconsumption-mask1)">
        <path
          d="M241.423 221.309L253.899 215.537L267.403 224.193L280.115 215.537"
          stroke="white"
          strokeWidth="5.75701"
          strokeLinecap="square"
        />
        <path
          d="M238.131 234.494L252.199 228.723L267.426 237.379L281.761 228.723"
          stroke="white"
          strokeWidth="5.75701"
          strokeLinecap="square"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.302 198.753L136.798 188.301C135.415 191.752 134.48 193.789 132.634 195.261C131.152 196.447 129.273 197.019 127.369 197.076C124.083 197.158 120.871 198.818 118.967 201.869C116.426 205.95 117.196 211.299 120.789 214.521C125.573 218.807 133.064 217.703 136.376 212.231C137.203 210.865 137.65 209.361 137.758 207.839C139.654 202.016 141.516 200.961 147.302 198.753Z"
        fill="#2A2C2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M153.825 234.45C156.284 233.788 158.717 232.831 161.052 231.645L160.679 231.482C159.967 230.517 159.553 229.249 159.512 227.908C159.512 227.908 159.28 212.826 159.023 212.033V212.017L159.015 212.008C158.543 210.618 157.401 209.489 155.878 209.072L155.861 209.064H155.853L155.795 209.048C153.337 208.434 150.845 209.898 150.216 212.327C149.935 213.44 153.146 232.242 153.146 232.242C153.246 233.052 153.486 233.796 153.825 234.45Z"
        fill="#FFCBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M146.524 288.167H157.028C157.343 288.167 157.616 288.036 157.823 287.84C158.022 287.644 158.154 287.366 158.154 287.055C158.154 286.54 157.79 286.09 157.269 285.975L153.602 285.166L153.626 285.002H146.524V288.167Z"
        fill="#F3F6F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M181.324 287.84C181.39 287.775 181.448 287.693 181.497 287.603C181.448 287.693 181.39 287.775 181.324 287.84Z"
        fill="#275995"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M177.119 285.003L177.855 279.425C177.855 279.425 179.709 264.908 181.555 248.682C181.862 245.901 180.976 243.112 179.113 241.01C177.251 238.909 174.561 237.674 171.73 237.608L160.505 237.543L154.951 228.113C154.951 228.113 134.24 228.097 133.983 227.859C134.141 228.007 130.465 232.448 130.109 232.963C128.752 234.975 127.651 237.175 127.965 239.661C128.578 244.544 133.776 248.183 138.544 248.183C138.544 248.183 141.723 248.183 146.036 248.183L146.524 269.668V285.003H153.626L154.363 279.425C154.363 279.425 156.217 264.908 158.055 248.682C158.071 248.51 158.071 248.347 158.088 248.183C164.271 248.183 169.528 248.183 169.528 248.183L170.025 269.668V288.168V285.003H177.119Z"
        fill="#BEC8CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M180.769 285.976C181.158 286.057 181.456 286.336 181.58 286.687C181.456 286.336 181.158 286.057 180.769 285.976L177.102 285.166L180.769 285.976Z"
        fill="#275995"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M181.58 286.687C181.456 286.335 181.158 286.057 180.769 285.975L177.102 285.166L177.119 285.002H170.025V288.167H180.529C180.844 288.167 181.117 288.036 181.324 287.84C181.39 287.774 181.448 287.693 181.497 287.603C181.547 287.521 181.58 287.431 181.613 287.333C181.63 287.243 181.646 287.153 181.646 287.055C181.646 286.924 181.622 286.801 181.58 286.687Z"
        fill="#F3F6F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M149.529 196.977C146.358 201.001 140.274 201.001 137.112 196.977C134.96 194.229 134.96 190.295 137.112 187.555C140.274 183.523 146.358 183.523 149.529 187.555C151.681 190.295 151.681 194.229 149.529 196.977Z"
        fill="#2A2C2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M150.522 194.566C150.357 197.461 148.006 199.857 145.084 200.119C143.561 200.25 142.137 199.816 141.011 199.006C139.513 197.935 138.544 196.185 138.544 194.222C138.544 190.951 141.226 188.301 144.537 188.301C144.662 188.301 144.794 188.301 144.918 188.309C148.163 188.513 150.713 191.261 150.522 194.566Z"
        fill="#FFCBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.294 186.084C146.59 186.044 145.829 185.896 145.059 185.725C144.761 185.88 144.405 185.962 143.983 185.962C143.71 185.962 143.461 185.929 143.23 185.855L140.407 187.769C138.321 188.906 136.905 191.098 136.905 193.625C136.905 195.604 137.783 197.379 139.165 198.606C139.471 198.941 138.851 199.947 139.223 200.217L142.874 190.771L147.095 186.681C147.261 186.493 146.549 187.164 146.665 186.951L147.294 186.084Z"
        fill="#2A2C2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M137.774 208.632L145.125 208.607L146.449 197.902L139.993 194.646L139.529 196.454L137.774 208.632Z"
        fill="#FFCBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M143.403 193.379C143.403 194.688 142.327 195.743 141.011 195.743C139.687 195.743 138.611 194.688 138.611 193.379C138.611 192.071 139.687 191.016 141.011 191.016C142.327 191.016 143.403 192.071 143.403 193.379Z"
        fill="#FFCBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.683 206.898C151.871 206.694 150.969 206.547 150.001 206.448C145.539 205.999 141.036 206.039 136.582 206.547C133.718 206.874 130.308 207.896 129.621 210.669C129.373 211.691 129.563 212.77 129.762 213.809C130.937 219.992 132.112 226.175 133.288 232.358C140.192 231.777 147.153 231.777 154.057 232.358C155.182 226.429 156.308 220.507 157.426 214.578C158.295 209.998 156.317 207.831 152.683 206.898Z"
        fill="#FFFFFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M161.235 235.786C159.555 235.786 158.181 234.436 158.181 232.776V232.465C158.181 230.805 159.555 229.447 161.235 229.447C162.477 229.447 163.487 230.453 163.487 231.68V233.561C163.487 234.788 162.477 235.786 161.235 235.786Z"
        fill="#FFCBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M152.724 234.148L152.716 234.115C152.55 233.322 151.929 232.618 151.226 232.414L136.467 228.922C136.376 228.374 136.342 227.801 136.384 227.221C136.384 227.221 137.766 212.197 137.584 211.387V211.354C137.261 209.923 136.243 208.68 134.77 208.107L134.761 208.099L134.753 208.107L134.687 208.083C132.311 207.208 129.679 208.41 128.801 210.757C128.495 211.575 129.099 222.583 129.422 227.957C129.522 229.715 129.596 230.868 129.596 230.868C129.613 231.784 129.803 232.643 130.134 233.387L130.151 233.395C130.209 233.534 130.275 233.673 130.349 233.804L130.482 233.78C131.566 234.941 132.949 235.628 134.256 235.636L151.623 235.808C152.434 235.816 152.914 235.08 152.724 234.148Z"
        fill="#FFCBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M229.356 283.426C229.455 285.048 229.558 286.672 229.678 288.283H229.678C229.536 286.382 229.408 284.466 229.3 282.55C229.219 281.132 229.151 279.708 229.086 278.281H221.207C221.553 280.061 221.898 281.84 222.236 283.621L222.237 283.622C222.672 283.558 223.113 283.492 223.554 283.426H229.356Z"
        fill="#FFCBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M218.494 286.531L222.564 285.353V285.352L218.493 286.531C218.439 286.609 218.394 286.687 218.35 286.776C218.261 286.971 218.221 287.179 218.219 287.387C218.221 287.179 218.262 286.97 218.353 286.774C218.393 286.687 218.439 286.607 218.494 286.531Z"
        fill="#E3A8C2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M228.74 248.868C229.111 242.35 229.526 235.845 229.656 229.316C229.716 226.051 232.472 217.829 232.527 215.512L209.72 214.426L213.22 219.396L213.462 228.568L213.557 228.6C213.57 228.793 213.582 228.987 213.595 229.179C214.675 245.593 217.679 262.14 220.812 278.284H229.61C229.507 274.914 228.691 265.634 228.657 262.239C228.608 257.799 228.608 253.338 228.641 248.868H228.74Z"
        fill="#CED5DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M210.621 282.55C210.541 281.132 210.472 279.708 210.407 278.281H202.528C202.874 280.061 203.219 281.84 203.557 283.621V283.622C203.992 283.558 204.434 283.492 204.875 283.426H210.678C210.775 285.048 210.878 286.672 210.999 288.283H211C210.857 286.382 210.729 284.466 210.621 282.55Z"
        fill="#FFCBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M199.814 286.531L203.885 285.353V285.352L199.814 286.531C199.76 286.609 199.715 286.687 199.672 286.776C199.581 286.974 199.54 287.186 199.54 287.397C199.54 287.185 199.582 286.973 199.673 286.774C199.714 286.687 199.759 286.607 199.814 286.531Z"
        fill="#E3A8C2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M209.963 248.868H210.061L210.154 234.504C210.189 232.775 210.218 231.045 210.253 229.316C210.287 227.433 210.318 225.561 210.351 223.683C209.346 208.025 205.307 213.745 201.019 214.542C199.505 214.823 193.779 209.473 193.539 222.614C192.39 243.779 202.132 278.283 202.132 278.283H210.93C210.827 274.914 210.012 265.634 209.977 262.239C209.928 257.799 209.928 253.338 209.963 248.868Z"
        fill="#CED5DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M193.429 220.94H226.01V212.252H193.429V220.94Z"
        fill="#CED5DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M218.219 287.388C218.221 287.18 218.262 286.971 218.353 286.775C218.393 286.689 218.439 286.607 218.494 286.532L222.564 285.353V285.352C222.452 284.777 222.347 284.201 222.235 283.625L222.237 283.624C222.672 283.56 223.113 283.494 223.554 283.428H229.356C229.443 284.859 229.537 286.287 229.64 287.71H218.251C218.23 287.604 218.217 287.496 218.219 287.388Z"
        fill="#F3F6F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M199.54 287.397C199.54 287.186 199.582 286.974 199.673 286.775C199.714 286.689 199.759 286.607 199.815 286.532L203.885 285.353V285.352C203.774 284.777 203.667 284.201 203.556 283.625L203.557 283.624C203.992 283.56 204.434 283.494 204.875 283.428H210.678C210.764 284.859 210.858 286.287 210.96 287.71H199.572C199.55 287.607 199.539 287.503 199.54 287.397Z"
        fill="#F3F6F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M193.018 217.714H234.022L233.057 184.576H246.414C244.641 178.305 241.581 172.424 237.316 167.388C234.436 163.992 231.151 161.141 227.297 159.519C218.448 155.799 200.681 155.167 192.986 161.68C187.046 166.71 184.867 175.513 181.859 182.837H193.785L193.018 217.714Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M233.088 182.809L233.306 185.479L233.749 190.916L226.246 221.534C225.39 223.035 225.986 224.957 227.55 225.706C228.875 226.34 230.465 225.916 231.303 224.713L238.294 210.201L241.241 204.077C243.211 199.989 244.594 195.67 245.356 191.252C245.837 188.465 246.067 185.638 246.038 182.809H233.088Z"
        fill="#FFCBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M181.872 182.807L181.859 182.836L169.72 161.554C168.578 159.422 165.789 158.836 163.885 160.327C162.456 161.446 161.99 163.404 162.761 165.048L177.601 196.688C178.811 199.269 182.294 199.718 184.12 197.528L193.785 182.836L181.872 182.807Z"
        fill="#FFCBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M168.118 164.161L166.785 164.427C166.361 164.511 165.95 164.236 165.866 163.814L163.14 150.115C163.055 149.692 163.33 149.281 163.754 149.197L165.087 148.931C165.51 148.847 165.921 149.122 166.006 149.545L168.731 163.243C168.816 163.666 168.541 164.077 168.118 164.161Z"
        fill="#4F5A60"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M211.764 159.924H219.48V149.135H211.764V159.924Z"
        fill="#FFCBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M205.823 145.634C206.16 149.726 209.748 152.767 213.846 152.43C215.47 152.296 216.935 151.645 218.084 150.656C218.386 150.396 218.668 150.108 218.925 149.793C220.137 148.357 220.803 146.442 220.636 144.413C220.391 141.444 218.439 139.027 215.822 138.06C215.397 137.895 214.951 137.779 214.498 137.698L214.48 137.693C213.877 137.596 213.257 137.565 212.625 137.617C212.468 137.63 212.31 137.649 212.153 137.674C210.851 137.863 209.669 138.384 208.684 139.142C206.765 140.63 205.609 143.026 205.823 145.634Z"
        fill="#FFCBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M206.856 134.034C207.785 133.893 208.755 134.19 209.402 134.871C209.712 135.196 209.925 135.542 210.062 135.895C210.28 136.457 210.843 136.814 211.446 136.843C212.544 136.897 213.665 136.925 214.729 137.126C214.831 137.139 214.933 137.15 215.035 137.165L215.053 137.169C215.506 137.25 215.951 137.366 216.377 137.531C218.994 138.499 220.946 140.915 221.191 143.885C221.358 145.914 220.692 147.829 219.48 149.264L219.377 149.378L214.102 144.151L213.898 144.675L213.878 144.729L213.837 144.833C213.706 145.173 213.383 145.403 213.017 145.415C212.488 145.433 212.055 144.997 212.076 144.467L212.182 141.837L212.169 141.68C212.101 140.85 211.368 140.228 210.537 140.296C209.621 140.371 208.551 140.621 207.664 140.28L207.595 140.269C204.551 139.883 203.309 134.574 206.856 134.034Z"
        fill="#2A2C2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M207.595 140.269L207.664 140.28C207.809 140.392 207.627 140.3 207.595 140.269Z"
        fill="#612965"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M213.528 144.816C213.303 146.258 214.647 147.398 216.034 146.941C216.686 146.726 217.185 146.135 217.291 145.457C217.516 144.015 216.172 142.876 214.785 143.333C214.134 143.548 213.633 144.138 213.528 144.816Z"
        fill="#FFCBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M220.378 153.04L220.491 144.908L216.598 146.855V149.26C216.598 151.347 218.29 153.04 220.378 153.04Z"
        fill="#2A2C2D"
      />
      <defs>
        <linearGradient
          id="energyconsumption-paint0_linear"
          x1="348.029"
          y1="243.158"
          x2="381.603"
          y2="282.059"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00613F" />
          <stop offset="1" stopColor="#004E2F" />
        </linearGradient>
        <linearGradient
          id="energyconsumption-paint1_linear"
          x1="298.42"
          y1="178.304"
          x2="341.979"
          y2="261.579"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00B080" />
          <stop offset="1" stopColor="#007849" />
        </linearGradient>
        <linearGradient
          id="energyconsumption-paint2_linear"
          x1="344.324"
          y1="203.621"
          x2="413.058"
          y2="264.932"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#008E64" />
          <stop offset="1" stopColor="#005534" />
        </linearGradient>
        <linearGradient
          id="energyconsumption-paint3_linear"
          x1="104.004"
          y1="7.81119"
          x2="104.004"
          y2="85.8191"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#D4D4D4" />
        </linearGradient>
        <linearGradient
          id="energyconsumption-paint4_linear"
          x1="100.402"
          y1="70.9422"
          x2="100.402"
          y2="162.673"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#92EFFF" />
          <stop offset="1" stopColor="#B5FAFF" stopOpacity="0.01" />
        </linearGradient>
        <linearGradient
          id="energyconsumption-paint5_linear"
          x1="73.0755"
          y1="222.13"
          x2="119.366"
          y2="300.158"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#65A1DB" />
          <stop offset="1" stopColor="#3176DE" />
        </linearGradient>
        <linearGradient
          id="paint6_linear"
          x1="161.983"
          y1="222.13"
          x2="208.273"
          y2="300.158"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#65A1DB" />
          <stop offset="1" stopColor="#3176DE" />
        </linearGradient>
        <linearGradient
          id="energyconsumption-paint7_linear"
          x1="277.028"
          y1="240.609"
          x2="297.691"
          y2="246.363"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#65A1DB" />
          <stop offset="1" stopColor="#3176DE" />
        </linearGradient>
        <linearGradient
          id="energyconsumption-paint8_linear"
          x1="102.506"
          y1="242.273"
          x2="124.174"
          y2="247.808"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#65A1DB" />
          <stop offset="1" stopColor="#3176DE" />
        </linearGradient>
        <linearGradient
          id="energyconsumption-paint9_linear"
          x1="90.6354"
          y1="236.119"
          x2="90.6354"
          y2="271.578"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#65A1DB" />
          <stop offset="1" stopColor="#3176DE" />
        </linearGradient>
        <linearGradient
          id="energyconsumption-paint10_linear"
          x1="-45.4986"
          y1="219.463"
          x2="-45.4986"
          y2="271.58"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#65A1DB" />
          <stop offset="1" stopColor="#3176DE" />
        </linearGradient>
        <radialGradient
          id="energyconsumption-paint11_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(259.989 220.855) rotate(88.2697) scale(32.7845 36.087)"
        >
          <stop stopColor="#FFE75A" />
          <stop offset="1" stopColor="#FFD049" />
        </radialGradient>
      </defs>
    </g>
  )
}

export default EnergyConsumption
