import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const FooterContent = ({ children, isTagline, isList }) => {
  const _FooterContent = classnames('cui-footer__content', {
    'cui-footer__tagline': isTagline,
    'lm--footer-tagline': isTagline,
    'cui-footer__list': isList,
    'lm--footer-list': isList,
  })
  function renderContent() {
    if (isList) {
      return (
        <ul className={_FooterContent}> 
          { React.Children.map(children, (child, childIdx) => (
            <li 
              className="cui-footer__list_item"
              key={`footer-listitem--${childIdx}`}
            >
              { child }
            </li>
          ))}
        </ul>
      )
    }
    return (
      <p className={_FooterContent} dangerouslySetInnerHTML={{__html:children}} />
    )
  }
  return renderContent()
}

FooterContent.propTypes = {
  isTagline: PropTypes.bool,
  isList: PropTypes.bool
}

FooterContent.defaultProps = {
  isTagline: false,
  isList: false
}

export default FooterContent
