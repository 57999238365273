import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const CardSegment = ({ children, width, id, className }) => {
  const _CardSegment = classnames('cui-card__segment', {
    [className]: className,
  })
  return (
    <div style={{ width: width }} className={_CardSegment} id={id}>
      {children}
    </div>
  )
}

CardSegment.propTypes = {
  // CardSegment children
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  width: PropTypes.string,
  id: PropTypes.string,
}

export default CardSegment
