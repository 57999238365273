import React from 'react'
import PropTypes from 'prop-types';

class CloseUtilitiesIcon extends React.PureComponent {
  static propTypes = {
    color: PropTypes.array,
  }

  render() {
    return (
      <g
        className="icon-close-utilities"
        stroke="none"
        strokeWidth="1"
        fill={this.props.color[0]}
        fillRule="evenodd"
      >
        <path
          d="M10.0786 28.3947C10.0786 24.817 12.2939 21.0431 15.3 17.4721C17.6656 14.662 20.3987 12.1133 22.6621 10.0027C23.0069 9.68114 23.3408 9.36978 23.6608 9.06921C26.1973 11.3239 29.4239 14.2308 32.1066 17.4651C35.0615 21.0273 37.2009 24.8066 37.2009 28.3947C37.2009 35.7343 31.1453 41.7129 23.6397 41.7129C16.1342 41.7129 10.0786 35.7343 10.0786 28.3947Z"
          fill="white"
          stroke="#818A91"
          strokeWidth="2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.6806 28.3307L18.0074 28.0749L25.9667 19.8577L25.5581 25.6769L29.2312 25.9326L21.3128 33.5679L21.6806 28.3307Z"
          fill="url(#paint0_linear)"
        />
        <mask
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="18"
          y="19"
          width="12"
          height="15"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M21.6806 28.3307L18.0074 28.0749L25.9667 19.8577L25.5581 25.6769L29.2312 25.9326L21.3128 33.5679L21.6806 28.3307Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0)">
          <rect
            x="18.1165"
            y="19.7129"
            width="12.0506"
            height="10"
            rx="2"
            fill="url(#paint1_linear)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.4984 30.2176C19.4984 30.2176 22.9707 29.3582 24.4008 27.0463C25.8309 24.7343 25.016 19.032 25.016 19.032L29.2538 19.3463C29.2538 19.3463 26.0819 30.6644 24.6522 32.7122C23.2224 34.76 20.675 33.3887 20.675 33.3887L19.4984 30.2176Z"
            fill="url(#paint2_linear)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.486 24.7126L29.2313 25.9325C29.2313 25.9325 24.971 32.6538 23.275 34.0792C21.5789 35.5047 19.6278 32.4192 19.6278 32.4192C19.6278 32.4192 24.7882 30.0028 26.1341 27.7126C27.4801 25.4225 26.486 24.7126 26.486 24.7126Z"
            fill="url(#paint3_linear)"
          />
        </g>
        <path
          d="M27.1503 35.7129C27.1503 31.8509 30.2954 28.7129 34.184 28.7129C38.0726 28.7129 41.2178 31.8509 41.2178 35.7129C41.2178 39.5749 38.0726 42.7129 34.184 42.7129C30.2954 42.7129 27.1503 39.5749 27.1503 35.7129Z"
          fill="white"
          stroke="#818A91"
          strokeWidth="2"
        />
        <path
          d="M36.6946 38.2129L31.6735 33.2129"
          stroke="#818A91"
          strokeLinecap="round"
        />
        <path
          d="M36.6947 33.2129L31.6736 38.2129"
          stroke="#818A91"
          strokeLinecap="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="16.1777"
            y1="25.2593"
            x2="23.2859"
            y2="29.3219"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F5B987" />
            <stop offset="1" stopColor="#FF718F" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="15.9101"
            y1="24.0475"
            x2="22.3411"
            y2="28.7828"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F5B987" />
            <stop offset="1" stopColor="#FF718F" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="23.9074"
            y1="27.7336"
            x2="25.525"
            y2="28.183"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D35F9B" />
            <stop offset="1" stopColor="#3C6DBF" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="30.8117"
            y1="32.5578"
            x2="31.5494"
            y2="21.9641"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#40B8FF" />
            <stop offset="1" stopColor="#89FCFF" />
          </linearGradient>
        </defs>
      </g>
    )
  }
}

export default CloseUtilitiesIcon
