import React from 'react'

class Individual extends React.PureComponent {
  render() {
    return (
      <g
        className="icon-individual"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-495.000000, -380.000000)">
          <g transform="translate(421.000000, 202.000000)">
            <g transform="translate(1.000000, 140.000000)">
              <g transform="translate(65.000000, 32.000000)">
                <rect x="0" y="0" width="48" height="48" />
                <path
                  d="M10,40 C10,32.2677736 16.2677736,26 24,26 C31.7322264,26 38,32.2677736 38,40"
                  stroke={this.props.color[0]}
                  strokeWidth="4"
                  strokeLinecap="round"
                />
                <circle
                  stroke={this.props.color[0]}
                  strokeWidth="4"
                  cx="24"
                  cy="16"
                  r="8"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    )
  }
}

export default Individual
