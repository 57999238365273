import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Icons from '../Icons'

const Preloader = ({ text, icon, children, isOverlay, overlayBackground }) => {
  const _Preloader = classnames('cui-preloader', {
    'is-overlayed': isOverlay,
  })
  return (
    <div className={_Preloader} style={{ backgroundColor: overlayBackground }}>
      {icon || <Icons.Spinloader width={50} height={50} color={['#9ba6aa']} />}
      {text && <span>{text}</span>}
      {children}
    </div>
  )
}

Preloader.defaultProps = {
  overlayBackground: 'transparent',
  text: 'Please wait..',
  isOverlay: false,
}

Preloader.propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
  icon: PropTypes.node,
  isOverlay: PropTypes.bool,
  overlayBackground: PropTypes.string,
}

export default Preloader
