import React from 'react'

const GraphFade = () => {
  return (
    <g className="illus-graph-fade">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 5C14.5 2.51472 16.5147 0.5 19 0.5C21.4853 0.5 23.5 2.51472 23.5 5V33C23.5 35.4853 21.4853 37.5 19 37.5C16.5147 37.5 14.5 35.4853 14.5 33V5ZM0.5 24.9595C0.5 22.4966 2.49657 20.5 4.95946 20.5C7.42235 20.5 9.41892 22.4966 9.41892 24.9595V33.0405C9.41892 35.5034 7.42235 37.5 4.95946 37.5C2.49657 37.5 0.5 35.5034 0.5 33.0405V24.9595ZM33.5 11.5C31.0371 11.5 29.0405 13.4966 29.0405 15.9595V33.0405C29.0405 35.5034 31.0371 37.5 33.5 37.5C35.9629 37.5 37.9595 35.5034 37.9595 33.0405V15.9595C37.9595 13.4966 35.9629 11.5 33.5 11.5ZM57.5 19C57.5 16.5147 59.5147 14.5 62 14.5C64.4853 14.5 66.5 16.5147 66.5 19V33C66.5 35.4853 64.4853 37.5 62 37.5C59.5147 37.5 57.5 35.4853 57.5 33V19ZM48 4.5C45.5147 4.5 43.5 6.51472 43.5 9V33C43.5 35.4853 45.5147 37.5 48 37.5C50.4853 37.5 52.5 35.4853 52.5 33V9C52.5 6.51472 50.4853 4.5 48 4.5Z"
        fill="url(#graph-fade-paint0_linear)"
      />
      <rect x="0.5" y="41.5" width="66" height="3" rx="1.5" fill="#F3F6F7" />
      <defs>
        <linearGradient
          id="graph-fade-paint0_linear"
          x1="40"
          y1="-2.5"
          x2="39.8839"
          y2="37.4997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF9FC" />
          <stop offset="1" stopColor="#E0EBED" />
        </linearGradient>
      </defs>
    </g>
  )
}

export default GraphFade
