import React from 'react'
import PropTypes from 'prop-types'

const Download = ({ color }) => {
  return (
    <g className="icon-download">
      <circle cx="16" cy="16" r="16" fill={color[0]} fillOpacity="0.1" />
      <path
        d="M12.0001 11C12.5524 11 13.0001 11.4477 13.0001 12C13.0001 12.5523 12.5524 13 12.0001 13H11.0001V21H21.0001V13H20.0001C19.4478 13 19.0001 12.5523 19.0001 12C19.0001 11.4477 19.4478 11 20.0001 11H22.0001C22.5524 11 23.0001 11.4477 23.0001 12V22C23.0001 22.5523 22.5524 23 22.0001 23H10.0001C9.44784 23 9.00012 22.5523 9.00012 22V12C9.00012 11.4477 9.44784 11 10.0001 11H12.0001ZM15.293 19.7071L12.293 16.7071C11.9025 16.3166 11.9025 15.6834 12.293 15.2929C12.6835 14.9024 13.3167 14.9024 13.7072 15.2929L15.0001 16.585V10C15.0001 9.48716 15.3862 9.06449 15.8835 9.00673L16.0001 9C16.513 9 16.9356 9.38604 16.9934 9.88338L17.0001 10V16.584L18.293 15.2929C18.6535 14.9324 19.2207 14.9047 19.613 15.2097L19.7072 15.2929C20.0677 15.6534 20.0954 16.2206 19.7904 16.6129L19.7072 16.7071L16.7072 19.7071C16.6814 19.733 16.6541 19.7574 16.6256 19.7803L16.6181 19.784C16.6072 19.7948 16.6013 19.7993 16.5954 19.8037C16.5713 19.8215 16.5464 19.8383 16.5208 19.8539L16.4842 19.8753C16.4644 19.8862 16.444 19.8966 16.4232 19.9063L16.3715 19.9288C16.3517 19.9367 16.3319 19.9439 16.3119 19.9505L16.3123 19.9503L16.292 19.9567C16.2817 19.9598 16.2714 19.9627 16.2611 19.9655L16.2294 19.9736L16.2029 19.9793C16.1832 19.9834 16.1634 19.9869 16.1436 19.9897L16.0871 19.995L16.0001 20L15.9799 19.9998L15.9411 19.9983L15.9052 19.9955L15.8835 19.9933L15.8567 19.9897C15.8368 19.9869 15.817 19.9834 15.7974 19.9793C15.7885 19.9776 15.7796 19.9757 15.7708 19.9736L15.7391 19.9655C15.7288 19.9627 15.7185 19.9598 15.7083 19.9567L15.6884 19.9505C15.6683 19.9439 15.6485 19.9367 15.6291 19.9289L15.5769 19.9063C15.5562 19.8966 15.5358 19.8862 15.5158 19.8751C15.5037 19.8685 15.4915 19.8614 15.4794 19.854C15.4538 19.8383 15.429 19.8215 15.405 19.8037L15.3801 19.784L15.3751 19.78L15.3731 19.778L15.293 19.7071Z"
        fill={color[0]}
      />
    </g>
  )
}

Download.propTypes = {
  color: PropTypes.array,
}

export default Download
