import React from 'react'
import PropTypes from 'prop-types'
import StepItem from './StepItem'

const Steps = ({ children }) => {
  let stepIndex = -1
  return React.Children.map(children, child => {
    if (child.type === StepItem) {
      stepIndex++
      return React.cloneElement(child, { stepIndex })
    }
    return child
  })
}

Steps.propTypes = {
  children: PropTypes.node,
}

Steps.Item = StepItem

export default Steps
