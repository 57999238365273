import React from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '../Theme'

const Breadcrumb = ({ items }) => {
  const theme = useTheme()
  return (
    <ol
      className="cui-breadcrumb"
      style={{
        '--cuiBreadcrumbTextPrimary': theme.colors.text.primary,
        '--cuiBreadcrumbTextSecondary': theme.colors.text.secondary,
        '--cuiBreadcrumbDivider': theme.colors.divider,
      }}
    >
      {items.map((item, index) => {
        return (
          <li key={index}>
            {item?.name ? <a href={item.link}>{item.name}</a> : item}
          </li>
        )
      })}
    </ol>
  )
}

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.any,
      link: PropTypes.string,
    })
  ).isRequired,
}

Breadcrumb.defaultProps = {
  items: [],
}

export default Breadcrumb
