import React from 'react'
import './styles.scss'
import ButtonsContainer from './ButtonsContainer'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Icons from '../Icons'
import { withTheme } from '../Theme'

// import { Sprite } from '@lumi/packs/index.js'

class Button extends React.Component {
  handleClick = () => {
    if (this.props.onClickHandler) {
      this.props.onClickHandler()
    }
  }

  initButton = () => {
    const {
      id,
      link,
      children,
      type,
      disabled,
      pill,
      loading,
      active,
      theme,
      className,
      style,
    } = this.props

    const _Button = classnames('lm--button cui-button', className, {
      // variant: solid outlined
      'lm--button--solid cui-button-solid': this.props.variant === 'solid',
      'lm--button--outlined cui-button-outlined':
        this.props.variant === 'outlined',
      'lm--button--grey cui-button-grey': this.props.grey,
      'lm--button--small cui-button-small': this.props.small,
      'lm--button--font-medium cui-button-font-medium': this.props.fontMedium,
      'lm--button--pill cui-button-pill': this.props.pill,
      'lm--button--alert cui-button-alert': this.props.alert,
      active: active,
      loading,
    })

    const styles = {
      '--cuiButtonPrimary': theme.colors.primary.main,
      '--cuiButtonPrimaryContrastText': theme.colors.primary.contrastText,
      '--cuiButtonPrimaryHover': theme.colors.primary.hover,
      '--cuiButtonText': theme.colors.text.primary,
      '--cuiButtonDisabledText': theme.colors.disabled.button.text,
      '--cuiButtonDisabledBg': theme.colors.disabled.button.background,
      '--cuiButtonGreyBg': theme.colors.grey[200],
      '--cuiButtonGreyHoverBg': theme.colors.grey[300],
      '--cuiButtonAlertBg': theme.colors.error.main,
      '--cuiButtonAlertHoverBg': theme.colors.error.hover,
      '--cuiButtonAlertText': theme.colors.error.contrastText,
      ...style,
    }

    if (link) {
      return (
        // link
        <a id={id} href={link} onClick={this.handleClick} style={styles}>
          {children}
        </a>
      )
    } else {
      return (
        // default
        <button
          className={_Button}
          id={id}
          onClick={this.handleClick}
          type={type}
          disabled={disabled}
          style={styles}
        >
          {pill && (
            <svg className="lm--icon lm--sm cui-button-icon cui-button-icon-sm">
              {/* <use href={`${Sprite}#plus-16`}></use> */}
              <use href="/static/sprite.svg#plus-16" />
            </svg>
          )}
          {children}
          {loading && (
            <div className="cui-button__loading-icon">
              <Icons.Spinloader width={40} height={40} color={['#333333']} />
            </div>
          )}
        </button>
      )
    }
  }

  render() {
    return <>{this.initButton()}</>
  }
}

Button.propTypes = {
  onClickHandler: PropTypes.func,
  link: PropTypes.string,
  children: PropTypes.object,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
  pill: PropTypes.bool,
  grey: PropTypes.bool,
  small: PropTypes.bool,
  fontMedium: PropTypes.bool,
  alert: PropTypes.bool,
  id: PropTypes.string,
  type: PropTypes.string,
  loading: PropTypes.bool,
  active: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
}

const ButtonWithTheme = withTheme(Button)
ButtonWithTheme.Container = ButtonsContainer

export default ButtonWithTheme
