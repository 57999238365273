import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Icons from '../Icons'
import { useNavigationStack } from './NavigationStack'

const NavigationStackHeader = ({ id, className, children }) => {
  const nsCtx = useNavigationStack()
  const _NavigationStackHeader = classnames(
    'cui-navigationstack_header',
    className
  )

  const shouldRenderNext = () => {
    return !(nsCtx.data.activeScene >= nsCtx.data.numOfScenes - 1)
  }

  const handlePrev = () => {
    nsCtx.actions.prev()
  }

  const handleNext = () => {
    nsCtx.actions.next()
  }

  return (
    <div id={id} className={_NavigationStackHeader}>
      <div>
        <button
          className="cui-navigationstack_dir dir-prev"
          onClick={handlePrev}
        >
          <Icons.Caret.Down width={16} />
        </button>
      </div>
      <div className="cui-navigationstack_header__container">
        {nsCtx.data.activeSceneTitle}
      </div>
      <div>
        {shouldRenderNext() && (
          <button
            className="cui-navigationstack_dir dir-next"
            onClick={handleNext}
          >
            <Icons.Caret.Down width={16} />
          </button>
        )}
      </div>
    </div>
  )
}

NavigationStackHeader.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
}

export default NavigationStackHeader
