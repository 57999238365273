import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import FlatListItem from './FlatListItem'

const FlatList = ({ children, type, listStyleType, className, style }) => {
  const renderItems = () => {
    return React.Children.map(children, child => {
      if (child.type === FlatListItem) {
        return child
      }
      return null
    })
  }

  const ListTag = type

  return (
    <ListTag
      className={classnames('cui-flat-list', className)}
      style={{ listStyleType, ...style }}
    >
      {renderItems()}
    </ListTag>
  )
}

FlatList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.string,
  type: PropTypes.oneOf(['ul', 'ol']),
  listStyleType: PropTypes.string,
}

FlatList.defaultProps = {
  type: 'ol',
}

FlatList.Item = FlatListItem

export default FlatList
