import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import NavTreeList from './NavTreeList'
import NavTreeListItem from './NavTreeListItem'
import Icons from '../Icons'
import Typo from '../Typo'
import { useScreenSize } from '../ScreenSize'
import { useTheme } from '../Theme'

const validTypes = [NavTreeList, NavTreeListItem]
export const isValidType = (type, checkWith) => {
  if (checkWith) {
    return type === checkWith
  }
  return validTypes.some(t => t === type)
}

function NavTree({ id, className, children, title, subMenuExpanded }) {
  const theme = useTheme()

  const screenSize = useScreenSize()
  const isTablet = screenSize
    ? screenSize.width < screenSize.breakpoints.TABLET.width
    : false

  const [openMenu, setOpenMenu] = React.useState(false)

  const _NavTree = classnames('cui-navtree', className)
  const renderChildren = () => {
    return React.Children.map(children, child => {
      if (isValidType(child.type)) {
        if (child.type === NavTreeList) {
          return React.cloneElement(child, {
            onCloseMenu: () => setOpenMenu(false),
          })
        }
        return React.cloneElement(child)
      }
      return null
    })
  }

  const renderNav = () => {
    return (
      <nav id={id} className="cui-navtree__nav" role="navigation">
        {renderChildren()}
      </nav>
    )
  }
  return (
    <div
      className={_NavTree}
      style={{
        '--cuiNavTreeBg': theme.colors.navTree.background,
        '--cuiNavTreeDivider': theme.colors.navTree.divider,
        '--cuiNavTreeShadow': theme.colors.navTree.boxShadow,
        '--cuiNavTreeTextPrimary': theme.colors.text.primary,
        '--cuiNavTreeTextSecondary': theme.colors.text.secondary,
      }}
    >
      {!subMenuExpanded && isTablet ? (
        <>
          <div
            className="cui-navtree__menu-icon-wrapper"
            onClick={() => setOpenMenu(!openMenu)}
          >
            {openMenu ? (
              <Icons.Close width={16} color={[theme.colors.icon]} />
            ) : (
              <Icons.Menu width={16} color={[theme.colors.icon]} />
            )}
            <Typo variant="body2" isBold>
              {title}
            </Typo>
          </div>
          {openMenu && renderNav()}
        </>
      ) : (
        renderNav()
      )}
    </div>
  )
}

NavTree.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  children: (props, propName, componentName) => {
    const prop = props[propName]
    let error = null
    React.Children.forEach(prop, child => {
      if (!isValidType(child.type)) {
        error = new Error(
          `${componentName} children should be of type 'CuiNavTreeList'`
        )
      }
    })
    return error
  },
  title: PropTypes.node,
  subMenuExpanded: PropTypes.bool,
}

NavTree.List = NavTreeList
NavTree.Item = NavTreeListItem

export default NavTree
