import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useFormikContext } from 'formik'

import Button from '../../Button'

const isEmpty = obj => {
  const entries = Object.keys(obj)
  if (entries.length > 0) {
    return false
  }
  return true
}

const isSubmitDisabled = (errors, isValid, isSubmitting, isDisabled) => {
  if (isDisabled) {
    return true
  }

  if (!isValid) {
    return true
  }

  if (isSubmitting) {
    return true
  }

  if (!isEmpty(errors)) {
    return true
  }

  return false
}

const FieldCTA = ({ isInline, fromLeft, isCenter, children, ...props }) => {
  const _fieldCta = classnames('lm--form_cta cui-field_cta field-cta', {
    'lm--form--inline': isInline,
    'from-right': !isCenter && !fromLeft,
    'from-left': !isCenter && fromLeft,
  })

  const { isValid, isSubmitting, errors } = useFormikContext() || {}
  const renderChildren = () => {
    return React.Children.map(children, (child, index) => {
      if (!child) {
        return null
      }
      const props = {
        key: `field-cta--btn-${index}`,
        ...child.props,
      }

      if (child.type === Button && child.props.type === 'submit') {
        if (isSubmitting) {
          props.loading = true
        } else {
          props.loading = null
          delete props.loading
        }
      }
      if (child.props.type === 'submit') {
        const isDisabled = isSubmitDisabled(
          errors,
          isValid,
          isSubmitting,
          props.disabled
        )
        props.disabled = isDisabled
      } else {
        props.disabled = props.disabled || isSubmitting
      }

      return React.cloneElement(child, props)
    })
  }

  return (
    <div className={_fieldCta} {...props}>
      {renderChildren()}
    </div>
  )
}

FieldCTA.propTypes = {
  isCenter: PropTypes.bool,
  fromLeft: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  isInline: PropTypes.bool,
}

FieldCTA.defaultProps = {
  isCenter: true,
  fromLeft: false,
}

export default FieldCTA
