import React from 'react'
import PropTypes from 'prop-types'

import Modal from '../Modal'
import Button from '../Button'
import Icons from '../Icons'

const PopupStatus = ({
  visible,
  onOk,
  children,
  okButton,
  closeButton,
  isSuccess,
  ...restProps
}) => {
  const [active, setActive] = React.useState(visible)

  React.useEffect(() => {
    setActive(visible)
  }, [visible])

  const handleClose = () => {
    setActive(false)
  }

  return (
    <Modal
      {...restProps}
      visible={active}
      onEscapeKeyDown={handleClose}
      handleClose={handleClose}
      className="cui-popup cui-popup-status"
    >
      {isSuccess ? (
        <Icons.Checked
          width={100}
          height={100}
          className="cui-popup-status__success-icon"
        />
      ) : (
        <Icons.Alert
          width={200}
          height={200}
          className="cui-popup-status__error-icon"
        />
      )}
      {children}
      <div className="cui-popup__cta">
        {closeButton && (
          <Button
            variant={!okButton ? 'solid' : 'outlined'}
            onClickHandler={handleClose}
            {...closeButton}
          >
            {closeButton.label}
          </Button>
        )}
        {okButton && (
          <Button variant="solid" onClickHandler={onOk} {...okButton}>
            {okButton.label}
          </Button>
        )}
      </div>
    </Modal>
  )
}

PopupStatus.defaultProps = {
  visible: true,
  okButton: {
    label: 'Try again',
  },
  closeButton: {
    label: 'Close',
  },
}

PopupStatus.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.bool,
  onOk: PropTypes.func,
  onClose: PropTypes.func,
  okButton: PropTypes.shape({
    label: PropTypes.node.isRequired,
  }),
  closeButton: PropTypes.shape({
    label: PropTypes.node.isRequired,
  }),
  isSuccess: PropTypes.bool,
}

export default PopupStatus
