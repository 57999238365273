import moment from 'moment'

require('moment-timezone')

const parseDate = (
  date,
  format = 'DD MMM YYYY',
  timezone = 'Asia/Singapore'
) => {
  if (timezone) {
    return moment(date).tz(timezone).format(format)
  }
  return moment(date).format(format)
}

const parseQueryParams = (queryStr = '') => {
  if (queryStr === '') return {}
  const search = queryStr.split('?')
  const queryParams = {}
  if (search.length > 0) {
    const queries = search[1].split('&')
    if (queries.length > 0) {
      queries.forEach(q => {
        const _query = q.split('=')
        if (_query.length > 0) {
          queryParams[_query[0]] = _query[1]
        }
      })
    }
  }
  return queryParams
}

const chunk = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  )

export { parseDate, parseQueryParams, chunk }
