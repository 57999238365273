import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const SelectInputOption = ({ id, value, children }) => {
  const _SelectInputOption = classnames('cui-selectinput-option')
  return (
    <option className={_SelectInputOption} id={id} value={value}>
      {children}
    </option>
  )
}
SelectInputOption.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
}

export default SelectInputOption
