import React from 'react'
import PropTypes from 'prop-types'

class PaperBillIcon extends React.PureComponent {
  static propTypes = {
    color: PropTypes.array,
  }

  render() {
    return (
      <g
        className="icon-paper-bill"
        stroke="none"
        strokeWidth="1"
        fill={this.props.color[0]}
        fillRule="evenodd"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M75.4868 13.4358C61.9096 6.76382 45.8192 9.31062 30.9404 12.045C27.5248 12.673 24.048 13.3238 20.9792 14.949C13.1344 19.1038 9.65958 29.2942 11.84 37.899C14.0204 46.5042 20.938 53.3678 28.9604 57.1686C36.9828 60.9694 46.0308 62.0466 54.9072 62.1706C61.3156 62.2602 68.102 61.7606 73.3836 58.1294C79.334 54.0394 82.2112 46.7682 83.81 39.727C84.9404 34.7498 85.5952 29.5386 84.4788 24.5582C83.3624 19.5778 80.1952 15.4062 75.4868 13.4358Z"
          fill="#F8FCFF"
        />
        <mask
          id="iconpapebill-mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="11"
          y="9"
          width="75"
          height="54"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M75.4868 13.836C61.9096 7.16397 45.8192 9.71077 30.9404 12.4452C27.5248 13.0732 24.048 13.724 20.9792 15.3492C13.1344 19.504 9.65958 29.6944 11.84 38.2992C14.0204 46.9044 20.938 53.768 28.9604 57.5688C36.9828 61.3696 46.0308 62.4468 54.9072 62.5708C61.3156 62.6604 68.102 62.1608 73.3836 58.5296C79.334 54.4396 82.2112 47.1684 83.81 40.1272C84.9404 35.15 85.5952 29.9388 84.4788 24.9584C83.3624 19.978 80.1952 15.8064 75.4868 13.836Z"
            fill="#F8FCFF"
          />
        </mask>
        <g mask="url(#iconpapebill-mask0)">
          <path
            d="M70.485 37.6812H25.5039C24.1553 37.6812 23.062 38.7744 23.062 40.123V49.1783C23.062 50.5269 24.1553 51.6201 25.5039 51.6201H70.485C71.8336 51.6201 72.9268 50.5269 72.9268 49.1783V40.123C72.9268 38.7744 71.8336 37.6812 70.485 37.6812Z"
            fill="url(#iconpapebill-paint0_linear)"
          />
          <path
            d="M62.3863 24.4036H63.1697C63.624 24.4036 64.0738 24.493 64.4935 24.6669C64.9133 24.8407 65.2946 25.0955 65.6158 25.4168C65.9371 25.738 66.1919 26.1193 66.3657 26.539C66.5396 26.9587 66.629 27.4086 66.629 27.8629V42.8091C66.629 43.1626 66.4886 43.5016 66.2387 43.7515C65.9887 44.0015 65.6497 44.1419 65.2962 44.1419H58.927V27.8629C58.927 26.9454 59.2915 26.0655 59.9402 25.4168C60.589 24.768 61.4688 24.4036 62.3863 24.4036Z"
            fill="url(#iconpapebill-paint1_linear)"
          />
          <path
            d="M73.5581 41.2522H22.4419C21.0933 41.2522 20 42.3455 20 43.6941V59.9731C20 61.3217 21.0933 62.415 22.4419 62.415H73.5581C74.9067 62.415 76 61.3217 76 59.9731V43.6941C76 42.3455 74.9067 41.2522 73.5581 41.2522Z"
            fill="#3176DE"
          />
          <path
            d="M56.8209 50.7348C58.0571 50.7348 59.0593 49.7327 59.0593 48.4964C59.0593 47.2602 58.0571 46.2581 56.8209 46.2581C55.5847 46.2581 54.5825 47.2602 54.5825 48.4964C54.5825 49.7327 55.5847 50.7348 56.8209 50.7348Z"
            fill="#F65B7D"
          />
          <path
            d="M70.6368 52.1912C71.8074 52.1912 72.7564 51.2422 72.7564 50.0716C72.7564 48.9009 71.8074 47.9519 70.6368 47.9519C69.4661 47.9519 68.5171 48.9009 68.5171 50.0716C68.5171 51.2422 69.4661 52.1912 70.6368 52.1912Z"
            fill="#F65B7D"
          />
          <path
            d="M64.042 52.1912C65.2127 52.1912 66.1617 51.2422 66.1617 50.0716C66.1617 48.9009 65.2127 47.9519 64.042 47.9519C62.8714 47.9519 61.9224 48.9009 61.9224 50.0716C61.9224 51.2422 62.8714 52.1912 64.042 52.1912Z"
            fill="#F65B7D"
          />
          <path
            d="M59.0592 28.4631V27.934C59.1041 26.9551 59.5352 26.0338 60.2579 25.3721C60.9807 24.7104 61.9363 24.362 62.9153 24.4035H32.2802C28.9938 24.4035 26.3281 27.4558 26.3281 31.1796V65.3962L29.7874 70.1578C29.8604 70.2547 29.955 70.3334 30.0636 70.3875C30.1721 70.4417 30.2918 70.4698 30.4132 70.4698C30.5345 70.4698 30.6542 70.4417 30.7628 70.3875C30.8713 70.3334 30.9659 70.2547 31.0389 70.1578L34.5084 65.3962L37.9778 70.1578C38.0508 70.2547 38.1454 70.3334 38.254 70.3875C38.3625 70.4417 38.4822 70.4698 38.6036 70.4698C38.7249 70.4698 38.8446 70.4417 38.9532 70.3875C39.0617 70.3334 39.1563 70.2547 39.2293 70.1578L42.6886 65.3962L46.1581 70.1578C46.23 70.2546 46.3236 70.3333 46.4314 70.3874C46.5391 70.4416 46.6581 70.4698 46.7787 70.4698C46.8993 70.4698 47.0183 70.4416 47.126 70.3874C47.2338 70.3333 47.3274 70.2546 47.3993 70.1578L50.8688 65.3962L54.3383 70.1578C54.4102 70.2546 54.5038 70.3333 54.6116 70.3874C54.7194 70.4416 54.8383 70.4698 54.9589 70.4698C55.0796 70.4698 55.1985 70.4416 55.3063 70.3874C55.414 70.3333 55.5076 70.2546 55.5796 70.1578L59.0491 65.3962V28.4631H59.0592Z"
            fill="url(#iconpapebill-paint2_linear)"
          />
          <path
            d="M50.8794 43.3584H32.25C31.8736 43.3584 31.5684 43.6636 31.5684 44.0401V44.1724C31.5684 44.5488 31.8736 44.854 32.25 44.854H50.8794C51.2559 44.854 51.5611 44.5488 51.5611 44.1724V44.0401C51.5611 43.6636 51.2559 43.3584 50.8794 43.3584Z"
            fill="white"
          />
          <path
            d="M50.8794 38.9426H32.25C31.8736 38.9426 31.5684 39.2478 31.5684 39.6243V39.7566C31.5684 40.1331 31.8736 40.4383 32.25 40.4383H50.8794C51.2559 40.4383 51.5611 40.1331 51.5611 39.7566V39.6243C51.5611 39.2478 51.2559 38.9426 50.8794 38.9426Z"
            fill="white"
          />
          <path
            d="M50.8794 47.7639H32.25C31.8736 47.7639 31.5684 48.0691 31.5684 48.4456V48.5779C31.5684 48.9543 31.8736 49.2595 32.25 49.2595H50.8794C51.2559 49.2595 51.5611 48.9543 51.5611 48.5779V48.4456C51.5611 48.0691 51.2559 47.7639 50.8794 47.7639Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M60.1785 41.2522H59.0593V62.415H60.1785V41.2522Z"
            fill="#5B696B"
          />
        </g>
        <path
          d="M58.9324 28.4382V27.9123C58.977 26.9393 59.4054 26.0237 60.1238 25.366C60.8422 24.7082 61.792 24.362 62.7651 24.4032H32.3158C29.0494 24.4032 26.3999 27.437 26.3999 31.1382V65.1472L29.8382 69.8799C29.9108 69.9762 30.0047 70.0544 30.1127 70.1082C30.2206 70.162 30.3395 70.19 30.4601 70.19C30.5807 70.19 30.6997 70.162 30.8076 70.1082C30.9155 70.0544 31.0095 69.9762 31.0821 69.8799L34.5305 65.1472L37.9789 69.8799C38.0515 69.9762 38.1454 70.0544 38.2534 70.1082C38.3613 70.162 38.4802 70.19 38.6008 70.19C38.7214 70.19 38.8404 70.162 38.9483 70.1082C39.0563 70.0544 39.1502 69.9762 39.2228 69.8799L42.6611 65.1472L46.1095 69.8799C46.181 69.9761 46.274 70.0543 46.3812 70.1081C46.4883 70.162 46.6065 70.19 46.7264 70.19C46.8463 70.19 46.9645 70.162 47.0716 70.1081C47.1787 70.0543 47.2717 69.9761 47.3433 69.8799L50.7917 65.1472L54.2401 69.8799C54.3116 69.9761 54.4046 70.0543 54.5117 70.1081C54.6189 70.162 54.7371 70.19 54.857 70.19C54.9769 70.19 55.0951 70.162 55.2022 70.1081C55.3093 70.0543 55.4023 69.9761 55.4738 69.8799L58.9223 65.1472V28.4382H58.9324Z"
          fill="url(#iconpapebill-paint3_linear)"
        />
        <path
          d="M50.802 43.2432H32.2857C31.9115 43.2432 31.6082 43.5465 31.6082 43.9207V44.0522C31.6082 44.4264 31.9115 44.7297 32.2857 44.7297H50.802C51.1762 44.7297 51.4796 44.4264 51.4796 44.0522V43.9207C51.4796 43.5465 51.1762 43.2432 50.802 43.2432Z"
          fill="white"
        />
        <path
          d="M50.802 38.8542H32.2857C31.9115 38.8542 31.6082 39.1576 31.6082 39.5318V39.6633C31.6082 40.0375 31.9115 40.3408 32.2857 40.3408H50.802C51.1762 40.3408 51.4796 40.0375 51.4796 39.6633V39.5318C51.4796 39.1576 51.1762 38.8542 50.802 38.8542Z"
          fill="white"
        />
        <path
          d="M50.802 47.6218H32.2857C31.9115 47.6218 31.6082 47.9252 31.6082 48.2994V48.4308C31.6082 48.805 31.9115 49.1084 32.2857 49.1084H50.802C51.1762 49.1084 51.4796 48.805 51.4796 48.4308V48.2994C51.4796 47.9252 51.1762 47.6218 50.802 47.6218Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="iconpapebill-paint0_linear"
            x1="70.9516"
            y1="60.0664"
            x2="43.9806"
            y2="42.1477"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#508BBC" />
            <stop offset="1" stopColor="#2D69C1" />
          </linearGradient>
          <linearGradient
            id="iconpapebill-paint1_linear"
            x1="56.6785"
            y1="31.8919"
            x2="68.7555"
            y2="37.9152"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C0CACD" />
            <stop offset="0.95" stopColor="#E8ECEF" />
          </linearGradient>
          <linearGradient
            id="iconpapebill-paint2_linear"
            x1="30.8338"
            y1="24.4001"
            x2="60.5092"
            y2="70.5619"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FCFEFF" />
            <stop offset="1" stopColor="#CED5DC" />
          </linearGradient>
          <linearGradient
            id="iconpapebill-paint3_linear"
            x1="30.8782"
            y1="24.3999"
            x2="60.3735"
            y2="70.2815"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F0F4F6" />
            <stop offset="1" stopColor="#C4CDD6" />
          </linearGradient>
        </defs>
      </g>
    )
  }
}

export default PaperBillIcon
