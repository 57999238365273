import React from 'react'

const DuvalTriangle = () => {
  return (
    <g className="illus-duval-triangle">
      <mask
        id="duval-triangle-mask0"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="197"
        height="171"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M98.5 0L197 171H0L98.5 0Z"
          fill="white"
        />
      </mask>
      <g mask="url(#duval-triangle-mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M109.821 54.2195L130.77 92.3855L116.394 117.431L151.501 177.223H39.3999L109.821 54.2195Z"
          fill="#00B0B2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M85.7185 15.6272L110.473 58.0734L42.1959 177.509H-7.31299L85.7185 15.6272Z"
          fill="#3384E1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M91.9956 2.50188L144.831 94.8241L136.103 111.081L171.132 173.821H140.971L108.608 117.512L124.774 90.7047L79.6206 11.732L91.9956 2.50188Z"
          fill="#48DCC0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M119.535 30.5906L113.176 43.2298L145.622 99.4956L151.877 86.7741L119.535 30.5906Z"
          fill="#FF8B00"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M149.232 82.5801L132.975 111.782L170.202 177.483H204.974L149.232 82.5801Z"
          fill="#E54545"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M119.535 30.589L113.176 43.2283L90.5273 3.89795L97.603 -8.82359L119.535 30.589Z"
          fill="#FFE75A"
        />
        <path
          d="M105.887 -4.16982H88.6499V3.33751H105.887V-4.16982Z"
          fill="#9A33E1"
        />
        <path d="M114.28 39.0121L118.28 32.0121" stroke="white" />
        <path d="M133.975 73L137.975 66" stroke="white" />
        <path d="M153.14 108.008L157.14 101.008" stroke="white" />
        <path d="M173.375 142L177.375 135" stroke="white" />
        <path d="M35 165L39 172" stroke="white" strokeLinecap="square" />
        <path d="M74 165L78 172" stroke="white" strokeLinecap="square" />
        <path d="M113 165L117 171.9" stroke="white" strokeLinecap="square" />
        <path d="M153 165L157 172" stroke="white" strokeLinecap="square" />
        <path d="M18.9314 137H25.9314" stroke="white" strokeLinecap="square" />
        <path
          d="M38.6311 103.002H45.6311"
          stroke="white"
          strokeLinecap="square"
        />
        <path
          d="M58.3313 68.9977H65.3313"
          stroke="white"
          strokeLinecap="square"
        />
        <path d="M78.031 34.5H85.031" stroke="white" strokeLinecap="square" />
      </g>
    </g>
  )
}

export default DuvalTriangle
