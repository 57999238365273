import React from 'react'

class PaperBill extends React.PureComponent {
  render() {
    return (
      <g className="illus-paper-bill">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M188.717 33.5897C154.774 16.9097 114.548 23.2767 77.3511 30.1127C68.8121 31.6827 60.1201 33.3097 52.4481 37.3727C32.8361 47.7597 24.1491 73.2357 29.6001 94.7477C35.0511 116.261 52.3451 133.42 72.4011 142.922C92.4571 152.424 115.077 155.117 137.268 155.427C153.289 155.651 170.255 154.402 183.459 145.324C198.335 135.099 205.528 116.921 209.525 99.3177C212.351 86.8747 213.988 73.8467 211.197 61.3957C208.406 48.9447 200.488 38.5157 188.717 33.5897Z"
          fill="#F8FCFF"
        />
        <mask
          id="paper-bill-mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="28"
          y="24"
          width="185"
          height="133"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M188.717 34.5897C154.774 17.9097 114.548 24.2767 77.3511 31.1127C68.8121 32.6827 60.1201 34.3097 52.4481 38.3727C32.8361 48.7597 24.1491 74.2357 29.6001 95.7477C35.0511 117.261 52.3451 134.42 72.4011 143.922C92.4571 153.424 115.077 156.117 137.268 156.427C153.289 156.651 170.255 155.402 183.459 146.324C198.335 136.099 205.528 117.921 209.525 100.318C212.351 87.8747 213.988 74.8467 211.197 62.3957C208.406 49.9447 200.488 39.5157 188.717 34.5897Z"
            fill="#F8FCFF"
          />
        </mask>
        <g mask="url(#paper-bill-mask0)">
          <path
            d="M176.213 94.2021H63.7599C60.3884 94.2021 57.6553 96.9353 57.6553 100.307V122.945C57.6553 126.316 60.3884 129.05 63.7599 129.05H176.213C179.584 129.05 182.317 126.316 182.317 122.945V100.307C182.317 96.9353 179.584 94.2021 176.213 94.2021Z"
            fill="url(#paper-bill-paint0_linear)"
          />
          <path
            d="M155.966 61.0088H157.924C159.06 61.0088 160.184 61.2325 161.234 61.6671C162.283 62.1017 163.236 62.7387 164.039 63.5418C164.843 64.3449 165.48 65.2982 165.914 66.3475C166.349 67.3968 166.572 68.5213 166.572 69.657V107.023C166.572 107.906 166.221 108.754 165.597 109.379C164.972 110.004 164.124 110.355 163.24 110.355H147.317V69.657C147.317 67.3634 148.229 65.1637 149.85 63.5418C151.472 61.9199 153.672 61.0088 155.966 61.0088Z"
            fill="url(#paper-bill-paint1_linear)"
          />
          <path
            d="M183.895 103.13H56.1047C52.7331 103.13 50 105.863 50 109.235V149.932C50 153.304 52.7331 156.037 56.1047 156.037H183.895C187.267 156.037 190 153.304 190 149.932V109.235C190 105.863 187.267 103.13 183.895 103.13Z"
            fill="#3176DE"
          />
          <path
            d="M142.052 126.836C145.143 126.836 147.648 124.331 147.648 121.24C147.648 118.15 145.143 115.645 142.052 115.645C138.961 115.645 136.456 118.15 136.456 121.24C136.456 124.331 138.961 126.836 142.052 126.836Z"
            fill="#F65B7D"
          />
          <path
            d="M176.592 130.478C179.519 130.478 181.891 128.106 181.891 125.179C181.891 122.252 179.519 119.88 176.592 119.88C173.665 119.88 171.293 122.252 171.293 125.179C171.293 128.106 173.665 130.478 176.592 130.478Z"
            fill="#F65B7D"
          />
          <path
            d="M160.105 130.478C163.031 130.478 165.404 128.106 165.404 125.179C165.404 122.252 163.031 119.88 160.105 119.88C157.178 119.88 154.806 122.252 154.806 125.179C154.806 128.106 157.178 130.478 160.105 130.478Z"
            fill="#F65B7D"
          />
          <path
            d="M147.648 71.1573V69.8346C147.76 67.3873 148.838 65.0842 150.645 63.4299C152.452 61.7755 154.841 60.9048 157.288 61.0083H80.7004C72.4846 61.0083 65.8203 68.6391 65.8203 77.9487V163.49L74.4686 175.394C74.6511 175.636 74.8874 175.833 75.1589 175.968C75.4303 176.104 75.7295 176.174 76.0329 176.174C76.3362 176.174 76.6354 176.104 76.9069 175.968C77.1784 175.833 77.4147 175.636 77.5972 175.394L86.2709 163.49L94.9446 175.394C95.1271 175.636 95.3634 175.833 95.6349 175.968C95.9064 176.104 96.2056 176.174 96.5089 176.174C96.8122 176.174 97.1114 176.104 97.3829 175.968C97.6544 175.833 97.8907 175.636 98.0732 175.394L106.721 163.49L115.395 175.394C115.575 175.636 115.809 175.833 116.078 175.968C116.348 176.104 116.645 176.174 116.947 176.174C117.248 176.174 117.546 176.104 117.815 175.968C118.085 175.833 118.318 175.636 118.498 175.394L127.172 163.49L135.846 175.394C136.026 175.636 136.26 175.833 136.529 175.968C136.798 176.104 137.096 176.174 137.397 176.174C137.699 176.174 137.996 176.104 138.266 175.968C138.535 175.833 138.769 175.636 138.949 175.394L147.623 163.49V71.1573H147.648Z"
            fill="url(#paper-bill-paint2_linear)"
          />
          <path
            d="M127.199 108.396H80.6251C79.6839 108.396 78.9209 109.159 78.9209 110.1V110.43C78.9209 111.372 79.6839 112.135 80.6251 112.135H127.199C128.14 112.135 128.903 111.372 128.903 110.43V110.1C128.903 109.159 128.14 108.396 127.199 108.396Z"
            fill="white"
          />
          <path
            d="M127.199 97.3564H80.6251C79.6839 97.3564 78.9209 98.1194 78.9209 99.0607V99.3913C78.9209 100.333 79.6839 101.096 80.6251 101.096H127.199C128.14 101.096 128.903 100.333 128.903 99.3913V99.0607C128.903 98.1194 128.14 97.3564 127.199 97.3564Z"
            fill="white"
          />
          <path
            d="M127.199 119.409H80.6251C79.6839 119.409 78.9209 120.172 78.9209 121.113V121.444C78.9209 122.385 79.6839 123.148 80.6251 123.148H127.199C128.14 123.148 128.903 122.385 128.903 121.444V121.113C128.903 120.172 128.14 119.409 127.199 119.409Z"
            fill="white"
          />
          <path
            opacity="0.2"
            d="M150.446 103.13H147.648V156.037H150.446V103.13Z"
            fill="#5B696B"
          />
        </g>
        <path
          d="M147.331 71.0956V69.781C147.443 67.3485 148.514 65.0595 150.31 63.4151C152.106 61.7708 154.48 60.9053 156.913 61.0082H80.7898C72.6238 61.0082 66 68.5927 66 77.8458V162.868L74.5958 174.7C74.7772 174.941 75.0121 175.136 75.2819 175.271C75.5517 175.405 75.8491 175.475 76.1506 175.475C76.4521 175.475 76.7495 175.405 77.0193 175.271C77.2891 175.136 77.524 174.941 77.7054 174.7L86.3265 162.868L94.9475 174.7C95.129 174.941 95.3638 175.136 95.6337 175.271C95.9035 175.405 96.2009 175.475 96.5024 175.475C96.8039 175.475 97.1012 175.405 97.3711 175.271C97.6409 175.136 97.8758 174.941 98.0572 174.7L106.653 162.868L115.274 174.7C115.453 174.941 115.685 175.136 115.953 175.271C116.221 175.405 116.516 175.475 116.816 175.475C117.116 175.475 117.411 175.405 117.679 175.271C117.947 175.136 118.18 174.941 118.358 174.7L126.979 162.868L135.6 174.7C135.779 174.941 136.012 175.136 136.28 175.271C136.547 175.405 136.843 175.475 137.143 175.475C137.442 175.475 137.738 175.405 138.006 175.271C138.274 175.136 138.506 174.941 138.685 174.7L147.306 162.868V71.0956H147.331Z"
          fill="url(#paper-bill-paint3_linear)"
        />
        <path
          d="M127.005 108.108H80.7144C79.7789 108.108 79.0205 108.867 79.0205 109.802V110.131C79.0205 111.066 79.7789 111.825 80.7144 111.825H127.005C127.941 111.825 128.699 111.066 128.699 110.131V109.802C128.699 108.867 127.941 108.108 127.005 108.108Z"
          fill="white"
        />
        <path
          d="M127.005 97.1357H80.7144C79.7789 97.1357 79.0205 97.8941 79.0205 98.8296V99.1583C79.0205 100.094 79.7789 100.852 80.7144 100.852H127.005C127.941 100.852 128.699 100.094 128.699 99.1583V98.8296C128.699 97.8941 127.941 97.1357 127.005 97.1357Z"
          fill="white"
        />
        <path
          d="M127.005 119.055H80.7144C79.7789 119.055 79.0205 119.813 79.0205 120.749V121.077C79.0205 122.013 79.7789 122.771 80.7144 122.771H127.005C127.941 122.771 128.699 122.013 128.699 121.077V120.749C128.699 119.813 127.941 119.055 127.005 119.055Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paper-bill-paint0_linear"
            x1="177.379"
            y1="150.165"
            x2="109.952"
            y2="105.369"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#508BBC" />
            <stop offset="1" stopColor="#2D69C1" />
          </linearGradient>
          <linearGradient
            id="paper-bill-paint1_linear"
            x1="141.696"
            y1="79.7297"
            x2="171.889"
            y2="94.7879"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#C0CACD" />
            <stop offset="0.95" stopColor="#E8ECEF" />
          </linearGradient>
          <linearGradient
            id="paper-bill-paint2_linear"
            x1="77.0845"
            y1="61"
            x2="151.273"
            y2="176.404"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FCFEFF" />
            <stop offset="1" stopColor="#CED5DC" />
          </linearGradient>
          <linearGradient
            id="paper-bill-paint3_linear"
            x1="77.1958"
            y1="61"
            x2="150.934"
            y2="175.704"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F0F4F6" />
            <stop offset="1" stopColor="#C4CDD6" />
          </linearGradient>
        </defs>
      </g>
    )
  }
}

export default PaperBill
