import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './styles.scss'

import PaginationOffset from './PaginationOffset'
import Icons from '../Icons'
import { withTheme } from '../Theme'

class Pagination extends React.PureComponent {
  static getDerivedStateFromProps(props, state) {
    if (props.index !== state.index) {
      if (props.onPageChange) {
        return {
          index: props.index,
        }
      }
    }
    return null
  }

  state = {
    index: this.props.index,
  }

  previousPage = () => {
    if (this.state.index > 1) {
      const { onPageChange } = this.props
      if (onPageChange) {
        onPageChange(this.state.index - 1)
      } else {
        this.setState(prevState => ({
          ...prevState,
          index: this.state.index - 1,
        }))
      }
    }
  }

  nextPage = () => {
    const { total } = this.props
    if (this.state.index < total) {
      const { onPageChange } = this.props
      if (onPageChange) {
        onPageChange(this.state.index + 1)
      } else {
        this.setState(prevState => ({
          ...prevState,
          index: this.state.index + 1,
        }))
      }
    }
  }

  handleChange = index => e => {
    this.setState(prevState => ({
      ...prevState,
      index: index,
    }))
    const { onPageChange } = this.props
    if (onPageChange) {
      onPageChange(index)
    }
  }

  renderPageNums = () => {
    const { children, total } = this.props
    let offsetIndex, offset
    React.Children.map(children, (child, childIdx) => {
      if (child.type === PaginationOffset) {
        offset = child
        offsetIndex = child.props.offsetIndex
      }
    })

    var pageNum = []
    for (let i = 1; i <= total; i++) {
      if (
        // for front ellipse
        this.state.index > offsetIndex && // current index have to be greater than offset index
        i + 1 < this.state.index - offsetIndex //  i + 1 has to be lesser than current index - offsetIndex (+1 to 2,3)
      ) {
        i = this.state.index - offsetIndex
        pageNum.push(
          <button onClick={this.handleChange(1)} key={`index-${1}`}>
            1
          </button>
        )
        pageNum.push(offset)
      } else if (
        // for back ellipse
        total - this.state.index - 1 > offsetIndex && // if total minus current index -1 is > offset, dont need place ellipse (-1 is for the last two pages)
        i >= offsetIndex + this.state.index // always maintain offsetIndex infront of current index & place ellipse
      ) {
        pageNum.push(offset)
        pageNum.push(
          <button onClick={this.handleChange(total)} key={`index-${total}`}>
            {total}
          </button>
        )
        break
      } else if (i === this.state.index) {
        pageNum.push(
          <button
            className="active"
            onClick={this.handleChange(i)}
            key={`index-${i}`}
          >
            {i}
          </button>
        )
      } else {
        pageNum.push(
          <button onClick={this.handleChange(i)} key={`index-${i}`}>
            {i}
          </button>
        )
      }
    }
    return pageNum
  }

  render() {
    const { theme } = this.props
    const _Pagination = classnames('lm--pagination cui--pagination')
    return (
      <>
        <div
          className={_Pagination}
          id={_Pagination}
          style={{
            '--cuiPaginationBg': theme.colors.background.level1,
            '--cuiPaginationShadow': theme.colors.boxShadow,
            '--cuiPaginationText': theme.colors.text.secondary,
            '--cuiPaginationPrimary': theme.colors.primary.main,
            '--cuiPaginationDisabledText': theme.colors.text.disabled,
          }}
        >
          <button
            onClick={this.previousPage}
            data-testid="left-chevron"
            className={classnames({ disabled: this.state.index < 2 })}
          >
            <Icons.ChevronLeft
              className="lm--icon cui--icon"
              width={16}
              height={16}
              color={[theme.colors.text.secondary]}
            />
          </button>
          {this.renderPageNums()}
          <button
            onClick={this.nextPage}
            data-testid="right-chevron"
            className={classnames({
              disabled: this.state.index >= this.props.total,
            })}
          >
            <Icons.ChevronRight
              className="lm--icon cui--icon"
              width={16}
              height={16}
              color={[theme.colors.text.secondary]}
            />
          </button>
        </div>
      </>
    )
  }
}

Pagination.propTypes = {
  total: PropTypes.number,
  index: PropTypes.number,
  onPageChange: PropTypes.func,
}

Pagination.defaultProps = {
  total: 10,
  index: 1,
}

const PaginationWithTheme = withTheme(Pagination)

PaginationWithTheme.Offset = PaginationOffset

export default PaginationWithTheme
