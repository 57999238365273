import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTheme } from '../Theme'

const ButtonsContainer = props => {
  const theme = useTheme()
  const _ButtonsContainer = classnames('cui-button-container', {
    'rounded-btns': props.rounded,
  })
  return (
    <div
      className={_ButtonsContainer}
      style={{
        '--cuiButtonContainerBorder': theme.colors.background.level1,
      }}
    >
      {props.children}
    </div>
  )
}

ButtonsContainer.propTypes = {
  rounded: PropTypes.bool,
}

ButtonsContainer.defaultProps = {
  rounded: false,
}

export default ButtonsContainer
