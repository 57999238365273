import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Tooltip from '../Tooltip/Tooltip'

const BrandItem = ({ isActive, onClick, logo, activeLogo, name }) => {
  return (
    <div
      className={classnames('cui-brand__logo', {
        active: isActive,
      })}
      onClick={onClick}
    >
      <Tooltip
        label={isActive ? activeLogo : logo}
        withIcon={false}
        isHover
        alignY="bottom"
        offset={{ y: -10 }}
      >
        {name}
      </Tooltip>
    </div>
  )
}

BrandItem.propTypes = {
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
  logo: PropTypes.node.isRequired,
  activeLogo: PropTypes.node.isRequired,
  name: PropTypes.node.isRequired,
}

export default BrandItem
