import React from 'react'
import PropTypes from 'prop-types'

import Typo from '../../Typo'

const FormReceiptItem = ({ label, children, ...props }) => {
  return (
    <div className="cui-receipt_item" {...props}>
      {!!label && (
        <div className="cui-receipt_item__label">
          <Typo size="small" weight={500} allCaps>
            {label}
          </Typo>
        </div>
      )}
      <div className="cui-receipt_item__content">{children}</div>
    </div>
  )
}

FormReceiptItem.defaultProps = {
  label: '',
}

FormReceiptItem.propTypes = {
  children: PropTypes.PropTypes.any([PropTypes.object, PropTypes.string]),
}

export default FormReceiptItem
