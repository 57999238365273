import React from 'react'

class CheckedIcon extends React.PureComponent {
  render() {
    return (
      <g
        className="checked-search"
        stroke="none"
        strokeWidth="1"
        fillRule="evenodd"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 10C0 15.5228 4.47715 20 10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10ZM9.70711 13.7071L15.7071 7.70711C16.0976 7.31658 16.0976 6.68342 15.7071 6.29289C15.3166 5.90237 14.6834 5.90237 14.2929 6.29289L9 11.5858L6.70711 9.29289C6.31658 8.90237 5.68342 8.90237 5.29289 9.29289C4.90237 9.68342 4.90237 10.3166 5.29289 10.7071L8.29289 13.7071C8.68342 14.0976 9.31658 14.0976 9.70711 13.7071Z"
          fill="#00B0B2"
        />
      </g>
    )
  }
}

export default CheckedIcon
