import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Typo from '../Typo'

const ChartLegendsItem = ({ field, color, value, id }) => {
  const _ChartLegendsItem = classnames('cui-chart__legends--item')
  const _ChartLegendsItemId = id
    ? 'chart-legend-item-id-' + id
    : 'chart-legend-item-id'
  // color taken from contextconsumers
  const getType = () => {
    switch (field) {
      case 'circle':
        return <circle cx="50%" cy="50%" r="4" fill={color} />
      case 'ellipse':
        return <ellipse cx="50%" cy="50%" rx="4" ry="2" fill={color} />
      case 'square':
        return <rect width="8" height="8" fill={color} />
      case 'dashed':
        return (
          <line
            x1="0"
            y1="4"
            x2="14"
            y2="4"
            stroke={color}
            strokeWidth={1}
            strokeDasharray="3 1"
          />
        )
      default:
        return (
          <line x1="0" y1="4" x2="8" y2="4" stroke={color} strokeWidth={1} />
        )
    }
  }
  return (
    <div className={_ChartLegendsItem} id={_ChartLegendsItemId}>
      <svg className="cui-chart__legends--item-svg">{getType()}</svg>{' '}
      <Typo variant="caption" element="span">
        {value}
      </Typo>
    </div>
  )
}

ChartLegendsItem.propTypes = {
  field: PropTypes.string,
  color: PropTypes.string,
  value: PropTypes.string,
}

export default ChartLegendsItem
