import React from 'react'

const Transformer = () => {
  return (
    <g className="illus-transformer">
      <rect y="10.8477" width="19.6741" height="37.9661" fill="#819090" />
      <rect
        width="5.42735"
        height="42.0339"
        transform="matrix(1 0 0 -1 55.6304 78.6445)"
        fill="#819090"
      />
      <rect
        width="5.42735"
        height="4.06779"
        transform="matrix(1 0 0 -1 55.6304 78.6445)"
        fill="#505B54"
      />
      <rect
        x="21.0308"
        y="4.74609"
        width="34.5994"
        height="73.8983"
        fill="#8E9898"
      />
      <rect
        width="1.35684"
        height="73.8983"
        transform="matrix(-1 0 0 1 21.0308 4.74609)"
        fill="#A0AAAA"
      />
      <rect
        x="54.2734"
        y="4.74609"
        width="1.35685"
        height="73.8983"
        fill="#A0AAAA"
      />
      <rect
        x="55.6304"
        y="6.7793"
        width="8.14103"
        height="43.3898"
        fill="#505B54"
      />
      <path
        d="M44.7247 18.8141C44.5476 18.4994 44.2145 18.3047 43.8533 18.3047H38.9139C38.5527 18.3047 38.2196 18.4994 38.0425 18.8141L35.5538 23.2351C35.3825 23.5396 35.3824 23.9113 35.5536 24.2158L38.0426 28.6423C38.2196 28.9572 38.5529 29.1521 38.9142 29.1521H43.853C44.2143 29.1521 44.5476 28.9572 44.7247 28.6423L47.2136 24.2158C47.3848 23.9113 47.3848 23.5396 47.2134 23.2351L44.7247 18.8141Z"
        fill="#9FA9AA"
      />
      <rect
        x="55.6304"
        y="6.7793"
        width="8.14103"
        height="29.8305"
        fill="#819090"
      />
      <rect
        x="27.8149"
        y="55.5928"
        width="9.49152"
        height="1.35684"
        transform="rotate(90 27.8149 55.5928)"
        fill="#9FA9AA"
      />
      <rect
        width="1.35683"
        height="44.0678"
        transform="matrix(-1 0 0 1 65.1284 6.7793)"
        fill="#65726A"
      />
      <rect
        width="1.35683"
        height="30.5085"
        transform="matrix(-1 0 0 1 65.1284 6.10156)"
        fill="#96A7A7"
      />
      <rect
        x="55.6304"
        y="33.8984"
        width="2.03526"
        height="2.71186"
        fill="#313933"
      />
      <rect
        x="55.6304"
        y="64.4072"
        width="2.71367"
        height="4.06779"
        fill="#8E9898"
      />
      <rect
        x="55.6304"
        y="65.085"
        width="2.03526"
        height="2.71187"
        fill="#505B54"
      />
      <rect
        x="50.8813"
        y="65.7627"
        width="5.42735"
        height="1.35593"
        fill="#9FA9AA"
      />
      <rect
        x="37.9912"
        y="35.2539"
        width="6.10577"
        height="11.5254"
        rx="1"
        fill="#AFA698"
      />
      <ellipse
        cx="44.7759"
        cy="23.7287"
        rx="3.39209"
        ry="3.38983"
        fill="#B1BBBC"
      />
      <ellipse
        cx="44.7758"
        cy="23.7286"
        rx="2.77535"
        ry="2.7735"
        fill="#7B6F5C"
      />
      <path
        d="M47.8598 23.7285H41.6924C41.6924 25.5775 43.3576 27.0567 45.0845 26.8102C46.8114 26.5636 47.9626 24.5503 47.8598 23.7285Z"
        fill="#B1BBBC"
      />
      <ellipse
        cx="44.7757"
        cy="23.7292"
        rx="0.925117"
        ry="0.9245"
        fill="#C2D4D6"
      />
      <rect
        x="31.8857"
        y="10.8477"
        width="4.74893"
        height="4.74576"
        rx="1"
        fill="#9FA9AA"
      />
      <rect
        width="2.03526"
        height="0.677965"
        transform="matrix(1 0 0 -1 33.2427 16.2715)"
        fill="#B6AE94"
      />
      <ellipse
        cx="29.1721"
        cy="13.559"
        rx="4.07052"
        ry="4.0678"
        fill="#B1BBBC"
      />
      <ellipse
        cx="29.1719"
        cy="13.5588"
        rx="3.39209"
        ry="3.38983"
        fill="#635D5E"
      />
      <ellipse
        cx="29.1721"
        cy="13.5588"
        rx="0.67842"
        ry="0.677967"
        fill="#C4C4C4"
      />
      <rect
        x="28.833"
        y="10.8467"
        width="0.678417"
        height="2.71186"
        rx="0.339209"
        fill="#C4C4C4"
      />
      <rect
        width="0.678157"
        height="1.68291"
        rx="0.339079"
        transform="matrix(0.649019 0.760773 -0.761201 0.648516 31.5464 12.2031)"
        fill="#C7883B"
      />
      <rect
        width="0.678157"
        height="1.68291"
        rx="0.339078"
        transform="matrix(-0.649018 0.760773 0.7612 0.648517 26.8984 12.2031)"
        fill="#C7883B"
      />
      <ellipse
        cx="43.4187"
        cy="19.6614"
        rx="0.678417"
        ry="0.677967"
        fill="#849799"
      />
      <ellipse
        cx="39.3483"
        cy="19.6614"
        rx="0.678417"
        ry="0.677967"
        fill="#849799"
      />
      <ellipse
        cx="37.3132"
        cy="23.7287"
        rx="0.678415"
        ry="0.677965"
        fill="#849799"
      />
      <ellipse
        cx="39.3483"
        cy="27.7961"
        rx="0.678417"
        ry="0.677966"
        fill="#849799"
      />
      <ellipse
        cx="43.4187"
        cy="27.7961"
        rx="0.678417"
        ry="0.677966"
        fill="#849799"
      />
      <path
        d="M27.0238 33.0246C29.0851 31.7837 28.3978 24.4072 28.3978 24.4072H29.4287C29.4287 24.4072 29.6401 31.0572 28.7416 32.6799C27.0238 35.7822 23.3904 32.4868 22.2141 35.4375C21.527 37.161 21.8705 57.1533 21.8705 57.1533C21.8705 59.2215 25.3057 62.064 29.0849 62.1514C31.9138 62.2169 34.2384 60.945 35.956 58.8768V59.9109C34.9256 61.2897 32.5639 63.3622 29.0849 63.0132C24.7621 62.5795 21.1833 60.6003 21.1834 56.4639C21.1834 56.4639 20.84 35.7822 21.1833 35.0928C22.7284 31.9905 24.9625 34.2655 27.0238 33.0246Z"
        fill="#505354"
      />
      <rect
        width="2.03526"
        height="1.35593"
        transform="matrix(1 0 0 -1 27.8149 25.084)"
        fill="#B6AE94"
      />
      <ellipse
        cx="28.8327"
        cy="21.3555"
        rx="3.05289"
        ry="3.05085"
        fill="#B1BBBC"
      />
      <ellipse
        cx="28.8326"
        cy="21.3559"
        rx="2.54407"
        ry="2.54237"
        fill="#635D5E"
      />
      <ellipse
        cx="28.8325"
        cy="21.3552"
        rx="0.508814"
        ry="0.508475"
        fill="#C4C4C4"
      />
      <rect
        x="28.5781"
        y="19.3213"
        width="0.508814"
        height="2.0339"
        rx="0.254407"
        fill="#C4C4C4"
      />
      <rect
        width="0.508617"
        height="1.26218"
        rx="0.254308"
        transform="matrix(0.649016 0.760774 -0.761199 0.648519 30.6133 20.3389)"
        fill="#C7883B"
      />
      <rect
        width="0.508617"
        height="1.26218"
        rx="0.254308"
        transform="matrix(-0.649017 0.760774 0.761199 0.648518 27.1274 20.3389)"
        fill="#C7883B"
      />
      <path
        d="M32.6526 30.5088C33.4759 28.5733 33.6259 21.3562 33.6259 16.2715H34.5993C34.4912 20.9043 34.3105 29.9142 32.977 31.8647C31.3547 34.2376 28.6509 33.7856 27.1367 33.8986V33.2206C28.4346 33.2206 31.3547 33.5596 32.6526 30.5088Z"
        fill="#505354"
      />
      <path
        d="M53.9345 8.13565C53.5952 7.45763 52.0123 7.68367 51.5601 7.79667V8.81364H52.5777C53.3091 8.81364 53.2561 9.3785 53.2561 9.49149C53.5953 25.7626 53.5953 52.2033 53.5953 53.5593C53.5953 54.9152 52.5777 54.9152 52.2385 54.9152V55.5932C52.8038 55.7062 53.887 55.7357 54.2737 54.5762C54.6129 53.5593 54.2992 41.0375 54.2737 28.8135C54.2516 18.1941 54.2737 8.81353 53.9345 8.13565Z"
        fill="#505354"
      />
      <rect
        width="2.0339"
        height="1.35683"
        transform="matrix(-4.37405e-08 -1 -1 4.36823e-08 52.2383 9.49121)"
        fill="#B6AE94"
      />
      <ellipse
        cx="48.507"
        cy="8.47467"
        rx="3.05289"
        ry="3.05085"
        fill="#B1BBBC"
      />
      <ellipse
        cx="48.507"
        cy="8.47499"
        rx="2.54407"
        ry="2.54237"
        fill="#635D5E"
      />
      <ellipse
        cx="48.5069"
        cy="8.47429"
        rx="0.508816"
        ry="0.508474"
        fill="#C4C4C4"
      />
      <rect
        x="48.2524"
        y="6.44043"
        width="0.508816"
        height="2.0339"
        rx="0.254408"
        fill="#C4C4C4"
      />
      <rect
        width="0.508617"
        height="1.26218"
        rx="0.254309"
        transform="matrix(0.649018 0.760773 -0.7612 0.648517 50.2876 7.45801)"
        fill="#C7883B"
      />
      <rect
        width="0.508616"
        height="1.26218"
        rx="0.254308"
        transform="matrix(-0.649016 0.760775 0.761199 0.648519 46.8018 7.45801)"
        fill="#C7883B"
      />
      <rect
        x="39.3481"
        y="36.6104"
        width="3.39209"
        height="0.677967"
        fill="#7B6F5C"
      />
      <rect
        x="39.3481"
        y="39.3223"
        width="3.39209"
        height="0.677967"
        fill="#7B6F5C"
      />
      <rect
        x="39.3481"
        y="40.6777"
        width="3.39209"
        height="0.677967"
        fill="#7B6F5C"
      />
      <rect
        x="39.3481"
        y="42.0342"
        width="3.39209"
        height="0.677967"
        fill="#7B6F5C"
      />
      <rect
        x="19.6743"
        y="4.74609"
        width="2.03526"
        height="7.45763"
        fill="#B1BBBC"
      />
      <rect
        x="54.2734"
        y="4.74609"
        width="2.03526"
        height="7.45763"
        fill="#B1BBBC"
      />
      <rect
        x="21.7095"
        y="6.10156"
        width="14.9252"
        height="1.35593"
        fill="#B1BBBC"
      />
      <rect
        x="27.1367"
        y="0.677734"
        width="16.9605"
        height="2.0339"
        fill="#B1BBBC"
      />
      <rect
        width="10.1763"
        height="2.0339"
        transform="matrix(1 0 0 -1 30.5288 4.74609)"
        fill="#9EAAAA"
      />
      <rect
        width="10.1763"
        height="0.677967"
        transform="matrix(1 0 0 -1 30.5288 3.38965)"
        fill="#505B54"
      />
      <rect x="28.4937" width="1.35684" height="0.677966" fill="#9EAAAA" />
      <rect x="32.564" width="1.35683" height="0.677966" fill="#9EAAAA" />
      <rect x="37.9912" width="1.35684" height="0.677966" fill="#9EAAAA" />
      <rect x="42.062" width="1.35685" height="0.677966" fill="#9EAAAA" />
      <rect
        x="28.4937"
        y="2.71191"
        width="1.35684"
        height="0.677966"
        fill="#9EAAAA"
      />
      <rect
        x="32.564"
        y="2.71191"
        width="1.35683"
        height="0.677966"
        fill="#9EAAAA"
      />
      <rect
        x="37.9912"
        y="2.71191"
        width="1.35684"
        height="0.677966"
        fill="#9EAAAA"
      />
      <rect
        x="42.062"
        y="2.71191"
        width="1.35685"
        height="0.677966"
        fill="#9EAAAA"
      />
      <rect
        x="57.6655"
        y="8.13574"
        width="4.74892"
        height="3.38983"
        fill="#8E9898"
      />
      <ellipse
        cx="29.8502"
        cy="75.9323"
        rx="2.03526"
        ry="2.0339"
        fill="#C4C4C4"
      />
      <rect
        x="16.2822"
        y="78.6445"
        width="39.3483"
        height="1.35593"
        fill="#505B54"
      />
      <rect
        x="29.1719"
        y="73.2207"
        width="1.35684"
        height="2.03389"
        fill="#B1BBBC"
      />
      <path
        d="M28.4937 75.933C28.4937 75.1836 29.1011 74.5762 29.8505 74.5762C30.5998 74.5762 31.2073 75.1836 31.2073 75.933V78.644H28.4937V75.933Z"
        fill="#9FA9AA"
      />
      <rect
        x="27.1367"
        y="71.8643"
        width="5.42735"
        height="1.35593"
        fill="#E54545"
      />
      <rect
        x="29.1719"
        y="71.8643"
        width="1.35684"
        height="1.35593"
        fill="#F65B7D"
      />
      <rect
        x="27.1367"
        y="71.8643"
        width="1.35684"
        height="1.35593"
        fill="#F65B7D"
      />
      <rect
        x="31.207"
        y="71.8643"
        width="1.35684"
        height="1.35593"
        fill="#F65B7D"
      />
      <rect
        x="21.7095"
        y="65.085"
        width="7.4626"
        height="1.35593"
        fill="#9FA9AA"
      />
      <path
        d="M27.8152 57.6271C23.7447 57.356 22.0487 56.1582 21.7095 55.5932V56.6102C23.0663 57.6271 25.7262 58.3051 28.4937 58.3051C31.8858 58.3051 34.8256 56.6102 35.9563 55.2543V54.2373C34.9386 55.3672 31.8858 57.8983 27.8152 57.6271Z"
        fill="#505354"
      />
      <rect
        x="35.2778"
        y="52.2031"
        width="17.6389"
        height="10.8475"
        rx="1"
        fill="#B2BBBC"
      />
      <ellipse
        cx="50.8815"
        cy="54.2366"
        rx="0.678425"
        ry="0.677967"
        fill="#EABE93"
      />
      <ellipse
        cx="47.4895"
        cy="54.2366"
        rx="0.678417"
        ry="0.677967"
        fill="#EABE93"
      />
      <ellipse
        cx="44.0974"
        cy="54.2366"
        rx="0.678415"
        ry="0.677967"
        fill="#EABE93"
      />
      <ellipse
        cx="40.7053"
        cy="54.2366"
        rx="0.678423"
        ry="0.677967"
        fill="#EABE93"
      />
      <ellipse
        cx="47.4895"
        cy="61.0168"
        rx="0.678417"
        ry="0.677965"
        fill="#EABE93"
      />
      <ellipse
        cx="44.0974"
        cy="61.0168"
        rx="0.678415"
        ry="0.677965"
        fill="#EABE93"
      />
      <ellipse
        cx="40.7053"
        cy="61.0168"
        rx="0.678423"
        ry="0.677965"
        fill="#EABE93"
      />
      <ellipse
        cx="37.3132"
        cy="54.2366"
        rx="0.678415"
        ry="0.677967"
        fill="#EABE93"
      />
      <ellipse
        cx="37.3132"
        cy="57.6272"
        rx="0.678415"
        ry="0.677965"
        fill="#EABE93"
      />
      <ellipse
        cx="50.8815"
        cy="57.6272"
        rx="0.678425"
        ry="0.677965"
        fill="#EABE93"
      />
      <ellipse
        cx="37.3132"
        cy="61.0168"
        rx="0.678415"
        ry="0.677965"
        fill="#EABE93"
      />
      <ellipse
        cx="50.8815"
        cy="61.0168"
        rx="0.678425"
        ry="0.677965"
        fill="#EABE93"
      />
      <rect
        x="18.9956"
        y="10.8477"
        width="0.678415"
        height="8.81356"
        fill="#8E9898"
      />
      <rect
        x="18.3174"
        y="11.5254"
        width="0.678415"
        height="7.45763"
        fill="#9FA9AA"
      />
      <rect
        x="16.9604"
        y="12.2031"
        width="1.35684"
        height="6.1017"
        fill="#B1BBBC"
      />
      <rect
        x="18.9956"
        y="61.0166"
        width="0.678415"
        height="8.81356"
        fill="#8E9898"
      />
      <rect
        x="18.3174"
        y="61.6943"
        width="0.678415"
        height="7.45763"
        fill="#9FA9AA"
      />
      <rect
        x="16.9604"
        y="62.3721"
        width="1.35684"
        height="6.1017"
        fill="#B1BBBC"
      />
      <rect
        x="16.9604"
        y="26.4404"
        width="2.71367"
        height="0.677967"
        fill="#9FA9AA"
      />
      <rect
        x="16.9604"
        y="26.4404"
        width="0.678419"
        height="2.71187"
        fill="#9FA9AA"
      />
      <rect
        x="16.9604"
        y="40.6777"
        width="2.71367"
        height="0.677967"
        fill="#9FA9AA"
      />
      <rect
        x="16.9604"
        y="40.6777"
        width="0.678419"
        height="2.71186"
        fill="#9FA9AA"
      />
      <rect
        x="16.9604"
        y="52.8818"
        width="2.71367"
        height="0.677967"
        fill="#9FA9AA"
      />
      <rect
        x="16.9604"
        y="52.8818"
        width="0.678419"
        height="2.71186"
        fill="#9FA9AA"
      />
      <rect y="9.49121" width="0.678419" height="64.4068" fill="#B1BBBC" />
      <rect
        x="0.678223"
        y="10.9062"
        width="1.35684"
        height="61.5757"
        fill="#A0AAAA"
      />
      <rect
        x="2.03516"
        y="9.49121"
        width="0.678419"
        height="64.4068"
        fill="#B1BBBC"
      />
      <rect
        x="2.71387"
        y="10.9062"
        width="1.35684"
        height="61.5757"
        fill="#A0AAAA"
      />
      <rect
        x="4.07031"
        y="9.49121"
        width="0.678419"
        height="64.4068"
        fill="#B1BBBC"
      />
      <rect
        x="4.74902"
        y="10.9062"
        width="1.35684"
        height="61.5757"
        fill="#A0AAAA"
      />
      <rect
        x="6.10596"
        y="9.49121"
        width="0.678418"
        height="64.4068"
        fill="#B1BBBC"
      />
      <rect
        x="6.78418"
        y="10.9062"
        width="1.35684"
        height="61.5757"
        fill="#A0AAAA"
      />
      <rect
        x="8.14111"
        y="9.49121"
        width="0.678419"
        height="64.4068"
        fill="#B1BBBC"
      />
      <rect
        x="8.81934"
        y="10.9062"
        width="1.35684"
        height="61.5757"
        fill="#A0AAAA"
      />
      <rect
        x="10.1763"
        y="9.49121"
        width="0.678421"
        height="64.4068"
        fill="#B1BBBC"
      />
      <rect
        x="10.8545"
        y="10.9062"
        width="1.35684"
        height="61.5757"
        fill="#A0AAAA"
      />
      <rect
        x="12.2114"
        y="9.49121"
        width="0.678419"
        height="64.4068"
        fill="#B1BBBC"
      />
      <rect
        x="12.8901"
        y="10.9062"
        width="1.35684"
        height="61.5757"
        fill="#A0AAAA"
      />
      <rect
        x="14.2466"
        y="9.49121"
        width="0.67842"
        height="64.4068"
        fill="#B1BBBC"
      />
      <rect
        x="14.9253"
        y="10.9062"
        width="1.35684"
        height="61.5757"
        fill="#A0AAAA"
      />
      <rect
        x="16.2822"
        y="9.49121"
        width="0.678417"
        height="64.4068"
        fill="#B1BBBC"
      />
      <rect y="15.5928" width="16.9605" height="0.677966" fill="#B1BBBC" />
      <rect y="42.0342" width="16.9605" height="0.677967" fill="#B1BBBC" />
      <rect y="65.085" width="16.9605" height="0.677971" fill="#B1BBBC" />
    </g>
  )
}

export default Transformer
