import React from 'react'
import PropTypes from 'prop-types'
import Illustrations from '../Illustrations'
import Typo from '../Typo'
import { useScreenSize } from '../ScreenSize'
import { useChart } from './Chart'

function calculateIntersectionPoint(x1, y1, x2, y2, x3, y3, x4, y4) {
  const denom = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1)
  if (denom === 0) {
    return null
  }
  const ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denom
  return {
    x: x1 + ua * (x2 - x1),
    y: y1 + ua * (y2 - y1),
  }
}

const ChartDuvalTriangle = ({ labels, width, dotSize }) => {
  const { chartData } = useChart()

  const triangleRef = React.useRef()
  const screenSize = useScreenSize()

  const [size, setSize] = React.useState({
    width: 0,
    height: 0,
  })

  React.useEffect(() => {
    if (!triangleRef.current) {
      return
    }
    const rect = triangleRef.current.getBoundingClientRect()
    setSize({ width: rect.width, height: rect.height })
  }, [screenSize, width])

  const renderTicks = from => {
    return [...Array(4)].map((_, index) => {
      return (
        <div
          key={index}
          className="cui-chart-duval-triangle__tick"
          style={{
            [from]: (size.width / 5) * (index + 1),
          }}
        >
          <Typo>{(index + 1) * 20}</Typo>
        </div>
      )
    })
  }

  const renderIntersectionPointAndLines = () => {
    if (
      [chartData.methane, chartData.ethylene, chartData.acetylene].filter(
        value => !value
      ).length > 1
    ) {
      return null
    }

    const total = chartData.methane + chartData.ethylene + chartData.acetylene

    const leftLength = (chartData.methane / total) * size.width
    const leftX1 = leftLength * Math.cos(Math.PI / 3)
    const leftY1 = leftLength * Math.sin(Math.PI / 3)
    const leftX2 = -leftLength * Math.cos(Math.PI / 3) + size.width
    const leftY2 = leftY1

    const rightLenght = (chartData.ethylene / total) * size.width
    const rightX1 = rightLenght * Math.cos(Math.PI / 3) + size.width / 2
    const rightY1 = size.height - rightLenght * Math.sin(Math.PI / 3)
    const rightX2 = rightLenght
    const rightY2 = 0

    const intersectionPoint = calculateIntersectionPoint(
      leftX1,
      leftY1,
      leftX2,
      leftY2,
      rightX1,
      rightY1,
      rightX2,
      rightY2
    )

    if (
      !intersectionPoint ||
      isNaN(intersectionPoint.x) ||
      isNaN(intersectionPoint.y)
    ) {
      return null
    }

    const bottomLength = (chartData.acetylene / total) * size.width
    const bottomX1 = size.width - bottomLength
    const bottomY1 = 0

    return (
      <svg className="cui-chart-duval-triangle__intersection-wrapper">
        <mask
          id="duval-triangle-mask1"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width={size.width}
          height={size.height}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d={`M0 ${size.height} L${size.width} ${size.height} L${
              size.width / 2
            } 0Z`}
            fill="white"
          />
        </mask>
        <g mask="url(#duval-triangle-mask1)">
          {[
            {
              x: leftX1 - 2,
              y: size.height - leftY1,
            },
            {
              x: rightX1 + 2,
              y: size.height - rightY1 - 2 / Math.tan(60),
            },
            {
              x: bottomX1 + 2,
              y:
                chartData.acetylene === 0
                  ? size.height
                  : size.height - bottomY1 + 2 / Math.tan(60),
            },
          ].map((from, index) => (
            <line
              key={index}
              x1={from.x}
              y1={from.y}
              x2={intersectionPoint.x}
              y2={size.height - intersectionPoint.y}
              stroke="white"
            />
          ))}
        </g>
        <circle
          cx={intersectionPoint.x}
          cy={size.height - intersectionPoint.y}
          r={dotSize}
          fill="white"
          stroke="#979797"
        />
      </svg>
    )
  }

  return (
    <div className="cui-chart-duval-triangle">
      <div
        className="cui-chart-duval-triangle__wrapper"
        ref={triangleRef}
        style={{
          width: width || '100%',
        }}
      >
        <Illustrations.DuvalTriangle />
        <div className="cui-chart-duval-triangle__ticks bottom">
          {renderTicks('right')}
          <div className="cui-chart-duval-triangle__tick label">
            <Typo>{labels.acetylene}</Typo>
          </div>
        </div>
        <div className="cui-chart-duval-triangle__ticks left">
          {renderTicks('left')}
          <div className="cui-chart-duval-triangle__tick label">
            <Typo>{labels.methane}</Typo>
          </div>
        </div>
        <div className="cui-chart-duval-triangle__ticks right">
          {renderTicks('left')}
          <div className="cui-chart-duval-triangle__tick label">
            <Typo>{labels.ethylene}</Typo>
          </div>
        </div>
        {renderIntersectionPointAndLines()}
      </div>
    </div>
  )
}

ChartDuvalTriangle.defaultProps = {
  chartData: {},
  labels: {
    methane: '% CH4',
    ethylene: '% C2H4',
    acetylene: '% C2H2',
  },
  dotSize: 4,
}

ChartDuvalTriangle.propTypes = {
  labels: PropTypes.object,
  width: PropTypes.number,
  dotSize: PropTypes.number,
}

export default ChartDuvalTriangle
