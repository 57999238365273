class ChartData {
  constructor({ type, data, x, y, otherY }) {
    this._type = type
    this._data = data
    this._x = x
    this._y = y
    this._otherY = otherY
    this._chartData = this.transformData()
  }

  transformData() {
    switch (this._type) {
      case 'xy':
        return this.transformXYData()

      case 'doughnut':
      case 'duval':
      case 'sinePulse':
        return this._data

      default:
        return this._data
    }
  }

  transformXYData() {
    if (!this._data) return []
    if (this._data.length === 0) return this._data
    const getDataXYValue = (value, key) => {
      if (!value) return null
      if (!Array.isArray(key) && typeof key !== 'string') {
        return null
      }
      if (Array.isArray(key)) {
        return key.map((k, index) => ({
          key: k,
          value: value[k] === null || value[k] === undefined ? null : value[k],
        }))
      }

      return value[key]
    }
    return this._data.map((dataItem, idx) => {
      let xData, yData, otherYData
      if (this._x) {
        xData = {
          value: getDataXYValue(dataItem, this._x),
          label: dataItem.label || '',
          key: this._x,
        }
      }
      if (this._y) {
        yData = {
          value: getDataXYValue(dataItem, this._y),
          label: dataItem.label || '',
          key: this._y,
        }
      }
      if (this._otherY) {
        otherYData = {
          value: getDataXYValue(dataItem, this._otherY),
          label: dataItem.label || '',
          key: this._otherY,
        }
      }
      const result = {
        x: xData,
        y: yData,
        otherY: otherYData,
      }
      return result
    })
  }

  getChartData = () => {
    return this._chartData
  }

  getX = () => {
    return this._x
  }

  getY = () => {
    return this._y
  }

  getOtherY = () => {
    return this._otherY
  }

  getChartType = () => {
    return this._type
  }
}
export default ChartData
