import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const TextBlockContent = (props) => {
  const _TextBlockContent = classnames('cui-textblock-content')

  return (
    <label className={_TextBlockContent} id={props.id}>
      {props.children}
    </label>
  )
}

TextBlockContent.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
}

export default TextBlockContent
