import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useFormikContext } from 'formik'

import FieldLabel from '../FieldLabel'
import FieldError from '../FieldError'
import { chunk } from '../../helpers'

import '../styles.scss'

const FieldChoose = ({
  children,
  multiple,
  choices,
  isColumn,
  spec,
  onChange,
  disabled,
}) => {
  const { values, handleChange, errors, handleBlur, setFieldValue } =
    useFormikContext() || {}

  const [stateError, setError] = useState(errors[spec.name])
  const selectedValues = values[spec.name] || []
  const _FieldChoose = classnames(
    'lm--input cui-field cui-field_choose--wrapper'
  )

  const _handleBlur = e => {
    if (handleBlur) {
      handleBlur(e)
    }

    if (selectedValues.length === 0) {
      setError('Please choose one')
    }
  }

  const _handleChange = e => {
    const { value, checked } = e.target
    const allValues = choices.map(item => item.id)

    let fieldValue = value

    if (multiple) {
      fieldValue = [...selectedValues] || []

      if (checked) {
        if (value === 'all') {
          fieldValue = allValues
        }
        fieldValue.push(value)
      } else {
        if (value === 'all') {
          fieldValue = []
        }
        fieldValue.splice(fieldValue.indexOf(value), 1)
      }
    }

    if (fieldValue.length) {
      setError(null)
    }

    if (setFieldValue) {
      setFieldValue(spec.name, fieldValue)
    }

    if (onChange) {
      onChange(fieldValue)
    } else {
      handleChange(e)
    }
  }

  const renderChooseContent = choice => {
    if (choice.image) {
      return (
        <div>
          <figure>
            <img src={choice.image} alt={choice.label} />
          </figure>
          <span>{choice.label}</span>
        </div>
      )
    }
    return choice.label
  }

  const renderFieldChoose = () => {
    if (choices.length <= 3) {
      return (
        <>
          {choices.map(choice => (
            <div key={choice.id} className="cui-field_choose--item">
              <input
                id={choice.id}
                type={multiple ? 'checkbox' : 'radio'}
                name={spec.name}
                onChange={_handleChange}
                disabled={disabled || choice.disabled}
                value={choice.id}
                checked={selectedValues.includes(choice.id)}
                onBlur={_handleBlur}
              />
              <label
                className={classnames('cui-field_choose--label', {
                  disabled: disabled || choice.disabled,
                })}
                htmlFor={choice.id}
              >
                {renderChooseContent(choice)}
              </label>
            </div>
          ))}
        </>
      )
    } else {
      return (
        <div className="cui-field_choose--group">
          {chunk(choices, 2).map((choice, idx) => (
            <div key={idx} className="cui-field_choose--item">
              {choice.map(item => {
                return (
                  <div key={item.id} className="cui-field_choose--group__item">
                    <input
                      id={item.id}
                      type={multiple ? 'checkbox' : 'radio'}
                      name={spec.name}
                      onChange={_handleChange}
                      disabled={disabled || item.disabled}
                      value={item.id}
                      checked={selectedValues.includes(item.id)}
                      onBlur={_handleBlur}
                    />
                    <label
                      className={classnames('cui-field_choose--label', {
                        disabled: disabled || item.disabled,
                      })}
                      htmlFor={item.id}
                    >
                      {renderChooseContent(item)}
                    </label>
                  </div>
                )
              })}
            </div>
          ))}
        </div>
      )
    }
  }

  const renderField = () => {
    const label = React.Children.map(children, (child, childIdx) => {
      if (!child) return null
      if (child.type === FieldLabel) {
        return <FieldLabel {...child.props} />
      }
      return null
    })

    return (
      <div className={_FieldChoose}>
        {label}
        <div
          className={classnames('cui-field_control', {
            column: isColumn,
            'full-width': React.Children.count(children) === 0,
          })}
        >
          {renderFieldChoose()}
        </div>
      </div>
    )
  }
  return (
    <div className="lm--input-group cui-field_group" id={spec.name}>
      {renderField()}
      {stateError && <FieldError>{stateError}</FieldError>}
    </div>
  )
}

FieldChoose.propTypes = {
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  choices: {
    id: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
  },
  multiple: PropTypes.bool,
  isColumn: PropTypes.bool,
  name: PropTypes.string,
  spec: {
    id: PropTypes.string,
    name: PropTypes.string,
  },
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
}

export default FieldChoose
