import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ProgressItem from './ProgressItem'
import { useScreenSize } from '../ScreenSize'
import { useTheme } from '../Theme'

import './styles.scss'
import Typo from '../Typo'

const Progress = ({ children, items, id, direction, progress }) => {
  const theme = useTheme()

  const screenSize = useScreenSize()
  const isMobile = screenSize
    ? screenSize.width < screenSize.breakpoints.MOBILE.width
    : false

  const _Progress = classnames('lm--progress cui-progress', {
    'is-crosswise': direction === 'column',
    'is-progress': progress,
  })

  function renderChildren() {
    if (items.length > 0) {
      if (isMobile) {
        const activeItemIndex = items.findIndex(item => item.active)
        return (
          <Typo variant="body2" color={theme.colors.text.secondary}>
            {`Step ${activeItemIndex + 1} of ${items.length}`}
          </Typo>
        )
      }
      return (
        <>
          <ul className="lm--progress-tracker cui-progress__items">
            {items.map((item, itemIdx) => {
              const { content, active, complete, ...itemProps } = item
              return (
                <ProgressItem
                  key={`${id}-progressitem--${itemIdx}`}
                  active={!!active}
                  complete={!!complete}
                  {...itemProps}
                >
                  {content}
                </ProgressItem>
              )
            })}
          </ul>
          {children}
        </>
      )
    }
    return children
  }

  return <div className={_Progress}>{renderChildren()}</div>
}

Progress.displayName = 'CuiProgress'

Progress.propTypes = {
  items: PropTypes.array,
  direction: PropTypes.string,
  id: PropTypes.string,
  progress: PropTypes.bool,
}

Progress.defaultProps = {
  items: [],
  direction: 'row',
  progress: true,
}

Progress.Item = ProgressItem

export default Progress
