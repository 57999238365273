import React from 'react'
import PropTypes from 'prop-types';

class OpenUtilitiesIcon extends React.PureComponent {
  static propTypes = {
    color: PropTypes.array,
  }

  render() {
    return (
      <g
        className="icon-open-utilities"
        stroke="none"
        strokeWidth="1"
        fill={this.props.color[0]}
        fillRule="evenodd"
      >
        <path
          d="M1.74092 21.3947C1.74092 17.817 3.95627 14.0431 6.96239 10.4721C9.32794 7.66197 12.0611 5.11329 14.3245 3.00266C14.6693 2.68114 15.0032 2.36978 15.3232 2.06921C17.8596 4.32388 21.0863 7.23084 23.769 10.4651C26.7239 14.0273 28.8633 17.8066 28.8633 21.3947C28.8633 28.7343 22.8077 34.7129 15.3021 34.7129C7.79654 34.7129 1.74092 28.7343 1.74092 21.3947Z"
          fill="white"
          stroke="#818A91"
          strokeWidth="2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.3429 21.3307L9.66977 21.0749L17.6291 12.8577L17.2204 18.6769L20.8936 18.9326L12.9752 26.5679L13.3429 21.3307Z"
          fill="url(#paint0_linear)"
        />
        <mask
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="9"
          y="12"
          width="12"
          height="15"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.3429 21.3307L9.66977 21.0749L17.6291 12.8577L17.2204 18.6769L20.8936 18.9326L12.9752 26.5679L13.3429 21.3307Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0)">
          <rect
            x="9.77881"
            y="12.7129"
            width="12.0506"
            height="10"
            rx="2"
            fill="url(#paint1_linear)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.1607 23.2176C11.1607 23.2176 14.6331 22.3582 16.0632 20.0463C17.4932 17.7343 16.6784 12.032 16.6784 12.032L20.9162 12.3463C20.9162 12.3463 17.7443 23.6644 16.3145 25.7122C14.8848 27.76 12.3373 26.3887 12.3373 26.3887L11.1607 23.2176Z"
            fill="url(#paint2_linear)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.1483 17.7126L20.8936 18.9325C20.8936 18.9325 16.6333 25.6538 14.9373 27.0792C13.2413 28.5047 11.2902 25.4192 11.2902 25.4192C11.2902 25.4192 16.4505 23.0028 17.7965 20.7126C19.1425 18.4225 18.1483 17.7126 18.1483 17.7126Z"
            fill="url(#paint3_linear)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="7.84009"
            y1="18.2593"
            x2="14.9482"
            y2="22.3219"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F5B987" />
            <stop offset="1" stopColor="#FF718F" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="7.5725"
            y1="17.0475"
            x2="14.0034"
            y2="21.7828"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F5B987" />
            <stop offset="1" stopColor="#FF718F" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="15.5697"
            y1="20.7336"
            x2="17.1874"
            y2="21.183"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D35F9B" />
            <stop offset="1" stopColor="#3C6DBF" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="22.4741"
            y1="25.5578"
            x2="23.2118"
            y2="14.9641"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#40B8FF" />
            <stop offset="1" stopColor="#89FCFF" />
          </linearGradient>
        </defs>
      </g>
    )
  }
}

export default OpenUtilitiesIcon
