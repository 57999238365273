import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Detail = ({ label, children, ...props }) => {
  const _Detail = classnames('cui-detail')
  const _DetailLabel = classnames('cui-detail__label')
  const _DetailContent = classnames('cui-detail__content')
  return (
    <div className={_Detail} {...props}>
      {label && (
        <div className={_DetailLabel}>
          <h4 className="cui-detail__labelnode">{label}</h4>
        </div>
      )}
      {children && <div className={_DetailContent}>{children}</div>}
    </div>
  )
}
Detail.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node,
}

export default Detail
