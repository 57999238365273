import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import PrintableButton from './PrintableButton'
import PrintableHead from './PrintableHead'
import PrintablePageBreak from './PrintablePageBreak'
import PrintableItem from './PrintableItem'

export const PrintableContext = React.createContext()
export const PrintableContextProvider = PrintableContext.Provider

const Printable = ({ id, children, ready, printAction }) => {
  const printablesRef = React.useRef(null)
  const printablesFrameRef = React.useRef(null)

  const _Printable = classnames('cui-printable')
  const printFrameId = `print_${id}`

  const handlePrint = () => {
    const frm = window.frames[printFrameId]
    if (frm) {
      frm.print()
    }
  }

  React.useEffect(() => {
    if (ready) {
      setTimeout(() => {
        const frm = window.frames[printFrameId]
        if (printablesFrameRef.current) {
          let styles = [...document.getElementsByTagName('style')]
          if (styles.length === 0) {
            styles = [...document.getElementsByTagName('link')]
            styles = styles.map(s => {
              return s.outerHTML
            })
            styles = styles.join(' ')
          } else {
            styles = styles.map(s => {
              return s.innerHTML
            })
            styles = '<style>' + styles.join(' ') + '</style>'
          }
          const contentHTML = printablesRef.current.innerHTML
          if (frm) {
            frm.document.write(`
              <!doctype html>
              <html moznomarginboxes mozdisallowselectionprint>
                <head>
                  ${styles}
                  <style> body { background: none !important; }</style>
                </head>
                <body>
                  <div class="print-container">
                    ${contentHTML}
                  </div>
                </body>
              </html>
            `)
            frm.document.close()
          }
        }
      }, 100)
    }
  }, [ready, printFrameId])

  const renderPrintAction = () => {
    if (printAction) {
      if (typeof printAction === 'function') {
        return printAction()
      }
      return printAction
    }
    return null
  }

  const renderContent = () => {
    return React.Children.map(children, (child, idx) => {
      if (!child) return null
      let renderTheKid = true
      if (child.type === PrintableItem) {
        renderTheKid = ready
      }
      if (renderTheKid) {
        return React.cloneElement(child)
      }
      return null
    })
  }

  return (
    <PrintableContextProvider
      value={{
        actions: {
          print: handlePrint,
        },
      }}
    >
      <div className={_Printable} id={id}>
        <iframe
          className="printable-frame"
          title={printFrameId}
          id={printFrameId}
          name={printFrameId}
          ref={printablesFrameRef}
          style={{
            opacity: '0',
            visibility: 'hidden',
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: '-1',
            width: '100%',
            height: '100%',
          }}
        />
        <div ref={printablesRef} style={{ paddingBottom: '1rem' }}>
          <figure className="printable printable-logo">
            <img src="/images/spgroup-logo.svg" alt="logo" />
          </figure>
          {renderContent()}
        </div>
        {renderPrintAction()}
      </div>
    </PrintableContextProvider>
  )
}

Printable.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  ready: PropTypes.bool,
  printAction: PropTypes.node,
}

Printable.Head = PrintableHead
Printable.Item = PrintableItem
Printable.Button = PrintableButton
Printable.Break = PrintablePageBreak

Printable.Context = PrintableContext
Printable.Provider = PrintableContextProvider

export default Printable
