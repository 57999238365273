import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ArticleHero from './ArticleHero'
import ArticleHeader from './ArticleHeader'
import ArticleDescription from './ArticleDescription'
import ArticleContent from './ArticleContent'

import './styles.scss'

const Article = ({ id, className, children, align, isCrosswise }) => {
  const _Article = classnames('cui-article', className, `align-${align}`, {
    'is-crosswise': isCrosswise,
  })
  return (
    <article className={_Article} id={id}>
      {children}
    </article>
  )
}

Article.defaultProps = {
  id: '',
  className: '',
  align: 'left',
  isCrosswise: false,
}

Article.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  isCrosswise: PropTypes.bool,
}

Article.Header = ArticleHeader
Article.Description = ArticleDescription
Article.Hero = ArticleHero
Article.Content = ArticleContent

export default Article
