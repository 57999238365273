import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import FieldCheckbox from '../Form/FieldCheckbox'
import FieldContent from '../Form/FieldContent'
import './choice.scss'

export const ChoiceCheck = ({ name, id, active, children }) => {
  return (
    <div className="cui-choice__btn--checkbox">
      <FieldCheckbox name={id} id={id} checked={active}>
        <FieldContent>{children}</FieldContent>
      </FieldCheckbox>
    </div>
  )
}

const Choice = ({
  id,
  children,
  className,
  title,
  iconTitle,
  content,
  active,
  disabled,
  onClickHandler,
}) => {
  const [isActiveChx, setActiveChx] = useState(false)
  const _Choice = classnames('cui-choice', className, {
    'is-active-checkbox': active && isActiveChx,
    'is-active': active,
    'is-disabled': disabled,
  })

  const handleClick = e => {
    if (onClickHandler && !disabled) {
      onClickHandler({
        id,
      })
    }

    React.Children.map(children, child => {
      if (child.type === ChoiceCheck) {
        setActiveChx(true)
      }
    })
  }

  const renderChoiceChildren = () => {
    if (!children) return null
    return React.Children.map(children, (child, childIdx) => {
      if (child.type === ChoiceCheck) {
        return React.cloneElement(child, {
          key: `${child.props.key}-${childIdx}`,
          ...child.props,
          active: active,
        })
      }
      return child
    })
  }

  const renderChoiceBtn = () => {
    return (
      <>
        <div
          className="cui-choice__btn"
          onClick={handleClick}
          disabled={disabled}
        >
          <div className="cui-choice__btn--title">
            {iconTitle && <span className="icon">{iconTitle}</span>}
            <span className="text">{title}</span>
          </div>
          <div className="cui-choice__btn--content">
            {renderChoiceChildren()}
          </div>
        </div>
      </>
    )
  }

  return (
    <div className={_Choice}>
      {renderChoiceBtn()}
      {content && active && (
        <div className="cui-choice__content">{content}</div>
      )}
    </div>
  )
}

Choice.Check = ChoiceCheck

Choice.defaultProps = {
  active: false,
  disabled: false,
}

ChoiceCheck.defaultProps = {
  active: false,
}

ChoiceCheck.propTypes = {
  id: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
}

Choice.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  onClickHandler: PropTypes.func,
  iconTitle: PropTypes.elementType,
  content: PropTypes.node,
}

export default Choice
