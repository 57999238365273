import React from 'react'

class AlertIcon extends React.PureComponent {
  render() {
    return (
      <g className="icon-alert" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M64.3261 57H32.6739C27.8321 57 25.6762 51.3923 27.8415 47.4183L42.6743 19.304C45.0746 14.8987 51.9253 14.8987 54.3256 19.304L69.1584 47.4182C71.3239 51.3923 69.168 57 64.3261 57Z"
          fill="#C64E73"
        />
        <path
          d="M62.3094 55H34.6906C30.4658 55 27.7179 50.7803 29.6073 47.194L43.4167 20.9817C45.5111 17.0061 51.4888 17.0061 53.5832 20.9817L67.3926 47.1939C69.2821 50.7803 66.5343 55 62.3094 55Z"
          fill="url(#paint0_linear)"
        />
        <path
          d="M66.3617 47.6572L67.2479 47.1939L66.3617 47.6573C67.1691 49.2015 67.1681 50.8197 66.5962 52.0083C66.0414 53.1614 64.9123 54 63.196 54H33.804C32.0877 54 30.9587 53.1614 30.4038 52.0083C29.8319 50.8197 29.8309 49.2015 30.6382 47.6573L29.752 47.194L30.6382 47.6573L44.3418 21.445C46.0461 18.185 50.9538 18.185 52.6581 21.445L66.3617 47.6572Z"
          stroke="white"
          strokeWidth="2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M46.8972 26.4346C46.4629 26.7242 46.2459 27.1354 46.2459 27.6677C46.2459 28.7607 46.2811 29.4702 46.3515 30.8914C46.3885 31.6394 46.4353 32.5846 46.4919 33.8866C46.535 34.8793 46.5724 35.6933 46.6042 36.3842C46.6933 38.3209 46.7378 39.2905 46.7378 40.5197C46.7378 41.2614 47.3533 41.6318 48.584 41.6318C49.0041 41.6318 49.4095 41.5333 49.8001 41.3367C50.1901 41.1401 50.3854 40.8677 50.3854 40.5197C50.3854 39.2807 50.4041 38.3056 50.4418 36.3381C50.4548 35.6571 50.4702 34.8573 50.4878 33.8866C50.5116 32.5592 50.5313 31.6027 50.5469 30.8479C50.5757 29.4557 50.5902 28.7495 50.5902 27.6677C50.5902 27.1585 50.4282 26.7535 50.1049 26.4521C49.7814 26.1508 49.2743 26 48.5839 26C47.8936 26 47.3313 26.145 46.8972 26.4346ZM46.7665 45.0302C46.2555 45.4817 46 46.0303 46 46.6761C46 47.296 46.2555 47.8381 46.7665 48.3028C47.2775 48.7676 47.8986 49 48.6287 49C49.2716 49 49.8274 48.7677 50.2961 48.3028C50.765 47.8381 51 47.2959 51 46.6761C51 46.0303 50.7649 45.4817 50.2961 45.0302C49.8274 44.5785 49.2717 44.3521 48.6287 44.3521C47.8987 44.3521 47.2775 44.5785 46.7665 45.0302Z"
          fill="#FFC466"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="25.2386"
            y1="31.3065"
            x2="44.0249"
            y2="52.9599"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F291B0" />
            <stop offset="1" stopColor="#F65B7D" />
          </linearGradient>
        </defs>
      </g>
    )
  }
}

export default AlertIcon
