import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useChartXY } from './ChartXY'
import { useTheme } from '../../Theme'

const ChartBar = ({
  options,
  fields,
  resolveLabel,
  resolveValue,
  getBarWidth,
  withoutOpacity,
  showMarker,
  onClick,
}) => {
  const theme = useTheme()
  const [barItemInfo, setBarItemPos] = useState({ pos: 0, value: 0, label: '' })
  const [isShowMarker, setShow] = useState(false)
  const {
    isNoRecord,
    parentWidth,
    parentHeight,
    direction,
    interval,
    markerLength,
    maxValue,
    minValue,
    y,
    unit,
    actions: { getDivisionX },
  } = useChartXY()

  fields = fields || y

  const barWidth = isNoRecord ? 32 : getBarWidth(direction)
  const isVertical = direction === 'vertical'
  const maxChartVal = isNoRecord ? 60 : maxValue
  const axisX = getDivisionX()

  const noRecordData = [
    {
      barstart: 0,
      pos: 0,
      data: [
        {
          key: 'no_record',
          value: 40,
        },
      ],
    },
    {
      barstart: 40,
      pos: 0,
      data: [
        {
          key: 'no_record',
          value: 80,
        },
      ],
    },
    {
      barstart: 80,
      pos: 0,
      data: [
        {
          key: 'no_record',
          value: 60,
        },
      ],
    },
    {
      barstart: 120,
      pos: 0,
      data: [
        {
          key: 'no_record',
          value: 30,
        },
      ],
    },
  ]

  const getBarData = () => {
    if (isNoRecord) {
      return noRecordData
    } else {
      return axisX.map(item => {
        if (fields) {
          if (item.data) {
            return {
              ...item,
              data: fields.map(field => {
                const f = item.data.filter(d => d.key === field)
                return f[0]
              }),
            }
          }
        }
        return item
      })
    }
  }

  const barData = getBarData()

  const getRatio = () => {
    if (isNoRecord) {
      return 2
    } else {
      if (isVertical) {
        return (
          (parentHeight - parentHeight / interval) / (maxChartVal - minValue)
        )
      } else {
        return (parentWidth - parentWidth / interval) / (maxChartVal - minValue)
      }
    }
  }

  const ratio = getRatio()

  const renderRect = data => {
    if (!data) return null
    let sum = 0
    return data.map((val, index) => {
      const rectHeight = Math.abs(val.value * ratio)
      sum = sum + rectHeight

      const attrs = {
        fill: options.color[val.key],
        transform: `${
          isVertical
            ? `translate(0, ${parentHeight - sum})`
            : `translate(${index === 0 ? 0 : sum - rectHeight}, -${barWidth})`
        }`,
      }

      if (options.rounded && index === data.length - 1) {
        return (
          <path
            key={index}
            d={
              isVertical
                ? `M 0 ${rectHeight} V ${barWidth / 2} Q 0 0 ${
                    barWidth / 2
                  } 0 Q ${barWidth} 0 ${barWidth} ${
                    barWidth / 2
                  } V ${rectHeight} Z`
                : `M 0 0 H ${
                    rectHeight - barWidth / 2
                  } Q ${rectHeight} 0 ${rectHeight} ${
                    barWidth / 2
                  } Q ${rectHeight} ${barWidth} ${
                    rectHeight - barWidth / 2
                  } ${barWidth} H 0 Z`
            }
            {...attrs}
          />
        )
      }
      return (
        <rect
          key={index}
          width={isVertical ? barWidth : rectHeight}
          height={isVertical ? rectHeight : barWidth}
          {...attrs}
        />
      )
    })
  }

  const handelMouseEnterBarItem = ({ data, barstart, label }, idx) => {
    if (!data || isNoRecord) return null
    const barItemData = data.reduce((acc, item) => acc + item.value, 0)

    setBarItemPos({
      pos: barstart,
      value: barItemData,
      label,
      idx,
    })
    setShow(true)
  }

  const handleMouseLeaveBarItem = () => {
    setBarItemPos({ pos: 0, value: 0, label: '' })
    setShow(false)
  }

  const renderBars = () => {
    return barData.map((data, idx) => (
      <g
        key={idx}
        className={classnames('cui-chart__bar--item', {
          active: withoutOpacity ? true : barItemInfo.label === data.label,
        })}
        transform={`${
          isVertical
            ? `translate(${data.barstart}, ${
                data.yPositivePosition || data.yNegativePosition || 0
              })`
            : `translate(0 ,${data.barstart})`
        }`}
        {...(showMarker
          ? {
              onMouseEnter: () => handelMouseEnterBarItem(data, idx),
              onMouseLeave: handleMouseLeaveBarItem,
            }
          : {})}
        onClick={onClick ? () => onClick(barItemInfo) : undefined}
      >
        {renderRect(data.data)}
      </g>
    ))
  }

  const isLeftSide = barItemInfo.idx < barData.length / 2

  return (
    <g className="cui-chart__bar">
      {renderBars()}
      {isShowMarker && (
        <g className="cui-chart__bar--marker">
          <line
            x1={isVertical ? barItemInfo.pos + barWidth / 2 : 0}
            y1={isVertical ? 0 : barItemInfo.pos - barWidth / 2}
            x2={isVertical ? barItemInfo.pos + barWidth / 2 : parentWidth}
            y2={isVertical ? parentHeight : barItemInfo.pos - barWidth / 2}
            strokeDasharray="1, 5"
            stroke={options.color[fields[0]]}
          />
          <line
            x1={isVertical ? barItemInfo.pos + barWidth / 2 : parentWidth}
            y1={isVertical ? -markerLength : barItemInfo.pos - barWidth / 2}
            x2={
              isVertical
                ? barItemInfo.pos + barWidth / 2
                : parentWidth + markerLength
            }
            y2={isVertical ? 0 : barItemInfo.pos - barWidth / 2}
            stroke={options.color[fields[0]]}
          />
          <g
            transform={`${
              isVertical
                ? `translate(${barItemInfo.pos + barWidth / 2}, -${
                    markerLength / 2
                  })`
                : `translate(${parentWidth}, ${barItemInfo.pos - barWidth})`
            }`}
          >
            <text
              className="value"
              textAnchor={isVertical ? (isLeftSide ? 'start' : 'end') : 'start'}
              x={isVertical ? (isLeftSide ? 6 : -6) : 0}
              y={0}
              fill={options.color[fields[0]]}
            >
              {resolveValue
                ? resolveValue(Number(barItemInfo.value.toFixed(2)))
                : Number(barItemInfo.value.toFixed(2))}{' '}
              {unit.y}
            </text>
            <text
              className="label"
              textAnchor={isVertical ? (isLeftSide ? 'start' : 'end') : 'start'}
              y={isVertical ? 12 : 15}
              x={isVertical ? (isLeftSide ? 6 : -6) : 0}
              fill={theme.colors.chart.text}
            >
              {resolveLabel
                ? resolveLabel(barItemInfo.label)
                : barItemInfo.label}
            </text>
          </g>
        </g>
      )}
    </g>
  )
}

ChartBar.defaultProps = {
  interval: 4,
  options: {
    width: 32,
    color: { electricity: 'blue', water: 'grey', gas: 'red' },
  },
  width: 800,
  height: 500,
  fields: null,
  showMarker: true,
  withoutOpacity: false,
}

ChartBar.propTypes = {
  options: PropTypes.object,
  fields: PropTypes.array,
}

export default ChartBar
