import React from 'react'
import PropTypes from 'prop-types'
import FieldLabel from '../FieldLabel'
import FieldError from '../FieldError'
import { useFormikContext } from 'formik'

const FieldGroup = ({ children, name }) => {
  const { errors } = useFormikContext() || {}

  const label = React.Children.map(children, child => {
    if (child.type === FieldLabel) {
      return <FieldLabel {...child.props} />
    }
    return null
  })

  const fields = React.Children.map(children, child => {
    if (child.type !== FieldLabel) {
      return child
    }
    return null
  })

  return (
    <div className="lm--input-group cui-field_group cui-field-group">
      <div className="lm--input cui-field">
        {label}
        <div className="cui-field_control">{fields}</div>
      </div>
      {name && errors[name] && <FieldError name={name} />}
    </div>
  )
}

FieldGroup.propTypes = {
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
}

export default FieldGroup
