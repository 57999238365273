import React from 'react'
import PropTypes from 'prop-types';

class WholesaleElectricityIcon extends React.PureComponent {
  static propTypes = {
    color: PropTypes.array,
  }

  render() {
    return (
      <g
        className="icon-wholesale-electricity"
        stroke="none"
        strokeWidth="1"
        fill={this.props.color[0]}
        fillRule="evenodd"
      >
        <rect
          x="9.39917"
          y="18.7129"
          width="30.135"
          height="22"
          rx="3"
          fill="white"
          stroke="#818A91"
          strokeWidth="2"
        />
        <rect
          x="8.39502"
          y="9.71289"
          width="32.1435"
          height="9"
          rx="3"
          fill="white"
          stroke="#818A91"
          strokeWidth="2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.0055 32.3307L18.3324 32.0749L26.2916 23.8577L25.883 29.6769L29.5562 29.9326L21.6378 37.5679L22.0055 32.3307Z"
          fill="url(#paint0_linear)"
        />
        <mask
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="18"
          y="23"
          width="12"
          height="15"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.0055 32.3307L18.3324 32.0749L26.2916 23.8577L25.883 29.6769L29.5562 29.9326L21.6378 37.5679L22.0055 32.3307Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0)">
          <rect
            x="18.4414"
            y="23.7129"
            width="12.0506"
            height="10"
            rx="2"
            fill="url(#paint1_linear)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.8233 34.2176C19.8233 34.2176 23.2957 33.3582 24.7258 31.0463C26.1558 28.7343 25.341 23.032 25.341 23.032L29.5788 23.3463C29.5788 23.3463 26.4069 34.6644 24.9771 36.7122C23.5474 38.76 20.9999 37.3887 20.9999 37.3887L19.8233 34.2176Z"
            fill="url(#paint2_linear)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M26.8109 28.7126L29.5562 29.9325C29.5562 29.9325 25.2959 36.6538 23.5999 38.0792C21.9039 39.5047 19.9528 36.4192 19.9528 36.4192C19.9528 36.4192 25.1131 34.0028 26.4591 31.7126C27.8051 29.4225 26.8109 28.7126 26.8109 28.7126Z"
            fill="url(#paint3_linear)"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.7411 15.6196L6.39087 19.4075V21.0466C6.39087 23.0715 8.08816 24.7106 10.1849 24.7106C12.2816 24.7106 13.9789 23.0715 13.9789 21.0466V19.4075L13.6344 19.4075L14.4922 15.6196H7.7411Z"
          fill="white"
        />
        <path
          d="M6.39087 19.4075L5.9199 19.2396L5.89087 19.3211V19.4075H6.39087ZM7.7411 15.6196V15.1196H7.38851L7.27012 15.4517L7.7411 15.6196ZM13.9789 19.4075H14.4789V18.9075L13.9789 18.9075L13.9789 19.4075ZM13.6344 19.4075L13.1468 19.2971L13.0086 19.9075L13.6344 19.9075L13.6344 19.4075ZM14.4922 15.6196L14.9799 15.7301L15.1181 15.1196H14.4922V15.6196ZM6.86184 19.5754L8.21207 15.7875L7.27012 15.4517L5.9199 19.2396L6.86184 19.5754ZM5.89087 19.4075V21.0466H6.89087V19.4075H5.89087ZM5.89087 21.0466C5.89087 23.3639 7.8286 25.2106 10.1849 25.2106V24.2106C8.34772 24.2106 6.89087 22.779 6.89087 21.0466H5.89087ZM10.1849 25.2106C12.5411 25.2106 14.4789 23.3639 14.4789 21.0466H13.4789C13.4789 22.779 12.022 24.2106 10.1849 24.2106V25.2106ZM14.4789 21.0466V19.4075H13.4789V21.0466H14.4789ZM13.9789 18.9075L13.6344 18.9075L13.6344 19.9075H13.9789L13.9789 18.9075ZM14.0046 15.5092L13.1468 19.2971L14.1221 19.518L14.9799 15.7301L14.0046 15.5092ZM7.7411 16.1196H14.4922V15.1196H7.7411V16.1196Z"
          fill="#818A91"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.3963 15.6196L13.4204 19.4568V21.1173C13.4204 23.1685 15.387 24.8289 17.8164 24.8289C20.2458 24.8289 22.2125 23.1685 22.2125 21.1173V19.4568L21.6617 19.4568L21.987 15.6196H14.3963Z"
          fill="white"
        />
        <path
          d="M13.4204 19.4568L12.9358 19.3336L12.9204 19.3943V19.4568H13.4204ZM14.3963 15.6196V15.1196H14.0076L13.9118 15.4964L14.3963 15.6196ZM22.2125 19.4568H22.7125V18.9568L22.2125 18.9568V19.4568ZM21.6617 19.4568L21.1635 19.4146L21.1175 19.9568L21.6617 19.9568V19.4568ZM21.987 15.6196L22.4852 15.6619L22.5312 15.1196H21.987V15.6196ZM13.905 19.5801L14.8809 15.7429L13.9118 15.4964L12.9358 19.3336L13.905 19.5801ZM12.9204 19.4568V21.1173H13.9204V19.4568H12.9204ZM12.9204 21.1173C12.9204 23.5206 15.1935 25.3289 17.8164 25.3289V24.3289C15.5805 24.3289 13.9204 22.8164 13.9204 21.1173H12.9204ZM17.8164 25.3289C20.4393 25.3289 22.7125 23.5206 22.7125 21.1173H21.7125C21.7125 22.8164 20.0523 24.3289 17.8164 24.3289V25.3289ZM22.7125 21.1173V19.4568H21.7125V21.1173H22.7125ZM22.2125 18.9568L21.6617 18.9568V19.9568L22.2125 19.9568V18.9568ZM21.4888 15.5774L21.1635 19.4146L22.1599 19.4991L22.4852 15.6619L21.4888 15.5774ZM14.3963 16.1196H21.987V15.1196H14.3963V16.1196Z"
          fill="#818A91"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.509 15.6196L35.5256 19.6196H35.5133V21.1651C35.5133 23.0742 37.3103 24.6196 39.5301 24.6196C41.75 24.6196 43.547 23.0742 43.547 21.1651V19.6196L41.2746 15.6196H34.509Z"
          fill="white"
        />
        <path
          d="M35.5256 19.6196V20.1196H36.1686L36.0102 19.4965L35.5256 19.6196ZM34.509 15.6196V15.1196H33.8661L34.0244 15.7428L34.509 15.6196ZM35.5133 19.6196V19.1196H35.0133V19.6196H35.5133ZM43.547 19.6196H44.047V19.4875L43.9817 19.3727L43.547 19.6196ZM41.2746 15.6196L41.7094 15.3727L41.5656 15.1196H41.2746V15.6196ZM36.0102 19.4965L34.9936 15.4965L34.0244 15.7428L35.041 19.7428L36.0102 19.4965ZM35.5256 19.1196H35.5133V20.1196H35.5256V19.1196ZM35.0133 19.6196V21.1651H36.0133V19.6196H35.0133ZM35.0133 21.1651C35.0133 23.4184 37.1075 25.1196 39.5301 25.1196V24.1196C37.513 24.1196 36.0133 22.7299 36.0133 21.1651H35.0133ZM39.5301 25.1196C41.9527 25.1196 44.047 23.4184 44.047 21.1651H43.047C43.047 22.7299 41.5473 24.1196 39.5301 24.1196V25.1196ZM44.047 21.1651V19.6196H43.047V21.1651H44.047ZM40.8399 15.8666L43.1123 19.8666L43.9817 19.3727L41.7094 15.3727L40.8399 15.8666ZM34.509 16.1196H41.2746V15.1196H34.509V16.1196Z"
          fill="#818A91"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28.2583 19.4568H27.8048L27.4795 15.6196H35.0702L36.0461 19.4568L36.0461 21.1173C36.0461 23.1685 34.3041 24.8289 32.1522 24.8289C30.0003 24.8289 28.2583 23.1685 28.2583 21.1173V19.4568Z"
          fill="white"
        />
        <path
          d="M27.8048 19.4568L27.3066 19.4991L27.3454 19.9568H27.8048V19.4568ZM28.2583 19.4568H28.7583V18.9568H28.2583V19.4568ZM27.4795 15.6196V15.1196H26.9353L26.9813 15.6619L27.4795 15.6196ZM35.0702 15.6196L35.5547 15.4964L35.4589 15.1196H35.0702V15.6196ZM36.0461 19.4568L36.5461 19.4568V19.3943L36.5307 19.3336L36.0461 19.4568ZM36.0461 21.1173L35.5461 21.1173V21.1173H36.0461ZM27.8048 19.9568H28.2583V18.9568H27.8048V19.9568ZM26.9813 15.6619L27.3066 19.4991L28.3031 19.4146L27.9777 15.5774L26.9813 15.6619ZM35.0702 15.1196H27.4795V16.1196H35.0702V15.1196ZM36.5307 19.3336L35.5547 15.4964L34.5856 15.7429L35.5615 19.5801L36.5307 19.3336ZM36.5461 21.1173L36.5461 19.4568L35.5461 19.4568L35.5461 21.1173L36.5461 21.1173ZM32.1522 25.3289C34.5574 25.3289 36.5461 23.4669 36.5461 21.1173H35.5461C35.5461 22.87 34.0508 24.3289 32.1522 24.3289V25.3289ZM27.7583 21.1173C27.7583 23.4669 29.747 25.3289 32.1522 25.3289V24.3289C30.2535 24.3289 28.7583 22.87 28.7583 21.1173H27.7583ZM27.7583 19.4568V21.1173H28.7583V19.4568H27.7583Z"
          fill="#818A91"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M21.7615 15.6196L21.4541 19.4568V21.1173C21.4541 23.1685 23.1962 24.8289 25.3481 24.8289C27.4999 24.8289 29.2419 23.1685 29.2419 21.1173V19.4568L28.9345 15.6196H21.7615Z"
          fill="white"
        />
        <path
          d="M21.4541 19.4568L20.9557 19.4169L20.9541 19.4368V19.4568H21.4541ZM21.7615 15.6196V15.1196H21.3L21.2631 15.5797L21.7615 15.6196ZM29.2419 19.4568H29.7419V19.4368L29.7403 19.4169L29.2419 19.4568ZM28.9345 15.6196L29.4329 15.5797L29.396 15.1196H28.9345V15.6196ZM21.9525 19.4968L22.2599 15.6596L21.2631 15.5797L20.9557 19.4169L21.9525 19.4968ZM20.9541 19.4568V21.1173H21.9541V19.4568H20.9541ZM20.9541 21.1173C20.9541 23.4669 22.9429 25.3289 25.3481 25.3289V24.3289C23.4494 24.3289 21.9541 22.87 21.9541 21.1173H20.9541ZM25.3481 25.3289C27.7531 25.3289 29.7419 23.4669 29.7419 21.1173H28.7419C28.7419 22.87 27.2466 24.3289 25.3481 24.3289V25.3289ZM29.7419 21.1173V19.4568H28.7419V21.1173H29.7419ZM28.4361 15.6596L28.7435 19.4968L29.7403 19.4169L29.4329 15.5797L28.4361 15.6596ZM21.7615 16.1196H28.9345V15.1196H21.7615V16.1196Z"
          fill="#818A91"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="16.5027"
            y1="29.2593"
            x2="23.6108"
            y2="33.3219"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F5B987" />
            <stop offset="1" stopColor="#FF718F" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="16.2351"
            y1="28.0475"
            x2="22.666"
            y2="32.7828"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F5B987" />
            <stop offset="1" stopColor="#FF718F" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="24.2323"
            y1="31.7336"
            x2="25.85"
            y2="32.183"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D35F9B" />
            <stop offset="1" stopColor="#3C6DBF" />
          </linearGradient>
          <linearGradient
            id="paint3_linear"
            x1="31.1367"
            y1="36.5578"
            x2="31.8744"
            y2="25.9641"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#40B8FF" />
            <stop offset="1" stopColor="#89FCFF" />
          </linearGradient>
        </defs>
      </g>
    )
  }
}

export default WholesaleElectricityIcon
