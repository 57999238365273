import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const TabPane = ({ id, children, className, style }) => {
  const _TabPane = classnames('cui--tab-pane', className)

  return (
    <div className={_TabPane} id={id} style={style}>
      {children}
    </div>
  )
}

TabPane.propTypes = {
  id: PropTypes.string,
}

export default TabPane
