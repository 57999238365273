import React from 'react'
import PropTypes from 'prop-types'
import Button from '../Button'
import Icons from '../Icons'
import ContextMenu from '../ContextMenu'

const DropdownButton = ({ children, options, menuProps, ...props }) => {
  const buttonRef = React.useRef()

  const [open, setOpen] = React.useState(false)

  return (
    <div className="cui-dropdown-button">
      <div ref={buttonRef} className="cui-dropdown-button__button-wrapper">
        <Button {...props}>{children}</Button>
        <Button {...props} onClickHandler={() => setOpen(true)}>
          <Icons.Caret.Down width={14} height={14} />
        </Button>
      </div>
      <ContextMenu
        targetNode={buttonRef.current}
        onCloseHandler={() => setOpen(false)}
        withBackdrop={false}
        className="cui-dropdown-button__modal"
        fullTargetWidth
        open={open}
        appearAnimation={false}
        disappearAnimation={false}
        width={186}
        {...menuProps}
      >
        {options.map((button, index) => {
          const { label, onClick, ...restProps } = button
          return (
            <Button key={index} {...restProps}>
              {label}
            </Button>
          )
        })}
      </ContextMenu>
    </div>
  )
}

DropdownButton.propTypes = {
  children: PropTypes.node,
  options: PropTypes.array,
}

DropdownButton.defaultProps = {
  options: [],
  menuProps: {},
}

export default DropdownButton
