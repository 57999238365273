import React from 'react'
import PropTypes from 'prop-types'

const PopupForm = ({ children }) => {
  return <div className="cui-popup__form">{children}</div>
}

PopupForm.propTypes = {
  children: PropTypes.node,
}

export default PopupForm
