import React from 'react'
import PropTypes from 'prop-types'

const HeroText = ({ children }) => {
  return <div className="cui-hero__text">{children}</div>
}

HeroText.propTypes = {
  // HeroText children
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
}

export default HeroText
