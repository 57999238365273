import React from 'react'
import PropTypes from 'prop-types'

const HeroImg = ({ children, src, alt }) => {
  return (
    <div className="cui-hero__img">
      {src && <img src={src} alt={alt} />}
      {children}
    </div>
  )
}

HeroImg.propTypes = {
  // HeroImg children
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  src: PropTypes.string,
  alt: PropTypes.string,
}

export default HeroImg
