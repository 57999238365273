import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Modal from '../Modal'

const PopupBare = ({ visible, children, className, ...restProps }) => {
  const [active, setActive] = React.useState(true)

  const _PopupBare = classnames('cui-popup bare', className)

  React.useEffect(() => {
    setActive(visible)
  }, [visible])

  const handleClose = () => {
    setActive(false)
  }
  return (
    <Modal
      {...restProps}
      visible={active}
      onEscapeKeyDown={handleClose}
      handleClose={handleClose}
      className={_PopupBare}
    >
      {children}
    </Modal>
  )
}

PopupBare.defaultProps = {
  visible: true,
}

PopupBare.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
}

export default PopupBare
