import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const FieldUnit = ({ children, after, before }) => {
  const _FieldUnit = classnames('cui-field_unit', {
    'lm--after-addon': after,
    'lm--before-addon': before,
  })
  return <span className={_FieldUnit}>{children}</span>
}

FieldUnit.propTypes = {
  after: PropTypes.bool,
  before: PropTypes.bool,
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
}
FieldUnit.defaultProps = {
  after: false,
  before: false,
}

export default FieldUnit
