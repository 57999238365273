// organize-imports-ignore
import React, { useEffect } from 'react'
import * as d3 from 'd3'
import PropTypes from 'prop-types'

import { useChartXY } from './ChartXY'

const ChartConstantLine = ({
  value,
  dashed,
  color,
  width,
  strokeDashArray,
}) => {
  const lineRef = React.createRef()
  const isArrColor = Array.isArray(color)
  const { parentHeight, parentWidth, interval, maxValue, direction } =
    useChartXY()

  const isVertical = direction === 'vertical'

  useEffect(() => {
    let points = []
    if (isVertical) {
      const ratio = (parentHeight - parentHeight / interval) / maxValue
      const yConstant = parentHeight - value * ratio
      points = [
        {
          x: 0,
          y: yConstant,
        },
        {
          x: parentWidth,
          y: yConstant,
        },
      ]
    } else {
      const ratio = (parentWidth - parentWidth / interval) / maxValue
      const xConstant = value * ratio
      points = [
        {
          x: xConstant,
          y: 0,
        },
        {
          x: xConstant,
          y: parentHeight,
        },
      ]
    }

    const GenStraightLine = d3
      .line()
      .x(p => p.x)
      .y(p => p.y)

    const lineSVG = d3.select(lineRef.current)

    lineSVG.selectAll('.cuiConstantLine').remove()

    lineSVG
      .append('path')
      .datum(points)
      .attr('class', 'cuiConstantLine')
      .attr('d', GenStraightLine(points))
      .attr('width', width)
      .attr('stroke', isArrColor ? color[0] : color)
      .attr('stroke-dasharray', dashed ? strokeDashArray : 0)
  }, [
    dashed,
    interval,
    isArrColor,
    lineRef,
    maxValue,
    value,
    width,
    color,
    parentWidth,
    parentHeight,
    strokeDashArray,
    isVertical,
  ])

  return <g ref={lineRef} />
}

ChartConstantLine.propTypes = {
  color: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dashed: PropTypes.bool,
  value: PropTypes.number,
  strokeDashArray: PropTypes.oneOfType([PropTypes.number, PropTypes.array]),
}

ChartConstantLine.defaultProps = {
  color: '#818b92',
  width: 1,
  dashed: false,
  strokeDash: 4,
}

export default ChartConstantLine
