import { Component } from 'react'
import ReactDOM from 'react-dom'

export default class Portal extends Component {
	constructor (props) {
		super(props)
		this.mountNode = this.props.container || document.body
	}
	
	render () {
		const {children} = this.props
		return ReactDOM.createPortal(children, this.mountNode)
	}
}
