import React from 'react'

class SocialShare extends React.PureComponent {
  render() {
    return (
      <g className="illus-social-share">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M180.204 34.8508C165.763 33.8478 151.386 31.7468 137.329 27.8618C127.6 25.1718 117.637 23.5778 107.631 23.1218C83.8734 22.0378 58.7704 28.0698 41.1044 46.1108C23.4394 64.1518 15.7414 96.0668 27.9204 119.216C40.0274 142.231 66.0714 149.673 89.5714 152.574C110.724 155.184 132.065 155.816 153.308 154.458C172.036 153.263 192.301 149.646 205.21 134.22C215.652 121.742 219.264 103.167 216.789 86.1978C214.313 69.2278 206.328 53.7808 196.294 40.8788C193.096 36.7678 186.984 35.3218 180.204 34.8508Z"
          fill="#F8FCFF"
        />
        <mask
          id="social-share-mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="240"
          height="180"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M180.204 34.8508C165.763 33.8478 151.386 31.7468 137.329 27.8618C127.6 25.1718 117.637 23.5778 107.631 23.1218C83.8734 22.0378 58.7704 28.0698 41.1044 46.1108C23.4394 64.1518 15.7414 96.0668 27.9204 119.216C40.0274 142.231 66.0714 149.673 89.5714 152.574C110.724 155.184 132.065 155.816 153.308 154.458C172.036 153.263 192.301 149.646 205.21 134.22C215.652 121.742 219.264 103.167 216.789 86.1978C214.313 69.2278 206.328 53.7808 196.294 40.8788C193.096 36.7678 186.984 35.3218 180.204 34.8508Z"
            fill="#F8FCFF"
          />
        </mask>
        <g mask="url(#social-share-mask0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M160.834 225.727H79.2719C69.112 225.727 60.7996 217.583 60.7996 207.63V23.9749C60.7996 14.0214 69.112 5.87793 79.2719 5.87793H160.834C170.993 5.87793 179.306 14.0214 179.306 23.9749V207.63C179.306 217.583 170.993 225.727 160.834 225.727Z"
            fill="url(#social-share-paint0_linear)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M93.5263 17.7014C93.5263 20.2746 95.6561 22.3611 98.2827 22.3611H141.823C144.45 22.3611 146.58 20.2746 146.58 17.7014V15.2744H162.256C167.122 15.2744 171.067 19.1384 171.067 23.9053V207.699C171.067 212.465 167.122 216.33 162.256 216.33H77.8504C72.9839 216.33 69.0391 212.465 69.0391 207.699V23.9053C69.0391 19.1384 72.9839 15.2744 77.8504 15.2744H93.5263V17.7014Z"
            fill="white"
          />
        </g>
        <mask
          id="social-share-mask1"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="39"
          y="14"
          width="163"
          height="123"
        >
          <rect x="39" y="14.0869" width="163" height="122" fill="white" />
        </mask>
        <g mask="url(#social-share-mask1)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M160.834 235.64H79.2719C69.112 235.64 60.7996 227.496 60.7996 217.543V33.888C60.7996 23.9345 69.112 15.791 79.2719 15.791H160.834C170.993 15.791 179.306 23.9345 179.306 33.888V217.543C179.306 227.496 170.993 235.64 160.834 235.64Z"
            fill="url(#social-share-paint1_linear)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M93.5263 27.6145C93.5263 30.1877 95.6561 32.2742 98.2827 32.2742H141.823C144.45 32.2742 146.58 30.1877 146.58 27.6145V25.1875H162.256C167.122 25.1875 171.067 29.0515 171.067 33.8184V217.612C171.067 222.378 167.122 226.243 162.256 226.243H77.8504C72.9839 226.243 69.0391 222.378 69.0391 217.612V33.8184C69.0391 29.0515 72.9839 25.1875 77.8504 25.1875H93.5263V27.6145Z"
            fill="white"
          />
        </g>
        <path
          d="M85.0219 46.554C85.034 44.0688 83.0292 42.042 80.544 42.0271C78.0588 42.0122 76.0342 44.0148 76.022 46.5001C76.0099 48.9853 78.0147 51.0121 80.4999 51.027C82.9852 51.0419 85.0097 49.0393 85.0219 46.554Z"
          fill="#E4EBF2"
        />
        <path
          d="M116.32 45H91.6634C90.7501 45 90 44.3235 90 43.5C90 42.6765 90.7338 42 91.6634 42H116.337C117.25 42 118 42.6765 118 43.5C118 44.3382 117.25 45 116.32 45Z"
          fill="#E4EBF2"
        />
        <path
          d="M156.32 51H91.6634C90.7501 51 90 50.3235 90 49.5C90 48.6765 90.7338 48 91.6634 48H156.337C157.25 48 158 48.6765 158 49.5C158 50.3382 157.25 51 156.32 51Z"
          fill="#E4EBF2"
        />
        <path
          d="M99.6877 116.923H89.0111C88.0969 116.923 87.3459 116.171 87.3459 115.255C87.3459 114.338 88.0969 113.586 89.0111 113.586H99.6877C100.602 113.586 101.353 114.338 101.353 115.255C101.353 116.171 100.618 116.923 99.6877 116.923Z"
          fill="#E4EBF2"
        />
        <path
          d="M153.756 114.257H123.718C123.163 114.257 122.722 113.815 122.722 113.259C122.722 112.702 123.163 112.261 123.718 112.261H153.756C154.311 112.261 154.752 112.702 154.752 113.259C154.752 113.799 154.295 114.257 153.756 114.257Z"
          fill="#E4EBF2"
        />
        <path
          d="M160.45 117.595H123.718C123.163 117.595 122.722 117.153 122.722 116.597C122.722 116.04 123.163 115.599 123.718 115.599H160.45C161.005 115.599 161.445 116.04 161.445 116.597C161.445 117.153 161.005 117.595 160.45 117.595Z"
          fill="#E4EBF2"
        />
        <path d="M164 58H76V107H164V58Z" fill="#E4EBF2" />
        <path
          d="M80.669 112.915C80.669 112.915 80.3425 111.246 78.6611 111.246C77.6652 111.246 76.6531 112.244 76.6531 113.586C76.6531 116.269 80.6527 118.935 80.6527 118.935C80.6527 118.935 84.6524 116.252 84.6524 113.586C84.6524 112.244 83.6565 111.246 82.6444 111.246C80.9955 111.246 80.669 112.915 80.669 112.915Z"
          fill="#F65B7D"
        />
        <path
          d="M41.5328 68.1136C41.5328 68.1136 40.0888 65.2707 37.0557 66.1323C35.2594 66.6425 33.9449 68.9613 34.6323 71.3812C36.007 76.221 44.5881 78.9819 44.5881 78.9819C44.5881 78.9819 50.4281 72.0928 49.0617 67.2825C48.3744 64.8626 46.0667 63.5727 44.2409 64.0913C41.2667 64.9361 41.5328 68.1136 41.5328 68.1136Z"
          fill="#F65B7D"
        />
        <path
          d="M117.384 112.948V114.895C117.384 116.024 116.47 116.94 115.344 116.94H114.446L114.038 118.952L112.209 116.94H110.74C109.613 116.94 108.699 116.024 108.699 114.895V112.948C108.699 111.819 109.613 110.903 110.74 110.903H115.344C116.47 110.903 117.384 111.819 117.384 112.948Z"
          fill="#3176DE"
        />
        <path
          d="M115.033 113.586H111.034C110.854 113.586 110.707 113.439 110.707 113.259C110.707 113.079 110.854 112.932 111.034 112.932H115.033C115.213 112.932 115.36 113.079 115.36 113.259C115.376 113.439 115.229 113.586 115.033 113.586Z"
          fill="#F4F4F4"
        />
        <path
          d="M115.033 114.911H111.034C110.854 114.911 110.707 114.764 110.707 114.584C110.707 114.404 110.854 114.257 111.034 114.257H115.033C115.213 114.257 115.36 114.404 115.36 114.584C115.376 114.764 115.229 114.911 115.033 114.911Z"
          fill="#F4F4F4"
        />
        <path
          d="M189.499 121.502L188.069 124.731C187.24 126.604 185.051 127.452 183.183 126.625L181.694 125.965L179.539 129.003L177.984 124.323L175.547 123.244C173.679 122.417 172.835 120.227 173.664 118.354L175.094 115.125C175.923 113.253 178.112 112.405 179.98 113.232L187.616 116.612C189.484 117.439 190.327 119.63 189.499 121.502Z"
          fill="#3176DE"
        />
        <path
          d="M185.131 120.834L178.497 117.897C178.199 117.766 178.063 117.413 178.196 117.115C178.328 116.816 178.679 116.68 178.977 116.812L185.611 119.749C185.909 119.881 186.044 120.233 185.912 120.531C185.807 120.842 185.455 120.978 185.131 120.834Z"
          fill="#F4F4F4"
        />
        <path
          d="M184.158 123.031L177.524 120.095C177.226 119.963 177.09 119.611 177.223 119.312C177.355 119.014 177.707 118.877 178.004 119.009L184.638 121.946C184.936 122.078 185.072 122.43 184.939 122.729C184.834 123.039 184.483 123.175 184.158 123.031Z"
          fill="#F4F4F4"
        />
        <path
          d="M163 127H77C76.4477 127 76 127.448 76 128C76 128.552 76.4477 129 77 129H163C163.552 129 164 128.552 164 128C164 127.448 163.552 127 163 127Z"
          fill="#E4EBF2"
        />
        <path
          d="M165.27 84.4777C162.496 91.0773 165.606 98.6778 172.227 101.472C178.849 104.266 186.459 101.178 189.225 94.5903C191.999 87.9907 188.877 80.3824 182.268 77.5962C175.646 74.8022 168.044 77.8781 165.27 84.4777Z"
          fill="#FFC466"
        />
        <path
          d="M174.999 94.9559C172.981 94.1001 171.325 92.6161 170.256 90.7251C169.976 90.2296 170.222 89.6193 170.746 89.3961C171.006 89.2851 171.305 89.4071 171.432 89.6602C172.334 91.4617 173.851 92.847 175.738 93.6059C177.624 94.3643 179.678 94.3985 181.566 93.7242C181.834 93.6286 182.137 93.7478 182.249 94.0089C182.475 94.5327 182.234 95.1461 181.689 95.3152C179.557 95.9777 177.279 95.8853 175.166 95.0453C175.125 95.0018 175.056 94.9749 174.999 94.9559Z"
          fill="#4A4A7C"
        />
        <path
          d="M172.137 82.7705C171.505 84.2754 171.961 85.8887 173.157 86.3936C174.354 86.8984 175.835 86.09 176.459 84.5974C177.082 83.1047 176.635 81.4792 175.438 80.9743C174.241 80.4695 172.76 81.2779 172.137 82.7705Z"
          fill="#4A4A7C"
        />
        <path
          d="M181.143 86.5874C180.512 88.0923 180.968 89.7056 182.164 90.2105C183.361 90.7153 184.842 89.9069 185.466 88.4143C186.097 86.9094 185.649 85.2838 184.453 84.779C183.256 84.2741 181.775 85.0826 181.143 86.5874Z"
          fill="#4A4A7C"
        />
        <path
          d="M42.4444 119.918C45.26 122.758 49.8476 122.774 52.7027 119.951C55.5578 117.128 55.5868 112.54 52.7758 109.707C49.9601 106.867 45.3659 106.855 42.5174 109.673C39.6623 112.496 39.6287 117.077 42.4444 119.918Z"
          fill="#FFC466"
        />
        <path
          d="M49.9297 117.129C49.0574 117.989 47.9379 118.527 46.7336 118.675C46.4181 118.714 46.1554 118.457 46.1519 118.139C46.1501 117.981 46.277 117.853 46.4347 117.841C47.5573 117.76 48.5962 117.272 49.3905 116.459C50.1843 115.648 50.6401 114.595 50.6949 113.476C50.7026 113.317 50.8287 113.186 50.9875 113.184C51.3061 113.179 51.5714 113.434 51.5425 113.752C51.4296 114.994 50.896 116.15 50.0116 117.062C49.9803 117.074 49.9517 117.104 49.9297 117.129Z"
          fill="#4A4A7C"
        />
        <path
          d="M43.0279 116.008C43.6702 116.655 44.6004 116.764 45.1163 116.254C45.6322 115.744 45.5308 114.807 44.8931 114.166C44.2555 113.526 43.3207 113.41 42.8048 113.92C42.2889 114.431 42.3902 115.368 43.0279 116.008Z"
          fill="#4A4A7C"
        />
        <path
          d="M46.9195 112.172C47.5618 112.819 48.492 112.928 49.0079 112.418C49.5238 111.908 49.4224 110.97 48.7847 110.33C48.1424 109.683 47.2076 109.568 46.6917 110.078C46.1758 110.588 46.2772 111.525 46.9195 112.172Z"
          fill="#4A4A7C"
        />
        <path
          d="M99.7503 83.9624C98.4675 86.5794 88.4927 92.4292 80.429 95.9699L81.6333 103L75.5856 97.8686C73.4388 98.587 71.7109 98.9718 70.7684 98.7922C66.2654 97.9199 56.8142 83.3209 58.1232 79.3184C59.9035 73.9047 82.4188 62.9234 86.8695 63.0004C91.3201 63.103 101.74 79.9598 99.7503 83.9624Z"
          fill="#00B0B2"
        />
        <path
          d="M72 82.8887L75.217 89C75.217 89 77.8648 87.5215 81.2551 86.1169C83.6059 85.1312 85.7341 84.5644 86.427 83.5048C88.1345 80.9174 85.7589 75.7425 82.8141 75.6686C80.29 75.5946 78.0875 78.7981 78.038 77.221C77.9638 75.6439 77.9391 73.3029 76.2069 72.2679C74.1034 71.0112 75.6872 74.4857 75.5139 76.26C75.2417 78.6996 74.5984 80.4245 72 82.8887Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="social-share-paint0_linear"
            x1="47.172"
            y1="36.3901"
            x2="93.423"
            y2="57.0471"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7C8084" />
            <stop offset="1" stopColor="#5E5E70" />
          </linearGradient>
          <linearGradient
            id="social-share-paint1_linear"
            x1="47.172"
            y1="46.3032"
            x2="93.423"
            y2="66.9602"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7C8084" />
            <stop offset="1" stopColor="#5E5E70" />
          </linearGradient>
        </defs>
      </g>
    )
  }
}

export default SocialShare
