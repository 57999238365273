import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { Field, useFormikContext } from 'formik'

import FieldError from '../FieldError'
import FieldLabel from '../FieldLabel'

const TextAreaComponent = ({ field, form, ...props }) => {
  const { handleTextareaChange, ...otherProps } = props
  return <textarea {...field} {...otherProps} onChange={handleTextareaChange} />
}

const FieldTextarea = ({
  spec: { ...otherSpec },
  rows,
  isInline,
  children,
}) => {
  const { errors, touched, handleChange, setFieldError } = useFormikContext()
  const hasError = touched[otherSpec.name] && errors[otherSpec.name]
  const _FormTextarea = classnames('lm--input cui-field cui-field_textarea', {
    'is-inline': isInline,
    'has-error': hasError,
  })
  const [remaining, setRemaining] = useState(otherSpec.maxlength)

  const checkValueForMinMax = value => {
    if (otherSpec.maxlength > 0) {
      if (remaining > 0) {
        setRemaining(otherSpec.maxlength - value.length)
      } else {
        setRemaining(0)
      }
    }
    if (otherSpec.minlength > 0) {
      if (value.length < otherSpec.minlength) {
        setFieldError(
          otherSpec.name,
          `Textarea should have a minimum characters of ${otherSpec.minlength}`
        )
      }
    }
  }

  const handleTextareaChange = e => {
    const { value } = e.target
    checkValueForMinMax(value)
    handleChange(e)
  }

  return (
    <div className="lm--input-group cui-field_group">
      <div className={_FormTextarea}>
        {React.Children.map(children, (child, childIdx) => {
          if (child.type === FieldLabel) {
            return <FieldLabel {...child.props} />
          }
          return null
        })}
        <div className="cui-field_control">
          {otherSpec.maxlength > 0 && (
            <span className="control-counter">{`[${remaining}]`}</span>
          )}
          <Field
            id={otherSpec.id}
            name={otherSpec.name}
            placeholder={otherSpec.placeholder}
            rows={rows || '10'}
            maxLength={otherSpec.maxlength}
            minLength={otherSpec.minlength}
            handleTextareaChange={handleTextareaChange}
            component={TextAreaComponent}
          />
          {hasError && <FieldError>{errors[otherSpec.name]}</FieldError>}
        </div>
      </div>
    </div>
  )
}

FieldTextarea.defaultProps = {
  isInline: true,
  spec: {},
  children: null,
}

FieldTextarea.propTypes = {
  isInline: PropTypes.bool,
  spec: PropTypes.object.isRequired,
  children: PropTypes.object,
}

export default FieldTextarea
