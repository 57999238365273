import React from 'react'

const LVB = () => {
  return (
    <>
      <g className="illus-lvb">
        <rect x="1" width="1.251" height="80" fill="#95AFAE" />
        <rect x="40.876" width="1.25101" height="80" fill="#95AFAE" />
        <rect
          width="38.6248"
          height="78.4896"
          transform="matrix(-1 0 0 1 40.876 0.676758)"
          fill="#95AFAE"
        />
        <g filter="url(#illus-lvb_filter0_d)">
          <rect
            x="2.25098"
            y="10.333"
            width="38.6248"
            height="59.3333"
            fill="#C4C4C4"
          />
        </g>
        <rect
          width="0.151489"
          height="78.4896"
          transform="matrix(-1 0 0 1 2.40234 0.676758)"
          fill="#8AA5A5"
        />
        <rect
          width="0.151485"
          height="78.4896"
          transform="matrix(-1 0 0 1 40.876 0.676758)"
          fill="#8AA5A5"
        />
        <rect
          width="19.3906"
          height="59.3333"
          transform="matrix(-1 0 0 1 40.876 10.333)"
          fill="#95AFAE"
        />
        <rect
          width="19.2342"
          height="59.3333"
          transform="matrix(-1 0 0 1 21.4854 10.333)"
          fill="#95AFAE"
        />
        <rect
          width="0.156376"
          height="59.3333"
          transform="matrix(-1 0 0 1 21.4854 10.333)"
          fill="#8AA5A5"
        />
        <rect
          x="23.9873"
          y="15.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="24.769"
          y="15.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="25.5513"
          y="15.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="26.333"
          y="15.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.1147"
          y="15.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.8965"
          y="15.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="28.6787"
          y="15.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="29.4604"
          y="15.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect x="23.9873" y="17" width="0.469126" height="1" fill="#445B5B" />
        <rect x="24.769" y="17" width="0.469128" height="1" fill="#445B5B" />
        <rect x="25.5513" y="17" width="0.469126" height="1" fill="#445B5B" />
        <rect x="26.333" y="17" width="0.469126" height="1" fill="#445B5B" />
        <rect x="27.1147" y="17" width="0.469126" height="1" fill="#445B5B" />
        <rect x="27.8965" y="17" width="0.469126" height="1" fill="#445B5B" />
        <rect x="28.6787" y="17" width="0.469126" height="1" fill="#445B5B" />
        <rect x="29.4604" y="17" width="0.469126" height="1" fill="#445B5B" />
        <rect
          x="23.9873"
          y="18.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="24.769"
          y="18.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="25.5513"
          y="18.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="26.333"
          y="18.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.1147"
          y="18.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.8965"
          y="18.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="28.6787"
          y="18.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="29.4604"
          y="18.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="23.9873"
          y="20.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="24.769"
          y="20.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="25.5513"
          y="20.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="26.333"
          y="20.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.1147"
          y="20.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.8965"
          y="20.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="28.6787"
          y="20.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="29.4604"
          y="20.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect x="23.9873" y="22" width="0.469126" height="1" fill="#445B5B" />
        <rect x="24.769" y="22" width="0.469128" height="1" fill="#445B5B" />
        <rect x="25.5513" y="22" width="0.469126" height="1" fill="#445B5B" />
        <rect x="26.333" y="22" width="0.469126" height="1" fill="#445B5B" />
        <rect x="27.1147" y="22" width="0.469126" height="1" fill="#445B5B" />
        <rect x="27.8965" y="22" width="0.469126" height="1" fill="#445B5B" />
        <rect x="28.6787" y="22" width="0.469126" height="1" fill="#445B5B" />
        <rect x="29.4604" y="22" width="0.469126" height="1" fill="#445B5B" />
        <rect
          x="23.9873"
          y="23.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="24.769"
          y="23.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="25.5513"
          y="23.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="26.333"
          y="23.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.1147"
          y="23.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.8965"
          y="23.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="28.6787"
          y="23.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="29.4604"
          y="23.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="23.9873"
          y="25.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="24.769"
          y="25.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="25.5513"
          y="25.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="26.333"
          y="25.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.1147"
          y="25.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.8965"
          y="25.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="28.6787"
          y="25.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="29.4604"
          y="25.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect x="23.9873" y="27" width="0.469126" height="1" fill="#445B5B" />
        <rect x="24.769" y="27" width="0.469128" height="1" fill="#445B5B" />
        <rect x="25.5513" y="27" width="0.469126" height="1" fill="#445B5B" />
        <rect x="26.333" y="27" width="0.469126" height="1" fill="#445B5B" />
        <rect x="27.1147" y="27" width="0.469126" height="1" fill="#445B5B" />
        <rect x="27.8965" y="27" width="0.469126" height="1" fill="#445B5B" />
        <rect x="28.6787" y="27" width="0.469126" height="1" fill="#445B5B" />
        <rect x="29.4604" y="27" width="0.469126" height="1" fill="#445B5B" />
        <rect
          x="23.9873"
          y="28.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="24.769"
          y="28.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="25.5513"
          y="28.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="26.333"
          y="28.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.1147"
          y="28.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.8965"
          y="28.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="28.6787"
          y="28.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="29.4604"
          y="28.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="23.9873"
          y="30.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="24.769"
          y="30.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="25.5513"
          y="30.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="26.333"
          y="30.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.1147"
          y="30.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.8965"
          y="30.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="28.6787"
          y="30.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="29.4604"
          y="30.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect x="23.9873" y="32" width="0.469126" height="1" fill="#445B5B" />
        <rect x="24.769" y="32" width="0.469128" height="1" fill="#445B5B" />
        <rect x="25.5513" y="32" width="0.469126" height="1" fill="#445B5B" />
        <rect x="26.333" y="32" width="0.469126" height="1" fill="#445B5B" />
        <rect x="27.1147" y="32" width="0.469126" height="1" fill="#445B5B" />
        <rect x="27.8965" y="32" width="0.469126" height="1" fill="#445B5B" />
        <rect x="28.6787" y="32" width="0.469126" height="1" fill="#445B5B" />
        <rect x="29.4604" y="32" width="0.469126" height="1" fill="#445B5B" />
        <rect
          x="23.9873"
          y="33.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="24.769"
          y="33.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="25.5513"
          y="33.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="26.333"
          y="33.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.1147"
          y="33.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.8965"
          y="33.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="28.6787"
          y="33.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="29.4604"
          y="33.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="23.9873"
          y="35.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="24.769"
          y="35.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="25.5513"
          y="35.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="26.333"
          y="35.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.1147"
          y="35.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.8965"
          y="35.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="28.6787"
          y="35.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="29.4604"
          y="35.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect x="23.9873" y="37" width="0.469126" height="1" fill="#445B5B" />
        <rect x="24.769" y="37" width="0.469128" height="1" fill="#445B5B" />
        <rect x="25.5513" y="37" width="0.469126" height="1" fill="#445B5B" />
        <rect x="26.333" y="37" width="0.469126" height="1" fill="#445B5B" />
        <rect x="27.1147" y="37" width="0.469126" height="1" fill="#445B5B" />
        <rect x="27.8965" y="37" width="0.469126" height="1" fill="#445B5B" />
        <rect x="28.6787" y="37" width="0.469126" height="1" fill="#445B5B" />
        <rect x="29.4604" y="37" width="0.469126" height="1" fill="#445B5B" />
        <rect
          x="32.4316"
          y="15.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.2134"
          y="15.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.9956"
          y="15.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="34.7773"
          y="15.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="35.5591"
          y="15.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="36.3408"
          y="15.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.123"
          y="15.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.9048"
          y="15.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect x="32.4316" y="17" width="0.469126" height="1" fill="#445B5B" />
        <rect x="33.2134" y="17" width="0.469128" height="1" fill="#445B5B" />
        <rect x="33.9956" y="17" width="0.469128" height="1" fill="#445B5B" />
        <rect x="34.7773" y="17" width="0.469128" height="1" fill="#445B5B" />
        <rect x="35.5591" y="17" width="0.469128" height="1" fill="#445B5B" />
        <rect x="36.3408" y="17" width="0.469128" height="1" fill="#445B5B" />
        <rect x="37.123" y="17" width="0.469128" height="1" fill="#445B5B" />
        <rect x="37.9048" y="17" width="0.469128" height="1" fill="#445B5B" />
        <rect
          x="32.4316"
          y="18.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.2134"
          y="18.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.9956"
          y="18.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="34.7773"
          y="18.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="35.5591"
          y="18.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="36.3408"
          y="18.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.123"
          y="18.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.9048"
          y="18.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="32.4316"
          y="20.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.2134"
          y="20.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.9956"
          y="20.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="34.7773"
          y="20.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="35.5591"
          y="20.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="36.3408"
          y="20.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.123"
          y="20.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.9048"
          y="20.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect x="32.4316" y="22" width="0.469126" height="1" fill="#445B5B" />
        <rect x="33.2134" y="22" width="0.469128" height="1" fill="#445B5B" />
        <rect x="33.9956" y="22" width="0.469128" height="1" fill="#445B5B" />
        <rect x="34.7773" y="22" width="0.469128" height="1" fill="#445B5B" />
        <rect x="35.5591" y="22" width="0.469128" height="1" fill="#445B5B" />
        <rect x="36.3408" y="22" width="0.469128" height="1" fill="#445B5B" />
        <rect x="37.123" y="22" width="0.469128" height="1" fill="#445B5B" />
        <rect x="37.9048" y="22" width="0.469128" height="1" fill="#445B5B" />
        <rect
          x="32.4316"
          y="23.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.2134"
          y="23.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.9956"
          y="23.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="34.7773"
          y="23.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="35.5591"
          y="23.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="36.3408"
          y="23.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.123"
          y="23.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.9048"
          y="23.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="32.4316"
          y="25.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.2134"
          y="25.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.9956"
          y="25.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="34.7773"
          y="25.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="35.5591"
          y="25.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="36.3408"
          y="25.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.123"
          y="25.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.9048"
          y="25.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect x="32.4316" y="27" width="0.469126" height="1" fill="#445B5B" />
        <rect x="33.2134" y="27" width="0.469128" height="1" fill="#445B5B" />
        <rect x="33.9956" y="27" width="0.469128" height="1" fill="#445B5B" />
        <rect x="34.7773" y="27" width="0.469128" height="1" fill="#445B5B" />
        <rect x="35.5591" y="27" width="0.469128" height="1" fill="#445B5B" />
        <rect x="36.3408" y="27" width="0.469128" height="1" fill="#445B5B" />
        <rect x="37.123" y="27" width="0.469128" height="1" fill="#445B5B" />
        <rect x="37.9048" y="27" width="0.469128" height="1" fill="#445B5B" />
        <rect
          x="32.4316"
          y="28.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.2134"
          y="28.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.9956"
          y="28.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="34.7773"
          y="28.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="35.5591"
          y="28.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="36.3408"
          y="28.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.123"
          y="28.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.9048"
          y="28.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="32.4316"
          y="30.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.2134"
          y="30.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.9956"
          y="30.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="34.7773"
          y="30.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="35.5591"
          y="30.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="36.3408"
          y="30.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.123"
          y="30.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.9048"
          y="30.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect x="32.4316" y="32" width="0.469126" height="1" fill="#445B5B" />
        <rect x="33.2134" y="32" width="0.469128" height="1" fill="#445B5B" />
        <rect x="33.9956" y="32" width="0.469128" height="1" fill="#445B5B" />
        <rect x="34.7773" y="32" width="0.469128" height="1" fill="#445B5B" />
        <rect x="35.5591" y="32" width="0.469128" height="1" fill="#445B5B" />
        <rect x="36.3408" y="32" width="0.469128" height="1" fill="#445B5B" />
        <rect x="37.123" y="32" width="0.469128" height="1" fill="#445B5B" />
        <rect x="37.9048" y="32" width="0.469128" height="1" fill="#445B5B" />
        <rect
          x="32.4316"
          y="33.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.2134"
          y="33.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.9956"
          y="33.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="34.7773"
          y="33.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="35.5591"
          y="33.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="36.3408"
          y="33.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.123"
          y="33.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.9048"
          y="33.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="32.4316"
          y="35.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.2134"
          y="35.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.9956"
          y="35.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="34.7773"
          y="35.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="35.5591"
          y="35.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="36.3408"
          y="35.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.123"
          y="35.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.9048"
          y="35.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect x="32.4316" y="37" width="0.469126" height="1" fill="#445B5B" />
        <rect x="33.2134" y="37" width="0.469128" height="1" fill="#445B5B" />
        <rect x="33.9956" y="37" width="0.469128" height="1" fill="#445B5B" />
        <rect x="34.7773" y="37" width="0.469128" height="1" fill="#445B5B" />
        <rect x="35.5591" y="37" width="0.469128" height="1" fill="#445B5B" />
        <rect x="36.3408" y="37" width="0.469128" height="1" fill="#445B5B" />
        <rect x="37.123" y="37" width="0.469128" height="1" fill="#445B5B" />
        <rect x="37.9048" y="37" width="0.469128" height="1" fill="#445B5B" />
        <rect
          x="23.9873"
          y="42"
          width="0.469126"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="24.769"
          y="42"
          width="0.469128"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="25.5513"
          y="42"
          width="0.469126"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="26.333"
          y="42"
          width="0.469126"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="27.1147"
          y="42"
          width="0.469126"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="27.8965"
          y="42"
          width="0.469126"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="28.6787"
          y="42"
          width="0.469126"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="29.4604"
          y="42"
          width="0.469126"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="23.9873"
          y="43.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="24.769"
          y="43.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="25.5513"
          y="43.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="26.333"
          y="43.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.1147"
          y="43.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.8965"
          y="43.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="28.6787"
          y="43.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="29.4604"
          y="43.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="23.9873"
          y="45.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="24.769"
          y="45.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="25.5513"
          y="45.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="26.333"
          y="45.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.1147"
          y="45.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.8965"
          y="45.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="28.6787"
          y="45.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="29.4604"
          y="45.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect x="23.9873" y="47" width="0.469126" height="1" fill="#445B5B" />
        <rect x="24.769" y="47" width="0.469128" height="1" fill="#445B5B" />
        <rect x="25.5513" y="47" width="0.469126" height="1" fill="#445B5B" />
        <rect x="26.333" y="47" width="0.469126" height="1" fill="#445B5B" />
        <rect x="27.1147" y="47" width="0.469126" height="1" fill="#445B5B" />
        <rect x="27.8965" y="47" width="0.469126" height="1" fill="#445B5B" />
        <rect x="28.6787" y="47" width="0.469126" height="1" fill="#445B5B" />
        <rect x="29.4604" y="47" width="0.469126" height="1" fill="#445B5B" />
        <rect
          x="23.9873"
          y="48.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="24.769"
          y="48.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="25.5513"
          y="48.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="26.333"
          y="48.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.1147"
          y="48.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.8965"
          y="48.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="28.6787"
          y="48.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="29.4604"
          y="48.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="23.9873"
          y="50.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="24.769"
          y="50.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="25.5513"
          y="50.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="26.333"
          y="50.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.1147"
          y="50.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.8965"
          y="50.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="28.6787"
          y="50.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="29.4604"
          y="50.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect x="23.9873" y="52" width="0.469126" height="1" fill="#445B5B" />
        <rect x="24.769" y="52" width="0.469128" height="1" fill="#445B5B" />
        <rect x="25.5513" y="52" width="0.469126" height="1" fill="#445B5B" />
        <rect x="26.333" y="52" width="0.469126" height="1" fill="#445B5B" />
        <rect x="27.1147" y="52" width="0.469126" height="1" fill="#445B5B" />
        <rect x="27.8965" y="52" width="0.469126" height="1" fill="#445B5B" />
        <rect x="28.6787" y="52" width="0.469126" height="1" fill="#445B5B" />
        <rect x="29.4604" y="52" width="0.469126" height="1" fill="#445B5B" />
        <rect
          x="23.9873"
          y="53.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="24.769"
          y="53.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="25.5513"
          y="53.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="26.333"
          y="53.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.1147"
          y="53.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.8965"
          y="53.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="28.6787"
          y="53.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="29.4604"
          y="53.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="23.9873"
          y="55.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="24.769"
          y="55.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="25.5513"
          y="55.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="26.333"
          y="55.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.1147"
          y="55.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.8965"
          y="55.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="28.6787"
          y="55.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="29.4604"
          y="55.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect x="23.9873" y="57" width="0.469126" height="1" fill="#445B5B" />
        <rect x="24.769" y="57" width="0.469128" height="1" fill="#445B5B" />
        <rect x="25.5513" y="57" width="0.469126" height="1" fill="#445B5B" />
        <rect x="26.333" y="57" width="0.469126" height="1" fill="#445B5B" />
        <rect x="27.1147" y="57" width="0.469126" height="1" fill="#445B5B" />
        <rect x="27.8965" y="57" width="0.469126" height="1" fill="#445B5B" />
        <rect x="28.6787" y="57" width="0.469126" height="1" fill="#445B5B" />
        <rect x="29.4604" y="57" width="0.469126" height="1" fill="#445B5B" />
        <rect
          x="23.9873"
          y="58.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="24.769"
          y="58.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="25.5513"
          y="58.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="26.333"
          y="58.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.1147"
          y="58.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.8965"
          y="58.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="28.6787"
          y="58.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="29.4604"
          y="58.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="23.9873"
          y="60.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="24.769"
          y="60.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="25.5513"
          y="60.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="26.333"
          y="60.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.1147"
          y="60.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="27.8965"
          y="60.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="28.6787"
          y="60.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="29.4604"
          y="60.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect x="23.9873" y="62" width="0.469126" height="1" fill="#445B5B" />
        <rect x="24.769" y="62" width="0.469128" height="1" fill="#445B5B" />
        <rect x="25.5513" y="62" width="0.469126" height="1" fill="#445B5B" />
        <rect x="26.333" y="62" width="0.469126" height="1" fill="#445B5B" />
        <rect x="27.1147" y="62" width="0.469126" height="1" fill="#445B5B" />
        <rect x="27.8965" y="62" width="0.469126" height="1" fill="#445B5B" />
        <rect x="28.6787" y="62" width="0.469126" height="1" fill="#445B5B" />
        <rect x="29.4604" y="62" width="0.469126" height="1" fill="#445B5B" />
        <rect
          x="23.9873"
          y="63.667"
          width="0.469126"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="24.769"
          y="63.667"
          width="0.469128"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="25.5513"
          y="63.667"
          width="0.469126"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="26.333"
          y="63.667"
          width="0.469126"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="27.1147"
          y="63.667"
          width="0.469126"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="27.8965"
          y="63.667"
          width="0.469126"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="28.6787"
          y="63.667"
          width="0.469126"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="29.4604"
          y="63.667"
          width="0.469126"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="32.4316"
          y="42"
          width="0.469126"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="33.2134"
          y="42"
          width="0.469128"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="33.9956"
          y="42"
          width="0.469128"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="34.7773"
          y="42"
          width="0.469128"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="35.5591"
          y="42"
          width="0.469128"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="36.3408"
          y="42"
          width="0.469128"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="37.123"
          y="42"
          width="0.469128"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="37.9048"
          y="42"
          width="0.469128"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="32.4316"
          y="43.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.2134"
          y="43.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.9956"
          y="43.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="34.7773"
          y="43.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="35.5591"
          y="43.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="36.3408"
          y="43.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.123"
          y="43.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.9048"
          y="43.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="32.4316"
          y="45.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.2134"
          y="45.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.9956"
          y="45.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="34.7773"
          y="45.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="35.5591"
          y="45.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="36.3408"
          y="45.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.123"
          y="45.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.9048"
          y="45.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect x="32.4316" y="47" width="0.469126" height="1" fill="#445B5B" />
        <rect x="33.2134" y="47" width="0.469128" height="1" fill="#445B5B" />
        <rect x="33.9956" y="47" width="0.469128" height="1" fill="#445B5B" />
        <rect x="34.7773" y="47" width="0.469128" height="1" fill="#445B5B" />
        <rect x="35.5591" y="47" width="0.469128" height="1" fill="#445B5B" />
        <rect x="36.3408" y="47" width="0.469128" height="1" fill="#445B5B" />
        <rect x="37.123" y="47" width="0.469128" height="1" fill="#445B5B" />
        <rect x="37.9048" y="47" width="0.469128" height="1" fill="#445B5B" />
        <rect
          x="32.4316"
          y="48.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.2134"
          y="48.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.9956"
          y="48.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="34.7773"
          y="48.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="35.5591"
          y="48.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="36.3408"
          y="48.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.123"
          y="48.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.9048"
          y="48.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="32.4316"
          y="50.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.2134"
          y="50.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.9956"
          y="50.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="34.7773"
          y="50.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="35.5591"
          y="50.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="36.3408"
          y="50.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.123"
          y="50.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.9048"
          y="50.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect x="32.4316" y="52" width="0.469126" height="1" fill="#445B5B" />
        <rect x="33.2134" y="52" width="0.469128" height="1" fill="#445B5B" />
        <rect x="33.9956" y="52" width="0.469128" height="1" fill="#445B5B" />
        <rect x="34.7773" y="52" width="0.469128" height="1" fill="#445B5B" />
        <rect x="35.5591" y="52" width="0.469128" height="1" fill="#445B5B" />
        <rect x="36.3408" y="52" width="0.469128" height="1" fill="#445B5B" />
        <rect x="37.123" y="52" width="0.469128" height="1" fill="#445B5B" />
        <rect x="37.9048" y="52" width="0.469128" height="1" fill="#445B5B" />
        <rect
          x="32.4316"
          y="53.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.2134"
          y="53.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.9956"
          y="53.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="34.7773"
          y="53.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="35.5591"
          y="53.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="36.3408"
          y="53.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.123"
          y="53.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.9048"
          y="53.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="32.4316"
          y="55.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.2134"
          y="55.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.9956"
          y="55.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="34.7773"
          y="55.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="35.5591"
          y="55.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="36.3408"
          y="55.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.123"
          y="55.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.9048"
          y="55.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect x="32.4316" y="57" width="0.469126" height="1" fill="#445B5B" />
        <rect x="33.2134" y="57" width="0.469128" height="1" fill="#445B5B" />
        <rect x="33.9956" y="57" width="0.469128" height="1" fill="#445B5B" />
        <rect x="34.7773" y="57" width="0.469128" height="1" fill="#445B5B" />
        <rect x="35.5591" y="57" width="0.469128" height="1" fill="#445B5B" />
        <rect x="36.3408" y="57" width="0.469128" height="1" fill="#445B5B" />
        <rect x="37.123" y="57" width="0.469128" height="1" fill="#445B5B" />
        <rect x="37.9048" y="57" width="0.469128" height="1" fill="#445B5B" />
        <rect
          x="32.4316"
          y="58.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.2134"
          y="58.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.9956"
          y="58.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="34.7773"
          y="58.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="35.5591"
          y="58.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="36.3408"
          y="58.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.123"
          y="58.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.9048"
          y="58.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="32.4316"
          y="60.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.2134"
          y="60.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="33.9956"
          y="60.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="34.7773"
          y="60.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="35.5591"
          y="60.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="36.3408"
          y="60.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.123"
          y="60.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="37.9048"
          y="60.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect x="32.4316" y="62" width="0.469126" height="1" fill="#445B5B" />
        <rect x="33.2134" y="62" width="0.469128" height="1" fill="#445B5B" />
        <rect x="33.9956" y="62" width="0.469128" height="1" fill="#445B5B" />
        <rect x="34.7773" y="62" width="0.469128" height="1" fill="#445B5B" />
        <rect x="35.5591" y="62" width="0.469128" height="1" fill="#445B5B" />
        <rect x="36.3408" y="62" width="0.469128" height="1" fill="#445B5B" />
        <rect x="37.123" y="62" width="0.469128" height="1" fill="#445B5B" />
        <rect x="37.9048" y="62" width="0.469128" height="1" fill="#445B5B" />
        <rect
          x="32.4316"
          y="63.667"
          width="0.469126"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="33.2134"
          y="63.667"
          width="0.469128"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="33.9956"
          y="63.667"
          width="0.469128"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="34.7773"
          y="63.667"
          width="0.469128"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="35.5591"
          y="63.667"
          width="0.469128"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="36.3408"
          y="63.667"
          width="0.469128"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="37.123"
          y="63.667"
          width="0.469128"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="37.9048"
          y="63.667"
          width="0.469128"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="4.6748"
          y="15.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="5.45654"
          y="15.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="6.23877"
          y="15.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.02051"
          y="15.333"
          width="0.469123"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.80225"
          y="15.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="8.58398"
          y="15.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="9.36621"
          y="15.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="10.1479"
          y="15.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect x="4.6748" y="17" width="0.469127" height="1" fill="#445B5B" />
        <rect x="5.45654" y="17" width="0.469126" height="1" fill="#445B5B" />
        <rect x="6.23877" y="17" width="0.469127" height="1" fill="#445B5B" />
        <rect x="7.02051" y="17" width="0.469123" height="1" fill="#445B5B" />
        <rect x="7.80225" y="17" width="0.469126" height="1" fill="#445B5B" />
        <rect x="8.58398" y="17" width="0.469126" height="1" fill="#445B5B" />
        <rect x="9.36621" y="17" width="0.469126" height="1" fill="#445B5B" />
        <rect x="10.1479" y="17" width="0.469127" height="1" fill="#445B5B" />
        <rect
          x="4.6748"
          y="18.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="5.45654"
          y="18.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="6.23877"
          y="18.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.02051"
          y="18.666"
          width="0.469123"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.80225"
          y="18.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="8.58398"
          y="18.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="9.36621"
          y="18.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="10.1479"
          y="18.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="4.6748"
          y="20.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="5.45654"
          y="20.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="6.23877"
          y="20.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.02051"
          y="20.333"
          width="0.469123"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.80225"
          y="20.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="8.58398"
          y="20.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="9.36621"
          y="20.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="10.1479"
          y="20.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect x="4.6748" y="22" width="0.469127" height="1" fill="#445B5B" />
        <rect x="5.45654" y="22" width="0.469126" height="1" fill="#445B5B" />
        <rect x="6.23877" y="22" width="0.469127" height="1" fill="#445B5B" />
        <rect x="7.02051" y="22" width="0.469123" height="1" fill="#445B5B" />
        <rect x="7.80225" y="22" width="0.469126" height="1" fill="#445B5B" />
        <rect x="8.58398" y="22" width="0.469126" height="1" fill="#445B5B" />
        <rect x="9.36621" y="22" width="0.469126" height="1" fill="#445B5B" />
        <rect x="10.1479" y="22" width="0.469127" height="1" fill="#445B5B" />
        <rect
          x="4.6748"
          y="23.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="5.45654"
          y="23.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="6.23877"
          y="23.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.02051"
          y="23.666"
          width="0.469123"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.80225"
          y="23.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="8.58398"
          y="23.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="9.36621"
          y="23.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="10.1479"
          y="23.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="4.6748"
          y="25.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="5.45654"
          y="25.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="6.23877"
          y="25.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.02051"
          y="25.333"
          width="0.469123"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.80225"
          y="25.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="8.58398"
          y="25.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="9.36621"
          y="25.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="10.1479"
          y="25.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect x="4.6748" y="27" width="0.469127" height="1" fill="#445B5B" />
        <rect x="5.45654" y="27" width="0.469126" height="1" fill="#445B5B" />
        <rect x="6.23877" y="27" width="0.469127" height="1" fill="#445B5B" />
        <rect x="7.02051" y="27" width="0.469123" height="1" fill="#445B5B" />
        <rect x="7.80225" y="27" width="0.469126" height="1" fill="#445B5B" />
        <rect x="8.58398" y="27" width="0.469126" height="1" fill="#445B5B" />
        <rect x="9.36621" y="27" width="0.469126" height="1" fill="#445B5B" />
        <rect x="10.1479" y="27" width="0.469127" height="1" fill="#445B5B" />
        <rect
          x="4.6748"
          y="28.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="5.45654"
          y="28.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="6.23877"
          y="28.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.02051"
          y="28.666"
          width="0.469123"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.80225"
          y="28.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="8.58398"
          y="28.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="9.36621"
          y="28.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="10.1479"
          y="28.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="4.6748"
          y="30.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="5.45654"
          y="30.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="6.23877"
          y="30.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.02051"
          y="30.333"
          width="0.469123"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.80225"
          y="30.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="8.58398"
          y="30.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="9.36621"
          y="30.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="10.1479"
          y="30.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect x="4.6748" y="32" width="0.469127" height="1" fill="#445B5B" />
        <rect x="5.45654" y="32" width="0.469126" height="1" fill="#445B5B" />
        <rect x="6.23877" y="32" width="0.469127" height="1" fill="#445B5B" />
        <rect x="7.02051" y="32" width="0.469123" height="1" fill="#445B5B" />
        <rect x="7.80225" y="32" width="0.469126" height="1" fill="#445B5B" />
        <rect x="8.58398" y="32" width="0.469126" height="1" fill="#445B5B" />
        <rect x="9.36621" y="32" width="0.469126" height="1" fill="#445B5B" />
        <rect x="10.1479" y="32" width="0.469127" height="1" fill="#445B5B" />
        <rect
          x="4.6748"
          y="33.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="5.45654"
          y="33.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="6.23877"
          y="33.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.02051"
          y="33.666"
          width="0.469123"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.80225"
          y="33.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="8.58398"
          y="33.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="9.36621"
          y="33.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="10.1479"
          y="33.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="4.6748"
          y="35.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="5.45654"
          y="35.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="6.23877"
          y="35.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.02051"
          y="35.333"
          width="0.469123"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.80225"
          y="35.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="8.58398"
          y="35.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="9.36621"
          y="35.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="10.1479"
          y="35.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect x="4.6748" y="37" width="0.469127" height="1" fill="#445B5B" />
        <rect x="5.45654" y="37" width="0.469126" height="1" fill="#445B5B" />
        <rect x="6.23877" y="37" width="0.469127" height="1" fill="#445B5B" />
        <rect x="7.02051" y="37" width="0.469123" height="1" fill="#445B5B" />
        <rect x="7.80225" y="37" width="0.469126" height="1" fill="#445B5B" />
        <rect x="8.58398" y="37" width="0.469126" height="1" fill="#445B5B" />
        <rect x="9.36621" y="37" width="0.469126" height="1" fill="#445B5B" />
        <rect x="10.1479" y="37" width="0.469127" height="1" fill="#445B5B" />
        <rect
          x="13.1191"
          y="15.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.9009"
          y="15.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="14.6831"
          y="15.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="15.4648"
          y="15.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="16.2466"
          y="15.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.0283"
          y="15.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.8105"
          y="15.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="18.5923"
          y="15.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect x="13.1191" y="17" width="0.469126" height="1" fill="#445B5B" />
        <rect x="13.9009" y="17" width="0.469127" height="1" fill="#445B5B" />
        <rect x="14.6831" y="17" width="0.469127" height="1" fill="#445B5B" />
        <rect x="15.4648" y="17" width="0.469127" height="1" fill="#445B5B" />
        <rect x="16.2466" y="17" width="0.469127" height="1" fill="#445B5B" />
        <rect x="17.0283" y="17" width="0.469126" height="1" fill="#445B5B" />
        <rect x="17.8105" y="17" width="0.469126" height="1" fill="#445B5B" />
        <rect x="18.5923" y="17" width="0.469126" height="1" fill="#445B5B" />
        <rect
          x="13.1191"
          y="18.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.9009"
          y="18.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="14.6831"
          y="18.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="15.4648"
          y="18.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="16.2466"
          y="18.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.0283"
          y="18.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.8105"
          y="18.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="18.5923"
          y="18.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.1191"
          y="20.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.9009"
          y="20.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="14.6831"
          y="20.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="15.4648"
          y="20.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="16.2466"
          y="20.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.0283"
          y="20.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.8105"
          y="20.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="18.5923"
          y="20.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect x="13.1191" y="22" width="0.469126" height="1" fill="#445B5B" />
        <rect x="13.9009" y="22" width="0.469127" height="1" fill="#445B5B" />
        <rect x="14.6831" y="22" width="0.469127" height="1" fill="#445B5B" />
        <rect x="15.4648" y="22" width="0.469127" height="1" fill="#445B5B" />
        <rect x="16.2466" y="22" width="0.469127" height="1" fill="#445B5B" />
        <rect x="17.0283" y="22" width="0.469126" height="1" fill="#445B5B" />
        <rect x="17.8105" y="22" width="0.469126" height="1" fill="#445B5B" />
        <rect x="18.5923" y="22" width="0.469126" height="1" fill="#445B5B" />
        <rect
          x="13.1191"
          y="23.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.9009"
          y="23.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="14.6831"
          y="23.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="15.4648"
          y="23.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="16.2466"
          y="23.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.0283"
          y="23.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.8105"
          y="23.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="18.5923"
          y="23.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.1191"
          y="25.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.9009"
          y="25.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="14.6831"
          y="25.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="15.4648"
          y="25.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="16.2466"
          y="25.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.0283"
          y="25.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.8105"
          y="25.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="18.5923"
          y="25.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect x="13.1191" y="27" width="0.469126" height="1" fill="#445B5B" />
        <rect x="13.9009" y="27" width="0.469127" height="1" fill="#445B5B" />
        <rect x="14.6831" y="27" width="0.469127" height="1" fill="#445B5B" />
        <rect x="15.4648" y="27" width="0.469127" height="1" fill="#445B5B" />
        <rect x="16.2466" y="27" width="0.469127" height="1" fill="#445B5B" />
        <rect x="17.0283" y="27" width="0.469126" height="1" fill="#445B5B" />
        <rect x="17.8105" y="27" width="0.469126" height="1" fill="#445B5B" />
        <rect x="18.5923" y="27" width="0.469126" height="1" fill="#445B5B" />
        <rect
          x="13.1191"
          y="28.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.9009"
          y="28.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="14.6831"
          y="28.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="15.4648"
          y="28.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="16.2466"
          y="28.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.0283"
          y="28.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.8105"
          y="28.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="18.5923"
          y="28.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.1191"
          y="30.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.9009"
          y="30.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="14.6831"
          y="30.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="15.4648"
          y="30.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="16.2466"
          y="30.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.0283"
          y="30.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.8105"
          y="30.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="18.5923"
          y="30.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect x="13.1191" y="32" width="0.469126" height="1" fill="#445B5B" />
        <rect x="13.9009" y="32" width="0.469127" height="1" fill="#445B5B" />
        <rect x="14.6831" y="32" width="0.469127" height="1" fill="#445B5B" />
        <rect x="15.4648" y="32" width="0.469127" height="1" fill="#445B5B" />
        <rect x="16.2466" y="32" width="0.469127" height="1" fill="#445B5B" />
        <rect x="17.0283" y="32" width="0.469126" height="1" fill="#445B5B" />
        <rect x="17.8105" y="32" width="0.469126" height="1" fill="#445B5B" />
        <rect x="18.5923" y="32" width="0.469126" height="1" fill="#445B5B" />
        <rect
          x="13.1191"
          y="33.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.9009"
          y="33.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="14.6831"
          y="33.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="15.4648"
          y="33.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="16.2466"
          y="33.666"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.0283"
          y="33.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.8105"
          y="33.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="18.5923"
          y="33.666"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.1191"
          y="35.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.9009"
          y="35.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="14.6831"
          y="35.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="15.4648"
          y="35.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="16.2466"
          y="35.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.0283"
          y="35.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.8105"
          y="35.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="18.5923"
          y="35.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect x="13.1191" y="37" width="0.469126" height="1" fill="#445B5B" />
        <rect x="13.9009" y="37" width="0.469127" height="1" fill="#445B5B" />
        <rect x="14.6831" y="37" width="0.469127" height="1" fill="#445B5B" />
        <rect x="15.4648" y="37" width="0.469127" height="1" fill="#445B5B" />
        <rect x="16.2466" y="37" width="0.469127" height="1" fill="#445B5B" />
        <rect x="17.0283" y="37" width="0.469126" height="1" fill="#445B5B" />
        <rect x="17.8105" y="37" width="0.469126" height="1" fill="#445B5B" />
        <rect x="18.5923" y="37" width="0.469126" height="1" fill="#445B5B" />
        <rect
          x="4.6748"
          y="42"
          width="0.469127"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="5.45654"
          y="42"
          width="0.469126"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="6.23877"
          y="42"
          width="0.469127"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="7.02051"
          y="42"
          width="0.469123"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="7.80225"
          y="42"
          width="0.469126"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="8.58398"
          y="42"
          width="0.469126"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="9.36621"
          y="42"
          width="0.469126"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="10.1479"
          y="42"
          width="0.469127"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="4.6748"
          y="43.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="5.45654"
          y="43.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="6.23877"
          y="43.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.02051"
          y="43.667"
          width="0.469123"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.80225"
          y="43.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="8.58398"
          y="43.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="9.36621"
          y="43.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="10.1479"
          y="43.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="4.6748"
          y="45.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="5.45654"
          y="45.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="6.23877"
          y="45.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.02051"
          y="45.333"
          width="0.469123"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.80225"
          y="45.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="8.58398"
          y="45.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="9.36621"
          y="45.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="10.1479"
          y="45.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect x="4.6748" y="47" width="0.469127" height="1" fill="#445B5B" />
        <rect x="5.45654" y="47" width="0.469126" height="1" fill="#445B5B" />
        <rect x="6.23877" y="47" width="0.469127" height="1" fill="#445B5B" />
        <rect x="7.02051" y="47" width="0.469123" height="1" fill="#445B5B" />
        <rect x="7.80225" y="47" width="0.469126" height="1" fill="#445B5B" />
        <rect x="8.58398" y="47" width="0.469126" height="1" fill="#445B5B" />
        <rect x="9.36621" y="47" width="0.469126" height="1" fill="#445B5B" />
        <rect x="10.1479" y="47" width="0.469127" height="1" fill="#445B5B" />
        <rect
          x="4.6748"
          y="48.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="5.45654"
          y="48.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="6.23877"
          y="48.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.02051"
          y="48.667"
          width="0.469123"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.80225"
          y="48.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="8.58398"
          y="48.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="9.36621"
          y="48.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="10.1479"
          y="48.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="4.6748"
          y="50.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="5.45654"
          y="50.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="6.23877"
          y="50.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.02051"
          y="50.333"
          width="0.469123"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.80225"
          y="50.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="8.58398"
          y="50.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="9.36621"
          y="50.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="10.1479"
          y="50.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect x="4.6748" y="52" width="0.469127" height="1" fill="#445B5B" />
        <rect x="5.45654" y="52" width="0.469126" height="1" fill="#445B5B" />
        <rect x="6.23877" y="52" width="0.469127" height="1" fill="#445B5B" />
        <rect x="7.02051" y="52" width="0.469123" height="1" fill="#445B5B" />
        <rect x="7.80225" y="52" width="0.469126" height="1" fill="#445B5B" />
        <rect x="8.58398" y="52" width="0.469126" height="1" fill="#445B5B" />
        <rect x="9.36621" y="52" width="0.469126" height="1" fill="#445B5B" />
        <rect x="10.1479" y="52" width="0.469127" height="1" fill="#445B5B" />
        <rect
          x="4.6748"
          y="53.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="5.45654"
          y="53.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="6.23877"
          y="53.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.02051"
          y="53.667"
          width="0.469123"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.80225"
          y="53.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="8.58398"
          y="53.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="9.36621"
          y="53.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="10.1479"
          y="53.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="4.6748"
          y="55.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="5.45654"
          y="55.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="6.23877"
          y="55.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.02051"
          y="55.333"
          width="0.469123"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.80225"
          y="55.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="8.58398"
          y="55.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="9.36621"
          y="55.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="10.1479"
          y="55.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect x="4.6748" y="57" width="0.469127" height="1" fill="#445B5B" />
        <rect x="5.45654" y="57" width="0.469126" height="1" fill="#445B5B" />
        <rect x="6.23877" y="57" width="0.469127" height="1" fill="#445B5B" />
        <rect x="7.02051" y="57" width="0.469123" height="1" fill="#445B5B" />
        <rect x="7.80225" y="57" width="0.469126" height="1" fill="#445B5B" />
        <rect x="8.58398" y="57" width="0.469126" height="1" fill="#445B5B" />
        <rect x="9.36621" y="57" width="0.469126" height="1" fill="#445B5B" />
        <rect x="10.1479" y="57" width="0.469127" height="1" fill="#445B5B" />
        <rect
          x="4.6748"
          y="58.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="5.45654"
          y="58.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="6.23877"
          y="58.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.02051"
          y="58.667"
          width="0.469123"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.80225"
          y="58.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="8.58398"
          y="58.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="9.36621"
          y="58.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="10.1479"
          y="58.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="4.6748"
          y="60.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="5.45654"
          y="60.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="6.23877"
          y="60.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.02051"
          y="60.333"
          width="0.469123"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="7.80225"
          y="60.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="8.58398"
          y="60.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="9.36621"
          y="60.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="10.1479"
          y="60.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect x="4.6748" y="62" width="0.469127" height="1" fill="#445B5B" />
        <rect x="5.45654" y="62" width="0.469126" height="1" fill="#445B5B" />
        <rect x="6.23877" y="62" width="0.469127" height="1" fill="#445B5B" />
        <rect x="7.02051" y="62" width="0.469123" height="1" fill="#445B5B" />
        <rect x="7.80225" y="62" width="0.469126" height="1" fill="#445B5B" />
        <rect x="8.58398" y="62" width="0.469126" height="1" fill="#445B5B" />
        <rect x="9.36621" y="62" width="0.469126" height="1" fill="#445B5B" />
        <rect x="10.1479" y="62" width="0.469127" height="1" fill="#445B5B" />
        <rect
          x="4.6748"
          y="63.667"
          width="0.469127"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="5.45654"
          y="63.667"
          width="0.469126"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="6.23877"
          y="63.667"
          width="0.469127"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="7.02051"
          y="63.667"
          width="0.469123"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="7.80225"
          y="63.667"
          width="0.469126"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="8.58398"
          y="63.667"
          width="0.469126"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="9.36621"
          y="63.667"
          width="0.469126"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="10.1479"
          y="63.667"
          width="0.469127"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="13.1191"
          y="42"
          width="0.469126"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="13.9009"
          y="42"
          width="0.469127"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="14.6831"
          y="42"
          width="0.469127"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="15.4648"
          y="42"
          width="0.469127"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="16.2466"
          y="42"
          width="0.469127"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="17.0283"
          y="42"
          width="0.469126"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="17.8105"
          y="42"
          width="0.469126"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="18.5923"
          y="42"
          width="0.469126"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="13.1191"
          y="43.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.9009"
          y="43.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="14.6831"
          y="43.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="15.4648"
          y="43.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="16.2466"
          y="43.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.0283"
          y="43.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.8105"
          y="43.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="18.5923"
          y="43.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.1191"
          y="45.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.9009"
          y="45.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="14.6831"
          y="45.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="15.4648"
          y="45.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="16.2466"
          y="45.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.0283"
          y="45.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.8105"
          y="45.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="18.5923"
          y="45.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect x="13.1191" y="47" width="0.469126" height="1" fill="#445B5B" />
        <rect x="13.9009" y="47" width="0.469127" height="1" fill="#445B5B" />
        <rect x="14.6831" y="47" width="0.469127" height="1" fill="#445B5B" />
        <rect x="15.4648" y="47" width="0.469127" height="1" fill="#445B5B" />
        <rect x="16.2466" y="47" width="0.469127" height="1" fill="#445B5B" />
        <rect x="17.0283" y="47" width="0.469126" height="1" fill="#445B5B" />
        <rect x="17.8105" y="47" width="0.469126" height="1" fill="#445B5B" />
        <rect x="18.5923" y="47" width="0.469126" height="1" fill="#445B5B" />
        <rect
          x="13.1191"
          y="48.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.9009"
          y="48.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="14.6831"
          y="48.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="15.4648"
          y="48.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="16.2466"
          y="48.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.0283"
          y="48.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.8105"
          y="48.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="18.5923"
          y="48.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.1191"
          y="50.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.9009"
          y="50.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="14.6831"
          y="50.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="15.4648"
          y="50.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="16.2466"
          y="50.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.0283"
          y="50.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.8105"
          y="50.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="18.5923"
          y="50.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect x="13.1191" y="52" width="0.469126" height="1" fill="#445B5B" />
        <rect x="13.9009" y="52" width="0.469127" height="1" fill="#445B5B" />
        <rect x="14.6831" y="52" width="0.469127" height="1" fill="#445B5B" />
        <rect x="15.4648" y="52" width="0.469127" height="1" fill="#445B5B" />
        <rect x="16.2466" y="52" width="0.469127" height="1" fill="#445B5B" />
        <rect x="17.0283" y="52" width="0.469126" height="1" fill="#445B5B" />
        <rect x="17.8105" y="52" width="0.469126" height="1" fill="#445B5B" />
        <rect x="18.5923" y="52" width="0.469126" height="1" fill="#445B5B" />
        <rect
          x="13.1191"
          y="53.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.9009"
          y="53.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="14.6831"
          y="53.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="15.4648"
          y="53.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="16.2466"
          y="53.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.0283"
          y="53.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.8105"
          y="53.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="18.5923"
          y="53.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.1191"
          y="55.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.9009"
          y="55.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="14.6831"
          y="55.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="15.4648"
          y="55.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="16.2466"
          y="55.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.0283"
          y="55.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.8105"
          y="55.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="18.5923"
          y="55.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect x="13.1191" y="57" width="0.469126" height="1" fill="#445B5B" />
        <rect x="13.9009" y="57" width="0.469127" height="1" fill="#445B5B" />
        <rect x="14.6831" y="57" width="0.469127" height="1" fill="#445B5B" />
        <rect x="15.4648" y="57" width="0.469127" height="1" fill="#445B5B" />
        <rect x="16.2466" y="57" width="0.469127" height="1" fill="#445B5B" />
        <rect x="17.0283" y="57" width="0.469126" height="1" fill="#445B5B" />
        <rect x="17.8105" y="57" width="0.469126" height="1" fill="#445B5B" />
        <rect x="18.5923" y="57" width="0.469126" height="1" fill="#445B5B" />
        <rect
          x="13.1191"
          y="58.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.9009"
          y="58.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="14.6831"
          y="58.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="15.4648"
          y="58.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="16.2466"
          y="58.667"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.0283"
          y="58.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.8105"
          y="58.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="18.5923"
          y="58.667"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.1191"
          y="60.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="13.9009"
          y="60.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="14.6831"
          y="60.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="15.4648"
          y="60.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="16.2466"
          y="60.333"
          width="0.469127"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.0283"
          y="60.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="17.8105"
          y="60.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="18.5923"
          y="60.333"
          width="0.469126"
          height="1"
          fill="#445B5B"
        />
        <rect x="13.1191" y="62" width="0.469126" height="1" fill="#445B5B" />
        <rect x="13.9009" y="62" width="0.469127" height="1" fill="#445B5B" />
        <rect x="14.6831" y="62" width="0.469127" height="1" fill="#445B5B" />
        <rect x="15.4648" y="62" width="0.469127" height="1" fill="#445B5B" />
        <rect x="16.2466" y="62" width="0.469127" height="1" fill="#445B5B" />
        <rect x="17.0283" y="62" width="0.469126" height="1" fill="#445B5B" />
        <rect x="17.8105" y="62" width="0.469126" height="1" fill="#445B5B" />
        <rect x="18.5923" y="62" width="0.469126" height="1" fill="#445B5B" />
        <rect
          x="13.1191"
          y="63.667"
          width="0.469126"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="13.9009"
          y="63.667"
          width="0.469127"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="14.6831"
          y="63.667"
          width="0.469127"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="15.4648"
          y="63.667"
          width="0.469127"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="16.2466"
          y="63.667"
          width="0.469127"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="17.0283"
          y="63.667"
          width="0.469126"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="17.8105"
          y="63.667"
          width="0.469126"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="18.5923"
          y="63.667"
          width="0.469126"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="22.2866"
          y="35.6875"
          width="0.752556"
          height="8.625"
          rx="0.376278"
          fill="#465856"
        />
        <rect
          x="22.2866"
          y="36.8545"
          width="0.752556"
          height="1.66667"
          fill="#688380"
        />
        <rect
          x="33.2134"
          y="19.9355"
          width="4.69127"
          height="11.9167"
          rx="1"
          fill="#FFD049"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.0788 23.4727C34.8586 23.5896 34.6781 23.7935 34.5965 24.0843L34.1355 25.7266C33.9564 26.3644 34.4358 26.9969 35.0982 26.9969H36.0203C36.6828 26.9969 37.1621 26.3644 36.9831 25.7266L36.5221 24.0843C36.4404 23.7935 36.26 23.5896 36.0398 23.4727L36.7782 26.0406C36.8333 26.2323 36.6894 26.4235 36.4898 26.4235H34.6287C34.4292 26.4235 34.2853 26.2323 34.3404 26.0406L35.0788 23.4727Z"
          fill="#4F5A60"
        />
        <rect
          x="35.4429"
          y="23.0156"
          width="0.306004"
          height="2.06935"
          rx="0.153002"
          fill="#4F5A60"
        />
        <ellipse
          cx="35.5959"
          cy="25.6806"
          rx="0.153002"
          ry="0.326149"
          fill="#4F5A60"
        />
        <path
          d="M33.8305 28.1165C33.8568 28.1165 33.8806 28.1245 33.9018 28.1405C33.9234 28.1565 33.9417 28.1795 33.9567 28.2095C33.9717 28.2395 33.9833 28.2762 33.9914 28.3195C33.9996 28.3628 34.0036 28.4118 34.0036 28.4665C34.0036 28.5212 33.9996 28.5702 33.9914 28.6135C33.9833 28.6568 33.9717 28.6935 33.9567 28.7235C33.9417 28.7535 33.9234 28.7765 33.9018 28.7925C33.8806 28.8085 33.8568 28.8165 33.8305 28.8165H33.7339V28.1165H33.8305ZM33.8141 28.2645V28.6685H33.8305C33.8577 28.6685 33.8795 28.6518 33.8957 28.6185C33.912 28.5845 33.9201 28.5338 33.9201 28.4665C33.9201 28.3992 33.912 28.3488 33.8957 28.3155C33.8795 28.2815 33.8577 28.2645 33.8305 28.2645H33.8141Z"
          fill="#E54545"
        />
        <path
          d="M34.2012 28.8295C34.1752 28.8295 34.1516 28.8202 34.1303 28.8015C34.1094 28.7828 34.0914 28.7572 34.0764 28.7245C34.0614 28.6918 34.0496 28.6535 34.0412 28.6095C34.0331 28.5648 34.029 28.5168 34.029 28.4655C34.029 28.4148 34.0331 28.3675 34.0412 28.3235C34.0496 28.2795 34.0614 28.2412 34.0764 28.2085C34.0914 28.1758 34.1094 28.1502 34.1303 28.1315C34.1516 28.1128 34.1752 28.1035 34.2012 28.1035C34.2271 28.1035 34.2506 28.1128 34.2715 28.1315C34.2928 28.1502 34.3109 28.1758 34.326 28.2085C34.341 28.2412 34.3525 28.2795 34.3607 28.3235C34.3691 28.3675 34.3733 28.4148 34.3733 28.4655C34.3733 28.5168 34.3691 28.5648 34.3607 28.6095C34.3525 28.6535 34.341 28.6918 34.326 28.7245C34.3109 28.7572 34.2928 28.7828 34.2715 28.8015C34.2506 28.8202 34.2271 28.8295 34.2012 28.8295ZM34.2012 28.2635C34.188 28.2635 34.176 28.2688 34.165 28.2795C34.1541 28.2895 34.1447 28.3035 34.1369 28.3215C34.1294 28.3395 34.1234 28.3608 34.1191 28.3855C34.1147 28.4102 34.1125 28.4368 34.1125 28.4655C34.1125 28.4948 34.1147 28.5222 34.1191 28.5475C34.1234 28.5722 34.1295 28.5935 34.1374 28.6115C34.1452 28.6295 34.1544 28.6438 34.165 28.6545C34.176 28.6645 34.188 28.6695 34.2012 28.6695C34.2143 28.6695 34.2262 28.6645 34.2368 28.6545C34.2478 28.6438 34.2571 28.6295 34.265 28.6115C34.2728 28.5935 34.2789 28.5722 34.2833 28.5475C34.2876 28.5222 34.2898 28.4948 34.2898 28.4655C34.2898 28.4368 34.2876 28.4102 34.2833 28.3855C34.2789 28.3608 34.2728 28.3395 34.265 28.3215C34.2575 28.3035 34.2482 28.2895 34.2373 28.2795C34.2263 28.2688 34.2143 28.2635 34.2012 28.2635Z"
          fill="#E54545"
        />
        <path
          d="M34.493 28.3875V28.8165H34.4142V28.1165H34.4958L34.6159 28.5505V28.1165H34.6947V28.8165H34.615L34.493 28.3875Z"
          fill="#E54545"
        />
        <path
          d="M34.9076 28.8295C34.8816 28.8295 34.858 28.8202 34.8368 28.8015C34.8158 28.7828 34.7978 28.7572 34.7828 28.7245C34.7678 28.6918 34.7561 28.6535 34.7476 28.6095C34.7395 28.5648 34.7354 28.5168 34.7354 28.4655C34.7354 28.4148 34.7395 28.3675 34.7476 28.3235C34.7561 28.2795 34.7678 28.2412 34.7828 28.2085C34.7978 28.1758 34.8158 28.1502 34.8368 28.1315C34.858 28.1128 34.8816 28.1035 34.9076 28.1035C34.9336 28.1035 34.957 28.1128 34.978 28.1315C34.9992 28.1502 35.0174 28.1758 35.0324 28.2085C35.0474 28.2412 35.059 28.2795 35.0671 28.3235C35.0756 28.3675 35.0798 28.4148 35.0798 28.4655C35.0798 28.5168 35.0756 28.5648 35.0671 28.6095C35.059 28.6535 35.0474 28.6918 35.0324 28.7245C35.0174 28.7572 34.9992 28.7828 34.978 28.8015C34.957 28.8202 34.9336 28.8295 34.9076 28.8295ZM34.9076 28.2635C34.8945 28.2635 34.8824 28.2688 34.8715 28.2795C34.8605 28.2895 34.8512 28.3035 34.8433 28.3215C34.8358 28.3395 34.8299 28.3608 34.8255 28.3855C34.8211 28.4102 34.8189 28.4368 34.8189 28.4655C34.8189 28.4948 34.8211 28.5222 34.8255 28.5475C34.8299 28.5722 34.836 28.5935 34.8438 28.6115C34.8516 28.6295 34.8609 28.6438 34.8715 28.6545C34.8824 28.6645 34.8945 28.6695 34.9076 28.6695C34.9207 28.6695 34.9326 28.6645 34.9433 28.6545C34.9542 28.6438 34.9636 28.6295 34.9714 28.6115C34.9792 28.5935 34.9853 28.5722 34.9897 28.5475C34.9941 28.5222 34.9963 28.4948 34.9963 28.4655C34.9963 28.4368 34.9941 28.4102 34.9897 28.3855C34.9853 28.3608 34.9792 28.3395 34.9714 28.3215C34.9639 28.3035 34.9547 28.2895 34.9437 28.2795C34.9328 28.2688 34.9207 28.2635 34.9076 28.2635Z"
          fill="#E54545"
        />
        <path
          d="M35.2383 28.2695V28.8165H35.158V28.2695H35.0872V28.1165H35.3091V28.2695H35.2383Z"
          fill="#E54545"
        />
        <path
          d="M35.5751 28.8285C35.5529 28.8285 35.5333 28.8218 35.5165 28.8085C35.4996 28.7945 35.4852 28.7752 35.4733 28.7505C35.4617 28.7258 35.453 28.6965 35.447 28.6625C35.4411 28.6278 35.4381 28.5895 35.4381 28.5475V28.1165H35.5183V28.5475C35.5183 28.5855 35.5233 28.6155 35.5333 28.6375C35.5437 28.6595 35.5577 28.6705 35.5756 28.6705C35.5937 28.6705 35.6076 28.6595 35.6173 28.6375C35.627 28.6155 35.6319 28.5852 35.6319 28.5465V28.1165H35.7121V28.5465C35.7121 28.5878 35.709 28.6258 35.7027 28.6605C35.6968 28.6945 35.6878 28.7242 35.676 28.7495C35.6644 28.7742 35.65 28.7935 35.6328 28.8075C35.6159 28.8215 35.5967 28.8285 35.5751 28.8285Z"
          fill="#E54545"
        />
        <path
          d="M35.8655 28.1035C35.8831 28.1035 35.8981 28.1095 35.9106 28.1215C35.9231 28.1335 35.9334 28.1482 35.9415 28.1655C35.95 28.1822 35.9566 28.1998 35.9612 28.2185C35.9659 28.2365 35.9694 28.2522 35.9716 28.2655L35.9049 28.3305C35.9034 28.3225 35.9015 28.3145 35.8993 28.3065C35.8971 28.2985 35.8943 28.2912 35.8909 28.2845C35.8877 28.2778 35.884 28.2725 35.8796 28.2685C35.8752 28.2638 35.8699 28.2615 35.8637 28.2615C35.8558 28.2615 35.8496 28.2662 35.8449 28.2755C35.8402 28.2842 35.8379 28.2952 35.8379 28.3085C35.8379 28.3245 35.8411 28.3382 35.8477 28.3495C35.8546 28.3602 35.863 28.3708 35.873 28.3815C35.8834 28.3915 35.8945 28.4022 35.9064 28.4135C35.9182 28.4248 35.9292 28.4392 35.9392 28.4565C35.9495 28.4732 35.958 28.4938 35.9645 28.5185C35.9714 28.5425 35.9748 28.5722 35.9748 28.6075C35.9748 28.6395 35.9722 28.6692 35.9669 28.6965C35.9616 28.7238 35.9539 28.7475 35.9439 28.7675C35.9342 28.7868 35.9221 28.8022 35.9078 28.8135C35.8937 28.8242 35.8777 28.8295 35.8599 28.8295C35.8415 28.8295 35.8257 28.8235 35.8125 28.8115C35.7994 28.7988 35.7884 28.7838 35.7797 28.7665C35.7709 28.7485 35.7641 28.7302 35.759 28.7115C35.754 28.6922 35.7504 28.6758 35.7483 28.6625L35.8153 28.5915C35.8197 28.6122 35.8261 28.6305 35.8346 28.6465C35.8433 28.6618 35.8533 28.6695 35.8646 28.6695C35.884 28.6695 35.8937 28.6532 35.8937 28.6205C35.8937 28.6052 35.8902 28.5922 35.8834 28.5815C35.8768 28.5702 35.8685 28.5595 35.8585 28.5495C35.8485 28.5388 35.8375 28.5275 35.8257 28.5155C35.8138 28.5035 35.8028 28.4885 35.7928 28.4705C35.7828 28.4525 35.7744 28.4308 35.7675 28.4055C35.7609 28.3802 35.7576 28.3488 35.7576 28.3115C35.7576 28.2802 35.7601 28.2518 35.7651 28.2265C35.7705 28.2005 35.7778 28.1785 35.7872 28.1605C35.7966 28.1425 35.8078 28.1285 35.821 28.1185C35.8344 28.1085 35.8493 28.1035 35.8655 28.1035Z"
          fill="#E54545"
        />
        <path
          d="M36.0177 28.8165V28.1165H36.1927V28.2695H36.0979V28.3865H36.1786V28.5385H36.0979V28.6635H36.1964V28.8165H36.0177Z"
          fill="#E54545"
        />
        <path
          d="M36.4177 28.5495V28.8165H36.3375V28.1165H36.511V28.2695H36.4177V28.3975H36.4993V28.5495H36.4177Z"
          fill="#E54545"
        />
        <path
          d="M36.7053 28.8295C36.6794 28.8295 36.6557 28.8202 36.6345 28.8015C36.6135 28.7828 36.5955 28.7572 36.5805 28.7245C36.5655 28.6918 36.5538 28.6535 36.5453 28.6095C36.5372 28.5648 36.5331 28.5168 36.5331 28.4655C36.5331 28.4148 36.5372 28.3675 36.5453 28.3235C36.5538 28.2795 36.5655 28.2412 36.5805 28.2085C36.5955 28.1758 36.6135 28.1502 36.6345 28.1315C36.6557 28.1128 36.6794 28.1035 36.7053 28.1035C36.7313 28.1035 36.7547 28.1128 36.7757 28.1315C36.797 28.1502 36.8151 28.1758 36.8301 28.2085C36.8451 28.2412 36.8567 28.2795 36.8648 28.3235C36.8733 28.3675 36.8775 28.4148 36.8775 28.4655C36.8775 28.5168 36.8733 28.5648 36.8648 28.6095C36.8567 28.6535 36.8451 28.6918 36.8301 28.7245C36.8151 28.7572 36.797 28.7828 36.7757 28.8015C36.7547 28.8202 36.7313 28.8295 36.7053 28.8295ZM36.7053 28.2635C36.6922 28.2635 36.6801 28.2688 36.6692 28.2795C36.6582 28.2895 36.6489 28.3035 36.641 28.3215C36.6335 28.3395 36.6276 28.3608 36.6232 28.3855C36.6188 28.4102 36.6167 28.4368 36.6167 28.4655C36.6167 28.4948 36.6188 28.5222 36.6232 28.5475C36.6276 28.5722 36.6337 28.5935 36.6415 28.6115C36.6493 28.6295 36.6586 28.6438 36.6692 28.6545C36.6801 28.6645 36.6922 28.6695 36.7053 28.6695C36.7185 28.6695 36.7303 28.6645 36.741 28.6545C36.7519 28.6438 36.7613 28.6295 36.7691 28.6115C36.7769 28.5935 36.783 28.5722 36.7874 28.5475C36.7918 28.5222 36.794 28.4948 36.794 28.4655C36.794 28.4368 36.7918 28.4102 36.7874 28.3855C36.783 28.3608 36.7769 28.3395 36.7691 28.3215C36.7616 28.3035 36.7524 28.2895 36.7414 28.2795C36.7305 28.2688 36.7185 28.2635 36.7053 28.2635Z"
          fill="#E54545"
        />
        <path
          d="M37.0764 28.8295C37.0499 28.8295 37.0258 28.8202 37.0042 28.8015C36.9829 28.7828 36.9648 28.7572 36.9498 28.7245C36.9348 28.6918 36.9232 28.6535 36.9151 28.6095C36.9069 28.5648 36.9029 28.5168 36.9029 28.4655C36.9029 28.4142 36.9071 28.3665 36.9155 28.3225C36.924 28.2785 36.9359 28.2402 36.9512 28.2075C36.9665 28.1748 36.9848 28.1495 37.0061 28.1315C37.0273 28.1128 37.0508 28.1035 37.0764 28.1035C37.0971 28.1035 37.1152 28.1092 37.1309 28.1205C37.1465 28.1312 37.1603 28.1452 37.1721 28.1625L37.1496 28.3125C37.1409 28.2998 37.1304 28.2885 37.1182 28.2785C37.1063 28.2685 37.0933 28.2635 37.0793 28.2635C37.0642 28.2635 37.0509 28.2688 37.0394 28.2795C37.0278 28.2902 37.0181 28.3048 37.0103 28.3235C37.0025 28.3422 36.9965 28.3638 36.9925 28.3885C36.9884 28.4125 36.9864 28.4382 36.9864 28.4655C36.9864 28.4928 36.9884 28.5188 36.9925 28.5435C36.9965 28.5682 37.0025 28.5898 37.0103 28.6085C37.0181 28.6272 37.0278 28.6422 37.0394 28.6535C37.0509 28.6642 37.0642 28.6695 37.0793 28.6695C37.0933 28.6695 37.1066 28.6645 37.1191 28.6545C37.1316 28.6445 37.1423 28.6332 37.151 28.6205L37.1735 28.7705C37.1617 28.7878 37.1476 28.8022 37.1313 28.8135C37.1154 28.8242 37.0971 28.8295 37.0764 28.8295Z"
          fill="#E54545"
        />
        <path
          d="M37.2148 28.8165V28.1165H37.3898V28.2695H37.295V28.3865H37.3757V28.5385H37.295V28.6635H37.3935V28.8165H37.2148Z"
          fill="#E54545"
        />
        <path
          d="M34.0638 29.6028V30.1498H33.9836V29.6028H33.9128V29.4498H34.1347V29.6028H34.0638Z"
          fill="#E54545"
        />
        <path
          d="M34.3141 30.1628C34.2881 30.1628 34.2645 30.1535 34.2433 30.1348C34.2223 30.1162 34.2043 30.0905 34.1893 30.0578C34.1743 30.0252 34.1626 29.9868 34.1541 29.9428C34.146 29.8982 34.1419 29.8502 34.1419 29.7988C34.1419 29.7482 34.146 29.7008 34.1541 29.6568C34.1626 29.6128 34.1743 29.5745 34.1893 29.5418C34.2043 29.5092 34.2223 29.4835 34.2433 29.4648C34.2645 29.4462 34.2881 29.4368 34.3141 29.4368C34.3401 29.4368 34.3635 29.4462 34.3845 29.4648C34.4057 29.4835 34.4239 29.5092 34.4389 29.5418C34.4539 29.5745 34.4655 29.6128 34.4736 29.6568C34.482 29.7008 34.4863 29.7482 34.4863 29.7988C34.4863 29.8502 34.482 29.8982 34.4736 29.9428C34.4655 29.9868 34.4539 30.0252 34.4389 30.0578C34.4239 30.0905 34.4057 30.1162 34.3845 30.1348C34.3635 30.1535 34.3401 30.1628 34.3141 30.1628ZM34.3141 29.5968C34.301 29.5968 34.2889 29.6022 34.278 29.6128C34.267 29.6228 34.2576 29.6368 34.2498 29.6548C34.2423 29.6728 34.2364 29.6942 34.232 29.7188C34.2276 29.7435 34.2254 29.7702 34.2254 29.7988C34.2254 29.8282 34.2276 29.8555 34.232 29.8808C34.2364 29.9055 34.2425 29.9268 34.2503 29.9448C34.2581 29.9628 34.2673 29.9772 34.278 29.9878C34.2889 29.9978 34.301 30.0028 34.3141 30.0028C34.3272 30.0028 34.3391 29.9978 34.3498 29.9878C34.3607 29.9772 34.3701 29.9628 34.3779 29.9448C34.3857 29.9268 34.3918 29.9055 34.3962 29.8808C34.4006 29.8555 34.4028 29.8282 34.4028 29.7988C34.4028 29.7702 34.4006 29.7435 34.3962 29.7188C34.3918 29.6942 34.3857 29.6728 34.3779 29.6548C34.3704 29.6368 34.3612 29.6228 34.3502 29.6128C34.3393 29.6022 34.3272 29.5968 34.3141 29.5968Z"
          fill="#E54545"
        />
        <path
          d="M34.7759 30.1628C34.7499 30.1628 34.7263 30.1535 34.7051 30.1348C34.6841 30.1162 34.6661 30.0905 34.6511 30.0578C34.6361 30.0252 34.6244 29.9868 34.6159 29.9428C34.6078 29.8982 34.6037 29.8502 34.6037 29.7988C34.6037 29.7482 34.6078 29.7008 34.6159 29.6568C34.6244 29.6128 34.6361 29.5745 34.6511 29.5418C34.6661 29.5092 34.6841 29.4835 34.7051 29.4648C34.7263 29.4462 34.7499 29.4368 34.7759 29.4368C34.8019 29.4368 34.8253 29.4462 34.8463 29.4648C34.8675 29.4835 34.8857 29.5092 34.9007 29.5418C34.9157 29.5745 34.9273 29.6128 34.9354 29.6568C34.9438 29.7008 34.9481 29.7482 34.9481 29.7988C34.9481 29.8502 34.9438 29.8982 34.9354 29.9428C34.9273 29.9868 34.9157 30.0252 34.9007 30.0578C34.8857 30.0905 34.8675 30.1162 34.8463 30.1348C34.8253 30.1535 34.8019 30.1628 34.7759 30.1628ZM34.7759 29.5968C34.7628 29.5968 34.7507 29.6022 34.7398 29.6128C34.7288 29.6228 34.7194 29.6368 34.7116 29.6548C34.7041 29.6728 34.6982 29.6942 34.6938 29.7188C34.6894 29.7435 34.6872 29.7702 34.6872 29.7988C34.6872 29.8282 34.6894 29.8555 34.6938 29.8808C34.6982 29.9055 34.7043 29.9268 34.7121 29.9448C34.7199 29.9628 34.7291 29.9772 34.7398 29.9878C34.7507 29.9978 34.7628 30.0028 34.7759 30.0028C34.789 30.0028 34.8009 29.9978 34.8115 29.9878C34.8225 29.9772 34.8319 29.9628 34.8397 29.9448C34.8475 29.9268 34.8536 29.9055 34.858 29.8808C34.8624 29.8555 34.8646 29.8282 34.8646 29.7988C34.8646 29.7702 34.8624 29.7435 34.858 29.7188C34.8536 29.6942 34.8475 29.6728 34.8397 29.6548C34.8322 29.6368 34.823 29.6228 34.812 29.6128C34.8011 29.6022 34.789 29.5968 34.7759 29.5968Z"
          fill="#E54545"
        />
        <path
          d="M35.2141 29.6758C35.2141 29.7098 35.2116 29.7408 35.2066 29.7688C35.2016 29.7968 35.1944 29.8212 35.185 29.8418C35.1759 29.8618 35.1648 29.8775 35.1517 29.8888C35.1389 29.8995 35.1245 29.9048 35.1085 29.9048H35.0701V30.1498H34.9899V29.4498H35.1081C35.1415 29.4498 35.1675 29.4702 35.186 29.5108C35.2047 29.5515 35.2141 29.6065 35.2141 29.6758ZM35.0701 29.5968V29.7578H35.0963C35.1082 29.7578 35.1171 29.7502 35.1231 29.7348C35.129 29.7195 35.132 29.6998 35.132 29.6758C35.132 29.6525 35.1289 29.6335 35.1226 29.6188C35.1167 29.6042 35.1082 29.5968 35.0973 29.5968H35.0701Z"
          fill="#E54545"
        />
        <path
          d="M35.2519 30.1498V29.4498H35.4269V29.6028H35.3321V29.7198H35.4128V29.8718H35.3321V29.9968H35.4306V30.1498H35.2519Z"
          fill="#E54545"
        />
        <path
          d="M35.5575 29.7208V30.1498H35.4787V29.4498H35.5603L35.6804 29.8838V29.4498H35.7592V30.1498H35.6794L35.5575 29.7208Z"
          fill="#E54545"
        />
        <path
          d="M36.0051 29.4498C36.0313 29.4498 36.0551 29.4578 36.0764 29.4738C36.0979 29.4898 36.1162 29.5128 36.1312 29.5428C36.1463 29.5728 36.1578 29.6095 36.166 29.6528C36.1741 29.6962 36.1782 29.7452 36.1782 29.7998C36.1782 29.8545 36.1741 29.9035 36.166 29.9468C36.1578 29.9902 36.1463 30.0268 36.1312 30.0568C36.1162 30.0868 36.0979 30.1098 36.0764 30.1258C36.0551 30.1418 36.0313 30.1498 36.0051 30.1498H35.9084V29.4498H36.0051ZM35.9886 29.5978V30.0018H36.0051C36.0323 30.0018 36.054 29.9852 36.0703 29.9518C36.0865 29.9178 36.0947 29.8672 36.0947 29.7998C36.0947 29.7325 36.0865 29.6822 36.0703 29.6488C36.054 29.6148 36.0323 29.5978 36.0051 29.5978H35.9886Z"
          fill="#E54545"
        />
        <path
          d="M36.3757 30.1628C36.3497 30.1628 36.3261 30.1535 36.3049 30.1348C36.2839 30.1162 36.2659 30.0905 36.2509 30.0578C36.2359 30.0252 36.2242 29.9868 36.2157 29.9428C36.2076 29.8982 36.2035 29.8502 36.2035 29.7988C36.2035 29.7482 36.2076 29.7008 36.2157 29.6568C36.2242 29.6128 36.2359 29.5745 36.2509 29.5418C36.2659 29.5092 36.2839 29.4835 36.3049 29.4648C36.3261 29.4462 36.3497 29.4368 36.3757 29.4368C36.4016 29.4368 36.4251 29.4462 36.4461 29.4648C36.4673 29.4835 36.4855 29.5092 36.5005 29.5418C36.5155 29.5745 36.5271 29.6128 36.5352 29.6568C36.5436 29.7008 36.5479 29.7482 36.5479 29.7988C36.5479 29.8502 36.5436 29.8982 36.5352 29.9428C36.5271 29.9868 36.5155 30.0252 36.5005 30.0578C36.4855 30.0905 36.4673 30.1162 36.4461 30.1348C36.4251 30.1535 36.4016 30.1628 36.3757 30.1628ZM36.3757 29.5968C36.3626 29.5968 36.3505 29.6022 36.3396 29.6128C36.3286 29.6228 36.3192 29.6368 36.3114 29.6548C36.3039 29.6728 36.298 29.6942 36.2936 29.7188C36.2892 29.7435 36.287 29.7702 36.287 29.7988C36.287 29.8282 36.2892 29.8555 36.2936 29.8808C36.298 29.9055 36.3041 29.9268 36.3119 29.9448C36.3197 29.9628 36.3289 29.9772 36.3396 29.9878C36.3505 29.9978 36.3626 30.0028 36.3757 30.0028C36.3888 30.0028 36.4007 29.9978 36.4113 29.9878C36.4223 29.9772 36.4317 29.9628 36.4395 29.9448C36.4473 29.9268 36.4534 29.9055 36.4578 29.8808C36.4622 29.8555 36.4644 29.8282 36.4644 29.7988C36.4644 29.7702 36.4622 29.7435 36.4578 29.7188C36.4534 29.6942 36.4473 29.6728 36.4395 29.6548C36.432 29.6368 36.4228 29.6228 36.4118 29.6128C36.4009 29.6022 36.3888 29.5968 36.3757 29.5968Z"
          fill="#E54545"
        />
        <path
          d="M36.7454 30.1628C36.7194 30.1628 36.6958 30.1535 36.6746 30.1348C36.6536 30.1162 36.6356 30.0905 36.6206 30.0578C36.6056 30.0252 36.5939 29.9868 36.5854 29.9428C36.5773 29.8982 36.5732 29.8502 36.5732 29.7988C36.5732 29.7482 36.5773 29.7008 36.5854 29.6568C36.5939 29.6128 36.6056 29.5745 36.6206 29.5418C36.6356 29.5092 36.6536 29.4835 36.6746 29.4648C36.6958 29.4462 36.7194 29.4368 36.7454 29.4368C36.7714 29.4368 36.7948 29.4462 36.8158 29.4648C36.837 29.4835 36.8552 29.5092 36.8702 29.5418C36.8852 29.5745 36.8968 29.6128 36.9049 29.6568C36.9134 29.7008 36.9176 29.7482 36.9176 29.7988C36.9176 29.8502 36.9134 29.8982 36.9049 29.9428C36.8968 29.9868 36.8852 30.0252 36.8702 30.0578C36.8552 30.0905 36.837 30.1162 36.8158 30.1348C36.7948 30.1535 36.7714 30.1628 36.7454 30.1628ZM36.7454 29.5968C36.7323 29.5968 36.7202 29.6022 36.7093 29.6128C36.6983 29.6228 36.689 29.6368 36.6811 29.6548C36.6736 29.6728 36.6677 29.6942 36.6633 29.7188C36.6589 29.7435 36.6567 29.7702 36.6567 29.7988C36.6567 29.8282 36.6589 29.8555 36.6633 29.8808C36.6677 29.9055 36.6738 29.9268 36.6816 29.9448C36.6894 29.9628 36.6986 29.9772 36.7093 29.9878C36.7202 29.9978 36.7323 30.0028 36.7454 30.0028C36.7585 30.0028 36.7704 29.9978 36.7811 29.9878C36.792 29.9772 36.8014 29.9628 36.8092 29.9448C36.817 29.9268 36.8231 29.9055 36.8275 29.8808C36.8319 29.8555 36.8341 29.8282 36.8341 29.7988C36.8341 29.7702 36.8319 29.7435 36.8275 29.7188C36.8231 29.6942 36.817 29.6728 36.8092 29.6548C36.8017 29.6368 36.7925 29.6228 36.7815 29.6128C36.7706 29.6022 36.7585 29.5968 36.7454 29.5968Z"
          fill="#E54545"
        />
        <path
          d="M37.1813 29.6648C37.1813 29.7035 37.1774 29.7398 37.1695 29.7738C37.162 29.8072 37.1508 29.8325 37.1358 29.8498L37.1451 29.8868C37.1473 29.8962 37.1503 29.9082 37.1541 29.9228C37.1578 29.9368 37.1623 29.9545 37.1677 29.9758C37.173 29.9965 37.1792 30.0212 37.1864 30.0498C37.1936 30.0785 37.2019 30.1118 37.2113 30.1498H37.1226L37.064 29.8908H37.0396V30.1498H36.9594V29.4498H37.0663C37.1054 29.4498 37.1344 29.4682 37.1531 29.5048C37.1719 29.5415 37.1813 29.5948 37.1813 29.6648ZM37.0396 29.5968V29.7478H37.0635C37.0745 29.7478 37.0831 29.7412 37.0893 29.7278C37.0959 29.7138 37.0992 29.6948 37.0992 29.6708C37.0992 29.6475 37.0962 29.6295 37.0903 29.6168C37.0846 29.6035 37.0745 29.5968 37.0598 29.5968H37.0396Z"
          fill="#E54545"
        />
        <rect
          x="5.14404"
          y="19.9355"
          width="4.69127"
          height="11.9167"
          rx="1"
          fill="#FFD049"
        />
        <path
          d="M7.01172 25.5704L7.49003 23.7012L7.41031 25.0606H7.96835L7.41031 26.7599L7.49003 25.5704H7.01172Z"
          fill="#4F5A60"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.01161 24.044C6.79042 24.1607 6.60903 24.365 6.52712 24.6568L6.05587 26.3355C5.87683 26.9733 6.3562 27.6058 7.01866 27.6058H7.96116C8.62361 27.6058 9.10299 26.9733 8.92394 26.3355L8.45269 24.6568C8.37078 24.365 8.18939 24.1607 7.96818 24.0439L8.7164 26.6462C8.77154 26.8379 8.62761 27.0291 8.42809 27.0291H6.55171C6.35219 27.0291 6.20826 26.8379 6.26339 26.6462L7.01161 24.044Z"
          fill="#4F5A60"
        />
        <path
          d="M6.17425 28.8586C6.21804 28.8586 6.25765 28.8719 6.2931 28.8986C6.32906 28.9252 6.35956 28.9636 6.38458 29.0136C6.4096 29.0636 6.42888 29.1247 6.44243 29.1969C6.45599 29.2691 6.46276 29.3508 6.46276 29.4419C6.46276 29.533 6.45599 29.6147 6.44243 29.6869C6.42888 29.7591 6.4096 29.8203 6.38458 29.8703C6.35956 29.9203 6.32906 29.9586 6.2931 29.9853C6.25765 30.0119 6.21804 30.0253 6.17425 30.0253H6.01318V28.8586H6.17425ZM6.14688 29.1052V29.7786H6.17425C6.2196 29.7786 6.25583 29.7508 6.28293 29.6953C6.31004 29.6386 6.32359 29.5541 6.32359 29.4419C6.32359 29.3297 6.31004 29.2458 6.28293 29.1902C6.25583 29.1336 6.2196 29.1052 6.17425 29.1052H6.14688Z"
          fill="#E54545"
        />
        <path
          d="M6.66219 29.7636L6.6231 30.0253H6.48861L6.67783 28.8586H6.81857L7.00778 30.0253H6.8733L6.8342 29.7636H6.66219ZM6.7482 29.1602L6.69659 29.5303H6.7998L6.7482 29.1602Z"
          fill="#E54545"
        />
        <path
          d="M7.19057 29.3102V30.0253H7.05921V28.8586H7.19526L7.39542 29.5819V28.8586H7.52678V30.0253H7.39386L7.19057 29.3102Z"
          fill="#E54545"
        />
        <path
          d="M8.12864 29.8636C8.11405 29.8869 8.09867 29.9097 8.08251 29.9319C8.06688 29.9541 8.04915 29.9741 8.02935 29.9919C8.00954 30.0086 7.98712 30.0219 7.9621 30.0319C7.93761 30.0419 7.90972 30.0469 7.87844 30.0469C7.83987 30.0469 7.80338 30.033 7.76898 30.0053C7.73458 29.9764 7.70435 29.9358 7.67828 29.8836C7.65274 29.8314 7.63241 29.7675 7.6173 29.6919C7.6027 29.6164 7.5954 29.5319 7.5954 29.4386C7.5954 29.3552 7.60218 29.2769 7.61573 29.2036C7.62981 29.1302 7.64935 29.0669 7.67437 29.0136C7.69939 28.9591 7.72937 28.9164 7.76429 28.8852C7.79973 28.853 7.83883 28.8369 7.88157 28.8369C7.91389 28.8369 7.9423 28.8441 7.9668 28.8586C7.99129 28.8719 8.01241 28.8891 8.03013 28.9102C8.04837 28.9314 8.06349 28.9536 8.07548 28.9769C8.08747 29.0002 8.09711 29.0214 8.10441 29.0402L8.01996 29.2269C8.01684 29.2191 8.01162 29.208 8.00433 29.1936C7.99755 29.1791 7.98843 29.1652 7.97696 29.1519C7.96601 29.1375 7.95298 29.1252 7.93787 29.1152C7.92275 29.1052 7.90607 29.1002 7.88783 29.1002C7.86333 29.1002 7.84143 29.1091 7.82215 29.1269C7.80338 29.1436 7.78722 29.1669 7.77367 29.1969C7.76064 29.2269 7.75074 29.2625 7.74396 29.3036C7.73718 29.3436 7.7338 29.3869 7.7338 29.4336C7.7338 29.488 7.73771 29.5369 7.74552 29.5803C7.75334 29.6236 7.76403 29.6603 7.77758 29.6903C7.79166 29.7203 7.80807 29.7436 7.82684 29.7603C7.8456 29.7758 7.86593 29.7836 7.88783 29.7836C7.90503 29.7836 7.9204 29.7808 7.93396 29.7753C7.94751 29.7697 7.95898 29.763 7.96836 29.7553C7.97826 29.7475 7.98608 29.7397 7.99182 29.7319C7.99807 29.723 8.00224 29.7164 8.00433 29.7119V29.6086H7.90972V29.3702H8.12864V29.8636Z"
          fill="#E54545"
        />
        <path
          d="M8.21069 30.0253V28.8586H8.50233V29.1136H8.34439V29.3086H8.47887V29.5619H8.34439V29.7703H8.50858V30.0253H8.21069Z"
          fill="#E54545"
        />
        <path
          d="M8.96 29.2169C8.96 29.2814 8.95348 29.3419 8.94045 29.3986C8.92794 29.4541 8.90918 29.4964 8.88416 29.5253L8.8998 29.5869C8.90344 29.6025 8.9084 29.6225 8.91465 29.6469C8.92091 29.6703 8.92846 29.6997 8.93733 29.7353C8.94619 29.7697 8.95661 29.8108 8.9686 29.8586C8.98059 29.9064 8.9944 29.9619 9.01004 30.0253H8.86227L8.76453 29.5936H8.72387V30.0253H8.59017V28.8586H8.76844C8.8336 28.8586 8.88181 28.8891 8.91309 28.9502C8.94436 29.0114 8.96 29.1002 8.96 29.2169ZM8.72387 29.1036V29.3552H8.76375C8.78199 29.3552 8.79633 29.3441 8.80675 29.3219C8.8177 29.2986 8.82317 29.2669 8.82317 29.2269C8.82317 29.188 8.81822 29.158 8.80832 29.1369C8.79893 29.1147 8.78199 29.1036 8.75749 29.1036H8.72387Z"
          fill="#E54545"
        />
        <rect
          x="21.4854"
          y="10.333"
          width="19.3906"
          height="0.333334"
          fill="#A4B9B8"
        />
        <rect
          x="2.25098"
          y="10.333"
          width="19.0778"
          height="0.333334"
          fill="#A4B9B8"
        />
        <g filter="url(#illus-lvb_filter1_d)">
          <rect
            x="14.3115"
            y="2.33301"
            width="5.62952"
            height="6.66667"
            rx="1"
            fill="#95AFAE"
          />
        </g>
        <ellipse
          cx="14.6778"
          cy="3.06217"
          rx="0.136828"
          ry="0.291667"
          fill="#73908E"
        />
        <ellipse
          cx="19.5743"
          cy="3.06217"
          rx="0.136828"
          ry="0.291667"
          fill="#73908E"
        />
        <ellipse
          cx="14.6778"
          cy="8.27018"
          rx="0.136828"
          ry="0.291667"
          fill="#73908E"
        />
        <ellipse
          cx="19.5743"
          cy="8.27018"
          rx="0.136828"
          ry="0.291667"
          fill="#73908E"
        />
        <rect
          x="14.7417"
          y="4.02051"
          width="4.76945"
          height="2.64583"
          rx="1"
          fill="#4F5A60"
        />
        <rect
          x="15.3867"
          y="7.375"
          width="3.47936"
          height="0.604167"
          rx="0.302083"
          fill="#4F5A60"
        />
        <ellipse
          cx="19.1739"
          cy="5.34375"
          rx="0.124612"
          ry="0.265625"
          fill="#6D7A82"
        />
        <ellipse
          cx="15.0787"
          cy="5.34375"
          rx="0.124611"
          ry="0.265625"
          fill="#6D7A82"
        />
        <g filter="url(#illus-lvb_filter2_d)">
          <rect
            x="8.33984"
            y="2.33301"
            width="5.62952"
            height="6.66667"
            rx="1"
            fill="#95AFAE"
          />
        </g>
        <ellipse
          cx="8.70616"
          cy="3.06217"
          rx="0.136829"
          ry="0.291667"
          fill="#73908E"
        />
        <ellipse
          cx="13.6027"
          cy="3.06217"
          rx="0.136832"
          ry="0.291667"
          fill="#73908E"
        />
        <ellipse
          cx="8.70616"
          cy="8.27018"
          rx="0.136829"
          ry="0.291667"
          fill="#73908E"
        />
        <ellipse
          cx="13.6027"
          cy="8.27018"
          rx="0.136832"
          ry="0.291667"
          fill="#73908E"
        />
        <rect
          x="8.95068"
          y="4.02051"
          width="0.517994"
          height="2.64583"
          fill="#4F5A60"
        />
        <rect
          x="8.95068"
          y="4.69727"
          width="0.517994"
          height="0.395834"
          fill="#818A91"
        />
        <rect
          x="9.5957"
          y="4.02051"
          width="0.517994"
          height="2.64583"
          fill="#4F5A60"
        />
        <rect
          x="9.5957"
          y="5.66602"
          width="0.517994"
          height="0.395833"
          fill="#818A91"
        />
        <rect
          x="10.2407"
          y="4.02051"
          width="0.517994"
          height="2.64583"
          fill="#4F5A60"
        />
        <rect
          x="10.2407"
          y="4.69727"
          width="0.517994"
          height="0.395834"
          fill="#818A91"
        />
        <rect
          x="10.8857"
          y="4.02051"
          width="0.517994"
          height="2.64583"
          fill="#4F5A60"
        />
        <rect
          x="10.8857"
          y="5.66602"
          width="0.517994"
          height="0.395833"
          fill="#818A91"
        />
        <rect
          x="11.5312"
          y="4.02051"
          width="0.517994"
          height="2.64583"
          fill="#4F5A60"
        />
        <rect
          x="11.5312"
          y="4.69727"
          width="0.517994"
          height="0.395834"
          fill="#818A91"
        />
        <rect
          x="12.8403"
          y="4.02051"
          width="0.517994"
          height="2.64583"
          fill="#4F5A60"
        />
        <rect
          x="12.8403"
          y="4.69727"
          width="0.517994"
          height="0.395834"
          fill="#818A91"
        />
        <rect
          x="12.1763"
          y="4.02051"
          width="0.517994"
          height="2.64583"
          fill="#4F5A60"
        />
        <rect
          x="12.1763"
          y="5.66602"
          width="0.517994"
          height="0.395833"
          fill="#818A91"
        />
        <rect
          x="9.41504"
          y="7.375"
          width="3.47936"
          height="0.604167"
          rx="0.302083"
          fill="#4F5A60"
        />
        <rect
          x="27.0854"
          y="3.54199"
          width="1.94492"
          height="4.14583"
          fill="#4F5A60"
        />
        <rect
          x="27.2417"
          y="3.875"
          width="1.63217"
          height="3.47917"
          fill="#B6D6E1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.5612 6.88587C27.5724 5.49649 28.0998 4.3752 28.7521 4.35572V4.11816C28.0382 4.13762 27.461 5.36526 27.4497 6.88587H27.5612Z"
          fill="#AFBEC7"
        />
        <path
          d="M27.539 4.68574L27.5122 4.85449H27.4497L27.5588 4.19824H27.6239L27.7329 4.85449H27.6705L27.6437 4.68574H27.539ZM27.5913 4.3398L27.5557 4.57605H27.6274L27.5913 4.3398Z"
          fill="#4F5A60"
        />
        <ellipse
          cx="28.4733"
          cy="6.51074"
          rx="0.175922"
          ry="0.375"
          fill="#3D4A51"
        />
        <rect
          x="27.5693"
          y="6.51074"
          width="0.796537"
          height="0.125"
          rx="0.0625"
          fill="#3D4A51"
        />
        <rect
          width="0.847792"
          height="0.1217"
          rx="0.0608498"
          transform="matrix(0.868651 0.495424 -0.124546 0.992214 27.6294 5.96973)"
          fill="#3D4A51"
        />
        <rect
          width="0.0439978"
          height="0.60638"
          rx="0.0219989"
          transform="matrix(0.854419 -0.519585 0.13264 0.991164 28.4731 4.36719)"
          fill="#AFBEC7"
        />
        <rect
          width="0.0624459"
          height="0.507208"
          rx="0.031223"
          transform="matrix(0.469451 -0.882959 0.382506 0.923953 27.9902 4.90918)"
          fill="#AFBEC7"
        />
        <rect
          width="0.0833016"
          height="0.293727"
          rx="0.0416508"
          transform="matrix(-0.0147681 0.999891 0.997756 0.066957 27.5308 6.69238)"
          fill="#AFBEC7"
        />
        <rect
          width="0.0759095"
          height="0.390472"
          rx="0.0379548"
          transform="matrix(-0.240613 0.970621 0.663916 0.747807 27.7266 5.54102)"
          fill="#AFBEC7"
        />
        <rect
          x="30.3398"
          y="3.54199"
          width="1.94492"
          height="4.14583"
          fill="#4F5A60"
        />
        <rect
          x="30.4961"
          y="3.875"
          width="1.63217"
          height="3.47917"
          fill="#B6D6E1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.8156 6.88587C30.8268 5.4965 31.3542 4.37521 32.0065 4.35572V4.11816C31.2926 4.13763 30.7154 5.36527 30.7041 6.88587H30.8156Z"
          fill="#AFBEC7"
        />
        <path
          d="M30.7934 4.68574L30.7666 4.85449H30.7041L30.8132 4.19824H30.8783L30.9873 4.85449H30.9249L30.8981 4.68574H30.7934ZM30.8457 4.3398L30.8101 4.57605H30.8818L30.8457 4.3398Z"
          fill="#4F5A60"
        />
        <ellipse
          cx="31.7277"
          cy="6.51074"
          rx="0.175922"
          ry="0.375"
          fill="#3D4A51"
        />
        <rect
          x="30.8237"
          y="6.51074"
          width="0.796537"
          height="0.125"
          rx="0.0625"
          fill="#3D4A51"
        />
        <rect
          width="0.847779"
          height="0.1217"
          rx="0.0608498"
          transform="matrix(0.868647 0.495431 -0.124544 0.992214 30.8838 5.96973)"
          fill="#3D4A51"
        />
        <rect
          width="0.0440024"
          height="0.606382"
          rx="0.0220012"
          transform="matrix(0.854452 -0.51953 0.132659 0.991162 31.7275 4.36719)"
          fill="#AFBEC7"
        />
        <rect
          width="0.062445"
          height="0.507203"
          rx="0.0312225"
          transform="matrix(0.469426 -0.882972 0.382484 0.923962 31.2441 4.90918)"
          fill="#AFBEC7"
        />
        <rect
          width="0.0833016"
          height="0.293701"
          rx="0.0416508"
          transform="matrix(-0.0147668 0.999891 0.997755 0.066963 30.7852 6.69238)"
          fill="#AFBEC7"
        />
        <rect
          width="0.0759093"
          height="0.390463"
          rx="0.0379547"
          transform="matrix(-0.2406 0.970624 0.663896 0.747825 30.981 5.54102)"
          fill="#AFBEC7"
        />
        <rect
          x="24.9839"
          y="3.54199"
          width="1.94492"
          height="4.14583"
          fill="#4F5A60"
        />
        <rect
          x="25.1401"
          y="3.875"
          width="1.63217"
          height="3.47917"
          fill="#B6D6E1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.4596 6.88587C25.4709 5.49649 25.9982 4.37521 26.6505 4.35572V4.11816C25.9366 4.13762 25.3594 5.36526 25.3481 6.88587H25.4596Z"
          fill="#AFBEC7"
        />
        <path
          d="M25.4374 4.68574L25.4106 4.85449H25.3481L25.4572 4.19824H25.5223L25.6314 4.85449H25.5689L25.5421 4.68574H25.4374ZM25.4898 4.3398L25.4541 4.57605H25.5258L25.4898 4.3398Z"
          fill="#4F5A60"
        />
        <ellipse
          cx="26.3717"
          cy="6.51074"
          rx="0.175921"
          ry="0.375"
          fill="#3D4A51"
        />
        <rect
          x="25.4678"
          y="6.51074"
          width="0.796539"
          height="0.125"
          rx="0.0625"
          fill="#3D4A51"
        />
        <rect
          width="0.847792"
          height="0.1217"
          rx="0.0608498"
          transform="matrix(0.868651 0.495424 -0.124546 0.992214 25.5278 5.96973)"
          fill="#3D4A51"
        />
        <rect
          width="0.0439978"
          height="0.60638"
          rx="0.0219989"
          transform="matrix(0.854419 -0.519585 0.13264 0.991164 26.3716 4.36719)"
          fill="#AFBEC7"
        />
        <rect
          width="0.062445"
          height="0.507203"
          rx="0.0312225"
          transform="matrix(0.469426 -0.882972 0.382484 0.923962 25.8887 4.90918)"
          fill="#AFBEC7"
        />
        <rect
          width="0.0833016"
          height="0.293727"
          rx="0.0416508"
          transform="matrix(-0.0147681 0.999891 0.997756 0.066957 25.4292 6.69238)"
          fill="#AFBEC7"
        />
        <rect
          width="0.0759098"
          height="0.390481"
          rx="0.0379549"
          transform="matrix(-0.240624 0.970618 0.663934 0.747791 25.625 5.54102)"
          fill="#AFBEC7"
        />
        <rect
          x="22.8828"
          y="3.54199"
          width="1.94492"
          height="4.14583"
          fill="#4F5A60"
        />
        <rect
          x="23.0391"
          y="3.875"
          width="1.63217"
          height="3.47917"
          fill="#B6D6E1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.3586 6.88587C23.3698 5.49649 23.8971 4.37521 24.5494 4.35572V4.11816C23.8356 4.13762 23.2583 5.36526 23.2471 6.88587H23.3586Z"
          fill="#AFBEC7"
        />
        <path
          d="M23.3364 4.68574L23.3095 4.85449H23.2471L23.3561 4.19824H23.4212L23.5303 4.85449H23.4679L23.441 4.68574H23.3364ZM23.3887 4.3398L23.3531 4.57605H23.4248L23.3887 4.3398Z"
          fill="#4F5A60"
        />
        <ellipse
          cx="24.2706"
          cy="6.51074"
          rx="0.175922"
          ry="0.375"
          fill="#3D4A51"
        />
        <rect
          x="23.3667"
          y="6.51074"
          width="0.796537"
          height="0.125"
          rx="0.0625"
          fill="#3D4A51"
        />
        <rect
          width="0.847792"
          height="0.1217"
          rx="0.0608498"
          transform="matrix(0.868651 0.495424 -0.124546 0.992214 23.4268 5.96973)"
          fill="#3D4A51"
        />
        <rect
          width="0.0439978"
          height="0.60638"
          rx="0.0219989"
          transform="matrix(0.854419 -0.519585 0.13264 0.991164 24.2705 4.36719)"
          fill="#AFBEC7"
        />
        <rect
          width="0.062445"
          height="0.507203"
          rx="0.0312225"
          transform="matrix(0.469426 -0.882972 0.382484 0.923962 23.7876 4.90918)"
          fill="#AFBEC7"
        />
        <rect
          width="0.0833016"
          height="0.293712"
          rx="0.0416508"
          transform="matrix(-0.0147674 0.999891 0.997756 0.0669604 23.3281 6.69238)"
          fill="#AFBEC7"
        />
        <rect
          width="0.0759095"
          height="0.390472"
          rx="0.0379548"
          transform="matrix(-0.240613 0.970621 0.663916 0.747807 23.5239 5.54102)"
          fill="#AFBEC7"
        />
        <rect
          x="29.1865"
          y="4.52148"
          width="0.996893"
          height="2.125"
          fill="#4F5A60"
        />
        <rect
          x="29.2451"
          y="4.64648"
          width="0.879612"
          height="1.875"
          fill="#C4DAD7"
        />
        <rect
          x="29.6631"
          y="4.7041"
          width="0.043972"
          height="0.322916"
          rx="0.021986"
          fill="#859BA8"
        />
        <rect
          x="29.6631"
          y="6.1416"
          width="0.043972"
          height="0.322917"
          rx="0.021986"
          fill="#859BA8"
        />
        <rect
          x="30.0977"
          y="5.53711"
          width="0.0937505"
          height="0.151489"
          rx="0.0468752"
          transform="rotate(90 30.0977 5.53711)"
          fill="#859BA8"
        />
        <rect
          x="29.4233"
          y="5.53711"
          width="0.0937505"
          height="0.151487"
          rx="0.0468752"
          transform="rotate(90 29.4233 5.53711)"
          fill="#859BA8"
        />
        <rect
          width="0.0732239"
          height="0.252211"
          rx="0.0366119"
          transform="matrix(-0.424694 0.905337 -0.424694 -0.905337 29.9922 6.17383)"
          fill="#859BA8"
        />
        <rect
          width="0.0732241"
          height="0.252212"
          rx="0.036612"
          transform="matrix(-0.424693 0.905338 -0.424693 -0.905338 29.5156 5.15723)"
          fill="#859BA8"
        />
        <rect
          width="0.0732266"
          height="0.25222"
          rx="0.0366133"
          transform="matrix(-0.42476 -0.905306 0.42476 -0.905306 29.4087 6.24023)"
          fill="#859BA8"
        />
        <rect
          width="0.0732264"
          height="0.25222"
          rx="0.0366132"
          transform="matrix(-0.424761 -0.905305 0.424762 -0.905305 29.8853 5.22363)"
          fill="#859BA8"
        />
        <ellipse
          cx="29.6848"
          cy="5.49544"
          rx="0.288318"
          ry="0.614583"
          fill="#404B51"
        />
        <ellipse
          cx="29.6849"
          cy="5.4952"
          rx="0.217649"
          ry="0.46395"
          fill="#4F5A60"
        />
        <rect
          x="29.5942"
          y="6.02148"
          width="0.180809"
          height="0.687093"
          fill="#404B51"
        />
        <rect
          x="22.8828"
          y="8.35449"
          width="6.14751"
          height="0.645833"
          fill="#4F5A60"
        />
        <rect x="42.127" width="1.25101" height="80" fill="#95AFAE" />
        <rect x="82.0029" width="1.25101" height="80" fill="#95AFAE" />
        <rect
          width="38.6247"
          height="78.4896"
          transform="matrix(-1 0 0 1 82.0029 0.676758)"
          fill="#95AFAE"
        />
        <g filter="url(#illus-lvb_filter3_d)">
          <rect
            x="43.3779"
            y="10.333"
            width="38.6247"
            height="59.3333"
            fill="#C4C4C4"
          />
        </g>
        <rect
          width="0.151485"
          height="78.4896"
          transform="matrix(-1 0 0 1 43.5293 0.676758)"
          fill="#8AA5A5"
        />
        <rect
          width="0.151489"
          height="78.4896"
          transform="matrix(-1 0 0 1 82.0029 0.676758)"
          fill="#8AA5A5"
        />
        <rect
          width="19.3906"
          height="59.3333"
          transform="matrix(-1 0 0 1 82.0029 10.333)"
          fill="#95AFAE"
        />
        <rect
          width="19.2342"
          height="59.3333"
          transform="matrix(-1 0 0 1 62.6123 10.333)"
          fill="#95AFAE"
        />
        <rect
          width="0.156376"
          height="59.3333"
          transform="matrix(-1 0 0 1 62.6123 10.333)"
          fill="#8AA5A5"
        />
        <rect
          x="65.1143"
          y="15.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.896"
          y="15.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="66.6782"
          y="15.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="67.46" y="15.333" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="68.2417"
          y="15.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.0234"
          y="15.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.8057"
          y="15.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="70.5874"
          y="15.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="65.1143" y="17" width="0.469124" height="1" fill="#445B5B" />
        <rect x="65.896" y="17" width="0.469124" height="1" fill="#445B5B" />
        <rect x="66.6782" y="17" width="0.469131" height="1" fill="#445B5B" />
        <rect x="67.46" y="17" width="0.469131" height="1" fill="#445B5B" />
        <rect x="68.2417" y="17" width="0.469124" height="1" fill="#445B5B" />
        <rect x="69.0234" y="17" width="0.469124" height="1" fill="#445B5B" />
        <rect x="69.8057" y="17" width="0.469131" height="1" fill="#445B5B" />
        <rect x="70.5874" y="17" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="65.1143"
          y="18.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.896"
          y="18.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="66.6782"
          y="18.666"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="67.46" y="18.666" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="68.2417"
          y="18.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.0234"
          y="18.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.8057"
          y="18.666"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="70.5874"
          y="18.666"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.1143"
          y="20.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.896"
          y="20.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="66.6782"
          y="20.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="67.46" y="20.333" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="68.2417"
          y="20.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.0234"
          y="20.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.8057"
          y="20.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="70.5874"
          y="20.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="65.1143" y="22" width="0.469124" height="1" fill="#445B5B" />
        <rect x="65.896" y="22" width="0.469124" height="1" fill="#445B5B" />
        <rect x="66.6782" y="22" width="0.469131" height="1" fill="#445B5B" />
        <rect x="67.46" y="22" width="0.469131" height="1" fill="#445B5B" />
        <rect x="68.2417" y="22" width="0.469124" height="1" fill="#445B5B" />
        <rect x="69.0234" y="22" width="0.469124" height="1" fill="#445B5B" />
        <rect x="69.8057" y="22" width="0.469131" height="1" fill="#445B5B" />
        <rect x="70.5874" y="22" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="65.1143"
          y="23.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.896"
          y="23.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="66.6782"
          y="23.666"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="67.46" y="23.666" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="68.2417"
          y="23.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.0234"
          y="23.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.8057"
          y="23.666"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="70.5874"
          y="23.666"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.1143"
          y="25.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.896"
          y="25.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="66.6782"
          y="25.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="67.46" y="25.333" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="68.2417"
          y="25.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.0234"
          y="25.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.8057"
          y="25.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="70.5874"
          y="25.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="65.1143" y="27" width="0.469124" height="1" fill="#445B5B" />
        <rect x="65.896" y="27" width="0.469124" height="1" fill="#445B5B" />
        <rect x="66.6782" y="27" width="0.469131" height="1" fill="#445B5B" />
        <rect x="67.46" y="27" width="0.469131" height="1" fill="#445B5B" />
        <rect x="68.2417" y="27" width="0.469124" height="1" fill="#445B5B" />
        <rect x="69.0234" y="27" width="0.469124" height="1" fill="#445B5B" />
        <rect x="69.8057" y="27" width="0.469131" height="1" fill="#445B5B" />
        <rect x="70.5874" y="27" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="65.1143"
          y="28.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.896"
          y="28.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="66.6782"
          y="28.666"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="67.46" y="28.666" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="68.2417"
          y="28.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.0234"
          y="28.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.8057"
          y="28.666"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="70.5874"
          y="28.666"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.1143"
          y="30.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.896"
          y="30.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="66.6782"
          y="30.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="67.46" y="30.333" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="68.2417"
          y="30.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.0234"
          y="30.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.8057"
          y="30.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="70.5874"
          y="30.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="65.1143" y="32" width="0.469124" height="1" fill="#445B5B" />
        <rect x="65.896" y="32" width="0.469124" height="1" fill="#445B5B" />
        <rect x="66.6782" y="32" width="0.469131" height="1" fill="#445B5B" />
        <rect x="67.46" y="32" width="0.469131" height="1" fill="#445B5B" />
        <rect x="68.2417" y="32" width="0.469124" height="1" fill="#445B5B" />
        <rect x="69.0234" y="32" width="0.469124" height="1" fill="#445B5B" />
        <rect x="69.8057" y="32" width="0.469131" height="1" fill="#445B5B" />
        <rect x="70.5874" y="32" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="65.1143"
          y="33.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.896"
          y="33.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="66.6782"
          y="33.666"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="67.46" y="33.666" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="68.2417"
          y="33.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.0234"
          y="33.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.8057"
          y="33.666"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="70.5874"
          y="33.666"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.1143"
          y="35.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.896"
          y="35.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="66.6782"
          y="35.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="67.46" y="35.333" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="68.2417"
          y="35.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.0234"
          y="35.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.8057"
          y="35.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="70.5874"
          y="35.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="65.1143" y="37" width="0.469124" height="1" fill="#445B5B" />
        <rect x="65.896" y="37" width="0.469124" height="1" fill="#445B5B" />
        <rect x="66.6782" y="37" width="0.469131" height="1" fill="#445B5B" />
        <rect x="67.46" y="37" width="0.469131" height="1" fill="#445B5B" />
        <rect x="68.2417" y="37" width="0.469124" height="1" fill="#445B5B" />
        <rect x="69.0234" y="37" width="0.469124" height="1" fill="#445B5B" />
        <rect x="69.8057" y="37" width="0.469131" height="1" fill="#445B5B" />
        <rect x="70.5874" y="37" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="73.5586"
          y="15.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="74.3403"
          y="15.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.1226"
          y="15.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.9043"
          y="15.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="76.686"
          y="15.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="77.4678"
          y="15.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="78.25" y="15.333" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="79.0317"
          y="15.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect x="73.5586" y="17" width="0.469124" height="1" fill="#445B5B" />
        <rect x="74.3403" y="17" width="0.469124" height="1" fill="#445B5B" />
        <rect x="75.1226" y="17" width="0.469124" height="1" fill="#445B5B" />
        <rect x="75.9043" y="17" width="0.469124" height="1" fill="#445B5B" />
        <rect x="76.686" y="17" width="0.469124" height="1" fill="#445B5B" />
        <rect x="77.4678" y="17" width="0.469131" height="1" fill="#445B5B" />
        <rect x="78.25" y="17" width="0.469124" height="1" fill="#445B5B" />
        <rect x="79.0317" y="17" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="73.5586"
          y="18.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="74.3403"
          y="18.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.1226"
          y="18.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.9043"
          y="18.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="76.686"
          y="18.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="77.4678"
          y="18.666"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="78.25" y="18.666" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="79.0317"
          y="18.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="73.5586"
          y="20.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="74.3403"
          y="20.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.1226"
          y="20.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.9043"
          y="20.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="76.686"
          y="20.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="77.4678"
          y="20.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="78.25" y="20.333" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="79.0317"
          y="20.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect x="73.5586" y="22" width="0.469124" height="1" fill="#445B5B" />
        <rect x="74.3403" y="22" width="0.469124" height="1" fill="#445B5B" />
        <rect x="75.1226" y="22" width="0.469124" height="1" fill="#445B5B" />
        <rect x="75.9043" y="22" width="0.469124" height="1" fill="#445B5B" />
        <rect x="76.686" y="22" width="0.469124" height="1" fill="#445B5B" />
        <rect x="77.4678" y="22" width="0.469131" height="1" fill="#445B5B" />
        <rect x="78.25" y="22" width="0.469124" height="1" fill="#445B5B" />
        <rect x="79.0317" y="22" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="73.5586"
          y="23.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="74.3403"
          y="23.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.1226"
          y="23.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.9043"
          y="23.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="76.686"
          y="23.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="77.4678"
          y="23.666"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="78.25" y="23.666" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="79.0317"
          y="23.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="73.5586"
          y="25.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="74.3403"
          y="25.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.1226"
          y="25.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.9043"
          y="25.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="76.686"
          y="25.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="77.4678"
          y="25.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="78.25" y="25.333" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="79.0317"
          y="25.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect x="73.5586" y="27" width="0.469124" height="1" fill="#445B5B" />
        <rect x="74.3403" y="27" width="0.469124" height="1" fill="#445B5B" />
        <rect x="75.1226" y="27" width="0.469124" height="1" fill="#445B5B" />
        <rect x="75.9043" y="27" width="0.469124" height="1" fill="#445B5B" />
        <rect x="76.686" y="27" width="0.469124" height="1" fill="#445B5B" />
        <rect x="77.4678" y="27" width="0.469131" height="1" fill="#445B5B" />
        <rect x="78.25" y="27" width="0.469124" height="1" fill="#445B5B" />
        <rect x="79.0317" y="27" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="73.5586"
          y="28.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="74.3403"
          y="28.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.1226"
          y="28.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.9043"
          y="28.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="76.686"
          y="28.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="77.4678"
          y="28.666"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="78.25" y="28.666" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="79.0317"
          y="28.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="73.5586"
          y="30.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="74.3403"
          y="30.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.1226"
          y="30.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.9043"
          y="30.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="76.686"
          y="30.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="77.4678"
          y="30.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="78.25" y="30.333" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="79.0317"
          y="30.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect x="73.5586" y="32" width="0.469124" height="1" fill="#445B5B" />
        <rect x="74.3403" y="32" width="0.469124" height="1" fill="#445B5B" />
        <rect x="75.1226" y="32" width="0.469124" height="1" fill="#445B5B" />
        <rect x="75.9043" y="32" width="0.469124" height="1" fill="#445B5B" />
        <rect x="76.686" y="32" width="0.469124" height="1" fill="#445B5B" />
        <rect x="77.4678" y="32" width="0.469131" height="1" fill="#445B5B" />
        <rect x="78.25" y="32" width="0.469124" height="1" fill="#445B5B" />
        <rect x="79.0317" y="32" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="73.5586"
          y="33.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="74.3403"
          y="33.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.1226"
          y="33.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.9043"
          y="33.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="76.686"
          y="33.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="77.4678"
          y="33.666"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="78.25" y="33.666" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="79.0317"
          y="33.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="73.5586"
          y="35.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="74.3403"
          y="35.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.1226"
          y="35.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.9043"
          y="35.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="76.686"
          y="35.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="77.4678"
          y="35.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="78.25" y="35.333" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="79.0317"
          y="35.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect x="73.5586" y="37" width="0.469124" height="1" fill="#445B5B" />
        <rect x="74.3403" y="37" width="0.469124" height="1" fill="#445B5B" />
        <rect x="75.1226" y="37" width="0.469124" height="1" fill="#445B5B" />
        <rect x="75.9043" y="37" width="0.469124" height="1" fill="#445B5B" />
        <rect x="76.686" y="37" width="0.469124" height="1" fill="#445B5B" />
        <rect x="77.4678" y="37" width="0.469131" height="1" fill="#445B5B" />
        <rect x="78.25" y="37" width="0.469124" height="1" fill="#445B5B" />
        <rect x="79.0317" y="37" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="65.1143"
          y="42"
          width="0.469124"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="65.896"
          y="42"
          width="0.469124"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="66.6782"
          y="42"
          width="0.469131"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="67.46"
          y="42"
          width="0.469131"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="68.2417"
          y="42"
          width="0.469124"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="69.0234"
          y="42"
          width="0.469124"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="69.8057"
          y="42"
          width="0.469131"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="70.5874"
          y="42"
          width="0.469131"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="65.1143"
          y="43.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.896"
          y="43.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="66.6782"
          y="43.667"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="67.46" y="43.667" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="68.2417"
          y="43.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.0234"
          y="43.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.8057"
          y="43.667"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="70.5874"
          y="43.667"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.1143"
          y="45.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.896"
          y="45.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="66.6782"
          y="45.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="67.46" y="45.333" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="68.2417"
          y="45.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.0234"
          y="45.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.8057"
          y="45.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="70.5874"
          y="45.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="65.1143" y="47" width="0.469124" height="1" fill="#445B5B" />
        <rect x="65.896" y="47" width="0.469124" height="1" fill="#445B5B" />
        <rect x="66.6782" y="47" width="0.469131" height="1" fill="#445B5B" />
        <rect x="67.46" y="47" width="0.469131" height="1" fill="#445B5B" />
        <rect x="68.2417" y="47" width="0.469124" height="1" fill="#445B5B" />
        <rect x="69.0234" y="47" width="0.469124" height="1" fill="#445B5B" />
        <rect x="69.8057" y="47" width="0.469131" height="1" fill="#445B5B" />
        <rect x="70.5874" y="47" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="65.1143"
          y="48.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.896"
          y="48.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="66.6782"
          y="48.667"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="67.46" y="48.667" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="68.2417"
          y="48.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.0234"
          y="48.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.8057"
          y="48.667"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="70.5874"
          y="48.667"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.1143"
          y="50.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.896"
          y="50.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="66.6782"
          y="50.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="67.46" y="50.333" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="68.2417"
          y="50.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.0234"
          y="50.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.8057"
          y="50.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="70.5874"
          y="50.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="65.1143" y="52" width="0.469124" height="1" fill="#445B5B" />
        <rect x="65.896" y="52" width="0.469124" height="1" fill="#445B5B" />
        <rect x="66.6782" y="52" width="0.469131" height="1" fill="#445B5B" />
        <rect x="67.46" y="52" width="0.469131" height="1" fill="#445B5B" />
        <rect x="68.2417" y="52" width="0.469124" height="1" fill="#445B5B" />
        <rect x="69.0234" y="52" width="0.469124" height="1" fill="#445B5B" />
        <rect x="69.8057" y="52" width="0.469131" height="1" fill="#445B5B" />
        <rect x="70.5874" y="52" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="65.1143"
          y="53.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.896"
          y="53.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="66.6782"
          y="53.667"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="67.46" y="53.667" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="68.2417"
          y="53.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.0234"
          y="53.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.8057"
          y="53.667"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="70.5874"
          y="53.667"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.1143"
          y="55.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.896"
          y="55.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="66.6782"
          y="55.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="67.46" y="55.333" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="68.2417"
          y="55.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.0234"
          y="55.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.8057"
          y="55.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="70.5874"
          y="55.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="65.1143" y="57" width="0.469124" height="1" fill="#445B5B" />
        <rect x="65.896" y="57" width="0.469124" height="1" fill="#445B5B" />
        <rect x="66.6782" y="57" width="0.469131" height="1" fill="#445B5B" />
        <rect x="67.46" y="57" width="0.469131" height="1" fill="#445B5B" />
        <rect x="68.2417" y="57" width="0.469124" height="1" fill="#445B5B" />
        <rect x="69.0234" y="57" width="0.469124" height="1" fill="#445B5B" />
        <rect x="69.8057" y="57" width="0.469131" height="1" fill="#445B5B" />
        <rect x="70.5874" y="57" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="65.1143"
          y="58.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.896"
          y="58.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="66.6782"
          y="58.667"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="67.46" y="58.667" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="68.2417"
          y="58.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.0234"
          y="58.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.8057"
          y="58.667"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="70.5874"
          y="58.667"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.1143"
          y="60.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="65.896"
          y="60.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="66.6782"
          y="60.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="67.46" y="60.333" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="68.2417"
          y="60.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.0234"
          y="60.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="69.8057"
          y="60.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="70.5874"
          y="60.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="65.1143" y="62" width="0.469124" height="1" fill="#445B5B" />
        <rect x="65.896" y="62" width="0.469124" height="1" fill="#445B5B" />
        <rect x="66.6782" y="62" width="0.469131" height="1" fill="#445B5B" />
        <rect x="67.46" y="62" width="0.469131" height="1" fill="#445B5B" />
        <rect x="68.2417" y="62" width="0.469124" height="1" fill="#445B5B" />
        <rect x="69.0234" y="62" width="0.469124" height="1" fill="#445B5B" />
        <rect x="69.8057" y="62" width="0.469131" height="1" fill="#445B5B" />
        <rect x="70.5874" y="62" width="0.469131" height="1" fill="#445B5B" />
        <rect
          x="65.1143"
          y="63.667"
          width="0.469124"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="65.896"
          y="63.667"
          width="0.469124"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="66.6782"
          y="63.667"
          width="0.469131"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="67.46"
          y="63.667"
          width="0.469131"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="68.2417"
          y="63.667"
          width="0.469124"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="69.0234"
          y="63.667"
          width="0.469124"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="69.8057"
          y="63.667"
          width="0.469131"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="70.5874"
          y="63.667"
          width="0.469131"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="73.5586"
          y="42"
          width="0.469124"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="74.3403"
          y="42"
          width="0.469124"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="75.1226"
          y="42"
          width="0.469124"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="75.9043"
          y="42"
          width="0.469124"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="76.686"
          y="42"
          width="0.469124"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="77.4678"
          y="42"
          width="0.469131"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="78.25"
          y="42"
          width="0.469124"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="79.0317"
          y="42"
          width="0.469124"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="73.5586"
          y="43.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="74.3403"
          y="43.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.1226"
          y="43.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.9043"
          y="43.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="76.686"
          y="43.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="77.4678"
          y="43.667"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="78.25" y="43.667" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="79.0317"
          y="43.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="73.5586"
          y="45.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="74.3403"
          y="45.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.1226"
          y="45.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.9043"
          y="45.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="76.686"
          y="45.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="77.4678"
          y="45.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="78.25" y="45.333" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="79.0317"
          y="45.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect x="73.5586" y="47" width="0.469124" height="1" fill="#445B5B" />
        <rect x="74.3403" y="47" width="0.469124" height="1" fill="#445B5B" />
        <rect x="75.1226" y="47" width="0.469124" height="1" fill="#445B5B" />
        <rect x="75.9043" y="47" width="0.469124" height="1" fill="#445B5B" />
        <rect x="76.686" y="47" width="0.469124" height="1" fill="#445B5B" />
        <rect x="77.4678" y="47" width="0.469131" height="1" fill="#445B5B" />
        <rect x="78.25" y="47" width="0.469124" height="1" fill="#445B5B" />
        <rect x="79.0317" y="47" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="73.5586"
          y="48.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="74.3403"
          y="48.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.1226"
          y="48.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.9043"
          y="48.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="76.686"
          y="48.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="77.4678"
          y="48.667"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="78.25" y="48.667" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="79.0317"
          y="48.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="73.5586"
          y="50.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="74.3403"
          y="50.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.1226"
          y="50.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.9043"
          y="50.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="76.686"
          y="50.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="77.4678"
          y="50.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="78.25" y="50.333" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="79.0317"
          y="50.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect x="73.5586" y="52" width="0.469124" height="1" fill="#445B5B" />
        <rect x="74.3403" y="52" width="0.469124" height="1" fill="#445B5B" />
        <rect x="75.1226" y="52" width="0.469124" height="1" fill="#445B5B" />
        <rect x="75.9043" y="52" width="0.469124" height="1" fill="#445B5B" />
        <rect x="76.686" y="52" width="0.469124" height="1" fill="#445B5B" />
        <rect x="77.4678" y="52" width="0.469131" height="1" fill="#445B5B" />
        <rect x="78.25" y="52" width="0.469124" height="1" fill="#445B5B" />
        <rect x="79.0317" y="52" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="73.5586"
          y="53.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="74.3403"
          y="53.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.1226"
          y="53.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.9043"
          y="53.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="76.686"
          y="53.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="77.4678"
          y="53.667"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="78.25" y="53.667" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="79.0317"
          y="53.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="73.5586"
          y="55.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="74.3403"
          y="55.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.1226"
          y="55.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.9043"
          y="55.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="76.686"
          y="55.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="77.4678"
          y="55.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="78.25" y="55.333" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="79.0317"
          y="55.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect x="73.5586" y="57" width="0.469124" height="1" fill="#445B5B" />
        <rect x="74.3403" y="57" width="0.469124" height="1" fill="#445B5B" />
        <rect x="75.1226" y="57" width="0.469124" height="1" fill="#445B5B" />
        <rect x="75.9043" y="57" width="0.469124" height="1" fill="#445B5B" />
        <rect x="76.686" y="57" width="0.469124" height="1" fill="#445B5B" />
        <rect x="77.4678" y="57" width="0.469131" height="1" fill="#445B5B" />
        <rect x="78.25" y="57" width="0.469124" height="1" fill="#445B5B" />
        <rect x="79.0317" y="57" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="73.5586"
          y="58.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="74.3403"
          y="58.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.1226"
          y="58.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.9043"
          y="58.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="76.686"
          y="58.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="77.4678"
          y="58.667"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="78.25" y="58.667" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="79.0317"
          y="58.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="73.5586"
          y="60.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="74.3403"
          y="60.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.1226"
          y="60.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="75.9043"
          y="60.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="76.686"
          y="60.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="77.4678"
          y="60.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect x="78.25" y="60.333" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="79.0317"
          y="60.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect x="73.5586" y="62" width="0.469124" height="1" fill="#445B5B" />
        <rect x="74.3403" y="62" width="0.469124" height="1" fill="#445B5B" />
        <rect x="75.1226" y="62" width="0.469124" height="1" fill="#445B5B" />
        <rect x="75.9043" y="62" width="0.469124" height="1" fill="#445B5B" />
        <rect x="76.686" y="62" width="0.469124" height="1" fill="#445B5B" />
        <rect x="77.4678" y="62" width="0.469131" height="1" fill="#445B5B" />
        <rect x="78.25" y="62" width="0.469124" height="1" fill="#445B5B" />
        <rect x="79.0317" y="62" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="73.5586"
          y="63.667"
          width="0.469124"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="74.3403"
          y="63.667"
          width="0.469124"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="75.1226"
          y="63.667"
          width="0.469124"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="75.9043"
          y="63.667"
          width="0.469124"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="76.686"
          y="63.667"
          width="0.469124"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="77.4678"
          y="63.667"
          width="0.469131"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="78.25"
          y="63.667"
          width="0.469124"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="79.0317"
          y="63.667"
          width="0.469124"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="45.8018"
          y="15.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="46.5835"
          y="15.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="47.3657"
          y="15.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.1475"
          y="15.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.9292"
          y="15.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="49.7109"
          y="15.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="50.4932"
          y="15.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="51.2749"
          y="15.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect x="45.8018" y="17" width="0.469128" height="1" fill="#445B5B" />
        <rect x="46.5835" y="17" width="0.469124" height="1" fill="#445B5B" />
        <rect x="47.3657" y="17" width="0.469124" height="1" fill="#445B5B" />
        <rect x="48.1475" y="17" width="0.469128" height="1" fill="#445B5B" />
        <rect x="48.9292" y="17" width="0.469128" height="1" fill="#445B5B" />
        <rect x="49.7109" y="17" width="0.469124" height="1" fill="#445B5B" />
        <rect x="50.4932" y="17" width="0.469124" height="1" fill="#445B5B" />
        <rect x="51.2749" y="17" width="0.469128" height="1" fill="#445B5B" />
        <rect
          x="45.8018"
          y="18.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="46.5835"
          y="18.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="47.3657"
          y="18.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.1475"
          y="18.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.9292"
          y="18.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="49.7109"
          y="18.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="50.4932"
          y="18.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="51.2749"
          y="18.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="45.8018"
          y="20.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="46.5835"
          y="20.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="47.3657"
          y="20.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.1475"
          y="20.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.9292"
          y="20.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="49.7109"
          y="20.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="50.4932"
          y="20.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="51.2749"
          y="20.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect x="45.8018" y="22" width="0.469128" height="1" fill="#445B5B" />
        <rect x="46.5835" y="22" width="0.469124" height="1" fill="#445B5B" />
        <rect x="47.3657" y="22" width="0.469124" height="1" fill="#445B5B" />
        <rect x="48.1475" y="22" width="0.469128" height="1" fill="#445B5B" />
        <rect x="48.9292" y="22" width="0.469128" height="1" fill="#445B5B" />
        <rect x="49.7109" y="22" width="0.469124" height="1" fill="#445B5B" />
        <rect x="50.4932" y="22" width="0.469124" height="1" fill="#445B5B" />
        <rect x="51.2749" y="22" width="0.469128" height="1" fill="#445B5B" />
        <rect
          x="45.8018"
          y="23.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="46.5835"
          y="23.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="47.3657"
          y="23.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.1475"
          y="23.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.9292"
          y="23.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="49.7109"
          y="23.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="50.4932"
          y="23.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="51.2749"
          y="23.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="45.8018"
          y="25.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="46.5835"
          y="25.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="47.3657"
          y="25.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.1475"
          y="25.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.9292"
          y="25.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="49.7109"
          y="25.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="50.4932"
          y="25.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="51.2749"
          y="25.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect x="45.8018" y="27" width="0.469128" height="1" fill="#445B5B" />
        <rect x="46.5835" y="27" width="0.469124" height="1" fill="#445B5B" />
        <rect x="47.3657" y="27" width="0.469124" height="1" fill="#445B5B" />
        <rect x="48.1475" y="27" width="0.469128" height="1" fill="#445B5B" />
        <rect x="48.9292" y="27" width="0.469128" height="1" fill="#445B5B" />
        <rect x="49.7109" y="27" width="0.469124" height="1" fill="#445B5B" />
        <rect x="50.4932" y="27" width="0.469124" height="1" fill="#445B5B" />
        <rect x="51.2749" y="27" width="0.469128" height="1" fill="#445B5B" />
        <rect
          x="45.8018"
          y="28.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="46.5835"
          y="28.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="47.3657"
          y="28.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.1475"
          y="28.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.9292"
          y="28.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="49.7109"
          y="28.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="50.4932"
          y="28.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="51.2749"
          y="28.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="45.8018"
          y="30.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="46.5835"
          y="30.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="47.3657"
          y="30.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.1475"
          y="30.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.9292"
          y="30.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="49.7109"
          y="30.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="50.4932"
          y="30.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="51.2749"
          y="30.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect x="45.8018" y="32" width="0.469128" height="1" fill="#445B5B" />
        <rect x="46.5835" y="32" width="0.469124" height="1" fill="#445B5B" />
        <rect x="47.3657" y="32" width="0.469124" height="1" fill="#445B5B" />
        <rect x="48.1475" y="32" width="0.469128" height="1" fill="#445B5B" />
        <rect x="48.9292" y="32" width="0.469128" height="1" fill="#445B5B" />
        <rect x="49.7109" y="32" width="0.469124" height="1" fill="#445B5B" />
        <rect x="50.4932" y="32" width="0.469124" height="1" fill="#445B5B" />
        <rect x="51.2749" y="32" width="0.469128" height="1" fill="#445B5B" />
        <rect
          x="45.8018"
          y="33.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="46.5835"
          y="33.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="47.3657"
          y="33.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.1475"
          y="33.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.9292"
          y="33.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="49.7109"
          y="33.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="50.4932"
          y="33.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="51.2749"
          y="33.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="45.8018"
          y="35.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="46.5835"
          y="35.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="47.3657"
          y="35.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.1475"
          y="35.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.9292"
          y="35.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="49.7109"
          y="35.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="50.4932"
          y="35.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="51.2749"
          y="35.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect x="45.8018" y="37" width="0.469128" height="1" fill="#445B5B" />
        <rect x="46.5835" y="37" width="0.469124" height="1" fill="#445B5B" />
        <rect x="47.3657" y="37" width="0.469124" height="1" fill="#445B5B" />
        <rect x="48.1475" y="37" width="0.469128" height="1" fill="#445B5B" />
        <rect x="48.9292" y="37" width="0.469128" height="1" fill="#445B5B" />
        <rect x="49.7109" y="37" width="0.469124" height="1" fill="#445B5B" />
        <rect x="50.4932" y="37" width="0.469124" height="1" fill="#445B5B" />
        <rect x="51.2749" y="37" width="0.469128" height="1" fill="#445B5B" />
        <rect
          x="54.2461"
          y="15.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.0278"
          y="15.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.8101"
          y="15.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="56.5918"
          y="15.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="57.3735"
          y="15.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.1553"
          y="15.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.9375"
          y="15.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="59.7192"
          y="15.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect x="54.2461" y="17" width="0.469128" height="1" fill="#445B5B" />
        <rect x="55.0278" y="17" width="0.469128" height="1" fill="#445B5B" />
        <rect x="55.8101" y="17" width="0.469124" height="1" fill="#445B5B" />
        <rect x="56.5918" y="17" width="0.469124" height="1" fill="#445B5B" />
        <rect x="57.3735" y="17" width="0.469128" height="1" fill="#445B5B" />
        <rect x="58.1553" y="17" width="0.469131" height="1" fill="#445B5B" />
        <rect x="58.9375" y="17" width="0.469124" height="1" fill="#445B5B" />
        <rect x="59.7192" y="17" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="54.2461"
          y="18.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.0278"
          y="18.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.8101"
          y="18.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="56.5918"
          y="18.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="57.3735"
          y="18.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.1553"
          y="18.666"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.9375"
          y="18.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="59.7192"
          y="18.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="54.2461"
          y="20.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.0278"
          y="20.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.8101"
          y="20.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="56.5918"
          y="20.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="57.3735"
          y="20.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.1553"
          y="20.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.9375"
          y="20.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="59.7192"
          y="20.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect x="54.2461" y="22" width="0.469128" height="1" fill="#445B5B" />
        <rect x="55.0278" y="22" width="0.469128" height="1" fill="#445B5B" />
        <rect x="55.8101" y="22" width="0.469124" height="1" fill="#445B5B" />
        <rect x="56.5918" y="22" width="0.469124" height="1" fill="#445B5B" />
        <rect x="57.3735" y="22" width="0.469128" height="1" fill="#445B5B" />
        <rect x="58.1553" y="22" width="0.469131" height="1" fill="#445B5B" />
        <rect x="58.9375" y="22" width="0.469124" height="1" fill="#445B5B" />
        <rect x="59.7192" y="22" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="54.2461"
          y="23.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.0278"
          y="23.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.8101"
          y="23.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="56.5918"
          y="23.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="57.3735"
          y="23.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.1553"
          y="23.666"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.9375"
          y="23.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="59.7192"
          y="23.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="54.2461"
          y="25.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.0278"
          y="25.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.8101"
          y="25.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="56.5918"
          y="25.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="57.3735"
          y="25.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.1553"
          y="25.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.9375"
          y="25.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="59.7192"
          y="25.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect x="54.2461" y="27" width="0.469128" height="1" fill="#445B5B" />
        <rect x="55.0278" y="27" width="0.469128" height="1" fill="#445B5B" />
        <rect x="55.8101" y="27" width="0.469124" height="1" fill="#445B5B" />
        <rect x="56.5918" y="27" width="0.469124" height="1" fill="#445B5B" />
        <rect x="57.3735" y="27" width="0.469128" height="1" fill="#445B5B" />
        <rect x="58.1553" y="27" width="0.469131" height="1" fill="#445B5B" />
        <rect x="58.9375" y="27" width="0.469124" height="1" fill="#445B5B" />
        <rect x="59.7192" y="27" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="54.2461"
          y="28.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.0278"
          y="28.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.8101"
          y="28.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="56.5918"
          y="28.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="57.3735"
          y="28.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.1553"
          y="28.666"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.9375"
          y="28.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="59.7192"
          y="28.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="54.2461"
          y="30.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.0278"
          y="30.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.8101"
          y="30.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="56.5918"
          y="30.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="57.3735"
          y="30.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.1553"
          y="30.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.9375"
          y="30.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="59.7192"
          y="30.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect x="54.2461" y="32" width="0.469128" height="1" fill="#445B5B" />
        <rect x="55.0278" y="32" width="0.469128" height="1" fill="#445B5B" />
        <rect x="55.8101" y="32" width="0.469124" height="1" fill="#445B5B" />
        <rect x="56.5918" y="32" width="0.469124" height="1" fill="#445B5B" />
        <rect x="57.3735" y="32" width="0.469128" height="1" fill="#445B5B" />
        <rect x="58.1553" y="32" width="0.469131" height="1" fill="#445B5B" />
        <rect x="58.9375" y="32" width="0.469124" height="1" fill="#445B5B" />
        <rect x="59.7192" y="32" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="54.2461"
          y="33.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.0278"
          y="33.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.8101"
          y="33.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="56.5918"
          y="33.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="57.3735"
          y="33.666"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.1553"
          y="33.666"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.9375"
          y="33.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="59.7192"
          y="33.666"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="54.2461"
          y="35.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.0278"
          y="35.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.8101"
          y="35.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="56.5918"
          y="35.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="57.3735"
          y="35.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.1553"
          y="35.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.9375"
          y="35.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="59.7192"
          y="35.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect x="54.2461" y="37" width="0.469128" height="1" fill="#445B5B" />
        <rect x="55.0278" y="37" width="0.469128" height="1" fill="#445B5B" />
        <rect x="55.8101" y="37" width="0.469124" height="1" fill="#445B5B" />
        <rect x="56.5918" y="37" width="0.469124" height="1" fill="#445B5B" />
        <rect x="57.3735" y="37" width="0.469128" height="1" fill="#445B5B" />
        <rect x="58.1553" y="37" width="0.469131" height="1" fill="#445B5B" />
        <rect x="58.9375" y="37" width="0.469124" height="1" fill="#445B5B" />
        <rect x="59.7192" y="37" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="45.8018"
          y="42"
          width="0.469128"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="46.5835"
          y="42"
          width="0.469124"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="47.3657"
          y="42"
          width="0.469124"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="48.1475"
          y="42"
          width="0.469128"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="48.9292"
          y="42"
          width="0.469128"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="49.7109"
          y="42"
          width="0.469124"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="50.4932"
          y="42"
          width="0.469124"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="51.2749"
          y="42"
          width="0.469128"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="45.8018"
          y="43.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="46.5835"
          y="43.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="47.3657"
          y="43.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.1475"
          y="43.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.9292"
          y="43.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="49.7109"
          y="43.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="50.4932"
          y="43.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="51.2749"
          y="43.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="45.8018"
          y="45.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="46.5835"
          y="45.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="47.3657"
          y="45.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.1475"
          y="45.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.9292"
          y="45.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="49.7109"
          y="45.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="50.4932"
          y="45.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="51.2749"
          y="45.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect x="45.8018" y="47" width="0.469128" height="1" fill="#445B5B" />
        <rect x="46.5835" y="47" width="0.469124" height="1" fill="#445B5B" />
        <rect x="47.3657" y="47" width="0.469124" height="1" fill="#445B5B" />
        <rect x="48.1475" y="47" width="0.469128" height="1" fill="#445B5B" />
        <rect x="48.9292" y="47" width="0.469128" height="1" fill="#445B5B" />
        <rect x="49.7109" y="47" width="0.469124" height="1" fill="#445B5B" />
        <rect x="50.4932" y="47" width="0.469124" height="1" fill="#445B5B" />
        <rect x="51.2749" y="47" width="0.469128" height="1" fill="#445B5B" />
        <rect
          x="45.8018"
          y="48.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="46.5835"
          y="48.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="47.3657"
          y="48.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.1475"
          y="48.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.9292"
          y="48.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="49.7109"
          y="48.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="50.4932"
          y="48.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="51.2749"
          y="48.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="45.8018"
          y="50.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="46.5835"
          y="50.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="47.3657"
          y="50.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.1475"
          y="50.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.9292"
          y="50.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="49.7109"
          y="50.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="50.4932"
          y="50.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="51.2749"
          y="50.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect x="45.8018" y="52" width="0.469128" height="1" fill="#445B5B" />
        <rect x="46.5835" y="52" width="0.469124" height="1" fill="#445B5B" />
        <rect x="47.3657" y="52" width="0.469124" height="1" fill="#445B5B" />
        <rect x="48.1475" y="52" width="0.469128" height="1" fill="#445B5B" />
        <rect x="48.9292" y="52" width="0.469128" height="1" fill="#445B5B" />
        <rect x="49.7109" y="52" width="0.469124" height="1" fill="#445B5B" />
        <rect x="50.4932" y="52" width="0.469124" height="1" fill="#445B5B" />
        <rect x="51.2749" y="52" width="0.469128" height="1" fill="#445B5B" />
        <rect
          x="45.8018"
          y="53.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="46.5835"
          y="53.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="47.3657"
          y="53.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.1475"
          y="53.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.9292"
          y="53.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="49.7109"
          y="53.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="50.4932"
          y="53.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="51.2749"
          y="53.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="45.8018"
          y="55.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="46.5835"
          y="55.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="47.3657"
          y="55.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.1475"
          y="55.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.9292"
          y="55.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="49.7109"
          y="55.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="50.4932"
          y="55.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="51.2749"
          y="55.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect x="45.8018" y="57" width="0.469128" height="1" fill="#445B5B" />
        <rect x="46.5835" y="57" width="0.469124" height="1" fill="#445B5B" />
        <rect x="47.3657" y="57" width="0.469124" height="1" fill="#445B5B" />
        <rect x="48.1475" y="57" width="0.469128" height="1" fill="#445B5B" />
        <rect x="48.9292" y="57" width="0.469128" height="1" fill="#445B5B" />
        <rect x="49.7109" y="57" width="0.469124" height="1" fill="#445B5B" />
        <rect x="50.4932" y="57" width="0.469124" height="1" fill="#445B5B" />
        <rect x="51.2749" y="57" width="0.469128" height="1" fill="#445B5B" />
        <rect
          x="45.8018"
          y="58.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="46.5835"
          y="58.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="47.3657"
          y="58.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.1475"
          y="58.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.9292"
          y="58.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="49.7109"
          y="58.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="50.4932"
          y="58.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="51.2749"
          y="58.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="45.8018"
          y="60.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="46.5835"
          y="60.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="47.3657"
          y="60.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.1475"
          y="60.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="48.9292"
          y="60.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="49.7109"
          y="60.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="50.4932"
          y="60.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="51.2749"
          y="60.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect x="45.8018" y="62" width="0.469128" height="1" fill="#445B5B" />
        <rect x="46.5835" y="62" width="0.469124" height="1" fill="#445B5B" />
        <rect x="47.3657" y="62" width="0.469124" height="1" fill="#445B5B" />
        <rect x="48.1475" y="62" width="0.469128" height="1" fill="#445B5B" />
        <rect x="48.9292" y="62" width="0.469128" height="1" fill="#445B5B" />
        <rect x="49.7109" y="62" width="0.469124" height="1" fill="#445B5B" />
        <rect x="50.4932" y="62" width="0.469124" height="1" fill="#445B5B" />
        <rect x="51.2749" y="62" width="0.469128" height="1" fill="#445B5B" />
        <rect
          x="45.8018"
          y="63.667"
          width="0.469128"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="46.5835"
          y="63.667"
          width="0.469124"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="47.3657"
          y="63.667"
          width="0.469124"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="48.1475"
          y="63.667"
          width="0.469128"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="48.9292"
          y="63.667"
          width="0.469128"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="49.7109"
          y="63.667"
          width="0.469124"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="50.4932"
          y="63.667"
          width="0.469124"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="51.2749"
          y="63.667"
          width="0.469128"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="54.2461"
          y="42"
          width="0.469128"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="55.0278"
          y="42"
          width="0.469128"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="55.8101"
          y="42"
          width="0.469124"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="56.5918"
          y="42"
          width="0.469124"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="57.3735"
          y="42"
          width="0.469128"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="58.1553"
          y="42"
          width="0.469131"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="58.9375"
          y="42"
          width="0.469124"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="59.7192"
          y="42"
          width="0.469124"
          height="0.999992"
          fill="#445B5B"
        />
        <rect
          x="54.2461"
          y="43.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.0278"
          y="43.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.8101"
          y="43.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="56.5918"
          y="43.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="57.3735"
          y="43.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.1553"
          y="43.667"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.9375"
          y="43.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="59.7192"
          y="43.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="54.2461"
          y="45.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.0278"
          y="45.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.8101"
          y="45.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="56.5918"
          y="45.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="57.3735"
          y="45.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.1553"
          y="45.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.9375"
          y="45.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="59.7192"
          y="45.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect x="54.2461" y="47" width="0.469128" height="1" fill="#445B5B" />
        <rect x="55.0278" y="47" width="0.469128" height="1" fill="#445B5B" />
        <rect x="55.8101" y="47" width="0.469124" height="1" fill="#445B5B" />
        <rect x="56.5918" y="47" width="0.469124" height="1" fill="#445B5B" />
        <rect x="57.3735" y="47" width="0.469128" height="1" fill="#445B5B" />
        <rect x="58.1553" y="47" width="0.469131" height="1" fill="#445B5B" />
        <rect x="58.9375" y="47" width="0.469124" height="1" fill="#445B5B" />
        <rect x="59.7192" y="47" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="54.2461"
          y="48.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.0278"
          y="48.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.8101"
          y="48.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="56.5918"
          y="48.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="57.3735"
          y="48.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.1553"
          y="48.667"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.9375"
          y="48.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="59.7192"
          y="48.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="54.2461"
          y="50.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.0278"
          y="50.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.8101"
          y="50.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="56.5918"
          y="50.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="57.3735"
          y="50.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.1553"
          y="50.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.9375"
          y="50.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="59.7192"
          y="50.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect x="54.2461" y="52" width="0.469128" height="1" fill="#445B5B" />
        <rect x="55.0278" y="52" width="0.469128" height="1" fill="#445B5B" />
        <rect x="55.8101" y="52" width="0.469124" height="1" fill="#445B5B" />
        <rect x="56.5918" y="52" width="0.469124" height="1" fill="#445B5B" />
        <rect x="57.3735" y="52" width="0.469128" height="1" fill="#445B5B" />
        <rect x="58.1553" y="52" width="0.469131" height="1" fill="#445B5B" />
        <rect x="58.9375" y="52" width="0.469124" height="1" fill="#445B5B" />
        <rect x="59.7192" y="52" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="54.2461"
          y="53.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.0278"
          y="53.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.8101"
          y="53.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="56.5918"
          y="53.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="57.3735"
          y="53.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.1553"
          y="53.667"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.9375"
          y="53.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="59.7192"
          y="53.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="54.2461"
          y="55.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.0278"
          y="55.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.8101"
          y="55.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="56.5918"
          y="55.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="57.3735"
          y="55.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.1553"
          y="55.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.9375"
          y="55.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="59.7192"
          y="55.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect x="54.2461" y="57" width="0.469128" height="1" fill="#445B5B" />
        <rect x="55.0278" y="57" width="0.469128" height="1" fill="#445B5B" />
        <rect x="55.8101" y="57" width="0.469124" height="1" fill="#445B5B" />
        <rect x="56.5918" y="57" width="0.469124" height="1" fill="#445B5B" />
        <rect x="57.3735" y="57" width="0.469128" height="1" fill="#445B5B" />
        <rect x="58.1553" y="57" width="0.469131" height="1" fill="#445B5B" />
        <rect x="58.9375" y="57" width="0.469124" height="1" fill="#445B5B" />
        <rect x="59.7192" y="57" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="54.2461"
          y="58.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.0278"
          y="58.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.8101"
          y="58.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="56.5918"
          y="58.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="57.3735"
          y="58.667"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.1553"
          y="58.667"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.9375"
          y="58.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="59.7192"
          y="58.667"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="54.2461"
          y="60.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.0278"
          y="60.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="55.8101"
          y="60.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="56.5918"
          y="60.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="57.3735"
          y="60.333"
          width="0.469128"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.1553"
          y="60.333"
          width="0.469131"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="58.9375"
          y="60.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect
          x="59.7192"
          y="60.333"
          width="0.469124"
          height="1"
          fill="#445B5B"
        />
        <rect x="54.2461" y="62" width="0.469128" height="1" fill="#445B5B" />
        <rect x="55.0278" y="62" width="0.469128" height="1" fill="#445B5B" />
        <rect x="55.8101" y="62" width="0.469124" height="1" fill="#445B5B" />
        <rect x="56.5918" y="62" width="0.469124" height="1" fill="#445B5B" />
        <rect x="57.3735" y="62" width="0.469128" height="1" fill="#445B5B" />
        <rect x="58.1553" y="62" width="0.469131" height="1" fill="#445B5B" />
        <rect x="58.9375" y="62" width="0.469124" height="1" fill="#445B5B" />
        <rect x="59.7192" y="62" width="0.469124" height="1" fill="#445B5B" />
        <rect
          x="54.2461"
          y="63.667"
          width="0.469128"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="55.0278"
          y="63.667"
          width="0.469128"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="55.8101"
          y="63.667"
          width="0.469124"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="56.5918"
          y="63.667"
          width="0.469124"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="57.3735"
          y="63.667"
          width="0.469128"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="58.1553"
          y="63.667"
          width="0.469131"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="58.9375"
          y="63.667"
          width="0.469124"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="59.7192"
          y="63.667"
          width="0.469124"
          height="0.999996"
          fill="#445B5B"
        />
        <rect
          x="63.4136"
          y="35.6875"
          width="0.752556"
          height="8.625"
          rx="0.376278"
          fill="#465856"
        />
        <rect
          x="63.4136"
          y="36.8545"
          width="0.752556"
          height="1.66667"
          fill="#688380"
        />
        <rect
          x="74.3403"
          y="19.9355"
          width="4.69127"
          height="11.9167"
          rx="1"
          fill="#FFD049"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M76.2057 23.4727C75.9855 23.5897 75.8051 23.7935 75.7235 24.0843L75.2624 25.7267C75.0834 26.3645 75.5627 26.997 76.2252 26.997H77.1473C77.8097 26.997 78.2891 26.3645 78.1101 25.7267L77.649 24.0843C77.5674 23.7935 77.3869 23.5896 77.1667 23.4727L77.9051 26.0406C77.9602 26.2323 77.8163 26.4235 77.6168 26.4235H75.7556C75.5561 26.4235 75.4122 26.2324 75.4673 26.0406L76.2057 23.4727Z"
          fill="#4F5A60"
        />
        <rect
          x="76.5698"
          y="23.0156"
          width="0.306"
          height="2.06935"
          rx="0.153"
          fill="#4F5A60"
        />
        <ellipse
          cx="76.7228"
          cy="25.6806"
          rx="0.153"
          ry="0.326149"
          fill="#4F5A60"
        />
        <path
          d="M74.9575 28.1165C74.9838 28.1165 75.0075 28.1245 75.0288 28.1405C75.0504 28.1565 75.0687 28.1795 75.0837 28.2095C75.0987 28.2395 75.1103 28.2762 75.1184 28.3195C75.1265 28.3628 75.1306 28.4118 75.1306 28.4665C75.1306 28.5212 75.1265 28.5702 75.1184 28.6135C75.1103 28.6568 75.0987 28.6935 75.0837 28.7235C75.0687 28.7535 75.0504 28.7765 75.0288 28.7925C75.0075 28.8085 74.9838 28.8165 74.9575 28.8165H74.8608V28.1165H74.9575ZM74.9411 28.2645V28.6685H74.9575C74.9847 28.6685 75.0064 28.6518 75.0227 28.6185C75.039 28.5845 75.0471 28.5338 75.0471 28.4665C75.0471 28.3992 75.039 28.3488 75.0227 28.3155C75.0064 28.2815 74.9847 28.2645 74.9575 28.2645H74.9411Z"
          fill="#E54545"
        />
        <path
          d="M75.3281 28.8295C75.3022 28.8295 75.2785 28.8202 75.2573 28.8015C75.2363 28.7828 75.2183 28.7572 75.2033 28.7245C75.1883 28.6918 75.1766 28.6535 75.1681 28.6095C75.16 28.5648 75.1559 28.5168 75.1559 28.4655C75.1559 28.4148 75.16 28.3675 75.1681 28.3235C75.1766 28.2795 75.1883 28.2412 75.2033 28.2085C75.2183 28.1758 75.2363 28.1502 75.2573 28.1315C75.2785 28.1128 75.3022 28.1035 75.3281 28.1035C75.3541 28.1035 75.3775 28.1128 75.3985 28.1315C75.4198 28.1502 75.4379 28.1758 75.4529 28.2085C75.4679 28.2412 75.4795 28.2795 75.4876 28.3235C75.4961 28.3675 75.5003 28.4148 75.5003 28.4655C75.5003 28.5168 75.4961 28.5648 75.4876 28.6095C75.4795 28.6535 75.4679 28.6918 75.4529 28.7245C75.4379 28.7572 75.4198 28.7828 75.3985 28.8015C75.3775 28.8202 75.3541 28.8295 75.3281 28.8295ZM75.3281 28.2635C75.315 28.2635 75.3029 28.2688 75.292 28.2795C75.2811 28.2895 75.2717 28.3035 75.2638 28.3215C75.2563 28.3395 75.2504 28.3608 75.246 28.3855C75.2416 28.4102 75.2395 28.4368 75.2395 28.4655C75.2395 28.4948 75.2416 28.5222 75.246 28.5475C75.2504 28.5722 75.2565 28.5935 75.2643 28.6115C75.2721 28.6295 75.2814 28.6438 75.292 28.6545C75.3029 28.6645 75.315 28.6695 75.3281 28.6695C75.3413 28.6695 75.3531 28.6645 75.3638 28.6545C75.3747 28.6438 75.3841 28.6295 75.3919 28.6115C75.3997 28.5935 75.4058 28.5722 75.4102 28.5475C75.4146 28.5222 75.4168 28.4948 75.4168 28.4655C75.4168 28.4368 75.4146 28.4102 75.4102 28.3855C75.4058 28.3608 75.3997 28.3395 75.3919 28.3215C75.3844 28.3035 75.3752 28.2895 75.3642 28.2795C75.3533 28.2688 75.3413 28.2635 75.3281 28.2635Z"
          fill="#E54545"
        />
        <path
          d="M75.62 28.3875V28.8165H75.5411V28.1165H75.6228L75.7429 28.5505V28.1165H75.8217V28.8165H75.7419L75.62 28.3875Z"
          fill="#E54545"
        />
        <path
          d="M76.0346 28.8295C76.0086 28.8295 75.985 28.8202 75.9637 28.8015C75.9428 28.7828 75.9248 28.7572 75.9098 28.7245C75.8948 28.6918 75.883 28.6535 75.8746 28.6095C75.8665 28.5648 75.8624 28.5168 75.8624 28.4655C75.8624 28.4148 75.8665 28.3675 75.8746 28.3235C75.883 28.2795 75.8948 28.2412 75.9098 28.2085C75.9248 28.1758 75.9428 28.1502 75.9637 28.1315C75.985 28.1128 76.0086 28.1035 76.0346 28.1035C76.0605 28.1035 76.084 28.1128 76.1049 28.1315C76.1262 28.1502 76.1443 28.1758 76.1593 28.2085C76.1744 28.2412 76.1859 28.2795 76.1941 28.3235C76.2025 28.3675 76.2067 28.4148 76.2067 28.4655C76.2067 28.5168 76.2025 28.5648 76.1941 28.6095C76.1859 28.6535 76.1744 28.6918 76.1593 28.7245C76.1443 28.7572 76.1262 28.7828 76.1049 28.8015C76.084 28.8202 76.0605 28.8295 76.0346 28.8295ZM76.0346 28.2635C76.0214 28.2635 76.0094 28.2688 75.9984 28.2795C75.9875 28.2895 75.9781 28.3035 75.9703 28.3215C75.9628 28.3395 75.9568 28.3608 75.9525 28.3855C75.9481 28.4102 75.9459 28.4368 75.9459 28.4655C75.9459 28.4948 75.9481 28.5222 75.9525 28.5475C75.9568 28.5722 75.9629 28.5935 75.9708 28.6115C75.9786 28.6295 75.9878 28.6438 75.9984 28.6545C76.0094 28.6645 76.0214 28.6695 76.0346 28.6695C76.0477 28.6695 76.0596 28.6645 76.0702 28.6545C76.0812 28.6438 76.0905 28.6295 76.0984 28.6115C76.1062 28.5935 76.1123 28.5722 76.1167 28.5475C76.121 28.5222 76.1232 28.4948 76.1232 28.4655C76.1232 28.4368 76.121 28.4102 76.1167 28.3855C76.1123 28.3608 76.1062 28.3395 76.0984 28.3215C76.0909 28.3035 76.0816 28.2895 76.0707 28.2795C76.0597 28.2688 76.0477 28.2635 76.0346 28.2635Z"
          fill="#E54545"
        />
        <path
          d="M76.3652 28.2695V28.8165H76.285V28.2695H76.2141V28.1165H76.436V28.2695H76.3652Z"
          fill="#E54545"
        />
        <path
          d="M76.7021 28.8285C76.6798 28.8285 76.6603 28.8218 76.6434 28.8085C76.6265 28.7945 76.6121 28.7752 76.6003 28.7505C76.5887 28.7258 76.5799 28.6965 76.574 28.6625C76.568 28.6278 76.5651 28.5895 76.5651 28.5475V28.1165H76.6453V28.5475C76.6453 28.5855 76.6503 28.6155 76.6603 28.6375C76.6706 28.6595 76.6847 28.6705 76.7025 28.6705C76.7207 28.6705 76.7346 28.6595 76.7443 28.6375C76.754 28.6155 76.7588 28.5852 76.7588 28.5465V28.1165H76.839V28.5465C76.839 28.5878 76.8359 28.6258 76.8297 28.6605C76.8237 28.6945 76.8148 28.7242 76.8029 28.7495C76.7913 28.7742 76.777 28.7935 76.7598 28.8075C76.7429 28.8215 76.7236 28.8285 76.7021 28.8285Z"
          fill="#E54545"
        />
        <path
          d="M76.9925 28.1035C77.01 28.1035 77.025 28.1095 77.0375 28.1215C77.05 28.1335 77.0604 28.1482 77.0685 28.1655C77.0769 28.1822 77.0835 28.1998 77.0882 28.2185C77.0929 28.2365 77.0963 28.2522 77.0985 28.2655L77.0319 28.3305C77.0303 28.3225 77.0285 28.3145 77.0263 28.3065C77.0241 28.2985 77.0213 28.2912 77.0178 28.2845C77.0147 28.2778 77.0109 28.2725 77.0066 28.2685C77.0022 28.2638 76.9969 28.2615 76.9906 28.2615C76.9828 28.2615 76.9765 28.2662 76.9718 28.2755C76.9672 28.2842 76.9648 28.2952 76.9648 28.3085C76.9648 28.3245 76.9681 28.3382 76.9747 28.3495C76.9815 28.3602 76.99 28.3708 77 28.3815C77.0103 28.3915 77.0214 28.4022 77.0333 28.4135C77.0452 28.4248 77.0561 28.4392 77.0661 28.4565C77.0765 28.4732 77.0849 28.4938 77.0915 28.5185C77.0984 28.5425 77.1018 28.5722 77.1018 28.6075C77.1018 28.6395 77.0991 28.6692 77.0938 28.6965C77.0885 28.7238 77.0808 28.7475 77.0708 28.7675C77.0611 28.7868 77.0491 28.8022 77.0347 28.8135C77.0206 28.8242 77.0047 28.8295 76.9869 28.8295C76.9684 28.8295 76.9526 28.8235 76.9395 28.8115C76.9263 28.7988 76.9154 28.7838 76.9066 28.7665C76.8979 28.7485 76.891 28.7302 76.886 28.7115C76.881 28.6922 76.8774 28.6758 76.8752 28.6625L76.9423 28.5915C76.9467 28.6122 76.9531 28.6305 76.9615 28.6465C76.9703 28.6618 76.9803 28.6695 76.9916 28.6695C77.0109 28.6695 77.0206 28.6532 77.0206 28.6205C77.0206 28.6052 77.0172 28.5922 77.0103 28.5815C77.0037 28.5702 76.9955 28.5595 76.9855 28.5495C76.9754 28.5388 76.9645 28.5275 76.9526 28.5155C76.9407 28.5035 76.9298 28.4885 76.9198 28.4705C76.9098 28.4525 76.9013 28.4308 76.8944 28.4055C76.8879 28.3802 76.8846 28.3488 76.8846 28.3115C76.8846 28.2802 76.8871 28.2518 76.8921 28.2265C76.8974 28.2005 76.9048 28.1785 76.9141 28.1605C76.9235 28.1425 76.9348 28.1285 76.9479 28.1185C76.9614 28.1085 76.9762 28.1035 76.9925 28.1035Z"
          fill="#E54545"
        />
        <path
          d="M77.1446 28.8165V28.1165H77.3196V28.2695H77.2248V28.3865H77.3055V28.5385H77.2248V28.6635H77.3234V28.8165H77.1446Z"
          fill="#E54545"
        />
        <path
          d="M77.5446 28.5495V28.8165H77.4644V28.1165H77.638V28.2695H77.5446V28.3975H77.6263V28.5495H77.5446Z"
          fill="#E54545"
        />
        <path
          d="M77.8323 28.8295C77.8063 28.8295 77.7827 28.8202 77.7614 28.8015C77.7405 28.7828 77.7225 28.7572 77.7075 28.7245C77.6925 28.6918 77.6807 28.6535 77.6723 28.6095C77.6642 28.5648 77.6601 28.5168 77.6601 28.4655C77.6601 28.4148 77.6642 28.3675 77.6723 28.3235C77.6807 28.2795 77.6925 28.2412 77.7075 28.2085C77.7225 28.1758 77.7405 28.1502 77.7614 28.1315C77.7827 28.1128 77.8063 28.1035 77.8323 28.1035C77.8582 28.1035 77.8817 28.1128 77.9026 28.1315C77.9239 28.1502 77.942 28.1758 77.9571 28.2085C77.9721 28.2412 77.9836 28.2795 77.9918 28.3235C78.0002 28.3675 78.0044 28.4148 78.0044 28.4655C78.0044 28.5168 78.0002 28.5648 77.9918 28.6095C77.9836 28.6535 77.9721 28.6918 77.9571 28.7245C77.942 28.7572 77.9239 28.7828 77.9026 28.8015C77.8817 28.8202 77.8582 28.8295 77.8323 28.8295ZM77.8323 28.2635C77.8191 28.2635 77.8071 28.2688 77.7961 28.2795C77.7852 28.2895 77.7758 28.3035 77.768 28.3215C77.7605 28.3395 77.7545 28.3608 77.7502 28.3855C77.7458 28.4102 77.7436 28.4368 77.7436 28.4655C77.7436 28.4948 77.7458 28.5222 77.7502 28.5475C77.7545 28.5722 77.7606 28.5935 77.7685 28.6115C77.7763 28.6295 77.7855 28.6438 77.7961 28.6545C77.8071 28.6645 77.8191 28.6695 77.8323 28.6695C77.8454 28.6695 77.8573 28.6645 77.8679 28.6545C77.8789 28.6438 77.8882 28.6295 77.8961 28.6115C77.9039 28.5935 77.91 28.5722 77.9144 28.5475C77.9187 28.5222 77.9209 28.4948 77.9209 28.4655C77.9209 28.4368 77.9187 28.4102 77.9144 28.3855C77.91 28.3608 77.9039 28.3395 77.8961 28.3215C77.8886 28.3035 77.8793 28.2895 77.8684 28.2795C77.8574 28.2688 77.8454 28.2635 77.8323 28.2635Z"
          fill="#E54545"
        />
        <path
          d="M78.2034 28.8295C78.1768 28.8295 78.1527 28.8202 78.1311 28.8015C78.1099 28.7828 78.0917 28.7572 78.0767 28.7245C78.0617 28.6918 78.0501 28.6535 78.042 28.6095C78.0339 28.5648 78.0298 28.5168 78.0298 28.4655C78.0298 28.4142 78.034 28.3665 78.0425 28.3225C78.0509 28.2785 78.0628 28.2402 78.0781 28.2075C78.0935 28.1748 78.1118 28.1495 78.133 28.1315C78.1543 28.1128 78.1777 28.1035 78.2034 28.1035C78.224 28.1035 78.2422 28.1092 78.2578 28.1205C78.2734 28.1312 78.2872 28.1452 78.2991 28.1625L78.2766 28.3125C78.2678 28.2998 78.2573 28.2885 78.2451 28.2785C78.2333 28.2685 78.2203 28.2635 78.2062 28.2635C78.1912 28.2635 78.1779 28.2688 78.1663 28.2795C78.1548 28.2902 78.1451 28.3048 78.1372 28.3235C78.1294 28.3422 78.1235 28.3638 78.1194 28.3885C78.1153 28.4125 78.1133 28.4382 78.1133 28.4655C78.1133 28.4928 78.1153 28.5188 78.1194 28.5435C78.1235 28.5682 78.1294 28.5898 78.1372 28.6085C78.1451 28.6272 78.1548 28.6422 78.1663 28.6535C78.1779 28.6642 78.1912 28.6695 78.2062 28.6695C78.2203 28.6695 78.2336 28.6645 78.2461 28.6545C78.2586 28.6445 78.2692 28.6332 78.278 28.6205L78.3005 28.7705C78.2886 28.7878 78.2745 28.8022 78.2583 28.8135C78.2423 28.8242 78.224 28.8295 78.2034 28.8295Z"
          fill="#E54545"
        />
        <path
          d="M78.3417 28.8165V28.1165H78.5167V28.2695H78.4219V28.3865H78.5026V28.5385H78.4219V28.6635H78.5205V28.8165H78.3417Z"
          fill="#E54545"
        />
        <path
          d="M75.1908 29.6028V30.1498H75.1106V29.6028H75.0397V29.4498H75.2616V29.6028H75.1908Z"
          fill="#E54545"
        />
        <path
          d="M75.441 30.1628C75.4151 30.1628 75.3915 30.1535 75.3702 30.1348C75.3493 30.1162 75.3313 30.0905 75.3163 30.0578C75.3012 30.0252 75.2895 29.9868 75.2811 29.9428C75.2729 29.8982 75.2689 29.8502 75.2689 29.7988C75.2689 29.7482 75.2729 29.7008 75.2811 29.6568C75.2895 29.6128 75.3012 29.5745 75.3163 29.5418C75.3313 29.5092 75.3493 29.4835 75.3702 29.4648C75.3915 29.4462 75.4151 29.4368 75.441 29.4368C75.467 29.4368 75.4905 29.4462 75.5114 29.4648C75.5327 29.4835 75.5508 29.5092 75.5658 29.5418C75.5808 29.5745 75.5924 29.6128 75.6006 29.6568C75.609 29.7008 75.6132 29.7482 75.6132 29.7988C75.6132 29.8502 75.609 29.8982 75.6006 29.9428C75.5924 29.9868 75.5808 30.0252 75.5658 30.0578C75.5508 30.0905 75.5327 30.1162 75.5114 30.1348C75.4905 30.1535 75.467 30.1628 75.441 30.1628ZM75.441 29.5968C75.4279 29.5968 75.4159 29.6022 75.4049 29.6128C75.394 29.6228 75.3846 29.6368 75.3768 29.6548C75.3693 29.6728 75.3633 29.6942 75.359 29.7188C75.3546 29.7435 75.3524 29.7702 75.3524 29.7988C75.3524 29.8282 75.3546 29.8555 75.359 29.8808C75.3633 29.9055 75.3694 29.9268 75.3772 29.9448C75.3851 29.9628 75.3943 29.9772 75.4049 29.9878C75.4159 29.9978 75.4279 30.0028 75.441 30.0028C75.4542 30.0028 75.4661 29.9978 75.4767 29.9878C75.4876 29.9772 75.497 29.9628 75.5049 29.9448C75.5127 29.9268 75.5188 29.9055 75.5231 29.8808C75.5275 29.8555 75.5297 29.8282 75.5297 29.7988C75.5297 29.7702 75.5275 29.7435 75.5231 29.7188C75.5188 29.6942 75.5127 29.6728 75.5049 29.6548C75.4973 29.6368 75.4881 29.6228 75.4772 29.6128C75.4662 29.6022 75.4542 29.5968 75.441 29.5968Z"
          fill="#E54545"
        />
        <path
          d="M75.9028 30.1628C75.8769 30.1628 75.8533 30.1535 75.832 30.1348C75.8111 30.1162 75.7931 30.0905 75.7781 30.0578C75.763 30.0252 75.7513 29.9868 75.7429 29.9428C75.7347 29.8982 75.7307 29.8502 75.7307 29.7988C75.7307 29.7482 75.7347 29.7008 75.7429 29.6568C75.7513 29.6128 75.763 29.5745 75.7781 29.5418C75.7931 29.5092 75.8111 29.4835 75.832 29.4648C75.8533 29.4462 75.8769 29.4368 75.9028 29.4368C75.9288 29.4368 75.9523 29.4462 75.9732 29.4648C75.9945 29.4835 76.0126 29.5092 76.0276 29.5418C76.0426 29.5745 76.0542 29.6128 76.0623 29.6568C76.0708 29.7008 76.075 29.7482 76.075 29.7988C76.075 29.8502 76.0708 29.8982 76.0623 29.9428C76.0542 29.9868 76.0426 30.0252 76.0276 30.0578C76.0126 30.0905 75.9945 30.1162 75.9732 30.1348C75.9523 30.1535 75.9288 30.1628 75.9028 30.1628ZM75.9028 29.5968C75.8897 29.5968 75.8777 29.6022 75.8667 29.6128C75.8558 29.6228 75.8464 29.6368 75.8386 29.6548C75.8311 29.6728 75.8251 29.6942 75.8207 29.7188C75.8164 29.7435 75.8142 29.7702 75.8142 29.7988C75.8142 29.8282 75.8164 29.8555 75.8207 29.8808C75.8251 29.9055 75.8312 29.9268 75.839 29.9448C75.8469 29.9628 75.8561 29.9772 75.8667 29.9878C75.8777 29.9978 75.8897 30.0028 75.9028 30.0028C75.916 30.0028 75.9279 29.9978 75.9385 29.9878C75.9494 29.9772 75.9588 29.9628 75.9666 29.9448C75.9745 29.9268 75.9806 29.9055 75.9849 29.8808C75.9893 29.8555 75.9915 29.8282 75.9915 29.7988C75.9915 29.7702 75.9893 29.7435 75.9849 29.7188C75.9806 29.6942 75.9745 29.6728 75.9666 29.6548C75.9591 29.6368 75.9499 29.6228 75.939 29.6128C75.928 29.6022 75.916 29.5968 75.9028 29.5968Z"
          fill="#E54545"
        />
        <path
          d="M76.3411 29.6758C76.3411 29.7098 76.3385 29.7408 76.3335 29.7688C76.3285 29.7968 76.3213 29.8212 76.312 29.8418C76.3029 29.8618 76.2918 29.8775 76.2787 29.8888C76.2658 29.8995 76.2514 29.9048 76.2355 29.9048H76.197V30.1498H76.1168V29.4498H76.235C76.2685 29.4498 76.2945 29.4702 76.3129 29.5108C76.3317 29.5515 76.3411 29.6065 76.3411 29.6758ZM76.197 29.5968V29.7578H76.2233C76.2352 29.7578 76.2441 29.7502 76.25 29.7348C76.256 29.7195 76.259 29.6998 76.259 29.6758C76.259 29.6525 76.2558 29.6335 76.2496 29.6188C76.2436 29.6042 76.2352 29.5968 76.2242 29.5968H76.197Z"
          fill="#E54545"
        />
        <path
          d="M76.3789 30.1498V29.4498H76.5538V29.6028H76.4591V29.7198H76.5398V29.8718H76.4591V29.9968H76.5576V30.1498H76.3789Z"
          fill="#E54545"
        />
        <path
          d="M76.6844 29.7208V30.1498H76.6056V29.4498H76.6872L76.8073 29.8838V29.4498H76.8862V30.1498H76.8064L76.6844 29.7208Z"
          fill="#E54545"
        />
        <path
          d="M77.132 29.4498C77.1583 29.4498 77.182 29.4578 77.2033 29.4738C77.2249 29.4898 77.2432 29.5128 77.2582 29.5428C77.2732 29.5728 77.2848 29.6095 77.2929 29.6528C77.301 29.6962 77.3051 29.7452 77.3051 29.7998C77.3051 29.8545 77.301 29.9035 77.2929 29.9468C77.2848 29.9902 77.2732 30.0268 77.2582 30.0568C77.2432 30.0868 77.2249 30.1098 77.2033 30.1258C77.182 30.1418 77.1583 30.1498 77.132 30.1498H77.0354V29.4498H77.132ZM77.1156 29.5978V30.0018H77.132C77.1592 30.0018 77.1809 29.9852 77.1972 29.9518C77.2135 29.9178 77.2216 29.8672 77.2216 29.7998C77.2216 29.7325 77.2135 29.6822 77.1972 29.6488C77.1809 29.6148 77.1592 29.5978 77.132 29.5978H77.1156Z"
          fill="#E54545"
        />
        <path
          d="M77.5026 30.1628C77.4767 30.1628 77.4531 30.1535 77.4318 30.1348C77.4108 30.1162 77.3929 30.0905 77.3779 30.0578C77.3628 30.0252 77.3511 29.9868 77.3427 29.9428C77.3345 29.8982 77.3305 29.8502 77.3305 29.7988C77.3305 29.7482 77.3345 29.7008 77.3427 29.6568C77.3511 29.6128 77.3628 29.5745 77.3779 29.5418C77.3929 29.5092 77.4108 29.4835 77.4318 29.4648C77.4531 29.4462 77.4767 29.4368 77.5026 29.4368C77.5286 29.4368 77.5521 29.4462 77.573 29.4648C77.5943 29.4835 77.6124 29.5092 77.6274 29.5418C77.6424 29.5745 77.654 29.6128 77.6621 29.6568C77.6706 29.7008 77.6748 29.7482 77.6748 29.7988C77.6748 29.8502 77.6706 29.8982 77.6621 29.9428C77.654 29.9868 77.6424 30.0252 77.6274 30.0578C77.6124 30.0905 77.5943 30.1162 77.573 30.1348C77.5521 30.1535 77.5286 30.1628 77.5026 30.1628ZM77.5026 29.5968C77.4895 29.5968 77.4775 29.6022 77.4665 29.6128C77.4556 29.6228 77.4462 29.6368 77.4384 29.6548C77.4309 29.6728 77.4249 29.6942 77.4205 29.7188C77.4162 29.7435 77.414 29.7702 77.414 29.7988C77.414 29.8282 77.4162 29.8555 77.4205 29.8808C77.4249 29.9055 77.431 29.9268 77.4388 29.9448C77.4467 29.9628 77.4559 29.9772 77.4665 29.9878C77.4775 29.9978 77.4895 30.0028 77.5026 30.0028C77.5158 30.0028 77.5277 29.9978 77.5383 29.9878C77.5492 29.9772 77.5586 29.9628 77.5664 29.9448C77.5743 29.9268 77.5804 29.9055 77.5847 29.8808C77.5891 29.8555 77.5913 29.8282 77.5913 29.7988C77.5913 29.7702 77.5891 29.7435 77.5847 29.7188C77.5804 29.6942 77.5743 29.6728 77.5664 29.6548C77.5589 29.6368 77.5497 29.6228 77.5388 29.6128C77.5278 29.6022 77.5158 29.5968 77.5026 29.5968Z"
          fill="#E54545"
        />
        <path
          d="M77.8724 30.1628C77.8464 30.1628 77.8228 30.1535 77.8015 30.1348C77.7806 30.1162 77.7626 30.0905 77.7476 30.0578C77.7326 30.0252 77.7208 29.9868 77.7124 29.9428C77.7043 29.8982 77.7002 29.8502 77.7002 29.7988C77.7002 29.7482 77.7043 29.7008 77.7124 29.6568C77.7208 29.6128 77.7326 29.5745 77.7476 29.5418C77.7626 29.5092 77.7806 29.4835 77.8015 29.4648C77.8228 29.4462 77.8464 29.4368 77.8724 29.4368C77.8983 29.4368 77.9218 29.4462 77.9427 29.4648C77.964 29.4835 77.9821 29.5092 77.9971 29.5418C78.0122 29.5745 78.0237 29.6128 78.0319 29.6568C78.0403 29.7008 78.0445 29.7482 78.0445 29.7988C78.0445 29.8502 78.0403 29.8982 78.0319 29.9428C78.0237 29.9868 78.0122 30.0252 77.9971 30.0578C77.9821 30.0905 77.964 30.1162 77.9427 30.1348C77.9218 30.1535 77.8983 30.1628 77.8724 30.1628ZM77.8724 29.5968C77.8592 29.5968 77.8472 29.6022 77.8362 29.6128C77.8253 29.6228 77.8159 29.6368 77.8081 29.6548C77.8006 29.6728 77.7946 29.6942 77.7903 29.7188C77.7859 29.7435 77.7837 29.7702 77.7837 29.7988C77.7837 29.8282 77.7859 29.8555 77.7903 29.8808C77.7946 29.9055 77.8007 29.9268 77.8086 29.9448C77.8164 29.9628 77.8256 29.9772 77.8362 29.9878C77.8472 29.9978 77.8592 30.0028 77.8724 30.0028C77.8855 30.0028 77.8974 29.9978 77.908 29.9878C77.919 29.9772 77.9283 29.9628 77.9362 29.9448C77.944 29.9268 77.9501 29.9055 77.9545 29.8808C77.9588 29.8555 77.961 29.8282 77.961 29.7988C77.961 29.7702 77.9588 29.7435 77.9545 29.7188C77.9501 29.6942 77.944 29.6728 77.9362 29.6548C77.9286 29.6368 77.9194 29.6228 77.9085 29.6128C77.8975 29.6022 77.8855 29.5968 77.8724 29.5968Z"
          fill="#E54545"
        />
        <path
          d="M78.3082 29.6648C78.3082 29.7035 78.3043 29.7398 78.2965 29.7738C78.289 29.8072 78.2777 29.8325 78.2627 29.8498L78.2721 29.8868C78.2743 29.8962 78.2773 29.9082 78.281 29.9228C78.2848 29.9368 78.2893 29.9545 78.2946 29.9758C78.2999 29.9965 78.3062 30.0212 78.3134 30.0498C78.3206 30.0785 78.3289 30.1118 78.3382 30.1498H78.2496L78.1909 29.8908H78.1665V30.1498H78.0863V29.4498H78.1933C78.2324 29.4498 78.2613 29.4682 78.2801 29.5048C78.2988 29.5415 78.3082 29.5948 78.3082 29.6648ZM78.1665 29.5968V29.7478H78.1905C78.2014 29.7478 78.21 29.7412 78.2163 29.7278C78.2228 29.7138 78.2261 29.6948 78.2261 29.6708C78.2261 29.6475 78.2231 29.6295 78.2172 29.6168C78.2116 29.6035 78.2014 29.5968 78.1867 29.5968H78.1665Z"
          fill="#E54545"
        />
        <rect
          x="46.271"
          y="19.9355"
          width="4.69127"
          height="11.9167"
          rx="1"
          fill="#FFD049"
        />
        <path
          d="M48.1387 25.5704L48.617 23.7012L48.5373 25.0606H49.0953L48.5373 26.7599L48.617 25.5704H48.1387Z"
          fill="#4F5A60"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.1386 24.0439C47.9174 24.1607 47.736 24.365 47.6541 24.6568L47.1828 26.3356C47.0038 26.9734 47.4832 27.6058 48.1456 27.6058H49.0881C49.7506 27.6058 50.23 26.9734 50.0509 26.3356L49.5797 24.6568C49.4977 24.365 49.3164 24.1607 49.0952 24.044L49.8434 26.6462C49.8985 26.8379 49.7546 27.0291 49.5551 27.0291H47.6787C47.4792 27.0291 47.3352 26.8379 47.3904 26.6462L48.1386 24.0439Z"
          fill="#4F5A60"
        />
        <path
          d="M47.3012 28.8586C47.345 28.8586 47.3846 28.8719 47.42 28.8986C47.456 28.9252 47.4865 28.9636 47.5115 29.0136C47.5365 29.0636 47.5558 29.1247 47.5694 29.1969C47.5829 29.2691 47.5897 29.3508 47.5897 29.4419C47.5897 29.533 47.5829 29.6147 47.5694 29.6869C47.5558 29.7591 47.5365 29.8203 47.5115 29.8703C47.4865 29.9203 47.456 29.9586 47.42 29.9853C47.3846 30.0119 47.345 30.0253 47.3012 30.0253H47.1401V28.8586H47.3012ZM47.2738 29.1052V29.7786H47.3012C47.3466 29.7786 47.3828 29.7508 47.4099 29.6953C47.437 29.6386 47.4505 29.5541 47.4505 29.4419C47.4505 29.3297 47.437 29.2458 47.4099 29.1902C47.3828 29.1336 47.3466 29.1052 47.3012 29.1052H47.2738Z"
          fill="#E54545"
        />
        <path
          d="M47.7891 29.7636L47.75 30.0253H47.6156L47.8048 28.8586H47.9455L48.1347 30.0253H48.0002L47.9612 29.7636H47.7891ZM47.8751 29.1602L47.8235 29.5303H47.9268L47.8751 29.1602Z"
          fill="#E54545"
        />
        <path
          d="M48.3175 29.3102V30.0253H48.1862V28.8586H48.3222L48.5224 29.5819V28.8586H48.6537V30.0253H48.5208L48.3175 29.3102Z"
          fill="#E54545"
        />
        <path
          d="M49.2556 29.8636C49.241 29.8869 49.2256 29.9097 49.2095 29.9319C49.1938 29.9541 49.1761 29.9741 49.1563 29.9919C49.1365 30.0086 49.1141 30.0219 49.0891 30.0319C49.0646 30.0419 49.0367 30.0469 49.0054 30.0469C48.9668 30.0469 48.9303 30.033 48.8959 30.0053C48.8615 29.9764 48.8313 29.9358 48.8052 29.8836C48.7797 29.8314 48.7594 29.7675 48.7442 29.6919C48.7297 29.6164 48.7224 29.5319 48.7224 29.4386C48.7224 29.3552 48.7291 29.2769 48.7427 29.2036C48.7568 29.1302 48.7763 29.0669 48.8013 29.0136C48.8263 28.9591 48.8563 28.9164 48.8912 28.8852C48.9267 28.853 48.9658 28.8369 49.0085 28.8369C49.0408 28.8369 49.0692 28.8441 49.0937 28.8586C49.1182 28.8719 49.1394 28.8891 49.1571 28.9102C49.1753 28.9314 49.1904 28.9536 49.2024 28.9769C49.2144 29.0002 49.2241 29.0214 49.2314 29.0402L49.1469 29.2269C49.1438 29.2191 49.1386 29.208 49.1313 29.1936C49.1245 29.1791 49.1154 29.1652 49.1039 29.1519C49.093 29.1375 49.0799 29.1252 49.0648 29.1152C49.0497 29.1052 49.033 29.1002 49.0148 29.1002C48.9903 29.1002 48.9684 29.1091 48.9491 29.1269C48.9303 29.1436 48.9142 29.1669 48.9006 29.1969C48.8876 29.2269 48.8777 29.2625 48.8709 29.3036C48.8641 29.3436 48.8607 29.3869 48.8607 29.4336C48.8607 29.488 48.8647 29.5369 48.8725 29.5803C48.8803 29.6236 48.891 29.6603 48.9045 29.6903C48.9186 29.7203 48.935 29.7436 48.9538 29.7603C48.9726 29.7758 48.9929 29.7836 49.0148 29.7836C49.032 29.7836 49.0474 29.7808 49.0609 29.7753C49.0745 29.7697 49.0859 29.763 49.0953 29.7553C49.1052 29.7475 49.113 29.7397 49.1188 29.7319C49.125 29.723 49.1292 29.7164 49.1313 29.7119V29.6086H49.0367V29.3702H49.2556V29.8636Z"
          fill="#E54545"
        />
        <path
          d="M49.3376 30.0253V28.8586H49.6293V29.1136H49.4713V29.3086H49.6058V29.5619H49.4713V29.7703H49.6355V30.0253H49.3376Z"
          fill="#E54545"
        />
        <path
          d="M50.0869 29.2169C50.0869 29.2814 50.0804 29.3419 50.0674 29.3986C50.0549 29.4541 50.0361 29.4964 50.0111 29.5253L50.0267 29.5869C50.0304 29.6025 50.0353 29.6225 50.0416 29.6469C50.0479 29.6703 50.0554 29.6997 50.0643 29.7353C50.0731 29.7697 50.0836 29.8108 50.0956 29.8586C50.1075 29.9064 50.1214 29.9619 50.137 30.0253H49.9892L49.8915 29.5936H49.8508V30.0253H49.7171V28.8586H49.8954C49.9605 28.8586 50.0088 28.8891 50.04 28.9502C50.0713 29.0114 50.0869 29.1002 50.0869 29.2169ZM49.8508 29.1036V29.3552H49.8907C49.9089 29.3552 49.9233 29.3441 49.9337 29.3219C49.9446 29.2986 49.9501 29.2669 49.9501 29.2269C49.9501 29.188 49.9452 29.158 49.9353 29.1369C49.9259 29.1147 49.9089 29.1036 49.8844 29.1036H49.8508Z"
          fill="#E54545"
        />
        <rect
          x="62.6123"
          y="10.333"
          width="19.3906"
          height="0.333334"
          fill="#A4B9B8"
        />
        <rect
          x="43.3779"
          y="10.333"
          width="19.0778"
          height="0.333334"
          fill="#A4B9B8"
        />
        <g filter="url(#illus-lvb_filter4_d)">
          <rect
            x="55.4385"
            y="2.33301"
            width="5.62952"
            height="6.66667"
            rx="1"
            fill="#95AFAE"
          />
        </g>
        <ellipse
          cx="55.8048"
          cy="3.06217"
          rx="0.136829"
          ry="0.291667"
          fill="#73908E"
        />
        <ellipse
          cx="60.7013"
          cy="3.06217"
          rx="0.136829"
          ry="0.291667"
          fill="#73908E"
        />
        <ellipse
          cx="55.8048"
          cy="8.27018"
          rx="0.136829"
          ry="0.291667"
          fill="#73908E"
        />
        <ellipse
          cx="60.7013"
          cy="8.27018"
          rx="0.136829"
          ry="0.291667"
          fill="#73908E"
        />
        <rect
          x="55.8687"
          y="4.02051"
          width="4.76945"
          height="2.64583"
          rx="1"
          fill="#4F5A60"
        />
        <rect
          x="56.5137"
          y="7.375"
          width="3.47936"
          height="0.604167"
          rx="0.302083"
          fill="#4F5A60"
        />
        <ellipse
          cx="60.3009"
          cy="5.34375"
          rx="0.124611"
          ry="0.265625"
          fill="#6D7A82"
        />
        <ellipse
          cx="56.2057"
          cy="5.34375"
          rx="0.124613"
          ry="0.265625"
          fill="#6D7A82"
        />
        <g filter="url(#illus-lvb_filter5_d)">
          <rect
            x="49.4668"
            y="2.33301"
            width="5.62952"
            height="6.66667"
            rx="1"
            fill="#95AFAE"
          />
        </g>
        <ellipse
          cx="49.8331"
          cy="3.06217"
          rx="0.136829"
          ry="0.291667"
          fill="#73908E"
        />
        <ellipse
          cx="54.7296"
          cy="3.06217"
          rx="0.136829"
          ry="0.291667"
          fill="#73908E"
        />
        <ellipse
          cx="49.8331"
          cy="8.27018"
          rx="0.136829"
          ry="0.291667"
          fill="#73908E"
        />
        <ellipse
          cx="54.7296"
          cy="8.27018"
          rx="0.136829"
          ry="0.291667"
          fill="#73908E"
        />
        <rect
          x="50.0776"
          y="4.02051"
          width="0.517994"
          height="2.64583"
          fill="#4F5A60"
        />
        <rect
          x="50.0776"
          y="4.69727"
          width="0.517994"
          height="0.395834"
          fill="#818A91"
        />
        <rect
          x="50.7227"
          y="4.02051"
          width="0.517994"
          height="2.64583"
          fill="#4F5A60"
        />
        <rect
          x="50.7227"
          y="5.66602"
          width="0.517994"
          height="0.395833"
          fill="#818A91"
        />
        <rect
          x="51.3677"
          y="4.02051"
          width="0.517994"
          height="2.64583"
          fill="#4F5A60"
        />
        <rect
          x="51.3677"
          y="4.69727"
          width="0.517994"
          height="0.395834"
          fill="#818A91"
        />
        <rect
          x="52.0127"
          y="4.02051"
          width="0.517994"
          height="2.64583"
          fill="#4F5A60"
        />
        <rect
          x="52.0127"
          y="5.66602"
          width="0.517994"
          height="0.395833"
          fill="#818A91"
        />
        <rect
          x="52.6582"
          y="4.02051"
          width="0.517994"
          height="2.64583"
          fill="#4F5A60"
        />
        <rect
          x="52.6582"
          y="4.69727"
          width="0.517994"
          height="0.395834"
          fill="#818A91"
        />
        <rect
          x="53.9673"
          y="4.02051"
          width="0.517994"
          height="2.64583"
          fill="#4F5A60"
        />
        <rect
          x="53.9673"
          y="4.69727"
          width="0.517994"
          height="0.395834"
          fill="#818A91"
        />
        <rect
          x="53.3032"
          y="4.02051"
          width="0.517994"
          height="2.64583"
          fill="#4F5A60"
        />
        <rect
          x="53.3032"
          y="5.66602"
          width="0.517994"
          height="0.395833"
          fill="#818A91"
        />
        <rect
          x="50.542"
          y="7.375"
          width="3.47936"
          height="0.604167"
          rx="0.302083"
          fill="#4F5A60"
        />
        <rect
          x="68.2124"
          y="3.54199"
          width="1.94492"
          height="4.14583"
          fill="#4F5A60"
        />
        <rect
          x="68.3687"
          y="3.875"
          width="1.63217"
          height="3.47917"
          fill="#B6D6E1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M68.6882 6.88587C68.6994 5.49653 69.2267 4.37527 69.879 4.35572V4.11816C69.1651 4.13768 68.5879 5.3653 68.5767 6.88587H68.6882Z"
          fill="#AFBEC7"
        />
        <path
          d="M68.6659 4.68574L68.6391 4.85449H68.5767L68.6857 4.19824H68.7508L68.8599 4.85449H68.7974L68.7706 4.68574H68.6659ZM68.7183 4.3398L68.6827 4.57605H68.7543L68.7183 4.3398Z"
          fill="#4F5A60"
        />
        <ellipse
          cx="69.6002"
          cy="6.51074"
          rx="0.175922"
          ry="0.375"
          fill="#3D4A51"
        />
        <rect
          x="68.6963"
          y="6.51074"
          width="0.796539"
          height="0.125"
          rx="0.0625"
          fill="#3D4A51"
        />
        <rect
          width="0.84778"
          height="0.1217"
          rx="0.0608498"
          transform="matrix(0.868648 0.49543 -0.124544 0.992214 68.7563 5.96973)"
          fill="#3D4A51"
        />
        <rect
          width="0.044003"
          height="0.606382"
          rx="0.0220015"
          transform="matrix(0.854456 -0.519524 0.132661 0.991161 69.6001 4.36719)"
          fill="#AFBEC7"
        />
        <rect
          width="0.0624456"
          height="0.507206"
          rx="0.0312228"
          transform="matrix(0.469441 -0.882964 0.382498 0.923956 69.1172 4.90918)"
          fill="#AFBEC7"
        />
        <rect
          width="0.0833016"
          height="0.293725"
          rx="0.0416508"
          transform="matrix(-0.014768 0.999891 0.997756 0.0669574 68.6577 6.69238)"
          fill="#AFBEC7"
        />
        <rect
          width="0.0759094"
          height="0.390467"
          rx="0.0379547"
          transform="matrix(-0.240605 0.970623 0.663904 0.747818 68.8535 5.54102)"
          fill="#AFBEC7"
        />
        <rect
          x="71.4668"
          y="3.54199"
          width="1.94492"
          height="4.14583"
          fill="#4F5A60"
        />
        <rect
          x="71.623"
          y="3.875"
          width="1.63217"
          height="3.47917"
          fill="#B6D6E1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.9426 6.88587C71.9538 5.49651 72.4811 4.37523 73.1334 4.35572V4.11816C72.4195 4.13764 71.8423 5.36528 71.8311 6.88587H71.9426Z"
          fill="#AFBEC7"
        />
        <path
          d="M71.9203 4.68574L71.8935 4.85449H71.8311L71.9401 4.19824H72.0052L72.1143 4.85449H72.0518L72.025 4.68574H71.9203ZM71.9727 4.3398L71.937 4.57605H72.0087L71.9727 4.3398Z"
          fill="#4F5A60"
        />
        <ellipse
          cx="72.8546"
          cy="6.51074"
          rx="0.175922"
          ry="0.375"
          fill="#3D4A51"
        />
        <rect
          x="71.9507"
          y="6.51074"
          width="0.796539"
          height="0.125"
          rx="0.0625"
          fill="#3D4A51"
        />
        <rect
          width="0.847806"
          height="0.1217"
          rx="0.0608498"
          transform="matrix(0.868656 0.495415 -0.124549 0.992213 72.0107 5.96973)"
          fill="#3D4A51"
        />
        <rect
          width="0.044003"
          height="0.606382"
          rx="0.0220015"
          transform="matrix(0.854456 -0.519524 0.132661 0.991161 72.8545 4.36719)"
          fill="#AFBEC7"
        />
        <rect
          width="0.0624451"
          height="0.507204"
          rx="0.0312226"
          transform="matrix(0.469429 -0.88297 0.382487 0.923961 72.3711 4.90918)"
          fill="#AFBEC7"
        />
        <rect
          width="0.0833016"
          height="0.293695"
          rx="0.0416508"
          transform="matrix(-0.0147665 0.999891 0.997755 0.0669643 71.9121 6.69238)"
          fill="#AFBEC7"
        />
        <rect
          width="0.0759093"
          height="0.390462"
          rx="0.0379546"
          transform="matrix(-0.240599 0.970625 0.663893 0.747827 72.1079 5.54102)"
          fill="#AFBEC7"
        />
        <rect
          x="66.1108"
          y="3.54199"
          width="1.94492"
          height="4.14583"
          fill="#4F5A60"
        />
        <rect
          x="66.2671"
          y="3.875"
          width="1.63216"
          height="3.47917"
          fill="#B6D6E1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66.5861 6.88587C66.5974 5.4965 67.1247 4.37522 67.777 4.35572V4.11816C67.0631 4.13763 66.4859 5.36527 66.4746 6.88587H66.5861Z"
          fill="#AFBEC7"
        />
        <path
          d="M66.5639 4.68574L66.5371 4.85449H66.4746L66.5837 4.19824H66.6488L66.7579 4.85449H66.6954L66.6686 4.68574H66.5639ZM66.6162 4.3398L66.5806 4.57605H66.6523L66.6162 4.3398Z"
          fill="#4F5A60"
        />
        <ellipse
          cx="67.4987"
          cy="6.51074"
          rx="0.175907"
          ry="0.375"
          fill="#3D4A51"
        />
        <rect
          x="66.5947"
          y="6.51074"
          width="0.796539"
          height="0.125"
          rx="0.0625"
          fill="#3D4A51"
        />
        <rect
          width="0.84778"
          height="0.1217"
          rx="0.0608498"
          transform="matrix(0.868648 0.49543 -0.124544 0.992214 66.6548 5.96973)"
          fill="#3D4A51"
        />
        <rect
          width="0.044003"
          height="0.606382"
          rx="0.0220015"
          transform="matrix(0.854456 -0.519524 0.132661 0.991161 67.4985 4.36719)"
          fill="#AFBEC7"
        />
        <rect
          width="0.0624446"
          height="0.507201"
          rx="0.0312223"
          transform="matrix(0.469416 -0.882977 0.382476 0.923966 67.0156 4.90918)"
          fill="#AFBEC7"
        />
        <rect
          width="0.0833016"
          height="0.293695"
          rx="0.0416508"
          transform="matrix(-0.0147665 0.999891 0.997755 0.0669643 66.5562 6.69238)"
          fill="#AFBEC7"
        />
        <rect
          width="0.0759094"
          height="0.390467"
          rx="0.0379547"
          transform="matrix(-0.240605 0.970623 0.663904 0.747818 66.752 5.54102)"
          fill="#AFBEC7"
        />
        <rect
          x="64.0098"
          y="3.54199"
          width="1.94492"
          height="4.14583"
          fill="#4F5A60"
        />
        <rect
          x="64.166"
          y="3.875"
          width="1.63218"
          height="3.47917"
          fill="#B6D6E1"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M64.4855 6.88587C64.4968 5.49653 65.0241 4.37527 65.6763 4.35572V4.11816C64.9625 4.13768 64.3853 5.3653 64.374 6.88587H64.4855Z"
          fill="#AFBEC7"
        />
        <path
          d="M64.4633 4.68574L64.4365 4.85449H64.374L64.4831 4.19824H64.5482L64.6573 4.85449H64.5948L64.568 4.68574H64.4633ZM64.5156 4.3398L64.48 4.57605H64.5517L64.5156 4.3398Z"
          fill="#4F5A60"
        />
        <ellipse
          cx="65.3976"
          cy="6.51074"
          rx="0.175922"
          ry="0.375"
          fill="#3D4A51"
        />
        <rect
          x="64.4937"
          y="6.51074"
          width="0.796539"
          height="0.125"
          rx="0.0625"
          fill="#3D4A51"
        />
        <rect
          width="0.847777"
          height="0.1217"
          rx="0.0608498"
          transform="matrix(0.868647 0.495432 -0.124543 0.992214 64.5537 5.96973)"
          fill="#3D4A51"
        />
        <rect
          width="0.044005"
          height="0.606383"
          rx="0.0220025"
          transform="matrix(0.854471 -0.519499 0.13267 0.99116 65.3975 4.36719)"
          fill="#AFBEC7"
        />
        <rect
          width="0.0624449"
          height="0.507203"
          rx="0.0312224"
          transform="matrix(0.469423 -0.882974 0.382481 0.923963 64.9146 4.90918)"
          fill="#AFBEC7"
        />
        <rect
          width="0.0833016"
          height="0.293706"
          rx="0.0416508"
          transform="matrix(-0.0147671 0.999891 0.997756 0.0669617 64.4551 6.69238)"
          fill="#AFBEC7"
        />
        <rect
          width="0.0759093"
          height="0.390464"
          rx="0.0379547"
          transform="matrix(-0.240602 0.970624 0.663899 0.747823 64.6509 5.54102)"
          fill="#AFBEC7"
        />
        <rect
          x="70.3135"
          y="4.52148"
          width="0.996895"
          height="2.125"
          fill="#4F5A60"
        />
        <rect
          x="70.3721"
          y="4.64648"
          width="0.879608"
          height="1.875"
          fill="#C4DAD7"
        />
        <rect
          x="70.79"
          y="4.7041"
          width="0.0439835"
          height="0.322916"
          rx="0.0219917"
          fill="#859BA8"
        />
        <rect
          x="70.79"
          y="6.1416"
          width="0.0439835"
          height="0.322917"
          rx="0.0219917"
          fill="#859BA8"
        />
        <rect
          x="71.2246"
          y="5.53711"
          width="0.0937505"
          height="0.151497"
          rx="0.0468752"
          transform="rotate(90 71.2246 5.53711)"
          fill="#859BA8"
        />
        <rect
          x="70.5503"
          y="5.53711"
          width="0.0937505"
          height="0.151489"
          rx="0.0468752"
          transform="rotate(90 70.5503 5.53711)"
          fill="#859BA8"
        />
        <rect
          width="0.073225"
          height="0.252215"
          rx="0.0366125"
          transform="matrix(-0.424723 0.905324 -0.424722 -0.905324 71.1191 6.17383)"
          fill="#859BA8"
        />
        <rect
          width="0.0732252"
          height="0.252216"
          rx="0.0366126"
          transform="matrix(-0.424721 0.905324 -0.424721 -0.905324 70.6426 5.15723)"
          fill="#859BA8"
        />
        <rect
          width="0.0732251"
          height="0.252215"
          rx="0.0366125"
          transform="matrix(-0.424722 -0.905324 0.424722 -0.905324 70.5356 6.24023)"
          fill="#859BA8"
        />
        <rect
          width="0.0732227"
          height="0.252207"
          rx="0.0366114"
          transform="matrix(-0.424665 -0.90535 0.424665 -0.90535 71.0122 5.22363)"
          fill="#859BA8"
        />
        <ellipse
          cx="70.8118"
          cy="5.49544"
          rx="0.288319"
          ry="0.614583"
          fill="#404B51"
        />
        <ellipse
          cx="70.8119"
          cy="5.4952"
          rx="0.21767"
          ry="0.46395"
          fill="#4F5A60"
        />
        <rect
          x="70.7212"
          y="6.02148"
          width="0.180801"
          height="0.687093"
          fill="#404B51"
        />
        <rect
          x="64.0098"
          y="8.35449"
          width="6.14751"
          height="0.645833"
          fill="#4F5A60"
        />
      </g>
      <defs>
        <filter
          id="illus-lvb_filter0_d"
          x="0.250977"
          y="9.33301"
          width="42.6248"
          height="63.3333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="illus-lvb_filter1_d"
          x="13.3115"
          y="1.33301"
          width="7.62952"
          height="8.66667"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="illus-lvb_filter2_d"
          x="7.33984"
          y="1.33301"
          width="7.62952"
          height="8.66667"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="illus-lvb_filter3_d"
          x="41.3779"
          y="9.33301"
          width="42.6247"
          height="63.3333"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="illus-lvb_filter4_d"
          x="54.4385"
          y="1.33301"
          width="7.62952"
          height="8.66667"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
        <filter
          id="illus-lvb_filter5_d"
          x="48.4668"
          y="1.33301"
          width="7.62952"
          height="8.66667"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="0.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </>
  )
}

export default LVB
