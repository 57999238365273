import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import FieldLabel from '../FieldLabel'
import FieldInputText from './FieldInputText'
import FieldInputSplit from './FieldInputSplit'
import FieldInputBox from './FieldInputBox'
import { Hint } from '../FieldMisc'
import FieldError from '../FieldError'
import FieldPrefix from '../FieldPrefix'
import FieldUnit from '../FieldUnit'
import Icons from '../../Icons'

const FieldInput = ({
  spec,
  onChange,
  onBlur,
  onFocus,
  children,
  hasError,
  capsOnChange,
  capsOnBlur,
  onlyNumber,
  trailingZero,
  disabled,
  maxLength,
  value,
  inputProps,
  loading,
  className,
  valuePrefix,
}) => {
  const [inputVal, setInputVal] = React.useState(value || '')

  React.useEffect(() => {
    setInputVal(value || '')
  }, [value])

  const _FieldInput = classnames('lm--input cui-field', className, {
    'is-error': hasError,
  })
  const _FieldGroup = classnames('cui-field_control', {
    'lm--double-fields': containsPrefix(),
    'lm--input-control with-before-addon': containsBeforeUnit(),
    'lm--input-control with-after-addon': containsAfterUnit(),
  })
  function containsPrefix() {
    let flag = false
    React.Children.forEach(children, (child, childIdx) => {
      if (child && child.type === FieldPrefix) {
        flag = true
      }
    })
    return flag
  }

  function containsBeforeUnit() {
    let flag = false
    if (containsUnit()) {
      React.Children.forEach(children, (child, childIdx) => {
        if (child && child.type === FieldUnit && child.props.before) {
          flag = true
        }
      })
    }
    return flag
  }

  function containsAfterUnit() {
    let flag = false
    if (containsUnit()) {
      React.Children.forEach(children, (child, childIdx) => {
        if (child && child.type === FieldUnit && child.props.after) {
          flag = true
        }
      })
    }
    return flag
  }

  function containsUnit() {
    let flag = false
    React.Children.forEach(children, (child, childIdx) => {
      if (child && child.type === FieldUnit) {
        flag = true
      }
    })
    return flag
  }

  function renderInput() {
    return (
      <input
        type="text"
        {...spec}
        {...inputProps}
        onChange={_changeHandler}
        onBlur={_blurHandler}
        onFocus={onFocus}
        disabled={disabled}
        value={inputVal}
        maxLength={maxLength}
      />
    )
  }

  function _changeHandler(e) {
    if (onChange) {
      _fieldChange(e)
      onChange(e)
    } else {
      _fieldChange(e)
    }
  }

  function _blurHandler(e) {
    if (onChange) {
      _fieldBlur(e)
      onChange(e)
    } else {
      _fieldBlur(e)
    }
  }

  function transformWithZero(v, padOffset) {
    if (v.length < 2) {
      if (v.charAt(0) !== '0' && checkIfValIsNum(v.charAt(0))) {
        return `0${v}`
      } else {
        return v.slice(padOffset)
      }
    }
    if (v.length > 2) {
      if (v.charAt(0) === '0' && checkIfValIsNum(v.charAt(0))) {
        let sliceIt = true
        for (let i = 1; i < v.length; i++) {
          if (v.charAt(i) && !checkIfValIsNum(v.charAt(i))) {
            sliceIt = false
          }
        }
        if (sliceIt) {
          return v.slice(padOffset + 1)
        }
        return v
      }
    }
    return v
  }

  function checkIfValIsNum(v) {
    return Number.isInteger(parseInt(v))
  }

  function _fieldBlur(e) {
    if (capsOnBlur) {
      setInputVal(e.target.value.toUpperCase())
    }
    if (trailingZero) {
      const padOffset = -e.target.value.length
      if (e.target.value) {
        const res = transformWithZero(e.target.value, padOffset)
        if (res) {
          setInputVal(res)
        } else {
          setInputVal(e.target.value.slice(padOffset))
        }
      }
    }
    if (onBlur) {
      onBlur(e)
    }
  }

  function _fieldChange(e) {
    if (onlyNumber) {
      const value = e.target.value.replace(/[a-zA-Z]/g, '')
      e.target.value = value
      setInputVal(value)
    } else if (capsOnChange) {
      const value = e.target.value.toUpperCase()
      e.target.value = value
      setInputVal(value)
    } else {
      setInputVal(e.target.value)
    }
  }

  function renderMisc() {
    if (!children) return null
    return React.Children.map(children, (child, childIdx) => {
      if (!child) return null
      if (child.type === Hint || child.type === FieldError) {
        return React.cloneElement(child)
      }
      return null
    })
  }

  function renderField() {
    const label = React.Children.map(children, (child, childIdx) => {
      if (!child) return null
      if (child.type === FieldLabel) {
        return <FieldLabel {...child.props} />
      }
      return null
    })
    const prefix = React.Children.map(children, (child, childIdx) => {
      if (!child) return null
      if (child.type === FieldPrefix) {
        return <FieldPrefix {...child.props} />
      }
      return null
    })

    const unit = React.Children.map(children, (child, childIdx) => {
      if (!child) return null
      if (child.type === FieldUnit) {
        return <FieldUnit {...child.props} />
      }
      return null
    })

    const inputs = () => {
      // Check if there is only 1 or no kids at all then just render input
      let inputCount = 0

      // if (React.Children.count(children) <= 2) {
      React.Children.forEach(children, child => {
        if (child && child.type === FieldInput) {
          inputCount++
        }
      })
      if (inputCount < 1) {
        if (valuePrefix) {
          return (
            <span className="cui-field__input-with-prefix" type="text">
              {valuePrefix}
              {renderInput()}
            </span>
          )
        } else {
          return renderInput()
        }
      }
      // }

      // But if there are number of kids which is more than 1,
      // render the components given that they're also with FieldInput type,
      // this render check is a provision for multiple splits
      if (inputCount > 0) {
        return React.Children.map(children, (child, childIdx) => {
          if (!child) return null
          if (child.type === FieldInput) {
            return React.cloneElement(child, [disabled]) // returns a copy of the specified element
          }
          return null
        })
      }

      return null
    }

    return (
      <div className={_FieldInput}>
        {label}
        <div className={_FieldGroup}>
          {prefix}
          {inputs()}
          {unit}
          {loading && (
            <div className="cui-field__loading-icon">
              <Icons.Spinloader width={30} height={30} color={['#333']} />
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <>
      {renderField()}
      {renderMisc()}
      {hasError && <FieldError name={spec.name} />}
    </>
  )
}

FieldInput.propTypes = {
  spec: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  hasError: PropTypes.bool,
  capsOnChange: PropTypes.bool,
  capsOnBlur: PropTypes.bool,
  onlyNumber: PropTypes.bool,
  trailingZero: PropTypes.bool,
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  value: PropTypes.string,
  inputProps: PropTypes.object,
  loading: PropTypes.bool,
  className: PropTypes.string,
}

FieldInput.defaultProps = {
  hasError: false,
  capsOnChange: false,
  capsOnBlur: false,
  onlyNumber: false,
  trailingZero: false,
}

FieldInput.Text = FieldInputText
FieldInput.Split = FieldInputSplit
FieldInput.Box = FieldInputBox

export default FieldInput
