import React from 'react'
import SignatureNodeContainer from './SignatureNodeContainer'

const SignatureNode = ({ by }) => (
  <div className="cui-signature_node">
    <div className="sn-line" />
    <div
      className="sn-by"
      dangerouslySetInnerHTML={{
        __html: by.map(o => `<span>${o}</span>`).join(``),
      }}
    />
  </div>
)

SignatureNode.defaultNode = {
  by: ['Signature'],
}

SignatureNode.Container = SignatureNodeContainer

export default SignatureNode
