import React from 'react'

class DownloadPhone extends React.PureComponent {
  render() {
    return (
      <g fill="none" fillRule="evenodd">
        <rect
          width="42"
          height="78"
          x="1"
          y="1"
          fill="#FFF"
          stroke="#CED5DC"
          strokeWidth="2"
          rx="5"
        />
        <path
          stroke="#48DCC0"
          strokeLinecap="square"
          strokeWidth="2"
          d="M10 45v5.92M13 43v7.92M16 39v11.92M19 40v10.92M22 40v10.92M25 43v7.92M28 44v6.92M31 43v7.92M34 44v6.92"
        />
        <rect width="35" height="64" x="4.5" y="5.5" stroke="#CED5DC" rx="3" />
        <path stroke="#CED5DC" strokeLinecap="square" d="M5 61h34" />
        <rect
          width="3"
          height="3"
          x="8.5"
          y="63.5"
          fill="#FFF"
          stroke="#CED5DC"
          rx="1"
        />
        <rect
          width="3"
          height="3"
          x="14.5"
          y="63.5"
          fill="#FFF"
          stroke="#CED5DC"
          rx="1"
        />
        <rect
          width="3"
          height="3"
          x="20.5"
          y="63.5"
          fill="#FFF"
          stroke="#CED5DC"
          rx="1"
        />
        <rect
          width="3"
          height="3"
          x="26.5"
          y="63.5"
          fill="#FFF"
          stroke="#CED5DC"
          rx="1"
        />
        <rect
          width="3"
          height="3"
          x="32.5"
          y="63.5"
          fill="#FFF"
          stroke="#CED5DC"
          rx="1"
        />
        <path
          stroke="#CED5DC"
          strokeLinecap="square"
          strokeWidth="2"
          d="M9.5 19.5h8M9.38 23H28M9.38 27H28"
        />
      </g>
    )
  }
}

export default DownloadPhone
