import React from 'react'

function InsightsIcon({ color }) {
  return (
    <>
      <mask
        id="icon-insights_mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="20"
        height="20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19 2H5C3.34315 2 2 3.34315 2 5V19C2 20.6569 3.34315 22 5 22H19C20.6569 22 22 20.6569 22 19V5C22 3.34315 20.6569 2 19 2ZM4 5C4 4.44772 4.44772 4 5 4H18.8365L13.16 13.5365L9.73541 9.82215C9.51516 9.58327 9.19257 9.46628 8.87039 9.50846C8.54822 9.55064 8.26662 9.74672 8.11528 10.0343L4 17.8533V5ZM5.13025 20H19C19.5523 20 20 19.5523 20 19V5.95544L14.1928 15.7115C14.0314 15.9828 13.7513 16.1618 13.4373 16.1946C13.1233 16.2274 12.8123 16.1099 12.5983 15.8778L9.2253 12.2194L5.13025 20Z"
          fill={color[0]}
        />
      </mask>
      <g mask="url(#icon-insights_mask0)">
        <rect width="24" height="24" fill="#00B0B2" />
      </g>
    </>
  )
}

export default InsightsIcon
