import React from 'react'
import PropTypes from 'prop-types'

class SortIcon extends React.PureComponent {
  static propTypes = {
    forMobile: PropTypes.bool,
  }

  static defaultProps = {
    forMobile: true,
  }

  render() {
    const { forMobile, order, activeColor } = this.props
    return (
      <g className="icon-sort" stroke="none" strokeWidth="1" fillRule="evenodd">
        {forMobile ? (
          <path d="M3 8C3.55228 8 4 8.44772 4 9C4 9.55228 3.55228 10 3 10H1C0.447715 10 0 9.55228 0 9C0 8.44772 0.447715 8 1 8H3ZM6.125 4C6.60825 4 7 4.44772 7 5C7 5.55228 6.60825 6 6.125 6H0.875C0.391751 6 0 5.55228 0 5C0 4.44772 0.391751 4 0.875 4H6.125ZM9.09091 0C9.59299 0 10 0.447715 10 1C10 1.55228 9.59299 2 9.09091 2H0.909091C0.407014 2 0 1.55228 0 1C0 0.447715 0.407014 0 0.909091 0H9.09091Z" />
        ) : (
          <>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.99878 12C7.73349 12 7.47907 11.8946 7.29152 11.707L5.29274 9.70732C4.9023 9.31671 4.90244 8.68355 5.29304 8.29311C5.68365 7.90267 6.31682 7.9028 6.70726 8.29341L7.99878 9.58548L9.2903 8.29341C9.68074 7.9028 10.3139 7.90267 10.7045 8.29311C11.0951 8.68355 11.0953 9.31671 10.7048 9.70732L8.70604 11.707C8.51849 11.8946 8.26407 12 7.99878 12Z"
              fill={order === 'desc' ? activeColor : null}
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.99878 3C7.73349 3 7.47907 3.10542 7.29152 3.29304L5.29274 5.29268C4.9023 5.68329 4.90244 6.31645 5.29304 6.70689C5.68365 7.09733 6.31682 7.0972 6.70726 6.70659L7.99878 5.41452L9.2903 6.70659C9.68074 7.0972 10.3139 7.09733 10.7045 6.70689C11.0951 6.31645 11.0953 5.68329 10.7048 5.29268L8.70604 3.29304C8.51849 3.10542 8.26407 3 7.99878 3Z"
              fill={order === 'asc' ? activeColor : null}
            />
          </>
        )}
      </g>
    )
  }
}

export default SortIcon
