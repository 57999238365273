/** React Package */
import React from 'react'
import PropTypes from 'prop-types'

/** Style Package */
import classnames from 'classnames'
import './styles.scss'

/** Theme packages */
import { useTheme } from '../Theme'

/** Supported Components */
import SPgroup from './SPgroup'

/** Logo Component
 * @param {object} logo The Logo component properties
 * @param {(string|SVGElement)} logo.source The source of Logo entitiy
 * @param {string} [logo.target] The target tab when component is clicked
 * @param {string} logo.name This display the component's name when source could not rendered properly or yet
 * @param {string} [logo.link] The URL to the destination site
 */
const Logo = ({ logo = {}, className, style, children }) => {
  /** Allow Dark or any Mode */
  const theme = useTheme()

  /** Accept other and existing className */
  const _Logo = classnames('cui-logo', className)

  /** Validator Functions */
  const islogoValid = obj => {
    return obj !== undefined
  }

  const islogoNameValid = obj => {
    return obj.name !== undefined || obj.name !== ''
  }

  const islogoTargetValid = obj => {
    return (
      (obj.link !== undefined || obj.link !== '') && obj.target !== undefined
    )
  }

  const isValidHttpUrl = string => {
    let url

    try {
      url = new URL(string)
    } catch (_) {
      return false
    }

    return url.protocol === 'http:' || url.protocol === 'https:'
  }

  const renderContent = () => {
    let content = null

    /** Check for invalid rules */
    if (
      !islogoValid(logo) ||
      !islogoNameValid(logo) ||
      !islogoTargetValid(logo)
    ) {
      return null
    }

    /** Check children exist */
    if (children !== undefined) {
      /** Children component is not allow in this component */
      return children
    }

    /** Source selector */
    if (typeof logo.source === 'string') {
      content = (
        <img className="lm--logo cui--logo" src={logo.source} alt={logo.name} />
      )
    } else {
      content = logo.source
    }

    /** Check target */
    if (typeof logo.link === 'string' && isValidHttpUrl(logo.link)) {
      if (logo.target === '_blank') {
        content = (
          <a href={logo.link} target={logo.target} rel="noopener noreferrer">
            {content}
          </a>
        )
      } else {
        content = (
          <a href={logo.link} target={logo.target}>
            {content}
          </a>
        )
      }
    }
    return content
  }

  /** Return with encapsuled element consist of its valid or non-valid child */
  return (
    <figure
      className={_Logo}
      style={{
        // '--cuiLogoBg': theme.colors.logo.background,
        // '--cuiLogoShadow': theme.colors.logo.boxShadow,
        ...style,
      }}
    >
      {renderContent()}
    </figure>
  )
}

/** SubComponents */
Logo.SPgroup = SPgroup

/** Required and Type Checks
 * @requires source The user must provide the source in path or svg Element
 * @requires name The user must provide a name for the Component
 */
Logo.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  logo: PropTypes.shape({
    source: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(SVGElement),
    ]).isRequired,
    target: PropTypes.string,
    name: PropTypes.string.isRequired,
    link: PropTypes.string,
  }),
}

/** Export */
export default Logo
