import React from 'react'

class PartialDischarge extends React.PureComponent {
  render() {
    if (this.props.noColors) {
      return (
        <g>
          <rect
            x="8"
            y="7.5791"
            width="24"
            height="4.15597"
            rx="2.07798"
            fill="url(#partial-discharge-paint0_linear)"
          />
          <rect
            x="8"
            y="27.7651"
            width="24"
            height="4.15597"
            rx="2.07798"
            fill="url(#partial-discharge-paint1_linear)"
          />
          <rect
            x="24.6387"
            y="18.2925"
            width="3"
            height="9"
            rx="1.5"
            transform="rotate(90 24.6387 18.2925)"
            fill="url(#partial-discharge-paint2_linear)"
          />
          <rect
            x="22.666"
            y="17.7485"
            width="3"
            height="9.05445"
            rx="1.5"
            transform="rotate(30 22.666 17.7485)"
            fill="url(#partial-discharge-paint3_linear)"
          />
          <rect
            x="19.5273"
            y="12.5"
            width="3"
            height="9.05478"
            rx="1.5"
            transform="rotate(30 19.5273 12.5)"
            fill="url(#partial-discharge-paint4_linear)"
          />
          <defs>
            <linearGradient
              id="partial-discharge-paint0_linear"
              x1="7.76923"
              y1="8.0986"
              x2="32.0895"
              y2="10.9795"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#818A91" />
              <stop offset="1" stopColor="#D8D8D8" />
            </linearGradient>
            <linearGradient
              id="partial-discharge-paint1_linear"
              x1="8"
              y1="26.4664"
              x2="32.1175"
              y2="29.7628"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#D8D8D8" />
              <stop offset="1" stopColor="#CED5DC" />
            </linearGradient>
            <linearGradient
              id="partial-discharge-paint2_linear"
              x1="31.3677"
              y1="22.2424"
              x2="29.9985"
              y2="26.3296"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFC880" />
              <stop offset="0.520833" stopColor="#FFC165" />
              <stop offset="1" stopColor="#FFB95C" />
            </linearGradient>
            <linearGradient
              id="partial-discharge-paint3_linear"
              x1="21.166"
              y1="22.2758"
              x2="26.5725"
              y2="24.0671"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFDEB3" />
              <stop offset="1" stopColor="#FFC466" />
            </linearGradient>
            <linearGradient
              id="partial-discharge-paint4_linear"
              x1="18.0273"
              y1="17.0274"
              x2="23.4339"
              y2="18.8187"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#FFDEB3" />
              <stop offset="1" stopColor="#FFC466" />
            </linearGradient>
          </defs>
        </g>
      )
    }
    return (
      <g>
        <rect
          x="8"
          y="7.5791"
          width="24"
          height="4.15597"
          rx="2.07798"
          fill="url(#partial-discharge-paint0_linear)"
        />
        <rect
          x="8"
          y="27.7651"
          width="24"
          height="4.15597"
          rx="2.07798"
          fill="url(#partial-discharge-paint1_linear)"
        />
        <rect
          x="24.6387"
          y="18.2925"
          width="3"
          height="9"
          rx="1.5"
          transform="rotate(90 24.6387 18.2925)"
          fill="url(#partial-discharge-paint2_linear)"
        />
        <rect
          x="22.6658"
          y="17.7485"
          width="3"
          height="9.05445"
          rx="1.5"
          transform="rotate(30 22.6658 17.7485)"
          fill="url(#partial-discharge-paint3_linear)"
        />
        <rect
          x="19.5273"
          y="12.5"
          width="3"
          height="9.05478"
          rx="1.5"
          transform="rotate(30 19.5273 12.5)"
          fill="url(#partial-discharge-paint4_linear)"
        />
        <defs>
          <linearGradient
            id="partial-discharge-paint0_linear"
            x1="7.76923"
            y1="8.0986"
            x2="31.5604"
            y2="9.61806"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1E78AB" />
            <stop offset="1" stopColor="#19B5DB" />
          </linearGradient>
          <linearGradient
            id="partial-discharge-paint1_linear"
            x1="8"
            y1="26.4664"
            x2="32.1175"
            y2="29.7628"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#CE63A2" />
            <stop offset="1" stopColor="#9D58AB" />
          </linearGradient>
          <linearGradient
            id="partial-discharge-paint2_linear"
            x1="31.3677"
            y1="22.2424"
            x2="29.9985"
            y2="26.3296"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFC880" />
            <stop offset="0.520833" stopColor="#FFC165" />
            <stop offset="1" stopColor="#FFB95C" />
          </linearGradient>
          <linearGradient
            id="partial-discharge-paint3_linear"
            x1="21.1658"
            y1="22.2758"
            x2="26.5723"
            y2="24.0671"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFDEB3" />
            <stop offset="1" stopColor="#FFC466" />
          </linearGradient>
          <linearGradient
            id="partial-discharge-paint4_linear"
            x1="18.0273"
            y1="17.0274"
            x2="23.4339"
            y2="18.8187"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFDEB3" />
            <stop offset="1" stopColor="#FFC466" />
          </linearGradient>
        </defs>
      </g>
    )
  }
}

export default PartialDischarge
