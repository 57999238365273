// organize-imports-ignore
import React, { useState, useEffect } from 'react'
import * as d3 from 'd3'
import classnames from 'classnames'

import PropTypes from 'prop-types'
import { useChartXY } from './ChartXY'
import { useChart } from '../Chart'
import Portal from '../../Portal'
import Typo from '../../Typo'

const TOOLTIP_Y_AXIS = 'yAxis'
const TOOLTIP_POINT = 'point'

const ChartTooltip = ({
  fields,
  tooltipType,
  color,
  width,
  resolveTitle,
  resolveField,
  resolveValue,
  lineColor,
}) => {
  const { chartData } = useChart()
  const otherYKey = chartData?.[0]?.otherY?.key?.[0]
  const lineRef = React.createRef()
  const isArrColor = Array.isArray(color)
  const {
    parentWidth,
    parentHeight,
    interval,
    maxValue,
    minValue,
    otherYMinValue,
    otherYMaxValue,
    y,
    actions: { getDivisionX, getDivisionY, getDivisionOtherY },
  } = useChartXY()

  fields = fields || y

  const ratio = (yMaxValue, yMinValue) => {
    return yMaxValue
      ? (parentHeight - parentHeight / interval) / (yMaxValue - yMinValue)
      : yMaxValue
  }
  const data = getDivisionX()
  const divisionYData = getDivisionY()
  const divisionOtherYData = getDivisionOtherY()

  const [lineFields] = useState(fields)
  const [hoveredItem, setHoveredItem] = useState({})

  const lineData = lineFields.map((key, index) => {
    const valueData = {}
    valueData[key] = []
    const pointData = data.map(item => {
      const data = key === otherYKey ? item.otherYData : item.data
      if (!data) return null
      const typeObj = data.reduce((acc, ele) => {
        if (ele.value !== null || ele.value !== undefined) {
          acc[ele.key] = ele.value
        }
        return acc
      }, {})
      valueData[key].push(typeObj[key])
      const yMaxValue = key === otherYKey ? otherYMaxValue : maxValue
      const yMinValue = key === otherYKey ? otherYMinValue : minValue
      return [
        item.pos,
        parentHeight -
          ((typeObj[key] - yMinValue) * ratio(yMaxValue, yMinValue) || 0),
      ].join(',')
    })
    return {
      name: key,
      value: pointData,
      valueData: valueData[key],
    }
  })

  useEffect(() => {
    const handleTooltipType = (chartSVG, allDataPoint) => {
      const tooltipType = allDataPoint.tooltipType

      if (tooltipType === TOOLTIP_POINT) {
        // Create Event Handlers for mouse on each point
        const handleMouseOverPoint = (event, item) => {
          hoveredItem?.id !== item?.id &&
            item?.pointValue !== null &&
            setHoveredItem({
              ...item,
              x: event.x,
              y: event.y,
              color: allDataPoint.color,
              tooltipType: TOOLTIP_POINT,
            })
        }

        const handleMouseOutPoint = () => {
          hoveredItem?.id && setHoveredItem({})
        }

        // Add circle point for each value
        const circlePointSVG = d3.select(lineRef.current)

        circlePointSVG
          .selectAll(`.circle-${allDataPoint.field}`)
          .data(allDataPoint.data)
          .join('circle')
          .attr('class', `circle-${allDataPoint.field}`)
          .attr('fill', allDataPoint.color)
          .attr('stroke', 'none')
          .attr('cx', d => d.x)
          .attr('cy', d => d.y)
          .attr('r', d => (d.pointValue === null ? 0 : 2))
          .style('cursor', 'pointer')
          .on('mousemove', handleMouseOverPoint)
          .on('mouseleave', handleMouseOutPoint)
      }

      if (tooltipType === TOOLTIP_Y_AXIS) {
        const handleMouseMoveOnChart = event => {
          const coordinates = d3.pointer(event)
          const dataPoints = [...allDataPoint.data]

          if (dataPoints?.length > 0) {
            const checkIsRightSide = index =>
              index >= Math.floor(dataPoints.length / 2)

            const closestPoint = dataPoints.reduce(
              (previousValue, currentValue, index) => {
                const result =
                  Math.abs(currentValue.x - coordinates[0]) <
                  Math.abs(previousValue.x - coordinates[0])
                    ? {
                        ...currentValue,
                        isRightSide: checkIsRightSide(index),
                      }
                    : previousValue
                return result
              },
              {
                ...dataPoints[0],
                isRightSide: checkIsRightSide(0),
              }
            )
            chartSVG
              .selectAll('line')
              .data([closestPoint])
              .join('line')
              .attr('id', d => `yAxisLine-${d.id}`)
              .attr('fill', lineColor || '#4F5A60')
              .attr('stroke', lineColor || '#4F5A60')
              .attr('stroke-width', d => (d.id ? 1 : 0))
              .attr('x1', d => d.x)
              .attr('y1', 0)
              .attr('x2', d => d.x)
              .attr('y2', parentHeight)

            setHoveredItem({
              ...closestPoint,
              x: closestPoint.isRightSide
                ? window.innerWidth - event.x + 20
                : event.x + 20,
              y: event.y + 20,
              xPoint: closestPoint.x,
              tooltipType: TOOLTIP_Y_AXIS,
            })
          }
        }

        const handleMouseOutOnChart = () => {
          d3.select(`#yAxisLine-${hoveredItem?.id}`).remove()
          setHoveredItem({})
        }
        chartSVG
          .selectAll('rect')
          .data(lineData)
          .enter()
          .append('rect')
          .attr('x', 0)
          .attr('y', 0)
          .attr('fill', 'transparent')
          .attr('height', parentHeight)
          .attr('width', parentWidth)

        chartSVG.on('mousemove', handleMouseMoveOnChart)
        chartSVG.on('mouseleave', handleMouseOutOnChart)
      }
    }

    const handleAllDataPoints = () => {
      const allDataPoints = []
      fields.forEach((field, indexField) => {
        const points = lineData[indexField].value.map((dataPoint, index) => {
          const pairPoint = dataPoint.split(',')
          const xData = data[index]
          const filteredValuesData = [
            ...xData.data,
            ...(xData.otherYData || []),
          ].reduce((previousValue, currentValue) => {
            const foundIndex = fields.indexOf(currentValue.key)
            if (foundIndex !== -1) {
              currentValue.color = isArrColor ? color[foundIndex] : color
              currentValue.unit =
                currentValue.key === otherYKey
                  ? divisionOtherYData[0].label
                  : divisionYData[0].label
              previousValue.push(currentValue)
            }
            return previousValue
          }, [])

          return {
            id: index + 1,
            x: Number(pairPoint[0]),
            y: Number(pairPoint[1]),
            dateTime: chartData[index]?.x?.value,
            pointName: lineData[indexField].name,
            pointValue: lineData[indexField].valueData[index],
            pointUnit:
              lineData[indexField].name === otherYKey
                ? divisionOtherYData[0].label
                : divisionYData[0].label,
            allValuesData: filteredValuesData,
          }
        })
        const result = {}
        result.data = points
        result.field = field
        result.color = isArrColor ? color[indexField] : color
        result.tooltipType = tooltipType
        allDataPoints.push(result)
      })
      return allDataPoints
    }

    const allDataPoints = handleAllDataPoints()

    const chartSVG = d3.select(lineRef.current)

    allDataPoints.forEach(allDataPoint => {
      handleTooltipType(chartSVG, allDataPoint)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    color,
    data,
    lineData,
    lineRef,
    parentHeight,
    ratio,
    width,
    chartData,
    fields,
    hoveredItem,
    tooltipType,
    isArrColor,
  ])

  const isTooltip = tooltipType.length > 0

  const renderTooltipType = (data, index = 0) => {
    const isTooltipPoint = hoveredItem?.tooltipType === TOOLTIP_POINT
    const tooltipName = isTooltipPoint ? hoveredItem.pointName : data.key
    const tooltipValue = isTooltipPoint ? hoveredItem.pointValue : data.value
    const tooltipColor = isTooltipPoint ? hoveredItem.color : data.color
    const tooltipUnit = isTooltipPoint ? hoveredItem.pointUnit : data.unit

    const renderTooltipValue = () => {
      switch (tooltipValue) {
        case null:
        case undefined:
          return <>--</>
        default:
          return (
            <>
              {tooltipValue}
              {tooltipUnit}
            </>
          )
      }
    }

    return (
      <div key={index} className="cui-chart__line-chart-tooltip--line-data">
        <div
          className="cui-chart__line-chart-tooltip--circle-color"
          style={{
            backgroundColor: tooltipColor,
          }}
        />
        <Typo className="cui-chart__line-chart-tooltip--name" variant="caption">
          {resolveField ? resolveField(tooltipName, index) : tooltipName}
        </Typo>
        <Typo
          className="cui-chart__line-chart-tooltip--value"
          variant="caption"
        >
          {resolveValue
            ? resolveValue(tooltipValue, index)
            : renderTooltipValue()}
        </Typo>
      </div>
    )
  }

  return (
    <>
      <g ref={lineRef} />
      {isTooltip && hoveredItem.id && (
        <Portal>
          <div
            className={classnames('cui-chart__line-chart-tooltip', {
              rightArrow: hoveredItem.isRightSide,
            })}
            style={{
              top: hoveredItem.y + 25,
              ...(hoveredItem.isRightSide
                ? { right: hoveredItem.x + 8 }
                : {
                    left: hoveredItem.x + 8,
                  }),
            }}
          >
            <>
              <Typo variant="subtitle3">
                {resolveTitle
                  ? resolveTitle(hoveredItem.dateTime, hoveredItem)
                  : hoveredItem.dateTime}
              </Typo>
              {hoveredItem.tooltipType === TOOLTIP_Y_AXIS &&
                hoveredItem.allValuesData?.map((item, index) =>
                  renderTooltipType(item, index)
                )}
              {hoveredItem.tooltipType === TOOLTIP_POINT && renderTooltipType()}
            </>
          </div>
        </Portal>
      )}
    </>
  )
}

ChartTooltip.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fields: PropTypes.array,
  tooltipType: PropTypes.string,
  lineColor: PropTypes.string,
}

ChartTooltip.defaultProps = {
  color: ['#818b92'],
  width: 1,
  tooltipType: TOOLTIP_Y_AXIS,
  lineColor: '#4F5A60',
}

export default ChartTooltip
