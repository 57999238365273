import React from 'react'
import PropTypes from 'prop-types'

class ClockIcon extends React.PureComponent {
  static propTypes = {
    color: PropTypes.array,
  }

  render() {
    return (
      <g
        className="icon-time"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-864.000000, -873.000000)">
          <g transform="translate(321.000000, 757.000000)">
            <g transform="translate(0.000000, 101.000000)">
              <g transform="translate(542.000000, 14.000000)">
                <rect x="0" y="0" width="16" height="16"></rect>
                <g
                  strokeWidth="1"
                  fillRule="evenodd"
                  transform="translate(2.000000, 2.000000)"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path
                    d="M12,6 C12,9.31371429 9.31371429,12 6,12 C2.68628571,12 0,9.31371429 0,6 C0,2.68628571 2.68628571,0 6,0 C9.31371429,0 12,2.68628571 12,6 Z"
                    stroke="#818A91"
                    strokeWidth="2"
                  ></path>
                  <path
                    d="M6,2.99739244 L6,5.99543677"
                    stroke="#818A91"
                    strokeWidth="2"
                  ></path>
                  <path
                    d="M8,7.99413299 L6,5.99543677"
                    stroke="#8C969D"
                    strokeWidth="2"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    )
  }
}

export default ClockIcon
