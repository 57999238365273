import React from 'react'
import PropTypes from 'prop-types'

class RescheduleAppointmentIcon extends React.PureComponent {
  static propTypes = {
    color: PropTypes.array,
  }

  render() {
    return (
      <g
        className="icon-reschedule-appointment"
        stroke="none"
        strokeWidth="1"
        fill={this.props.color[0]}
        fillRule="evenodd"
      >
        <rect
          x="3"
          y="8.8335"
          width="42"
          height="32.6667"
          rx="4"
          fill="#818A91"
        />
        <rect
          x="5.3335"
          y="11.1665"
          width="37.3333"
          height="28"
          rx="2.5"
          fill="white"
        />
        <rect
          x="11.1665"
          y="6.5"
          width="2.33333"
          height="7"
          rx="1"
          fill="#818A91"
        />
        <rect
          x="34.5"
          y="6.5"
          width="2.33333"
          height="7"
          rx="1"
          fill="#818A91"
        />
        <circle cx="12.51" cy="18.2658" r="1.26583" fill="#818A91" />
        <circle cx="12.51" cy="26.5485" r="1.26583" fill="#818A91" />
        <circle cx="12.51" cy="33.4323" r="1.26583" fill="#818A91" />
        <circle cx="20.1047" cy="18.2658" r="1.26583" fill="#818A91" />
        <circle cx="20.1047" cy="26.5485" r="1.26583" fill="#818A91" />
        <circle cx="20.1047" cy="33.4323" r="1.26583" fill="#818A91" />
        <circle cx="27.6994" cy="18.2658" r="1.26583" fill="#818A91" />
        <circle cx="27.6994" cy="33.4323" r="1.26583" fill="#818A91" />
        <circle cx="35.2951" cy="18.2658" r="1.26583" fill="#818A91" />
        <circle cx="35.2951" cy="26.5485" r="1.26583" fill="#818A91" />
        <circle cx="35.2951" cy="33.4323" r="1.26583" fill="#818A91" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.519 30.5341C30.086 30.5341 32.1669 28.4532 32.1669 25.8862C32.1669 23.3192 30.086 21.2383 27.519 21.2383C24.952 21.2383 22.8711 23.3192 22.8711 25.8862C22.8711 28.4532 24.952 30.5341 27.519 30.5341Z"
          fill="url(#paint0_linear)"
        />
        <mask
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="22"
          y="21"
          width="11"
          height="10"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M27.519 30.5341C30.086 30.5341 32.1669 28.4532 32.1669 25.8862C32.1669 23.3192 30.086 21.2383 27.519 21.2383C24.952 21.2383 22.8711 23.3192 22.8711 25.8862C22.8711 28.4532 24.952 30.5341 27.519 30.5341Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.0222 20.5215C30.0222 20.5215 30.3797 28.7447 24.6592 30.5324C18.9387 32.32 38.1044 30.5324 38.1044 30.5324L30.0222 20.5215Z"
            fill="url(#paint1_linear)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32.1672 23.0254C32.1672 23.0254 30.022 29.461 24.3015 31.2486C18.581 33.0363 39.5344 31.2486 39.5344 31.2486L32.1672 23.0254Z"
            fill="url(#paint2_linear)"
          />
        </g>
        <rect
          x="30.1963"
          y="23.3042"
          width="1.48354"
          height="5.93417"
          rx="0.5"
          transform="rotate(45 30.1963 23.3042)"
          fill="white"
        />
        <rect
          x="28.0991"
          y="27.4995"
          width="1.48354"
          height="4.45063"
          rx="0.5"
          transform="rotate(135 28.0991 27.4995)"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="21.1692"
            y1="25.2676"
            x2="26.7398"
            y2="28.6715"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F5B987" />
            <stop offset="1" stopColor="#FF718F" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="28.9888"
            y1="26.6086"
            x2="30.2817"
            y2="28.0238"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D35F9B" />
            <stop offset="1" stopColor="#3C6DBF" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="29.5308"
            y1="28.0456"
            x2="30.3388"
            y2="29.4211"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#40B8FF" />
            <stop offset="1" stopColor="#89FCFF" />
          </linearGradient>
        </defs>
      </g>
    )
  }
}

export default RescheduleAppointmentIcon
