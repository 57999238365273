import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

function LayoutItem({
  children,
  contain,
  fill,
  spanX,
  spanY,
  spanOffset,
  className,
  valign,
  halign,
  valignSelf,
  halignSelf,
  flow,
  style,
}) {
  const _LayoutItem = classnames('cui-layout__item', className, {
    'flow-col': flow === 'column',
    'flow-row': flow === 'row',
    contained: contain,
    'x-fill': (!spanX && fill) || (spanX && spanX < 1 && fill),
    [`y-${spanY * (spanOffset || 1)}`]: spanY && spanY > 0,
    [`x-${spanX * (spanOffset || 1)}`]: spanX && spanX > 0,
    [`valign-${valign}`]: valign,
    [`halign-${halign}`]: halign,
    [`valign-self-${valignSelf}`]: valignSelf,
    [`halign-self-${halignSelf}`]: halignSelf,
  })

  const renderLayoutContent = () => {
    return <div className="container">{children}</div>
  }

  return (
    <div className={_LayoutItem} style={style}>
      {renderLayoutContent()}
    </div>
  )
}

LayoutItem.propTypes = {
  contain: PropTypes.bool,
  fill: PropTypes.bool,
  spanX: PropTypes.number,
  spanY: PropTypes.number,
  spanOffset: PropTypes.number,
  valign: PropTypes.string,
  halign: PropTypes.string,
  valignSelf: PropTypes.string,
  halignSelf: PropTypes.string,
  flow: PropTypes.string,
  style: PropTypes.object,
}

LayoutItem.defaultProps = {
  contain: true,
  fill: true,
  spanX: 0,
  spanY: 1,
  spanOffset: 1,
  valign: 'center',
  halign: 'center',
  valignSelf: null,
  halignSelf: null,
  flow: 'column',
}

export default LayoutItem
