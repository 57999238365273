import React from 'react'
import classnames from 'classnames'

import { utils } from '../Tools'

import ScreenSize from '../ScreenSize'

import ListHeader from './ListHeader'
import ListItem from './ListItem'
import ListFilter from './ListFilter'
import ListSort from './ListSort'
import Typo from '../Typo'
import Icons from '../Icons'
import { useTheme } from '../Theme'

import './styles.scss'

function extractHeaderData(data) {
  let headerData = []
  if (data.length > 0) {
    const keys = Object.keys(data[0])
    headerData = keys.map(key => ({
      key,
      node: key,
    }))
    return headerData
  }
  return headerData
}

const List = ({
  id,
  data: dataProp,
  itemResolver,
  headerResolver,
  mobileItemResolver,
  emptyComponent,
  toggleInnerContent,
  className,
  options,
  styles,
  filterData,
  sortData,
  onFilterChange,
  onFilterSubmit,
  onSortSubmit,
  onSort,
  config,
  groupsMobileResolver,
  mobileLayout,
  primaryKey,
  isNested,
}) => {
  const theme = useTheme()
  const { useScreenSize } = ScreenSize

  const screenSize = useScreenSize()
  const mobileBreakpoint = 768
  const isMobile = screenSize.width < mobileBreakpoint
  const [data, setData] = React.useState(dataProp)
  const [sortBy, setSortBy] = React.useState([])

  React.useEffect(() => {
    setData(dataProp)
  }, [dataProp])

  const sortHandler = field => () => {
    let newSortBy = [...sortBy]
    const index = sortBy.findIndex(s => s.field === field)
    if (index !== -1) {
      const fieldSortBy = { ...sortBy[index] }
      if (fieldSortBy.order === 'asc') {
        fieldSortBy.order = 'desc'
        newSortBy[index] = fieldSortBy
      } else {
        newSortBy.splice(index, 1)
      }
    } else {
      newSortBy = [
        {
          field,
          order: 'asc',
        },
        ...newSortBy,
      ]
    }

    setSortBy(newSortBy)

    if (onSort) {
      onSort(newSortBy)
    } else {
      const sorted = utils.sortBy([...dataProp], newSortBy)
      setData(sorted)
    }
  }

  const renderHeader = () => {
    if (data.length > 0) {
      const headerData = extractHeaderData(data, options.items)
      return (
        <ListHeader
          id={id}
          data={headerData}
          resolver={headerResolver}
          toggleInner={toggleInnerContent}
          options={options}
          sortHandler={sortHandler}
          sortables={config?.sortables}
          sensitives={config?.sensitives}
          primaryKey={primaryKey}
          sortBy={sortBy}
        />
      )
    }
    return null
  }

  const renderListFunc = () => {
    const { filter, sort } = config || {}
    return (
      <div className="cui-list__func">
        {filter?.data?.length > 0 && (
          <ListFilter
            data={filter?.data}
            status={filter?.status}
            onFilterChange={onFilterChange}
            onFilterSubmit={onFilterSubmit}
            isMobile={isMobile}
          />
        )}
        {isMobile && sort?.data?.length > 0 && (
          <ListSort
            data={sort?.data}
            onSortSubmit={onSortSubmit}
            spec={{ name: 'sort' }}
          />
        )}
      </div>
    )
  }

  const renderItems = (forMobile = false, groupData = data) => {
    if (groupData.length > 0) {
      return (
        <div className="cui-list__items">
          {groupData.map((d, idx) => {
            let itemData = d

            if (!forMobile && itemResolver) {
              itemData = {
                ...itemResolver(d, idx),
              }
            }
            if (forMobile && mobileItemResolver) {
              itemData = {
                ...mobileItemResolver(d, idx),
              }
            }

            return (
              <ListItem
                key={`cui-list__item-${id}_${idx}`}
                content={itemData}
                toggleInner={toggleInnerContent}
                options={options}
                primaryKey={primaryKey}
              />
            )
          })}
        </div>
      )
    }
    if (emptyComponent) {
      return emptyComponent
    }
    return 'You have an empty list.'
  }

  const renderMobileList = groupData => {
    return <>{renderItems(true, groupData)}</>
  }

  const renderList = () => {
    if (isMobile && mobileItemResolver) {
      if (groupsMobileResolver) {
        const groups = groupsMobileResolver(data)
        if (groups.length === 0) {
          return renderMobileList()
        }
        return (
          <>
            {groups.map(group => {
              return (
                <React.Fragment key={group.title}>
                  <div className="cui-list__group-title">
                    <Typo isBold variant="body1">
                      {group.title}
                    </Typo>
                  </div>
                  {renderMobileList(group.data)}
                </React.Fragment>
              )
            })}
          </>
        )
      } else {
        return renderMobileList()
      }
    }

    return (
      <>
        {renderHeader()}
        {renderItems()}
      </>
    )
  }

  const _List = classnames(`cui-list`, className, {
    'use-mobile-layout': mobileLayout,
    nested: isNested,
  })
  return (
    <>
      <div
        className={_List}
        style={{
          '--cuiListPrimary': theme.colors.primary.main,
          '--cuiListItemBg': theme.colors.background.level1,
          '--cuiListTextPrimary': theme.colors.text.primary,
          '--cuiListTextSecondary': theme.colors.text.secondary,
          '--cuiListShadow': theme.colors.boxShadow,
          '--cuiListDivider': theme.colors.grey[200],
          ...(styles || {}),
        }}
      >
        {renderListFunc()}
        <div className="cui-list__container">
          <div className="cui-list__list">{renderList()}</div>
        </div>
      </div>
    </>
  )
}

List.defaultProps = {
  data: [],
  filterData: [],
  sortData: [],
  itemResolver: d => {
    return d
  },
  headerResolver: h => {
    return h
  },
  mobileItemResolver: null,
  className: '""',
  emptyComponent: null,
  toggleInnerContent: false,
  options: {
    items: [],
    styles: {},
  },
  sortConfig: null,
  mobileLayout: false,
}

const ListWithScreenProvider = Comp => {
  return class extends React.PureComponent {
    render() {
      const { ...compProps } = this.props
      const { ScreenSizeProvider } = ScreenSize
      return (
        <ScreenSizeProvider>
          <Comp {...compProps} />
        </ScreenSizeProvider>
      )
    }
  }
}

export default ListWithScreenProvider(List)
