import React, { useState, useLayoutEffect, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useFormikContext } from 'formik'

import Icons from '../../Icons'
import FieldError from '../FieldError'
import FieldLabel from '../FieldLabel'
import FieldContent from '../FieldContent'
import { Hint } from '../FieldMisc'
import { useTheme } from '../../Theme'

const FieldSelection = ({
  children,
  options,
  placeholder,
  spec,
  disabled,
  onBlur,
  onFocus,
  onChange,
  errors = {},
  disabledIfOneOption,
  defaultSelected,
}) => {
  const theme = useTheme()
  const { values, setFieldValue, errors: formikErrors } =
    useFormikContext() || {}

  const [stateError, setError] = useState(
    errors[spec.name] || formikErrors[spec.name]
  )

  const _FieldSelection = classnames(
    'lm--input lm--select cui-field cui-field_selection'
  )
  const { innerWidth, innerHeight } = window

  const selectionListRef = React.createRef()
  const selectionListViewRef = React.createRef()
  const selectionViewRef = React.createRef()

  const [windowWidth, setWidth] = useState(innerWidth)
  const [windowHeight, setHeight] = useState(innerHeight)
  const [openList, setOpenList] = useState(false)
  const [selectedOption, setSelectedOption] = useState(values[spec.name])

  const selectedOptionItem =
    options.find(option => option.value === selectedOption) || selectedOption

  useEffect(() => {
    if (defaultSelected) {
      setSelectedOption(defaultSelected)
    } else {
      setSelectedOption(values[spec.name])
    }
  }, [defaultSelected, spec.name, values])

  useEffect(() => {
    const handleClickOutside = e => {
      if (
        openList &&
        selectionViewRef.current &&
        !selectionViewRef.current.contains(e.target)
      ) {
        setOpenList(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [openList, selectionViewRef])

  const renderFieldSelection = () => {
    if (disabledIfOneOption && options.length === 1) {
      return <input type="text" disabled value={options[0].label} />
    }

    return (
      <>
        <select
          id={spec.id}
          name={spec.name}
          defaultValue={values[spec.name]}
          className={_FieldSelection}
          ref={selectionListRef}
          disabled={disabled}
        >
          <option value="">select one</option>
          {options.map(option => {
            return (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            )
          })}
        </select>
        <div
          className={classnames('cui-field_selection--view', {
            open: openList,
            'is-disabled': disabled,
          })}
          ref={selectionViewRef}
        >
          <div className="selected" onClick={handleChooseSelected}>
            {selectedOptionItem?.label || placeholder}
            <span className="icon-caret">
              <Icons.Caret.Down
                width={14}
                height={8}
                color={[theme.colors.icon]}
              />
            </span>
          </div>
          <div className="selection-list" ref={selectionListViewRef}>
            <div className="selection-placeholder">{placeholder}</div>
            {options.map(option => {
              return (
                <div
                  key={option.value}
                  className="selection-item"
                  data-testid="selection-option-item"
                  onClick={() => handleChangeOption(option.value)}
                  onFocus={onFocus}
                  onBlur={onBlur}
                >
                  {option.label}
                  {selectedOption === option.value && (
                    <span className="selected-icon">
                      <Icons.Checked width={20} height={20} />
                    </span>
                  )}
                </div>
              )
            })}
          </div>
          <div className="back-drop" onClick={() => setOpenList(false)} />
        </div>
      </>
    )
  }

  const renderFieldMisc = () => {
    return React.Children.map(children, (child, childIdx) => {
      if (child.type === Hint) {
        return child
      }
    })
  }

  const renderField = () => {
    const label = React.Children.map(children, (child, childIdx) => {
      if (child.type === FieldLabel) {
        return <FieldLabel {...child.props} />
      }
      return null
    })

    const fieldContent = React.Children.map(children, (child, childIdx) => {
      if (
        child.type === FieldContent &&
        selectedOption === child.props.selectedOption
      ) {
        return <FieldContent {...child.props} />
      }
      return null
    })
    return (
      <div className={_FieldSelection}>
        {label}
        <div
          className={classnames('cui-field_control', {
            'full-width': React.Children.count(children) === 0,
          })}
        >
          {renderFieldSelection()}
          {fieldContent}
          {renderFieldMisc()}
        </div>
      </div>
    )
  }

  const handleChooseSelected = () => {
    if (!disabled) {
      setOpenList(!openList)
    }
  }

  const handleChangeOption = value => {
    const selectedValue = options.find(option => option.value === value).value
    selectionListRef.current.value = value
    setOpenList(!openList)
    setSelectedOption(selectedValue)

    if (onChange) {
      onChange({ [spec.name]: value })
      setFieldValue(spec.name, value)
    } else {
      setFieldValue(spec.name, value)
    }

    setError(null)
  }

  useLayoutEffect(() => {
    const updateWindowWidth = e => {
      const resizedWindowWidth = e && e.currentTarget.innerWidth
      const resizedWindowHeight = e && e.currentTarget.innerHeight
      if (resizedWindowWidth && resizedWindowWidth) {
        setWidth(resizedWindowWidth)
        setHeight(resizedWindowHeight)
      }
    }
    updateWindowWidth()
    window.addEventListener('resize', updateWindowWidth)
    return () => {
      window.removeEventListener('resize', updateWindowWidth)
    }
  }, [windowWidth])

  useEffect(() => {
    if (openList) {
      const selectionFieldOffsetBottom =
        windowHeight - selectionViewRef.current.getBoundingClientRect().bottom

      const selectionListHeight = selectionListViewRef.current.getBoundingClientRect()
        .height
      if (selectionListHeight > selectionFieldOffsetBottom) {
        selectionListViewRef.current.style.top = `-${selectionListHeight}px`
      } else {
        selectionListViewRef.current.style.top = '100%'
      }
    }
  }, [
    openList,
    options,
    selectionListViewRef,
    selectionViewRef,
    windowHeight,
    windowWidth,
  ])

  useEffect(() => {
    if (errors[spec.name]) {
      setError(errors[spec.name])
    }
  }, [errors, setError])

  return (
    <div className="lm--input-group cui-field_group" id={spec.id}>
      {renderField()}
      {stateError && <FieldError>{stateError}</FieldError>}
    </div>
  )
}

FieldSelection.propTypes = {
  options: PropTypes.array,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  values: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  spec: {
    name: PropTypes.string,
    id: PropTypes.string,
  },
  disabledIfOneOption: PropTypes.bool,
}

export default FieldSelection
