import React from 'react'
import Printable from './Printable'
import PropTypes from 'prop-types'
const PrintableButton = ({ children }) => {
  const printableCtx = React.useContext(Printable.Context)
  const { actions } = printableCtx

  const handleClick = () => {
    if (actions.print) {
      actions.print()
    }
  }

  return (
    <button
      type="button"
      style={{ appearance: 'none', padding: '0', outline: 'none' }}
      onClick={handleClick}
    >
      {children}
    </button>
  )
}

PrintableButton.propTypes = {
  children: PropTypes.node,
}

export default PrintableButton
