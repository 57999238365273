import React from 'react'
import PropTypes from 'prop-types'

const PopupTitle = ({ children }) => {
  return <div className="cui-popup__title">{children}</div>
}

PopupTitle.propTypes = {
  children: PropTypes.node,
}

export default PopupTitle
