import React from 'react'

const Substation = () => {
  return (
    <>
      <g className="illus-substation">
        <path
          d="M214.84 33.5771H111.673V101.527L214.84 113V33.5771Z"
          fill="#969B91"
        />
        <path
          d="M111.076 41.8711H215V44.6229H111.076V41.8711Z"
          fill="#818A91"
        />
        <path
          d="M111.564 100.544H138.773V112.731H111.564V100.544Z"
          fill="#818A91"
        />
        <path d="M136.214 33.5771H215V113H136.214V33.5771Z" fill="#DFDED2" />
        <path d="M136.214 100.731H215V113H136.214V100.731Z" fill="#E7E9EA" />
        <path
          d="M136.214 100.731H215V102.669H136.214V100.731Z"
          fill="#ABB2A4"
        />
        <path d="M136.214 41.9717H215V44.5545H136.214V41.9717Z" fill="white" />
        <path
          d="M136.214 30.9941H215V40.0341H136.214V30.9941Z"
          fill="#818A91"
        />
        <path d="M134.922 30.9941H215V38.097H134.922V30.9941Z" fill="white" />
        <path
          d="M134.276 97.5029H215V100.731H134.276V97.5029Z"
          fill="#F6F6F6"
        />
        <path
          opacity="0.2"
          d="M175.009 30.9941H191.154L188.57 113H175.009V30.9941Z"
          fill="#4F5A60"
        />
        <path
          d="M149.177 100.731H173.717V102.669H149.177V100.731Z"
          fill="#8E8E8E"
        />
        <path
          d="M148.532 30.9941H172.426V38.097H148.532V30.9941Z"
          fill="#818A91"
        />
        <path
          d="M149.177 38.0967H173.717V40.0338H149.177V38.0967Z"
          fill="#7B868B"
        />
        <path
          d="M159.587 50.9775V98.7746H154.062L153.95 50.9775H159.587Z"
          fill="#462D3E"
        />
        <path
          d="M159.587 53.2051V99.6589H156.261L156.13 53.2051H159.587Z"
          fill="url(#illus-substation_paint0_linear)"
        />
        <path
          d="M153.217 50.9775H154.626V98.7746H153.217V50.9775Z"
          fill="#6B6B6B"
        />
        <path
          d="M163.083 50.9775H168.551V98.7746H163.083V50.9775Z"
          fill="#462D3E"
        />
        <path
          d="M168.551 53.2051V99.102H165.225L165.263 53.2051H168.551Z"
          fill="url(#illus-substation_paint1_linear)"
        />
        <path
          d="M162.181 50.9775H163.59V98.7746H162.181V50.9775Z"
          fill="#6B6B6B"
        />
        <path
          d="M147.886 97.5029H171.78V100.731H147.886V97.5029Z"
          fill="#818A91"
        />
        <rect x="107.847" width="79.4321" height="8.39429" fill="white" />
        <rect
          width="46.4968"
          height="8.39429"
          transform="matrix(-1 0 0 1 107.847 0)"
          fill="#818A91"
        />
        <rect
          x="63.2876"
          y="8.39453"
          width="121.408"
          height="104.606"
          fill="#969B91"
        />
        <rect
          x="104.618"
          y="8.39453"
          width="80.0779"
          height="104.606"
          fill="#DFDED2"
        />
        <rect
          width="80.0779"
          height="1.29143"
          transform="matrix(1 0 0 -1 104.618 9.68555)"
          fill="#818A91"
        />
        <rect
          width="80.0779"
          height="2.58286"
          transform="matrix(1 0 0 -1 104.618 15.4971)"
          fill="white"
        />
        <rect
          x="104.618"
          y="15.4971"
          width="40.6847"
          height="2.58286"
          transform="rotate(-180 104.618 15.4971)"
          fill="#818A91"
        />
        <rect
          x="104.618"
          y="9.68555"
          width="40.6847"
          height="1.29143"
          transform="rotate(-180 104.618 9.68555)"
          fill="#7B868B"
        />
        <path
          d="M104.458 33.5771H1.29028V101.527L104.458 113V33.5771Z"
          fill="#969B91"
        />
        <path
          d="M0.693497 41.8711H104.618V44.6229H0.693497V41.8711Z"
          fill="#818A91"
        />
        <path
          d="M1.18207 100.544H28.3911V112.731H1.18207V100.544Z"
          fill="#818A91"
        />
        <path
          d="M25.8313 33.5771H104.618V113H25.8313V33.5771Z"
          fill="#DFDED2"
        />
        <path
          d="M25.8313 100.731H104.618V113H25.8313V100.731Z"
          fill="#E7E9EA"
        />
        <path
          d="M25.8313 100.731H104.618V102.669H25.8313V100.731Z"
          fill="#ABB2A4"
        />
        <path
          d="M1.29154 100.731H25.8315V102.669H1.29154V100.731Z"
          fill="#8E8E8E"
        />
        <path
          d="M25.8313 41.9717H104.618V44.5545H25.8313V41.9717Z"
          fill="white"
        />
        <path
          d="M25.8313 30.9941H104.618V40.0341H25.8313V30.9941Z"
          fill="#818A91"
        />
        <path
          d="M24.5403 30.9941H104.618V38.097H24.5403V30.9941Z"
          fill="white"
        />
        <path
          d="M0.645826 30.9941H24.54V38.097H0.645826V30.9941Z"
          fill="#818A91"
        />
        <path
          d="M1.29154 38.0967H25.8315V40.0338H1.29154V38.0967Z"
          fill="#7B868B"
        />
        <path
          d="M11.7017 50.9775V98.7746H6.17675L6.064 50.9775H11.7017Z"
          fill="#462D3E"
        />
        <path
          d="M11.7017 53.2051V99.6589H8.37545L8.2439 53.2051H11.7017Z"
          fill="url(#illus-substation_paint2_linear)"
        />
        <path
          d="M5.33133 50.9775H6.74072V98.7746H5.33133V50.9775Z"
          fill="#6B6B6B"
        />
        <path
          d="M15.1965 50.9775H20.665V98.7746H15.1965V50.9775Z"
          fill="#462D3E"
        />
        <path
          d="M20.665 53.2051V99.102H17.3388L17.3764 53.2051H20.665Z"
          fill="url(#illus-substation_paint3_linear)"
        />
        <path
          d="M14.2947 50.9775H15.7041V98.7746H14.2947V50.9775Z"
          fill="#6B6B6B"
        />
        <path
          d="M23.894 97.5029H104.618V100.731H23.894V97.5029Z"
          fill="#F6F6F6"
        />
        <path
          d="M184.696 100.731H104.618V113H184.696V100.731Z"
          fill="#E7E9EA"
        />
        <path
          d="M184.696 100.731H104.618V102.669H184.696V100.731Z"
          fill="#ABB2A4"
        />
        <path
          d="M185.987 97.5029H104.618V100.731H185.987V97.5029Z"
          fill="#F6F6F6"
        />
        <path
          d="M-0.000171661 97.5029H23.894V100.731H-0.000171661V97.5029Z"
          fill="#818A91"
        />
        <path
          d="M45.8294 76.3105H86.0308V112.69H45.8294V76.3105Z"
          fill="url(#illus-substation_paint4_linear)"
        />
        <path
          d="M87.1348 67.1543V112.731H81.5728V77.3008H46.9334V112.731H41.3306V67.1543H87.1348Z"
          fill="#818A91"
        />
        <path
          d="M80.2233 76.9707H84.1494V112.731H80.2233V76.9707Z"
          fill="#818A91"
        />
        <path
          d="M88.8525 67.1543V112.731H83.2497V77.3008H48.6103V112.731H43.0075V67.1543H88.8525Z"
          fill="white"
        />
        <path
          d="M80.1411 112.73H48.569V77.2588H80.1411V112.73Z"
          fill="url(#illus-substation_paint5_linear)"
        />
        <path
          d="M77.4013 101.264C77.933 101.264 78.3828 100.81 78.3828 100.274V96.0671C78.3828 95.5309 77.933 95.0771 77.4013 95.0771C76.8696 95.0771 76.4198 95.5309 76.4198 96.0671V100.274C76.4198 100.81 76.8287 101.264 77.4013 101.264Z"
          fill="#818A91"
        />
        <path
          d="M73.5167 97.0154H77.0747C77.2383 97.0154 77.4019 96.8917 77.4019 96.6854C77.4019 96.5205 77.2792 96.3555 77.0747 96.3555H73.5167C73.3531 96.3555 73.1895 96.4792 73.1895 96.6854C73.1895 96.8917 73.3531 97.0154 73.5167 97.0154Z"
          fill="white"
        />
        <path
          d="M136.886 58.2305H177.087V94.6094H136.886V58.2305Z"
          fill="url(#illus-substation_paint6_linear)"
        />
        <path
          d="M178.191 49.0742V94.6511H172.629V59.2207H137.99V94.6511H132.387V49.0742H178.191Z"
          fill="#818A91"
        />
        <path
          d="M171.279 58.8906H175.206V94.6509H171.279V58.8906Z"
          fill="#818A91"
        />
        <path
          d="M179.909 49.0742V94.6511H174.306V59.2207H139.666V94.6511H134.064V49.0742H179.909Z"
          fill="white"
        />
        <path
          d="M171.197 94.6503H139.625V59.1787H171.197V94.6503Z"
          fill="url(#illus-substation_paint7_linear)"
        />
        <path
          d="M168.457 83.184C168.989 83.184 169.439 82.7303 169.439 82.1941V77.987C169.439 77.4508 168.989 76.9971 168.457 76.9971C167.926 76.9971 167.476 77.4508 167.476 77.987V82.1941C167.476 82.7303 167.885 83.184 168.457 83.184Z"
          fill="#818A91"
        />
        <path
          d="M164.573 78.9353H168.131C168.294 78.9353 168.458 78.8116 168.458 78.6054C168.458 78.4404 168.335 78.2754 168.131 78.2754H164.573C164.409 78.2754 164.246 78.3991 164.246 78.6054C164.246 78.8116 164.409 78.9353 164.573 78.9353Z"
          fill="white"
        />
        <path
          opacity="0.3"
          d="M185.342 75.2252V93.6281H128.835L100.743 112.031H94.9312V95.5652L129.804 74.9023L185.342 75.2252Z"
          fill="#7FA7CB"
        />
        <path
          d="M129.804 74.2568H185.987V75.5483H129.804L94.2852 96.2111V94.9197L129.804 74.2568Z"
          fill="#BFC9CF"
        />
        <rect
          x="94.2852"
          y="94.9199"
          width="1.29158"
          height="15.4971"
          fill="#818A91"
        />
        <rect
          x="184.696"
          y="74.2568"
          width="1.29158"
          height="16.1429"
          fill="#818A91"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M125.283 90.4004H130.449H185.664V96.5347H130.449L103.326 113H99.4515H94.2852V109.772H99.4515V109.805C99.4689 109.794 99.4864 109.783 99.5039 109.772H99.4515V106.543H104.618V106.579L104.675 106.543H104.618V103.315H109.784V103.348L109.837 103.315H109.784V100.086H114.95V100.114L114.994 100.086H114.95V96.8575H120.117V96.8776L120.149 96.8575H120.117V93.629H125.283V93.6398C125.289 93.6362 125.295 93.6326 125.3 93.629H125.283V90.4004Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="illus-substation_paint0_linear"
          x1="157.862"
          y1="118.863"
          x2="157.862"
          y2="37.4381"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#587888" />
          <stop offset="1" stopColor="#462D3E" />
        </linearGradient>
        <linearGradient
          id="illus-substation_paint1_linear"
          x1="166.892"
          y1="118.07"
          x2="166.892"
          y2="37.6287"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#587888" />
          <stop offset="1" stopColor="#462D3E" />
        </linearGradient>
        <linearGradient
          id="illus-substation_paint2_linear"
          x1="9.9762"
          y1="118.863"
          x2="9.9762"
          y2="37.4381"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#587888" />
          <stop offset="1" stopColor="#462D3E" />
        </linearGradient>
        <linearGradient
          id="illus-substation_paint3_linear"
          x1="19.0055"
          y1="118.07"
          x2="19.0055"
          y2="37.6287"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#587888" />
          <stop offset="1" stopColor="#462D3E" />
        </linearGradient>
        <linearGradient
          id="illus-substation_paint4_linear"
          x1="65.9407"
          y1="121.815"
          x2="65.9407"
          y2="81.4756"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#587888" />
          <stop offset="1" stopColor="#462D3E" />
        </linearGradient>
        <linearGradient
          id="illus-substation_paint5_linear"
          x1="64.3538"
          y1="126.754"
          x2="64.3538"
          y2="52.966"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A6366" />
          <stop offset="1" />
        </linearGradient>
        <linearGradient
          id="illus-substation_paint6_linear"
          x1="156.997"
          y1="103.735"
          x2="156.997"
          y2="63.3955"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#587888" />
          <stop offset="1" stopColor="#462D3E" />
        </linearGradient>
        <linearGradient
          id="illus-substation_paint7_linear"
          x1="155.41"
          y1="108.674"
          x2="155.41"
          y2="34.8859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5A6366" />
          <stop offset="1" />
        </linearGradient>
      </defs>
    </>
  )
}

export default Substation
