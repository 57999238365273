import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const FieldPrefix = ({ children, smallwidth, value, ...props }) => {
  const _FieldPrefix = classnames('cui-field_prefix', {
    'small-width': smallwidth,
  })
  return (
    <input
      className={_FieldPrefix}
      type="text"
      placeholder={children}
      defaultValue={value}
      {...props}
    />
  )
}

FieldPrefix.propTypes = {
  smallwidth: PropTypes.bool,
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
}
FieldPrefix.defaultProps = {
  smallwidth: false,
}

export default FieldPrefix
