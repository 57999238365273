import React from 'react'
import classnames from 'classnames'

const FieldHint = ({ children, content }) => {
  const _FieldHint = classnames('lm--field-hint cui-field_hint')
  return (
    <div className={_FieldHint}>
      {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
      {children}
    </div>
  )
}

export default FieldHint
