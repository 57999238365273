import React from 'react'
import PropTypes from 'prop-types'

class FilterIcon extends React.PureComponent {
  static propTypes = {
    color: PropTypes.array,
  }

  render() {
    return (
      <g
        className="icon-filter"
        stroke="none"
        strokeWidth="1"
        fill={this.props.color[0]}
        fillRule="evenodd"
      >
        <path
          d="M4 6C4.74025 6 5.38657 6.40217 5.73239 6.99993L11 7L11.1166 7.00673C11.614 7.06449 12 7.48716 12 8C12 8.55228 11.5523 9 11 9L5.73181 9.00108C5.38585 9.59828 4.73984 10 4 10C3.26016 10 2.61415 9.59828 2.26819 9.00108L1 9L0.883379 8.99327C0.38604 8.93551 0 8.51284 0 8C0 7.44772 0.447715 7 1 7L2.26761 6.99993C2.61343 6.40217 3.25975 6 4 6ZM4 7C3.44772 7 3 7.44772 3 8C3 8.55228 3.44772 9 4 9C4.55228 9 5 8.55228 5 8C5 7.44772 4.55228 7 4 7ZM8 0C8.74025 0 9.38657 0.402166 9.73239 0.999928L11 1C11.5523 1 12 1.44772 12 2C12 2.51284 11.614 2.93551 11.1166 2.99327L11 3L9.73181 3.00108C9.38585 3.59828 8.73984 4 8 4C7.26016 4 6.61415 3.59828 6.26819 3.00108L1 3C0.447715 3 0 2.55228 0 2C0 1.48716 0.38604 1.06449 0.883379 1.00673L1 1L6.26761 0.999928C6.61343 0.402166 7.25975 0 8 0ZM8 1C7.44772 1 7 1.44772 7 2C7 2.55228 7.44772 3 8 3C8.55228 3 9 2.55228 9 2C9 1.44772 8.55228 1 8 1Z"
          fill={this.props.color[0]}
        />
      </g>
    )
  }
}

export default FilterIcon
