import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import FieldContent from '../FieldContent'

const FieldNote = props => {
  const _FieldNote = classnames('lm--note cui-field-note', {
    'lm--note__full-width cui-field-note__full-width': props.fullWidth,
  })
  const _FieldGroup = classnames(
    'cui-note-field_control lm--note-input-control',
    {
      'cui-note-field_control__full-width lm--note-input-control__full-width':
        props.fullWidth,
    }
  )
  return (
    <div className={_FieldNote}>
      <div className={_FieldGroup}>
        <FieldContent>{props.children}</FieldContent>
      </div>
    </div>
  )
}

FieldNote.propTypes = {
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  fullWidth: PropTypes.bool,
}

export default FieldNote
