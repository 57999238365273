// organize-imports-ignore
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { useTheme } from '../Theme'
import AccordionItem from './AccordionItem'
import AccordionContent from './AccordionContent'
import AccordionTitle from './AccordionTitle'

import './styles.scss'

const Accordion = ({ className, multiple, children, style }) => {
  const [preExpand, setPreExpand] = React.useState([])

  const _Accordion = classnames('cui-accordion', className)

  const theme = useTheme()

  const renderAccordionItem = () => {
    if (!children) return null
    return React.Children.map(children, (child, idx) => {
      return React.cloneElement(child, {
        ...child.props,
        isActive: preExpand.includes(idx),
        index: idx,
        multiple: multiple,
        onToggleAccordion: () =>
          setPreExpand(preExpand.includes(idx) ? [] : [idx]),
        isDefaultExpand: preExpand.includes(idx),
        setDefaultExpand: id => setPreExpand(prevArr => [...prevArr, id]),
      })
    })
  }
  return (
    <div
      className={_Accordion}
      style={{
        '--cuiAccordionBg': theme.colors.card.background,
        '--cuiAccordionShadow': theme.colors.boxShadow,
        ...style,
      }}
      data-testid="cui-accordion"
    >
      {renderAccordionItem()}
    </div>
  )
}

Accordion.Item = AccordionItem
Accordion.Title = AccordionTitle
Accordion.Content = AccordionContent

Accordion.propTypes = {
  className: PropTypes.string,
  multiple: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.object,
}

Accordion.defaultProps = {
  multiple: false,
}
export default Accordion
