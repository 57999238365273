import React from 'react'
import PropTypes from 'prop-types'

class AdvancedMeterIcon extends React.PureComponent {
  static propTypes = {
    color: PropTypes.array,
  }

  render() {
    return (
      <svg
        width="16"
        height="18"
        viewBox="0 0 16 18"
        fill={this.props.color[0]}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.01006 15.8578L5.847 9.99924H2.00001C1.04785 9.99924 0.634482 8.79446 1.38607 8.20989L10.3861 1.20989C11.1049 0.650771 12.1361 1.26533 11.9864 2.16364L11.1805 6.99924H14C14.8909 6.99924 15.3371 8.07639 14.7071 8.70635L6.70712 16.7064C6.02758 17.3859 4.87415 16.8092 5.01006 15.8578ZM10 8.99924C9.38207 8.99924 8.91203 8.44438 9.01361 7.83485L9.59263 4.36073L4.91455 7.99924H7.00001C7.60856 7.99924 8.07602 8.53823 7.98996 9.14067L7.41422 13.1708L11.5858 8.99924H10Z"
          fill="#00B0B2"
        />
      </svg>
    )
  }
}

export default AdvancedMeterIcon
