import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

import Payment from './Payment'
import MerlionCharge from './MerlionCharge'
import WorkInProgress from './WorkInProgress'
import SubmitMeter from './SubmitMeter'
import HLAShield from './HLAShield'
import UsingApp from './UsingApp'
import EnergyConsumption from './EnergyConsumption'
import EligiblePayment from './EligiblePayment'
import Ecommerce from './Ecommerce'
import Fail from './Fail'

import SpotApplication from './SpotApplication'
import Co2 from './Co2'
import SPApp from './SPApp'
import DownloadPhone from './DownloadPhone'
import SPAppQrCode from './SPAppQrCode'
import Notify from './Notify'
import SocialShare from './SocialShare'
import PaperBill from './PaperBill'
import NotFound from './NotFound'
import Substation from './Substation'
import Transformer from './Transformer'
import Switchgear from './Switchgear'
import LVB from './LVB'
import DuvalTriangle from './DuvalTriangle'
import DigitalTwin from './DigitalTwin'
import PartialDischarge from './PartialDischarge'
import Success from './Success'
import GraphFade from './GraphFade'
import PayNow from './PayNow'
import PayNowQR from './PayNowQR'
import Splash from './Splash'

const Illustrations = (IllustrationComponent, CustomProps) => {
  return class extends PureComponent {
    static defaultProps = {
      stroke: 'none',
      addToDimension: true,
      className: 'cui-illustration',
    }

    static propTypes = {
      width: PropTypes.number,
      height: PropTypes.number,
    }

    render() {
      const { width, height, ...rest } = this.props
      const { viewBox: cViewBox, ...customRest } = CustomProps
      let viewBox = `0 0 ${width} ${height}`

      if (rest.viewBox && rest.viewBox !== '') {
        viewBox = rest.viewBox
      } else if (cViewBox && cViewBox !== '') {
        viewBox = cViewBox
      }

      if (!rest.color) {
        rest.color = CustomProps.color
      }
      return (
        <svg
          id={rest.id}
          className={rest.className}
          width={rest.addToDimension ? width : null}
          height={rest.addToDimension ? height : null}
          viewBox={viewBox}
          preserveAspectRatio="xMidYMid meet"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          <IllustrationComponent {...customRest} {...rest} />
        </svg>
      )
    }
  }
}

Illustrations.Payment = Illustrations(Payment, {
  viewBox: '0 0 320 240',
  color: ['#FFFFFF'],
})

Illustrations.MerlionCharge = Illustrations(MerlionCharge, {
  viewBox: '0 0 640 480',
  color: ['#FFFFFF'],
})

Illustrations.WorkInProgress = Illustrations(WorkInProgress, {
  viewBox: '0 0 640 480',
  color: ['#FFFFFF'],
})

Illustrations.SubmitMeter = Illustrations(SubmitMeter, {
  viewBox: '0 0 120 90',
  color: ['#FFFFFF'],
})

Illustrations.HLAShield = Illustrations(HLAShield, {
  viewBox: '0 0 90 60',
  color: ['#FFFFFF'],
})

Illustrations.UsingApp = Illustrations(UsingApp, {
  viewBox: '0 0 79 176',
  color: ['#FFFFFF'],
})

Illustrations.EnergyConsumption = Illustrations(EnergyConsumption, {
  viewBox: '0 0 415 303',
  color: ['#FFFFFF'],
})

// Spots

Illustrations.Spot = {
  Application: Illustrations(SpotApplication, {
    viewBox: '0 0 240 180',
    color: ['#FFFFFF'],
  }),
}

Illustrations.EligiblePayment = Illustrations(EligiblePayment, {
  viewBox: '0 0 640 480',
})

Illustrations.Ecommerce = Illustrations(Ecommerce, {
  viewBox: '0 0 240 183',
})

Illustrations.Fail = Illustrations(Fail, {
  viewBox: '0 0 287 247',
})

Illustrations.Co2 = Illustrations(Co2, {
  viewBox: '0 0 376 181',
})

Illustrations.SPApp = Illustrations(SPApp, {
  viewBox: '0 0 376 181',
})

Illustrations.DownloadPhone = Illustrations(DownloadPhone, {
  viewBox: '0 0 44 80',
})

Illustrations.SPAppQrCode = Illustrations(SPAppQrCode, {
  viewBox: '0 0 78 78',
})

Illustrations.Notify = Illustrations(Notify, {
  viewBox: '0 0 120 90',
})

Illustrations.SocialShare = Illustrations(SocialShare, {
  viewBox: '0 0 240 180',
})

Illustrations.PaperBill = Illustrations(PaperBill, {
  viewBox: '0 0 240 180',
})

Illustrations.NotFound = Illustrations(NotFound, {
  viewBox: '0 0 640 480',
})

Illustrations.Substation = Illustrations(Substation, {
  viewBox: '0 0 215 113',
})

Illustrations.Transformer = Illustrations(Transformer, {
  viewBox: '0 0 66 80',
})

Illustrations.Switchgear = Illustrations(Switchgear, {
  viewBox: '0 0 83 80',
})

Illustrations.LVB = Illustrations(LVB, {
  viewBox: '0 0 65 80',
})

Illustrations.DuvalTriangle = Illustrations(DuvalTriangle, {
  viewBox: '0 0 197 171',
})

Illustrations.DigitalTwin = Illustrations(DigitalTwin, {
  viewBox: '0 0 40 40',
})

Illustrations.PartialDischarge = Illustrations(PartialDischarge, {
  viewBox: '0 0 40 40',
})

Illustrations.Success = Illustrations(Success, {
  viewBox: '0 0 240 180',
})

Illustrations.GraphFade = Illustrations(GraphFade, {
  viewBox: '0 0 66 44',
})

Illustrations.PayNow = Illustrations(PayNow, {
  viewBox: '0 0 120 122',
})

Illustrations.PayNowQR = Illustrations(PayNowQR, {
  viewBox: '0 0 475 356',
})

Illustrations.Splash = Illustrations(Splash, {
  viewBox: '0 0 665 480',
})

export default Illustrations
