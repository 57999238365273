import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import DetailNode from '../../Detail'
import Card from '../../Card'
import FormReceiptRow from './FormReceiptRow'
import FormReceiptItem from './FormReceiptItem'
import { useTheme } from '../../Theme'

const FormReceipt = ({ children, labelWidth, contentWidth, basis }) => {
  const theme = useTheme()
  const _FormReceipt = classnames('cui-receipt')

  const style = {
    '--labelWidth': labelWidth,
    '--contentWidth': contentWidth,
    '--flexBasis': basis,
    '--cuiFormTextSecondary': theme.colors.text.secondary,
  }

  const _renderDetails = () => {
    const details = React.Children.map(children, (child, childIdx) => {
      if (
        child &&
        (child.type === DetailNode.Section || child.type === FormReceiptRow)
      ) {
        return child
      }
    })
    return details
  }

  return (
    <>
      <div className={_FormReceipt} style={style}>
        <Card>
          <Card.Content direction="col">{_renderDetails()}</Card.Content>
        </Card>
      </div>
    </>
  )
}

FormReceipt.propTypes = {
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  basis: PropTypes.string,
  labelWidth: PropTypes.string,
  contentWidth: PropTypes.string,
}

FormReceipt.defaultProps = {
  basis: '50%',
  labelWidth: '35%',
  dataWidth: '65%',
}

FormReceipt.Row = FormReceiptRow
FormReceipt.Item = FormReceiptItem

export default FormReceipt
