import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import FieldLabel from '../FieldLabel'
import FieldContent from '../FieldContent'

const FieldBare = props => {
  const renderField = () => {
    const _FieldBare = classnames('lm--bare cui-field-bare')
    const _FieldGroup = classnames(
      'cui-bare-field_control lm--bare-input-control'
    )
    const label = React.Children.map(props.children, (child, childIdx) => {
      if (child.type === FieldLabel) {
        return <FieldLabel {...child.props} />
      }
      return null
    })
    const fieldContent = React.Children.map(
      props.children,
      (child, childIdx) => {
        if (child.type === FieldContent) {
          return <FieldContent {...child.props} />
        }
        return null
      }
    )
    return (
      <div className={_FieldBare}>
        {label}
        <div className={_FieldGroup}>{fieldContent}</div>
      </div>
    )
  }
  return renderField()
}

FieldBare.propTypes = {
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
}

export default FieldBare
