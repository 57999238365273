import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const MenuListItem = ({ children, id, value, name, onItemClick }) => {
  const _MenuListItem = classnames('cui-menulist__item')

  const handleItemClick = e => {
    if (onItemClick) {
      onItemClick({
        id,
        value,
        name,
      })
    }
  }

  return (
    <li id={id} className={_MenuListItem} onClick={handleItemClick}>
      {children}
    </li>
  )
}

MenuListItem.propTypes = {
  id: PropTypes.string,
  onItemClick: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.node,
}

MenuListItem.defaultProps = {
  onItemClick: null,
}

export default MenuListItem
