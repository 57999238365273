import React from 'react'
import PropTypes from 'prop-types'

const MenuIcon = ({ color }) => {
  return (
    <g className="icon-menu">
      <path
        d="M1 2H15C15.5523 2 16 1.55228 16 1C16 0.447715 15.5523 0 15 0H1C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2Z"
        fill={color[0]}
      />
      <path
        d="M1 7H15C15.5523 7 16 6.55228 16 6C16 5.44772 15.5523 5 15 5H1C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7Z"
        fill={color[0]}
      />
      <path
        d="M15 12H1C0.447715 12 0 11.5523 0 11C0 10.4477 0.447715 10 1 10H15C15.5523 10 16 10.4477 16 11C16 11.5523 15.5523 12 15 12Z"
        fill={color[0]}
      />
    </g>
  )
}

MenuIcon.defaultProps = {
  color: ['#818A91'],
}

MenuIcon.propTypes = {
  color: PropTypes.arrayOf(PropTypes.string),
}

export default MenuIcon
