import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const HeaderLogo = ({ logo }) => {
  const _HeaderLogo = classnames('lm--header__logo cui-header__logo')

  function renderLogo() {
    if (!logo) return null
    return (
      <a href={logo.link} target="_blank" rel="noopener noreferrer">
        <figure>
          {typeof logo.source === 'string' ? (
            <img
              className="lm--icon"
              style={{ width: logo.width, height: 'auto' }}
              src={logo.source}
              alt={logo.name}
            />
          ) : (
            logo.source
          )}
        </figure>
      </a>
    )
  }

  return <div className={_HeaderLogo}>{renderLogo()}</div>
}

HeaderLogo.propTypes = {
  logo: PropTypes.shape({
    source: PropTypes.string,
    link: PropTypes.string,
    name: PropTypes.string,
    width: PropTypes.number,
  }),
}

export default HeaderLogo
