import React from 'react'
import PropTypes from 'prop-types'

class ChevronRightIcon extends React.PureComponent {
  static propTypes = {
    color: PropTypes.array,
  }

  render() {
    return (
      <>
        <path d="M5.293 4.707a1 1 0 011.414-1.414l3.998 3.997a1 1 0 010 1.414l-3.997 4.001a1 1 0 01-1.415-1.414l3.291-3.293-3.29-3.291z"></path>
      </>
    )
  }
}

export default ChevronRightIcon
