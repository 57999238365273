import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Icons from '../Icons'
import NavMenu from './NavMenu'
import NavSubMenu from './NavSubMenu'
import MenuList from '../MenuList'
import { ScreenSizeProvider } from '../ScreenSize'
import { useTheme } from '../Theme'

const Navigation = ({ children, handleClickClose, isToggleMobile }) => {
  const theme = useTheme()

  const [menuToggle, setMenuToggle] = React.useState(false)
  const _Navigation = classnames('lm--navigation cui-navigation', {
    'is-open': menuToggle,
    'is-toggle': isToggleMobile,
  })

  const handleOpenMenu = () => {
    document.documentElement.style.overflow = 'hidden'
    setMenuToggle(true)
  }

  const handleCloseMenu = () => {
    document.documentElement.style.overflow = null
    setMenuToggle(false)
  }

  const handleMenuToggle = () => {
    if (!menuToggle) {
      handleOpenMenu()
    } else {
      handleCloseMenu()
    }
  }

  return (
    <ScreenSizeProvider>
      <nav
        className={_Navigation}
        style={{
          '--cuiNavigationTextPrimary': theme.colors.text.primary,
          '--cuiNavigationTextSecondary': theme.colors.text.secondary,
          '--cuiNavigationPrimary': theme.colors.primary.main,
          '--cuiNavigationBackground': theme.colors.header.background,
          '--cuiNavigationShadow': theme.colors.boxShadow,
          '--cuiNavigationDivider': theme.colors.divider,
          '--cuiNavigationSubItemHover': theme.colors.grey[100],
        }}
      >
        {!handleClickClose && (
          <ul
            className="lm--navigation__container cui-navigation__container"
            style={{
              maxHeight: window.innerHeight - 57,
            }}
          >
            {React.Children.map(children, child => {
              if (!child) {
                return null
              }
              if (child.type === NavMenu) {
                return React.cloneElement(child, {
                  handleCloseMenu,
                  isToggleMobile,
                })
              }
              if (child.type === MenuList) {
                return React.cloneElement(child, {
                  onRedirect: handleCloseMenu,
                  isToggleMobile,
                })
              }
              return child
            })}
          </ul>
        )}
        <div className="cui-navigation__toggle">
          <button onClick={handleClickClose || handleMenuToggle}>
            {menuToggle || handleClickClose ? (
              <Icons.Close width={16} height={16} color={[theme.colors.icon]} />
            ) : (
              <Icons.Menu width={16} color={[theme.colors.icon]} />
            )}
          </button>
        </div>
      </nav>
    </ScreenSizeProvider>
  )
}

Navigation.propTypes = {
  children: (props, propName, componentName) => {
    let error
    const prop = props[propName]
    React.Children.forEach(prop, child => {
      if (!isValidChildType(child.type)) {
        error = new Error(
          `${componentName} only accepts children of type NavMenu. Please use Navigation.Menu or NavigationMenu components instead.`
        )
      }
    })
    return error
  },
}

Navigation.defaultProps = {
  isToggleMobile: true,
}

Navigation.Menu = NavMenu
Navigation.SubMenu = NavSubMenu

function isValidChildType(type) {
  const validChildren = [NavMenu]
  return validChildren.some(t => t === type)
}

export default Navigation
