import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import NavMenu from './NavMenu'
import { useTheme } from '../Theme'

const NavSubMenuGroup = ({ children, title, handleCloseMenu }) => {
  const _NavSubMenuGroup = classnames(
    'lm--navmenu__sub_group cui-navmenu__sub_group'
  )
  return (
    <li className={_NavSubMenuGroup}>
      <ul>
        {title && (
          <li className="lm--navmenu__sub_group__title cui-navmenu__sub_group__title">
            {title}
          </li>
        )}
        {React.Children.map(children, child => {
          if (child && child.type === NavMenu) {
            return React.cloneElement(child, { handleCloseMenu })
          }
          return child
        })}
      </ul>
    </li>
  )
}

NavSubMenuGroup.propTypes = {
  children: (props, propName, componentName) => {
    let error
    const prop = props[propName]
    React.Children.forEach(prop, child => {
      if (!isValidChildType(child.type)) {
        error = new Error(
          `${componentName} only accepts children of type NavMenu and NavSubMenuGroup. Please use Navigation.SubMenu / NavSubMenu or Navigation.Menu / NavMenu components instead.`
        )
      }
    })
    return error
  },
  title: PropTypes.string,
}

const NavSubMenu = ({ children, open, handleCloseMenu }) => {
  const _NavSubMenu = classnames('lm--navmenu__sub cui-navmenu__sub', { open })
  const theme = useTheme()

  return (
    <ul
      className={_NavSubMenu}
      style={{
        '--cuiNavigationTextPrimary': theme.colors.text.primary,
        '--cuiNavigationTextSecondary': theme.colors.text.secondary,
        '--cuiNavigationBackground': theme.colors.background.level1,
        '--cuiNavigationDivider': theme.colors.divider,
        '--cuiNavigationSubItemHover': theme.colors.grey[100],
      }}
    >
      {React.Children.map(children, child => {
        return child && React.cloneElement(child, { handleCloseMenu })
      })}
    </ul>
  )
}

NavSubMenu.propTypes = {
  children: (props, propName, componentName) => {
    let error
    const prop = props[propName]
    React.Children.forEach(prop, child => {
      if (!isValidChildType(child.type)) {
        error = new Error(
          `${componentName} only accepts children of type NavMenu and NavSubMenuGroup. Please use Navigation.SubMenu / NavSubMenu or Navigation.Menu / NavMenu components instead.`
        )
      }
    })
    return error
  },
}

NavSubMenu.Group = NavSubMenuGroup

function isValidChildType(type) {
  const validChildren = [NavMenu, NavSubMenuGroup]
  return validChildren.some(t => t === type)
}

export default NavSubMenu
