import React from 'react'
import PropTypes from 'prop-types'

const PopupContent = ({ children }) => {
  return <div className="cui-popup__content">{children}</div>
}

PopupContent.propTypes = {
  children: PropTypes.node,
}

export default PopupContent
