import React from 'react'
import PropTypes from 'prop-types'

import TableRow from './TableRow'
import TableCell from './TableCell'

import './styles.scss'

const TableList = ({ children }) => {
  return <div className="cui-tableList">{children}</div>
}

TableList.Row = TableRow
TableList.Cell = TableCell

TableList.propTypes = {
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
}

export default TableList
