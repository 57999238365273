import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const CardTitle = ({ children, className, id }) => {
  const _CardTitle = classnames('cui-card__title', className)
  return (
    <div className={_CardTitle} id={id}>
      {children}
    </div>
  )
}

CardTitle.propTypes = {
  // CardTitle children
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
  id: PropTypes.string,
}

export default CardTitle
