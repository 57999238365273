import React from 'react'

function MediaPauseIcon() {
  return (
    <>
      <g className="icon-media-pause">
        <mask
          id="icon-media-pause-mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="3"
          y="3"
          width="18"
          height="18"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12ZM4.8 12C4.8 8.02355 8.02355 4.8 12 4.8C15.9764 4.8 19.2 8.02355 19.2 12C19.2 15.9764 15.9764 19.2 12 19.2C8.02355 19.2 4.8 15.9764 4.8 12ZM9.5 16C8.94772 16 8.5 15.5523 8.5 15L8.5 9C8.5 8.44772 8.94772 8 9.5 8C10.0523 8 10.5 8.44772 10.5 9V15C10.5 15.5523 10.0523 16 9.5 16ZM13.5 15C13.5 15.5523 13.9477 16 14.5 16C15.0523 16 15.5 15.5523 15.5 15V9C15.5 8.44772 15.0523 8 14.5 8C13.9477 8 13.5 8.44772 13.5 9V15Z"
          />
        </mask>
        <g mask="url(#icon-media-pause-mask0)">
          <rect width="24" height="24" />
        </g>
      </g>
    </>
  )
}

export default MediaPauseIcon
