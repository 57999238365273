import React from 'react'
import PropTypes from 'prop-types'
import FieldInput from '../Form/FieldInput'
import FieldLabel from '../Form/FieldLabel'

import './styles.scss'
import { useFormikContext } from 'formik'

const Address = props => {
  const { name, handleFetchStreetName, capsOnChange } = props

  const { errors, values, setFieldValue, setFieldError, setFieldTouched } =
    useFormikContext()

  const [loading, setLoading] = React.useState(false)

  const handleBlur = () => {
    if (
      !values[`${name}_postal_code`] &&
      !values[`${name}_block_house_no`] &&
      !values[`${name}_floor`] &&
      !values[`${name}_unit`]
    ) {
      setFieldValue(`${name}_street_name`, '')
      return
    }
    if (!values[`${name}_postal_code`] || !values[`${name}_block_house_no`]) {
      setFieldValue(`${name}_street_name`, '')
      return
    }
    if (
      !errors[`${name}_postal_code`] &&
      !errors[`${name}_block_house_no`] &&
      !errors[`${name}_floor`] &&
      !errors[`${name}_unit`]
    ) {
      if (!handleFetchStreetName) {
        return
      }
      setFieldValue(`${name}_street_name`, '')
      setLoading(true)
      handleFetchStreetName(values, streetName => {
        setFieldTouched(`${name}_street_name`, true)

        if (!streetName) {
          setFieldError(
            `${name}_street_name`,
            'You have entered an invalid mailing address'
          )
        } else {
          setFieldValue(`${name}_street_name`, streetName)
          setFieldError(`${name}_street_name`, '')
        }
        setLoading(false)
      })
    }
  }

  return (
    <>
      <FieldInput.Text
        capsOnChange={capsOnChange}
        name={`${name}_block_house_no`}
        onBlurHandler={handleBlur}
      >
        <FieldLabel>Block/House No.</FieldLabel>
      </FieldInput.Text>
      <FieldInput.Split
        capsOnChange={capsOnChange}
        onBlurHandler={handleBlur}
        fields={[
          {
            name: `${name}_floor`,
            placeholder: 'Floor',
            trailingZero: true,
          },
          {
            name: `${name}_unit`,
            placeholder: 'Unit',
            trailingZero: true,
          },
        ]}
      >
        <FieldLabel> Unit No. </FieldLabel>
      </FieldInput.Split>
      <FieldInput.Text
        onlyNumber
        name={`${name}_postal_code`}
        maxLength={6}
        onBlurHandler={handleBlur}
      >
        <FieldLabel>Postal Code</FieldLabel>
      </FieldInput.Text>
      <FieldInput.Text name={`${name}_street_name`} disabled loading={loading}>
        <FieldLabel>Street Name</FieldLabel>
      </FieldInput.Text>
    </>
  )
}

Address.propTypes = {
  name: PropTypes.string,
  handleFetchStreetName: PropTypes.func,
  capsOnChange: PropTypes.bool,
}

Address.defaultProps = {
  name: 'form-default',
}

export default Address
