import React from 'react'
import PropTypes from 'prop-types'

const ToastMessage = ({ children }) => {
  return <div className="cui-toast__message">{children}</div>
}

ToastMessage.propTypes = {
  children: PropTypes.node,
}

export default ToastMessage
