import Choices from './Choices'
import Choice from './Choice'

export {
  Choices,
  Choice
}

export default {
  Choices,
  Choice
}
