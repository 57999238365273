import React from 'react'

class ShieldIcon extends React.PureComponent {
  render() {
    return (
      <>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.3333 3.73301L8 2.73301L3.66667 3.73301C3.46667 3.73301 3.33333 3.86634 3.33333 4.06634V5.99967C3.33333 9.33301 5.13333 11.6663 8 13.333C10.8667 11.6663 12.6667 9.33301 12.6667 5.99967V4.06634C12.6667 3.86634 12.5333 3.73301 12.3333 3.73301ZM7.73333 1.39967L8 1.33301L8.26667 1.39967L12.5333 2.39967C13.3333 2.53301 14 3.19967 14 4.06634V5.99967C14 9.59968 12.2 12.4663 8.66667 14.4663L8 14.8663L7.33333 14.4663C3.8 12.4663 2 9.59968 2 5.99967V4.06634C2 3.19967 2.66667 2.46634 3.46667 2.39967L7.73333 1.39967ZM4 5.39968C4 5.79968 4.26667 6.06635 4.66667 6.06635H11.3333C11.7333 6.06635 12 5.79968 12 5.39968C12 4.99968 11.7333 4.73301 11.3333 4.73301H4.66667C4.26667 4.73301 4 4.99968 4 5.39968Z"
        />
      </>
    )
  }
}

export default ShieldIcon
