import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const FieldContent = ({ children, content }) => {
  const _FieldContent = classnames('cui-field_content')
  return (
    <div className={_FieldContent}>
      {content && <div dangerouslySetInnerHTML={{ __html: content }} />}
      {children}
    </div>
  )
}

FieldContent.propTypes = {
  content: PropTypes.string,
  children: PropTypes.node,
}

export default FieldContent
