import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ContextMenu from '../ContextMenu'
import Icons from '../Icons'
import { withTheme } from '../Theme'

import SelectInputOption from './SelectInputOption'
import './styles.scss'

class SelectInput extends React.PureComponent {
  state = {
    open: false,
    test: false,
  }

  selectRef = React.createRef()

  handleCloseModal = () => {
    setTimeout(() => {
      this.setState({
        open: false,
      })
    }, 0)
  }

  handleSelectClose = () => {
    this.setState(prevState => ({
      ...prevState,
      open: false,
    }))
  }

  handleSelectOpen = () => {
    this.setState(prevState => ({
      ...prevState,
      open: true,
    }))
  }

  renderOptions() {
    const { children, theme, menuClassName } = this.props

    const { open } = this.state

    return (
      <ContextMenu
        targetNode={this.selectRef.current}
        onCloseHandler={this.handleSelectClose}
        open={open}
        withBackdrop={false}
        alignX="left"
        className={classnames('cui-selectinput__modal', menuClassName)}
        style={{
          '--cuiSelectInputBg': theme.colors.input.background,
        }}
      >
        {children({ closeSelect: this.handleCloseModal })}
      </ContextMenu>
    )
  }

  render() {
    const { selectedOption, handleToggleMenu, theme, className } = this.props

    const _SelectInput = classnames('cui-selectinput', className)
    return (
      <>
        <div
          className={_SelectInput}
          ref={this.selectRef}
          style={{
            '--cuiSelectInputIconColor': theme.colors.icon,
          }}
        >
          <div
            className="cui-selectinput__control"
            onClick={handleToggleMenu || this.handleSelectOpen}
          >
            <div className="cui-selectinput__control_selected">
              {selectedOption}
              <i className="cui-selectinput__caret">
                <Icons.Caret.Down width={14} />
              </i>
            </div>
            {this.renderOptions()}
          </div>
        </div>
      </>
    )
  }
}

SelectInput.propTypes = {
  selectedOption: PropTypes.node,
  handleToggleMenu: PropTypes.func,
  className: PropTypes.string,
  menuClassName: PropTypes.string,
  children: PropTypes.func,
}

const SelectInputWithTheme = withTheme(SelectInput)

SelectInputWithTheme.Option = SelectInputOption
export default SelectInputWithTheme
