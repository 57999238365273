import React from 'react'

class Info extends React.PureComponent {
  render() {
    return (
      <g
        className="icon-info"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-785.000000, -334.000000)">
          <g transform="translate(316.000000, 283.000000)">
            <g transform="translate(206.000000, 40.000000)">
              <g transform="translate(262.000000, 10.000000)">
                <g>
                  <g>
                    <rect x="0" y="0" width="16" height="16"></rect>
                    <circle
                      fill={this.props.color[0]}
                      cx="8"
                      cy="5"
                      r="1"
                    ></circle>
                  </g>
                  <path
                    d="M8,15 C4.13400675,15 1,11.8659932 1,8 C1,4.13400675 4.13400675,1 8,1 C11.8659932,1 15,4.13400675 15,8 C15,11.8659932 11.8659932,15 8,15 Z M8,13 C10.7614237,13 13,10.7614237 13,8 C13,5.23857625 10.7614237,3 8,3 C5.23857625,3 3,5.23857625 3,8 C3,10.7614237 5.23857625,13 8,13 Z"
                    fill={this.props.color[0]}
                    fillRule="nonzero"
                  ></path>
                </g>
                <path
                  d="M8,8 L8,11"
                  stroke={this.props.color[0]}
                  strokeWidth="2"
                  strokeLinecap="round"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    )
  }
}

export default Info
