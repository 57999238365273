import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const TableRow = ({ children, className }) => {
  const _TableRow = classnames('cui-tableList__row', className)

  return <div className={_TableRow}>{children}</div>
}

TableRow.propTypes = {
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
}

export default TableRow
