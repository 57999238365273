import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import NavSubMenu from './NavSubMenu'
import Icons from '../Icons'
import ScreenSize from '../ScreenSize'

const NavMenu = props => {
  const { children, active, handleCloseMenu, isToggleMobile } = props
  const screenSize = ScreenSize.useScreenSize()
  const isMobileAndTablet =
    screenSize && isToggleMobile
      ? screenSize.width < screenSize.breakpoints.TABLET.width
      : false

  const [openSubMenu, setOpenSubMenu] = React.useState(false)

  const _NavMenu = classnames('lm--navmenu cui-navmenu', {
    'is-active': active,
  })

  let hasSubMenu = false
  const subMenu = React.Children.map(children, child => {
    if (child && child.type === NavSubMenu) {
      hasSubMenu = true
      return React.cloneElement(child, { handleCloseMenu, open: openSubMenu })
    }
    return null
  })

  const content = React.Children.map(children, child => {
    if (child && child.type !== NavSubMenu) {
      return child
    }
    return null
  })

  const handleToggleSubMenu = event => {
    event.stopPropagation()
    setOpenSubMenu(!openSubMenu)
  }

  return (
    <>
      <li
        className={_NavMenu}
        onClick={event => {
          event.stopPropagation()
          handleCloseMenu()
        }}
      >
        {content}
        {hasSubMenu && (
          <div
            className="cui-navmenu__dropdown-icon"
            onClick={handleToggleSubMenu}
          >
            <Icons.Caret.Down width={14} />
          </div>
        )}
        {!isMobileAndTablet && subMenu}
      </li>
      {isMobileAndTablet && subMenu}
    </>
  )
}

NavMenu.propTypes = {
  active: PropTypes.bool,
}

NavMenu.defaultProps = {
  active: false,
}

export default NavMenu
