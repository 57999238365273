import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const PaginationOffset = ({ type }) => {
  const _PaginationOffset = classnames('cui--pagination__offset')

  function getIcon() {
    switch (type) {
      case 'Ellipse':
        return <>&hellip;</>
      default:
        return <>&hellip;</>
    }
  }

  return (
    <>
      <span className={_PaginationOffset}>{getIcon()}</span>
    </>
  )
}

PaginationOffset.defaultProps = {
  type: 'Ellipse',
  offsetIndex: PropTypes.number,
}

PaginationOffset.propTypes = {
  type: PropTypes.string,
  offsetIndex: 4,
}

export default PaginationOffset
