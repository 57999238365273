import React from 'react'
import PropTypes from 'prop-types'

class CloseFilledIcon extends React.PureComponent {
  static propTypes = {
    color: PropTypes.array,
  }

  render() {
    return (
      <g
        className="icon-close-filled"
        stroke="none"
        strokeWidth="1"
        fill={this.props.color[0]}
        fillRule="evenodd"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-8.586l3.293 3.293a1 1 0 001.414-1.414L13.414 12l3.293-3.293c.943-.943-.471-2.357-1.414-1.414L12 10.586 8.707 7.293a1 1 0 00-1.414 1.414L10.586 12l-3.293 3.293c-.943.943.471 2.357 1.414 1.414L12 13.414z"
        />
      </g>
    )
  }
}

export default CloseFilledIcon
