import React from 'react'
import PropTypes from 'prop-types'

import { isValidChildType } from './utils'

import { useNavigationStack } from './NavigationStack'
import NavigationStackScene from './NavigationStackScene'

const NavigationStackScenes = ({ children }) => {
  const nsCtx = useNavigationStack()

  function renderScenes() {
    const scenes = []
    React.Children.forEach(children, (child, childIdx) => {
      if (isValidChildType(child.type, [NavigationStackScene])) {
        if (childIdx === nsCtx.data.activeScene) {
          scenes.push(
            React.cloneElement(child, {
              ...child.props,
              key: `navigationstack-scene--${childIdx}`,
              active: true,
            })
          )
        }
      }
    })
    return scenes
  }

  React.useEffect(() => {
    nsCtx.actions.setTotalScenes(React.Children.count(children))
  }, [])

  return <div className="cui-navigationstack_scenes">{renderScenes()}</div>
}

NavigationStackScenes.propTypes = {
  children: (props, propName, componentName) => {
    let error
    const prop = props[propName]
    React.Children.forEach(prop, child => {
      if (!isValidChildType(child.type, [NavigationStackScene])) {
        error = new Error(
          `${componentName} only accepts children of type NavigationStackScene. Please use NavigationStack.Scene component instead.`
        )
      }
    })
    return error
  },
}

export default NavigationStackScenes
