import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { useNavigationStack } from './NavigationStack'
const NavigationStackScene = ({ id, active, className, title, children }) => {
  const nsCtx = useNavigationStack()

  const _NavigationStackScene = classnames(
    'cui-navigationstack_scene',
    className,
    {
      'is-active': active,
    }
  )

  React.useEffect(() => {
    if (active) {
      nsCtx.actions.setTitle(title)
    }
  }, [active])

  return (
    <div id={id} className={_NavigationStackScene}>
      <div className="cui-navigationstack_scene__container">{children}</div>
    </div>
  )
}

NavigationStackScene.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  active: PropTypes.bool,
}

export default NavigationStackScene
