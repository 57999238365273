import React from 'react'
import PropTypes from 'prop-types'

// AmountPayable Icon
class BillsIcon extends React.PureComponent {
  static propTypes = {
    color: PropTypes.array,
  }

  render() {
    return (
      <g
        className="icon-bill"
        stroke="none"
        strokeWidth="1"
        fill={this.props.color[0]}
        fillRule="evenodd"
      >
        <rect
          x="43.0593"
          y="0.941406"
          width="58"
          height="67.5"
          rx="4"
          transform="rotate(9 43.0593 0.941406)"
          fill="#CED5DC"
        />
        <rect
          x="33"
          y="13.4414"
          width="58"
          height="29.5"
          rx="4"
          fill="#F9FBFD"
        />
        <mask
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="3"
          width="120"
          height="91"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M100.214 37.5663C99.7403 35.8308 99.2993 34.0848 99.0078 32.3123C98.4888 29.1593 96.3563 26.3093 93.6918 24.3368C90.6273 22.0683 86.9123 20.7833 83.2303 19.6423C72.2138 16.2283 60.5953 13.7703 49.1043 15.1158C37.6143 16.4608 26.2038 22.1348 20.4933 31.8083C15.0713 40.9928 15.4898 52.6638 19.8283 62.3653C22.4008 68.1183 26.4123 73.4598 32.0218 76.6483C36.0718 78.9503 40.7393 80.0258 45.3773 80.7953C52.7238 82.0148 60.1763 82.5678 67.6303 82.4583C75.4868 82.3428 83.9398 81.5763 90.4328 77.1798C96.3838 73.1493 99.9363 66.5183 101.76 59.7458C103.685 52.5988 102.203 44.8618 100.214 37.5663Z"
            fill="#F8FCFF"
          />
        </mask>
        <g mask="url(#mask0)">
          <rect
            x="33"
            y="13.4414"
            width="58"
            height="79"
            rx="4"
            fill="url(#paint0_linear)"
          />
          <rect
            x="40.5"
            y="30.9414"
            width="23"
            height="2.5"
            rx="1.25"
            fill="#E4EBF2"
          />
          <rect
            x="50"
            y="37.4414"
            width="23"
            height="2.5"
            rx="1.25"
            fill="#F65B7D"
          />
          <rect
            x="40.5"
            y="44.4414"
            width="39"
            height="2.5"
            rx="1.25"
            fill="#E4EBF2"
          />
          <rect
            x="40.5"
            y="51.4414"
            width="39"
            height="2.50001"
            rx="1.25"
            fill="#E4EBF2"
          />
          <rect
            x="40.5"
            y="58.4414"
            width="39"
            height="2.5"
            rx="1.25"
            fill="#E4EBF2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M51.068 74.9178L53.6138 69.2738C53.7049 69.1382 53.8666 69.0687 54.0269 69.0966C54.2007 69.1178 54.3438 69.2452 54.3853 69.4167L55.6998 74.3915C55.9335 75.3085 56.7559 75.9477 57.6944 75.9413H57.7004C58.6367 75.95 59.459 75.3164 59.6973 74.4028L60.1773 72.6259C60.2231 72.4585 60.362 72.3338 60.5324 72.3085C60.7024 72.2833 60.8713 72.3618 60.9627 72.5088L62.3536 74.7553C62.7517 75.3969 63.4625 75.7713 64.2118 75.7336C64.9617 75.6961 65.632 75.2522 65.965 74.5736C66.0211 74.4621 66.1218 74.3805 66.2416 74.3488C66.3619 74.3174 66.4892 74.339 66.5923 74.4085L67.5742 75.0689C67.8944 75.2847 68.2681 75.4056 68.6531 75.4188C69.1051 75.4347 69.4838 75.078 69.4995 74.6219C69.5152 74.1663 69.1613 73.7839 68.7097 73.7681C68.6284 73.7651 68.5498 73.7397 68.4823 73.6947L67.5004 73.0343C67.0053 72.7015 66.3919 72.5994 65.817 72.7548C65.2418 72.91 64.7613 73.3076 64.4972 73.8459C64.4273 73.9876 64.2864 74.0802 64.1299 74.0873C63.9729 74.0949 63.8242 74.0163 63.7411 73.8822L62.3502 71.6364C61.9146 70.9325 61.1069 70.5558 60.293 70.6767C59.4793 70.7976 58.8138 71.3934 58.5977 72.1941L58.1176 73.9702C58.0599 74.1573 57.8914 74.2865 57.6978 74.2933C57.4985 74.302 57.3218 74.1648 57.2799 73.968L55.9683 68.9916C55.7541 68.1815 55.0804 67.5789 54.2572 67.4618C53.434 67.3451 52.6214 67.7361 52.1933 68.4552C52.178 68.4816 52.1634 68.5099 52.1503 68.5379L49.5812 74.2328C49.4523 74.5026 49.4782 74.8215 49.649 75.0663C49.8193 75.3111 50.1081 75.4434 50.4032 75.4123C50.6983 75.3814 50.9533 75.1913 51.0702 74.9162L51.068 74.9178Z"
            fill="#3176DE"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M73.6252 75.4414C74.1083 75.4414 74.5 74.9937 74.5 74.4416C74.5 73.8891 74.1083 73.4414 73.6252 73.4414H71.8748C71.3917 73.4414 71 73.8891 71 74.4416C71 74.9937 71.3917 75.4414 71.8748 75.4414H73.6252Z"
            fill="#3176DE"
          />
          <rect
            x="40.5"
            y="20.4414"
            width="10"
            height="2.5"
            rx="1.25"
            fill="#00B0B2"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="70.5"
            y1="36.1914"
            x2="88.2551"
            y2="47.3784"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F9FBFD" />
            <stop offset="1" stopColor="white" />
          </linearGradient>
        </defs>
      </g>
    )
  }
}

export default BillsIcon
