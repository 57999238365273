import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'formik'

import Detail from './Detail'
import Node from './Node'

class FormSummary extends React.PureComponent {
  static propTypes = {
    fields: PropTypes.object,
    // data: PropTypes.object,
    labelWidth: PropTypes.string,
    dataWidth: PropTypes.string,
  }

  render() {
    const { fields, labelWidth, dataWidth } = this.props
    const _FormSummary = classnames('cui-summary')
    if (!fields) return null
    const style = {
      '--labelWidth': labelWidth,
      '--dataWidth': dataWidth,
    }

    return (
      <div className={_FormSummary} style={style}>
        {this._renderFields(Object.keys(fields))}
      </div>
    )
  }

  _renderFields(groupFields) {
    const { fields } = this.props
    const data = { ...(this.props.formik?.values || {}) }
    if (!groupFields) {
      return null
    }

    return groupFields.map((groupField, index) => {
      const group = fields[groupField]

      return (
        <Detail
          id={`${group.id ? group.id : groupField}`}
          key={`${groupField}--${index}`}
          label={group.label}
        >
          {group.fields &&
            group.fields.map((f, i) => {
              let d
              if (f.parent && f.parent !== '') {
                if (data[groupField]) {
                  d = data[groupField][f.parent]
                  if (Array.isArray(f.field) && f.field.length > 0) {
                    d = {}
                    f.field.forEach(key => {
                      if (data[groupField] && data[groupField][f.parent]) {
                        d[key] = data[groupField][f.parent][key]
                      } else if (data[f.parent]) {
                        d[key] = data[f.parent][key]
                      }
                    })
                  } else {
                    if (data[f.parent]) {
                      d = data[f.parent][f.field]
                    }
                  }
                }
              } else {
                if (Array.isArray(f.field) && f.field.length > 0) {
                  d = {}
                  f.field.forEach(key => {
                    d[key] = data[groupField]
                      ? data[groupField][key]
                      : data[key]
                  })
                } else {
                  d = data[groupField]
                    ? data[groupField][f.field]
                    : data[f.field]
                }
                if (data[groupField]) {
                }
              }
              if (d) {
                if (Object.prototype.hasOwnProperty.call(f, 'show')) {
                  if (typeof f.show === 'boolean') {
                    if (f.show) {
                      return (
                        <Detail.DetailNodeItem
                          id={this.getNodeId(groupField, f)}
                          key={`${groupField}-${f.field}--${i}`}
                          label={f.label}
                          data={d}
                          render={data => {
                            if (f.resolve && typeof f.resolve === 'function') {
                              return f.resolve(data)
                            }

                            return <span>{d}</span>
                          }}
                        />
                      )
                    } else {
                      return null
                    }
                  }
                  if (typeof f.show === 'function') {
                    if (f.show(d)) {
                      return (
                        <Detail.DetailNodeItem
                          id={this.getNodeId(groupField, f)}
                          key={`${groupField}-${f.field}--${i}`}
                          label={f.label}
                          data={d}
                          render={data => {
                            if (f.resolve && typeof f.resolve === 'function') {
                              return f.resolve(data)
                            }
                            return <span>{d}</span>
                          }}
                        />
                      )
                    } else {
                      return null
                    }
                  }
                }
                return (
                  <Node
                    id={this.getNodeId(groupField, f)}
                    key={`${groupField}-${f.field}--${i}`}
                    label={f.label}
                    data={d}
                    render={data => {
                      if (f.resolve && typeof f.resolve === 'function') {
                        return f.resolve(data)
                      }

                      return <span>{d}</span>
                    }}
                  />
                )
              }
              return null
            })}
        </Detail>
      )
    })
  }

  getNodeId = (g, n) => {
    let id = `${g}`
    if (n.id) {
      id += `-${n.id}`
    } else if (n.field && typeof n.field !== 'string') {
      if (n.parent) {
        id += `-${n.parent}`
      }
    } else {
      id += `-${n.field}`
    }

    return id
  }
}

FormSummary.defaultProps = {
  data: {},
  labelWidth: '45%',
  dataWidth: '55%',
}

export default connect(FormSummary)
