import React from 'react'

const SubmitMeter = () => {
  return (
    <g className="illu-submitmeter">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M90.1022 17.4254C82.8817 16.9239 75.6932 15.8734 68.6647 13.9309C63.8002 12.5859 58.8187 11.7889 53.8157 11.5609C41.9367 11.0189 29.3852 14.0349 20.5522 23.0554C11.7197 32.0759 7.8707 48.0334 13.9602 59.6079C20.0137 71.1154 33.0357 74.8364 44.7857 76.2869C55.3622 77.5919 66.0327 77.9079 76.6542 77.2289C86.0182 76.6314 96.1507 74.8229 102.605 67.1099C107.826 60.8709 109.632 51.5834 108.395 43.0989C107.157 34.6139 103.164 26.8904 98.1472 20.4394C96.5482 18.3839 93.4922 17.6609 90.1022 17.4254Z"
        fill="#F8FCFF"
      />
      <path
        d="M71.0513 57.9644L95.6917 64.7792L94.6014 68.7216C93.9992 70.899 91.7459 72.1759 89.5685 71.5738L72.813 66.9397C70.6356 66.3375 69.3587 64.0842 69.9609 61.9068L71.0513 57.9644Z"
        fill="#FFC466"
      />
      <rect
        x="73.8647"
        y="60.6069"
        width="0.892076"
        height="4.55149"
        transform="rotate(15.4599 73.8647 60.6069)"
        fill="white"
      />
      <rect
        x="77.3037"
        y="61.5581"
        width="0.892072"
        height="4.55148"
        transform="rotate(15.4599 77.3037 61.5581)"
        fill="white"
      />
      <rect
        x="80.7429"
        y="62.5093"
        width="0.892072"
        height="4.55148"
        transform="rotate(15.4598 80.7429 62.5093)"
        fill="white"
      />
      <rect
        x="84.1821"
        y="63.4604"
        width="0.892072"
        height="4.55148"
        transform="rotate(15.4598 84.1821 63.4604)"
        fill="white"
      />
      <rect
        width="0.892076"
        height="4.55148"
        transform="matrix(0.963818 0.266562 -0.266564 0.963817 87.6213 64.4116)"
        fill="white"
      />
      <rect
        width="0.892076"
        height="4.55148"
        transform="matrix(0.963818 0.266562 -0.266564 0.963817 91.0605 65.3628)"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.641 42.1923C70.0656 42.5863 71.535 41.7682 71.9231 40.365C72.3112 38.9617 71.471 37.5048 70.0464 37.1108C68.6219 36.7169 67.1524 37.535 66.7643 38.9382C66.3763 40.3414 67.2165 41.7983 68.641 42.1923Z"
        fill="#CED5DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.878 52.2143C106.303 52.6083 107.772 51.7901 108.16 50.3869C108.549 48.9837 107.708 47.5268 106.284 47.1328C104.859 46.7388 103.39 47.557 103.002 48.9602C102.614 50.3634 103.454 51.8203 104.878 52.2143Z"
        fill="#CED5DC"
      />
      <rect
        x="73.4202"
        y="22.5454"
        width="38.8596"
        height="35.7917"
        rx="5.24604"
        transform="rotate(15.4599 73.4202 22.5454)"
        fill="url(#submitmeter_paint0_linear)"
      />
      <rect
        x="75.5593"
        y="26.3198"
        width="32.7238"
        height="29.656"
        rx="5.24604"
        transform="rotate(15.4599 75.5593 26.3198)"
        fill="url(#submitmeter_paint1_linear)"
      />
      <path
        d="M74.5636 41.4289C74.94 40.068 76.3483 39.27 77.7092 39.6463L96.9287 44.9619L95.5658 49.89L76.3462 44.5744C74.9853 44.198 74.1873 42.7897 74.5636 41.4289Z"
        fill="#2A2C2D"
      />
      <path
        d="M96.9287 44.9619L98.4071 45.3708C99.768 45.7472 100.566 47.1555 100.19 48.5163C99.8133 49.8772 98.405 50.6753 97.0442 50.2989L95.5657 49.89L96.9287 44.9619Z"
        fill="#F65B7D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.4985 44.7528L88.2259 45.7384L92.1684 46.8288L92.441 45.8432L88.4985 44.7528ZM83.2978 44.3755L83.5704 43.3898L87.5129 44.4802L87.2403 45.4658L83.2978 44.3755ZM78.3697 43.0125L78.6423 42.0269L82.5848 43.1172L82.3122 44.1029L78.3697 43.0125Z"
        fill="white"
      />
      <mask
        id="submitmeter-mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="120"
        height="90"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M90.1022 17.4254C82.8817 16.9239 75.6932 15.8734 68.6647 13.9309C63.8002 12.5859 58.8187 11.7889 53.8157 11.5609C41.9367 11.0189 29.3852 14.0349 20.5522 23.0554C11.7197 32.0759 7.8707 48.0334 13.9602 59.6079C20.0137 71.1154 33.0357 74.8364 44.7857 76.2869C55.3622 77.5919 66.0327 77.9079 76.6542 77.2289C86.0182 76.6314 96.1507 74.8229 102.605 67.1099C107.826 60.8709 109.632 51.5834 108.395 43.0989C107.157 34.6139 103.164 26.8904 98.1472 20.4394C96.5482 18.3839 93.4922 17.6609 90.1022 17.4254Z"
          fill="#F8FCFF"
        />
      </mask>
      <g mask="url(#submitmeter-mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M67.917 93.8633H27.1358C22.0559 93.8633 17.8997 89.7916 17.8997 84.8152V-7.01256C17.8997 -11.9893 22.0559 -16.061 27.1358 -16.061H67.917C72.9966 -16.061 77.1528 -11.9893 77.1528 -7.01256V84.8152C77.1528 89.7916 72.9966 93.8633 67.917 93.8633Z"
          fill="url(#submitmeter_paint2_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.2629 -10.1493C34.2629 -8.86268 35.3278 -7.81944 36.6411 -7.81944H58.4114C59.725 -7.81944 60.7899 -8.86268 60.7899 -10.1493V-11.3628H68.6278C71.061 -11.3628 73.0331 -9.43079 73.0331 -7.04735V84.8495C73.0331 87.2326 71.061 89.165 68.6278 89.165H26.425C23.9917 89.165 22.0193 87.2326 22.0193 84.8495V-7.04735C22.0193 -9.43079 23.9917 -11.3628 26.425 -11.3628H34.2629V-10.1493Z"
          fill="white"
        />
      </g>
      <mask
        id="submitmeter_mask1"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="7"
        y="5"
        width="82"
        height="59"
      >
        <rect x="7" y="5.5" width="81.5" height="58" fill="white" />
      </mask>
      <g mask="url(#submitmeter_mask1)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M67.9172 110.829H27.1361C22.0561 110.829 17.8999 106.957 17.8999 102.226V14.914C17.8999 10.182 22.0561 6.31055 27.1361 6.31055H67.9172C72.9968 6.31055 77.153 10.182 77.153 14.914V102.226C77.153 106.957 72.9968 110.829 67.9172 110.829Z"
          fill="url(#paint3_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M34.2632 11.9317C34.2632 13.155 35.3281 14.1469 36.6413 14.1469H58.4116C59.7253 14.1469 60.7901 13.155 60.7901 11.9317V10.7778H68.628C71.0612 10.7778 73.0333 12.6148 73.0333 14.881V102.258C73.0333 104.524 71.0612 106.362 68.628 106.362H26.4252C23.992 106.362 22.0195 104.524 22.0195 102.258V14.881C22.0195 12.6148 23.992 10.7778 26.4252 10.7778H34.2632V11.9317Z"
          fill="white"
        />
      </g>
      <rect
        x="26.5"
        y="22.5"
        width="22"
        height="2.5"
        rx="1.25"
        fill="#E4EBF2"
      />
      <rect
        x="27"
        y="29.5"
        width="42.5"
        height="2.5"
        rx="1.25"
        fill="#E4EBF2"
      />
      <rect
        x="27"
        y="56.5"
        width="42"
        height="10.5"
        rx="5"
        fill="url(#submitmeter_paint4_linear)"
      />
      <rect x="27" y="37.5" width="6.5" height="12" rx="3.25" fill="#FFC466" />
      <rect x="36" y="37.5" width="6.5" height="12" rx="3.25" fill="#F65B7D" />
      <rect x="45" y="37.5" width="6.5" height="12" rx="3.25" fill="#E4EBF2" />
      <rect x="54" y="37.5" width="6.5" height="12" rx="3.25" fill="#E4EBF2" />
      <rect x="63" y="37.5" width="6.5" height="12" rx="3.25" fill="#E4EBF2" />
      <defs>
        <linearGradient
          id="submitmeter_paint0_linear"
          x1="53.9904"
          y1="40.4413"
          x2="89.6614"
          y2="79.1698"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#65A1DB" />
          <stop offset="1" stopColor="#3176DE" />
        </linearGradient>
        <linearGradient
          id="submitmeter_paint1_linear"
          x1="42.4754"
          y1="23.154"
          x2="66.7875"
          y2="64.1875"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F8FCFF" />
          <stop offset="1" stopColor="#C0C6CC" />
        </linearGradient>
        <linearGradient
          id="submitmeter_paint2_linear"
          x1="11.0859"
          y1="-0.804966"
          x2="34.2114"
          y2="9.52354"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7C8084" />
          <stop offset="1" stopColor="#5E5E70" />
        </linearGradient>
        <linearGradient
          id="paint3_linear"
          x1="11.0861"
          y1="20.8163"
          x2="33.8106"
          y2="31.4907"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7C8084" />
          <stop offset="1" stopColor="#5E5E70" />
        </linearGradient>
        <linearGradient
          id="submitmeter_paint4_linear"
          x1="12.4834"
          y1="60.7039"
          x2="16.028"
          y2="76.8913"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#52CFD1" />
          <stop offset="1" stopColor="#00B0B2" />
        </linearGradient>
      </defs>
    </g>
  )
}

export default SubmitMeter
