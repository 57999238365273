import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Icons from '../Icons'
import { useTheme } from '../Theme'

const ListSortButton = ({ id, onClickHandler, order }) => {
  const theme = useTheme()

  const _ListSortButton = classnames('cui-list__sort--btn')

  return (
    <button className={_ListSortButton} id={id} onClick={onClickHandler}>
      <Icons.SortList
        width={16}
        order={order}
        activeColor={theme.colors.text.primary}
      />
    </button>
  )
}

ListSortButton.propTypes = {
  id: PropTypes.string,
}

export default ListSortButton
