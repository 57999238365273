// organize-imports-ignore
import React from 'react'
import PropTypes from 'prop-types'
import ChartLabel from './ChartLabel'
import ChartLegends from './ChartLegends'
import ChartLegendsItem from './ChartLegendsItem'
import ChartData from './ChartData'
import ChartXY from './charts/ChartXY'
import ChartBar from './charts/ChartBar'
import ChartDoughnut from './charts/ChartDoughnut'
import ChartLine from './charts/ChartLine'
import ChartDuvalTriangle from './ChartDuvalTriangle'
import ChartSinePulse from './charts/ChartSinePulse'
import ChartConstantLine from './charts/ChartConstantLine'
import ChartTooltip from './charts/ChartTooltip'
import ChartGraphHorizontalBar from './charts/ChartGraphHorizontalBar'

export const ChartCtx = React.createContext()
export const ChartProvider = ChartCtx.Provider
export const ChartConsumer = ChartCtx.Consumer

export const useChart = () => {
  const chartCtx = React.useContext(ChartCtx)
  return chartCtx
}

export const withChart = (Component, otherProps) => {
  return props => (
    <ChartConsumer>
      {context => (
        <div>
          <Component {...props} {...otherProps} {...context} />
        </div>
      )}
    </ChartConsumer>
  )
}

const Chart = ({ children, data }) => {
  const chartData = data.getChartData()

  return (
    <div className="cui-chart">
      <ChartProvider
        value={{
          data,
          chartData,
        }}
      >
        {children}
      </ChartProvider>
    </div>
  )
}

Chart.Label = ChartLabel
Chart.Bar = ChartBar
Chart.Legends = ChartLegends
Chart.Legends.Item = ChartLegendsItem
Chart.Line = ChartLine
Chart.ConstantLine = ChartConstantLine
Chart.Doughnut = ChartDoughnut
Chart.DuvalTriangle = ChartDuvalTriangle
Chart.XY = ChartXY
Chart.SinePulse = ChartSinePulse
Chart.Data = ChartData
Chart.Tooltip = ChartTooltip
Chart.GraphHorizontalBar = ChartGraphHorizontalBar

Chart.defaultProps = {
  data: new ChartData({ type: 'xy' }),
}

Chart.propTypes = {
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  data: ChartData,
}

export default Chart
