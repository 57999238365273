import React from 'react'
import PropTypes from 'prop-types'

import { useTheme } from '../Theme'
import Typo from '../Typo'
import Icons from '../Icons'
import ContextMenu from '../ContextMenu'
import BrandItem from './BrandItem'

const validTypes = [BrandItem]
export const isValidType = (type, checkWith) => {
  if (checkWith) {
    return type === checkWith
  }
  return validTypes.some(t => t === type)
}

const Brand = ({ children, onChange, onClose }) => {
  const theme = useTheme()

  const brands = React.Children.toArray(children).filter(child => {
    return isValidType(child.type)
  })
  const [selectedItem, setSelectedItem] = React.useState(() => ({
    key: brands[0]?.props?.id || '',
    index: 0,
  }))
  const [open, setOpen] = React.useState(false)

  const target = React.useRef(null)

  const handleChange = (key, index) => {
    setSelectedItem({ index, key })
    onChange(key)
  }

  const handleClose = () => {
    setOpen(false)
    if (onClose) {
      onClose()
    }
  }

  const handleBrandSelectionClick = () => setOpen(true)

  const renderBrand = () => {
    const brandsByKey = brands.reduce(
      (obj, brand) => ({ ...obj, [brand.props.id]: brand.props }),
      {}
    )
    if (brandsByKey) {
      const selectedBrand = brandsByKey[selectedItem.key]
      if (!selectedBrand) {
        return null
      }
      return (
        <div className="cui-brand">
          {selectedBrand?.bigLogo || selectedBrand?.logo}
          <div ref={target} onClick={handleBrandSelectionClick}>
            <Typo variant="body1">{selectedBrand.name}</Typo>
            <Icons.Caret.Down width={14} color={[theme.colors.icon]} />
          </div>
        </div>
      )
    }
  }

  return (
    <>
      {renderBrand()}
      <ContextMenu
        targetNode={target.current}
        onCloseHandler={handleClose}
        open={open}
        withBackdrop={false}
        alignX="left"
        className="cui-brand__modal"
        style={{
          '--cuiBrandMenuBg': theme.colors.input.background,
        }}
      >
        <div
          className="cui-brand__menu"
          style={{
            '--cuiBrandPrimary': theme.colors.primary.main,
            '--cuiBrandDivider': theme.colors.divider,
          }}
        >
          <div className="cui-brand__tabs">
            {children.map((child, index) => {
              if (!child || child.type !== BrandItem) {
                return null
              }

              const isActive = selectedItem.index === index
              return (
                <BrandItem
                  key={index}
                  {...child.props}
                  isActive={isActive}
                  onClick={() => handleChange(child.props.id, index)}
                />
              )
            })}
          </div>
          <div className="cui-brand__content">
            {children[selectedItem.index].props.children}
          </div>
        </div>
      </ContextMenu>
    </>
  )
}

Brand.propTypes = {
  children: (props, propName, componentName) => {
    const prop = props[propName]
    let error = null
    React.Children.forEach(prop, child => {
      if (!isValidType(child.type)) {
        error = new Error(
          `${componentName} children should be of type 'CuiBrandItem'`
        )
      }
    })
    return error
  },
  onChange: PropTypes.func,
  onClose: PropTypes.func,
}

Brand.Item = BrandItem

export default Brand
