import React from 'react'
import PropTypes from 'prop-types'

const PrintableHead = ({ children }) => {
  return (
    <div className="printable printable-head">
      <h1>{children}</h1>
    </div>
  )
}

PrintableHead.propTypes = {
  children: PropTypes.node,
}

export default PrintableHead
