import React from 'react'

const Company = ({ color }) => {
  return (
    <g className="icon-company">
      <g>
        <path
          fill={color[0]}
          d="M47.85,20.76H40.54V17.19a1.72,1.72,0,0,0-1.71-1.71H26.17a1.72,1.72,0,0,0-1.71,1.71v3.57H17.15A3.15,3.15,0,0,0,14,23.91V45.37a3.15,3.15,0,0,0,3.15,3.15h30.7A3.15,3.15,0,0,0,51,45.37V23.91A3.15,3.15,0,0,0,47.85,20.76ZM37.12,18.9v1.86H27.88V18.9ZM17.41,34.47a4.61,4.61,0,0,0,1.18.17h1.86c.06,1.81,1.84,3.22,4.11,3.22s4.05-1.41,4.11-3.22h7.66c.06,1.81,1.84,3.22,4.11,3.22s4.05-1.41,4.11-3.22h1.86a4.61,4.61,0,0,0,1.18-.17V45.1H17.41Zm30.18-8v3.56a1.17,1.17,0,0,1-1.18,1.17H18.59a1.17,1.17,0,0,1-1.18-1.17V24.18H47.59Z"
        />
      </g>
    </g>
  )
}

export default Company
