import React from 'react'
import PropTypes from 'prop-types'
import Typo from '../Typo'

const StepItem = ({ children, stepIndex, title, description }) => {
  return (
    <div className="cui-steps__item">
      <Typo isBold size="bigger">
        Step {stepIndex + 1}
        {title && ': ' + title}
      </Typo>
      <div className="cui-steps__description">{description}</div>
      {children}
    </div>
  )
}

StepItem.propTypes = {
  children: PropTypes.node,
  stepIndex: PropTypes.number,
  title: PropTypes.string,
  description: PropTypes.node,
}

export default StepItem
