const darkMode = {
  themeId: 'cui-dark-theme',
  type: 'dark',
  colors: {
    background: {
      body: '#05243B',
      level1: '#072F4C',
      level2: '#05243B',
      level3: '#001B30',
      disabled: '#0a304c',
      overlayLoader: 'rgba(6, 36, 59, 0.5)',
    },
    text: {
      primary: '#fff',
      secondary: '#818A91',
      disabled: '#4F5A60',
    },
    disabled: {
      button: {
        background: '#818A91',
        text: '#4F5A60',
      },
    },
    grey: {
      100: '#042b46',
      200: '#124873',
      300: '#223e4f',
      400: '#415059',
    },
    error: {
      main: '#e54545',
      fade: 'rgb(229, 69, 69, 0.2)',
    },
    warning: {
      main: '#FF8B00',
      fade: '#FF9D5A4D',
    },
    divider: '#1F4F87',
    boxShadow: '0px 1px 4px #09213d',
    input: {
      border: '#1F4F87',
      background: '#072F4C',
      disabled: '#072F4C',
    },
    icon: '#fff',
    tooltip: {
      background: 'rgba(79, 90, 96, 0.9)',
      text: '#fff',
      borderRadius: '5px',
    },
    modal: {
      background: '#001B30',
    },
    tabs: {
      background: '#001b30',
      itemBackground: '#072F4C',
      text: '#fff',
    },
    chart: {
      division: '#BFC9CF',
      text: '#BFC9CF',
    },
    datePicker: {
      itemHoverBackground: '#0e4e7d',
      disabled: '#686868',
    },
    shell: {
      color1: '#072F4C',
      color2: '#0d3c61',
    },
    menuList: {
      background: '#072F4C',
      itemHover: '#042b46',
      divider: '#1F4F87',
    },
    header: {
      background: '#001B30',
      divider: '#072F4C',
      boxShadow: '0 1px #072f4c',
    },
    navTree: {
      background: '#001B30',
      boxShadow: '0px 1px 4px #09213d',
      divider: '#072F4C',
    },
    card: {
      background: '#072F4C',
      boxShadow: '0 0 0 1px #1F4F87',
      divider: '#1F4F87',
    },
    green: {
      main: '#48DCC0',
      fade: 'rgba(72, 220, 192, 0.2)',
    },
  },
}

export default darkMode
