import React from 'react'

class Fail extends React.PureComponent {
  render() {
    return (
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M233.724 155.076C233.752 154.983 233.78 154.891 233.809 154.798C234.206 153.475 234.545 152.123 234.824 150.741C235.869 145.583 236.021 140.291 235.461 134.928C235.048 130.978 234.25 126.991 233.144 123.004C231.82 118.238 230.059 113.473 227.975 108.785C226.404 105.252 224.649 101.762 222.748 98.3518C222.053 97.2562 221.315 96.1703 220.59 95.0935C218.257 91.6334 215.624 88.2712 212.778 85.0476C208.226 79.8924 203.143 75.0945 197.754 70.7903C193.462 67.3625 188.97 64.2479 184.35 61.5019C177.648 57.5166 170.203 54.3963 162.226 52.032C155.791 50.125 148.945 48.7355 141.785 47.9463C135.801 47.2883 129.596 47.0495 123.303 47.3067C118.349 47.5089 113.34 48.0185 108.374 48.8803C103.542 50.2725 98.7119 52.0886 94.0232 54.2673C87.803 57.1566 81.8375 60.6877 76.4313 64.658C71.9069 67.9786 67.7743 71.605 64.152 75.377C57.5713 82.1805 52.9179 90.3033 50.4098 98.7257C48.0961 106.373 47.3956 114.571 48.3756 122.76C49.2499 130.16 51.4854 137.545 54.8771 144.505C57.3517 149.586 60.4344 154.44 64.0036 158.928C65.0587 160.253 66.1543 161.546 67.2894 162.804C70.866 166.366 74.7009 169.718 78.7215 172.828C83.8298 176.781 89.2343 180.346 94.8179 183.478C101.5 187.226 108.435 190.354 115.528 192.816C116.585 193.182 117.644 193.535 118.708 193.872L119.022 193.971C122.722 195.132 126.464 196.114 130.25 196.913C134.318 197.771 138.311 198.392 142.238 198.799C148.424 199.438 154.442 199.545 160.3 199.197C167.657 198.758 174.769 197.604 181.562 195.855C185.81 194.76 189.931 193.431 193.885 191.89C195.115 191.41 196.33 190.91 197.525 190.39C198.777 189.846 200.01 189.28 201.221 188.692C205.324 186.701 209.186 184.463 212.733 181.994C217.593 178.61 221.856 174.789 225.335 170.537C228.995 166.063 231.781 161.108 233.542 155.653L233.724 155.076Z"
          fill="#F8FCFF"
        />
        <mask
          id="fail_mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="287"
          height="247"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M233.724 155.076C233.752 154.983 233.78 154.891 233.809 154.798C234.206 153.475 234.544 152.123 234.824 150.741C235.869 145.583 236.021 140.291 235.461 134.928C235.047 130.978 234.249 126.991 233.143 123.004C231.82 118.238 230.059 113.473 227.975 108.785C226.404 105.252 224.649 101.762 222.748 98.3518C222.052 97.2562 221.315 96.1703 220.589 95.0935C218.257 91.6334 215.623 88.2712 212.778 85.0476C208.226 79.8924 203.142 75.0945 197.754 70.7903C193.462 67.3625 188.97 64.2479 184.35 61.5019C177.648 57.5166 170.203 54.3963 162.226 52.032C155.791 50.125 148.945 48.7355 141.784 47.9463C135.8 47.2883 129.596 47.0495 123.303 47.3067C118.349 47.5089 113.34 48.0185 108.374 48.8803C103.542 50.2725 98.7117 52.0886 94.023 54.2673C87.8028 57.1566 81.8373 60.6877 76.431 64.658C71.9067 67.9786 67.774 71.605 64.1517 75.377C57.571 82.1805 52.9176 90.3033 50.4095 98.7257C48.0959 106.373 47.3953 114.571 48.3754 122.76C49.2497 130.16 51.4851 137.545 54.8769 144.505C57.3515 149.586 60.4342 154.44 64.0034 158.928C65.0585 160.253 66.1541 161.546 67.2891 162.804C70.8657 166.366 74.7007 169.718 78.7212 172.828C83.8295 176.781 89.2341 180.346 94.8176 183.478C101.5 187.226 108.435 190.354 115.528 192.816C116.584 193.182 117.644 193.535 118.708 193.872L119.021 193.971C122.722 195.132 126.464 196.114 130.25 196.913C134.317 197.771 138.31 198.392 142.238 198.799C148.423 199.438 154.441 199.545 160.3 199.197C167.657 198.758 174.769 197.604 181.562 195.855C185.81 194.76 189.93 193.431 193.885 191.89C195.114 191.41 196.329 190.91 197.525 190.39C198.777 189.846 200.009 189.28 201.221 188.692C205.324 186.701 209.186 184.463 212.733 181.994C217.593 178.61 221.856 174.789 225.334 170.537C228.995 166.063 231.78 161.108 233.542 155.653L233.724 155.076Z"
            fill="#008587"
          />
        </mask>
        <g mask="url(#fail_mask0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M159.96 190.579L137.088 205.773L135.017 144.798L166.415 145.167L159.96 190.579Z"
            fill="#FACBBD"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M168.831 202.852C158.942 208.131 147.367 208.751 137.001 204.557L134.836 203.681L132.856 183.887C132.78 180.697 135.21 178.026 138.766 177.94L161.684 177.382C164.221 177.319 166.775 179.869 166.851 183.059L168.831 202.852Z"
            fill="url(#fail_paint0_linear)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M136.341 181.346C136.486 184.104 138.839 186.222 141.596 186.078L148.742 185.703C151.5 185.559 153.618 183.206 153.473 180.448L149.949 113.206L132.817 114.104L136.341 181.346Z"
            fill="#F65B7D"
          />
          <mask
            id="fail_mask1"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="132"
            y="113"
            width="22"
            height="74"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M136.341 181.346C136.486 184.104 138.839 186.222 141.596 186.078L148.742 185.703C151.5 185.559 153.618 183.206 153.473 180.448L149.949 113.206L132.817 114.104L136.341 181.346Z"
              fill="white"
            />
          </mask>
          <g mask="url(#fail_mask1)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M129.38 104.483L130.28 124.245L151.291 139.114L149.123 102.808L129.38 104.483Z"
              fill="#C64E73"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M165.706 146.639L157.976 151.618C157.089 152.188 155.906 151.939 155.331 151.061L141.628 130.137C138.937 126.027 140.118 120.531 144.266 117.859L146.09 116.685C146.974 116.115 148.159 116.364 148.733 117.242L166.269 144.016C166.845 144.894 166.593 146.069 165.706 146.639Z"
            fill="#FFCBBB"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M144.331 174.06L142.717 143.267C142.799 142.063 142.362 140.835 141.393 139.972L133.283 132.732C131.607 131.236 129 131.373 127.49 133.036L126.932 133.652C126.142 134.521 125.813 135.639 125.909 136.725C125.908 136.771 125.899 136.814 125.901 136.859L127.642 170.071C127.679 170.787 128.11 171.39 128.711 171.7C128.788 171.783 128.853 171.873 128.939 171.95L137.05 179.188C138.725 180.683 141.501 180.545 142.841 178.884C144.181 177.224 144.338 174.207 144.331 174.06Z"
            fill="#FACBBD"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M71.8515 103.586C71.9216 110.911 77.9682 116.835 85.302 116.769L190.083 115.791C197.417 115.722 203.351 109.684 203.281 102.359L202.795 50.9703C202.725 43.6454 196.676 37.7207 189.343 37.7898L84.5623 38.7667C77.2294 38.8348 71.2939 44.8729 71.3641 52.1976L71.8515 103.586ZM198.281 102.407C198.325 106.969 194.61 110.748 190.036 110.791L85.2556 111.769C80.6809 111.81 76.8949 108.101 76.8512 103.539L76.3639 52.1499C76.3202 47.5888 80.0358 43.8089 84.6088 43.7665L189.39 42.7895C193.964 42.7464 197.751 46.4562 197.795 51.0179L198.281 102.407Z"
          fill="#CED5DC"
        />
        <path
          d="M190.036 110.791C194.61 110.748 198.325 106.969 198.281 102.407L197.795 51.0179C197.751 46.4561 193.964 42.7464 189.39 42.7895L84.6088 43.7665C80.0357 43.8089 76.3201 47.5888 76.3639 52.1499L76.8512 103.539C76.8949 108.101 80.6809 111.81 85.2556 111.769L190.036 110.791Z"
          fill="url(#fail_paint1_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M146.453 54.8067L115.314 87.2801C112.077 90.656 112.193 96.013 115.572 99.2452L115.845 99.5073C119.225 102.74 124.588 102.624 127.826 99.248L158.963 66.7747C162.201 63.3996 162.084 58.041 158.705 54.8087L158.433 54.5482C156.675 52.8666 154.379 52.0911 152.126 52.2092C150.048 52.3181 148.005 53.1871 146.453 54.8067Z"
          fill="#F65B7D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M125.553 55.902L159.915 84.9427C163.487 87.9617 163.932 93.3015 160.91 96.8692L160.665 97.1584C157.642 100.726 152.296 101.172 148.723 98.1529L114.362 69.1121C110.789 66.094 110.345 60.7525 113.368 57.1848L113.611 56.8973C115.183 55.0411 117.385 54.0299 119.639 53.9118C121.717 53.8029 123.839 54.4535 125.553 55.902Z"
          fill="url(#fail_paint2_linear)"
        />
        <defs>
          <linearGradient
            id="fail_paint0_linear"
            x1="115.511"
            y1="186.674"
            x2="130.769"
            y2="217.601"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#52CFD1" />
            <stop offset="0.979133" stopColor="#06B2B4" />
            <stop offset="1" stopColor="#00B0B2" />
          </linearGradient>
          <linearGradient
            id="fail_paint1_linear"
            x1="33.9936"
            y1="55.5128"
            x2="59.3608"
            y2="120.712"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#EBF0F5" />
          </linearGradient>
          <linearGradient
            id="fail_paint2_linear"
            x1="134.754"
            y1="31.5219"
            x2="180.212"
            y2="78.9502"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F291B0" />
            <stop offset="1" stopColor="#F65B7D" />
          </linearGradient>
        </defs>
      </g>
    )
  }
}

export default Fail
