import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import MenuListItem from './MenuListItem'
import { useTheme } from '../Theme'

const MenuList = ({ children, className }) => {
  const theme = useTheme()

  const _MenuList = classnames('cui-menulist', className)
  return (
    <div
      className={_MenuList}
      style={{
        '--cuiMenuListBg': theme.colors.menuList.background,
        '--cuiMenuListItemHoverBg': theme.colors.menuList.itemHover,
        '--cuiMenuListDivider': theme.colors.menuList.divider,
      }}
    >
      <ul>{children}</ul>
    </div>
  )
}

MenuList.Item = MenuListItem

export default MenuList
