import React from 'react'
import { ContextConsumer } from './context'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const _TabItem = classnames('cui--tab-item')

const TabItem = (props) => {
  return (
    <ContextConsumer>
      {({ selectedTabId }) => {
        function initClassName() {
          if (props.tabId === selectedTabId) {
            return _TabItem + ' is-active'
          } else {
            return _TabItem
          }
        }
        function displayItem() {
          if (props.tabId === selectedTabId) {
            return <>{props.children}</>
          }
        }
        return (
          <div
            className={initClassName()}
            id={props.id}
            data-testid={'tab-item-test-id-' + props.tabId}
          >
            {displayItem()}
          </div>
        )
      }}
    </ContextConsumer>
  )
}

TabItem.propTypes = {
  tabId: PropTypes.string,
}

export default TabItem
