const STORAGE_KEY = process.env.REACT_APP_STORAGE_KEY || 'casperui:store'

const Retrieve = (key = STORAGE_KEY, isSession = true) => {
  const storage = isSession ? 'sessionStorage' : 'localStorage'
  const data = window[storage].getItem(key)
  return data
}

const Save = (key = STORAGE_KEY, value, isSession) => {
  const storage = isSession ? 'sessionStorage' : 'localStorage'
  let storeValue = value
  if (typeof storeValue === 'object') {
    storeValue = JSON.stringify(value)
  }

  window[storage].setItem(key, storeValue)
}

const Delete = (key = STORAGE_KEY, isSession) => {
  const storage = isSession ? 'sessionStorage' : 'localStorage'
  const data = Retrieve(key)
  if (!data) return false

  window[storage].removeItem(key)
  return true
}

const Clear = isSession => {
  const storage = isSession ? 'sessionStorage' : 'localStorage'
  window[storage].clear()
}

export default {
  Retrieve,
  Save,
  Delete,
  Clear,
}
