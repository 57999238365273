import React from 'react'

const CalendarIcon = () => {
  return (
    <g className="icon-calendar">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3.88235C16 2.84276 15.2041 2 14.2222 2H12V1C12 0.447715 11.5523 0 11 0C10.4477 0 10 0.447715 10 1V2H6V1C6 0.447715 5.55228 0 5 0C4.44772 0 4 0.447715 4 1V2H1.77778C0.795938 2 0 2.84276 0 3.88235V16.1176C0 17.1572 0.795938 18 1.77778 18H14.2222C15.2041 18 16 17.1572 16 16.1176V3.88235ZM6 4V4.99739C6 5.54968 5.55228 5.99739 5 5.99739C4.44772 5.99739 4 5.54968 4 4.99739V4H2V7.0043L14 7.05084V4H12V4.99739C12 5.54968 11.5523 5.99739 11 5.99739C10.4477 5.99739 10 5.54968 10 4.99739V4H6ZM14 8.9957L2 8.94916V16H14V8.9957Z"
      />
    </g>
  )
}

export default CalendarIcon
