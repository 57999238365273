// organize-imports-ignore
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const AccordionTitle = ({ children, className, toggleExpandContent }) => {
  const _AccordionTitle = classnames('cui-accordion__item-title', className)

  return (
    <div
      className={_AccordionTitle}
      onClick={toggleExpandContent}
      data-testid="accordion-item-title"
    >
      {children}
    </div>
  )
}

AccordionTitle.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  toggleExpandContent: PropTypes.func,
}

export default AccordionTitle
