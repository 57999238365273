import React from 'react'

class DigitalTwin extends React.PureComponent {
  render() {
    if (this.props.noColors) {
      return (
        <g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.9342 18.2122C23.2278 17.7037 23.878 17.5295 24.3865 17.823C24.895 18.1166 25.0693 18.7668 24.7757 19.2753L23.873 20.8389L26.3259 20.8389C26.8888 20.8389 27.3495 21.2763 27.3867 21.8297C27.4121 22.0494 27.3694 22.2789 27.2499 22.4849L25.4562 25.575C25.1614 26.0828 24.5108 26.2555 24.003 25.9608C23.4952 25.666 23.3225 25.0154 23.6172 24.5076L24.5125 22.9652H22.0733C21.5027 22.9652 21.0371 22.5157 21.0113 21.9515C20.9879 21.7351 21.0305 21.5095 21.1477 21.3065L22.9342 18.2122Z"
            fill="url(#digital-twin-paint0_linear)"
          />
          <path
            d="M14.4 11.6C14.4 10.4402 15.3402 9.5 16.5 9.5H31.9C33.0598 9.5 34 10.4402 34 11.6V28.4C34 29.5598 33.0598 30.5 31.9 30.5C30.7402 30.5 29.8 29.5598 29.8 28.4V13.7H18.6V18.1333C18.6 19.2931 17.6598 20.2333 16.5 20.2333H10.2V28.4C10.2 29.5598 9.2598 30.5 8.1 30.5C6.9402 30.5 6 29.5598 6 28.4V18.1333C6 16.9735 6.9402 16.0333 8.1 16.0333H14.4V11.6Z"
            fill="url(#digital-twin-paint1_linear)"
          />
          <defs>
            <linearGradient
              id="digital-twin-paint0_linear"
              x1="21.4125"
              y1="19.0833"
              x2="24.1993"
              y2="26.1047"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#C4C4C4" />
              <stop offset="1" stopColor="#E3E3E3" />
            </linearGradient>
            <linearGradient
              id="digital-twin-paint1_linear"
              x1="36.1"
              y1="8.8"
              x2="1.56667"
              y2="32.8333"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#4F5A60" />
              <stop offset="1" stopColor="#D8D8D8" />
            </linearGradient>
          </defs>
        </g>
      )
    }

    return (
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.9345 18.2122C23.2281 17.7037 23.8783 17.5295 24.3868 17.823C24.8953 18.1166 25.0695 18.7668 24.7759 19.2753L23.8732 20.8389L26.3262 20.8389C26.889 20.8389 27.3497 21.2763 27.3869 21.8297C27.4123 22.0494 27.3697 22.2789 27.2501 22.4849L25.4564 25.575C25.1616 26.0828 24.511 26.2555 24.0032 25.9608C23.4954 25.666 23.3227 25.0154 23.6175 24.5076L24.5128 22.9652H22.0736C21.503 22.9652 21.0374 22.5157 21.0115 21.9515C20.9881 21.7351 21.0308 21.5095 21.148 21.3065L22.9345 18.2122Z"
          fill="url(#digital-twin-paint0_linear)"
        />
        <path
          d="M14.4 11.6C14.4 10.4402 15.3402 9.5 16.5 9.5H31.9C33.0598 9.5 34 10.4402 34 11.6V28.4C34 29.5598 33.0598 30.5 31.9 30.5C30.7402 30.5 29.8 29.5598 29.8 28.4V13.7H18.6V18.1333C18.6 19.2931 17.6598 20.2333 16.5 20.2333H10.2V28.4C10.2 29.5598 9.2598 30.5 8.1 30.5C6.9402 30.5 6 29.5598 6 28.4V18.1333C6 16.9735 6.9402 16.0333 8.1 16.0333H14.4V11.6Z"
          fill="url(#digital-twin-paint1_linear)"
        />
        <defs>
          <linearGradient
            id="digital-twin-paint0_linear"
            x1="21.4128"
            y1="19.0833"
            x2="24.1996"
            y2="26.1047"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFD049" />
            <stop offset="1" stopColor="#FFAB49" />
          </linearGradient>
          <linearGradient
            id="digital-twin-paint1_linear"
            x1="36.1"
            y1="8.8"
            x2="1.56667"
            y2="32.8333"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#62E6C6" />
            <stop offset="1" stopColor="#2C94CF" />
          </linearGradient>
        </defs>
      </g>
    )
  }
}

export default DigitalTwin
