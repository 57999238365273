import React from 'react'
import PropTypes from 'prop-types'

import { isValidType } from './NavTree'
import NavTreeListItem from './NavTreeListItem'

function NavTreeList({ children, onCloseMenu, id, ...props }) {
  const renderList = () => {
    return React.Children.map(children, child => {
      if (isValidType(child.type, NavTreeListItem)) {
        return React.cloneElement(child, {
          onClick: onCloseMenu,
        })
      }
    })
  }
  return (
    <ul id={id} {...props}>
      {renderList()}
    </ul>
  )
}

NavTreeList.propTypes = {
  id: PropTypes.string,
  children: (props, propName, componentName) => {
    const prop = props[propName]
    let error = null
    React.Children.forEach(prop, child => {
      if (child.type !== NavTreeListItem) {
        error = new Error(
          `${componentName} children should be of type 'NavTreeListItem'`
        )
      }
    })
    return error
  },
}

export default NavTreeList
