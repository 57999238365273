// organize-imports-ignore
import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import ToggleButtonGroup from './ToggleButtonGroup'

const ToggleButton = ({
  index,
  children,
  className,
  disabled,
  value,
  selected,
  onChange,
  multiple,
}) => {
  const _Button = classnames('cui-toggle-button', className, {
    'is-active': multiple
      ? selected && selected.includes(value)
      : selected === value,
  })

  const handleChange = () => {
    onChange(value)
  }

  return (
    <button
      id={index}
      onClick={() => handleChange()}
      disabled={disabled}
      className={_Button}
    >
      {children}
    </button>
  )
}

ToggleButton.Group = ToggleButtonGroup
ToggleButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  value: PropTypes.string.isRequired,
  selected: PropTypes.any,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
}

ToggleButton.defaultProps = {
  disabled: false,
}

export default ToggleButton
