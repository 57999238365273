import React from 'react'
import propTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'

import Icons from '../../Icons'
import ContextMenu from '../../ContextMenu'

import './styles.scss'

class FieldTimePicker extends React.PureComponent {
  state = {
    open: false,
  }

  selectRef = React.createRef()

  handleChange = e => {
    const { onChangeHandler } = this.props
    const { value } = e.target
    if (onChangeHandler) {
      onChangeHandler(value)
    }
  }

  handleItemClick = e => {
    const { onChangeHandler, id } = this.props
    const val = e.target.getAttribute('value')
    var menuContainer = document.getElementById(`${id}-menu`)
    var liActives = menuContainer.getElementsByTagName('li')
    for (let i = 0; i < liActives.length; i++) {
      liActives[i].className = liActives[i].className.replace(' active', '')
    }
    e.target.className = e.target.className.includes('active')
      ? e.target.className
      : e.target.className + ' active'

    if (onChangeHandler) {
      onChangeHandler(val)
    }
  }

  handleSelectClose = () => {
    this.setState(prevState => ({
      ...prevState,
      open: false,
    }))
  }

  handleSelectOpen = () => {
    this.setState(prevState => ({
      ...prevState,
      open: true,
    }))
  }

  renderOptions() {
    const { resolveOption, id, timeslot } = this.props
    const options = []
    var start, end
    for (let i = 0; i < timeslot.length; i++) {
      start = moment(timeslot[i].start).format('HH:mm')
      end = moment(timeslot[i].end).format('HH:mm')
      options.push(
        <li
          className="cui-fieldtimeslot"
          key={`time${i}`}
          id={`time${i}`}
          start={timeslot[i].start}
          end={timeslot[i].end}
          value={`${start} - ${end}`}
          onClick={this.handleItemClick}
        >
          {`${start} - ${end}`}
        </li>
      )
    }
    return (
      <ContextMenu
        targetNode={this.selectRef.current}
        onCloseHandler={this.handleSelectClose}
        open={this.state.open}
      >
        <div className="cui-fieldtimeslotlist">
          <ul>{options}</ul>
        </div>
      </ContextMenu>
    )
  }

  renderValue() {
    const { onSelected, value } = this.props
    if (onSelected) {
      return <>{onSelected(value)}</>
    }
    return value
  }

  render() {
    const { id, name, value, children } = this.props
    const _FieldTimePicker = classnames('cui-fieldtimepicker')
    return (
      <>
        <div className={_FieldTimePicker} id={_FieldTimePicker}>
          <div
            className="cui-fieldtimepicker__control"
            ref={this.selectRef}
            onClick={this.handleSelectOpen}
          >
            <div className="cui-fieldtimepicker__control_selected">
              {this.renderValue()}
              <i className="cui-fieldtimepicker__icon">
                <Icons.Clock width={13} height={13} />
              </i>
            </div>
            {this.renderOptions()}
          </div>
          <select
            className="cui-fieldtimepicker-select"
            id={id}
            name={name}
            data-testid="test-select"
            onChange={this.handleChange}
            value={value}
          />
        </div>
      </>
    )
  }
}

FieldTimePicker.propTypes = {
  timeslot: propTypes.object.isRequired,
  id: propTypes.string,
  name: propTypes.string,
  onChangeHandler: propTypes.func,
}

FieldTimePicker.defaultProps = {
  name: 'timepicker',
}

export default FieldTimePicker
