import React from 'react'

const Switchgear = () => {
  return (
    <g className="illus-switchgear">
      <path
        d="M0 2.54417C0 1.13906 1.13906 0 2.54417 0H30.5268C31.9319 0 33.071 1.13906 33.071 2.54417H0Z"
        fill="#B93939"
      />
      <path d="M0 2.54395H33.071V79.9998H0V2.54395Z" fill="#D5D5D5" />
      <rect y="3.39258" width="1.41329" height="1.41343" fill="#4F5A60" />
      <rect y="7.63281" width="1.41329" height="1.41343" fill="#4F5A60" />
      <rect y="13.2861" width="1.41329" height="1.41343" fill="#4F5A60" />
      <rect y="24.876" width="1.41329" height="1.41343" fill="#4F5A60" />
      <rect y="38.1621" width="1.41329" height="1.41343" fill="#4F5A60" />
      <rect y="46.3604" width="1.41329" height="1.41343" fill="#4F5A60" />
      <rect y="58.2334" width="1.41329" height="1.41343" fill="#4F5A60" />
      <rect y="70.3887" width="1.41329" height="1.41343" fill="#4F5A60" />
      <ellipse
        cx="30.9513"
        cy="6.36003"
        rx="0.706646"
        ry="0.706714"
        fill="#4F5A60"
      />
      <ellipse
        cx="30.9513"
        cy="13.9928"
        rx="0.706646"
        ry="0.706714"
        fill="#4F5A60"
      />
      <ellipse
        cx="30.9513"
        cy="38.8688"
        rx="0.706646"
        ry="0.706715"
        fill="#4F5A60"
      />
      <ellipse
        cx="30.9513"
        cy="47.0671"
        rx="0.706646"
        ry="0.706713"
        fill="#4F5A60"
      />
      <ellipse
        cx="30.9513"
        cy="71.0954"
        rx="0.706646"
        ry="0.706715"
        fill="#4F5A60"
      />
      <rect
        x="14.6982"
        y="6.21875"
        width="5.37051"
        height="2.82686"
        rx="1"
        fill="#3176DE"
      />
      <rect
        x="15.5464"
        y="7.06641"
        width="1.13063"
        height="0.282685"
        rx="0.141343"
        fill="white"
      />
      <rect
        x="15.5464"
        y="7.91406"
        width="1.13063"
        height="0.282687"
        rx="0.141344"
        fill="white"
      />
      <rect
        x="17.8076"
        y="7.91406"
        width="1.13063"
        height="0.282687"
        rx="0.141344"
        fill="white"
      />
      <rect
        x="5.93604"
        y="11.873"
        width="3.95721"
        height="3.39223"
        rx="1"
        fill="#BFC9CF"
      />
      <rect
        x="6.46387"
        y="12.3945"
        width="2.90196"
        height="2.34847"
        rx="1"
        fill="#BFC9CF"
      />
      <rect
        x="11.5894"
        y="11.873"
        width="3.95721"
        height="3.39223"
        rx="1"
        fill="#BFC9CF"
      />
      <rect
        x="12.1172"
        y="12.3945"
        width="2.90196"
        height="2.34847"
        rx="1"
        fill="#BFC9CF"
      />
      <rect
        x="17.2422"
        y="11.873"
        width="3.95722"
        height="3.39223"
        rx="1"
        fill="#BFC9CF"
      />
      <rect
        x="17.77"
        y="12.3945"
        width="2.90195"
        height="2.34847"
        rx="1"
        fill="#BFC9CF"
      />
      <rect
        x="22.8955"
        y="11.873"
        width="3.95721"
        height="3.39223"
        rx="1"
        fill="#BFC9CF"
      />
      <rect
        x="23.4233"
        y="12.3945"
        width="2.90197"
        height="2.34847"
        rx="1"
        fill="#BFC9CF"
      />
      <ellipse
        cx="13.1437"
        cy="17.3854"
        rx="0.706645"
        ry="0.706714"
        fill="#E54545"
      />
      <ellipse
        cx="16.253"
        cy="17.3854"
        rx="0.706645"
        ry="0.706714"
        fill="#FFC466"
      />
      <ellipse
        cx="19.3619"
        cy="17.3854"
        rx="0.706645"
        ry="0.706714"
        fill="#48DCC0"
      />
      <ellipse
        cx="13.1437"
        cy="20.2116"
        rx="0.706645"
        ry="0.706714"
        fill="#48DCC0"
      />
      <ellipse
        cx="16.253"
        cy="20.2116"
        rx="0.706645"
        ry="0.706714"
        fill="#FFC466"
      />
      <ellipse
        cx="19.3619"
        cy="20.2116"
        rx="0.706645"
        ry="0.706714"
        fill="#E54545"
      />
      <rect
        x="0.282715"
        y="9.89355"
        width="32.5057"
        height="0.565371"
        rx="0.282685"
        fill="#D2DCE1"
      />
      <path
        d="M0.282715 10.1762C0.282715 10.0201 0.409277 9.89355 0.5654 9.89355H32.5057C32.6618 9.89355 32.7884 10.0201 32.7884 10.1762H0.282715Z"
        fill="#9CA4AB"
      />
      <rect
        x="0.56543"
        y="43.251"
        width="31.9404"
        height="0.565372"
        rx="0.282686"
        fill="#D2DCE1"
      />
      <path
        d="M0.56543 43.5337C0.56543 43.3775 0.691992 43.251 0.848114 43.251H32.2231C32.3792 43.251 32.5058 43.3775 32.5058 43.5337H0.56543Z"
        fill="#9CA4AB"
      />
      <rect
        x="0.282715"
        y="74.9121"
        width="32.5057"
        height="0.565376"
        rx="0.282688"
        fill="#D2DCE1"
      />
      <path
        d="M0.282715 75.1948C0.282715 75.0387 0.409277 74.9121 0.565399 74.9121H32.5057C32.6618 74.9121 32.7884 75.0387 32.7884 75.1948H0.282715Z"
        fill="#9CA4AB"
      />
      <rect
        x="8.76221"
        y="27.9863"
        width="15.5462"
        height="14.417"
        rx="1"
        fill="#4F5A60"
      />
      <rect
        x="10.458"
        y="30.248"
        width="3.3919"
        height="7.9152"
        rx="1"
        fill="#BFC9CF"
      />
      <rect
        x="19.7856"
        y="35.3359"
        width="2.26127"
        height="4.80565"
        rx="1"
        fill="#BFC9CF"
      />
      <rect
        x="14.6982"
        y="32.792"
        width="1.41329"
        height="0.565372"
        rx="0.282686"
        fill="#E54545"
      />
      <rect
        x="14.6982"
        y="34.4883"
        width="1.41329"
        height="0.565361"
        rx="0.282681"
        fill="#B93939"
      />
      <rect
        x="16.9595"
        y="32.792"
        width="1.41329"
        height="0.565372"
        rx="0.282686"
        fill="#48DCC0"
      />
      <rect
        x="16.9595"
        y="34.4883"
        width="1.41329"
        height="0.565361"
        rx="0.282681"
        fill="#E7ECEF"
      />
      <rect
        x="10.458"
        y="40.4248"
        width="1.41329"
        height="0.565372"
        rx="0.282686"
        fill="#BFC9CF"
      />
      <rect
        x="14.1328"
        y="40.4248"
        width="1.41329"
        height="0.565372"
        rx="0.282686"
        fill="#BFC9CF"
      />
      <ellipse
        cx="8.47964"
        cy="18.9402"
        rx="1.69595"
        ry="1.69611"
        fill="#FFC466"
      />
      <ellipse
        cx="8.47946"
        cy="18.94"
        rx="1.21139"
        ry="1.21151"
        fill="#FFDEB3"
      />
      <ellipse
        cx="8.47977"
        cy="18.9398"
        rx="0.726836"
        ry="0.726908"
        fill="#B93939"
      />
      <path
        d="M7.99512 19.1828C7.99512 18.9152 8.21206 18.6982 8.47967 18.6982C8.74729 18.6982 8.96423 18.9152 8.96423 19.1828V20.6367H7.99512V19.1828Z"
        fill="#E54545"
      />
      <rect
        x="7.63184"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="7.91455"
        y="76.3242"
        width="0.847976"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="8.7627"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="9.04492"
        y="76.3242"
        width="0.847975"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="9.89307"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="10.1758"
        y="76.3242"
        width="0.847978"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="11.0239"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="11.3062"
        y="76.3242"
        width="0.847975"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="12.1543"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="12.437"
        y="76.3242"
        width="0.847975"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="13.2852"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="13.5679"
        y="76.3242"
        width="0.847974"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="14.4155"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="14.6982"
        y="76.3242"
        width="0.847975"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="15.5464"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="15.8291"
        y="76.3242"
        width="0.847971"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="16.6768"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="16.9595"
        y="76.3242"
        width="0.847975"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="17.8076"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="18.0903"
        y="76.3242"
        width="0.847975"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="18.938"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="19.2207"
        y="76.3242"
        width="0.847975"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="20.0688"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="20.3516"
        y="76.3242"
        width="0.847982"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="21.1992"
        y="76.042"
        width="1.41328"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="21.4819"
        y="76.3242"
        width="0.847975"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="22.3301"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="22.6128"
        y="76.3242"
        width="0.847975"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="23.4604"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="23.7432"
        y="76.3242"
        width="0.847975"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="24.5913"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="24.874"
        y="76.3242"
        width="0.847975"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="25.7222"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="26.0044"
        y="76.3242"
        width="0.847975"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="0.848145"
        y="77.4551"
        width="0.565316"
        height="0.565369"
        fill="#BFC9CF"
      />
      <rect
        x="31.6577"
        y="77.4551"
        width="0.565315"
        height="0.565369"
        fill="#BFC9CF"
      />
      <path
        d="M15.5688 68.7678C15.9659 68.1873 16.8223 68.1873 17.2194 68.7678L19.2402 71.7212C19.6943 72.3849 19.219 73.2859 18.4149 73.2859H14.3734C13.5692 73.2859 13.0939 72.3849 13.548 71.7212L15.5688 68.7678Z"
        fill="#FFC466"
      />
      <path
        d="M15.5568 69.4102C15.9514 68.8056 16.8371 68.8056 17.2317 69.4102L18.5673 71.4568C19.0014 72.1219 18.5241 73.0033 17.7298 73.0033H15.0587C14.2645 73.0033 13.7872 72.1219 14.2213 71.4568L15.5568 69.4102Z"
        fill="#4F5A60"
      />
      <path
        d="M15.9782 69.3142C16.1762 69.0181 16.6116 69.0181 16.8096 69.3142L18.5666 71.9428C18.7887 72.275 18.5506 72.7207 18.1509 72.7207H14.6369C14.2372 72.7207 13.9991 72.275 14.2212 71.9428L15.9782 69.3142Z"
        fill="#FFC466"
      />
      <path
        d="M15.5459 71.378L16.3939 69.8232L16.2525 70.954H17.2418L16.2525 72.3674L16.3939 71.378H15.5459Z"
        fill="#4F5A60"
      />
      <path
        d="M33.3535 2.54417C33.3535 1.13906 34.4926 0 35.8977 0H55.118C56.5231 0 57.6621 1.13906 57.6621 2.54417H33.3535Z"
        fill="#B93939"
      />
      <path
        opacity="0.5"
        d="M57.6621 2.54395H33.3535V79.9998H57.6621V2.54395Z"
        fill="#C4C4C4"
      />
      <rect
        x="33.3535"
        y="3.3916"
        width="1.4133"
        height="1.41343"
        fill="#4F5A60"
      />
      <rect
        x="33.3535"
        y="7.63184"
        width="1.4133"
        height="1.41343"
        fill="#4F5A60"
      />
      <ellipse
        cx="56.1075"
        cy="6.36003"
        rx="0.706644"
        ry="0.706714"
        fill="#4F5A60"
      />
      <rect
        x="33.3535"
        y="13.0029"
        width="1.4133"
        height="1.41343"
        fill="#4F5A60"
      />
      <rect
        x="33.3535"
        y="26.5723"
        width="1.4133"
        height="1.41343"
        fill="#4F5A60"
      />
      <ellipse
        cx="56.1075"
        cy="13.7096"
        rx="0.706644"
        ry="0.706714"
        fill="#4F5A60"
      />
      <ellipse
        cx="56.1075"
        cy="27.279"
        rx="0.706644"
        ry="0.706715"
        fill="#4F5A60"
      />
      <rect
        x="33.3535"
        y="70.3887"
        width="1.4133"
        height="1.41343"
        fill="#4F5A60"
      />
      <ellipse
        cx="56.1075"
        cy="71.0954"
        rx="0.706644"
        ry="0.706715"
        fill="#4F5A60"
      />
      <rect
        x="42.6812"
        y="6.21875"
        width="5.37051"
        height="2.82686"
        rx="1"
        fill="#3176DE"
      />
      <rect
        x="43.5293"
        y="7.06641"
        width="1.13063"
        height="0.282685"
        rx="0.141343"
        fill="white"
      />
      <rect
        x="43.5293"
        y="7.91406"
        width="1.13063"
        height="0.282687"
        rx="0.141344"
        fill="white"
      />
      <rect
        x="45.7905"
        y="7.91406"
        width="1.13062"
        height="0.282687"
        rx="0.141344"
        fill="white"
      />
      <rect
        x="37.876"
        y="11.873"
        width="3.95721"
        height="3.39223"
        rx="1"
        fill="#BFC9CF"
      />
      <rect
        x="38.4038"
        y="12.3945"
        width="2.90196"
        height="2.34847"
        rx="1"
        fill="#BFC9CF"
      />
      <rect
        x="43.5293"
        y="11.873"
        width="3.95721"
        height="3.39223"
        rx="1"
        fill="#BFC9CF"
      />
      <rect
        x="44.0571"
        y="12.3945"
        width="2.90196"
        height="2.34847"
        rx="1"
        fill="#BFC9CF"
      />
      <rect
        x="49.1821"
        y="11.873"
        width="3.95722"
        height="3.39223"
        rx="1"
        fill="#BFC9CF"
      />
      <rect
        x="49.71"
        y="12.3945"
        width="2.90195"
        height="2.34847"
        rx="1"
        fill="#BFC9CF"
      />
      <rect
        x="33.6362"
        y="74.9121"
        width="23.7433"
        height="0.565376"
        rx="0.282688"
        fill="#D2DCE1"
      />
      <path
        d="M33.6362 75.1948C33.6362 75.0387 33.7628 74.9121 33.9189 74.9121H57.0968C57.253 74.9121 57.3795 75.0387 57.3795 75.1948H33.6362Z"
        fill="#9CA4AB"
      />
      <rect
        x="33.6362"
        y="31.0957"
        width="23.7433"
        height="0.565363"
        rx="0.282681"
        fill="#D2DCE1"
      />
      <path
        d="M33.6362 31.3784C33.6362 31.2223 33.7628 31.0957 33.9189 31.0957H57.0968C57.253 31.0957 57.3795 31.2223 57.3795 31.3784H33.6362Z"
        fill="#9CA4AB"
      />
      <rect
        x="33.6362"
        y="9.89355"
        width="23.7433"
        height="0.565371"
        rx="0.282685"
        fill="#D2DCE1"
      />
      <path
        d="M33.6362 10.1762C33.6362 10.0201 33.7628 9.89355 33.9189 9.89355H57.0968C57.253 9.89355 57.3795 10.0201 57.3795 10.1762H33.6362Z"
        fill="#9CA4AB"
      />
      <rect
        x="35.8975"
        y="76.042"
        width="1.41328"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="36.1802"
        y="76.3242"
        width="0.847977"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="37.0283"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="37.3105"
        y="76.3242"
        width="0.847973"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="38.1587"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="38.4414"
        y="76.3242"
        width="0.847973"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="39.2896"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="39.5718"
        y="76.3242"
        width="0.847973"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="40.4199"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="40.7026"
        y="76.3242"
        width="0.847988"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="41.5508"
        y="76.042"
        width="1.41328"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="41.8335"
        y="76.3242"
        width="0.847973"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="42.6812"
        y="76.042"
        width="1.4133"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="42.9639"
        y="76.3242"
        width="0.847973"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="43.812"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="44.0947"
        y="76.3242"
        width="0.847977"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="44.9424"
        y="76.042"
        width="1.41328"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="45.2251"
        y="76.3242"
        width="0.847977"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="46.0732"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="46.356"
        y="76.3242"
        width="0.847977"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="47.2036"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="47.4863"
        y="76.3242"
        width="0.847977"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="48.3345"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="48.6172"
        y="76.3242"
        width="0.847977"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="49.4648"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="49.7476"
        y="76.3242"
        width="0.847977"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="50.5957"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="50.8784"
        y="76.3242"
        width="0.847973"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="51.7261"
        y="76.042"
        width="1.4133"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="52.0088"
        y="76.3242"
        width="0.847973"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="52.8569"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="53.1396"
        y="76.3242"
        width="0.847973"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="53.9878"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="54.27"
        y="76.3242"
        width="0.847973"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="34.2017"
        y="77.4551"
        width="0.565323"
        height="0.565369"
        fill="#BFC9CF"
      />
      <rect
        x="56.249"
        y="77.4551"
        width="0.565315"
        height="0.565369"
        fill="#BFC9CF"
      />
      <path
        d="M44.6826 68.7678C45.0797 68.1873 45.9361 68.1873 46.3332 68.7678L48.354 71.7212C48.808 72.3849 48.3328 73.2859 47.5286 73.2859H43.4871C42.683 73.2859 42.2077 72.3849 42.6618 71.7212L44.6826 68.7678Z"
        fill="#FFC466"
      />
      <path
        d="M44.6706 69.4102C45.0652 68.8056 45.9509 68.8056 46.3455 69.4102L47.6811 71.4568C48.1151 72.1219 47.6379 73.0033 46.8436 73.0033H44.1725C43.3782 73.0033 42.901 72.1219 43.335 71.4568L44.6706 69.4102Z"
        fill="#4F5A60"
      />
      <path
        d="M45.092 69.3143C45.29 69.0181 45.7254 69.0181 45.9234 69.3143L47.6804 71.9428C47.9025 72.275 47.6643 72.7207 47.2647 72.7207H43.7506C43.351 72.7207 43.1129 72.275 43.335 71.9428L45.092 69.3143Z"
        fill="#FFC466"
      />
      <path
        d="M44.6602 71.378L45.5081 69.8232L45.3668 70.954H46.3561L45.3668 72.3674L45.5081 71.378H44.6602Z"
        fill="#4F5A60"
      />
      <rect
        x="42.6812"
        y="16.6787"
        width="5.37051"
        height="4.80565"
        rx="1"
        fill="#BFC9CF"
      />
      <rect
        x="43.2778"
        y="17.2441"
        width="4.17705"
        height="3.67491"
        rx="1"
        fill="#BFC9CF"
      />
      <rect
        x="43.8745"
        y="17.8096"
        width="1.49181"
        height="0.565372"
        rx="0.282686"
        fill="#B93939"
      />
      <rect
        x="43.8745"
        y="19.2227"
        width="1.49181"
        height="0.565372"
        rx="0.282686"
        fill="#C1904F"
      />
      <rect
        x="43.2466"
        y="22.6152"
        width="4.23986"
        height="4.24028"
        rx="1"
        fill="#4F5A60"
      />
      <rect
        width="0.84797"
        height="3.10953"
        rx="0.423985"
        transform="matrix(0.979027 -0.203733 0.20369 0.979035 44.9424 26.4629)"
        fill="#4F5A60"
      />
      <rect
        width="0.847973"
        height="2.54417"
        rx="0.423986"
        transform="matrix(1 0 0 -1 44.9424 25.1592)"
        fill="#65A1DB"
      />
      <ellipse
        cx="42.3988"
        cy="35.3359"
        rx="0.565317"
        ry="0.565372"
        fill="#E54545"
      />
      <ellipse
        cx="45.5082"
        cy="35.3359"
        rx="0.56531"
        ry="0.565372"
        fill="#E54545"
      />
      <ellipse
        cx="48.6171"
        cy="35.3359"
        rx="0.565315"
        ry="0.565372"
        fill="#E54545"
      />
      <ellipse
        cx="42.3988"
        cy="38.4453"
        rx="0.565317"
        ry="0.565371"
        fill="#E54545"
      />
      <ellipse
        cx="45.5082"
        cy="38.4453"
        rx="0.56531"
        ry="0.565371"
        fill="#E54545"
      />
      <ellipse
        cx="48.6171"
        cy="38.4453"
        rx="0.565315"
        ry="0.565371"
        fill="#E54545"
      />
      <path
        d="M82.2534 2.54395H57.9448V79.9998H82.2534V2.54395Z"
        fill="#D5D5D5"
      />
      <path
        d="M57.9448 2.54417C57.9448 1.13906 59.0839 0 60.489 0H79.7093C81.1144 0 82.2534 1.13906 82.2534 2.54417H57.9448Z"
        fill="#B93939"
      />
      <rect
        x="57.9448"
        y="3.39258"
        width="1.41329"
        height="1.41343"
        fill="#4F5A60"
      />
      <rect
        x="57.9448"
        y="7.63281"
        width="1.41329"
        height="1.41343"
        fill="#4F5A60"
      />
      <rect
        x="57.9448"
        y="11.3076"
        width="1.41329"
        height="1.41343"
        fill="#4F5A60"
      />
      <rect
        x="57.9448"
        y="14.417"
        width="1.41329"
        height="1.41342"
        fill="#4F5A60"
      />
      <ellipse
        cx="80.6988"
        cy="6.36003"
        rx="0.706642"
        ry="0.706714"
        fill="#4F5A60"
      />
      <ellipse
        cx="80.6988"
        cy="13.4274"
        rx="0.706642"
        ry="0.706714"
        fill="#4F5A60"
      />
      <rect
        x="67.2725"
        y="6.21875"
        width="5.3705"
        height="2.82686"
        rx="1"
        fill="#3176DE"
      />
      <rect
        x="68.1206"
        y="7.06641"
        width="1.13064"
        height="0.282685"
        rx="0.141343"
        fill="white"
      />
      <rect
        x="68.1206"
        y="7.91406"
        width="1.13064"
        height="0.282687"
        rx="0.141344"
        fill="white"
      />
      <rect
        x="70.3818"
        y="7.91406"
        width="1.13063"
        height="0.282687"
        rx="0.141344"
        fill="white"
      />
      <rect
        x="58.2275"
        y="9.89355"
        width="23.7433"
        height="0.565371"
        rx="0.282685"
        fill="#D2DCE1"
      />
      <path
        d="M58.2275 10.1762C58.2275 10.0201 58.3541 9.89355 58.5102 9.89355H81.6881C81.8443 9.89355 81.9708 10.0201 81.9708 10.1762H58.2275Z"
        fill="#9CA4AB"
      />
      <rect
        x="58.2275"
        y="16.6787"
        width="23.7433"
        height="0.565374"
        rx="0.282687"
        fill="#D2DCE1"
      />
      <path
        d="M58.2275 16.9614C58.2275 16.8053 58.3541 16.6787 58.5102 16.6787H81.6881C81.8443 16.6787 81.9708 16.8053 81.9708 16.9614H58.2275Z"
        fill="#9CA4AB"
      />
      <rect
        x="60.4888"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="60.7715"
        y="76.3242"
        width="0.847973"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="61.6196"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="61.9019"
        y="76.3242"
        width="0.847973"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="62.75"
        y="76.042"
        width="1.41328"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="63.0327"
        y="76.3242"
        width="0.847973"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="63.8809"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="64.1631"
        y="76.3242"
        width="0.847969"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="65.0112"
        y="76.042"
        width="1.41331"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="65.2939"
        y="76.3242"
        width="0.847969"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="66.1421"
        y="76.042"
        width="1.41328"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="66.4248"
        y="76.3242"
        width="0.847977"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="67.2725"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="67.5552"
        y="76.3242"
        width="0.847969"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="68.4033"
        y="76.042"
        width="1.41331"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="68.686"
        y="76.3242"
        width="0.847969"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="69.5337"
        y="76.042"
        width="1.41331"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="69.8164"
        y="76.3242"
        width="0.847977"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="70.6646"
        y="76.042"
        width="1.41328"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="70.9473"
        y="76.3242"
        width="0.847977"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="71.7949"
        y="76.042"
        width="1.41328"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="72.0776"
        y="76.3242"
        width="0.847977"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="72.9258"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="73.2085"
        y="76.3242"
        width="0.847977"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="74.0562"
        y="76.042"
        width="1.41328"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="74.3389"
        y="76.3242"
        width="0.847969"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="75.187"
        y="76.042"
        width="1.4133"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="75.4697"
        y="76.3242"
        width="0.847977"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="76.3174"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="76.6001"
        y="76.3242"
        width="0.847969"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="77.4482"
        y="76.042"
        width="1.41329"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="77.731"
        y="76.3242"
        width="0.847969"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="78.5791"
        y="76.042"
        width="1.4133"
        height="3.10954"
        fill="#BFC9CF"
      />
      <rect
        x="78.8613"
        y="76.3242"
        width="0.847977"
        height="2.54417"
        fill="#BFC9CF"
      />
      <rect
        x="58.793"
        y="77.4551"
        width="0.565315"
        height="0.565369"
        fill="#BFC9CF"
      />
      <rect
        x="80.8403"
        y="77.4551"
        width="0.565315"
        height="0.565369"
        fill="#BFC9CF"
      />
      <ellipse
        cx="67.5554"
        cy="13.2865"
        rx="1.41329"
        ry="1.41343"
        fill="#2A2C2D"
      />
      <rect
        x="67.2729"
        y="13.0039"
        width="0.565315"
        height="2.26148"
        rx="0.282658"
        fill="#585C5E"
      />
      <rect
        x="71.7949"
        y="11.0244"
        width="7.3491"
        height="4.80565"
        rx="1"
        fill="#4F5A60"
      />
      <rect
        x="72.3604"
        y="11.5898"
        width="6.21848"
        height="3.67491"
        rx="1"
        fill="#DDDDDD"
      />
      <ellipse
        cx="75.4696"
        cy="14.4169"
        rx="0.565315"
        ry="0.565371"
        fill="#B93939"
      />
      <ellipse
        cx="75.4696"
        cy="12.7201"
        rx="0.847973"
        ry="0.848057"
        fill="#4F5A60"
      />
      <ellipse
        cx="75.4696"
        cy="12.7206"
        rx="0.565315"
        ry="0.565371"
        fill="#BFC9CF"
      />
      <path
        d="M74.6216 16.6781C74.6216 16.2098 75.0013 15.8301 75.4696 15.8301H79.992C80.4604 15.8301 80.8401 16.2098 80.8401 16.6781H74.6216Z"
        fill="#2A2C2D"
      />
      <path
        d="M59.6406 16.6781C59.6406 16.2098 60.0203 15.8301 60.4887 15.8301H65.011C65.4794 15.8301 65.8591 16.2098 65.8591 16.6781H59.6406Z"
        fill="#2A2C2D"
      />
      <rect
        x="63.3149"
        y="14.9824"
        width="0.847961"
        height="0.848053"
        fill="#F65B7D"
      />
      <rect
        x="57.9448"
        y="18.375"
        width="1.41329"
        height="1.41343"
        fill="#4F5A60"
      />
      <rect
        x="57.9448"
        y="21.4844"
        width="1.41329"
        height="1.41343"
        fill="#4F5A60"
      />
      <ellipse
        cx="80.6988"
        cy="20.4948"
        rx="0.706642"
        ry="0.706713"
        fill="#4F5A60"
      />
      <rect
        x="58.2275"
        y="23.7451"
        width="23.7433"
        height="0.565371"
        rx="0.282685"
        fill="#D2DCE1"
      />
      <path
        d="M58.2275 24.0278C58.2275 23.8717 58.3541 23.7451 58.5102 23.7451H81.6881C81.8443 23.7451 81.9708 23.8717 81.9708 24.0278H58.2275Z"
        fill="#9CA4AB"
      />
      <ellipse
        cx="67.5554"
        cy="20.3529"
        rx="1.41329"
        ry="1.41343"
        fill="#2A2C2D"
      />
      <rect
        x="67.2729"
        y="20.0703"
        width="0.565315"
        height="2.26148"
        rx="0.282658"
        fill="#585C5E"
      />
      <rect
        x="71.7949"
        y="18.0918"
        width="7.3491"
        height="4.80565"
        rx="1"
        fill="#4F5A60"
      />
      <rect
        x="72.3604"
        y="18.6572"
        width="6.21848"
        height="3.67491"
        rx="1"
        fill="#DDDDDD"
      />
      <ellipse
        cx="75.4696"
        cy="21.4843"
        rx="0.565315"
        ry="0.565371"
        fill="#B93939"
      />
      <ellipse
        cx="75.4696"
        cy="19.7875"
        rx="0.847973"
        ry="0.848053"
        fill="#4F5A60"
      />
      <ellipse
        cx="75.4696"
        cy="19.788"
        rx="0.565315"
        ry="0.565372"
        fill="#BFC9CF"
      />
      <path
        d="M74.6216 23.7455C74.6216 23.2771 75.0013 22.8975 75.4696 22.8975H79.992C80.4604 22.8975 80.8401 23.2771 80.8401 23.7455H74.6216Z"
        fill="#2A2C2D"
      />
      <path
        d="M59.6406 23.7455C59.6406 23.2771 60.0203 22.8975 60.4887 22.8975H65.011C65.4794 22.8975 65.8591 23.2771 65.8591 23.7455H59.6406Z"
        fill="#2A2C2D"
      />
      <rect
        x="63.3149"
        y="22.0498"
        width="0.847961"
        height="0.848059"
        fill="#F65B7D"
      />
      <rect
        x="57.9448"
        y="25.4414"
        width="1.41329"
        height="1.41343"
        fill="#4F5A60"
      />
      <rect
        x="57.9448"
        y="28.5508"
        width="1.41329"
        height="1.41343"
        fill="#4F5A60"
      />
      <ellipse
        cx="80.6988"
        cy="27.5622"
        rx="0.706642"
        ry="0.706714"
        fill="#4F5A60"
      />
      <rect
        x="58.2275"
        y="30.8125"
        width="23.7433"
        height="0.565372"
        rx="0.282686"
        fill="#D2DCE1"
      />
      <path
        d="M58.2275 31.0952C58.2275 30.9391 58.3541 30.8125 58.5102 30.8125H81.6881C81.8443 30.8125 81.9708 30.9391 81.9708 31.0952H58.2275Z"
        fill="#9CA4AB"
      />
      <ellipse
        cx="67.5554"
        cy="27.4203"
        rx="1.41329"
        ry="1.41343"
        fill="#2A2C2D"
      />
      <rect
        x="67.2729"
        y="27.1377"
        width="0.565315"
        height="2.26148"
        rx="0.282658"
        fill="#585C5E"
      />
      <rect
        x="71.7949"
        y="25.1592"
        width="7.3491"
        height="4.80565"
        rx="1"
        fill="#4F5A60"
      />
      <rect
        x="72.3604"
        y="25.7246"
        width="6.21848"
        height="3.67491"
        rx="1"
        fill="#DDDDDD"
      />
      <ellipse
        cx="75.4696"
        cy="28.5517"
        rx="0.565315"
        ry="0.565371"
        fill="#B93939"
      />
      <ellipse
        cx="75.4696"
        cy="26.8549"
        rx="0.847973"
        ry="0.848056"
        fill="#4F5A60"
      />
      <ellipse
        cx="75.4696"
        cy="26.8554"
        rx="0.565315"
        ry="0.565368"
        fill="#BFC9CF"
      />
      <path
        d="M74.6216 30.8129C74.6216 30.3445 75.0013 29.9648 75.4696 29.9648H79.992C80.4604 29.9648 80.8401 30.3445 80.8401 30.8129H74.6216Z"
        fill="#2A2C2D"
      />
      <path
        d="M59.6406 30.8119C59.6406 30.3436 60.0203 29.9639 60.4887 29.9639H65.011C65.4794 29.9639 65.8591 30.3436 65.8591 30.8119H59.6406Z"
        fill="#2A2C2D"
      />
      <rect
        x="63.3149"
        y="29.1162"
        width="0.847961"
        height="0.848057"
        fill="#F65B7D"
      />
      <rect
        x="57.9448"
        y="32.5088"
        width="1.41329"
        height="1.41343"
        fill="#4F5A60"
      />
      <rect
        x="57.9448"
        y="35.6182"
        width="1.41329"
        height="1.41343"
        fill="#4F5A60"
      />
      <ellipse
        cx="80.6988"
        cy="34.6286"
        rx="0.706642"
        ry="0.706713"
        fill="#4F5A60"
      />
      <rect
        x="58.2275"
        y="37.8799"
        width="23.7433"
        height="0.565369"
        rx="0.282684"
        fill="#D2DCE1"
      />
      <path
        d="M58.2275 38.1626C58.2275 38.0064 58.3541 37.8799 58.5102 37.8799H81.6881C81.8443 37.8799 81.9708 38.0064 81.9708 38.1626H58.2275Z"
        fill="#9CA4AB"
      />
      <ellipse
        cx="67.5554"
        cy="34.4876"
        rx="1.41329"
        ry="1.41343"
        fill="#2A2C2D"
      />
      <rect
        x="67.2729"
        y="34.2051"
        width="0.565315"
        height="2.26148"
        rx="0.282658"
        fill="#585C5E"
      />
      <rect
        x="71.7949"
        y="32.2266"
        width="7.3491"
        height="4.80566"
        rx="1"
        fill="#4F5A60"
      />
      <rect
        x="72.3604"
        y="32.792"
        width="6.21848"
        height="3.67491"
        rx="1"
        fill="#DDDDDD"
      />
      <ellipse
        cx="75.4696"
        cy="35.6191"
        rx="0.565315"
        ry="0.565372"
        fill="#B93939"
      />
      <ellipse
        cx="75.4696"
        cy="33.9223"
        rx="0.847973"
        ry="0.848057"
        fill="#4F5A60"
      />
      <ellipse
        cx="75.4696"
        cy="33.9228"
        rx="0.565315"
        ry="0.565374"
        fill="#BFC9CF"
      />
      <path
        d="M74.6216 37.8803C74.6216 37.4119 75.0013 37.0322 75.4696 37.0322H79.992C80.4604 37.0322 80.8401 37.4119 80.8401 37.8803H74.6216Z"
        fill="#2A2C2D"
      />
      <path
        d="M59.6406 37.8793C59.6406 37.4109 60.0203 37.0312 60.4887 37.0312H65.011C65.4794 37.0312 65.8591 37.4109 65.8591 37.8793H59.6406Z"
        fill="#2A2C2D"
      />
      <rect
        x="63.3149"
        y="36.1836"
        width="0.847961"
        height="0.848057"
        fill="#F65B7D"
      />
      <rect
        x="57.9448"
        y="39.5762"
        width="1.41329"
        height="1.41343"
        fill="#4F5A60"
      />
      <rect
        x="57.9448"
        y="42.6855"
        width="1.41329"
        height="1.41343"
        fill="#4F5A60"
      />
      <ellipse
        cx="80.6988"
        cy="41.696"
        rx="0.706642"
        ry="0.706713"
        fill="#4F5A60"
      />
      <rect
        x="58.2275"
        y="44.9473"
        width="23.7433"
        height="0.565372"
        rx="0.282686"
        fill="#D2DCE1"
      />
      <path
        d="M58.2275 45.23C58.2275 45.0738 58.3541 44.9473 58.5102 44.9473H81.6881C81.8443 44.9473 81.9708 45.0738 81.9708 45.23H58.2275Z"
        fill="#9CA4AB"
      />
      <ellipse
        cx="67.5554"
        cy="41.555"
        rx="1.41329"
        ry="1.41343"
        fill="#2A2C2D"
      />
      <rect
        x="67.2729"
        y="41.2725"
        width="0.565315"
        height="2.2615"
        rx="0.282658"
        fill="#585C5E"
      />
      <rect
        x="71.7949"
        y="39.293"
        width="7.3491"
        height="4.80565"
        rx="1"
        fill="#4F5A60"
      />
      <rect
        x="72.3604"
        y="39.8584"
        width="6.21848"
        height="3.67491"
        rx="1"
        fill="#DDDDDD"
      />
      <ellipse
        cx="75.4696"
        cy="42.6855"
        rx="0.565315"
        ry="0.565372"
        fill="#B93939"
      />
      <ellipse
        cx="75.4696"
        cy="40.9887"
        rx="0.847973"
        ry="0.848057"
        fill="#4F5A60"
      />
      <ellipse
        cx="75.4696"
        cy="40.9892"
        rx="0.565315"
        ry="0.565372"
        fill="#BFC9CF"
      />
      <path
        d="M74.6216 44.9467C74.6216 44.4783 75.0013 44.0986 75.4696 44.0986H79.992C80.4604 44.0986 80.8401 44.4783 80.8401 44.9467H74.6216Z"
        fill="#2A2C2D"
      />
      <path
        d="M59.6406 44.9467C59.6406 44.4783 60.0203 44.0986 60.4887 44.0986H65.011C65.4794 44.0986 65.8591 44.4783 65.8591 44.9467H59.6406Z"
        fill="#2A2C2D"
      />
      <rect
        x="63.3149"
        y="43.251"
        width="0.847961"
        height="0.848053"
        fill="#F65B7D"
      />
      <rect
        x="57.9448"
        y="46.9258"
        width="1.41329"
        height="1.41342"
        fill="#4F5A60"
      />
      <rect
        x="57.9448"
        y="50.0352"
        width="1.41329"
        height="1.41343"
        fill="#4F5A60"
      />
      <ellipse
        cx="80.6988"
        cy="49.3288"
        rx="0.706642"
        ry="0.706713"
        fill="#4F5A60"
      />
      <rect
        x="58.2275"
        y="52.5791"
        width="23.7433"
        height="0.565357"
        rx="0.282679"
        fill="#D2DCE1"
      />
      <path
        d="M58.2275 52.8618C58.2275 52.7057 58.3541 52.5791 58.5102 52.5791H81.6881C81.8443 52.5791 81.9708 52.7057 81.9708 52.8618H58.2275Z"
        fill="#9CA4AB"
      />
      <ellipse
        cx="67.5554"
        cy="49.1869"
        rx="1.41329"
        ry="1.41343"
        fill="#2A2C2D"
      />
      <rect
        x="67.2729"
        y="48.9043"
        width="0.565315"
        height="2.26148"
        rx="0.282658"
        fill="#585C5E"
      />
      <rect
        x="71.7949"
        y="46.9258"
        width="7.3491"
        height="4.80564"
        rx="1"
        fill="#4F5A60"
      />
      <rect
        x="72.3604"
        y="47.4912"
        width="6.21848"
        height="3.67491"
        rx="1"
        fill="#DDDDDD"
      />
      <ellipse
        cx="75.4696"
        cy="50.3183"
        rx="0.565315"
        ry="0.565371"
        fill="#B93939"
      />
      <ellipse
        cx="75.4696"
        cy="48.6215"
        rx="0.847973"
        ry="0.848049"
        fill="#4F5A60"
      />
      <ellipse
        cx="75.4696"
        cy="48.622"
        rx="0.565315"
        ry="0.565372"
        fill="#BFC9CF"
      />
      <path
        d="M74.6216 52.5795C74.6216 52.1111 75.0013 51.7314 75.4697 51.7314H79.992C80.4604 51.7314 80.8401 52.1111 80.8401 52.5795H74.6216Z"
        fill="#2A2C2D"
      />
      <path
        d="M59.6406 52.5795C59.6406 52.1111 60.0203 51.7314 60.4887 51.7314H65.011C65.4794 51.7314 65.8591 52.1111 65.8591 52.5795H59.6406Z"
        fill="#2A2C2D"
      />
      <rect
        x="63.3149"
        y="50.8838"
        width="0.847961"
        height="0.848057"
        fill="#F65B7D"
      />
      <rect
        x="58.2275"
        y="74.9121"
        width="23.7433"
        height="0.565376"
        rx="0.282688"
        fill="#D2DCE1"
      />
      <path
        d="M58.2275 75.1948C58.2275 75.0387 58.3541 74.9121 58.5102 74.9121H81.6881C81.8443 74.9121 81.9708 75.0387 81.9708 75.1948H58.2275Z"
        fill="#9CA4AB"
      />
      <rect
        x="57.9448"
        y="69.2578"
        width="1.41329"
        height="1.41343"
        fill="#4F5A60"
      />
      <rect
        x="57.9448"
        y="72.3672"
        width="1.41329"
        height="1.41342"
        fill="#4F5A60"
      />
      <ellipse
        cx="80.6988"
        cy="71.3776"
        rx="0.706642"
        ry="0.706711"
        fill="#4F5A60"
      />
      <ellipse
        cx="67.5554"
        cy="71.5199"
        rx="1.41329"
        ry="1.41343"
        fill="#2A2C2D"
      />
      <rect
        x="67.2729"
        y="71.2373"
        width="0.565315"
        height="2.26149"
        rx="0.282658"
        fill="#585C5E"
      />
      <rect
        x="71.7949"
        y="69.2578"
        width="7.3491"
        height="4.80566"
        rx="1"
        fill="#4F5A60"
      />
      <rect
        x="72.3604"
        y="69.8232"
        width="6.21848"
        height="3.67492"
        rx="1"
        fill="#DDDDDD"
      />
      <ellipse
        cx="75.4696"
        cy="72.6503"
        rx="0.565315"
        ry="0.565372"
        fill="#B93939"
      />
      <ellipse
        cx="75.4696"
        cy="70.9535"
        rx="0.847973"
        ry="0.848061"
        fill="#4F5A60"
      />
      <ellipse
        cx="75.4696"
        cy="70.954"
        rx="0.565315"
        ry="0.565365"
        fill="#BFC9CF"
      />
      <path
        d="M74.6216 74.9115C74.6216 74.4432 75.0013 74.0635 75.4696 74.0635H79.992C80.4604 74.0635 80.8401 74.4432 80.8401 74.9115H74.6216Z"
        fill="#2A2C2D"
      />
      <path
        d="M59.6406 74.9115C59.6406 74.4432 60.0203 74.0635 60.4887 74.0635H65.011C65.4794 74.0635 65.8591 74.4432 65.8591 74.9115H59.6406Z"
        fill="#2A2C2D"
      />
      <rect
        x="63.3149"
        y="73.2158"
        width="0.847961"
        height="0.848061"
        fill="#F65B7D"
      />
      <rect
        x="58.2275"
        y="67.5615"
        width="23.7433"
        height="0.565369"
        rx="0.282684"
        fill="#D2DCE1"
      />
      <path
        d="M58.2275 67.8442C58.2275 67.6881 58.3541 67.5615 58.5102 67.5615H81.6881C81.8443 67.5615 81.9708 67.6881 81.9708 67.8442H58.2275Z"
        fill="#9CA4AB"
      />
      <rect
        x="58.2275"
        y="60.2119"
        width="23.7433"
        height="0.56538"
        rx="0.28269"
        fill="#D2DCE1"
      />
      <path
        d="M58.2275 60.4946C58.2275 60.3385 58.3541 60.2119 58.5102 60.2119H81.6881C81.8443 60.2119 81.9708 60.3385 81.9708 60.4946H58.2275Z"
        fill="#9CA4AB"
      />
      <rect
        x="57.9448"
        y="61.9082"
        width="1.41329"
        height="1.41341"
        fill="#4F5A60"
      />
      <rect
        x="57.9448"
        y="65.0176"
        width="1.41329"
        height="1.41344"
        fill="#4F5A60"
      />
      <ellipse
        cx="80.6988"
        cy="64.028"
        rx="0.706642"
        ry="0.706722"
        fill="#4F5A60"
      />
      <ellipse
        cx="67.5554"
        cy="64.1693"
        rx="1.41329"
        ry="1.41343"
        fill="#2A2C2D"
      />
      <rect
        x="67.2729"
        y="63.8867"
        width="0.565315"
        height="2.26148"
        rx="0.282658"
        fill="#585C5E"
      />
      <rect
        x="71.7949"
        y="61.9082"
        width="7.3491"
        height="4.80566"
        rx="1"
        fill="#4F5A60"
      />
      <rect
        x="72.3604"
        y="62.4736"
        width="6.21848"
        height="3.67491"
        rx="1"
        fill="#DDDDDD"
      />
      <ellipse
        cx="75.4696"
        cy="65.3007"
        rx="0.565315"
        ry="0.565369"
        fill="#B93939"
      />
      <ellipse
        cx="75.4696"
        cy="63.6039"
        rx="0.847973"
        ry="0.848053"
        fill="#4F5A60"
      />
      <ellipse
        cx="75.4696"
        cy="63.6044"
        rx="0.565315"
        ry="0.565371"
        fill="#BFC9CF"
      />
      <path
        d="M74.6216 67.5619C74.6216 67.0936 75.0013 66.7139 75.4696 66.7139H79.992C80.4604 66.7139 80.8401 67.0936 80.8401 67.5619H74.6216Z"
        fill="#2A2C2D"
      />
      <path
        d="M59.6406 67.5609C59.6406 67.0926 60.0203 66.7129 60.4887 66.7129H65.011C65.4794 66.7129 65.8591 67.0926 65.8591 67.5609H59.6406Z"
        fill="#2A2C2D"
      />
      <rect
        x="63.3149"
        y="65.8652"
        width="0.847961"
        height="0.848061"
        fill="#F65B7D"
      />
      <rect
        x="57.9448"
        y="53.1445"
        width="24.3086"
        height="7.06714"
        fill="#4F5A60"
      />
      <ellipse
        cx="78.1548"
        cy="55.8302"
        rx="0.989304"
        ry="0.989399"
        fill="#CED5DC"
      />
      <ellipse
        cx="78.1549"
        cy="57.8088"
        rx="0.423985"
        ry="0.424028"
        fill="#E54545"
      />
      <ellipse
        cx="66.8487"
        cy="55.8302"
        rx="0.989311"
        ry="0.989399"
        fill="#CED5DC"
      />
      <ellipse
        cx="66.8488"
        cy="57.8088"
        rx="0.423988"
        ry="0.424028"
        fill="#E54545"
      />
      <ellipse
        cx="73.7736"
        cy="57.1019"
        rx="1.41329"
        ry="1.41343"
        fill="#CED5DC"
      />
      <ellipse
        cx="73.7738"
        cy="57.102"
        rx="0.847977"
        ry="0.848057"
        fill="#BFC9CF"
      />
      <rect
        x="73.4912"
        y="57.1016"
        width="0.565323"
        height="1.69611"
        rx="0.282661"
        fill="#4F5A60"
      />
      <rect
        x="74.0566"
        y="59.6465"
        width="0.847969"
        height="0.565372"
        fill="#F3F6F7"
      />
      <ellipse
        cx="62.4675"
        cy="57.1019"
        rx="1.41329"
        ry="1.41343"
        fill="#CED5DC"
      />
      <ellipse
        cx="62.4676"
        cy="57.102"
        rx="0.847975"
        ry="0.848057"
        fill="#BFC9CF"
      />
      <rect
        x="62.1851"
        y="57.1016"
        width="0.565315"
        height="1.69611"
        rx="0.282658"
        fill="#4F5A60"
      />
      <rect
        x="62.75"
        y="59.6465"
        width="0.847973"
        height="0.565372"
        fill="#F3F6F7"
      />
      <rect
        x="33.0708"
        y="2.54395"
        width="0.282654"
        height="77.4558"
        fill="#BFC9CF"
      />
      <rect
        x="57.6621"
        y="2.54395"
        width="0.282661"
        height="77.4558"
        fill="#BFC9CF"
      />
    </g>
  )
}

export default Switchgear
