import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { ScreenSizeProvider } from '../ScreenSize'

import TabNav from './TabNav'
import TabContent from './TabContent'
import TabItem from './TabItem'

import './styles.scss'

const TabsContext = React.createContext()
const TabsProvider = TabsContext.Provider

const isValidChildType = (type) => {
  const validChildren = [TabNav, TabContent]
  return validChildren.some((t) => t === type)
}

const Tabs = ({ id, children, direction, title, static: propStatic }) => {
  const [tabItems, setTabItems] = useState([])
  const [activeTab, setActiveTab] = useState()
  const [navType, setNavType] = useState('')
  const _Tabs = classnames('cui-tabs', {
    'is-static': propStatic,
  })

  const handleTabClick = (idx) => () => {
    if (activeTab !== idx) {
      setActiveTab(idx)
    }
  }

  const setTypeOfNav = (type) => {
    // setNavType(type)
  }

  useEffect(() => {
    const currentItems = []
    let itemIdx = 0
    React.Children.forEach(children, (child) => {
      if (child.type === TabNav) {
        setNavType(child.props.type)
        React.Children.forEach(child.props.children, (gChild) => {
          if (gChild.type === TabItem) {
            if (gChild.props.active) {
              setActiveTab(itemIdx)
            }
            currentItems.push({
              id: itemIdx,
              title: gChild.props.children,
              content: gChild.props.children,
              ...gChild.props,
            })
            itemIdx++
          }
        })
      }
    })
    setTabItems(currentItems)
  }, [children])

  return (
    <section className={_Tabs} id={id} style={{ '--tabsDirection': direction }}>
      <ScreenSizeProvider>
        <TabsProvider
          value={{
            id,
            title,
            items: tabItems,
            active: activeTab,
            static: propStatic,
            navType,
            direction,
            actions: {
              handleTabClick,
              setTypeOfNav,
            },
          }}
        >
          {children}
        </TabsProvider>
      </ScreenSizeProvider>
    </section>
  )
}

Tabs.Nav = TabNav
Tabs.Content = TabContent
Tabs.Item = TabItem

Tabs.Context = TabsContext
Tabs.Provider = TabsProvider

Tabs.displayName = 'CuiTabs'
Tabs.propTypes = {
  id: PropTypes.string,
  children: (props, propName, componentName) => {
    let error
    const prop = props[propName]
    React.Children.forEach(prop, (child) => {
      if (!isValidChildType(child.type)) {
        error = new Error(
          `${componentName} only accepts children of type TabNav and TabContent. Please use Tabs.Nav and Tabs.Content component instead.`
        )
      }
    })
    return error
  },
  direction: PropTypes.string,
  title: PropTypes.string,
  static: PropTypes.bool,
}
Tabs.defaultProps = {
  direction: 'row',
  title: '',
  static: false,
}

export default Tabs
