import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

function Floater({ children, flow, valign, halign }) {
  const _Floater = classnames('cui-floater', {
    'flow-col': flow === 'column',
    'flow-row': flow === 'row',
    [`valign-${valign}`]: valign,
    [`halign-${halign}`]: halign,
  })
  return <div className={_Floater}>{children}</div>
}

Floater.propTypes = {
  flow: PropTypes.string,
  valign: PropTypes.string,
  halign: PropTypes.string,
}

Floater.defaultProps = {
  flow: 'row',
  valign: 'center',
  halign: 'center',
}

export default Floater
