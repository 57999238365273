import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

const TabNavContainer = ({ children, id, halign, className }) => {
  const _TabNavContainer = classnames('cui--tab-nav-container', className, {
    [`halign-${halign}`]: halign,
  })
  return (
    <div className={_TabNavContainer} id={id}>
      <ul>{children}</ul>
    </div>
  )
}

TabNavContainer.propTypes = {
  id: PropTypes.string,
  halign: PropTypes.string,
}

TabNavContainer.defaultProps = {
  halign: 'center',
}

export default TabNavContainer
