import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Icons from '../Icons'
import { NavigatorContext } from './Navigator'
import { useTheme } from '../Theme'

const NavigatorNext = ({ className }) => {
  const theme = useTheme()
  const {
    selected,
    itemsObj,
    selectedSubItems,
    handleChangeSubItem,
  } = useContext(NavigatorContext)

  const { selectedItem } = selected
  const currentIndex = selectedSubItems[selectedItem]?.index
  const subItems = itemsObj[selectedItem]?.subItems || []

  const handleSelectNext = () => {
    handleChangeSubItem(currentIndex - 1)
  }

  const subItemsSize = subItems.length
  const disabled = subItemsSize < 2 || currentIndex === 0

  return (
    <button
      className={classnames(
        'navigator__caret-button navigator__caret-button--next',
        { disabled },
        className
      )}
      onClick={handleSelectNext}
    >
      <Icons.Caret.Right
        width={20}
        color={[
          disabled ? theme.colors.text.disabled : theme.colors.text.primary,
        ]}
      />
    </button>
  )
}

NavigatorNext.propTypes = {
  className: PropTypes.string,
}

export default NavigatorNext
