import React from 'react'

const SpotApplication = () => {
  return (
    <g className="illus-spot_application">
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M200.428 80.2501C199.481 76.7791 198.599 73.2871 198.016 69.7421C196.978 63.4361 192.713 57.7361 187.384 53.7911C181.255 49.2541 173.825 46.6841 166.461 44.4021C144.428 37.5741 121.191 32.6581 98.2085 35.3491C75.2285 38.0391 52.4075 49.3871 40.9865 68.7341C30.1425 87.1031 30.9795 110.445 39.6565 129.848C44.8015 141.354 52.8245 152.037 64.0435 158.414C72.1435 163.018 81.4785 165.169 90.7545 166.708C105.448 169.147 120.353 170.253 135.261 170.034C150.974 169.803 167.88 168.27 180.866 159.477C192.768 151.416 199.873 138.154 203.521 124.609C207.37 110.315 204.407 94.8411 200.428 80.2501Z"
          fill="#F8FCFF"
        />
        <rect
          x="86.1187"
          y="7"
          width="116"
          height="135"
          rx="4"
          transform="rotate(9 86.1187 7)"
          fill="#CED5DC"
        />
        <rect x="66" y="32" width="116" height="59" rx="4" fill="#F9FBFD" />
        <mask
          id="spotapplication-mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="12"
          width="240"
          height="181"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M200.428 80.2501C199.481 76.7791 198.599 73.2871 198.016 69.7421C196.978 63.4361 192.713 57.7361 187.384 53.7911C181.255 49.2541 173.825 46.6841 166.461 44.4021C144.428 37.5741 121.191 32.6581 98.2085 35.3491C75.2285 38.0391 52.4075 49.3871 40.9865 68.7341C30.1425 87.1031 30.9795 110.445 39.6565 129.848C44.8015 141.354 52.8245 152.037 64.0435 158.414C72.1435 163.018 81.4785 165.169 90.7545 166.708C105.448 169.147 120.353 170.253 135.261 170.034C150.974 169.803 167.88 168.27 180.866 159.477C192.768 151.416 199.873 138.154 203.521 124.609C207.37 110.315 204.407 94.8411 200.428 80.2501Z"
            fill="#F8FCFF"
          />
        </mask>
        <g mask="url(#spotapplication-mask0)">
          <rect
            x="66"
            y="32"
            width="116"
            height="158"
            rx="4"
            fill="url(#spotapplication-paint0_linear)"
          />
          <rect x="81" y="67" width="46" height="5" rx="2.5" fill="#E4EBF2" />
          <rect x="100" y="80" width="46" height="5" rx="2.5" fill="#F65B7D" />
          <rect x="81" y="94" width="78" height="5" rx="2.5" fill="#E4EBF2" />
          <rect x="81" y="108" width="78" height="5" rx="2.5" fill="#E4EBF2" />
          <rect x="81" y="122" width="78" height="5" rx="2.5" fill="#E4EBF2" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M102.136 154.953L107.228 143.665C107.41 143.394 107.733 143.255 108.054 143.31C108.401 143.353 108.688 143.608 108.771 143.951L111.4 153.9C111.867 155.734 113.512 157.013 115.389 157H115.401C117.273 157.017 118.918 155.75 119.395 153.923L120.355 150.369C120.446 150.034 120.724 149.785 121.065 149.734C121.405 149.684 121.743 149.841 121.925 150.135L124.707 154.628C125.503 155.911 126.925 156.66 128.424 156.584C129.923 156.509 131.264 155.622 131.93 154.264C132.042 154.041 132.244 153.878 132.483 153.815C132.724 153.752 132.978 153.795 133.185 153.934L135.148 155.255C135.789 155.687 136.536 155.928 137.306 155.955C138.21 155.987 138.968 155.273 138.999 154.361C139.03 153.45 138.323 152.685 137.419 152.653C137.257 152.647 137.1 152.597 136.965 152.507L135.001 151.186C134.011 150.52 132.784 150.316 131.634 150.627C130.484 150.937 129.523 151.732 128.994 152.809C128.855 153.092 128.573 153.278 128.26 153.292C127.946 153.307 127.648 153.15 127.482 152.882L124.7 148.39C123.829 146.982 122.214 146.229 120.586 146.471C118.959 146.712 117.628 147.904 117.195 149.505L116.235 153.058C116.12 153.432 115.783 153.69 115.396 153.704C114.997 153.721 114.644 153.447 114.56 153.053L111.937 143.1C111.508 141.48 110.161 140.275 108.514 140.041C106.868 139.807 105.243 140.589 104.387 142.028C104.356 142.08 104.327 142.137 104.301 142.193L99.1624 153.583C98.9047 154.122 98.9565 154.76 99.298 155.25C99.6387 155.739 100.216 156.004 100.806 155.942C101.397 155.88 101.907 155.5 102.14 154.95L102.136 154.953Z"
            fill="#3176DE"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M147.25 156C148.217 156 149 155.105 149 154C149 152.895 148.217 152 147.25 152H143.75C142.783 152 142 152.895 142 154C142 155.105 142.783 156 143.75 156H147.25Z"
            fill="#3176DE"
          />
          <rect x="81" y="46" width="20" height="5" rx="2.5" fill="#00B0B2" />
        </g>
      </g>
      <defs>
        <linearGradient
          id="spotapplication-paint0_linear"
          x1="141"
          y1="77.5"
          x2="176.51"
          y2="99.8739"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9FBFD" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0">
          <rect width="240" height="180" fill="white" />
        </clipPath>
      </defs>
    </g>
  )
}

export default SpotApplication
