import React from 'react'
import PropTypes from 'prop-types'

class SpinLoaderIcon extends React.PureComponent {
  static propTypes = {
    color: PropTypes.array,
  }

  render() {
    return (
      <g
        className="icon-spinloader"
        stroke="none"
        strokeWidth="1"
        fillRule="evenodd"
      >
        <path
          fill={this.props.color[0]}
          d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
        >
          <animateTransform
            attributeName="transform"
            attributeType="XML"
            type="rotate"
            dur="1s"
            from="0 50 50"
            to="360 50 50"
            repeatCount="indefinite"
          />
        </path>
      </g>
    )
  }
}

export default SpinLoaderIcon
