// organize-imports-ignore
import React, { useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ChartBar from './ChartBar'
import ChartLine from './ChartLine'
import Typo from '../../Typo'
import { useScreenSize } from '../../ScreenSize'
import Chart, { useChart } from '../Chart'
import ChartLabel from '../ChartLabel'
import ChartLegends from '../ChartLegends'
import { useTheme } from '../../Theme'
import ChartSinePulse from './ChartSinePulse'
import ChartConstantLine from './ChartConstantLine'
import ChartTooltip from './ChartTooltip'

export const ChartXYCtx = React.createContext()
export const ChartXYProvider = ChartXYCtx.Provider
export const ChartXYConsumer = ChartXYCtx.Consumer

export const useChartXY = () => {
  const chartCtx = React.useContext(ChartXYCtx)
  return chartCtx
}

const MIN_BAR_ITEM_SPACE = 5
const MAX_BAR_ITEM_WIDTH = 32
const LEGEND_HEIGHT = 34

const ChartXY = ({
  id,
  children,
  direction,
  width,
  height,
  interval,
  isNoRecord,
  noRecordFootnotes,
  isRounded,
  unit,
  labelSize,
  markerLength,
  legends,
  barWidth,
  innerPadding,
  maxVal,
  timeScale,
  isStartFromMinValue,
}) => {
  const theme = useTheme()

  const { data, chartData } = useChart()
  const [maxValue, setMaxValue] = useState(0)
  const [minValue, setMinValue] = useState(0)
  const [otherYMaxValue, setOtherYMaxValue] = useState(0)
  const [otherYMinValue, setOtherYMinValue] = useState(0)

  const [sinePulseValues, setSinePulseValues] = useState({})

  const chartType = data.getChartType()

  const screenSize = useScreenSize()

  const isVertical = direction === 'vertical'

  const [containerWidth, setContainerWidth] = React.useState(width)
  const [containerHeight, setContainerHeight] = React.useState(
    height - (height && legends && legends.length > 0 ? LEGEND_HEIGHT : 0)
  )

  const [parentWidthAdj, setParentWidthAdj] = React.useState(() => {
    return isVertical ? width - labelSize.y : width - labelSize.x - markerLength
  })

  const [parentHeightAdj, setParentHeightAdj] = React.useState(() => {
    return isVertical
      ? height - labelSize.x - markerLength
      : height - labelSize.y
  })

  const chartRef = React.useRef(null)

  const getMaxValue = useCallback(
    (fields, y = 'y') => {
      const totalValues = chartData.map(item => {
        const selectedFieldsValue = item[y].value.filter(ele =>
          fields?.includes(ele.key)
        )
        return selectedFieldsValue.reduce((total, val) => total + val.value, 0)
      })
      const isNegativeValue = totalValues.every(val => val < 0)
      if (isNegativeValue) {
        return Math.min(...totalValues)
      }

      return Math.max(...totalValues)
    },
    [chartData]
  )

  const getMinValue = useCallback(
    (fields, y = 'y') => {
      const totalValues = []
      chartData.forEach(item => {
        const selectedFieldsValue = item[y].value.filter(ele =>
          fields?.includes(ele.key)
        )
        if (
          selectedFieldsValue?.length &&
          selectedFieldsValue?.[0]?.value !== null &&
          selectedFieldsValue?.[0]?.value !== undefined
        ) {
          totalValues.push(
            selectedFieldsValue.reduce((total, val) => total + val.value, 0)
          )
        }
      })

      return totalValues?.length ? Math.min(...totalValues) : false
    },
    [chartData]
  )

  const getBarWidth = () => {
    const numData = chartData.length
    const barSpaceWidth =
      direction === 'vertical'
        ? Math.floor((parentWidthAdj - innerPadding * 2) / numData)
        : Math.floor((parentHeightAdj - innerPadding * 2) / numData)

    if (barWidth === 0) {
      return 0
    }
    if (barWidth) {
      return Math.min(barWidth, barSpaceWidth - MIN_BAR_ITEM_SPACE)
    }
    return Math.min(barSpaceWidth / 2, MAX_BAR_ITEM_WIDTH)
  }

  const getDivisionX = () => {
    if (chartType === 'sinePulse') {
      return [0, 90, 180, 270, 360].map((label, index) => ({
        pos:
          innerPadding +
          index *
            (((isVertical ? parentWidthAdj : parentHeightAdj) -
              innerPadding * 2) /
              4),
        label,
      }))
    }

    const items = []
    const numData = chartData.length

    const barWidth = getBarWidth()
    const numInterval = barWidth ? numData : numData - 1

    const barSpaceWidth =
      direction === 'vertical'
        ? (parentWidthAdj - innerPadding * 2) / numInterval
        : (parentHeightAdj - innerPadding * 2) / numInterval

    const barSpace = barSpaceWidth - barWidth

    for (let i = 0; i < numData; i++) {
      const length = i * barSpaceWidth
      const xAxisData = {}
      xAxisData.barstart = length + (barWidth ? barSpace / 2 : 0) + innerPadding

      const curTime = chartData[i].x.value
      if (timeScale) {
        const ratio =
          (new Date(curTime).getTime() - new Date(timeScale.min).getTime()) /
          (new Date(timeScale.max).getTime() -
            new Date(timeScale.min).getTime())

        xAxisData.pos =
          (barWidth ? barSpace / 2 : 0) + parentWidthAdj * ratio + innerPadding
      } else {
        xAxisData.pos =
          length + (barWidth ? barSpace / 2 : 0) + barWidth / 2 + innerPadding
      }

      if (minValue) {
        const value = chartData[i]?.y?.value[0]?.value
        if (value < 0) {
          xAxisData.yNegativePosition =
            ((minValue - value) / (maxValue - minValue)) *
            (parentHeightAdj - parentHeightAdj / interval)
        } else {
          xAxisData.yPositivePosition =
            (minValue / (maxValue - minValue)) *
            (parentHeightAdj - parentHeightAdj / interval)
        }
      }

      if (direction === 'horizontal') {
        xAxisData.barstart = parentHeightAdj - xAxisData.barstart
        xAxisData.pos = parentHeightAdj - xAxisData.pos
      }

      xAxisData.data = chartData[i] && chartData[i].y.value
      xAxisData.label = chartData[i].x.label
      xAxisData.otherYData = chartData[i] && chartData[i].otherY?.value
      items.push({ ...xAxisData })
    }

    return items
  }

  const getDivisionY = (yMaxValue = maxValue, isOtherY) => {
    if (chartType === 'sinePulse') {
      const items = []
      const unit = sinePulseValues.fixedMaxValue / interval
      const svgUnit = parentHeightAdj / (interval * 2)
      for (let i = interval; i > 0; i--) {
        items.push({
          label: unit * i,
          pos: svgUnit * (interval - i),
        })
      }
      items.push({
        label: 0,
        pos: parentHeightAdj / 2,
      })
      for (let i = 0; i < interval; i++) {
        items.push({
          label: -unit * (i + 1),
          pos: parentHeightAdj - svgUnit * (interval - i - 1),
        })
      }
      return items
    }

    const items = []
    const yAxisData = {}
    yAxisData.pos = isVertical ? 0 : parentWidthAdj
    yAxisData.label = isOtherY ? unit.otherY : unit.y
    items.push({ ...yAxisData })
    const totalLength =
      direction === 'vertical' ? parentHeightAdj : parentWidthAdj
    const svgUnit = totalLength / interval // no -1 to account for symbol
    const displayUnit =
      (yMaxValue - (isOtherY ? otherYMinValue : minValue)) / (interval - 1)

    for (let i = interval - 1; i >= 0; i--) {
      const length = Math.ceil(i * svgUnit)
      const displayVal = yMaxValue - (interval - 1 - i) * displayUnit

      const yAxisData = {}
      yAxisData.pos = length
      if (direction === 'vertical') {
        yAxisData.pos = parentHeightAdj - yAxisData.pos
      }
      yAxisData.label = displayVal
      items.push({ ...yAxisData })
    }
    return items
  }

  const setMinMaxValues = React.useCallback(
    (y, yFields, setMaxValue, setMinValue) => {
      let maxValue = maxVal
      let minValue = 0
      if (!maxVal) {
        const maxValues = []
        const minValues = []
        React.Children.map(children, child => {
          if (child) {
            if (child.type === ChartBar || child.type === ChartLine) {
              const fields = child.props.fields || yFields
              const maxValueChild = getMaxValue(fields, y)
              const minValueChild = getMinValue(fields, y)
              maxValues.push(maxValueChild)
              typeof minValueChild === 'number' && minValues.push(minValueChild)
            }
            if (child.props.children) {
              Array.isArray(child.props.children) &&
                child.props.children.forEach(child => {
                  const fields = child.props.fields || data.getY()
                  const maxValueChild = getMaxValue(fields, y)
                  const minValueChild = getMinValue(fields, y)
                  maxValues.push(maxValueChild)
                  typeof minValueChild === 'number' &&
                    minValues.push(minValueChild)
                })
            }
          }
        })
        maxValue = isFinite(Math.max(...maxValues)) ? Math.max(...maxValues) : 0
        minValue = isFinite(Math.min(...minValues))
          ? isStartFromMinValue
            ? Math.min(...minValues)
            : Math.min(...minValues, 0)
          : 0
      }

      const roundValue = (yMaxValue, withInterval = interval - 1) => {
        if (isRounded) {
          let intervalValue = yMaxValue / withInterval
          if (intervalValue < 1) {
            const matchZeros = intervalValue.toString().match(/(\.0*)/)
            const numOfZeros = matchZeros ? matchZeros[0].length - 1 : 0

            const powerOf10 = Math.pow(10, numOfZeros + 2)
            intervalValue =
              (Math.ceil((intervalValue * powerOf10) / 5) * 5) / powerOf10
          } else if (intervalValue <= 10) {
            intervalValue = Math.ceil(intervalValue)
          } else {
            intervalValue = Math.ceil(intervalValue / 5) * 5
          }
          return intervalValue * withInterval
        } else {
          if (isFinite(yMaxValue)) {
            return yMaxValue
          }
        }
      }

      if (!minValue || (isStartFromMinValue && minValue >= 0)) {
        maxValue = roundValue(maxValue)
        if (maxValue) {
          setMaxValue(maxValue > 0 ? maxValue : 0)
        }

        let displayMinValue = minValue
        if (minValue === maxValue) {
          displayMinValue = 0
        } else if (isRounded) {
          displayMinValue = Math.floor(minValue)
        }

        isStartFromMinValue && setMinValue(displayMinValue)
      } else {
        let positiveInterval
        let negativeInterval
        if (maxValue > -minValue) {
          positiveInterval = Math.ceil(
            ((interval - 1) * maxValue) / (maxValue - minValue)
          )
          negativeInterval = interval - 1 - positiveInterval
          if (negativeInterval < 1) {
            negativeInterval = 1
            positiveInterval = interval - 1 - negativeInterval
          }
        } else {
          negativeInterval = Math.ceil(
            -((interval - 1) * minValue) / (maxValue - minValue)
          )
          positiveInterval = interval - 1 - negativeInterval
          if (positiveInterval < 1) {
            positiveInterval = 1
            negativeInterval = interval - 1 - positiveInterval
          }
        }

        if (maxValue / positiveInterval > -minValue / negativeInterval) {
          maxValue = roundValue(maxValue, positiveInterval)
          setMaxValue(maxValue)
          setMinValue(-(maxValue / positiveInterval) * negativeInterval)
        } else {
          minValue = -roundValue(-minValue, negativeInterval)
          setMinValue(minValue)
          setMaxValue(-(minValue / negativeInterval) * positiveInterval)
        }
      }
    },
    [children, getMaxValue, isRounded, data, interval, maxVal, getMinValue]
  )

  useEffect(() => {
    setMinMaxValues('y', data.getY(), setMaxValue, setMinValue)
    if (data.getOtherY()) {
      setMinMaxValues(
        'otherY',
        data.getOtherY(),
        setOtherYMaxValue,
        setOtherYMinValue
      )
    }
  }, [setMinMaxValues, data])

  useEffect(() => {
    if (chartType === 'sinePulse') {
      const yItems = chartData.map(item => Math.abs(item.y))
      const maxValue = Math.max(...yItems)

      let unitValue = maxValue / interval

      const matchZeros = unitValue.toString().match(/(\.0*)/)
      const numOfZeros = matchZeros ? matchZeros[0].length - 1 : 0
      const powerOf10 = Math.pow(10, numOfZeros + 1)

      if (unitValue < 1) {
        unitValue = Math.ceil(unitValue * powerOf10) / powerOf10
      } else {
        unitValue = Math.ceil(unitValue)
      }

      setSinePulseValues({
        fixedMaxValue: unitValue * interval,
        maxValue,
      })
    }
  }, [chartType, chartData, interval])

  React.useEffect(() => {
    if (!chartRef.current) {
      return
    }
    const dim = chartRef.current.getBoundingClientRect()

    if (width === 0) {
      setContainerWidth(dim.width - 10)
    }
    if (height === 0) {
      setContainerHeight(
        dim.height - (legends && legends.length > 0 ? LEGEND_HEIGHT : 0)
      )
    }
  }, [height, width, screenSize, legends])

  React.useEffect(() => {
    if (containerWidth > 0) {
      setParentWidthAdj(
        isVertical
          ? containerWidth -
              labelSize.y -
              innerPadding -
              (data.getOtherY() ? labelSize.y : 0)
          : containerWidth - labelSize.x - markerLength
      )
    }
    if (containerHeight > 0) {
      setParentHeightAdj(
        isVertical
          ? containerHeight - labelSize.x - markerLength
          : containerHeight - labelSize.y - innerPadding
      )
    }
  }, [
    labelSize,
    isVertical,
    containerWidth,
    containerHeight,
    markerLength,
    innerPadding,
    data,
  ])

  const renderChartContent = children =>
    React.Children.map(
      children,
      child =>
        child &&
        child.type &&
        [
          ChartBar,
          ChartLine,
          ChartLabel,
          ChartSinePulse,
          ChartConstantLine,
          ChartTooltip,
        ].includes(child.type) &&
        React.cloneElement(child, {
          getBarWidth,
          getDivisionX,
          getDivisionY,
        })
    )

  const renderChartLegend = children => {
    if (legends.length > 0) {
      return <ChartLegends items={legends} />
    }
    return React.Children.map(
      children,
      child => child.type === ChartLegends && React.cloneElement(child)
    )
  }

  const renderChartWithNoRecord = () => {
    return <Chart.Bar />
  }

  return (
    <div
      className="cui-chart-xy"
      id={id}
      ref={chartRef}
      style={{
        '--cuiChartXYText': theme.colors.chart.text,
        '--cuiChartXYDivision': theme.colors.chart.division,
      }}
    >
      <ChartXYProvider
        value={{
          parentWidth: parentWidthAdj,
          parentHeight: parentHeightAdj,
          interval,
          maxValue,
          minValue,
          otherYMaxValue,
          otherYMinValue,
          isNoRecord,
          direction,
          markerLength,
          unit,
          innerPadding,
          sinePulseValues,
          x: data.getX(),
          y: data.getY(),
          otherY: data.getOtherY(),
          actions: {
            getBarWidth,
            getDivisionX,
            getDivisionY,
            getDivisionOtherY: data.getOtherY()
              ? () => getDivisionY(otherYMaxValue, true)
              : () => {},
          },
        }}
      >
        <svg
          className={classnames('cui-chart-xy__container', {
            'no-record': isNoRecord,
          })}
          height={containerHeight}
          width={containerWidth}
        >
          {containerWidth > 0 &&
            containerHeight > 0 &&
            parentHeightAdj > 0 &&
            parentWidthAdj > 0 &&
            !isNoRecord && (
              <g
                transform={`${
                  isVertical
                    ? `translate(${labelSize.y}, ${markerLength})`
                    : `translate(${labelSize.y}, ${labelSize.x})`
                }`}
              >
                {renderChartContent(children)}
              </g>
            )}
          {isNoRecord && (
            <g transform={`translate(${(parentWidthAdj + 50 - 152) / 2}, 30)`}>
              {renderChartWithNoRecord()}
            </g>
          )}
        </svg>
        {isNoRecord && (
          <div className="cui-chart__error">
            <Typo variant="subtitle1" isBold>
              There are no records found.
            </Typo>
            {noRecordFootnotes}
          </div>
        )}
        {!isNoRecord && legends && renderChartLegend(children)}
      </ChartXYProvider>
    </div>
  )
}

ChartXY.defaultProps = {
  data: [],
  x: '',
  y: '',
  width: 0,
  height: 0,
  isNoRecord: false,
  isRounded: true,
  labelSize: {
    x: 20,
    y: 30,
  },
  markerLength: 30,
  unit: {
    x: '',
    y: '',
  },
  legends: [],
  innerPadding: 0,
  isStartFromMinValue: false,
}

ChartXY.propTypes = {
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  width: PropTypes.number,
  height: PropTypes.number,
  interval: PropTypes.number,
  direction: PropTypes.string,
  isNoRecord: PropTypes.bool,
  noRecordFootnotes: PropTypes.node,
  isRounded: PropTypes.bool,
  unit: PropTypes.object,
  labelSize: PropTypes.object,
  markerLength: PropTypes.number,
  legends: PropTypes.array,
  barWidth: PropTypes.number,
  innerPadding: PropTypes.number,
  maxVal: PropTypes.number,
  timeScale: PropTypes.object,
  isStartFromMinValue: PropTypes.bool,
}

export default ChartXY
