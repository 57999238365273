// organize-imports-ignore
import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import './style.scss'
import { useTheme } from '../Theme'
import ToggleButton from './ToggleButton'

const ToggleButtonGroup = ({
  className,
  children,
  isColumn,
  fullWidth,
  onChange,
  value,
  multiple,
}) => {
  const { colors } = useTheme()

  const _ToggleGroup = classnames('cui-toggle-button-group', className, {
    'is-column': isColumn,
    'full-width': fullWidth,
  })

  const onChangeButton = el => {
    if (!onChange) {
      return
    }

    onChange(el)
  }

  const onChangeMultButton = el => {
    if (!onChange) {
      return
    }

    const index = value && value.indexOf(el)
    let newValue

    if (value && index >= 0) {
      newValue = value && value.filter(v => v !== el)
    } else {
      newValue = value && [...value, el]
    }

    onChange(newValue)
  }

  const renderButtons = () => {
    if (!children) return null
    return React.Children.map(children, (child, idx) => {
      return (
        child &&
        child.type === ToggleButton &&
        React.cloneElement(child, {
          ...child.props,
          index: idx,
          selected: value,
          onChange: multiple ? onChangeMultButton : onChangeButton,
          multiple: multiple,
        })
      )
    })
  }

  return (
    <div
      className={_ToggleGroup}
      style={{
        '--cuiBtnGroupBg': colors.background.level1,
        '--cuiBtnGroupText': colors.text.secondary,
        '--cuiBtnGroupActiveBg': colors.background.level2,
        '--cuiBtnGroupActiveText': colors.text.primary,
        '--cuiBtnGroupBorder': colors.divider,
      }}
    >
      {renderButtons()}
    </div>
  )
}

ToggleButtonGroup.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  isColumn: PropTypes.bool,
  fullWidth: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  multiple: PropTypes.bool,
}

ToggleButtonGroup.defaultProps = {
  isColumn: false,
  fullWidth: false,
  multiple: false,
}

export default ToggleButtonGroup
