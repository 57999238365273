import classnames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { useTheme } from '../../Theme'
import { useChart } from '../Chart'
import ChartLegends from '../ChartLegends'

function ChartGraphHorizontalBar({
  showLegend,
  options,
  widthChart,
  className,
  numberResolver,
  fields,
}) {
  const theme = useTheme()
  const { chartData } = useChart()

  const maxValue = Object.keys(chartData).reduce(function (acc, key, index) {
    return acc.value < chartData[key] || index === 0
      ? { id: key, label: key, value: chartData[key] }
      : acc
  }, {})
  const sizeNumber = (maxValue.value + '').length * 12
  const tempPercent = 100 / maxValue.value

  const dataMap = (fields || Object.keys(fields)).reduce((acc, key) => {
    return chartData?.[key] || chartData?.[key] === 0
      ? [
          ...acc,
          {
            id: key,
            label: key,
            value: chartData[key],
            percent: `( 100% - ${sizeNumber}px ) / 100 * ${
              tempPercent * chartData[key]
            }`,
            isMax: key === maxValue.id,
          },
        ]
      : acc
  }, [])

  return (
    <div>
      <div
        className={classnames('cui-chart-horizontal-bar__container', className)}
        style={{
          width: widthChart,
          '--cuiTextColor': theme.colors.chart.text,
        }}
      >
        {dataMap.map(e => {
          const styleDefaults = e.value === 0 ? { maxWidth: 1 } : {}
          return (
            <div className="cui-chart-horizontal-bar__row-item" key={e.id}>
              <div
                className="cui-chart-horizontal-bar__bar"
                style={{
                  width: `calc(${e.percent})`,
                  background: options?.color[e.id],
                  ...styleDefaults,
                }}
              />{' '}
              <p className="cui-chart-horizontal-bar__value">
                {numberResolver(e.value)}
              </p>
            </div>
          )
        })}
      </div>
      {showLegend && (
        <ChartLegends
          items={dataMap.map(field => ({
            color: options.color[field.id],
            field: 'circle',
            value: options.label[field.id],
          }))}
        />
      )}
    </div>
  )
}

ChartGraphHorizontalBar.propTypes = {
  options: PropTypes.shape({
    width: PropTypes.number,
    color: PropTypes.object,
    label: PropTypes.object,
    unit: PropTypes.string,
  }),
  showLegend: PropTypes.bool,
  widthChart: PropTypes.number,
  className: PropTypes.string,
  numberResolver: PropTypes.func,
  fields: PropTypes.array,
}
ChartGraphHorizontalBar.defaultProps = {
  options: {},
  numberResolver: number => number,
}
export default ChartGraphHorizontalBar
