import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useFormikContext } from 'formik'

import Pill from '../../Pill'
import Icons from '../../Icons'
import Popup from '../../Popup'

import FieldLabel from '../FieldLabel'
import FieldError from '../FieldError'
import { useTheme } from '../../Theme'

export default function FieldSelectionArea({
  children,
  name,
  selection,
  multiple,
  onChange,
}) {
  const theme = useTheme()
  const { values, errors, setFieldValue } = useFormikContext() || {}

  const [selectedItems, setSelectedItems] = React.useState(
    values ? values[name] : []
  )

  const [stateError, setError] = React.useState(errors[name])
  const [openSelection, setOpenSelection] = React.useState(false)

  React.useEffect(() => {
    if (openSelection) {
      setSelectedItems(values ? values[name] : [])
    }
  }, [openSelection, values, name])

  const _FieldSelectionArea = classnames(
    'lm--input cui-field cui-field_selectionarea'
  )

  const getValue = v => {
    if (selection.length === 0) return {}
    const val = selection.filter(s => s.id === v)
    return val[0] ? val[0].label : {}
  }

  const handleOpenSelection = () => {
    setOpenSelection(true)
  }

  const handleCloseSelection = () => {
    setOpenSelection(false)
  }

  const handleRemoveItem = item => () => {
    const selected = [...(values[name] || [])]
    const itemIdx = selected.indexOf(item)
    if (itemIdx > -1) {
      selected.splice(itemIdx, 1)
      setFieldValue(name, selected)
    }
  }

  const handleSelectionOptionChange = e => {
    const { value, checked } = e.target
    const allValues = selection.map(item => item.id)

    let fieldValue = value

    if (multiple) {
      fieldValue = [...selectedItems] || []
      if (checked) {
        if (value === 'all') {
          fieldValue = allValues
        }
        fieldValue.push(value)
      } else {
        if (value === 'all') {
          fieldValue = []
        }
        fieldValue.splice(fieldValue.indexOf(value), 1)
      }
    }

    if (fieldValue.length) {
      setError(null)
    }

    setSelectedItems(fieldValue)

    if (onChange) {
      onChange(e)
    }
  }

  const handleConfirm = () => {
    setFieldValue(name, selectedItems)
    setOpenSelection(false)
  }

  const renderSelection = () => {
    const selected = (() => {
      if (values[name] && values[name].length > 0) {
        const _values = values[name]
        return _values.map((v, ind) => (
          <Pill variant="grey" key={`${ind}-${v}`}>
            <button
              className="close"
              type="button"
              onClick={handleRemoveItem(v)}
            >
              <Icons.Close width={10} color={[theme.colors.text.primary]} />
            </button>
            {getValue(v)}
          </Pill>
        ))
      }
      return null
    })()
    return (
      <div className="selection">
        {selected && <div className="selection-container">{selected}</div>}
        <button
          className="selection-add"
          type="button"
          onClick={handleOpenSelection}
        >
          {!selected && <span>Select your items here..</span>}
          <span>
            <Icons.Plus width={16} color={['#4F5A60']} />
          </span>
        </button>
      </div>
    )
  }

  const renderSelectionItemContent = selection => {
    return (
      <div>
        <figure>
          {selection.image && (
            <img src={selection.image} alt={selection.label} />
          )}
        </figure>
        <span>{selection.label}</span>
      </div>
    )
  }

  const renderSelctionOptions = () => {
    if (selection.length === 0) {
      return <Icons.Spinloader width={50} height={50} color={['#333333']} />
    }
    return selection.map((s, idx) => {
      const active = selectedItems.includes(s.id)
      const _SelectionItem = classnames('selection-item', {
        'is-active': active,
      })
      return (
        <div className={_SelectionItem} key={`${s.id}-${idx}`}>
          <input
            id={s.id}
            value={s.id}
            type="checkbox"
            onChange={handleSelectionOptionChange}
            checked={active}
          />
          <label htmlFor={s.id}>{renderSelectionItemContent(s)}</label>
        </div>
      )
    })
  }

  const renderField = () => {
    const label = React.Children.map(children, (child, childIdx) => {
      if (!child) return null
      if (child.type === FieldLabel) {
        return <FieldLabel {...child.props} />
      }
      return null
    })

    return (
      <div className={_FieldSelectionArea}>
        {label}
        <div className={classnames('cui-field_control')}>
          {renderSelection()}
        </div>
      </div>
    )
  }

  return (
    <div className="lm--input-group cui-field_group" id={name}>
      {renderField()}
      {stateError && <FieldError>{stateError}</FieldError>}
      {openSelection && (
        <Popup.Confirmation
          onConfirm={handleConfirm}
          onClose={handleCloseSelection}
          className="cui-selection-area_popup"
        >
          <Popup.Title>Select Retailer(s)</Popup.Title>
          <Popup.Form>
            <div className="selection-content">{renderSelctionOptions()}</div>
          </Popup.Form>
        </Popup.Confirmation>
      )}
    </div>
  )
}

FieldSelectionArea.defaultProps = {
  selection: [],
  multiple: true,
}

FieldSelectionArea.propTypes = {
  selection: PropTypes.array,
}
