import React from 'react'
import PropTypes from 'prop-types'

const Caret = ({ type }) => {
  const renderCaret = () => {
    switch (type) {
      case 'down':
        return (
          <path d="M1.70711 0.292893L7 5.58579L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893C14.0976 0.683418 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.31658 8.09763 6.68342 8.09763 6.29289 7.70711L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683418 0.292893 0.292893C0.683418 -0.0976311 1.31658 -0.0976311 1.70711 0.292893Z" />
        )
      case 'left':
        return (
          <path d="M15.707 6.707a1 1 0 00-1.414-1.414l-6 6a1 1 0 000 1.414l6 6a1 1 0 001.414-1.414L10.414 12l5.293-5.293z" />
        )
      case 'right':
        return (
          <path d="M8.293 17.293L13.586 12 8.293 6.707a1 1 0 011.414-1.414l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414z" />
        )
      default:
        return null
    }
  }
  return renderCaret()
}

Caret.propTypes = {
  type: PropTypes.string,
}

export default Caret
