import React, { useState } from 'react'
import classnames from 'classnames'

import FieldSelection from '../Form/FieldSelection/'
import NavigationStack from '../NavigationStack/NavigationStack'
import Form from '../Form'
import Icons from '../Icons'
import Button from '../Button'
import SearchInput from '../SearchInput'

const ListFilter = ({
  data,
  status,
  onFilterChange,
  onFilterSubmit,
  isMobile,
}) => {
  const [showFilter, setShowStack] = useState(false)
  const [mountFilter, setMountFilter] = useState(false)
  const [errors, setErrors] = useState({})
  const initFilterValue = data.reduce((acc, item) => {
    acc[item.name] = item.value
    return acc
  }, {})

  const filterStackRef = React.useRef(null)
  const formRef = React.useRef(null)

  const _ListFilterStack = classnames('cui-listfilter_navigation_stack', {
    open: showFilter,
    close: !showFilter,
  })

  const handleCloseFilter = () => setShowStack(false)
  const handleClosingEnd = React.useCallback(() => {
    if (!showFilter) {
      handleUnmount()
    }
  }, [showFilter])

  const handleFilterError = values => {
    return data.reduce((acc, item) => {
      if (Object.keys(values).length === 0) {
        acc[item.name] = 'please select one'
      } else if (values[item.name]) {
        acc[item.name] = null
      } else {
        acc[item.name] = 'please select one'
      }
      return acc
    }, {})
  }

  const handleSubmitFilter = values => {
    onFilterSubmit(values)
    setShowStack(false)
  }

  const handleShowFilterMobile = () => {
    setShowStack(true)
    setMountFilter(true)
  }

  const handleUnmount = () => setMountFilter(false)

  React.useEffect(() => {
    const el = filterStackRef.current
    if (filterStackRef.current) {
      filterStackRef.current.addEventListener('animationend', handleClosingEnd)
    }
    return () => {
      if (el) {
        el.removeEventListener('animationend', handleClosingEnd)
      }
    }
  }, [handleClosingEnd])

  const render = () => {
    if (isMobile) {
      return (
        <div className="cui-list__filter">
          <div className="cui-list__filter--wrapper">
            <div
              className="cui-list__filter--mobile"
              onClick={handleShowFilterMobile}
            >
              <span className="text">Filter</span>
              <span>
                <Icons.Filter width={12} height={10} />
              </span>
            </div>

            {mountFilter && (
              <NavigationStack
                className={_ListFilterStack}
                onClose={handleCloseFilter}
                portal
                ref={filterStackRef}
              >
                <NavigationStack.Header />
                <NavigationStack.Scenes>
                  <NavigationStack.Scene title="Filter" id="filter-list">
                    <Form
                      initialValues={initFilterValue}
                      onSubmit={handleSubmitFilter}
                      innerRef={formRef}
                    >
                      <Form.Wrapper>
                        {data.map((data, idx) => {
                          if (!data.options) {
                            return (
                              <SearchInput
                                key={idx}
                                placeholder={data.placeholder}
                                onSearch={keyword => {
                                  formRef.current.setFieldValue(
                                    data.name,
                                    keyword
                                  )
                                }}
                                hideCloseIcon
                                initialValue={data.value}
                              />
                            )
                          }
                          return (
                            <FieldSelection
                              key={idx}
                              spec={{
                                id: data.id,
                                name: data.name,
                              }}
                              options={data.options}
                              placeholder={data.placeholder}
                              errors={errors}
                            />
                          )
                        })}
                        <Button
                          variant="solid"
                          type="submit"
                          id="submit-filter"
                        >
                          Update
                        </Button>
                      </Form.Wrapper>
                    </Form>
                  </NavigationStack.Scene>
                </NavigationStack.Scenes>
              </NavigationStack>
            )}
            <div className="cui-list__filter--status">{status}</div>
          </div>
        </div>
      )
    }

    return (
      <div className="cui-list__filter">
        <Form
          initialValues={initFilterValue}
          onSubmit={handleSubmitFilter}
          innerRef={formRef}
        >
          <Form.Wrapper>
            <div className="cui-list__filter--wrapper">
              <div className="cui-list__filter--desktop">
                {data.map((data, idx) => {
                  if (!data.options) {
                    return (
                      <SearchInput
                        key={idx}
                        placeholder={data.placeholder}
                        hideCloseIcon
                        onSearch={keyword => {
                          formRef.current.setFieldValue(data.name, keyword)
                          onFilterChange({
                            ...formRef.current.values,
                            [data.name]: keyword,
                          })
                        }}
                      />
                    )
                  }
                  return (
                    <FieldSelection
                      key={idx}
                      spec={{
                        id: data.id,
                        name: data.name,
                      }}
                      options={data.options}
                      placeholder={data.placeholder}
                      onChange={value => {
                        onFilterChange({ ...formRef.current.values, ...value })
                      }}
                    />
                  )
                })}
              </div>
              <div className="cui-list__filter--status">{status}</div>
            </div>
          </Form.Wrapper>
        </Form>
      </div>
    )
  }

  return render()
}

export default ListFilter
