import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import './styles.scss'

const FormGroupLabel = props => {
  const { children } = props
  const _FormGroupLabel = classnames('cui-formgrouplabel')
  return (
    <div className={_FormGroupLabel}>
      <div className="lm--input-group cui-field_group">
        <div className="lm--input cui-field">
          <label className="cui-formgrouplabel_label">{children}</label>
          <div className="cui-field_control" />
        </div>
      </div>
    </div>
  )
}

FormGroupLabel.propTypes = {
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
}

export default FormGroupLabel
