import React from 'react'

function CardIcon() {
  return (
    <g className="icon-card">
      <mask
        id="icon-card-mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="18"
        height="18"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 4C3 3.44772 3.44772 3 4 3H20C20.5523 3 21 3.44772 21 4V10C21 10.5523 20.5523 11 20 11H4C3.44772 11 3 10.5523 3 10V4ZM5 9V5H19V9H5Z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 14C3 13.4477 3.44772 13 4 13H20C20.5523 13 21 13.4477 21 14V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V14ZM5 19V15H19V19H5Z"
        />
      </mask>
      <g mask="url(#icon-card-mask0)">
        <rect width="24" height="24" />
      </g>
    </g>
  )
}

export default CardIcon
