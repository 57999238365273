import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect, withRouter } from 'react-router-dom'

import Progress from '../Progress'
import CUIStorage from '../Tools/storage'

const WizContext = React.createContext()

export const useWiz = () => {
  const WizCtx = React.useContext(WizContext)
  return WizCtx
}

export const withWiz = (Component, otherProps) => {
  return props => (
    <WizContext.Consumer>
      {context => (
        <div>
          <Component {...props} {...otherProps} {...context} />
        </div>
      )}
    </WizContext.Consumer>
  )
}

function getActiveItem(items, pathname) {
  if (items.length === 0) return null
  const activeItem = items.filter(item => {
    return item.path === pathname
    // return item.active
  })
  return activeItem
}

function getItemIdx(items, pathname) {
  if (items.length === 0) return 0
  const itemIdx = items.findIndex(item => item.path === pathname)
  return itemIdx
}

class Wiz extends PureComponent {
  state = {
    data: this.props.initialData || {},
    active: {
      page: getActiveItem(this.props.items),
    },
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this._getActivePageByPath()
    }
  }

  componentDidMount() {
    this._checkFlow()
  }

  componentWillUnmount() {
    document.body.classList.remove('__wiz')
  }

  render() {
    const { title, children } = this.props
    const { data } = this.state
    return (
      <>
        <WizContext.Provider
          value={{
            data,
            actions: {
              next: this._handleNext,
              prev: this._handlePrev,
              setData: this._setData,
            },
          }}
        >
          <div className="cui-wiz">
            {title && <h3> {title} </h3>}
            <Progress items={this._getPages()} progress={this.props.progress} />
            <div className="cui-wiz__content">
              {this._renderPages()}
              {children}
            </div>
          </div>
        </WizContext.Provider>
      </>
    )
  }

  _renderPages = () => {
    const { id, basePath, items } = this.props

    if (!items) return null
    return (
      <>
        <Route
          exact
          path={basePath}
          render={() => <Redirect to={items[0].actualPath || items[0].path} />}
        />
        {items.map((page, idx) => {
          const Component = page.component
          return (
            <Route
              key={`${id}-wiz--${idx}`}
              path={page.path}
              component={Component}
            />
          )
        })}
      </>
    )
  }

  _checkFlow = () => {
    const { history, location, items, storageKey } = this.props
    const { pathname } = location
    const pageIdx = getItemIdx(items, pathname)
    const currPageIdx = parseInt(CUIStorage.Retrieve(storageKey))

    if (currPageIdx === items.length) {
      history.push('/')
      return
    }

    if (pageIdx > 0) {
      if (!currPageIdx) {
        CUIStorage.Save(storageKey, 0)
        history.push(items[0].actualPath || items[0].path)
      } else {
        if (pageIdx <= currPageIdx) {
          CUIStorage.Save(storageKey, pageIdx)
          this._processFlow()
        } else {
          CUIStorage.Save(storageKey, 0)
          history.push(items[0].actualPath || items[0].path)
          this._processFlow()
        }
      }
    } else {
      // If there's no pageIdx or if its 0, set it to the first page
      CUIStorage.Save(storageKey, 0)
      this._processFlow()
    }
  }

  _processFlow = () => {
    const { onInitialization } = this.props
    this._getActivePageByPath()
    if (onInitialization) {
      onInitialization()
    }
  }

  _handleNext = () => {
    const { history, location, storageKey, onNext, onSubmit, items } =
      this.props
    const { data } = this.state
    const currentPathIndex = items.findIndex(
      pg => pg.path === location.pathname
    )
    if (currentPathIndex > -1 && currentPathIndex < items.length - 1) {
      const nextPageIdx = currentPathIndex + 1
      const nextPage = items[nextPageIdx]
      // Next
      if (onNext) {
        onNext(data)
      }
      history.push(nextPage.actualPath || nextPage.path)
      CUIStorage.Save(storageKey, nextPageIdx)
    } else {
      // Submission
      if (onSubmit) {
        onSubmit(data, {
          resetWiz: () => {
            this.setState(
              {
                data: this.props.initialData || {},
              },
              () => {
                history.push(items[0].actualPath || items[0].path)
              }
            )
          },
        })
      }
      CUIStorage.Delete(storageKey)
    }
  }

  _handlePrev = () => {
    const { history, onPrev, location, items, storageKey } = this.props
    const { data } = this.state
    const currentPathIndex = items.findIndex(
      pg => pg.path === location.pathname
    )
    if (currentPathIndex > -1 && currentPathIndex <= items.length - 1) {
      const prevPageIdx = currentPathIndex - 1
      const prevPage = items[prevPageIdx]

      if (onPrev) {
        onPrev(data)
      }
      history.push(prevPage.actualPath || prevPage.path)
      CUIStorage.Save(storageKey, prevPageIdx)
    }
  }

  _setData = (key, data) => {
    this.setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [key]: data,
      },
    }))
  }

  _getActivePageByPath() {
    const { location, items } = this.props
    if (!location.pathname) {
      return
    }
    const activePage = getActiveItem(items, location.pathname)
    if (activePage) {
      this.setState(prevState => ({
        ...prevState,
        active: {
          ...prevState.active,
          page: activePage,
        },
      }))
    }
  }

  _getPages() {
    const { items } = this.props
    const {
      active: { page: activePage },
    } = this.state
    const result = items.map(page => {
      let active = false

      if (activePage && activePage[0]) {
        active = activePage[0].path === page.path
      }

      return {
        ...page,
        active,
      }
    })
    return result
  }
}

Wiz.propTypes = {
  id: PropTypes.string,
  storageKey: PropTypes.string,
  initialData: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  items: PropTypes.array,
  basePath: PropTypes.string,
  onInitialization: PropTypes.func,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onSubmit: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
  progress: PropTypes.bool,
}

Wiz.defaultProps = {
  id: '',
  storageKey: 'casperui:wiz',
  items: null,
  basePath: '/',
  progress: false,
}

Wiz.useWiz = useWiz

export default withRouter(Wiz)
