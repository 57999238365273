import React from 'react'

function MediaPlayIcon() {
  return (
    <g className="icon-media-play">
      <mask
        id="media-play-mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="12"
        height="12"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 8C2 11.3137 4.68629 14 8 14C11.3137 14 14 11.3137 14 8C14 4.68629 11.3137 2 8 2C4.68629 2 2 4.68629 2 8ZM3.2 8C3.2 5.34903 5.34903 3.2 8 3.2C10.651 3.2 12.8 5.34903 12.8 8C12.8 10.651 10.651 12.8 8 12.8C5.34903 12.8 3.2 10.651 3.2 8ZM7.70313 6.11197C7.49856 5.97559 7.23553 5.96287 7.01876 6.07889C6.80199 6.1949 6.66667 6.4208 6.66667 6.66667V9.33333C6.66667 9.5792 6.80199 9.8051 7.01876 9.92112C7.23553 10.0371 7.49856 10.0244 7.70313 9.88803L9.70313 8.5547C9.8886 8.43106 10 8.2229 10 8C10 7.7771 9.8886 7.56894 9.70313 7.4453L7.70313 6.11197Z"
        />
      </mask>
      <g mask="url(#media-play-mask0)">
        <rect width="16" height="16" />
      </g>
    </g>
  )
}

export default MediaPlayIcon
