import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const FieldLabel = ({ children, for: forLabel, className, required }) => {
  const _FieldLabel = classnames('cui-field_label', className)
  return (
    <label className={_FieldLabel} htmlFor={forLabel}>
      {children}
      {required && <sup>*</sup>}
    </label>
  )
}

FieldLabel.propTypes = {
  for: PropTypes.string,
}

export default FieldLabel
