import React from 'react'
import ReactDOMServer from 'react-dom/server'
import PropTypes from 'prop-types'
import { useTheme } from '../Theme'

const isChildNull = children => {
  return !children && !ReactDOMServer.renderToStaticMarkup(children)
}

const ShellAnimation = ({ type }) => {
  switch (type) {
    case 'tabs': {
      return (
        <div className="cui-shell__tabs">
          <div className="cui-shell" />
          <div className="cui-shell" />
          <div className="cui-shell" />
        </div>
      )
    }
    case 'list': {
      return (
        <div className="cui-shell__list">
          <div className="cui-shell" />
          <div className="cui-shell" />
          <div className="cui-shell" />
        </div>
      )
    }
    default: {
      return <div className="cui-shell" />
    }
  }
}

ShellAnimation.propTypes = {
  type: 'tabs',
}

const Shell = ({ children, type, width, height, className, style }) => {
  const theme = useTheme()
  const shellStyle = {
    width: width || '100%',
    height,
    minHeight: '10px',
    '--cuiShellColor1': theme.colors.shell.color1,
    '--cuiShellColor2': theme.colors.shell.color2,
    ...style,
  }
  const renderChildren = () => {
    if (isChildNull(children)) {
      return (
        <div style={shellStyle} className={className}>
          {' '}
          <ShellAnimation type={type} />{' '}
        </div>
      )
    }
    return children
  }
  return renderChildren()
}

Shell.propTypes = {
  children: PropTypes.node,
  type: PropTypes.string,
}

Shell.defaultProps = {
  type: 'normal',
}

export default Shell
