import React from 'react'
import PropTypes from 'prop-types'

const CardContainer = ({ children, id }) => {
  return (
    <div className="cui-card__container" id={id}>
      {children}
    </div>
  )
}

CardContainer.propTypes = {
  // CardTitle children
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  id: PropTypes.string,
}

export default CardContainer
