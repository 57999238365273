import React from 'react'

class EligiblePayment extends React.PureComponent {
  render() {
    return (
      <g className="illus-ecommerce">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M187.717 33.5897C153.774 16.9097 113.548 23.2767 76.3511 30.1127C67.8121 31.6827 59.1201 33.3097 51.4481 37.3727C31.8361 47.7597 23.1491 73.2357 28.6001 94.7477C34.0511 116.261 51.3451 133.42 71.4011 142.922C91.4571 152.424 114.077 155.117 136.268 155.427C152.289 155.651 169.255 154.402 182.459 145.324C197.335 135.099 204.528 116.921 208.525 99.3177C211.351 86.8747 212.988 73.8467 210.197 61.3957C207.406 48.9447 199.488 38.5157 187.717 33.5897Z"
          fill="#F8FCFF"
        />
        <mask
          id="ecommerce-mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="-1"
          y="0"
          width="240"
          height="180"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M187.717 33.5897C153.774 16.9097 113.548 23.2767 76.3511 30.1127C67.8121 31.6827 59.1201 33.3097 51.4481 37.3727C31.8361 47.7597 23.1491 73.2357 28.6001 94.7477C34.0511 116.261 51.3451 133.42 71.4011 142.922C91.4571 152.424 114.077 155.117 136.268 155.427C152.289 155.651 169.255 154.402 182.459 145.324C197.335 135.099 204.528 116.921 208.525 99.3177C211.351 86.8747 212.988 73.8467 210.197 61.3957C207.406 48.9447 199.488 38.5157 187.717 33.5897Z"
            fill="#F8FCFF"
          />
        </mask>
        <g mask="url(#ecommerce-mask0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M63.1746 155.379H189.235V54.3986H63.1746V155.379Z"
            fill="#CED5DC"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.9199 54.18H188.98V42.96C188.98 40.773 187.207 39 185.02 39H66.2199C64.3974 39 62.9199 40.4775 62.9199 42.3V54.18Z"
            fill="#E4EBF2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.9893 165.415H189.049V154.415H62.9893V165.415Z"
            fill="#266396"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M106.471 165.1H145.815V89.2H106.471V165.1Z"
            fill="#BEC8CE"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M109.892 165.412H142.393V96.1119H109.892V165.412Z"
            fill="#00B0B2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M114.4 153.84H137.494V101.04H114.4V153.84Z"
            fill="#E4EBF2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M125.961 110.487L123.012 116.791C122.961 116.899 122.971 117.025 123.04 117.123C123.106 117.223 123.222 117.284 123.345 117.285L125.91 117.292L125.899 121.147C125.899 121.314 126.019 121.456 126.188 121.491C126.213 121.497 126.239 121.5 126.264 121.5C126.405 121.501 126.539 121.422 126.598 121.292L129.548 114.99C129.599 114.881 129.589 114.756 129.52 114.655C129.453 114.555 129.338 114.496 129.215 114.496L126.65 114.488L126.661 110.631C126.661 110.466 126.541 110.323 126.372 110.289C126.345 110.283 126.319 110.28 126.293 110.28C126.151 110.28 126.021 110.36 125.961 110.487Z"
            fill="#FFC466"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M113.08 130.74H139.48V128.76H113.08V130.74Z"
            fill="#00B0B2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M114.4 132.06H137.5V130.74H114.4V132.06Z"
            fill="#008587"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M70.3999 154.5H98.9999V105H70.3999V154.5Z"
            fill="#00B0B2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M72.5999 154.5H96.7999V113.8H72.5999V154.5Z"
            fill="#F8FCFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M72.5999 125.9H96.7999V107.2H72.5999V125.9Z"
            fill="#E4EBF2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M63.043 93.1044L189.103 106.98V85.2H63.043V93.1044Z"
            fill="#7C8084"
            fillOpacity="0.5"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M63.5064 54.4236L55.6597 82.2517H196.9L189.053 54.4236H63.5064Z"
            fill="url(#ecommerce-paint0_linear)"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M81.2391 82.2517H68.4092L74.8238 54.4236H86.2279L81.2391 82.2517Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M106.898 82.2517H94.0688L97.6325 54.4236H109.037L106.898 82.2517Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M132.558 82.2517H119.729L120.441 54.4236H131.845L132.558 82.2517Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M158.217 82.2517H145.388L143.249 54.4236H154.653L158.217 82.2517Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M183.876 82.2517H171.047L166.057 54.4236H177.462L183.876 82.2517Z"
            fill="white"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M177.951 95.0952H176.973C173.7 95.0952 171.047 92.4396 171.047 89.1631V82.2518H183.876V89.1631C183.876 92.4396 181.224 95.0952 177.951 95.0952Z"
            fill="#E4EBF2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M190.78 95.0952H189.802C186.529 95.0952 183.877 92.4396 183.877 89.1631V82.2518H196.706V89.1631C196.706 92.4396 194.053 95.0952 190.78 95.0952Z"
            fill="#00649A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M152.291 95.0952H151.313C148.041 95.0952 145.387 92.4396 145.387 89.1631V82.2518H158.217V89.1631C158.217 92.4396 155.564 95.0952 152.291 95.0952Z"
            fill="#E4EBF2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M165.121 95.0952H164.143C160.87 95.0952 158.217 92.4396 158.217 89.1631V82.2518H171.047V89.1631C171.047 92.4396 168.394 95.0952 165.121 95.0952Z"
            fill="#00649A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M126.632 95.0952H125.654C122.381 95.0952 119.729 92.4396 119.729 89.1631V82.2518H132.558V89.1631C132.558 92.4396 129.905 95.0952 126.632 95.0952Z"
            fill="#E4EBF2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M139.462 95.0952H138.483C135.211 95.0952 132.558 92.4396 132.558 89.1631V82.2518H145.387V89.1631C145.387 92.4396 142.734 95.0952 139.462 95.0952Z"
            fill="#00649A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M100.973 95.0952H99.9944C96.7215 95.0952 94.0688 92.4396 94.0688 89.1631V82.2518H106.898V89.1631C106.898 92.4396 104.245 95.0952 100.973 95.0952Z"
            fill="#E4EBF2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M113.802 95.0952H112.824C109.552 95.0952 106.898 92.4396 106.898 89.1631V82.2518H119.728V89.1631C119.728 92.4396 117.075 95.0952 113.802 95.0952Z"
            fill="#00649A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M75.3129 95.0952H74.3348C71.0624 95.0952 68.4092 92.4396 68.4092 89.1631V82.2518H81.2391V89.1631C81.2391 92.4396 78.5858 95.0952 75.3129 95.0952Z"
            fill="#E4EBF2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M88.1424 95.0952H87.1648C83.892 95.0952 81.2393 92.4396 81.2393 89.1631V82.2518H94.0686V89.1631C94.0686 92.4396 91.4153 95.0952 88.1424 95.0952Z"
            fill="#00649A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M62.4833 95.0952H61.5052C58.2323 95.0952 55.5796 92.4396 55.5796 89.1631V82.2518H68.4089V89.1631C68.4089 92.4396 65.7562 95.0952 62.4833 95.0952Z"
            fill="#00649A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M154 154.5H182.6V105H154V154.5Z"
            fill="#00B0B2"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M156.2 154.636H180.4V107.336H156.2V154.636Z"
            fill="#F8FCFF"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M156.2 124.936H180.4V107.336H156.2V124.936Z"
            fill="#E4EBF2"
          />
          <rect
            x="83.5999"
            y="107.2"
            width="2.2"
            height="47.3"
            fill="#00B0B2"
          />
          <rect x="167.2" y="107.2" width="2.2" height="47.3" fill="#00B0B2" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M72.1599 50.88C73.9824 50.88 75.4599 49.4025 75.4599 47.58C75.4599 45.7575 73.9824 44.28 72.1599 44.28C70.3373 44.28 68.8599 45.7575 68.8599 47.58C68.8599 49.4025 70.3373 50.88 72.1599 50.88Z"
            fill="#E54545"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M84.04 50.88C85.8625 50.88 87.34 49.4025 87.34 47.58C87.34 45.7575 85.8625 44.28 84.04 44.28C82.2175 44.28 80.74 45.7575 80.74 47.58C80.74 49.4025 82.2175 50.88 84.04 50.88Z"
            fill="#FF9D5A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M95.9199 50.88C97.7424 50.88 99.2199 49.4025 99.2199 47.58C99.2199 45.7575 97.7424 44.28 95.9199 44.28C94.0973 44.28 92.6199 45.7575 92.6199 47.58C92.6199 49.4025 94.0973 50.88 95.9199 50.88Z"
            fill="#00B0B2"
          />
        </g>
        <defs>
          <linearGradient
            id="ecommerce-paint0_linear"
            x1="-4.93854"
            y1="54.7459"
            x2="-4.81148"
            y2="82.5883"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#65A1DB" />
            <stop offset="1" stopColor="#3176DE" />
            <stop offset="1" stopColor="#3176DE" />
          </linearGradient>
        </defs>
      </g>
    )
  }
}

export default EligiblePayment
