import React from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'

import FieldInput from './FieldInput'
import FieldLabel from './FieldLabel'
import FieldCTA from './FieldCTA'
import FieldSelection from './FieldSelection'
import FieldCheckbox from './FieldCheckbox'
import FieldContent from './FieldContent'
import FieldChoose from './FieldChoose'
import FieldFileUpload from './FieldFileUpload'

import FieldPrefix from './FieldPrefix'
import FieldUnit from './FieldUnit'
import FieldDatePicker from './FieldDatePicker'
import FieldTimePicker from './FieldTimePicker'
import FormGroup from './FormGroup'
import FieldGroup from './FieldGroup'
import { Hint } from './FieldMisc'
import FormSummary from './FormSummary'
import FieldBare from './FieldBare'
import FieldNote from './FieldNote'
import FormReceipt from './FormReceipt'
import FieldInputSplit from './FieldInput/FieldInputSplit'

import Utils from '../Tools/utils'

const Fields = ({ fields = [], groups = [], options = {} }) => {
  const formProps = useFormikContext()
  if (fields.length === 0) {
    return null
  }
  const sortedFields = fields.sort((a, b) => b.index - a.index)

  const groupTheFields = (
    source = [],
    by,
    groupOptions = { shape: ['fields', 'label'] }
  ) => {
    if (!by) return source
    const result = {}
    by.forEach(grp => {
      const grpItems = source.filter(item => {
        return item.group && item.group === grp.id
      })
      if (groupOptions) {
        result[grp.id] = result[grp.id] || {}
        if (groupOptions.shape) {
          groupOptions.shape.forEach(key => {
            if (key === 'fields') {
              result[grp.id][key] = grpItems
            } else {
              result[grp.id][key] = grp[key]
            }
          })
        }
      } else {
        result[grp.id] = grpItems
      }
    })
    return result
  }

  const shouldDisplay = (parentId, condition, parentValues) => {
    const { values } = formProps
    switch (condition) {
      case 'VALUES_EQ': {
        return parentValues[0].toLowerCase() === values[parentId].toLowerCase()
      }
      case 'VALUES_EITHER': {
        if (Array.isArray(values[parentId])) {
          return parentValues.some(opt => values[parentId].includes(opt))
        }
        return parentValues.some(opt => opt === values[parentId])
      }
      case 'NON_ZERO': {
        return parseInt(values[parentId]) > 0
      }
      case 'EXISTS': {
        return !!values[parentId]
      }
      default: {
        return false
      }
    }
  }

  const shouldDisplayField = field => {
    if (!field.parentId) return true

    const condition = field.parentCondition
    const parentId = field.parentId
    const parentValues = field.parentValues

    if (options.additionalCriteria) {
      if (
        options.additionalCriteria[field.id] &&
        options.additionalCriteria[field.id].length > 0
      ) {
        const criteriaNotFulfilled = options.additionalCriteria[field.id].some(
          ac => {
            return !shouldDisplay(
              ac.parentId,
              ac.parentCondition,
              ac.parentValues
            )
          }
        )
        if (criteriaNotFulfilled) {
          return false
        }
      }
    }

    return shouldDisplay(parentId, condition, parentValues)
  }

  const checkIfFieldIsRequired = field => {
    if (field.validations && Array.isArray(field.validations)) {
      if (field.validations.length > 0) {
        let validations = field.validations
        if (
          Array.isArray(field.validations[0]) &&
          field.validations[0].length > 0
        ) {
          validations = field.validations[0]
        }
        return validations.some(v => v.type === 'required')
      }
    }
    return field.mandatory
  }

  const getFieldCompAndProps = field => {
    const isFieldRequired = checkIfFieldIsRequired(field)
    const result = {
      Component: FieldInput,
      props: {
        ...field,
        key: `field-${field.id}`,
        id: field.id,
        name: field.id,
        spec: {
          id: field.id,
          name: field.id,
        },
        disabled: field.disabled,
        children: [],
      },
    }
    let fieldLabel = field.label || field.question

    if (fieldLabel && !isFieldRequired && field.optional) {
      fieldLabel = (
        <>
          {field.label}
          <br />
          <i>(optional)</i>
        </>
      )
    }

    if (fieldLabel) {
      result.props.children.push(
        <FieldLabel key={`field-fieldlabel-${field.id}`}>
          {fieldLabel}
        </FieldLabel>
      )
    }

    if (field.hint) {
      if (typeof field.hint === 'object') {
        result.props.children.push(
          <Hint key={`field-fieldhint-${field.id}`}>{field.hint}</Hint>
        )
      } else {
        result.props.children.push(
          <Hint key={`field-fieldhint-${field.id}`} content={field.hint} />
        )
      }
    }

    if (field.content) {
      if (typeof field.content === 'object') {
        let content = field.content.node
        if (field.content.params) {
          content = Utils.parseStrTemplate(content, field.content.params)
        }
        result.props.children.push(
          <FieldContent
            key={`field-fieldcontent-${field.id}`}
            content={content}
          />
        )
      } else {
        result.props.children.push(
          <FieldContent key={`field-fieldcontent-${field.id}`}>
            {field.content}
          </FieldContent>
        )
      }
    }

    if (field.prefix) {
      result.props.children.push(
        <FieldPrefix
          key={`field-fieldprefix-${field.id}`}
          smallwidth
          {...field.prefix}
          disabled={
            field.prefix.disabled === undefined ? true : field.prefix.disabled
          }
        >
          {field.prefix.value}
        </FieldPrefix>
      )
    }

    if (field.unit) {
      if (typeof field.unit === 'object') {
        if (field.after) {
          result.props.children.push(
            <FieldUnit after>{field.unit.node}</FieldUnit>
          )
        } else {
          result.props.children.push(<FieldUnit>{field.unit.node}</FieldUnit>)
        }
      } else {
        result.props.children.push(<FieldUnit>{field.unit}</FieldUnit>)
      }
    }

    switch (field.type) {
      case 'CHECKBOX': {
        result.Component = FieldCheckbox
        break
      }
      case 'CHECKBOX_ALL':
      case 'CHOICES':
      case 'SIMGLECHOICE': {
        result.Component = FieldChoose
        result.props = {
          ...result.props,
          multiple: Boolean(field.multiple),
          isColumn: Boolean(field.column),
          choices: field.options,
        }
        break
      }
      case 'SELECT': {
        result.Component = FieldSelection
        result.props = {
          ...result.props,
          options: [
            {
              id: '',
              value: '',
              label: 'Select One',
              disabled: true,
            },
            ...field.options,
          ],
        }
        break
      }
      case 'MOBILE': {
        result.Component = FieldInput.Text
        result.props = {
          ...result.props,
          onlyNumber: true,
          inputProps: {
            ...result.props.inputProps,
            maxLength: field.maxLength ? `${field.maxLength}` : null,
          },
        }
        break
      }
      case 'DATEPICKER': {
        result.Component = FieldDatePicker
        break
      }
      case 'FILEUPLOAD': {
        result.Component = FieldFileUpload
        break
      }
      case 'SPLIT': {
        result.Component = FieldInputSplit
        break
      }
      default: {
        result.Component = FieldInput.Text
        result.props = {
          ...result.props,
          test: true,
        }
        break
      }
    }
    // console.log('# result', result)
    return result
  }

  const renderFields = () => {
    return sortedFields.map((field, fieldKey) => {
      const isFieldRequired = checkIfFieldIsRequired(field)
      const inputAttr = field.inputAttr || {}
      let fieldLabel = field.label || field.question
      if (!isFieldRequired) {
        fieldLabel = (
          <>
            {field.label} <br />
            <i>(optional)</i>
          </>
        )
      }
      if (!field.type) {
        if (field.content) {
          return (
            <FieldBare
              key={`${field.id}_${fieldKey}`}
              field="field-item--content"
              label={fieldLabel}
            >
              <p>{field.content}</p>
            </FieldBare>
          )
        }
      }

      if (shouldDisplayField(field, formProps.values)) {
        const FieldComp = getFieldCompAndProps(field)
        const Field = FieldComp.Component
        return (
          <Field name={field.name} {...FieldComp.props} {...inputAttr}>
            {/* {Boolean(field.label) && <FieldLabel>{field.label}</FieldLabel>}
            {Boolean(field.content) && (
              <FieldContent>{field.content}</FieldContent>
            )} */}
          </Field>
        )
      }
      return null
    })
  }

  if (groups && groups.length > 0) {
    const grouped = groupTheFields(sortedFields, groups)
    const groupedKeys = Object.keys(grouped)
    return groupedKeys.map(key => {
      const grp = grouped[key]
      return (
        <FieldGroup key={key}>
          {grp.label && <FieldLabel>{grp.label}</FieldLabel>}
          {renderFields()}
        </FieldGroup>
      )
    })
  }
  return renderFields()
}

Fields.propTypes = {
  fields: PropTypes.array,
  options: PropTypes.object,
}

export default Fields
