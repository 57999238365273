import React from 'react'
import PropTypes from 'prop-types'

const SummaryTotal = ({ children }) => {
  return <div className="cui-summary__total">{children}</div>
}

SummaryTotal.propTypes = {
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
}

export default SummaryTotal
