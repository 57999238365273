import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useFormikContext } from 'formik'
import Button from '../../Button'
import Icons from '../../Icons'
import FieldLabel from '../FieldLabel'
import FieldError from '../FieldError'

const FieldFileUpload = ({
  children,
  spec,
  max,
  format,
  onChange,
  maxNameChars,
}) => {
  const [loading, setLoading] = useState(false)

  const {
    setFieldValue,
    setFieldError,
    setFieldTouched,
    values,
    errors,
    touched,
  } = useFormikContext()

  const handleChangeFile = file => {
    if (file) {
      const nameParts = file.name.split('.')
      const name = nameParts.slice(0, -1).join('.')
      const ext = `${nameParts[nameParts.length - 1]}`.toLowerCase()

      if (format && format.length > 0 && !format.includes(`.${ext}`)) {
        let exts = format.slice(0, -1).join(', ')
        if (format.length > 1) {
          exts += ' and ' + format[format.length - 1]
        } else {
          exts = format[0]
        }
        setFieldTouched(spec.name, true, false)
        setFieldError(spec.name, `Please upload file type in ${exts} only`)
        return
      }

      if (name.length > maxNameChars) {
        setFieldTouched(spec.name, true, false)
        setFieldError(
          spec.name,
          `Please rename your filename to ${maxNameChars} characters`
        )
        return
      }

      if (file.size > max) {
        setFieldTouched(spec.name, true, false)
        setFieldError(
          spec.name,
          `Please upload attachment with size no more than ${max / 1000000}MB.`
        )
        return
      }
    }

    if (onChange) {
      setLoading(true)
      onChange(file, err => {
        setLoading(false)
        if (err) {
          const errMsg =
            err.message || "Sorry, there's an error occured. Please try again."
          setFieldTouched(spec.name, true)
          setFieldError(spec.name, errMsg)
        } else {
          setFieldValue(spec.name, file)
        }
      })
    } else {
      setFieldTouched(spec.name, true)
      setFieldValue(spec.name, file)
    }
  }

  const renderFieldFileUpload = () => {
    const file = values[spec.name]
    return file ? (
      <div className="cui-field-file__file-name-wrapper">
        <Icons.File width={14} height={16} color={['#818a91']} />
        <span className="cui-field-file__file-name">{file.name}</span>
        <Button
          small
          disabled={loading}
          onClickHandler={() => handleChangeFile('')}
        >
          {loading ? 'Deleting...' : 'Delete'}
        </Button>
      </div>
    ) : (
      <>
        <div className="lm--choose-file-btn">
          <input
            type="file"
            name={spec.name}
            onChange={event => handleChangeFile(event.target.files[0])}
            disabled={loading}
            data-testid="fileInput"
            accept={format.join(', ')}
          />
          <div className={classnames('cui-field-file__button', { loading })}>
            {loading && (
              <Icons.Spinloader width={25} height={25} color={['#333333']} />
            )}
            <span>Choose File</span>
          </div>
          <span className="lm--field-hint cui-field-file__button_msg">
            {`${max ? `Max ${max / 1000000}MB` : ''}${
              format !== '' ? `, file format ${format.join(', ')}` : ''
            }`}
          </span>
        </div>
      </>
    )
  }

  const renderField = () => {
    const label = React.Children.map(children, (child, childIdx) => {
      if (child && child.type === FieldLabel) {
        return <FieldLabel {...child.props} />
      }
      return null
    })

    return (
      <div className="lm--input cui-field cui-field-file__field-wrapper">
        {label}
        <div
          className={classnames(
            'lm--input-control cui-field_control cui-field-file',
            {
              'full-width': React.Children.count(children) === 0,
            }
          )}
        >
          {renderFieldFileUpload()}
        </div>
      </div>
    )
  }

  return (
    <div className="lm--input-group cui-field_group" id={spec.id}>
      {renderField()}
      {touched[spec.name] && errors[spec.name] && (
        <FieldError>{errors[spec.name]}</FieldError>
      )}
    </div>
  )
}

FieldFileUpload.propTypes = {
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  spec: {
    id: PropTypes.string,
    name: PropTypes.string,
  },
  onChange: PropTypes.func,
  format: PropTypes.array,
  max: PropTypes.number,
  maxNameChars: PropTypes.number,
}

FieldFileUpload.defaultProps = {
  max: 4000000,
  format: ['.pdf', '.jpg', '.jpeg', '.png'],
}

export default FieldFileUpload
