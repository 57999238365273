import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Formik, Form as FormikForm, useFormikContext } from 'formik'
import * as yup from 'yup'

import FieldInput from './FieldInput'
import FieldLabel from './FieldLabel'
import FieldCTA from './FieldCTA'
import FieldSelection from './FieldSelection'
import FieldCheckbox from './FieldCheckbox'
import FieldContent from './FieldContent'
import FieldChoose from './FieldChoose'
import FieldFileUpload from './FieldFileUpload'

import FieldPrefix from './FieldPrefix'
import FieldUnit from './FieldUnit'
import FieldDatePicker from './FieldDatePicker'
import FieldTimePicker from './FieldTimePicker'
import FormGroup from './FormGroup'
import FieldGroup from './FieldGroup'
import { Hint } from './FieldMisc'
import FormSummary from './FormSummary'
import FieldBare from './FieldBare'
import FieldNote from './FieldNote'
import FormReceipt from './FormReceipt'
import FormWrapper from './FormWrapper'
import Address from '../Address'
import FieldSelectionArea from './FieldSelectionArea'

import Fields from './Fields'

import './styles.scss'
import FieldDropdown from './FieldDropdown'
import { useTheme } from '../Theme'
import { FieldTextarea } from './FieldTextarea'

const generateSchemaValidator = (validationType, validations) => {
  if (!yup[validationType]) {
    return null
  }
  let validator = yup[validationType]()
  validations.forEach(validation => {
    let { params, type } = validation
    if (!validator[type]) {
      return
    }
    if (!params) {
      params = []
    }
    if (
      params.length > 0 &&
      typeof params[0] === 'object' &&
      !Array.isArray(params[0]) &&
      (params[0].type === 'ref' || params[0].type === 'when' || type === 'when') // special object keys required for this block
    ) {
      if (params[0].type === 'ref') {
        const ref = params[0]
        const _params = params.slice(1, params.length)
        _params.unshift(yup[ref.type](...ref.params))
        validator = validator[type](..._params)
      } else if (params[0].type === 'when' || type === 'when') {
        const when = params[0]

        if (!when.target && !when.is && !when.then && !when.otherwise) {
          console.error(
            `Check the object param of validation type WHEN, it should have .target, .is, .then and .otherwise`
          )
        } else {
          let isParam = when.is
          if (typeof isParam === 'object') {
            if (isParam.type === 'function') {
              const isFunc = value => {
                if (isParam.condition === 'NON_ZERO') {
                  return parseInt(value) > 0
                } else if (isParam.condition === 'NON_EMPTY') {
                  return value && value !== ''
                }

                return false
              }
              isParam = isFunc
            }
          }
          const whenParams = [
            when.target,
            {
              is: isParam,
              then: generateSchemaValidator(
                when.then.validationType,
                when.then.validations
              ),
              otherwise: generateSchemaValidator(
                when.otherwise.validationType,
                when.otherwise.validations
              ),
            },
          ]
          validator = validator.when(...whenParams)
        }
      }
    } else {
      validator = validator[type](...params)
    }
  })

  return validator
}

const createSchema = fields => {
  if (!fields) return {}
  if (fields.length < 1) return {}

  const result = fields.reduce((schema, field) => {
    const { id, validationType, validations = [] } = field
    if (!validationType) return schema

    if (Array.isArray(id) && id.length > 1) {
      id.forEach((key, idx) => {
        if (!yup[validationType[idx]]) {
          return schema
        }
        const validator = generateSchemaValidator(
          validationType[idx],
          validations[idx]
        )
        if (!validator) {
          return schema
        }
        schema[key] = validator
      })
    } else {
      const validator = generateSchemaValidator(validationType, validations)
      if (!validator) {
        return schema
      }
      schema[id] = validator
    }
    return schema
  }, {})

  return result
}

const FormTitle = ({ children, align }) => {
  const _FormTitle = classnames('cui-form_title', {
    [`cui-form_title__${align}`]: !!align,
  })
  return <h3 className={_FormTitle}>{children}</h3>
}

FormTitle.propTypes = {
  align: PropTypes.string,
  children: PropTypes.node,
}

const FormDescription = ({ children, align }) => {
  const _FormDescription = classnames('cui-form_description', {
    [`cui-form_description__${align}`]: !!align,
  })
  return <small className={_FormDescription}>{children}</small>
}

FormDescription.propTypes = {
  align: PropTypes.string,
  children: PropTypes.node,
}

const ValidChildren = [
  FieldLabel,
  FieldCTA,
  FieldInput,
  FieldInput.Text,
  FieldInput.Split,
  FieldInput.Box,
  FieldSelection,
  FieldCheckbox,
  FieldContent,
  FieldChoose,
  FieldFileUpload,
  FieldPrefix,
  FieldUnit,
  FieldDatePicker,
  FieldTimePicker,
  FormGroup,
  FieldGroup,
  Hint,
  FormSummary,
  FieldBare,
  FieldNote,
  FormReceipt,
  FormTitle,
  FormDescription,
  FormWrapper,
  Address,
  FieldSelectionArea,
  FieldDropdown,
  FieldTextarea,
]

const Form = ({
  children,
  alignment,
  contain,
  id,
  inlined,
  fields,
  ...formProps
}) => {
  const theme = useTheme()
  // const [kids, setKids] = React.useState(() => React.Children.toArray(children))
  const [validationSchema, setValidationSchema] = React.useState(
    () => formProps.validationSchema || null
  )
  const _Form = classnames('lm--form cui-form', `${alignment}-label`, {
    'is-contained': contain,
    'top-label': !inlined,
  })

  React.useEffect(() => {
    if (formProps.validationSchema) {
      setValidationSchema(formProps.validationSchema)
    }
  }, [formProps.validationSchema])

  React.useEffect(() => {
    if (fields && fields.length > 0) {
      const fieldsSchema = createSchema(fields)
      const schema = yup.object().shape(fieldsSchema)
      setValidationSchema(schema)
    }
  }, [fields])

  const renderChildren = () => {
    const _kids = React.Children.toArray(children).filter(kid => {
      return ValidChildren.some(vc => vc === kid.type)
    })

    // TODO: RENDER WITH DYNAMIC FIELDS HERE
    if (fields && fields.length > 0) {
      // const _fields = getFields(fields)
      // return _fields
      return (
        <>
          <Fields fields={fields} />
          {_kids}
        </>
      )
    }

    return _kids
  }

  return (
    <Formik {...formProps} validationSchema={validationSchema}>
      <FormikForm
        id={id}
        className={_Form}
        style={{
          '--cuiFormTextPrimary': theme.colors.text.primary,
          '--cuiFormTextSecondary': theme.colors.text.secondary,
          '--cuiFormInputBg': theme.colors.input.background,
          '--cuiFormInputDisabled': theme.colors.input.disabled,
          '--cuiFormError': theme.colors.error.main,
          '--cuiFormInputBorder': theme.colors.input.border,
          '--cuiFormTextDisabled': theme.colors.text.disabled,
          '--cuiFormPrimary': theme.colors.primary.main,
          '--cuiFormButtonBg': theme.colors.background.level3,
          '--cuiFormDivider': theme.colors.divider,
          '--cuiFormShadow': theme.colors.boxShadow,
          '--cuiFormIconColor': theme.colors.icon,
        }}
      >
        {renderChildren()}
      </FormikForm>
    </Formik>
  )
}

Form.Title = FormTitle
Form.Description = FormDescription
Form.FieldInput = FieldInput
Form.Label = FieldLabel
Form.Hint = Hint
Form.CTA = FieldCTA
Form.FieldSelection = FieldSelection
Form.FieldSelectionArea = FieldSelectionArea
Form.Checkbox = FieldCheckbox
Form.FieldContent = FieldContent
Form.FieldChoose = FieldChoose
Form.FieldFile = FieldFileUpload
Form.FieldDropdown = FieldDropdown
Form.Textarea = FieldTextarea

Form.Prefix = FieldPrefix
Form.Unit = FieldUnit
Form.FieldDatePicker = FieldDatePicker
Form.FieldTimePicker = FieldTimePicker
Form.Group = FormGroup
Form.FieldGroup = FieldGroup
Form.Summary = FormSummary
Form.FieldBare = FieldBare
Form.FieldNote = FieldNote
Form.Receipt = FormReceipt
Form.Details = FormReceipt.Details
Form.Wrapper = FormWrapper

Form.createSchema = createSchema

Form.defaultProps = {
  alignment: 'left',
  contain: true,
  inlined: true,
  fields: null,
}

Form.propTypes = {
  id: PropTypes.string,
  alignment: PropTypes.string,
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  contain: PropTypes.bool,
  inlined: PropTypes.bool,
  fields: PropTypes.array,
}

export {
  FieldInput,
  FieldLabel,
  FieldSelection,
  FieldCheckbox,
  FieldContent,
  FieldFileUpload,
}

export default Form
