import React from 'react'
import PropTypes from 'prop-types'

class AnnouncementIcon extends React.PureComponent {
  static propTypes = {
    color: PropTypes.array,
  }

  render() {
    return (
      <g
        className="icon-announcement"
        stroke="none"
        strokeWidth="1"
        fill={this.props.color[0]}
        fillRule="evenodd"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M60.0408 27.6751L5.76981 27.6583C3.03357 27.6574 0.816449 25.4396 0.816895 22.7041L0.81734 22.0508L30.3407 3.07088C31.9074 2.06382 33.9179 2.06426 35.4842 3.07221L64.9968 22.0708V22.724C64.9959 25.4591 62.777 27.676 60.0408 27.6751Z"
          fill="#266396"
        />
        <mask
          id="mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="65"
          height="26"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.843262 25.8332H64.9529V0.362305H0.843262V25.8332Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.817383 45.7206H64.996V22.1362H0.817383V45.7206Z"
            fill="#266396"
          />
        </g>
        <mask
          id="mask1"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="5"
          y="0"
          width="56"
          height="47"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.84687 0.883789C6.74365 0.883789 5.84033 1.94222 5.84033 3.23536V44.4038C5.84033 45.6987 6.74365 46.7571 7.84687 46.7571H58.0291C59.1332 46.7571 60.0361 45.6987 60.0361 44.4038V3.23536C60.0361 1.94222 59.1332 0.883789 58.0291 0.883789H7.84687Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.84687 0.883789C6.74365 0.883789 5.84033 1.94222 5.84033 3.23536V44.4038C5.84033 45.6987 6.74365 46.7571 7.84687 46.7571H58.0291C59.1332 46.7571 60.0361 45.6987 60.0361 44.4038V3.23536C60.0361 1.94222 59.1332 0.883789 58.0291 0.883789H7.84687Z"
            fill="url(#paint0_linear)"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.84707 0.883789H58.0293C59.133 0.883789 60.0363 1.94222 60.0363 3.2358V44.4038C60.0363 45.6983 59.133 46.7572 58.0293 46.7572H7.84707C6.74341 46.7572 5.84009 45.6983 5.84009 44.4038V3.2358C5.84009 1.94222 6.74341 0.883789 7.84707 0.883789Z"
          stroke="white"
          strokeWidth="0.888904"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.80664 4.69932V2.70691C5.80664 1.61172 6.70907 0.715821 7.81185 0.715821H12.8266L9.61715 4.69932H5.80664ZM27.668 4.69932L30.8771 0.71582H37.8962L34.6871 4.69932H27.668ZM53.741 4.69932L56.9496 0.71582H57.9522C59.0554 0.71582 59.9583 1.61172 59.9583 2.70691V4.69932H53.741Z"
          fill="#3176DE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.6074 4.83403L17.8245 0.883301H24.8627L21.6452 4.83403H14.6074ZM40.7481 4.83403L43.9652 0.883301H51.0034L47.7855 4.83403H40.7481Z"
          fill="#F65B7D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.3726 17.4618H50.7475C51.3075 17.4618 51.7661 17.0034 51.7661 16.4423C51.7661 15.8821 51.3075 15.4229 50.7475 15.4229H30.3726C29.8126 15.4229 29.354 15.8821 29.354 16.4423C29.354 17.0034 29.8126 17.4618 30.3726 17.4618Z"
          fill="#00B0B2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.6534 23.5791H30.3726C29.8126 23.5791 29.354 24.0388 29.354 24.599C29.354 25.1597 29.8126 25.6185 30.3726 25.6185H45.6534C46.2139 25.6185 46.672 25.1597 46.672 24.599C46.672 24.0388 46.2139 23.5791 45.6534 23.5791Z"
          fill="#E4EBF2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M50.7475 19.501H30.3726C29.8126 19.501 29.354 19.9602 29.354 20.5204C29.354 21.0815 29.8126 21.5403 30.3726 21.5403H50.7475C51.3075 21.5403 51.7661 21.0815 51.7661 20.5204C51.7661 19.9602 51.3075 19.501 50.7475 19.501Z"
          fill="#E4EBF2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.6919 20.5204C23.6919 23.5049 21.2597 25.9246 18.2595 25.9246C15.2593 25.9246 12.8267 23.5049 12.8267 20.5204C12.8267 17.536 15.2593 15.1167 18.2595 15.1167C21.2597 15.1167 23.6919 17.536 23.6919 20.5204Z"
          fill="#E4EBF2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.83085 61.4074C2.17914 61.4079 0.827048 60.0642 0.826603 58.4208L0.815918 24.5639L30.3388 43.4496C31.9051 44.4517 33.916 44.4513 35.4818 43.4478L64.9936 24.5444L65.0043 58.4013C65.0043 60.0443 63.6513 61.3893 61.9965 61.3897L3.83085 61.4074Z"
          fill="url(#paint1_linear)"
        />
        <mask
          id="mask2"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="3"
          y="24"
          width="63"
          height="38"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M65.0041 58.4013C65.0046 60.0443 63.6521 61.3893 61.9972 61.3897L3.1709 61.4079L64.9939 24.5444L65.0041 58.4013Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask2)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M65.0041 58.4013C65.0046 60.0443 63.6521 61.3893 61.9972 61.3897L3.1709 61.4079L64.9939 24.5444L65.0041 58.4013Z"
            fill="#3176DE"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="33.2976"
            y1="11.805"
            x2="33.2976"
            y2="33.8591"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="white" />
            <stop offset="1" stopColor="#F1F5F9" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="-24.0379"
            y1="32.7422"
            x2="-12.7447"
            y2="66.9806"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#65A1DB" />
            <stop offset="1" stopColor="#3176DE" />
          </linearGradient>
        </defs>
      </g>
    )
  }
}

export default AnnouncementIcon
