import React from 'react'
import PropTypes from 'prop-types'

class CloseIcon extends React.PureComponent {
  static propTypes = {
    color: PropTypes.array,
  }

  render() {
    return (
      <g
        className="icon-close"
        stroke="none"
        strokeWidth="1"
        fill={this.props.color[0]}
        fillRule="evenodd"
      >
        <path d="M0.292893 0.292893C0.683418 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L5 3.585L8.29289 0.292893C8.68342 -0.0976311 9.31658 -0.0976311 9.70711 0.292893C10.0976 0.683418 10.0976 1.31658 9.70711 1.70711L6.415 5L9.70711 8.29289C10.0676 8.65338 10.0953 9.22061 9.7903 9.6129L9.70711 9.70711C9.31658 10.0976 8.68342 10.0976 8.29289 9.70711L5 6.415L1.70711 9.70711C1.31658 10.0976 0.683418 10.0976 0.292893 9.70711C-0.0976311 9.31658 -0.0976311 8.68342 0.292893 8.29289L3.585 5L0.292893 1.70711C-0.0675907 1.34662 -0.0953202 0.779392 0.209705 0.387101L0.292893 0.292893Z" />
      </g>
    )
  }
}

export default CloseIcon
