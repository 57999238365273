import React from 'react'

function LocationIcon() {
  return (
    <g className="icon-location">
      <mask
        id="location-icon-mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="5"
        y="2"
        width="14"
        height="20"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.3013 11.8553L12 18.9501L7.69873 11.8553C5.61249 8.41407 8.03643 3.97091 12 3.97091C15.9636 3.97091 18.3875 8.41407 16.3013 11.8553ZM6.05757 12.8927C3.17532 8.13846 6.52412 2 12 2C17.4759 2 20.8247 8.13846 17.9424 12.8927L12.6427 21.6344C12.3472 22.1219 11.6528 22.1219 11.3573 21.6344L6.05757 12.8927ZM12.0002 6.92727C10.9342 6.92727 10.07 7.80967 10.07 8.89818C10.07 9.98668 10.9342 10.8691 12.0002 10.8691C13.0662 10.8691 13.9304 9.98668 13.9304 8.89818C13.9304 7.80967 13.0662 6.92727 12.0002 6.92727Z"
        />
      </mask>
      <g mask="url(#location-icon-mask0)">
        <rect width="24" height="24" />
      </g>
    </g>
  )
}

export default LocationIcon
