import React from 'react'
import PropTypes from 'prop-types'

class WaterIcon extends React.PureComponent {
  static propTypes = {
    color: PropTypes.array,
  }

  render() {
    return (
      <g
        className="icon-reschedule-appointment"
        stroke="none"
        strokeWidth="1"
        fill={this.props.color[0]}
        fillRule="evenodd"
      >
        <circle cx="16" cy="16" r="16" fill="#3384E1" fillOpacity="0.1" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16 24.4938C12.6664 24.4938 10 21.5904 10 18.0494C10 15.722 11.7686 12.6957 15.2571 8.8244L16 8L16.7429 8.8244C20.2314 12.6957 22 15.722 22 18.0494C22 21.5904 19.3336 24.4938 16 24.4938ZM16 22.4938C18.1892 22.4938 20 20.5221 20 18.0494C20 16.5043 18.6792 14.1187 16 11.0062C13.3208 14.1187 12 16.5043 12 18.0494C12 20.5221 13.8108 22.4938 16 22.4938ZM13 18.4938C13 20.1501 14.3437 21.4938 16 21.4938C16.5523 21.4938 17 21.0461 17 20.4938C17 19.9415 16.5523 19.4938 16 19.4938C15.4483 19.4938 15 19.0455 15 18.4938C15 17.9415 14.5523 17.4938 14 17.4938C13.4477 17.4938 13 17.9415 13 18.4938Z"
          fill="#3384E1"
        />
      </g>
    )
  }
}

export default WaterIcon
