import React from 'react'
import PropTypes from 'prop-types'

class FileIcon extends React.PureComponent {
  render() {
    return (
      <g
        className="icon-plans"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g transform="translate(-368.000000, -638.000000)">
          <g transform="translate(120.000000, 271.000000)">
            <g transform="translate(0.000000, 129.000000)">
              <g transform="translate(234.000000, 226.000000)">
                <rect fillRule="nonzero" x="0" y="0" width="64" height="64" />
                <g transform="translate(16.000000, 14.000000)">
                  <path
                    d="M9.25,19.5 C7.86928813,19.5 6.75,18.3807119 6.75,17 C6.75,15.6192881 7.86928813,14.5 9.25,14.5 L22.75,14.5 C24.1307119,14.5 25.25,15.6192881 25.25,17 C25.25,18.3807119 24.1307119,19.5 22.75,19.5 L9.25,19.5 Z"
                    fill={this.props.color[0]}
                    fillRule="nonzero"
                  />
                  <path
                    d="M9.25,28.5 C7.86928813,28.5 6.75,27.3807119 6.75,26 C6.75,24.6192881 7.86928813,23.5 9.25,23.5 L22.75,23.5 C24.1307119,23.5 25.25,24.6192881 25.25,26 C25.25,27.3807119 24.1307119,28.5 22.75,28.5 L9.25,28.5 Z"
                    fill={this.props.color[0]}
                    fillRule="nonzero"
                  />
                  <path
                    d="M2.5,0.000122784708 L21.8180285,0.000122784708 C22.4147603,0.000122784708 22.9870519,0.237171462 23.4090079,0.659121674 L31.0909794,8.3409884 C31.5129429,8.76294613 31.75,9.33524709 31.75,9.93198951 L31.75,33.7501228 C31.75,34.9927635 30.7426407,36.0001228 29.5,36.0001228 L2.5,36.0001228 C1.25735931,36.0001228 0.25,34.9927635 0.25,33.7501228 L0.25,2.25012278 C0.25,1.0074821 1.25735931,0.000122784708 2.5,0.000122784708 Z"
                    stroke={this.props.color[0]}
                    strokeWidth="4"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    )
  }
}

FileIcon.propTypes = {
  color: PropTypes.array,
}

export default FileIcon
