import React from 'react'
import PropTypes from 'prop-types'
import Toast from '../Toast'
import Portal from '../Portal'
import './styles.scss'
import { useTransition, animated } from '@react-spring/web'

const AlertsContext = React.createContext()

const Alerts = ({ autoHideDuration, addAlert }) => {
  const refMap = React.useMemo(() => new WeakMap(), [])
  const cancelMap = React.useMemo(() => new WeakMap(), [])
  const [items, setItems] = React.useState([])

  const transitions = useTransition(items, {
    from: {
      opacity: 0,
      transform: 'translateX(100%)',
      life: '100%',
      height: 0,
    },
    keys: item => item.key,
    enter: item => async (next, cancel) => {
      cancelMap.set(item, cancel)
      await next({
        opacity: 1,
        transform: 'translateX(0)',
        height: refMap.get(item).offsetHeight,
      })
      await next({ life: '0%' })
    },
    leave: [
      {
        opacity: 0,
      },
      { height: 0 },
    ],
    onRest: (_, __, item) => {
      setItems(state =>
        state.filter(i => {
          return i.key !== item.key
        })
      )
    },
    config: (_, __, phase) => key => {
      return phase === 'enter' && key === 'life'
        ? { duration: autoHideDuration }
        : phase === 'enter'
        ? { tension: 200, friction: 18, precision: 0.01 }
        : {
            tension: 125,
            friction: 20,
            precision: 0.1,
          }
    },
  })

  React.useEffect(() => {
    addAlert(item => {
      setItems(state => [...state, { key: new Date().getTime(), ...item }])
    })
  }, [])

  return (
    <Portal>
      <div className="cui-alerts">
        {transitions(({ life, ...style }, item) => {
          return (
            <animated.div style={style} className="cui-alerts__item">
              <div
                className="cui-alerts__item-wrapper"
                ref={ref => ref && refMap.set(item, ref)}
              >
                <Toast
                  show
                  isSuccess={item.type === 'success'}
                  isError={item.type === 'error'}
                  handleClose={() => {
                    if (cancelMap.has(item)) cancelMap.get(item)()
                  }}
                >
                  <Toast.Title>{item.title}</Toast.Title>
                  <Toast.Message>{item.message}</Toast.Message>
                </Toast>
              </div>
            </animated.div>
          )
        })}
      </div>
    </Portal>
  )
}

export const AlertsProvider = ({ children, autoHideDuration }) => {
  const ref = React.useRef()

  const handleAddAlert = alert => {
    ref.current?.(alert)
  }

  return (
    <AlertsContext.Provider
      value={{
        alert: handleAddAlert,
      }}
    >
      {children}
      <Alerts
        addAlert={add => {
          ref.current = add
        }}
        autoHideDuration={autoHideDuration}
      />
    </AlertsContext.Provider>
  )
}

AlertsProvider.propTypes = {
  children: PropTypes.node,
  autoHideDuration: PropTypes.number,
}

AlertsProvider.defaultProps = {
  autoHideDuration: 5000,
}

export const useAlerts = () => React.useContext(AlertsContext)
