import React from 'react'

class Notify extends React.PureComponent {
  render() {
    return (
      <g className="illus-notify">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.8888 10.1019C40.7958 11.0684 32.5178 16.0499 25.8993 22.3594C22.1703 25.9139 18.8463 29.9539 16.5383 34.5599C13.7783 40.0674 12.5378 46.3259 12.9888 52.4699C13.3738 57.7159 15.0793 63.0319 18.7023 66.8449C21.2028 69.4774 24.4633 71.2529 27.7618 72.7704C37.0953 77.0644 47.1578 79.6279 57.3258 81.1004C65.1153 82.229 73.1158 82.7209 80.8123 81.0724C88.5078 79.424 95.9288 75.4369 100.483 69.0174C104.202 63.7744 107.215 53.5054 108.915 44.6169C110.555 36.0499 106.542 27.3659 98.8938 23.1714C98.8458 23.1454 98.7978 23.1194 98.7498 23.0934C89.1063 17.8369 78.5358 14.4729 67.8423 11.9714C63.1163 10.8659 58.2818 9.91895 53.4483 9.91895C52.2608 9.91895 51.0743 9.97595 49.8888 10.1019Z"
          fill="#F8FCFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.9787 73.2548C52.8296 74.359 56.8448 72.1382 57.947 68.2944C59.0492 64.4506 56.8209 60.4395 52.9701 59.3353C49.1192 58.2311 45.104 60.4519 44.0018 64.2957C42.8996 68.1395 45.1279 72.1506 48.9787 73.2548Z"
          fill="url(#notify-paint0_linear)"
        />
        <path
          opacity="0.439779"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M48.8452 73.0902C45.123 72.0228 42.9692 68.1457 44.0346 64.4303C44.1148 64.1505 44.2111 63.8792 44.3221 63.618L44.3233 63.6183C44.2961 63.6825 44.2697 63.7474 44.2442 63.8129L49.4811 73.2402C52.9996 73.9071 56.5089 71.7998 57.5138 68.2956C57.594 68.0156 57.6561 67.7347 57.7004 67.4541L57.7006 67.4542C57.6561 67.7345 57.5942 68.0156 57.5139 68.2954C56.4485 72.0109 52.5674 74.1575 48.8452 73.0902Z"
          fill="#FFE75A"
        />
        <mask
          id="notify-mask9"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="43"
          y="63"
          width="15"
          height="11"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M48.8452 73.0902C45.123 72.0228 42.9692 68.1457 44.0346 64.4303C44.1148 64.1505 44.2111 63.8792 44.3221 63.618L44.3233 63.6183C44.2961 63.6825 44.2697 63.7474 44.2442 63.8129L49.4811 73.2402C52.9996 73.9071 56.5089 71.7998 57.5138 68.2956C57.594 68.0156 57.6561 67.7347 57.7004 67.4541L57.7006 67.4542C57.6561 67.7345 57.5942 68.0156 57.5139 68.2954C56.4485 72.0109 52.5674 74.1575 48.8452 73.0902Z"
            fill="white"
          />
        </mask>
        <g mask="url(#notify-mask9)" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M31.1375 53.0994C30.7238 53.101 30.3028 53.0447 29.8851 52.925C29.8851 52.925 44.8503 58.2651 52.5268 60.4663C60.3262 62.7027 76.3131 66.238 76.3131 66.238C75.8955 66.1182 75.5086 65.9429 75.1588 65.7223C73.5234 64.6914 72.6924 62.6727 73.2513 60.7236L73.6893 59.1961L76.3852 49.7942C78.9198 40.9552 74.9646 31.7932 67.3415 27.3771C68.0382 26.7156 68.5753 25.8664 68.8591 24.8769C69.7072 21.9186 67.9918 18.8306 65.028 17.9807C62.0641 17.1308 58.9731 18.8406 58.1248 21.7989C57.8411 22.7884 57.8464 23.7931 58.0867 24.7233C49.2817 24.4287 41.074 30.1033 38.5395 38.9421L35.4055 49.8715C34.8467 51.8206 33.0711 53.0919 31.1375 53.0994ZM65.6837 23.6052C65.3301 24.8382 64.0421 25.5506 62.8068 25.1964C61.5715 24.8422 60.8567 23.5554 61.2102 22.3224C61.5638 21.0894 62.8518 20.377 64.0872 20.7312C65.3225 21.0854 66.0373 22.3721 65.6837 23.6052Z"
          fill="url(#notify-paint1_linear)"
        />
        <mask
          id="notify-mask1"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="29"
          y="17"
          width="49"
          height="50"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M31.1375 53.0994C30.7238 53.101 30.3028 53.0447 29.8851 52.925C29.8851 52.925 44.8503 58.2651 52.5268 60.4663C60.3262 62.7027 76.3131 66.238 76.3131 66.238C75.8955 66.1182 75.5086 65.9429 75.1588 65.7223C73.5234 64.6914 72.6924 62.6727 73.2513 60.7236L73.6893 59.1961L76.3852 49.7942C78.9198 40.9552 74.9646 31.7932 67.3415 27.3771C68.0382 26.7156 68.5753 25.8664 68.8591 24.8769C69.7072 21.9186 67.9918 18.8306 65.028 17.9807C62.0641 17.1308 58.9731 18.8406 58.1248 21.7989C57.8411 22.7884 57.8464 23.7931 58.0867 24.7233C49.2817 24.4287 41.074 30.1033 38.5395 38.9421L35.4055 49.8715C34.8467 51.8206 33.0711 53.0919 31.1375 53.0994ZM65.6837 23.6052C65.3301 24.8382 64.0421 25.5506 62.8068 25.1964C61.5715 24.8422 60.8567 23.5554 61.2102 22.3224C61.5638 21.0894 62.8518 20.377 64.0872 20.7312C65.3225 21.0854 66.0373 22.3721 65.6837 23.6052Z"
            fill="white"
          />
        </mask>
        <g mask="url(#notify-mask1)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.3535 43.585C36.3535 43.585 46.7055 47.6214 55.4308 50.1233C64.1562 52.6253 75.496 54.8089 75.496 54.8089L73.8984 60.3805C73.8984 60.3805 63.818 58.7088 53.7933 55.8343C43.7685 52.9597 34.7558 49.1565 34.7558 49.1565L36.3535 43.585Z"
            fill="#FFC466"
          />
        </g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.1243 56.6868C25.0961 53.847 28.1398 52.2398 31.0628 53.0779L31.1374 53.0993C30.7238 53.101 30.3028 53.0447 29.8851 52.9249L76.3131 66.2379C75.8955 66.1182 75.5086 65.9428 75.1588 65.7222L75.2333 65.7436C78.25 66.6086 79.9955 69.7508 79.1322 72.7621C79.0258 73.1333 78.8849 73.4848 78.7137 73.8147C78.7137 73.8147 67.9966 72.2527 50.733 67.3025C33.4694 62.3522 23.8344 58.0783 23.8344 58.0783C23.8566 57.8013 23.8995 57.5229 23.9642 57.245L24.1243 56.6868Z"
          fill="url(#notify-paint2_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M72.8982 24.6497C72.8982 23.6938 73.6731 22.9189 74.629 22.9189H96.6674C97.6233 22.9189 98.3982 23.6938 98.3982 24.6497V38.9189V40.6882V46.9189L93.0544 42.4189H74.629C73.6731 42.4189 72.8982 41.6441 72.8982 40.6882V24.6497ZM79.3982 35.4189C80.5028 35.4189 81.3982 34.5235 81.3982 33.4189C81.3982 32.3144 80.5028 31.4189 79.3982 31.4189C78.2936 31.4189 77.3982 32.3144 77.3982 33.4189C77.3982 34.5235 78.2936 35.4189 79.3982 35.4189ZM87.8982 33.4189C87.8982 34.5235 87.0028 35.4189 85.8982 35.4189C84.7936 35.4189 83.8982 34.5235 83.8982 33.4189C83.8982 32.3144 84.7936 31.4189 85.8982 31.4189C87.0028 31.4189 87.8982 32.3144 87.8982 33.4189ZM91.8982 35.4189C93.0028 35.4189 93.8982 34.5235 93.8982 33.4189C93.8982 32.3144 93.0028 31.4189 91.8982 31.4189C90.7936 31.4189 89.8982 32.3144 89.8982 33.4189C89.8982 34.5235 90.7936 35.4189 91.8982 35.4189Z"
          fill="url(#notify-paint3_linear)"
        />
        <defs>
          <linearGradient
            id="notify-paint0_linear"
            x1="42.5425"
            y1="68.7863"
            x2="50.3053"
            y2="75.9909"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFC466" />
            <stop offset="1" stopColor="#FEBD67" />
          </linearGradient>
          <linearGradient
            id="notify-paint1_linear"
            x1="28.8338"
            y1="37.0278"
            x2="49.5838"
            y2="78.1719"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FA8A73" />
            <stop offset="1" stopColor="#F65B7D" />
          </linearGradient>
          <linearGradient
            id="notify-paint2_linear"
            x1="37.1169"
            y1="54.8139"
            x2="35.7928"
            y2="59.4315"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F87477" />
            <stop offset="1" stopColor="#F65B7D" />
          </linearGradient>
          <linearGradient
            id="notify-paint3_linear"
            x1="63.8458"
            y1="35.3783"
            x2="84.2732"
            y2="57.2307"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#52CFD1" />
            <stop offset="1" stopColor="#00B0B2" />
          </linearGradient>
        </defs>
      </g>
    )
  }
}

export default Notify
