import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import NavigatorPrev from './NavigatorPrev'
import NavigatorCurrent from './NavigatorCurrent'
import NavigatorNext from './NavigatorNext'
import { useTheme } from '../Theme'

export const NavigatorContext = createContext()

const Navigator = ({ children, items, value, onChange, className }) => {
  const theme = useTheme()
  const itemsObj = React.useMemo(
    () => items.reduce((obj, item) => ({ ...obj, [item.value]: item }), {}),
    [items]
  )
  const subItemsObj = React.useMemo(() => {
    const subItems = items.map(item => item.subItems).flat()
    return subItems.reduce((obj, item) => ({ ...obj, [item.value]: item }), {})
  }, [items])

  const [selected, setSelected] = React.useState({
    selectedItem: null,
    selectedSubItem: null,
  })

  const [selectedSubItems, setSelectedSubItems] = React.useState({})

  React.useEffect(() => {
    const newSelected = value || {
      selectedItem: items[0].value,
      selectedSubItem: items[0].subItems[0].value,
    }
    setSelected(newSelected)
    const { selectedItem, selectedSubItem } = newSelected
    setSelectedSubItems({
      [selectedItem]: {
        index: itemsObj[selectedItem].subItems.findIndex(
          subItem => subItem.value === selectedSubItem
        ),
        value: selectedSubItem,
      },
    })
  }, [value, items, itemsObj])

  const handleChangeItem = selectedItem => {
    const subItems = itemsObj[selectedItem].subItems
    const selectedSubItem = selectedSubItems[selectedItem] || {
      value: subItems[0].value,
      index: 0,
    }
    const newValue = {
      selectedItem,
      selectedSubItem: selectedSubItem.value,
    }
    setSelected(newValue)
    onChange(newValue)
    setSelectedSubItems({
      ...selectedSubItems,
      [selectedItem]: selectedSubItem,
    })
  }

  const handleChangeSubItem = selectedSubItemIndex => {
    const { selectedItem } = selected
    const selectedSubItemValue =
      itemsObj[selectedItem].subItems[selectedSubItemIndex].value
    const newValue = {
      selectedItem,
      selectedSubItem: selectedSubItemValue,
    }
    setSelected(newValue)
    onChange(newValue)
    setSelectedSubItems({
      ...selectedSubItems,
      [selectedItem]: {
        value: selectedSubItemValue,
        index: selectedSubItemIndex,
      },
    })
  }

  return (
    <NavigatorContext.Provider
      value={{
        selected,
        items,
        itemsObj,
        subItemsObj,
        selectedSubItems,
        handleChangeItem,
        handleChangeSubItem,
      }}
    >
      <div
        className={classnames('navigator', className)}
        style={{
          '--cuiNavigatorBg': theme.colors.input.background,
          '--cuiNavigatorTextSecondary': theme.colors.text.secondary,
          '--cuiNavigatorBorder': theme.colors.divider,
          '--cuiNavigatorHoverBg': theme.colors.grey[100],
        }}
      >
        {children}
      </div>
    </NavigatorContext.Provider>
  )
}

Navigator.propTypes = {
  children: PropTypes.node,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.node,
      subItems: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.any,
          label: PropTypes.node,
        })
      ),
    })
  ).isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func,
  className: PropTypes.string,
}

Navigator.Prev = NavigatorPrev
Navigator.Next = NavigatorNext
Navigator.Current = NavigatorCurrent

export default Navigator
