import React, { useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'
import Icons from '../../Icons'

import FieldContent from '../FieldContent'
import FieldLabel from '../FieldLabel'
import FieldError from '../FieldError'
import DatePicker from './DatePicker'

const FieldDatePicker = ({
  spec,
  children,
  isRange,
  disablePrev,
  disableNext,
  availableDays,
  offset,
  showLegend,
  isInput,
  disabled,
  disableBefore,
  disableAfter,
  timezone,
  onChange,
  name,
  selectedMonth,
  isLoading,
  placeholder,
  resolve,
  onFirstDayClick,
}) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    setFieldValue,
    handleBlur,
    setFieldTouched,
  } = useFormikContext() || {}

  const isError = touched[name] && errors[name]

  const _onSelectHandler = (name, value) => {
    if (setFieldTouched) {
      if (isRange) {
        if (value && value.from && value.to) {
          setFieldTouched(name, true)
        }
      } else {
        setFieldTouched(name, true)
      }
    }
    if (setFieldValue) {
      setFieldValue(name, value === null ? '' : value)
    }
  }

  const _blurHandler = e => {
    if (handleBlur) {
      handleBlur(e)
    }
  }

  const renderField = () => {
    const _FieldDatePicker = classnames(
      'lm--input cui-field cui-field_datepicker'
    )
    const label = React.Children.map(children, (child, childIdx) => {
      if (child.type === FieldLabel) {
        return <FieldLabel {...child.props} />
      }
      return null
    })

    const fieldContent = React.Children.map(children, (child, childIdx) => {
      if (child.type === FieldContent) {
        return <FieldContent {...child.props} />
      }
      return null
    })

    return (
      <div className={_FieldDatePicker}>
        {label}
        <div
          className={classnames('cui-field_control', {
            'full-width': React.Children.count(children) === 0,
            'is-error': isError,
          })}
        >
          <DatePicker
            id={spec.id}
            name={name}
            onSelectHandler={_onSelectHandler}
            isRange={isRange}
            disablePrev={disablePrev}
            disableNext={disableNext}
            disableBefore={disableBefore}
            disableAfter={disableAfter}
            availableDays={availableDays}
            offset={offset}
            value={values[name]}
            showLegend={showLegend}
            onBlurHandler={_blurHandler}
            onChange={onChange}
            isInput={isInput}
            disabled={disabled}
            timezone={timezone}
            selectedMonth={selectedMonth}
            placeholder={placeholder}
            resolve={resolve}
            onFirstDayClick={onFirstDayClick}
          />
          {fieldContent}
          {isLoading && (
            <div className="cui-field_datepicker__loader">
              <Icons.Spinloader width={40} height={40} color={['#333333']} />
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="lm--input-group cui-field_group" id={spec.id}>
      {renderField()}
      {isError && <FieldError>{errors[name]}</FieldError>}
    </div>
  )
}

FieldDatePicker.defaultProps = {
  placeholder: 'Date',
}

FieldDatePicker.propTypes = {
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  spec: {
    id: PropTypes.string,
  },
  errors: PropTypes.object,
  isRange: PropTypes.bool,
  disablePrev: PropTypes.bool,
  disableNext: PropTypes.bool,
  availableDays: PropTypes.array,
  offset: PropTypes.object,
  showLegend: PropTypes.bool,
  isInput: PropTypes.bool,
  disabled: PropTypes.bool,
  disableBefore: PropTypes.bool,
  disableAfter: PropTypes.bool,
  timezone: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  resolve: PropTypes.func,
  onFirstDayClick: PropTypes.func,
  placeholder: PropTypes.node,
}

export default FieldDatePicker
