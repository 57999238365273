import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import SliderItem from './SliderItem'
import SlickSlider from 'react-slick'
import Icons from '../Icons'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function NextArrow(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <Icons.Caret.Right width={16} color={['#818A91']} />
    </div>
  )
}

function PrevArrow(props) {
  const { className, onClick } = props
  return (
    <div className={className} onClick={onClick}>
      <Icons.Caret.Left width={16} color={['#818A91']} />
    </div>
  )
}

const Slider = ({ options, children, className, style }) => {
  const renderChildren = () => {
    return React.Children.map(children, child => {
      if (child.type === SliderItem) {
        return <div>{child}</div>
      }
      return null
    })
  }

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: classnames('cui-slider', className),
    style,
    dotsClass: 'cui-slider__dots',
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    ...options,
  }

  return <SlickSlider {...settings}>{renderChildren()}</SlickSlider>
}

Slider.propTypes = {
  children: PropTypes.node,
  options: PropTypes.object,
  className: PropTypes.string,
  style: PropTypes.object,
}

Slider.defaultProps = {
  options: {},
}

Slider.Item = SliderItem

export default Slider
