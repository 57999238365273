import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import CardContent from './CardContent'
import CardTitle from './CardTitle'
import CardSegment from './CardSegment'
import CardContainer from './CardContainer'
import { useTheme } from '../Theme'

import './styles.scss'

const Card = ({
  children,
  segmentType,
  className,
  id,
  minHeight,
  hasShadow,
  onClick,
}) => {
  const theme = useTheme()
  const _Card = classnames('cui-card__wrapper', className, {
    [`cui-card__wrapper--${segmentType}`]: segmentType,
    'cui-card--shadow': hasShadow,
  })
  return (
    <div
      className={_Card}
      id={id}
      style={{
        minHeight: `${minHeight}`,
        '--cuiCardBg': theme.colors.card.background,
        '--cuiCardDivider': theme.colors.card.divider,
        '--cuiCardTextSecondary': theme.colors.text.secondary,
        '--cuiCardShadow': theme.colors.card.boxShadow,
      }}
      onClick={onClick}
    >
      {React.Children.map(children, child => React.cloneElement(child))}
    </div>
  )
}

Card.Content = CardContent
Card.Title = CardTitle
Card.Segment = CardSegment
Card.Container = CardContainer

Card.propTypes = {
  // Card children
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  segmentType: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  minHeight: PropTypes.string,
  onClick: PropTypes.func,
}

Card.defaultProps = {
  segmentType: 'row',
  minHeight: '20px',
  hasShadow: true,
  onClick: () => {},
}

export default Card
