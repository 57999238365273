import React from 'react'
import PropTypes from 'prop-types'

import HeroImg from './HeroImg'
import HeroText from './HeroText'

import './styles.scss'

/**
 * Hero component
 */
const Hero = ({ children }) => {
  return (
    <div className="cui-hero">
      {React.Children.map(children, child => React.cloneElement(child))}
    </div>
  )
}

Hero.Img = HeroImg
Hero.Text = HeroText

Hero.propTypes = {
  // Hero children
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
}

export default Hero
