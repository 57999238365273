import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Icons from '../Icons'
import Modal from '../Modal'
import { withTheme } from '../Theme'

class Tooltip extends React.PureComponent {
  state = {
    show: false,
  }

  render() {
    const {
      label,
      withIcon,
      arrow,
      children,
      useMinHeight,
      isHover,
      theme,
      alignX,
      alignY,
      offset,
      ...rest
    } = this.props
    const { show } = this.state

    const _Tooltip = classnames(
      'cui-tooltip__pop',
      `${rest.id ? `${rest.id}-pop` : ''}`
    )
    return (
      <>
        <div className="cui-tooltip" {...rest}>
          <div
            className={classnames('tooltip-target', { clickable: !isHover })}
            ref={el => {
              this._target = el
            }}
            {...(isHover
              ? {
                  onMouseEnter: this._tooltipOpenHandler,
                  onMouseLeave: this._tooltipCloseHandler,
                }
              : {
                  onClick: this._tooltipOpenHandler,
                })}
          >
            {withIcon && (
              <Icons.Info width={14} height={14} color={[theme.colors.icon]} />
            )}
            <span>{label}</span>
          </div>
        </div>
        {show && (
          <Modal
            visible
            target={this._target}
            className={_Tooltip}
            handleClose={this._tooltipCloseHandler}
            onEscapeKeyDown={this._tooltipOnEscKeyDownHandler}
            withBackdrop={false}
            arrow={arrow}
            useMinHeight={useMinHeight}
            hideBackdrop={isHover}
            style={{
              '--cuiTooltipBg': theme.colors.tooltip.background,
              '--cuiTooltipText': theme.colors.tooltip.text,
              '--cuiTooltipBorderRadius': theme.colors.tooltip.borderRadius,
            }}
            alignX={alignX}
            alignY={alignY}
            offset={offset}
          >
            {children}
          </Modal>
        )}
      </>
    )
  }

  _tooltipCloseHandler = () => {
    const { onPopClose } = this.props
    this.setState(
      prevState => ({
        ...prevState,
        show: false,
      }),
      () => {
        if (onPopClose) {
          onPopClose()
        }
      }
    )
  }

  _tooltipOpenHandler = () => {
    const { onPopOpen } = this.props
    this.setState(
      prevState => ({
        ...prevState,
        show: true,
      }),
      () => {
        if (onPopOpen) {
          onPopOpen()
        }
      }
    )
  }

  _tooltipOnEscKeyDownHandler = () => {
    this._tooltipCloseHandler()
  }
}

Tooltip.propTypes = {
  label: PropTypes.string,
  withIcon: PropTypes.bool,
  useMinHeight: PropTypes.bool,
  isHover: PropTypes.bool,
}

Tooltip.defaultProps = {
  withIcon: true,
  useMinHeight: false,
}

export default withTheme(Tooltip)
