import React from 'react'
import PropTypes from 'prop-types';

class MeterReadingsIcon extends React.PureComponent {
  static propTypes = {
    color: PropTypes.array,
  }

  render() {
    return (
      <g
        className="icon-meter-readings"
        stroke="none"
        strokeWidth="1"
        fill={this.props.color[0]}
        fillRule="evenodd"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.5215 35.313H13.3696V40.7987C13.3696 41.1282 14.0578 41.713 14.4456 41.713H33.8127C34.2004 41.713 34.8886 41.1282 34.8886 40.7987V35.313H32.7367V39.8844H15.5215V35.313Z"
          fill="#818A91"
        />
        <rect
          x="10.1326"
          y="9.77956"
          width="27.9932"
          height="24.5333"
          rx="2.93333"
          stroke="#818A91"
          strokeWidth="2.13333"
        />
        <rect
          x="13.903"
          y="13.5128"
          width="20.4523"
          height="17.0667"
          rx="1.0301"
          stroke="#818A91"
          strokeWidth="1.06667"
        />
        <rect
          x="17.0996"
          y="20.7129"
          width="14.0591"
          height="4"
          rx="2"
          fill="url(#paint0_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.7514 20.7129H29.1588C30.2633 20.7129 31.1588 21.6083 31.1588 22.7129C31.1588 23.8175 30.2636 24.7129 29.159 24.7129C26.9104 24.7129 23.3876 24.7129 21.9663 24.7129C27.9144 22.7129 25.7514 20.7129 25.7514 20.7129Z"
          fill="url(#paint1_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M29.5364 20.7129C30.4323 20.7129 31.1586 21.4392 31.1586 22.3351V22.7129C31.1586 23.8175 30.2633 24.7129 29.1587 24.7129C28.2794 24.7129 27.2344 24.7129 26.292 24.7129C30.0771 23.7129 29.5364 20.7129 29.5364 20.7129Z"
          fill="url(#paint2_linear)"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="14.5256"
            y1="22.4467"
            x2="16.5872"
            y2="26.8744"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F5B987" />
            <stop offset="1" stopColor="#FF718F" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="25.7371"
            y1="23.201"
            x2="27.5531"
            y2="24.2078"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#D35F9B" />
            <stop offset="1" stopColor="#3C6DBF" />
          </linearGradient>
          <linearGradient
            id="paint2_linear"
            x1="28.7891"
            y1="23.5407"
            x2="30.7019"
            y2="24.3613"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#40B8FF" />
            <stop offset="1" stopColor="#89FCFF" />
          </linearGradient>
        </defs>
      </g>
    )
  }
}

export default MeterReadingsIcon
