// organize-imports-ignore
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

import SearchIcon from './SearchIcon'
import CloseIcon from './CloseIcon'
import BillsIcon from './BillsIcon'
import MeterReadingsIcon from './MeterReadingsIcon'
import CloseUtilitiesIcon from './CloseUtilitiesIcon'
import OpenUtilitiesIcon from './OpenUtilitiesIcon'
import WholesaleElectricityIcon from './WholesaleElectricityIcon'
import AlertIcon from './AlertIcon'
import AnnouncementIcon from './AnnouncementIcon'
import ElectricityIcon from './ElectricityIcon'
import GasIcon from './GasIcon'
import PaperBillIcon from './PaperBillIcon'
import QuestionAnswerIcon from './QuestionAnswerIcon'
import RescheduleAppointmentIcon from './RescheduleAppointmentIcon'
import WaterIcon from './WaterIcon'
import MenuIcon from './MenuIcon'
import CaretIcon from './Caret'
import CalendarIcon from './CalendarIcon'
import CheckedIcon from './CheckedIcon'
import SpinLoaderIcon from './SpinLoader'
import FileIcon from './FileIcon'
import FilterIcon from './FilterIcon'
import SortIcon from './SortIcon'
import ClockIcon from './ClockIcon'
import ChevronRightIcon from './ChevronRightIcon'
import ChevronLeftIcon from './ChevronLeftIcon'
import CumulativeIcon from './Cumulative'
import AdvancedMeterIcon from './AdvancedMeterIcon'
import CloseFilledIcon from './CloseFilledIcon'
import ShieldIcon from './ShieldIcon'
import IndividualIcon from './IndividualIcon'
import CompanyIcon from './CompanyIcon'
import PlusIcon from './PlusIcon'
import InfoIcon from './InfoIcon'
import DownloadIcon from './DownloadIcon'
import InsightsIcon from './InsightsIcon'
import ThermometerIcon from './ThermometerIcon'
import CubeIcon from './CubeIcon'
import CardIcon from './CardIcon'
import MediaPlayIcon from './MediaPlayIcon'
import LocationIcon from './LocationIcon'
import MediaPauseIcon from './MediaPauseIcon'
import Chat from './ChatIcon'
import PdfIcon from './PdfIcon'
import NotificationIcon from './NotificationIcon'
import KebabMenu from './KebabMenuIcon'
import NextArrowIcon from './NextArrowIcon'

const Icons = (IconComponent, CustomProps) => {
  return class extends PureComponent {
    static defaultProps = {
      stroke: 'none',
      addToDimension: true,
      className: 'cui-icon',
    }

    static propTypes = {
      width: PropTypes.number,
      height: PropTypes.number,
    }

    render() {
      const { width, height, ...rest } = this.props
      const { viewBox: cViewBox, ...customRest } = CustomProps
      let viewBox = `0 0 ${width} ${height}`

      if (rest.viewBox && rest.viewBox !== '') {
        viewBox = rest.viewBox
      } else if (cViewBox && cViewBox !== '') {
        viewBox = cViewBox
      }

      if (!rest.color) {
        rest.color = CustomProps.color
      }
      return (
        <svg
          id={rest.id}
          className={rest.className}
          width={rest.addToDimension ? width : null}
          height={rest.addToDimension ? height : null}
          viewBox={viewBox}
          preserveAspectRatio="xMidYMid meet"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          style={{
            fill: rest.color ? rest.color[0] : null,
          }}
        >
          <IconComponent {...customRest} {...rest} />
        </svg>
      )
    }
  }
}

Icons.Search = Icons(SearchIcon, {
  color: ['#818A91'],
  viewBox: '0 0 16 16',
})

Icons.Close = Icons(CloseIcon, {
  color: ['#818A91'],
  viewBox: '0 0 10 10',
})

Icons.Bills = Icons(BillsIcon, {
  color: ['#818A91'],
})

Icons.MeterReadings = Icons(MeterReadingsIcon, {
  color: ['#FFFFFF'],
})

Icons.CloseUtilities = Icons(CloseUtilitiesIcon, {
  color: ['#FFFFFF'],
})

Icons.OpenUtilities = Icons(OpenUtilitiesIcon, {
  color: ['#FFFFFF'],
})

Icons.WholesaleElectricity = Icons(WholesaleElectricityIcon, {
  color: ['#FFFFFF'],
})

Icons.Alert = Icons(AlertIcon, {
  viewBox: '0 0 96 72',
  color: ['#FFFFFF'],
})

Icons.Announcement = Icons(AnnouncementIcon, {
  color: ['#FFFFFF'],
})

Icons.Electricity = Icons(ElectricityIcon, {
  viewBox: '0 0 32 32',
  color: ['#FFFFFF'],
})

Icons.Gas = Icons(GasIcon, {
  viewBox: '0 0 32 32',
  color: ['#FFFFFF'],
})

Icons.PaperBill = Icons(PaperBillIcon, {
  viewBox: '0 0 92 72',
  color: ['#FFFFFF'],
})

Icons.QuestionAnswer = Icons(QuestionAnswerIcon, {
  color: ['#FFFFFF'],
})

Icons.RescheduleAppointment = Icons(RescheduleAppointmentIcon, {
  color: ['#FFFFFF'],
})

Icons.Water = Icons(WaterIcon, {
  viewBox: '0 0 32 32',
  color: ['#FFFFFF'],
})

Icons.Menu = Icons(MenuIcon, {
  viewBox: '0 0 16 12',
  color: ['#818A91'],
})

Icons.Calendar = Icons(CalendarIcon, {
  viewBox: '0 0 16 18',
  color: ['#818A91'],
})

Icons.Checked = Icons(CheckedIcon, {
  viewBox: '0 0 20 20',
})

Icons.Spinloader = Icons(SpinLoaderIcon, {
  viewBox: '0 0 100 100',
  color: ['#FFFFFF'],
})

Icons.File = Icons(FileIcon, {
  viewBox: '0 0 36 40',
  color: ['#FFFFFF'],
})

Icons.Caret = {}

Icons.Caret.Down = Icons(props => <CaretIcon type="down" {...props} />, {
  viewBox: '0 0 14 8',
  color: ['#818A91'],
})

Icons.Caret.Left = Icons(props => <CaretIcon type="left" {...props} />, {
  viewBox: '0 0 24 24',
  color: ['#818A91'],
})

Icons.Caret.Right = Icons(props => <CaretIcon type="right" {...props} />, {
  viewBox: '0 0 24 24',
  color: ['#818A91'],
})

Icons.Filter = Icons(FilterIcon, {
  viewBox: '0 0 12 10',
  color: ['#818A91'],
})

Icons.Clock = Icons(ClockIcon, {
  viewBox: '0 0 14 14',
  color: ['#FFFFFF'],
})

Icons.ChevronRight = Icons(ChevronRightIcon, {
  viewBox: '0 0 16 16',
  fill: 'none',
})

Icons.ChevronLeft = Icons(ChevronLeftIcon, {
  viewBox: '0 0 16 16',
  fill: 'none',
})

Icons.Sort = Icons(SortIcon, {
  viewBox: '0 0 12 10',
  color: ['#818A91'],
})

Icons.SortList = Icons(SortIcon, {
  viewBox: '0 0 16 16',
  color: ['#BEC8CE'],
  forMobile: false,
})

Icons.Cumulative = Icons(CumulativeIcon, {
  viewBox: '0 0 17 17',
  color: ['#00B0B2'],
})

Icons.AdvancedMeter = Icons(AdvancedMeterIcon, {
  viewBox: '0 0 16 18',
  color: ['#00B0B2'],
})

Icons.CloseFilled = Icons(CloseFilledIcon, {
  viewBox: '0 0 24 24',
  color: ['#e44444'],
})

Icons.Shield = Icons(ShieldIcon, {
  viewBox: '0 0 16 16',
  color: ['#818A91'],
})

Icons.Individual = Icons(IndividualIcon, {
  viewBox: '0 0 32 36',
  color: ['#4f5a60', '#FFFFFF'],
})

Icons.Company = Icons(CompanyIcon, {
  viewBox: '0 0 65 64',
  color: ['#4f5a60'],
})

Icons.Plus = Icons(PlusIcon, {
  viewBox: '0 0 16 16',
  color: ['#818A91'],
})

Icons.Info = Icons(InfoIcon, {
  viewBox: '0 0 14 14',
  color: ['#818A91'],
})

Icons.Download = Icons(DownloadIcon, {
  viewBox: '0 0 32 32',
  color: ['#818A91'],
})

Icons.Insights = Icons(InsightsIcon, {
  viewBox: '0 0 24 24',
  color: ['#818A91'],
})

Icons.Thermometer = Icons(ThermometerIcon, {
  viewBox: '0 0 44 43',
  color: ['#FF8B00', '#FCECEC'],
})

Icons.Cube = Icons(CubeIcon, {
  viewBox: '0 0 24 24',
  color: ['#818A91'],
})

Icons.Card = Icons(CardIcon, {
  viewBox: '0 0 24 24',
  color: ['#818A91'],
})

Icons.MediaPlay = Icons(MediaPlayIcon, {
  viewBox: '0 0 16 16',
  color: ['#00B0B2'],
})

Icons.Location = Icons(LocationIcon, {
  viewBox: '0 0 24 24',
  color: ['#818A91'],
})

Icons.MediaPause = Icons(MediaPauseIcon, {
  viewBox: '0 0 24 24',
  color: ['#fff'],
})

Icons.Chat = Icons(Chat, {
  viewBox: '0 0 38 32',
  color: ['#818A91'],
})

Icons.Pdf = Icons(PdfIcon, {
  viewBox: '0 0 32 32',
  color: ['#00B0B2'],
})

Icons.Notification = Icons(NotificationIcon, {
  viewBox: '0 0 24 24',
  color: ['#818A91'],
})

Icons.KebabMenu = Icons(KebabMenu, {
  viewBox: '0 0 24 24',
  color: ['#00B0B2'],
})

Icons.NextArrow = Icons(NextArrowIcon, {
  viewBox: '0 0 16 16',
  color: ['#00B0B2'],
})

export default Icons
