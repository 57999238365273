import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Icons from '../Icons'
import Portal from '../Portal'
import ToastTitle from './ToastTitle'
import ToastMessage from './ToastMessage'
import Illustrations from '../Illustrations'
import { useTheme } from '../Theme'

const Toast = ({
  show,
  children,
  id,
  isBanner,
  isInfo,
  isSuccess,
  isError,
  handleClose,
  isFloating,
}) => {
  const theme = useTheme()
  const _Toast = classnames('lm--alert lm--toast cui-toast', {
    'lm--alert-info cui-toast_info': isInfo,
    'lm--alert-success cui-toast_success': isSuccess,
    'lm--alert-error cui-toast_error': isError,
    'cui-toast_floating': isFloating,
    'lm--alert-success cui-toast_banner ': isBanner,
  })

  const displayChildren = () => {
    return (
      <div>
        {children}
        {handleClose && (
          <div className="cui-toast_close" onClick={handleClose}>
            <Icons.Close
              width={10}
              height={10}
              color={[theme.colors.text.secondary]}
            />
          </div>
        )}
      </div>
    )
  }

  const renderToast = () => {
    return (
      <div
        className={_Toast}
        id={id}
        style={{
          '--cuiToastBg': theme.colors.background.level1,
          '--cuiToastText': theme.colors.text.primary,
        }}
      >
        <div className="cui-toast_container">
          {isSuccess && <Icons.Checked width={20} height={20} />}
          {isError && <Icons.CloseFilled width={24} height={24} />}
          {isBanner && <Illustrations.Notify width={120} />}
          {displayChildren()}
        </div>
      </div>
    )
  }

  if (!show) {
    return null
  }
  if (isFloating) {
    return <Portal>{renderToast()}</Portal>
  }
  return renderToast()
}

Toast.propTypes = {
  handleClose: PropTypes.func,
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  isInfo: PropTypes.bool,
  isSuccess: PropTypes.bool,
  isBanner: PropTypes.bool,
  isError: PropTypes.bool,
  id: PropTypes.string,
  show: PropTypes.bool,
  isFloating: PropTypes.bool,
}

Toast.defaultProps = {
  isInfo: false,
  isBanner: false,
  isSuccess: false,
  isError: false,
  show: false,
  isFloating: false,
}

Toast.Title = ToastTitle
Toast.Message = ToastMessage

export default Toast
