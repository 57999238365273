import React from 'react'
import PropTypes from 'prop-types'

const Chat = ({ bare }) => {
  return (
    <g className="icon-chat">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4211 13C18.078 13 19.4211 14.3431 19.4211 16V24.0167C19.4211 25.6736 18.078 27.0167 16.4211 27.0167H5.89494L1.82412 30.4806C1.49942 30.7569 1.00009 30.5262 1.00009 30.0998V27.0167V25.8038V16C1.00009 14.3431 2.34323 13 4.00008 13H16.4211Z"
        fill="white"
      />
      <path
        d="M5.89494 27.0167L5.24689 26.2551L5.52706 26.0167H5.89494V27.0167ZM1.82412 30.4806L1.17607 29.719L1.82412 30.4806ZM18.4211 16C18.4211 14.8954 17.5257 14 16.4211 14V12C18.6303 12 20.4211 13.7909 20.4211 16H18.4211ZM18.4211 24.0167V16H20.4211V24.0167H18.4211ZM16.4211 26.0167C17.5257 26.0167 18.4211 25.1213 18.4211 24.0167H20.4211C20.4211 26.2259 18.6303 28.0167 16.4211 28.0167V26.0167ZM5.89494 26.0167H16.4211V28.0167H5.89494V26.0167ZM1.17607 29.719L5.24689 26.2551L6.54299 27.7783L2.47217 31.2422L1.17607 29.719ZM2.00009 30.0998C2.00009 29.6735 1.50075 29.4428 1.17607 29.719L2.47217 31.2422C1.49809 32.0711 8.96454e-05 31.3788 8.96454e-05 30.0998H2.00009ZM2.00009 27.0167V30.0998H8.96454e-05V27.0167H2.00009ZM2.00009 25.8038V27.0167H8.96454e-05V25.8038H2.00009ZM2.00009 16V25.8038H8.96454e-05V16H2.00009ZM4.00008 14C2.89552 14 2.00009 14.8954 2.00009 16H8.96454e-05C8.96454e-05 13.7909 1.79095 12 4.00008 12V14ZM16.4211 14H4.00008V12H16.4211V14Z"
        fill="#818A91"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 1C16.3431 1 15 2.34315 15 4V14.5197C15 16.1766 16.3431 17.5197 18 17.5197H30.9883L35.6653 21.54C35.9895 21.8187 36.4912 21.5884 36.4912 21.1609V17.5197V15.8224V4C36.4912 2.34315 35.1481 1 33.4912 1H18Z"
        fill="white"
      />
      <path
        d="M30.9883 17.5197L31.6402 16.7614L31.359 16.5197H30.9883V17.5197ZM35.6653 21.54L35.0134 22.2984H35.0134L35.6653 21.54ZM16 4C16 2.89543 16.8954 2 18 2V0C15.7909 0 14 1.79086 14 4H16ZM16 14.5197V4H14V14.5197H16ZM18 16.5197C16.8954 16.5197 16 15.6243 16 14.5197H14C14 16.7289 15.7909 18.5197 18 18.5197V16.5197ZM30.9883 16.5197H18V18.5197H30.9883V16.5197ZM36.3172 20.7817L31.6402 16.7614L30.3364 18.2781L35.0134 22.2984L36.3172 20.7817ZM35.4912 21.1609C35.4912 20.7334 35.993 20.503 36.3172 20.7817L35.0134 22.2984C35.986 23.1344 37.4912 22.4434 37.4912 21.1609H35.4912ZM35.4912 17.5197V21.1609H37.4912V17.5197H35.4912ZM35.4912 15.8224V17.5197H37.4912V15.8224H35.4912ZM35.4912 4V15.8224H37.4912V4H35.4912ZM33.4912 2C34.5958 2 35.4912 2.89543 35.4912 4H37.4912C37.4912 1.79086 35.7004 0 33.4912 0V2ZM18 2H33.4912V0H18V2Z"
        fill="#818A91"
      />
      {/* Q and A */}
      {!bare && (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.5 21.8845C9.46085 21.8845 8.61541 21.0391 8.61541 19.9999C8.61541 18.9607 9.46085 18.1153 10.5 18.1153C11.5392 18.1153 12.3846 18.9607 12.3846 19.9999C12.3846 20.2218 12.3458 20.4348 12.275 20.6327L12.148 20.5057C11.8326 20.1903 11.3212 20.1903 11.0058 20.5057C10.6904 20.8211 10.6904 21.3325 11.0058 21.6479L11.1328 21.7749C10.9349 21.8457 10.7219 21.8845 10.5 21.8845ZM13.7634 22.1212L13.4782 21.8359C13.8086 21.3017 14 20.6729 14 20C14 18.0701 12.4299 16.5 10.5 16.5C8.57009 16.5 7 18.0701 7 20C7 21.9299 8.57009 23.5 10.5 23.5C11.1729 23.5 11.8019 23.3086 12.3359 22.9782L12.6212 23.2634C12.7789 23.4211 12.9856 23.5 13.1922 23.5C13.3989 23.5 13.6057 23.4211 13.7633 23.2634C14.0789 22.9479 14.0789 22.4365 13.7634 22.1212Z"
            fill="url(#icon-chat-paint0_linear)"
          />
          <mask
            id="icon-chat-mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="7"
            y="16"
            width="7"
            height="8"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.5 21.8845C9.46085 21.8845 8.61541 21.0391 8.61541 19.9999C8.61541 18.9607 9.46085 18.1153 10.5 18.1153C11.5392 18.1153 12.3846 18.9607 12.3846 19.9999C12.3846 20.2218 12.3458 20.4348 12.275 20.6327L12.148 20.5057C11.8326 20.1903 11.3212 20.1903 11.0058 20.5057C10.6904 20.8211 10.6904 21.3325 11.0058 21.6479L11.1328 21.7749C10.9349 21.8457 10.7219 21.8845 10.5 21.8845ZM13.7634 22.1212L13.4782 21.8359C13.8086 21.3017 14 20.6729 14 20C14 18.0701 12.4299 16.5 10.5 16.5C8.57009 16.5 7 18.0701 7 20C7 21.9299 8.57009 23.5 10.5 23.5C11.1729 23.5 11.8019 23.3086 12.3359 22.9782L12.6212 23.2634C12.7789 23.4211 12.9856 23.5 13.1922 23.5C13.3989 23.5 13.6057 23.4211 13.7633 23.2634C14.0789 22.9479 14.0789 22.4365 13.7634 22.1212Z"
              fill="white"
            />
          </mask>
          <g mask="url(#icon-chat-mask0)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.375 15.5C12.375 15.5 11.75 22.5 8.625 23.5C5.5 24.5 18 24.5 18 24.5L12.375 15.5Z"
              fill="url(#icon-chat-paint1_linear)"
            />
          </g>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.0165 11.7498L25.5 7.73404L26.9836 11.7498H24.0165ZM28.9552 13.0398L26.1554 5.46104C26.053 5.18369 25.7918 5 25.5 5C25.2082 5 24.947 5.18369 24.8446 5.46104L22.0448 13.0398C21.909 13.4072 22.0924 13.8168 22.4544 13.9546C22.8163 14.0924 23.2198 13.9062 23.3555 13.5388L23.4915 13.1709H27.5086L27.6445 13.5388C27.7499 13.824 28.0166 14 28.3 14C28.3816 14 28.4647 13.9854 28.5457 13.9546C28.9075 13.8168 29.091 13.4072 28.9552 13.0398Z"
            fill="url(#icon-chat-paint2_linear)"
          />
          <mask
            id="icon-chat-mask1"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="22"
            y="5"
            width="7"
            height="9"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M24.0165 11.7498L25.5 7.73404L26.9836 11.7498H24.0165ZM28.9552 13.0398L26.1554 5.46104C26.053 5.18369 25.7918 5 25.5 5C25.2082 5 24.947 5.18369 24.8446 5.46104L22.0448 13.0398C21.909 13.4072 22.0924 13.8168 22.4544 13.9546C22.8163 14.0924 23.2198 13.9062 23.3555 13.5388L23.4915 13.1709H27.5086L27.6445 13.5388C27.7499 13.824 28.0166 14 28.3 14C28.3816 14 28.4647 13.9854 28.5457 13.9546C28.9075 13.8168 29.091 13.4072 28.9552 13.0398Z"
              fill="white"
            />
          </mask>
          <g mask="url(#icon-chat-mask1)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M27.1939 4.5C27.1939 4.5 27.5 13 22.2347 16.5714C16.9694 20.1429 30.5 17.5 30.5 17.5L27.1939 4.5Z"
              fill="url(#icon-chat-paint3_linear)"
            />
          </g>
        </>
      )}

      <defs>
        <linearGradient
          id="icon-chat-paint0_linear"
          x1="5.71839"
          y1="19.5342"
          x2="9.91322"
          y2="22.0974"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F5B987" />
          <stop offset="1" stopColor="#FF718F" />
        </linearGradient>
        <linearGradient
          id="icon-chat-paint1_linear"
          x1="12.0793"
          y1="21.2133"
          x2="14.5884"
          y2="23.1172"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D35F9B" />
          <stop offset="1" stopColor="#3C6DBF" />
        </linearGradient>
        <linearGradient
          id="icon-chat-paint2_linear"
          x1="23.8419"
          y1="11.0767"
          x2="26.4206"
          y2="11.8304"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D35F9B" />
          <stop offset="1" stopColor="#3C6DBF" />
        </linearGradient>
        <linearGradient
          id="icon-chat-paint3_linear"
          x1="26.6536"
          y1="13.0182"
          x2="28.9439"
          y2="13.8924"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#40B8FF" />
          <stop offset="1" stopColor="#89FCFF" />
        </linearGradient>
      </defs>
    </g>
  )
}

Chat.propTypes = {
  bare: PropTypes.bool,
}

Chat.defaultProps = {
  bare: true,
}

export default Chat
