// organize-imports-ignore
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import keycode from 'keycode'
import classnames from 'classnames'
import anime from 'animejs'
import Transition from 'react-transition-group/Transition'

import Backdrop from '../Backdrop'
import Portal from '../Portal'
import Icons from '../Icons'
import { withTheme } from '../Theme'

const MODAL_ANIMATION_DONE_EVENT = 'modal:animation::done'
const BD_ANIMATION_DONE_EVENT = 'bd:animation::done'

const getWindowSize = () => {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  }
}

const triggerModalAnimationDoneEvent = node =>
  node.dispatchEvent(new Event(MODAL_ANIMATION_DONE_EVENT))

const triggerBdAnimationDoneEvent = node =>
  node.dispatchEvent(new Event(BD_ANIMATION_DONE_EVENT))

const createOpacityAnimationConfig = (animatingIn, duration = 100) => ({
  value: animatingIn ? [0, 1] : 0,
  easing: 'linear',
  duration,
})

const easing = 'spring(0.8, 350, 10)'

class Modal extends PureComponent {
  static _modals = []
  state = {
    position: {
      x: 0,
      y: 0,
      origin: '',
    },
    topOffset: 10,
    minHeightOffset: 0,
  }

  transitionKey = 1

  animateEnter = obj => {
    setTimeout(() => {
      anime({
        targets: obj,
        opacity: createOpacityAnimationConfig(true),
        scale: [0.3, 1],
        complete: () => {
          triggerModalAnimationDoneEvent(obj)
        },
        easing,
      })
    }, 50)
  }

  animateExit = obj =>
    anime({
      targets: obj,
      opacity: createOpacityAnimationConfig(false),
      scale: [1, 0],
      complete: () => {
        if (!this.props.visible) {
          this._onClose()
        }
        triggerModalAnimationDoneEvent(obj)
      },
      easing,
    })

  animateBdEnter = obj =>
    anime({
      targets: obj,
      opacity: this.props.withBackdrop ? createOpacityAnimationConfig(true) : 0,
      complete: () => triggerBdAnimationDoneEvent(obj),
      easing,
    })

  animateBdExit = obj =>
    anime({
      targets: obj,
      opacity: createOpacityAnimationConfig(false),
      complete: () => {
        triggerBdAnimationDoneEvent(obj)
      },
      easing,
    })

  modalAddEndListener = (node, done) => {
    node.addEventListener(
      MODAL_ANIMATION_DONE_EVENT,
      () => {
        done()
      },
      false
    )
  }

  bdAddEndListener = (node, done) => {
    node.addEventListener(BD_ANIMATION_DONE_EVENT, done, false)
  }

  _calculatePosition(target) {
    this._setBodyOverflow()

    const {
      offset,
      arrow,
      fullTargetWidth,
      useMinHeight,
      width,
      alignX,
      alignY,
    } = this.props
    const heightOffset = useMinHeight ? this.state.minHeightOffset : 0
    /**
     * Calculate position from the target element
     */
    const position = {
      origin: '',
    }
    /**
     * get window size - width and height, and half of it
     * this is to check whether the target is at what quadrant of the screen
     * and render the modal on that quadrant
     */
    const windowSize = getWindowSize()
    const halfWidthWindow = windowSize.width / 2
    const halfHeightWindow = windowSize.height / 2

    if (this._modalElement) {
      let label = ''

      /**
       * get modal's bounding client rect to get both size and position
       */
      const modalElementClientRect = this._modalElement.getBoundingClientRect()

      if (target && fullTargetWidth) {
        const targetRect = target.getBoundingClientRect()
        if (width && width > targetRect.width) {
          position.left = targetRect.left - (width - targetRect.width)
          position.width = width
        } else {
          position.left = targetRect.left
          position.width = targetRect.width
        }

        if (
          windowSize.height - targetRect.bottom <
          modalElementClientRect.height
        ) {
          position.top = windowSize.height - modalElementClientRect.height - 10
        } else {
          position.top = targetRect.bottom + 1
        }
      } else if (target) {
        /**
         * if target is available get its client rect
         */
        const targetRect = target.getBoundingClientRect()

        /**
         * if targetRect doesnt have x and y properies but instead has left and top,
         * set x and y with the left and top respectively
         */
        if (!targetRect.x && targetRect.left) {
          targetRect.x = targetRect.left
        }

        if (!targetRect.y && targetRect.top) {
          targetRect.y = targetRect.top
        }

        if (
          (targetRect.x + targetRect.width / 2 > halfWidthWindow &&
            alignX === 'auto') ||
          alignX === 'right'
        ) {
          /**
           * if target x position is more than the half of window's width
           * then modal's x position would be on the right and
           * get the value base on the full width of the screen and target's position x and width
           */
          if (targetRect.x + targetRect.width > modalElementClientRect.width) {
            position.right = windowSize.width - targetRect.x - targetRect.width
          } else {
            position.left = 5
          }
          if (arrow === 'relative') {
            position.right += -(targetRect.width / 2 + 10)
          }
          if (offset && offset.x) {
            position.right += offset.x
          }
          label = 'right'
        } else {
          /**
           * if target x position is less than the half of window's width
           * then modal's x position would be on the left and
           * get the value base on target's position x
           */
          if (targetRect.x + modalElementClientRect.width < windowSize.width) {
            position.left = targetRect.x
          } else {
            position.right = 5
          }
          if (arrow === 'relative') {
            position.left += -(targetRect.width / 2 + 10)
          }
          if (offset && offset.x) {
            position.left += offset.x
          }
          label = 'left'
        }

        if (
          (targetRect.y + targetRect.height / 2 >
            halfHeightWindow + heightOffset &&
            alignY === 'auto') ||
          alignY === 'top'
        ) {
          /**
           * if target y position is more than the half of window's height
           * then modal's y position would be on the bottom and
           * get the value base on the full height of the screen and target's position y and height
           * together with the topOffset
           */
          if (targetRect.y > modalElementClientRect.height) {
            position.bottom =
              windowSize.height - targetRect.y + this.state.topOffset
          } else {
            position.top = 10
          }

          if (offset && offset.y) {
            position.bottom += offset.y
          }
          label += '_bottom'
          position.origin += ' bottom'
        } else {
          /**
           * if target y position is less than the half of window's height
           * then modal's y position would be on the top and
           * get the value base on the full height of the screen and target's position y and height
           * together with the topOffset
           */
          if (
            windowSize.height - targetRect.y - targetRect.height >
            modalElementClientRect.height
          ) {
            position.top =
              targetRect.height + targetRect.y + this.state.topOffset
          } else {
            position.top =
              windowSize.height - modalElementClientRect.height - 10
          }

          if (offset && offset.y) {
            position.top += offset.y
          }
          label += '_top'
          position.origin += ' top'
        }
      } else {
        /**
         * if there's no target available,
         * get the half of the screen both width and height
         * then set the position both for left and top respectively with half of the window's sizes
         */
        position.top = halfHeightWindow - modalElementClientRect.height / 2
        position.left = halfWidthWindow - modalElementClientRect.width / 2
        position.origin = 'center center'
      }

      this.setState(prevState => ({
        ...prevState,
        position,
        label,
      }))
    }
  }

  _onClose = () => {
    this.props.onClose()
  }

  _handleClose = () => {
    this.props.handleClose()
  }

  _backdropClickHandler = () => {
    /**
     * on backdrop being clicked, invoke the onClose handler from props
     */
    if (!this.props.disableBackdropClick) {
      this._handleClose()
    }
  }

  _closeBtnClickHandler = () => {
    /**
     * on close button being clicked, invoke the onClose handler from props
     */
    this._handleClose()
  }

  _keyDownHandler = event => {
    /**
     * if the key is 'esc' call this onEscapeKeyDown() handler
     * from the props if available and disableEscapeKey is false
     */
    if (keycode(event) === 'esc') {
      if (this.props.onEscapeKeyDown && !this.props.disableEscapeKey) {
        this.props.onEscapeKeyDown(event)
      }
    }
  }

  _restoreLastFocus() {
    /**
     * Last modal in the list will be focused, by calling the _enforceFocus() method
     */
    const modal = Modal._modals[Modal._modals.length - 1]
    if (modal) {
      this._enforceFocus(modal)
    }
  }

  _enforceFocus = modal => {
    /**
     * enforce focus on modal by focus() API
     */
    if (modal) {
      modal.focus({
        preventScroll: true,
      })
    }
  }

  _setBodyOverflow() {
    /**
     * sets body overflow to hidden and
     * checks if browser is in windows
     * then force to mimic scrollbar's width
     */

    this._bodyElement = document.querySelector('body')
    this._bodyElement.classList.add('overflow-hidden')

    document.documentElement.style.overflow = 'hidden'

    if (window.navigator) {
      const _nav = window.navigator
      const { platform } = _nav
      const regex = /win/g
      const res = platform.toLowerCase().match(regex)
      if (res) {
        this._bodyElement.classList.add('overflow-offset')
      }
    }
  }

  handleResize = () => {
    const { target } = this.props
    if (target) {
      this._calculatePosition(target)
    }
  }

  componentWillUnmount() {
    /**
     * if component is unmounting, remove any event/listeners on it
     * and keep track of the modals available
     * and restore the focus to the last modal
     */
    if (this._modalElement) {
      this._modalElement.removeEventListener('keydown', this._keyDownHandler)
      Modal._modals.splice(Modal._modals.length - 1, 1)
      this._restoreLastFocus()
    }

    if (this._bodyElement) {
      this._bodyElement.classList.remove('overflow-hidden', 'overflow-offset')
    }
    document.documentElement.style.overflow = 'initial'

    window.removeEventListener('resize', this.handleResize)
  }

  componentDidMount() {
    /**
     * When mounted, check and calculate position of modal based on target node.
     * Target is usually the one who invoked this Modal
     */
    const { target } = this.props
    if (target) {
      this._calculatePosition(target)
    }

    if (this._modalElement) {
      /**
       * force modal to scroll to the top most
       */
      this._modalElement.scrollTop = 0

      /**
       * force the focus in the modal
       */
      this._enforceFocus(this._modalElement)

      /**
       * add a listener to every keydown event
       * this is to handle the esc key - close modal feature
       */
      this._modalElement.addEventListener('keydown', this._keyDownHandler)
    }

    /**
     * keep track of all the modals being mounted
     * this component should be able to render nested modals
     */
    Modal._modals.push(this._modalElement)

    window.addEventListener('resize', this.handleResize)
  }

  componentDidUpdate(prevProps) {
    this._enforceFocus(this._modalElement)
    if (prevProps.visible !== this.props.visible) {
      if (this.props.visible) {
        /**
         * When mounted, check and calculate position of modal based on target node.
         * Target is usually the one who invoked this Modal
         */
        const { target } = this.props
        if (target) {
          this._calculatePosition(target)
        }

        if (this._modalElement) {
          /**
           * force modal to scroll to the top most
           */
          this._modalElement.scrollTop = 0

          /**
           * force the focus in the modal
           */
          this._enforceFocus(this._modalElement)

          /**
           * add a listener to every keydown event
           * this is to handle the esc key - close modal feature
           */
          this._modalElement.addEventListener('keydown', this._keyDownHandler)
        }

        /**
         * keep track of all the modals being mounted
         * this component should be able to render nested modals
         */
        Modal._modals.push(this._modalElement)
      } else {
        if (this._bodyElement) {
          this._bodyElement.classList.remove(
            'overflow-hidden',
            'overflow-offset'
          )
        }
        document.documentElement.style.overflow = 'initial'
      }
    }
  }

  render() {
    const {
      children,
      style,
      className,
      target,
      withBackdrop,
      arrow,
      closeBtn,
      disableEscapeKey,
      onEscapeKeyDown,
      visible,
      handleClose,
      fullTargetWidth,
      appearAnimation,
      disappearAnimation,
      useMinHeight,
      theme,
      width,
      alignX,
      alignY,
      hideBackdrop,
      ...rest
    } = this.props
    const { position, label: positionLabel } = this.state
    const _styles = target
      ? {
          top: position.top,
          right: position.right,
          bottom: position.bottom,
          left: position.left,
          width: width || position.width,
          transformOrigin: position.origin,
          zIndex: 4000 + Modal._modals.length,
          opacity: !appearAnimation ? 1 : 0,
          ...style,
        }
      : {
          top: `50%`,
          left: `50%`,
          bottom: null,
          right: null,
          transform: `translate(-50%, -50%)`,
          zIndex: 4000 + Modal._modals.length,
          opacity: !appearAnimation ? 1 : 0,
          ...style,
        }
    const __modal__ = classnames(`cui-modal ${className}`, {
      'modal-arrow': arrow !== null,
    })

    return (
      <Portal>
        {!hideBackdrop && (
          <Transition
            unmountOnExit
            appear={appearAnimation}
            exit={disappearAnimation}
            addEndListener={this.bdAddEndListener}
            onEnter={this.animateBdEnter}
            onExit={this.animateBdExit}
            in={visible}
            key={this.transitionKey + 100}
          >
            <Backdrop
              onClickHandler={this._backdropClickHandler}
              style={{
                zIndex: 4000 + Modal._modals.length,
                opacity: withBackdrop ? 1 : 0,
              }}
            />
          </Transition>
        )}
        <Transition
          unmountOnExit
          appear={appearAnimation}
          exit={disappearAnimation}
          addEndListener={this.modalAddEndListener}
          onEnter={this.animateEnter}
          onExit={this.animateExit}
          in={visible}
          key={this.transitionKey + 2}
        >
          <div
            id={`app_modal_${Modal._modals.length}`}
            tabIndex={-1}
            ref={el => (this._modalElement = el)}
            className={__modal__}
            style={{
              '--cuiModalBg': theme.colors.modal.background,
              '--cuiModalShadow': theme.colors.boxShadow,
              '--cuiModalText': theme.colors.text.primary,
              ..._styles,
            }}
            {...rest}
          >
            {arrow && (
              <div
                className={`modal-arrow ${
                  arrow === 'relative' ? positionLabel : ''
                }`}
                style={typeof arrow === 'object' ? arrow : {}}
              />
            )}
            {children}
            {closeBtn && (
              <button
                className="modal-close"
                onClick={this._closeBtnClickHandler}
              >
                <Icons.Close width={10} />
              </button>
            )}
          </div>
        </Transition>
      </Portal>
    )
  }
}

Modal.propTypes = {
  /**
   * Handler for closing the Modal, being called on esc key pressed/backdrop click or custom event
   */
  onClose: PropTypes.func,
  /**
   * class names to be added on the modal, list will be in a string format
   */
  className: PropTypes.string,
  /**
   * if `true`, disabling closing event on esc key press
   */
  disableEscapeKey: PropTypes.bool,
  withBackdrop: PropTypes.bool,
  offset: PropTypes.object,
  handleClose: PropTypes.func,
  fullTargetWidth: PropTypes.bool,
  appearAnimation: PropTypes.bool,
  disappearAnimation: PropTypes.bool,
  useMinHeight: PropTypes.bool,
  width: PropTypes.number,
  alignX: PropTypes.oneOf(['auto', 'left', 'right']),
  alignY: PropTypes.oneOf(['auto', 'top', 'bottom']),
  hideBackdrop: PropTypes.bool,
}

Modal.defaultProps = {
  onClose: () => {},
  handleClose: () => {},
  className: 'default',
  disableEscapeKey: false,
  withBackdrop: true,
  arrow: null,
  offset: null,
  closeBtn: false,
  appearAnimation: true,
  disappearAnimation: true,
  useMinHeight: true,
  alignX: 'auto',
  alignY: 'auto',
}

export default withTheme(Modal)
