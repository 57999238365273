import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.scss'
import { useTheme } from '../Theme'

const variants = {
  h1: { tag: 'h1', size: '6rem' },
  h2: { tag: 'h2', size: '3.75rem' },
  h3: { tag: 'h3', size: '3rem' },
  h4: { tag: 'h4', size: '2rem' },
  h5: { tag: 'h5', size: '1.5rem' },
  h6: { tag: 'h6', size: '1.25rem' },
  subtitle1: { tag: 'div', size: '1rem' },
  subtitle2: { tag: 'div', size: '0.875rem' },
  subtitle3: { tag: 'div', size: '0.625rem' },
  body1: { tag: 'div', size: '1rem' },
  body2: { tag: 'div', size: '0.875rem' },
  button: { tag: 'div', size: '0.875rem' },
  caption: { tag: 'div', size: '0.75rem' },
  overline: { tag: 'div', size: '0.75rem' },
}

const getVariantTag = (variant, tag) => {
  if (tag) return tag
  return variants[variant]?.tag || 'span'
}

/**
 * Typo component
 */
const Typo = ({
  children,
  weight,
  isBold,
  isBlock,
  isLight,
  allCaps,
  size,
  color,
  style,
  variant,
  variantSize,
  element,
  isMarkedStatus,
  variantColor,
  className,
  markedStatusColor,
}) => {
  const theme = useTheme()

  const variantFontSize = variantSize || variants[variant]?.size

  const typoColor = () => {
    if (!color) {
      if (variantColor && theme.colors.text[variantColor]) {
        return theme.colors.text[variantColor]
      }
      return null
    }
    if (
      color.includes('#') ||
      color.includes('rgb') ||
      color.includes('rgba')
    ) {
      return color
    }
    return `var(--color-${color})`
  }

  const Tag = getVariantTag(variant, element)

  return (
    <Tag
      className={classNames('cui-typo', className, {
        'cui-typo__light': isLight,
        'cui-typo__bold': isBold,
        'cui-typo__block': isBlock,
        'cui-typo__normal': size === 'normal',
        'cui-typo__small': size === 'small',
        'cui-typo__medium': size === 'medium',
        'cui-typo__big': size === 'big',
        'cui-typo__bigger': size === 'bigger',
        'cui-typo__large': size === 'large',
        'cui-typo__allcaps': allCaps,
      })}
      style={{
        ...style,
        color: typoColor(),
        '--typoWeight': weight,
        ...(variantFontSize
          ? {
              '--typoSize': variantFontSize,
            }
          : {}),
        '--cuiTypoColor': typoColor(),
        ...(isMarkedStatus
          ? {
              '--cuiTypoMarkedStatusColor':
                markedStatusColor ||
                typoColor() ||
                theme.colors.text[variantColor],
            }
          : {}),
      }}
    >
      {isMarkedStatus && <span className="status-mark" />} {children}
    </Tag>
  )
}

Typo.propTypes = {
  // Typo children
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  isBold: PropTypes.bool,
  size: PropTypes.string,
  color: PropTypes.string,
  weight: PropTypes.number,
  isLight: PropTypes.bool,
  isBlock: PropTypes.bool,
  allCaps: PropTypes.bool,
  style: PropTypes.object,
  variant: PropTypes.string,
  variantSize: PropTypes.string,
  element: PropTypes.string,
  isMarkedStatus: PropTypes.bool,
  variantColor: PropTypes.string, // one of ['primary', 'secondary', 'disabled']
  className: PropTypes.string,
  markedStatusColor: PropTypes.string,
}

Typo.defaultProps = {
  isBold: false,
  isBlock: false,
  isLight: false,
  allCaps: false,
  size: '',
  weight: 400,
  style: {},
  variant: 'span',
  variantSize: null,
  element: null,
  isMarkedStatus: false,
}

export default Typo
