import React, { useState, useEffect, PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

import DayPicker, { DateUtils } from 'react-day-picker'
import moment from 'moment'
import 'react-day-picker/lib/style.css'

import { parseDate } from '../../helpers'
import './DatePicker.scss'
import Icons from '../../Icons'
import Modal from '../../Modal'
import { withTheme } from '../../Theme'

const currentYear = new Date().getFullYear()
const fromMonth = new Date(1901, 0)

class YearMonthForm extends PureComponent {
  static getDerivedStateFromProps(props, state) {
    if (props.date !== state.date) {
      return {
        date: props.date,
      }
    }
    return null
  }

  state = {
    toMonth: new Date(currentYear + 10, 11),
    date: this.props.date,
  }

  _handleChange = e => {
    const { onChange, name } = this.props
    const month = name ? e.target.form[`${name}-month`] : e.target.form.month
    const year = name ? e.target.form[`${name}-year`] : e.target.form.year
    // const { year, name-month } = e.target.form

    onChange(new Date(year.value, month.value))
  }

  componentDidMount() {
    const { offset, date } = this.props
    let toMonth = new Date(currentYear + 10, 11)
    let currentDate = date

    if (offset) {
      if (offset.years && offset.months) {
        toMonth = new Date(currentYear - offset.years, 11 - offset.months)
        currentDate = toMonth
      } else if (offset.years) {
        toMonth = new Date(currentYear - offset.years, 11)
        currentDate = toMonth
      } else if (offset.months) {
        toMonth = new Date(currentYear, 11 - offset.months)
        currentDate = toMonth
      }
    }
    this.setState(prevState => ({
      ...prevState,
      toMonth,
      date: currentDate,
    }))
  }

  render() {
    const { localeUtils, name } = this.props
    const { date: currentDate, toMonth, year, month } = this.state
    const months = localeUtils.getMonths()
    const years = []
    for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
      years.push(i)
    }

    return (
      <form className="DayPicker-Caption" data-testid="year-month-form">
        <select
          name={name ? `${name}-month` : 'month'}
          onChange={this._handleChange}
          value={currentDate.getMonth()}
          data-testid="month-selection-dp"
        >
          {months.map((month, i) => (
            <option key={month} value={i}>
              {month}
            </option>
          ))}
        </select>
        <select
          name={name ? `${name}-year` : 'year'}
          onChange={this._handleChange}
          value={currentDate.getFullYear()}
          data-testid="year-selection-dp"
        >
          {years.map(year => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </form>
    )
  }
}

class DatePicker extends PureComponent {
  constructor(props) {
    super(props)
    this.state = this.getInitialState()
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value !== state._value) {
      if (props.value === '') {
        return {
          from: null,
          to: null,
          enteredTo: null,
          selectedDay: null,
          _value: '',
        }
      }
      return {
        _value: props.value,
        ...(props.isRange
          ? {
              from: props.value?.from || null,
              to: props.value?.to || null,
              enteredTo: props.value?.to || null,
            }
          : {}),
      }
    }
    return null
  }

  getInitialState() {
    return {
      from: null,
      to: null,
      enteredTo: null,
      selectedDay: null,
      _value: '',
    }
  }

  isSelectingFirstDay = (from, to, day) => {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from)
    const isRangeSelected = from && to
    return !from || isBeforeFirstDay || isRangeSelected
  }

  handleSingleDay = (day, { selected, disabled }) => {
    if (disabled) {
      return null
    }
    if (this.props.onChange) {
      this.props.onChange(day)
    }
    this.setState(
      prevState => ({
        ...prevState,
        selectedDay: selected ? null : day,
      }),
      () => {
        this.handleClosePicker()
      }
    )
  }

  handleDayClick = (day, modifiers = {}) => {
    const { from, to, selectedDay, enteredTo } = this.state

    if (modifiers.disabled) {
      return null
    }

    if (from && to && day >= from && day <= to) {
      this.handleResetClick()
      return
    }

    if (this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        from: day,
        to: null,
        enteredTo: null,
      })
      this.props?.onFirstDayClick &&
        this.props.onFirstDayClick({
          from: day,
          to: null,
          enteredTo: null,
        })
    } else {
      if (this.props.onChange) {
        this.props.onChange({
          from: this.state.from,
          to: day,
        })
      }
      this.setState(
        {
          to: day,
          enteredTo: day,
        },
        () => {
          this.handleClosePicker()
        }
      )
    }
  }

  handleResetClick = () => {
    this.setState(this.getInitialState())
    const { onSelectHandler, onChange, name } = this.props
    this.props?.onFirstDayClick &&
      this.props.onFirstDayClick({
        from: null,
        to: null,
        enteredTo: null,
      })
    if (onChange) {
      onChange(null)
    }
    if (onSelectHandler) {
      onSelectHandler(name, null)
    }
  }

  handleClosePicker = () => {
    this.setState(prevState => ({
      ...prevState,
      showPicker: false,
    }))
  }

  handleOpenPicker = () => {
    this.setState(prevState => ({
      ...prevState,
      showPicker: true,
    }))
  }

  handleYearMonthChange = month => {
    this.setState(prevState => ({
      ...prevState,
      month,
    }))
  }

  componentDidUpdate(prevProps, prevState) {
    const { onSelectHandler, name } = this.props

    if (this.state.selectedDay !== prevState.selectedDay) {
      onSelectHandler(name, this.state.selectedDay)
    }
    if (this.state.from !== prevState.from || this.state.to !== prevState.to) {
      const payload = {
        from: this.state.from,
        to: this.state.to,
      }
      onSelectHandler(name, payload)
    }
  }

  componentDidMount() {
    const { offset, id } = this.props
    if (offset) {
      let toMonth = new Date(currentYear + 10, 11)
      if (offset.years && offset.months) {
        toMonth = new Date(currentYear - offset.years, 11 - offset.months)
      } else if (offset.years) {
        toMonth = new Date(currentYear - offset.years, 11)
      } else if (offset.months) {
        toMonth = new Date(currentYear, 11 - offset.months)
      }
      this.setState(prevState => ({
        ...prevState,
        month: toMonth,
      }))
    }
  }

  render() {
    const {
      children,
      isRange,
      availableDays,
      publicHolidays,
      disablePrev,
      disableNext,
      disableBefore,
      disableAfter,
      disabled,
      offset,
      value,
      showLegend,
      onBlurHandler,
      onChangeHandler,
      isInput,
      id,
      timezone,
      name,
      selectedMonth,
      theme,
      placeholder,
      resolve,
      onFirstDayClick,
    } = this.props

    const {
      from,
      to,
      enteredTo,
      showPicker,
      selectedDay: sd,
      month = selectedMonth,
    } = this.state

    const modifiers = {
      start: from,
      end: enteredTo,
      nonWorkingDays: {
        daysOfWeek: [0],
      },
    }
    const modifiersStyles = {
      nonWorkingDays: {
        color: '#FF0000',
      },
      today: {
        color: '#00b0b2',
        fontWeight: '400',
      },
      selected: {
        color: '#00b0b2',
        backgroundColor: 'transparent',
        border: '1px solid #00b0b2',
        fontWeight: 'bold',
      },
      publicHolidays: {
        color: '#FF0000',
      },
    }
    const minDate = disableBefore
    const maxDate = disableAfter

    const disabledDays = this._getDisabledDays(minDate, maxDate)

    const multiDisabledDays = [disabledDays]
    if (publicHolidays) {
      publicHolidays.map(day => {
        multiDisabledDays.push(new Date(day))
      })
    }

    const selectedDays = [from, { from, to: enteredTo }]
    let selectedDay = sd

    if (value) {
      if (typeof value === 'string') {
        const _selected = moment(value).tz('Asia/Singapore')
        selectedDay = _selected.toDate()
      }
      if (typeof value === 'object') {
        selectedDay = value
      }
    }

    return (
      <>
        {isInput && (
          <div className="datepicker">
            <input
              id={`${id}-input`}
              type="text"
              // onChange={onChangeHandler}
              onBlur={onBlurHandler}
              value={this._getFormattedSelectedDay(value)}
            />
            <button
              disabled={disabled}
              type="button"
              ref={el => (this._targetElem = el)}
              onClick={this.handleOpenPicker}
              className="lm--button lm--button-picker"
              onBlur={onBlurHandler}
            >
              <Icons.Calendar width={16} height={16} />
            </button>
          </div>
        )}
        {!isInput && (
          <button
            id={`${id}-btn`}
            data-testid={`${id}-btn`}
            disabled={disabled}
            type="button"
            ref={el => (this._targetElem = el)}
            onClick={this.handleOpenPicker}
            className="lm--button lm--button-picker"
            onBlur={onBlurHandler}
          >
            <Icons.Calendar width={16} height={16} />
            {resolve && typeof resolve === 'function'
              ? resolve({
                  to,
                  from,
                  selectedDay,
                })
              : isRange
              ? to && to !== 'Today'
                ? `${from && parseDate(from, 'DD/MM/YYYY')} - ${
                    to && parseDate(to, 'DD/MM/YYYY')
                  }`
                : placeholder
              : `${
                  selectedDay
                    ? parseDate(selectedDay, 'DD/MM/YYYY')
                    : placeholder
                }`}
          </button>
        )}
        {showPicker && (
          <Modal
            id={`${id}-dp`}
            data-testid={`${id}-dp`}
            className="app-datepicker"
            handleClose={this.handleClosePicker}
            target={this._targetElem}
            withBackdrop={false}
            visible={showPicker}
            style={{
              '--cuiDatePickerBg': theme.colors.input.background,
              '--cuiDatePickerItemHoverBg':
                theme.colors.datePicker.itemHoverBackground,
              '--cuiDatePickerSelectBorder': theme.colors.input.border,
              '--cuiDatePickerTextPrimary': theme.colors.text.primary,
              '--cuiDatePickerTextSecondary': theme.colors.text.secondary,
              '--cuiDatePickerDisabled': theme.colors.datePicker.disabled,
            }}
          >
            {isRange ? (
              <>
                <DayPicker
                  className="datepicker-range"
                  numberOfMonths={1}
                  fromMonth={from}
                  selectedDays={selectedDays}
                  disabledDays={multiDisabledDays}
                  modifiers={modifiers}
                  modifiersStyles={modifiersStyles}
                  onDayClick={this.handleDayClick}
                  captionElement={({ date, localeUtils }) => (
                    <YearMonthForm
                      offset={offset}
                      name={name}
                      date={date}
                      localeUtils={localeUtils}
                      onChange={this.handleYearMonthChange}
                    />
                  )}
                  month={month}
                />
                <div>
                  {!from && !to && 'Please select the first day.'}
                  {from && !to && 'Please select the last day.'}
                  {from &&
                    !to &&
                    onFirstDayClick &&
                    'Please select the last day.' && (
                      <button className="link" onClick={this.handleResetClick}>
                        Reset
                      </button>
                    )}
                  {from &&
                    to &&
                    `Selected from ${from.toLocaleDateString()} to
                            ${to.toLocaleDateString()}`}{' '}
                  {from && to && (
                    <button className="link" onClick={this.handleResetClick}>
                      Reset
                    </button>
                  )}
                </div>
              </>
            ) : (
              <>
                <DayPicker
                  disabledDays={multiDisabledDays}
                  selectedDays={selectedDay}
                  modifiers={{
                    nonWorkingDays: {
                      daysOfWeek: [0],
                    },
                    publicHolidays: publicHolidays
                      ? publicHolidays.map(date => new Date(date))
                      : publicHolidays,
                  }}
                  modifiersStyles={modifiersStyles}
                  onDayClick={this.handleSingleDay}
                  captionElement={({ date, localeUtils }) => (
                    <YearMonthForm
                      offset={offset}
                      name={name}
                      date={date}
                      localeUtils={localeUtils}
                      onChange={this.handleYearMonthChange}
                    />
                  )}
                  month={month}
                />
              </>
            )}
            {showLegend && (
              <div className="modal-footnote">
                <span className="fn-item">
                  <span
                    className="circle"
                    style={{
                      backgroundColor: '#00b0b2',
                    }}
                  />
                  <span>Today</span>
                </span>
                <span className="fn-item">
                  <span
                    className="circle"
                    style={{
                      backgroundColor: '#FF0000',
                    }}
                  />
                  <span>Sun / PH day</span>
                </span>
              </div>
            )}
          </Modal>
        )}
      </>
    )
  }

  _getDisabledDays(minDate, maxDate) {
    const { availableDays, disableToday, disablePrev, disableNext, timezone } =
      this.props
    if (availableDays && availableDays.length > 0) {
      return day => {
        const today = moment().startOf('day')
        const todayDate = today.format('YYYY-MM-DD')
        const cDay = moment(day).startOf('day')
        const cDayDate = cDay.format('YYYY-MM-DD')
        if (disableToday) {
          if (todayDate === cDayDate) {
            return true
          }
        }
        return !availableDays.some(dDay => {
          const aDay = moment(dDay).tz(timezone).startOf('day')
          return aDay.format('YYYY-MM-DD') === cDayDate
        })
      }
    } else {
      if (disablePrev) {
        return { before: new Date() }
      }
      if (disableNext) {
        return { after: new Date() }
      }
      if (minDate && maxDate) {
        return {
          before: moment(minDate).toDate(),
          after: moment(maxDate).toDate(),
        }
      }
      if (minDate) {
        return { before: moment(minDate).toDate() }
      }
      if (maxDate) {
        return { after: moment(maxDate).toDate() }
      }
    }
    return null
  }

  _getFormattedSelectedDay(value) {
    if (Object.prototype.toString.call(value) === '[object Date]') {
      if (isNaN(value)) {
        // d.valueOf() could also work
        return value
      } else {
        var options = { year: 'numeric', month: '2-digit', day: '2-digit' }
        const selectedDay = new Date(value)
        return selectedDay.toLocaleDateString('en-SG', options)
      }
    }
    return value
  }
}

export default withTheme(DatePicker)
