import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const FormReceiptRow = ({ children }) => {
  const _FormReceiptRow = classnames('cui-receipt_row')

  return <div className={_FormReceiptRow}>{children}</div>
}

FormReceiptRow.propTypes = {
  children: PropTypes.PropTypes.any([PropTypes.object, PropTypes.string]),
}

export default FormReceiptRow
