import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useFormikContext } from 'formik'

import FieldLabel from '../FieldLabel'
import FieldContent from '../FieldContent'
import { Hint } from '../FieldMisc'
import FieldError from '../FieldError'

const _FieldCheckbox = ({
  children,
  name,
  id,
  value,
  onChangeHandler,
  disabled,
  checked,
  className,
}) => {
  const _FieldCheckbox = classnames(
    'lm--input-group cui-field_group',
    className
  )
  const { errors, touched, values, handleChange: formikHandleChange } =
    useFormikContext() || {}

  const handleChange = e => {
    if (onChangeHandler) {
      onChangeHandler(e)
    }
    if (formikHandleChange) {
      formikHandleChange(e)
    }
  }

  function renderMisc() {
    return React.Children.map(children, (child, childIdx) => {
      if (child.type === Hint || child.type === FieldError) {
        return React.cloneElement(child)
      }
    })
  }

  function renderField() {
    const label = React.Children.map(children, (child, childIdx) => {
      if (child.type === FieldLabel) {
        return <FieldLabel {...child.props} />
      }
      return null
    })

    const fieldContent = React.Children.map(children, (child, childIdx) => {
      if (child.type === FieldContent) {
        return <FieldContent {...child.props} />
      }
      return null
    })

    const inputs = () => {
      return (
        <input
          type="checkbox"
          onChange={handleChange}
          value={(values && values[name]) || value}
          id={id || name}
          name={name}
          className="cui-field_checkbox lm--checkbox-input"
          disabled={disabled}
          checked={
            checked !== undefined && checked !== null ? checked : values[name]
          }
        />
      )
    }

    return (
      <div className={_FieldCheckbox}>
        <div className="lm--input cui-field">
          {label || <label />}
          <div
            className={classnames('lm--input-group-control cui-field_control', {
              'full-width': React.Children.count(children) === 1,
            })}
          >
            <span className="lm--checkbox">
              {inputs()}
              <label
                className="lm--checkbox-label cui-field_control__label"
                htmlFor={id || name}
              >
                {fieldContent}
              </label>
            </span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      {renderField()}
      <div className="cui-field_checkbox__misc">{renderMisc()}</div>
    </>
  )
}

_FieldCheckbox.propTypes = {
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  name: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
}

export default _FieldCheckbox
