import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useChartXY } from './charts/ChartXY'
import { useTheme } from '../Theme'

const ChartLabel = ({
  axis,
  rotate,
  gap,
  resolve,
  lineWidth,
  showUnitLine,
  textColors,
  lineColors,
  labels,
}) => {
  const theme = useTheme()
  const yAxisRef = React.createRef()
  const {
    parentHeight,
    parentWidth,
    direction,
    actions: { getDivisionY, getDivisionX, getDivisionOtherY, getBarWidth },
    otherY,
    innerPadding,
  } = useChartXY()
  const isVertical = direction === 'vertical'
  const isArrColor = {
    text: Array.isArray(textColors),
    line: Array.isArray(lineColors),
  }

  const renderLabel = () => {
    const displayHorizontalLabels = () => {
      const _ChartLabelItem = classnames('cui-chart__labelX--item')
      let xAxis = isVertical ? getDivisionX() : getDivisionY()
      let idx = xAxis.length - 1
      const rows = []

      if (xAxis === undefined) {
        return rows
      }

      if (labels) {
        const barWidth = getBarWidth()
        xAxis = labels.map((label, index) => {
          return {
            label,
            pos:
              (parentWidth / labels.length) * index +
              innerPadding +
              barWidth / 2,
          }
        })
      }

      for (var i = 0; i < xAxis.length; i++) {
        if (gap && i % gap !== 0) {
          continue
        }
        const translateY = rotate > 0 ? parentHeight + 14 : parentHeight
        const textY = rotate > 0 ? '3' : '15'
        rows.push(
          <g key={'label-x-' + i}>
            <g
              className={_ChartLabelItem}
              transform={`translate(${xAxis[i].pos}, ${translateY}) rotate(${rotate})`}
            >
              <text
                className={_ChartLabelItem + '-text'}
                x="0"
                y={textY}
                textAnchor="start"
                fill={
                  isArrColor.text
                    ? textColors[idx]
                      ? textColors[idx]
                      : textColors[textColors.length - 1]
                    : textColors || theme.colors.chart.text
                }
              >
                {resolve ? resolve(xAxis[i].label, i) : xAxis[i].label}
              </text>
            </g>
            {!isVertical && (
              <line
                className="cui-chart__labelY--row"
                x1={xAxis[i].pos}
                y1={parentHeight}
                x2={xAxis[i].pos}
                y2={0}
                stroke={
                  isArrColor.line
                    ? lineColors[idx]
                      ? lineColors[idx]
                      : lineColors[lineColors.length - 1]
                    : lineColors || theme.colors.chart.division
                }
                strokeWidth={lineWidth}
              />
            )}
          </g>
        )
        idx--
      }
      return rows
    }

    const displayVerticalLabels = () => {
      const _ChartLabelItem = classnames('cui-chart__labelY--item')
      const yAxis = isVertical ? getDivisionY() : getDivisionX()
      let idx = yAxis.length - 1
      const rows = []

      if (yAxis === undefined) {
        return rows
      }

      const renderText = (axis, i, isOtherY) => {
        return (
          <text
            className={_ChartLabelItem + '-text'}
            x={isOtherY ? parentWidth + 5 : -5}
            y="0"
            textAnchor={isOtherY ? 'start' : 'end'}
            fontSize="1rem"
            fill={
              isArrColor.text
                ? textColors[idx]
                  ? textColors[idx]
                  : textColors[textColors.length - 1]
                : textColors || theme.colors.chart.text
            }
          >
            {resolve ? resolve(axis[i].label, i) : axis[i].label}
          </text>
        )
      }

      const otherYAxis = getDivisionOtherY()
      for (let i = 0; i < yAxis.length; i++) {
        rows.push(
          <g key={'label-y-' + i}>
            <g
              className={_ChartLabelItem}
              transform={`translate(0, ${yAxis[i].pos})`}
            >
              {renderText(yAxis, i)}
              {isVertical && otherYAxis && renderText(otherYAxis, i, true)}
            </g>
            {isVertical && (
              <line
                className="cui-chart__labelY--row"
                x1="0"
                y1={yAxis[i].pos}
                x2={parentWidth}
                y2={yAxis[i].pos}
                stroke={
                  isArrColor.line
                    ? lineColors[idx]
                      ? lineColors[idx]
                      : lineColors[lineColors.length - 1]
                    : lineColors || theme.colors.chart.division
                }
                strokeWidth={lineWidth}
              />
            )}
          </g>
        )
        idx--
      }
      return rows
    }

    if (axis === 'y') {
      return (
        <g className="cui-chart-label-y" ref={yAxisRef}>
          {displayVerticalLabels()}
        </g>
      )
    } else {
      return (
        <g className="cui-chart-label-x">
          {displayHorizontalLabels()}
          {showUnitLine && (
            <>
              <line
                className="chart-yaxis-line"
                x1="0"
                y1={isVertical ? 0 : parentHeight}
                x2={isVertical ? 0 : parentWidth}
                y2={parentHeight}
                style={{ strokeWidth: lineWidth }}
                transform="translate(0,0)"
                stroke={
                  isArrColor.line
                    ? lineColors[0]
                    : lineColors || theme.colors.chart.division
                }
              />
              {otherY && (
                <line
                  className="chart-yaxis-line"
                  x1={parentWidth}
                  y1={isVertical ? 0 : parentHeight}
                  x2={parentWidth}
                  y2={parentHeight}
                  style={{ strokeWidth: lineWidth }}
                  transform="translate(0,0)"
                  stroke={
                    isArrColor.line
                      ? lineColors[0]
                      : lineColors || theme.colors.chart.division
                  }
                />
              )}
            </>
          )}
        </g>
      )
    }
  }
  return <>{renderLabel()}</>
}

ChartLabel.defaultProps = {
  rotate: 0,
  lineWidth: 1,
}

ChartLabel.propTypes = {
  axis: PropTypes.string,
  rotate: PropTypes.number,
  gap: PropTypes.number,
  resolve: PropTypes.func,
  lineWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  textColors: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  lineColors: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  showUnitLine: PropTypes.bool,
}

export default ChartLabel
