import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useFormikContext } from 'formik'

import debounce from 'lodash/debounce'
import Icons from '../../Icons'
import FieldLabel from '../FieldLabel'
import ContextMenu from '../../ContextMenu'
import SearchInput from '../../SearchInput'
import FieldCheckbox from '../FieldCheckbox'
import FieldContent from '../FieldContent'
import { useTheme } from '../../Theme'
import Typo from '../../Typo'

const DropdownMenu = ({
  children,
  name,
  value,
  onChange,
  options,
  multiple,
  handleCloseOptions,
  group,
  labelCheckAll,
  isCheckAll,
  setIsCheckAll,
  isLoading,
  onSearchChange,
}) => {
  const theme = useTheme()
  const { values, setFieldValue } = useFormikContext() || {
    values: {},
  }
  const searchInputRef = React.useRef()

  const [searchKeyword, setSearchKeyword] = React.useState('')
  const [isLoadingOptions, setIsLoadingOptions] = React.useState(isLoading)

  React.useEffect(() => {
    setIsLoadingOptions(isLoading)
    focusInput()
  }, [isLoading])

  const focusInput = () => {
    const searchInput = searchInputRef.current?.querySelector('input')
    searchInput?.focus()
  }

  const handleChangeCheckAll = React.useCallback(
    (tempValues, tempsOptions, tempKey) => {
      const filteredValues = tempKey
        ? tempValues.filter(option => {
            return (
              option.label.toLowerCase().indexOf(tempKey.toLowerCase()) !== -1
            )
          })
        : tempValues
      const filteredOptions = tempKey
        ? tempsOptions.filter(option => {
            return (
              option.label.toLowerCase().indexOf(tempKey.toLowerCase()) !== -1
            )
          })
        : tempsOptions
      setIsCheckAll(filteredValues.length === filteredOptions.length)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const searchDebounce = React.useCallback(
    debounce(tempKey => {
      setSearchKeyword(tempKey)
      if (onSearchChange) {
        setIsLoadingOptions(true)
        onSearchChange(tempKey)
      }
      if (labelCheckAll) {
        const selectedOptions = values[name] || value || []
        const filteredValues = tempKey
          ? selectedOptions.filter(option => {
              return (
                option.label.toLowerCase().indexOf(tempKey.toLowerCase()) !== -1
              )
            })
          : selectedOptions
        const filteredOptions = tempKey
          ? options.filter(option => {
              return (
                option.label.toLowerCase().indexOf(tempKey.toLowerCase()) !== -1
              )
            })
          : options
        setIsCheckAll(filteredValues.length === filteredOptions.length)
      }
      const searchInput = searchInputRef.current?.querySelector('input')
      searchInput?.focus()
    }, 400),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values, name, value, labelCheckAll, options, onSearchChange]
  )

  const renderSearch = () => {
    return React.Children.map(children, (child, childIdx) => {
      if (child.type === SearchInput) {
        return (
          <div
            className="cui-field-dropdown__option-wrapper"
            ref={searchInputRef}
          >
            {React.cloneElement(child, {
              onSearch: keyword => {
                searchDebounce(keyword)
              },
            })}
          </div>
        )
      }
      return null
    })
  }

  const hightLightSearchKey = (label = '', keySearch) => {
    if (!keySearch) {
      return label
    }
    const regex = new RegExp(`${keySearch}|.`, 'gmi')
    const arrayLabels = label.match(regex)
    const result = arrayLabels.reduce(
      (acc, item) =>
        item.toLowerCase() === keySearch.toLowerCase()
          ? (acc += `<span class='cui-field-dropdown__text-highlight'>${item}</span>`)
          : (acc += item),
      ''
    )
    return (
      <span
        dangerouslySetInnerHTML={{
          __html: result,
        }}
      />
    )
  }

  const renderOptonsGroup = () => {
    const selectedOptions = values[name] || value || []

    const filteredOptions = searchKeyword
      ? options.filter(option => {
          return (
            option.label.toLowerCase().indexOf(searchKeyword.toLowerCase()) !==
            -1
          )
        })
      : options

    const newOptions = filteredOptions.reduce((acc, item) => {
      const key = (item?.group || '').toLowerCase().replace(/\s/gm, '-')
      return {
        ...acc,
        [key]: acc[key]
          ? { ...acc[key], data: [...acc[key].data, item] }
          : { data: [item], title: item.group },
      }
    }, {})

    const handleSelect = option => {
      let newValue
      if (multiple) {
        newValue = [...(values[name] || value || [])]
        const index = selectedOptions.findIndex(o => o.value === option.value)
        if (index === -1) {
          newValue.push(option)
        } else {
          newValue.splice(index, 1)
        }
      } else {
        newValue = option
        handleCloseOptions()
      }
      if (setFieldValue) {
        setFieldValue(name, newValue)
        labelCheckAll && handleChangeCheckAll(newValue, options, searchKeyword)
      }
      if (onChange) {
        onChange(newValue)
      }
    }

    const selectedAll = () => {
      const selectedOptions = values[name] || value || []
      const filteredOptions = searchKeyword
        ? options.filter(option => {
            return (
              option.label
                .toLowerCase()
                .indexOf(searchKeyword.toLowerCase()) !== -1
            )
          })
        : options
      if (!isCheckAll) {
        const newItems = selectedOptions.filter(
          currentItem =>
            !filteredOptions.find(
              newItem => newItem.value === currentItem.value
            )
        )
        setFieldValue(name, [...filteredOptions, ...newItems])
      } else {
        const newItems = selectedOptions.reduce(
          (acc, item) =>
            filteredOptions.some(e => e.value === item.value)
              ? acc
              : [...acc, item],
          []
        )
        setFieldValue(name, newItems)
      }
      setIsCheckAll(prev => !prev)
    }
    return (
      <>
        {isLoadingOptions ? (
          <div className="cui-field-dropdown__loading">
            <Icons.Spinloader width={30} color={[theme.colors.icon]} />
          </div>
        ) : (
          <>
            {labelCheckAll && multiple && Object.keys(newOptions).length > 1 && (
              <div
                className="cui-field-dropdown__option-wrapper"
                onClick={() => selectedAll()}
              >
                <FieldCheckbox checked={isCheckAll}>
                  <FieldContent>All {labelCheckAll}</FieldContent>
                </FieldCheckbox>
              </div>
            )}
            {Object.keys(newOptions).map(item => {
              return (
                <div
                  key={item}
                  className="cui-field-dropdown__option-group-wrapper"
                >
                  <Typo variant="caption" variantColor="secondary" isBold>
                    {newOptions[item].title}
                  </Typo>
                  {newOptions[item].data.map(option => {
                    return (
                      <div
                        key={option.value}
                        onClick={() => handleSelect(option)}
                        className="cui-field-dropdown__item-group-wrapper"
                      >
                        {multiple ? (
                          <FieldCheckbox
                            name={option.name}
                            checked={selectedOptions.some(
                              o => o.value === option.value
                            )}
                          >
                            <FieldContent>
                              {hightLightSearchKey(option.label, searchKeyword)}
                            </FieldContent>
                          </FieldCheckbox>
                        ) : (
                          option.label
                        )}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </>
        )}
      </>
    )
  }

  const renderOptions = () => {
    const selectedOptions = values[name] || value || []
    const filteredOptions = searchKeyword
      ? options.filter(option => {
          return (
            option.label.toLowerCase().indexOf(searchKeyword.toLowerCase()) !==
            -1
          )
        })
      : options

    const handleSelect = option => {
      let newValue
      if (multiple) {
        newValue = [...(values[name] || value || [])]
        const index = selectedOptions.findIndex(o => o.value === option.value)
        if (index === -1) {
          newValue.push(option)
        } else {
          newValue.splice(index, 1)
        }
      } else {
        newValue = option
        handleCloseOptions()
      }
      if (setFieldValue) {
        setFieldValue(name, newValue)
      }
      if (onChange) {
        onChange(newValue)
      }
    }
    return (
      <>
        {filteredOptions.map(option => {
          return (
            <div
              key={option.value}
              className="cui-field-dropdown__option-wrapper"
              onClick={() => handleSelect(option)}
            >
              {multiple ? (
                <FieldCheckbox
                  name={option.name}
                  checked={selectedOptions.some(o => o.value === option.value)}
                >
                  <FieldContent>{option.label}</FieldContent>
                </FieldCheckbox>
              ) : (
                option.label
              )}
            </div>
          )
        })}
      </>
    )
  }

  return (
    <div
      style={{
        '--cuiDropdownHoverBg': theme.colors.menuList.itemHover,
        '--cuiDropdownDivider': theme.colors.menuList.divider,
        '--cuiDropdownBg': theme.colors.input.background,
        '--cuiHighlightSearchKeyPrimary': theme.colors.primary.main,
      }}
    >
      {renderSearch()}
      <div className="cui-field-dropdown__options">
        {group ? renderOptonsGroup() : renderOptions()}
      </div>
    </div>
  )
}

const FieldDropdown = ({
  id,
  children,
  name,
  placeholder,
  value,
  onChange,
  className,
  options,
  multiple,
  group,
  labelCheckAll,
  isLoading,
  onSearchChange,
}) => {
  const dropdownRef = React.useRef()

  const [open, setOpen] = React.useState(false)
  const [isCheckAll, setIsCheckAll] = React.useState(false)

  const { values } = useFormikContext() || { values: {} }

  const renderLabel = () => {
    return React.Children.map(children, (child, childIdx) => {
      if (child.type === FieldLabel) {
        return <FieldLabel {...child.props} />
      }
      return null
    })
  }

  const renderDropdown = () => {
    const currentValue = values[name] || value || []
    const valueInput =
      (multiple
        ? currentValue.map(option => option.label).join(', ')
        : currentValue?.label) || placeholder
    const groupValue = isCheckAll ? (
      <p>
        <span className="cui-field-dropdown__selected-item">{`${labelCheckAll} `}</span>{' '}
        All
      </p>
    ) : (
      <div>
        <span className="cui-field-dropdown__selected-item-block">
          {' '}
          {currentValue.length} x
        </span>{' '}
        <span className="cui-field-dropdown__selected-item">
          {labelCheckAll}
        </span>
      </div>
    )
    return (
      <div className={classnames('cui-field-dropdown__wrapper', className)}>
        <div
          className="cui-field-dropdown__select"
          onClick={() => setOpen(true)}
          ref={dropdownRef}
          data-testid="dropdown"
        >
          <div className="cui-field-dropdown__selected">
            {group && currentValue.length > 2 && labelCheckAll
              ? groupValue
              : valueInput}
          </div>
          <span className="cui-field-dropdown__dropdown-icon">
            <Icons.Caret.Down width={14} height={8} />
          </span>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="lm--input-group cui-field-dropdown" id={id}>
        <div className="lm--input lm--select cui-field">
          {renderLabel()}
          <div
            className={classnames('cui-field_control', {
              'full-width': React.Children.count(children) === 0,
            })}
          >
            {renderDropdown()}
          </div>
        </div>
      </div>
      <ContextMenu
        targetNode={dropdownRef.current}
        onCloseHandler={() => setOpen(false)}
        withBackdrop={false}
        className="cui-field-dropdown__modal"
        fullTargetWidth
        open={open}
        appearAnimation={false}
        disappearAnimation={false}
      >
        <DropdownMenu
          name={name}
          value={value}
          onSearchChange={onSearchChange}
          onChange={onChange}
          options={options}
          multiple={multiple}
          group={group}
          handleCloseOptions={() => setOpen(false)}
          labelCheckAll={labelCheckAll}
          setIsCheckAll={setIsCheckAll}
          isCheckAll={isCheckAll}
          isLoading={isLoading}
        >
          {children}
        </DropdownMenu>
      </ContextMenu>
    </>
  )
}

FieldDropdown.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  name: PropTypes.string,
  placeholder: PropTypes.node,
  value: PropTypes.oneOf([PropTypes.object, PropTypes.array]), // object for single selection, array for multiple selection
  onChange: PropTypes.func,
  onSearchChange: PropTypes.func,
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    })
  ),
  multiple: PropTypes.bool,
  group: PropTypes.bool,
  labelCheckAll: PropTypes.string,
  isLoading: PropTypes.bool,
}

export default FieldDropdown
