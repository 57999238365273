import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import NavigationStackHeader from './NavigationStackHeader'
import NavigationStackScenes from './NavigationStackScenes'
import NavigationStackScene from './NavigationStackScene'

import Portal from '../Portal'
import { useTheme } from '../Theme'

export const NavigationStackCtx = React.createContext()
export const NavigationStackProvider = NavigationStackCtx.Provider
export const NavigationStackConsumer = NavigationStackCtx.Consumer

export const useNavigationStack = () => {
  const nsCtx = React.useContext(NavigationStackCtx)
  return nsCtx
}

export const withNavigationStack = (Component, otherProps) => {
  return props => (
    <NavigationStackConsumer>
      {context => (
        <div>
          <Component {...props} {...otherProps} {...context} />
        </div>
      )}
    </NavigationStackConsumer>
  )
}

const NavigationStack = React.forwardRef(
  ({ children, id, onClose, portal, className }, ref) => {
    const theme = useTheme()
    const [activeScene, setActiveScene] = React.useState(0)
    const [activeSceneTitle, setActiveSceneTitle] = React.useState('Stack')
    const [numOfScenes, setNumOfScenes] = React.useState(0)

    const _NavigationStack = classnames('cui-navigationstack', className)

    const handleClose = () => {
      if (onClose) {
        onClose()
      }
    }

    const handleNext = () => {
      let active = activeScene
      if (numOfScenes - 1 > active) {
        active++
        setActiveScene(active)
      }
    }
    const handlePrev = () => {
      let active = activeScene
      if (activeScene === 0) {
        handleClose()
        return
      }
      if (active > 0) {
        active--
        setActiveScene(active)
      }
    }

    const handleSceneTitle = title => {
      setActiveSceneTitle(title)
    }

    const handleTotalScenes = total => {
      setNumOfScenes(total)
    }

    function renderChildren() {
      return React.Children.map(children, (child, childIdx) =>
        React.cloneElement(child, {
          ...child.props,
          testProp: '123',
          key: `navigationstack--child--${childIdx}`,
        })
      )
    }

    function render() {
      return (
        <section id={id} className={_NavigationStack} ref={ref}>
          <NavigationStackProvider
            value={{
              data: {
                activeScene,
                activeSceneTitle,
                numOfScenes,
              },
              actions: {
                next: handleNext,
                prev: handlePrev,
                setTitle: handleSceneTitle,
                setTotalScenes: handleTotalScenes,
              },
            }}
          >
            {renderChildren()}
          </NavigationStackProvider>
        </section>
      )
    }

    return portal ? (
      <Portal>
        <div
          className="cui-navigationstack-port"
          style={{
            '--cuiNavigationStackBg': theme.colors.background.level1,
            '--cuiNavigationStackTextPrimary': theme.colors.text.primary,
            '--cuiNavigationStackTextSecondary': theme.colors.text.secondary,
            '--cuiNavigationStackShadow': theme.colors.boxShadow,
          }}
        >
          {render()}
        </div>
      </Portal>
    ) : (
      render()
    )
  }
)

NavigationStack.propTypes = {
  id: PropTypes.string,
  onClose: PropTypes.func,
  portal: PropTypes.bool,
  className: PropTypes.string,
}

NavigationStack.Header = NavigationStackHeader
NavigationStack.Scenes = NavigationStackScenes
NavigationStack.Scene = NavigationStackScene

export default NavigationStack
