import './styles.scss'
import Bare from './PopupBare'
import Confirmation from './PopupConfirmation'
import Title from './PopupTitle'
import Content from './PopupContent'
import Status from './PopupStatus'
import Form from './PopupForm'

export default {
  Bare,
  Confirmation,
  Title,
  Content,
  Status,
  Form,
}
