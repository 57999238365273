import React from 'react'

function Thermometer({ color }) {
  return (
    <>
      <g className="icon-thermometer">
        <rect opacity="0.2" width="44" height="43" rx="21.5" fill={color[1]} />
        <mask
          id="icon-thermometer_mask0"
          mask-type="alpha"
          maskUnits="userSpaceOnUse"
          x="17"
          y="12"
          width="11"
          height="18"
        >
          <path
            d="M21.4318 18.5455C21.4318 18.0936 21.8287 17.7273 22.3182 17.7273C22.8077 17.7273 23.2046 18.0936 23.2046 18.5455V22.6364C23.2046 22.6827 23.2004 22.728 23.1924 22.7722C24.2315 23.1059 24.9773 24.0181 24.9773 25.091C24.9773 26.4466 23.7868 27.5455 22.3182 27.5455C20.8496 27.5455 19.6591 26.4466 19.6591 25.091C19.6591 24.0181 20.4049 23.1059 21.444 22.7722C21.436 22.728 21.4318 22.6827 21.4318 22.6364V18.5455Z"
            fill={color[0]}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.3182 12C20.3601 12 18.7727 13.4652 18.7727 15.2727V21.4318C17.6847 22.3307 17 23.637 17 25.0909C17 27.8021 19.381 30 22.3182 30C25.2553 30 27.6364 27.8021 27.6364 25.0909C27.6364 23.637 26.9516 22.3307 25.8636 21.4318V15.2727C25.8636 13.4653 24.2763 12 22.3182 12ZM20.0466 22.9737C20.5065 22.5937 20.7727 22.0284 20.7727 21.4318V15.2727C20.7727 14.7175 21.3109 14 22.3182 14C23.3255 14 23.8636 14.7175 23.8636 15.2727V21.4318C23.8636 22.0284 24.1299 22.5937 24.5898 22.9737C25.263 23.5298 25.6364 24.2896 25.6364 25.0909C25.6364 26.5498 24.3045 28 22.3182 28C20.3319 28 19 26.5498 19 25.0909C19 24.2896 19.3734 23.5298 20.0466 22.9737Z"
            fill={color[0]}
          />
        </mask>
        <g mask="url(#icon-thermometer_mask0)">
          <rect
            width="24"
            height="24"
            transform="translate(10 9)"
            fill={color[0]}
          />
        </g>
      </g>
    </>
  )
}

export default Thermometer
