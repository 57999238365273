import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

/**
 * Component for backdrops eg. a black opaque overlay
 * when modal is active
 */
const Backdrop = props => {
  const __backdrop__ = classnames('cui-backdrop')

  return (
    <div
      className={__backdrop__}
      onClick={event => {
        event.stopPropagation()
        props.onClickHandler()
      }}
      style={{
        ...props.style,
      }}
    />
  )
}

Backdrop.propTypes = {
  onClickHandler: PropTypes.func,
  style: PropTypes.object,
}

export default Backdrop
