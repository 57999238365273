import React from 'react'

class SearchIcon extends React.PureComponent {
  render() {
    return (
      <g
        className="icon-search"
        stroke="none"
        strokeWidth="1"
        fillRule="evenodd"
      >
        <path d="M6.5 1C9.53757 1 12 3.46243 12 6.5C12 7.65723 11.6426 8.73099 11.0322 9.61691L14.7071 13.2929C15.0976 13.6834 15.0976 14.3166 14.7071 14.7071C14.3166 15.0976 13.6834 15.0976 13.2929 14.7071L9.61691 11.0322C8.73099 11.6426 7.65723 12 6.5 12C3.46243 12 1 9.53757 1 6.5C1 3.46243 3.46243 1 6.5 1ZM6.5 3C4.567 3 3 4.567 3 6.5C3 8.433 4.567 10 6.5 10C8.433 10 10 8.433 10 6.5C10 4.567 8.433 3 6.5 3Z" />
      </g>
    )
  }
}

export default SearchIcon
