import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const TextBlockInline = ({ children, hideIn, id }) => {
  const _TextBlockInline = classnames('cui-textblock-inline', {
    'hide-in-mobile': hideIn === 'mobile',
  })

  return (
    <span className={_TextBlockInline} id={id}>
      {children}
    </span>
  )
}

TextBlockInline.propTypes = {
  hideIn: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
}

export default TextBlockInline
