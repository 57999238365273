import React from 'react'
import PropTypes from 'prop-types'

const SummaryTitle = ({ children }) => {
  return <div className="title">{children}</div>
}

SummaryTitle.propTypes = {
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
}

export default SummaryTitle
