import React from 'react'
import PropTypes from 'prop-types'

class ElectricityIcon extends React.PureComponent {
  static propTypes = {
    color: PropTypes.array,
  }

  render() {
    return (
      <g
        className="icon-electricity"
        stroke="none"
        strokeWidth="1"
        fill={this.props.color[0]}
        fillRule="evenodd"
      >
        <circle cx="16" cy="16" r="16" fill="#48DCC0" fillOpacity="0.1" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.0101 22.8586L13.847 17H10C9.04785 17 8.63448 15.7952 9.38607 15.2106L18.3861 8.21062C19.1049 7.6515 20.1361 8.26606 19.9864 9.16438L19.1805 14H22C22.8909 14 23.3371 15.0771 22.7071 15.7071L14.7071 23.7071C14.0276 24.3866 12.8742 23.8099 13.0101 22.8586ZM18 16C17.3821 16 16.912 15.4451 17.0136 14.8356L17.5926 11.3615L12.9145 15H15C15.6086 15 16.076 15.539 15.99 16.1414L15.4142 20.1715L19.5858 16H18Z"
          fill="#48DCC0"
        />
      </g>
    )
  }
}

export default ElectricityIcon
