import React from 'react'
import PropTypes from 'prop-types'
import * as d3 from 'd3'
import { useChartXY } from './ChartXY'
import { useChart } from '../Chart'
import Portal from '../../Portal'
import Typo from '../../Typo'

const shape = d3
  .scaleOrdinal()
  .domain(['cross', 'dot', 'square', 'star', 'triangle', 'diamond'])
  .range([
    d3.symbolCross,
    d3.symbolCircle,
    d3.symbolSquare,
    d3.symbolStar,
    d3.symbolTriangle,
    d3.symbolDiamond,
  ])

const ChartSinePulse = ({ options, markerResolver }) => {
  const {
    sine: sineOptions,
    point: pointOptions,
    pointType: pointTypeOptions,
  } = options

  const ref = React.useRef()

  const { chartData } = useChart()
  const { parentHeight, parentWidth, innerPadding, sinePulseValues } =
    useChartXY()
  const { maxValue, fixedMaxValue } = sinePulseValues

  const [hoveredItem, setHoveredItem] = React.useState(null)

  const pointSymbol = React.useCallback(
    d3.symbol().type(function (d) {
      return shape(
        (d.type && pointTypeOptions
          ? pointTypeOptions[d.type]?.shape
          : pointOptions.shape) || 'dot'
      )
    }),
    [pointTypeOptions, pointOptions]
  )

  const handleMouseEnter = React.useCallback(
    function (event, d) {
      d3.select(this)
        .transition()
        .duration('100')
        .attr(
          'd',
          pointSymbol.size(
            d =>
              (d.type && pointTypeOptions
                ? pointTypeOptions[d.type]?.hoverSize
                : pointOptions.hoverSize) || 30
          )
        )
      setTimeout(() => {
        setHoveredItem({
          x: event.x,
          y: event.y,
          data: d,
        })
      })
    },
    [setHoveredItem, pointOptions, pointTypeOptions, pointSymbol]
  )

  const handleMouseLeave = React.useCallback(
    function (_, d) {
      d3.select(this)
        .transition()
        .duration('100')
        .attr(
          'd',
          pointSymbol.size(
            d =>
              (d.type && pointTypeOptions
                ? pointTypeOptions[d.type]?.size
                : pointOptions.size) || 20
          )
        )

      setHoveredItem(null)
    },
    [setHoveredItem, pointOptions, pointTypeOptions, pointSymbol]
  )

  const handleMouseEnterSineWave = React.useCallback(
    function (event, d) {
      d3.select(this).attr('fill', sineOptions?.color || '#ffc466')

      setTimeout(() => {
        setHoveredItem({
          x: event.x,
          y: event.y,
          data: {
            ...d,
            isSineWave: true,
          },
        })
      })
    },
    [sineOptions]
  )

  const handleMouseLeaveSineWave = React.useCallback(function (event, d) {
    d3.select(this).attr('fill', 'transparent')

    setHoveredItem(null)
  }, [])

  React.useEffect(() => {
    var x = d3
      .scaleLinear()
      .domain([0, 360])
      .range([0 + innerPadding, parentWidth - innerPadding])

    var y = d3
      .scaleLinear()
      .domain([-fixedMaxValue, fixedMaxValue])
      .range([parentHeight, 0])

    // render points
    const svg = d3.select(ref.current).html('')
    svg
      .selectAll('dot')
      .data(chartData)
      .enter()
      .append('path')
      .attr(
        'd',
        pointSymbol.size(
          d =>
            (d.type && pointTypeOptions
              ? pointTypeOptions[d.type]?.size
              : pointOptions.size) || 20
        )
      )
      .attr('transform', d => 'translate(' + x(d.x) + ',' + y(d.y) + ')')
      .style(
        'fill',
        d =>
          (d.type && pointTypeOptions
            ? pointTypeOptions[d.type]?.color
            : pointOptions.color) || '#00B0B2'
      )
      .on('mouseover', handleMouseEnter)
      .on('mouseleave', handleMouseLeave)

    // render sine wave
    const sineWaveData = d3.range(0, 361).map(i => {
      return { x: i, y: Math.sin((i * Math.PI) / 180) * maxValue }
    })
    svg
      .append('path')
      .datum(sineWaveData)
      .attr('fill', 'none')
      .attr('stroke', sineOptions?.color || '#ffc466')
      .attr('stroke-width', sineOptions?.size || 2)
      .attr(
        'd',
        d3
          .line()
          .x(function (d) {
            return x(d.x)
          })
          .y(function (d) {
            return y(d.y)
          })
          .curve(d3.curveBasis)
      )

    // render sine points
    svg
      .selectAll('dot')
      .data(sineWaveData)
      .enter()
      .append('circle')
      .attr('r', sineOptions?.size || 2)
      .attr('transform', d => 'translate(' + x(d.x) + ',' + y(d.y) + ')')
      .attr('fill', 'transparent')
      .on('mouseover', handleMouseEnterSineWave)
      .on('mouseleave', handleMouseLeaveSineWave)
  }, [
    parentWidth,
    parentHeight,
    innerPadding,
    handleMouseEnter,
    handleMouseLeave,
    chartData,
    fixedMaxValue,
    maxValue,
    sineOptions,
    pointOptions,
    pointTypeOptions,
    pointSymbol,
    handleMouseEnterSineWave,
    handleMouseLeaveSineWave,
  ])

  return (
    <>
      <g ref={ref} />
      {hoveredItem && (
        <Portal>
          <div
            className="cui-chart__sine-pulse-tooltip"
            style={{
              top: hoveredItem.y - 4,
              left: hoveredItem.x + 4,
            }}
          >
            {markerResolver ? (
              markerResolver(hoveredItem.data)
            ) : (
              <>
                <Typo variant="caption" isBold>
                  {hoveredItem.data.x}
                </Typo>
                <Typo variant="caption">{hoveredItem.data.y}</Typo>
              </>
            )}
          </div>
        </Portal>
      )}
    </>
  )
}

ChartSinePulse.propTypes = {
  options: PropTypes.object,
  markerResolver: PropTypes.func,
}

ChartSinePulse.defaultProps = {
  options: {
    sine: {
      color: '',
      size: 2,
    },
    point: {
      color: '',
      size: 30,
      hoverSize: 50,
      shape: 'dot', // one of ['cross', 'dot', 'square', 'star', 'triangle', 'diamond']
    },
  },
}

export default ChartSinePulse
