import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ChartLegendsItem from './ChartLegendsItem'

const ChartLegends = ({ children, items }) => {
  const _ChartLegends = classnames('cui-chart__legends')
  const renderLegends = () => {
    if (children) {
      return children
    }
    if (items.length > 0) {
      return items.map((itm, itmIdx) => {
        return (
          <ChartLegendsItem
            key={`lgnd-${itmIdx}-${itm.field}`}
            {...itm}
            id={++itmIdx}
          />
        )
      })
    }
    return null
  }
  return <div className={_ChartLegends}>{renderLegends()}</div>
}

ChartLegends.propTypes = {
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  width: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      color: PropTypes.string,
      field: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  id: PropTypes.string,
}

export default ChartLegends
