// organize-imports-ignore
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const AccordionContent = ({ children, className, isExpand }) => {
  const [height, setHeight] = React.useState(0)

  const accordionContentRef = React.useRef()

  const _AccordionContent = classnames('cui-accordion__item-content', className)
  const _ContentExpanded = classnames('content-styling', {
    'is-expand': isExpand,
  })
  React.useEffect(() => {
    setHeight(accordionContentRef.current?.scrollHeight)
  }, [isExpand])

  return (
    <div
      ref={accordionContentRef}
      className={_AccordionContent}
      style={{
        height: isExpand ? height : 14 + 'px',
      }}
      data-testid="accordion-item-content"
    >
      <div className={_ContentExpanded}>{children}</div>
    </div>
  )
}

AccordionContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isExpand: PropTypes.bool,
}
export default AccordionContent
