import React from 'react'
import PropTypes from 'prop-types'
import { useFormikContext } from 'formik'

import FieldInput from './FieldInput'

const FieldInputText = ({
  children,
  id,
  name,
  value,
  onChangeHandler,
  disabled,
  placeholder,
  capsOnChange,
  capsOnBlur,
  onlyNumber,
  maxLength,
  trailingZero,
  inputProps,
  onBlurHandler,
  loading,
  valuePrefix,
}) => {
  const {
    errors,
    touched,
    values,
    handleBlur: formikHandleBlur,
    handleChange: formikHandleChange,
  } = useFormikContext() || {}

  function handleChange(e) {
    if (onChangeHandler) {
      onChangeHandler(e)
    }
    formikHandleChange(e)
  }

  function handleBlur(e) {
    if (onBlurHandler) {
      onBlurHandler(e)
    }
    formikHandleBlur(e)
  }

  return (
    <div className="lm--input-group cui-field_group">
      <FieldInput
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disabled}
        onlyNumber={onlyNumber}
        capsOnChange={capsOnChange}
        capsOnBlur={capsOnBlur}
        trailingZero={trailingZero}
        maxLength={maxLength}
        spec={{
          placeholder,
          id: id,
          name: name,
        }}
        value={values && values[name] ? values[name] : value}
        hasError={touched[name] && !!errors[name]}
        inputProps={inputProps}
        loading={loading}
        valuePrefix={valuePrefix}
      >
        {children}
      </FieldInput>
    </div>
  )
}

FieldInputText.propTypes = {
  name: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  onChangeHandler: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  inputProps: PropTypes.object,
  loading: PropTypes.bool,
}

export default FieldInputText
