import React from 'react'
import PropTypes from 'prop-types'

import SummaryItemDesc from './SummaryDesc'
import SummaryItemPrice from './SummaryPrice'
import SummaryTotal from './SummaryTotal'
import SummaryItem from './SummaryItem'
import SummaryTitle from './SummaryTitle'

import './styles.scss'

/**
 * Summary component
 */
const Summary = ({ children }) => {
  return (
    <div className="cui-summary__content">
      {React.Children.map(children, child => React.cloneElement(child))}
    </div>
  )
}

Summary.Description = SummaryItemDesc
Summary.Price = SummaryItemPrice
Summary.Total = SummaryTotal
Summary.Item = SummaryItem
Summary.Title = SummaryTitle

Summary.propTypes = {
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
}

export default Summary
