import React from 'react'
import PropTypes from 'prop-types'

import Icons from '../Icons'

export default function LiveChat({
  mode,
  url,
  title,
  onToggle,
  offsetX,
  offsetY,
  width,
  height,
}) {
  const [chatToggle, setChatToggle] = React.useState(false)
  const _liveChatRef = React.useRef()

  const handleChatToggle = React.useCallback(() => {
    if (typeof onToggle === 'function') {
      onToggle()
    } else {
      if (mode === 'float') {
        setChatToggle(() => !chatToggle)
      } else {
        window.open(
          url,
          title,
          `width=${width}, height=${height}, left=${
            window.innerWidth / 2 - width / 2
          }, top=${
            window.innerHeight / 2 - height / 2
          },menubar=0,location=0,resizable=yes,scrollbars=yes,status=yes`
        )
      }
    }
  }, [url, mode])

  const handleCloseChat = () => {
    setChatToggle(false)
  }

  React.useEffect(() => {
    const defaultRight = 20
    if (_liveChatRef.current) {
      const right =
        window.innerWidth < 768
          ? `${
              offsetX > 70 || offsetX <= 0
                ? defaultRight
                : offsetX + defaultRight
            }px`
          : `${offsetX >= 70 ? offsetX - defaultRight - 50 : offsetX}px`
      _liveChatRef.current.style.right = right
    }
  }, [offsetX])

  return (
    <>
      <div ref={_liveChatRef} className="live-chat">
        <button className="live-chat__toggler" onClick={handleChatToggle}>
          {' '}
          <Icons.Chat width={30} />{' '}
        </button>
        {mode === 'float' && chatToggle && (
          <div className="live-chat__container">
            <div className="live-chat__head">
              <Icons.Chat width={25} />
              <h1>Live Chat</h1>
              <button className="live-chat__close" onClick={handleCloseChat}>
                x
              </button>
            </div>
            <iframe title={title} width={`${width}px`} src={url} />
          </div>
        )}
      </div>
    </>
  )
}

LiveChat.propTypes = {
  mode: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
  onToggle: PropTypes.func,
  offsetX: PropTypes.number,
  offsetY: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
}

LiveChat.defaultProps = {
  mode: 'float',
  url: '_blank',
  title: 'Live Chat',
  onToggle: null,
  offsetX: 0,
  offsetY: 0,
  width: 375,
  height: 450,
}
