import React from 'react'
import PropTypes from 'prop-types'

class GasIcon extends React.PureComponent {
  static propTypes = {
    color: PropTypes.array,
  }

  render() {
    return (
      <g
        className="icon-gas"
        stroke="none"
        strokeWidth="1"
        fill={this.props.color[0]}
        fillRule="evenodd"
      >
        <circle cx="16" cy="16" r="16" fill="#FF9D5A" fillOpacity="0.1" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 14C14.6766 14 14.1846 13.6157 13.681 12.9159C13.4733 12.6274 13.2824 12.3131 13.1148 11.9985C13.0151 11.8114 12.9471 11.6697 12.9155 11.5977L12.3182 10.2384L11.2831 11.3029C9.13795 13.5088 8 15.5805 8 18C8 21.6885 11.8765 25 16 25C20.1235 25 24 21.6885 24 18C24 13.968 22.1 10.6242 18.5695 8.17803L17 7.09058V9.00001C17 11.9395 15.9563 14 15 14ZM13.0082 22.2261C13.0027 22.1526 13 22.0772 13 22L12.9997 21.8999L12.999 21.7914C12.996 21.3835 12.9988 21.1932 13.0191 20.942C13.1091 19.8301 13.5487 18.9436 14.5402 18.2C14.8152 17.9938 15.1124 17.8154 15.4279 17.6624C16.3745 17.2033 17.2997 17.0282 17.9586 17.0009L19 16.9577V20C19 20.8832 18.7764 21.7971 18.3478 22.528C20.4154 21.6881 22 19.8483 22 18C22 15.2295 20.9331 12.8735 18.8597 10.9611C18.4246 13.8904 17.0052 16 15 16C13.8517 16 12.9034 15.2593 12.0576 14.0841C11.9679 13.9594 11.8829 13.8351 11.803 13.7125C10.5979 15.1769 10 16.5361 10 18C10 19.6511 11.2645 21.2954 13.0082 22.2261ZM15.9896 22H16C16.4693 22 17 20.9667 17 20V19.1941C16.7589 19.2651 16.5233 19.3539 16.3006 19.4619C16.0965 19.5609 15.9088 19.6736 15.7402 19.8C15.2348 20.1791 15.0587 20.5342 15.0126 21.1034C14.9986 21.2766 14.9963 21.4273 14.9989 21.7767C14.9997 21.8779 14.9997 21.8856 14.9999 21.9764C15.1691 21.9928 15.4645 22 15.9896 22Z"
          fill="#FF9D5A"
        />
      </g>
    )
  }
}

export default GasIcon
