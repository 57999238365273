import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import debounce from 'lodash/debounce'
import InfiniteScroll from 'react-infinite-scroll-component'
import Icons from '../Icons'
import { useTheme } from '../Theme'

function searchKeyword(str, keyword) {
  if (!keyword) {
    return {
      isMatch: true,
      textArr: [
        {
          text: str,
          isHighlight: false,
        },
      ],
    }
  }
  const regex = new RegExp(keyword, 'gi') // search for all instances
  let isMatch = false
  let match
  const arr = []
  let curIndex = 0

  while ((match = regex.exec(str)) !== null) {
    isMatch = true
    const prevText = str.slice(curIndex, match.index)
    if (prevText) {
      arr.push({
        text: prevText,
        isHighlight: false,
      })
    }
    arr.push({
      text: str.slice(match.index, regex.lastIndex),
      isHighlight: true,
    })
    curIndex = regex.lastIndex
  }
  const lastText = str.slice(curIndex)
  if (lastText) {
    arr.push({
      text: lastText,
      isHighlight: false,
    })
  }
  return { isMatch, textArr: arr }
}

const HighlightSearchMenu = ({
  options,
  onChange,
  className,
  onSearch,
  optionsMaxHeight,
  infiniteScrollProps,
  debounceTime,
}) => {
  const theme = useTheme()

  const [keyword, setKeyword] = React.useState('')
  const [items, setItems] = React.useState(options)
  const [hasMore, setHasMore] = React.useState(false)
  const [page, setPage] = React.useState(0)

  let latestKeyword = ''
  const handleSearch = React.useCallback(
    debounce(searchKeyword => {
      if (onSearch) {
        latestKeyword = searchKeyword
        if (searchKeyword) {
          setHasMore(true)
          setPage(1)
          setItems([])
          onSearch({ keyword: searchKeyword, page: 0 }, result => {
            if (latestKeyword === searchKeyword) {
              setTimeout(() => setItems(result))
              if (!result.length) {
                setHasMore(false)
              }
            }
          })
        } else {
          setItems([])
          setHasMore(false)
        }
      }
    }, debounceTime),
    []
  )

  const handleChangeKeyword = event => {
    const searchKeyword = event.target.value
    handleSearch(searchKeyword)
    setKeyword(searchKeyword)
  }

  const handleLoadMore = () => {
    onSearch({ keyword, page }, result => {
      if (!result.length) {
        setHasMore(false)
      } else {
        setItems([...items, ...result])
      }
    })
    setPage(page + 1)
  }

  return (
    <div
      className={classnames('cui-highlight-search-menu', className)}
      style={{
        '--cuiHighlightSearchMenuBg': theme.colors.background.level1,
        '--cuiHighlightSearchMenuHoverBg': theme.colors.background.level2,
        '--cuiHighlightSearchMenuDivider': theme.colors.divider,
        '--cuiHighlightSearchMenuText': theme.colors.text.primary,
        '--cuiHighlightSearchMenuPrimary': theme.colors.primary.main,
      }}
    >
      <div className="cui-highlight-search-menu__search">
        <Icons.Search width={18} height={18} />
        <input value={keyword} onChange={handleChangeKeyword} />
      </div>
      <div
        className="cui-highlight-search-menu__options"
        id="cui-highlight-search-menu-scrollable"
        style={{
          maxHeight: optionsMaxHeight,
        }}
      >
        <InfiniteScroll
          dataLength={items.length}
          next={handleLoadMore}
          hasMore={hasMore}
          loader={
            <div className="cui-highlight-search-menu__loading">
              <Icons.Spinloader width={30} color={[theme.colors.icon]} />
            </div>
          }
          scrollableTarget="cui-highlight-search-menu-scrollable"
          scrollThreshold={0.9}
          {...infiniteScrollProps}
        >
          {items.map(option => {
            const { isMatch, textArr } = searchKeyword(option.label, keyword)
            if (!isMatch) {
              return null
            }
            return (
              <div
                key={option.value}
                className="cui-highlight-search-menu__item"
              >
                <span onClick={() => onChange(option)}>
                  {textArr.map((text, index) => {
                    if (text.isHighlight) {
                      return (
                        <span
                          key={index}
                          className="cui-highlight-search-menu__text-highlight"
                        >
                          {text.text}
                        </span>
                      )
                    }
                    return text.text
                  })}
                </span>
              </div>
            )
          })}
        </InfiniteScroll>
      </div>
    </div>
  )
}

HighlightSearchMenu.propTypes = {
  options: PropTypes.array,
  onChange: PropTypes.func,
  className: PropTypes.string,
  onSearch: PropTypes.func,
  optionsMaxHeight: PropTypes.number,
  infiniteScrollProps: PropTypes.object,
  debounceTime: PropTypes.number,
}

HighlightSearchMenu.defaultProps = {
  options: [],
  debounceTime: 500,
}

export default HighlightSearchMenu
