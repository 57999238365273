// organize-imports-ignore
import React from 'react'
import PropTypes from 'prop-types'

export default function PdfIcon({ color }) {
  return (
    <g
      className="icon-filepdf"
      stroke="none"
      strokeWidth="1"
      fillRule="evenodd"
    >
      <circle cx="16" cy="16" r="16" fillOpacity="0.1" />

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 2C0.5 0.89543 1.39543 0 2.5 0H11.9142L16.5 4.58579V16C16.5 17.1046 15.6046 18 14.5 18H2.5C1.39543 18 0.5 17.1046 0.5 16V2ZM10.5 2H2.5V16H14.5V6H11.5C10.9477 6 10.5 5.55228 10.5 5V2ZM5.83416 10.5C6.54416 10.5 6.96416 10.94 6.96416 11.63C6.96416 12.31 6.51916 12.775 5.83916 12.775H5.42916V14H4.57416V10.5H5.83416ZM6.08916 11.63C6.08916 11.395 5.95416 11.235 5.71916 11.235H5.42916V12.04H5.70916C5.95916 12.04 6.08916 11.87 6.08916 11.63ZM7.36713 14V10.5H8.39713C9.52213 10.5 10.2421 11.155 10.2421 12.25C10.2421 13.345 9.52213 14 8.39713 14H7.36713ZM9.35213 12.25C9.35213 11.58 8.97213 11.24 8.39713 11.24H8.22213V13.26H8.39713C8.97213 13.26 9.35213 12.92 9.35213 12.25ZM12.4124 12.665V11.905H11.5424V11.265H12.5374V10.5H10.6874V14H11.5424V12.665H12.4124Z"
        transform="translate(10 9.5) scale(0.7)"
      />
    </g>
  )
}
PdfIcon.defaultProps = {
  color: ['#00B0B2'],
}

PdfIcon.propTypes = {
  color: PropTypes.arrayOf(PropTypes.string),
}
