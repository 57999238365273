import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Modal from '../Modal'
import Button from '../Button'
import Form from '../Form'

const PopupConfirmation = ({
  visible,
  onConfirm,
  onClose,
  children,
  confirmButton,
  closeButton,
  formProps,
  open,
  className,
  ...restProps
}) => {
  const [active, setActive] = React.useState(visible)
  const [shouldRender, setShouldRender] = React.useState(open)

  React.useEffect(() => {
    setActive(visible)
  }, [visible])

  React.useEffect(() => {
    if (open) {
      setShouldRender(true)
      setActive(true)
    } else {
      setTimeout(() => {
        setShouldRender(false)
      }, 100)
    }
  }, [open])

  if (!shouldRender) {
    return null
  }

  const handleClose = () => {
    onClose()
    setActive(false)
  }

  return (
    <Modal
      {...restProps}
      visible={active}
      onEscapeKeyDown={handleClose}
      handleClose={handleClose}
      className={classnames('cui-popup', className)}
    >
      <Form onSubmit={onConfirm} initialValues={{}} {...formProps}>
        <Form.Wrapper>{children}</Form.Wrapper>
        <Form.CTA isCenter>
          {closeButton && (
            <Button
              variant={!confirmButton ? 'solid' : 'outlined'}
              onClickHandler={handleClose}
              type="button"
              {...closeButton}
            >
              {closeButton.label}
            </Button>
          )}
          {confirmButton && (
            <Button variant="solid" type="submit" {...confirmButton}>
              {confirmButton.label}
            </Button>
          )}
        </Form.CTA>
      </Form>
    </Modal>
  )
}

PopupConfirmation.defaultProps = {
  visible: true,
  confirmButton: {
    label: 'Confirm',
  },
  closeButton: {
    label: 'Cancel',
  },
  open: true,
}

PopupConfirmation.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.bool,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  confirmButton: PropTypes.shape({
    label: PropTypes.node.isRequired,
  }),
  closeButton: PropTypes.shape({
    label: PropTypes.node.isRequired,
  }),
  open: PropTypes.bool,
}

export default PopupConfirmation
