import React, { useState } from 'react'
import classnames from 'classnames'

import Portal from '../Portal'
import Backdrop from '../Backdrop'
import Icons from '../Icons'
import { useTheme } from '../Theme'

const ListSort = ({ data, onSortSubmit, spec }) => {
  const theme = useTheme()
  const [showSort, setShowSort] = useState(false)
  const [mountSortList, setMountSortList] = useState(false)
  const [selectedSort, setSort] = useState('')

  const sortListRef = React.useRef(null)

  const _ListSort = classnames('cui-list__sort', {
    open: showSort,
  })

  const _ListSortPortal = classnames('cui-list__sort--list', {
    open: showSort,
    close: !showSort,
  })

  const handleSort = value => {
    setSort(value)
    onSortSubmit({ [spec.name]: value })
    setShowSort(false)
  }

  const handleOpen = () => {
    setShowSort(true)
    setMountSortList(true)
  }

  const handleClose = () => setShowSort(false)
  const handleClosingEnd = React.useCallback(() => {
    if (!showSort) {
      handleUnmount()
    }
  }, [showSort])

  const handleUnmount = () => setMountSortList(false)

  React.useEffect(() => {
    if (sortListRef.current) {
      sortListRef.current.addEventListener('animationend', handleClosingEnd)
    }
  }, [handleClosingEnd])

  return (
    <div className={_ListSort}>
      <div className="cui-list__sort--icon" onClick={handleOpen}>
        <span className="text">Sort</span>
        <span>
          <Icons.Sort width={10} height={10} />
        </span>
      </div>
      {mountSortList && (
        <Portal>
          <div
            className={_ListSortPortal}
            ref={sortListRef}
            style={{
              '--cuiListItemBg': theme.colors.background.level1,
              '--cuiListDivider': theme.colors.grey[200],
            }}
          >
            <div className="cui-list__sort--title">Sort By</div>
            {data.map(item => (
              <div
                key={item.value}
                className="cui-list__sort--item"
                onClick={() => handleSort(item.value)}
              >
                {item.label}
                {selectedSort === item.value && (
                  <span className="selected-icon">
                    <Icons.Checked width={20} height={20} />
                  </span>
                )}
              </div>
            ))}
          </div>
          <Backdrop style={{ zIndex: 1900 }} onClickHandler={handleClose} />
        </Portal>
      )}
    </div>
  )
}

export default ListSort
