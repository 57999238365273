import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const TextBlockTitle = props => {
  const _TextBlockTitle = classnames('cui-textblock-title')
  return (
    <>
      <h4 className={_TextBlockTitle} id={props.id}>
        {props.children}
      </h4>
    </>
  )
}

TextBlockTitle.propTypes = {
  id: PropTypes.string,
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
}

export default TextBlockTitle
