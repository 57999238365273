import React from 'react'
import PropTypes from 'prop-types'

const SummaryItemPrice = ({ children }) => {
  return <div className="cui-summary__item--price">{children}</div>
}

SummaryItemPrice.propTypes = {
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
}

export default SummaryItemPrice
