import React, { useState, useEffect, useRef } from 'react'
import * as d3 from 'd3'

import PropTypes from 'prop-types'
import { useChartXY } from './ChartXY'
import { useChart } from '../Chart'

const ChartLine = ({
  fields,
  dashed,
  color,
  width,
  curved,
  filled,
  fillColor,
  stepped,
  dot,
  hasStartLine,
}) => {
  const { chartData } = useChart()
  const lineRef = React.createRef()
  const isArrColor = Array.isArray(color)
  const {
    parentHeight,
    interval,
    maxValue,
    otherYMaxValue,
    minValue,
    otherYMinValue,
    y,
    otherY,
    actions: { getDivisionX },
  } = useChartXY()

  const gradientIdRef = useRef(new Date().getTime().toString())

  fields = fields || y

  const isOtherY = fields && fields[0] && otherY && otherY.includes(fields[0])

  let yMaxValue = maxValue
  if (isOtherY) {
    yMaxValue = otherYMaxValue
  }

  const ratio = yMaxValue
    ? (parentHeight - parentHeight / interval) /
      (yMaxValue - (isOtherY ? otherYMinValue : minValue))
    : yMaxValue
  const data = getDivisionX()

  const [lineFields] = useState(fields)

  const lineData = lineFields.map(key => {
    const valueData = {}
    valueData[key] = []
    const pointData = data.map(item => {
      const itemData = isOtherY ? item.otherYData : item.data
      if (!itemData) return null
      const typeObj = itemData.reduce((acc, ele) => {
        if (ele.value !== null || ele.value !== undefined) {
          acc[ele.key] = ele.value
        }
        return acc
      }, {})
      valueData[key].push(typeObj[key])
      return [
        item.pos,
        parentHeight -
          ((typeObj[key] - (isOtherY ? otherYMinValue : minValue)) * ratio ||
            0),
      ].join(',')
    })
    return {
      name: key,
      value: pointData,
      valueData: valueData[key],
    }
  })

  useEffect(() => {
    const points = lineData[0].value.map((dataPoint, index) => {
      const pairPoint = dataPoint.split(',')

      return {
        id: index + 1,
        x: Number(pairPoint[0]),
        y: Number(pairPoint[1]),
        dateTime: chartData[index]?.x?.value,
        pointName: lineData[0].name,
        valueData: lineData[0].valueData[index],
        allValuesData: data[index]?.data,
      }
    })
    let linePoints = points

    if (hasStartLine) {
      linePoints = [
        {
          id: -1,
          x: 0,
          y: points[0]?.y,
          dateTime: chartData?.[0]?.x?.value || '',
          pointName: lineData[0].name,
          valueData: 0,
          allValuesData: [
            {
              ...data?.[0]?.data?.[0],
            },
          ],
        },
        ...points,
      ]
    }

    const GenCurveLine = d3
      .line()
      .x(p => p.x)
      .y(p => p.y)
      .curve(
        curved ? d3.curveCatmullRom : stepped ? d3.curveStep : d3.curveLinear
      )
      .defined(d => d.valueData !== null)

    const GenFilledArea = d3
      .area()
      .x(d => d.x)
      .y0(parentHeight)
      .y1(d => d.y)
      .curve(
        curved ? d3.curveCatmullRom : stepped ? d3.curveStep : d3.curveLinear
      )
      .defined(d => d.valueData !== null)

    const chartSVG = d3.select(lineRef.current)

    if (ratio) {
      chartSVG
        .selectAll('path')
        .data(lineData)
        .join('path')
        .attr('class', 'lineChart')
        .attr(
          'd',
          filled ? GenFilledArea(linePoints) : GenCurveLine(linePoints)
        )
        .attr('fill', filled ? `url(#${gradientIdRef.current})` : 'none')
        .attr('stroke-width', width)
        .attr('stroke', filled ? 'none' : isArrColor ? color[0] : color)
        .attr('stroke-dasharray', dashed ? 4 : 0)

      stepped &&
        chartSVG
          .selectAll('.line')
          .data(lineData)
          .join('path')
          .attr('class', 'line')
          .attr('d', GenCurveLine(linePoints))
          .attr('stroke-width', width)
          .attr('fill', 'none')
          .attr('stroke', isArrColor ? color[0] : color)
          .attr('stroke-dasharray', dashed ? 4 : 0)

      if (dot) {
        // Add dots
        chartSVG
          .selectAll('circle')
          .data(points)
          .join('circle')
          .attr('fill', dot.fill || (isArrColor ? color[0] : color))
          .attr('stroke', dot.stroke || 'none')
          .attr('cx', d => d.x)
          .attr('cy', d => d.y)
          .attr('r', dot.size || 3)
      }
    }
  }, [
    color,
    curved,
    dashed,
    data,
    lineData,
    lineRef,
    parentHeight,
    ratio,
    width,
    chartData,
    filled,
    isArrColor,
    fields,
    stepped,
    dot,
    hasStartLine,
  ])

  const gradientColor = fillColor || color
  return (
    <>
      <g ref={lineRef}>
        {filled && (
          <defs>
            <linearGradient
              id={gradientIdRef.current}
              x1="0%"
              y1="0%"
              x2="0%"
              y2="100%"
            >
              <stop
                offset="0%"
                stopColor={
                  Array.isArray(gradientColor)
                    ? gradientColor[0]
                    : gradientColor
                }
              />
              <stop
                offset="100%"
                stopColor={
                  Array.isArray(gradientColor)
                    ? gradientColor[1]
                    : gradientColor
                }
              />
            </linearGradient>
          </defs>
        )}
      </g>
    </>
  )
}

ChartLine.propTypes = {
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fields: PropTypes.array,
  dashed: PropTypes.bool,
  curved: PropTypes.bool,
  filled: PropTypes.bool,
  fillColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  stepped: PropTypes.bool,
  dot: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  hasStartLine: PropTypes.bool,
}

ChartLine.defaultProps = {
  color: '#818b92',
  width: 1,
  dashed: false,
  hasStartLine: false,
}

export default ChartLine
