import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useFormikContext } from 'formik'

import FieldLabel from '../FieldLabel'
import { Hint } from '../FieldMisc'
import FieldError from '../FieldError'

const BACKSPACE = 8
const LEFT_ARROW = 37
const RIGHT_ARROW = 39
const DELETE = 46

class FieldInputBox extends React.Component {
  state = {
    activeInput: 0, // index for box focus
    boxValue: Array.apply(null, Array(this.props.length)).map(function () {
      return ''
    }),
  }

  // Focus on next input
  focusNextInput = () => {
    const { activeInput } = this.state
    this.focusInput(activeInput + 1)
  }

  // Focus on previous input
  focusPrevInput = () => {
    const { activeInput } = this.state
    this.focusInput(activeInput - 1)
  }

  // set focus to index
  focusInput = input => {
    const { length } = this.props
    const activeInput = Math.max(Math.min(length - 1, input), 0)
    document.getElementById(this.props.spec.name + activeInput).focus()
    this.setState({ activeInput })
  }

  shiftFocus = event => {
    if (
      // if delete / backspace pressed
      event.keyCode === DELETE ||
      event.keyCode === BACKSPACE ||
      event.keyCode === LEFT_ARROW
    ) {
      this.focusPrevInput()
    } else if (
      // if right arrow pressed || digit entered & value not empty
      (this.isValidInput(event.target.value) && event.target.value !== '') ||
      event.keyCode === RIGHT_ARROW
    ) {
      this.focusNextInput()
    }
  }

  // check input is digit or empty space "" (for backspace)
  isValidInput = value => {
    const valid = this.props.numeric
      ? !isNaN(parseInt(value, 10)) || value === ''
      : true
    if (value === ' ') return false
    return valid
  }

  handleOnInput = event => {
    if (this.isValidInput(event.target.value)) {
      this.focusNextInput()
    }
  }

  setFocus = (index, event) => {
    this.setState({ activeInput: index })
    event.target.select()
  }

  render() {
    return (
      <InputBox
        length={this.props.length}
        decimal={this.props.decimal || 0}
        disabled={this.props.disabled}
        spec={this.props.spec}
        setFocus={this.setFocus}
        shiftFocus={this.shiftFocus}
        minVal={this.props.minVal}
        maxVal={this.props.maxVal}
        numeric={this.props.numeric}
      >
        {this.props.children}
      </InputBox>
    )
  }
}

const InputBox = ({
  children,
  spec,
  length,
  decimal,
  disabled,
  setFocus,
  shiftFocus,
  numeric,
}) => {
  const {
    errors,
    setFieldTouched,
    setFieldValue,
    values,
    touched,
  } = useFormikContext()

  const decimalPoint = length - decimal
  // check input is digit or empty space "" (for backspace)
  const isValidInput = value => {
    const valid = numeric ? !isNaN(parseInt(value, 10)) || value === '' : true
    if (value === ' ') return false
    return valid
  }

  const handleInputBoxChange = e => {
    if (isValidInput(e.target.value)) {
      setFieldValue(e.target.name, e.target.value)
    } else {
      document.getElementById(e.target.id).value = ''
    }
  }

  useEffect(() => {
    const pattern = new RegExp(`${spec.name}_input`)
    const selectedFieldsValues = Object.keys(values).filter((value) => pattern.test(value)).map(name => ({ [name]: values[name] }));
    const isAllInputsFilled = selectedFieldsValues.every(value => Object.values(value)[0])
    const inputBoxValue = []
    if (selectedFieldsValues.length > 0) {
      for (let i = 0; i < length; i++) {
        if (decimal > 0 && length - i === decimal) {
          inputBoxValue.push('.')
        }
        inputBoxValue.push(Object.values(selectedFieldsValues[i])[0])
      }
    }
    if (isAllInputsFilled) {
      setFieldValue(spec.name, inputBoxValue.join(''))
    } else {
      setFieldValue(spec.name, '')
    }
  }, [setFieldValue, spec.name, values])

  const handleInputBoxFocus = i => e => {
    setFocus(i, e)
  }

  const handleInputBoxShift = e => {
    shiftFocus(e)
  }

  const handleInputBoxBlur = e => {
    setFieldTouched(spec.name, true)
  }

  const renderMisc = () => {
    return React.Children.map(children, (child, childIdx) => {
      if (child.type === Hint || child.type === FieldError) {
        return React.cloneElement(child)
      }
    })
  }

  const renderError = () => {
    let boxVal = ''
    for (let i = 0; i < length; i++) {
      if (i === decimalPoint) {
        boxVal = boxVal + '.'
      }
      if (
        values[`${spec.name}_input_${i}`] === '' &&
        touched &&
        touched[spec.name]
      ) {
        return <FieldError>Please enter all required readings</FieldError>
      }
      boxVal = boxVal + values[`${spec.name}_input_${i}`]
    }
    boxVal = parseFloat(boxVal)

    if (errors && errors[spec.name] && touched && touched[spec.name]) {
      return <FieldError>{errors[spec.name]}</FieldError>
    }
  }

  const renderBoxes = () => {
    const boxes = []

    for (let i = 0; i < length; i++) {
      boxes.push(
        <div
          key={spec.name + '-' + i}
          className={classnames({
            'is-decimal': i === length - decimal,
          })}
        >
          <input
            name={`${spec.name}_input_${i}`}
            id={spec.name + i}
            type="text"
            className={classnames('lm-box-input cui-box-input', {
              'is-error':
                (errors &&
                  errors[spec.name] &&
                  touched &&
                  touched[spec.name]) ||
                (values[`${spec.name}_input_${i}`] === '' &&
                  touched &&
                  touched[spec.name]),
            })}
            data-testid={'test-fieldinput-box-' + i}
            maxLength={1}
            autoComplete="off"
            onChange={handleInputBoxChange}
            onFocus={handleInputBoxFocus(i)}
            onKeyUp={handleInputBoxShift} // for backspace/del/arrow
            onBlur={handleInputBoxBlur}
            disabled={disabled}
            value={values[`${spec.name}_input_${i}`] || ''}
          />
        </div>
      )
    }

    return boxes
  }

  const renderField = () => {
    const _FieldInputBox = classnames('lm-box cui-box')
    const _FieldInput = classnames('lm--input cui-field')
    const label = React.Children.map(children, (child, childIdx) => {
      if (child.type === FieldLabel) {
        return <FieldLabel {...child.props} />
      }
      return null
    })

    return (
      <div className={_FieldInput}>
        {label}
        <div className={_FieldInputBox}>{renderBoxes()}</div>
      </div>
    )
  }

  return (
    <>
      {renderField()}
      {renderMisc()}
      {renderError()}
    </>
  )
}

FieldInputBox.propTypes = {
  spec: PropTypes.object.isRequired,
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  length: PropTypes.number,
  numeric: PropTypes.bool,
  decimal: PropTypes.number,
  disabled: PropTypes.bool,
  minVal: PropTypes.number,
  maxVal: PropTypes.number,
}

InputBox.propTypes = {
  spec: PropTypes.object.isRequired,
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  length: PropTypes.number,
  decimal: PropTypes.number,
  disabled: PropTypes.bool,
  setFocus: PropTypes.func,
  shiftFocus: PropTypes.func,
  numeric: PropTypes.bool,
}

export default FieldInputBox
