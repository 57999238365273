import React from 'react'
import PropTypes from 'prop-types'

const FlatListItem = ({ children }) => {
  return <li className="cui-flat-list__item">{children}</li>
}

FlatListItem.propTypes = {
  children: PropTypes.node,
}

export default FlatListItem
