import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useFormikContext } from 'formik'

import FieldInput from './FieldInput'
import FieldError from '../FieldError'

const FieldInputSplit = ({
  children,
  onChangeHandler,
  onBlurHandler,
  fields,
  disabled,
  capsOnChange,
}) => {
  const {
    errors,
    values,
    handleChange: formikHandleChange,
    handleBlur: formikHandleBlur,
  } = useFormikContext() || {}

  const _FieldInputSplit = classnames('cui-input_split', {
    'is-error': fields.some(field => errors[field.name]),
  })

  function handleChange(e) {
    if (onChangeHandler) {
      onChangeHandler(e)
    }
    formikHandleChange(e)
  }

  function handleBlur(e) {
    if (onBlurHandler) {
      onBlurHandler(e)
    }
    formikHandleBlur(e)
  }

  const renderErrors = () => {
    return fields.map((field, idx) => {
      if (errors[field.name]) {
        return <FieldError key={idx} name={field.name} />
      }
      return null
    })
  }

  function renderSplitFields() {
    // TODO: split field render logic here
    const fieldInputs = fields.map((field, fieldIdx) => {
      return (
        <FieldInput
          key={`split-input-field--${fieldIdx}-${field.name}`}
          onChange={handleChange}
          onBlur={handleBlur}
          spec={{
            id: field.name,
            name: field.name,
            placeholder: field.placeholder,
            // ...field,
          }}
          {...field}
          disabled={field.disabled}
          value={values[field.name]}
          capsOnChange={capsOnChange}
        />
      )
    })
    return (
      <div className="lm--input-group cui-field_group">
        <FieldInput disabled={disabled} className={_FieldInputSplit}>
          {children}
          {fieldInputs}
        </FieldInput>
        {renderErrors()}
      </div>
    )
  }

  return <>{renderSplitFields()}</>
}

FieldInputSplit.propTypes = {
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  maxLength: PropTypes.number,
  onChangeHandler: PropTypes.func,
  capsOnChange: PropTypes.bool,
}

export default FieldInputSplit
