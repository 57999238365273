import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import HeaderLogo from './HeaderLogo'
import HeaderActions from './HeaderActions'
import Navigation from '../Navigation'

import Brand from '../Brand'
import { useTheme } from '../Theme'

import Logo from '../Logo'
import './styles.scss'

const validTypes = [
  HeaderLogo,
  HeaderActions,
  Navigation,
  Brand,
  Logo,
  Logo.SPgroup,
]
export const isValidType = (type, checkWith) => {
  if (checkWith) {
    return type === checkWith
  }
  return validTypes.some(t => t === type)
}

const Header = ({ children, className, style }) => {
  const theme = useTheme()
  const _Header = classnames('lm--header cui-header', className)

  const renderHeaderContent = () => {
    return (
      <div className="lm--header__container cui-header__container">
        {React.Children.map(children, (child, childIdx) => {
          if (child) {
            if (isValidType(child.type)) {
              return React.cloneElement(child)
            }
          }
          return null
        })}
      </div>
    )
  }

  const renderHeaderExtra = () => {
    return (
      <div className="lm--header__ext cui-header__ext">
        {React.Children.map(children, (child, childIdx) => {
          if (child) {
            if (isValidType(child.type)) {
              return null
            }
            return child
          }
          return null
        })}
      </div>
    )
  }

  return (
    <header
      className={_Header}
      style={{
        '--cuiHeaderBg': theme.colors.header.background,
        '--cuiHeaderShadow': theme.colors.header.boxShadow,
        '--cuiHeaderDivider': theme.colors.header.divider,
        ...style,
      }}
    >
      {renderHeaderContent()}
      {renderHeaderExtra()}
    </header>
  )
}

Header.Logo = HeaderLogo
Header.ActionGroup = HeaderActions
Header.Brand = Brand

Header.propTypes = {
  className: PropTypes.string,
}

export default Header
