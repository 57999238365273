import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const SliderItem = ({ children, className, ...restProps }) => {
  return (
    <div className={classnames('cui-slider__item', className)} {...restProps}>
      {children}
    </div>
  )
}

SliderItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}

export default SliderItem
