import React from 'react'
import PropTypes from 'prop-types'

class QuestionAnswerIcon extends React.PureComponent {
  static propTypes = {
    color: PropTypes.array,
  }

  render() {
    return (
      <g
        className="icon-question-answer"
        stroke="none"
        strokeWidth="1"
        fill={this.props.color[0]}
        fillRule="evenodd"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M107.25 46.2894C107.25 46.2413 107.249 46.1939 107.249 46.1458C107.239 45.4614 107.198 44.7724 107.124 44.0775C106.849 41.4853 106.133 38.9625 105.069 36.5124C104.286 34.708 103.315 32.943 102.198 31.2244C100.863 29.1699 99.3215 27.1812 97.6388 25.2766C96.3704 23.8416 95.0214 22.4541 93.6153 21.1263C93.1235 20.7123 92.6134 20.3092 92.1102 19.9085C90.493 18.6213 88.7479 17.4252 86.9234 16.3261C84.0049 14.5687 80.8887 13.0593 77.7021 11.8284C75.1637 10.8484 72.5777 10.0461 69.9859 9.43703C66.226 8.55255 62.2441 8.1874 58.1232 8.25869C54.7992 8.31623 51.358 8.67958 47.8576 9.37334C44.9325 9.95382 41.9659 10.7652 39.0311 11.8241C36.7209 12.6576 34.4302 13.6445 32.2125 14.7913C30.1374 16.1687 28.1259 17.7462 26.2353 19.4739C23.7271 21.7654 21.4348 24.3222 19.4721 27.0032C17.8293 29.2459 16.4171 31.5747 15.2677 33.8963C13.1722 38.0906 12.1838 42.6212 12.2534 46.9738C12.2995 50.9311 13.1898 54.9088 14.8725 58.6312C16.3878 61.9969 18.544 65.153 21.183 67.9356C23.109 69.9677 25.2884 71.8018 27.643 73.3902C28.339 73.8592 29.0491 74.3068 29.7728 74.7321C31.9929 75.8818 34.3039 76.8941 36.6667 77.7648C39.6688 78.8711 42.7531 79.7505 45.8574 80.3983C49.5725 81.1735 53.3149 81.6183 57.0324 81.725C57.5862 81.7406 58.1391 81.7493 58.692 81.75H58.8549C60.776 81.7475 62.6902 81.6541 64.5979 81.4674C66.6473 81.2672 68.6263 80.9658 70.5421 80.5727C73.5596 79.9532 76.4187 79.1074 79.1347 78.0702C82.545 76.7674 85.7328 75.1627 88.6815 73.3247C90.5252 72.1745 92.274 70.9329 93.9127 69.6156C94.422 69.2059 94.9215 68.7886 95.4088 68.3648C95.9194 67.9212 96.4173 67.4702 96.9021 67.0121C98.5437 65.4603 100.035 63.8281 101.343 62.133C103.135 59.8103 104.58 57.3704 105.59 54.8433C106.653 52.1844 107.23 49.4289 107.25 46.5895V46.2894Z"
          fill="#F8FCFF"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M57.4856 38C58.322 38 59 38.678 59 39.5144V59.3277C59 60.1641 58.322 60.8421 57.4856 60.8421H35.0577L29 66V59.3277V56.4211V39.5144C29 38.678 29.678 38 30.5144 38H57.4856Z"
          fill="url(#paint0_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M44.5 52.9999C42.5702 52.9999 41 51.4298 41 49.4999C41 47.57 42.5702 45.9999 44.5 45.9999C46.4299 45.9999 48.0001 47.57 48.0001 49.4999C48.0001 49.912 47.9279 50.3074 47.7964 50.6749L47.5606 50.4392C46.9749 49.8533 46.0252 49.8533 45.4393 50.4392C44.8535 51.025 44.8535 51.9747 45.4393 52.5604L45.6751 52.7963C45.3076 52.9277 44.9122 52.9999 44.5 52.9999ZM50.5606 53.4393L50.031 52.9096C50.6446 51.9175 51 50.7497 51 49.5C51 45.9159 48.0841 43 44.5 43C40.9159 43 38 45.9159 38 49.5C38 53.084 40.9159 56 44.5 56C45.7497 56 46.9177 55.6446 47.9096 55.0309L48.4393 55.5606C48.7323 55.8534 49.1161 56 49.4999 56C49.8837 56 50.2677 55.8535 50.5605 55.5606C51.1465 54.9747 51.1465 54.0249 50.5606 53.4393Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M52.7308 22C51.7749 22 51 22.7749 51 23.7308V47.1903C51 48.1462 51.7749 48.9211 52.7308 48.9211H78.9327L86 55V47.1903V43.7105V23.7308C86 22.7749 85.2251 22 84.2692 22H52.7308Z"
          fill="url(#paint1_linear)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M66 39L68.9999 31L72 39H66ZM69.7171 26.8708C69.5269 26.347 69.0419 26 68.5 26C67.9581 26 67.4731 26.347 67.2829 26.8708L62.0831 41.1863C61.831 41.8802 62.1716 42.6539 62.8439 42.9142C63.516 43.1745 64.2653 42.8228 64.5174 42.1288L64.7699 41.4339H72.2302L72.4826 42.1288C72.6784 42.6676 73.1737 43.0001 73.6999 43C73.8516 43 74.0059 42.9724 74.1562 42.9142C74.8283 42.6539 75.169 41.8803 74.9169 41.1863L69.7171 26.8708Z"
          fill="white"
        />
        <defs>
          <linearGradient
            id="paint0_linear"
            x1="36.5"
            y1="31"
            x2="50.4667"
            y2="45.9644"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F291B0" />
            <stop offset="1" stopColor="#F65B7D" />
          </linearGradient>
          <linearGradient
            id="paint1_linear"
            x1="34.3662"
            y1="28.5136"
            x2="61.5829"
            y2="61.4685"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#65A1DB" />
            <stop offset="1" stopColor="#3176DE" />
          </linearGradient>
        </defs>
      </g>
    )
  }
}

export default QuestionAnswerIcon
