import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { NavigatorContext } from './Navigator'
import Icons from '../Icons'
import ContextMenu from '../ContextMenu'
import { useTheme } from '../Theme'

const NavigatorCurrent = ({ className }) => {
  const theme = useTheme()
  const selectedRef = React.useRef()

  const { selected, items, subItemsObj, handleChangeItem } = useContext(
    NavigatorContext
  )

  const { selectedItem, selectedSubItem } = selected
  const [openOptions, setOpenOptions] = useState(false)

  const handleClickSelect = () => {
    setOpenOptions(!openOptions)
  }

  const handleSelectOption = newSelectedItem => () => {
    setOpenOptions(false)
    if (newSelectedItem !== selectedItem) {
      handleChangeItem(newSelectedItem)
    }
  }

  return (
    <div className={classnames('navigator__current-item', className)}>
      <div
        role="button"
        className="navigator__select-button"
        onClick={handleClickSelect}
        ref={selectedRef}
      >
        <span>{subItemsObj[selectedSubItem]?.label}</span>
        <Icons.Caret.Down width={12} color={[theme.colors.text.primary]} />
      </div>

      <ContextMenu
        targetNode={selectedRef.current}
        onCloseHandler={() => setOpenOptions(false)}
        withBackdrop={false}
        className="cui-field-dropdown__modal"
        fullTargetWidth
        open={openOptions}
        appearAnimation={false}
        disappearAnimation={false}
      >
        {items.map(item => {
          return (
            <div
              role="button"
              key={item.value}
              className={classnames('navigator__option-item', {
                selected: item.value === selectedItem,
                disabled: item.disabled,
              })}
              onClick={handleSelectOption(item.value)}
              style={{
                '--cuiNavigatorBorder': theme.colors.divider,
                '--cuiNavigatorHoverBg': theme.colors.grey[100],
                '--cuiNavigatorDisabledText': theme.colors.text.disabled,
              }}
            >
              {item.label}
            </div>
          )
        })}
      </ContextMenu>
    </div>
  )
}

NavigatorCurrent.propTypes = {
  className: PropTypes.string,
}

export default NavigatorCurrent
