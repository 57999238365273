import React from 'react'
import PropTypes from 'prop-types'

const PrintableItem = ({ children }) => {
  return <div className="printable">{children}</div>
}

PrintableItem.propTypes = {
  children: PropTypes.node,
}

export default PrintableItem
