import React from 'react'
import PropTypes from 'prop-types'

const CumulativeIcon = ({ color }) => {
  return (
    <g className="icon-cumulative">
      <path
        d="M0 1C0 0.447715 0.447715 0 1 0C1.55228 0 2 0.447715 2 1V9.58579L5.29289 6.29289C5.68342 5.90237 6.31658 5.90237 6.70711 6.29289L9 8.58579L13.2929 4.29289C13.6834 3.90237 14.3166 3.90237 14.7071 4.29289C15.0976 4.68342 15.0976 5.31658 14.7071 5.70711L9.70711 10.7071C9.31658 11.0976 8.68342 11.0976 8.29289 10.7071L6 8.41421L2 12.4142V15H16C16.5523 15 17 15.4477 17 16C17 16.5523 16.5523 17 16 17H2C0.89543 17 0 16.1046 0 15V1Z"
        fill="#00B0B2"
      />
    </g>
  )
}

CumulativeIcon.propTypes = {
  color: PropTypes.array,
}

CumulativeIcon.defaultProps = {
  color: '#818A91',
}

export default CumulativeIcon
