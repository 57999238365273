import React from 'react'
import { ContextProvider } from './context'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'
import { parseQueryParams } from '../helpers'
import TabNav from './TabNav'
import TabPane from './TabPane'
import TabItem from './TabItem'
import TabNavContainer from './TabNavContainer'
import { useTheme } from '../Theme'

const Tabs = ({
  activeTab,
  children,
  capsule,
  fullWidth,
  id,
  onChange,
  className,
}) => {
  const { colors } = useTheme()
  const location = useLocation()

  const [selectedTabId, setSelectedTabId] = React.useState(activeTab)

  React.useEffect(() => {
    const queryParams = parseQueryParams(location.search)
    if (queryParams.tabId) {
      const tabIds = queryParams.tabId.split(',')
      const tabId = tabIds.find(tabId => {
        return tabId.split('.')[0] === id
      })
      if (tabId) {
        setSelectedTabId(tabId.slice(id.length + 1))
      }
    }
  }, [location.search, id])

  const selectTab = tabId => {
    if (onChange) {
      onChange(tabId)
    }
    setSelectedTabId(tabId)
  }

  const _Tabs = classnames('cui--tabs', className, {
    'cui--tabs--capsule': capsule,
    'cui--tabs--full-width': fullWidth,
  })

  const _TabsId = id ? 'tab-id-' + id : 'tab-id'

  return (
    <nav
      className={_Tabs}
      data-testid="tab-test"
      id={_TabsId}
      style={{
        '--cuiTabNavBg': colors.tabs.background,
        '--cuiTabItemText': colors.tabs.text,
        '--cuiTabItemBg': colors.tabs.itemBackground,
        '--cuiTabItemActiveText': colors.text.primary,
      }}
    >
      <ContextProvider
        value={{
          selectedTabId: selectedTabId,
          tabSectionId: id,
          actions: {
            selectTab: selectTab,
          },
        }}
      >
        {children}
      </ContextProvider>
    </nav>
  )
}

const TabsRouter = props => {
  return <Tabs {...props} />
}

TabsRouter.Nav = TabNav
TabsRouter.Pane = TabPane
TabsRouter.Item = TabItem
TabsRouter.NavContainer = TabNavContainer

export default TabsRouter
