import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import FormGroupLabel from './FormGroupLabel'

// check if there exist child of FormGroupLabel, display it at the first

const FormGroup = props => {
  const _FormGroup = classnames('cui-formgroup')
  const { children } = props
  const formlabel = () => {
    return React.Children.map(children, child => {
      if (!child) {
        return null
      }
      if (child.type === FormGroupLabel) {
        return React.cloneElement(child)
      }
      return null
    })
  }
  const formfield = () => {
    return React.Children.map(children, child => {
      if (!child) {
        return null
      }
      if (child.type !== FormGroupLabel) {
        return React.cloneElement(child)
      }
      return null
    })
  }
  return (
    <>
      <div className={_FormGroup}>
        {formlabel()}
        {formfield()}
      </div>
    </>
  )
}

FormGroup.propTypes = {
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
}

FormGroup.Label = FormGroupLabel

export default FormGroup
