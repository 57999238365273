import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Icons from '../Icons'

import './styles.scss'
import { useTheme } from '../Theme'

const SearchInput = ({
  placeholder,
  onSearch,
  hideCloseIcon,
  initialValue,
}) => {
  const theme = useTheme()
  const [searchKey, setSearchKey] = useState(initialValue)

  const handleSearch = ({ target: { value } }) => {
    setSearchKey(value)
    onSearch(value)
  }

  const handleRemoveSearchKey = () => {
    setSearchKey('')
    onSearch('')
  }

  return (
    <div
      className="cui-search"
      style={{
        '--cuiSearchInputBg': theme.colors.background.level1,
        '--cuiSearchInputBorder': theme.colors.input.border,
        '--cuiSearchInputText': theme.colors.text.primary,
      }}
    >
      <input
        className="cui-search__input"
        type="text"
        placeholder={placeholder}
        value={searchKey}
        onChange={handleSearch}
      />
      <Icons.Search
        className="cui-search__icon"
        width={16}
        height={16}
        color={[theme.colors.text.secondary]}
      />
      {!hideCloseIcon && (
        <span className="cui-search__remove" onClick={handleRemoveSearchKey}>
          <Icons.Close
            width={10}
            height={10}
            color={[theme.colors.text.secondary]}
          />
        </span>
      )}
    </div>
  )
}

SearchInput.defaultProps = {
  placeholder: '',
  initialValue: '',
}

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  hideCloseIcon: PropTypes.bool,
}

export default SearchInput
