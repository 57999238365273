import React from 'react'
import PropTypes from 'prop-types'

const TableCell = ({ children, width, style }) => {
  return (
    <div className="cui-tableList__cell" style={{ ...style, flexBasis: width }}>
      {children}
    </div>
  )
}

TableCell.propTypes = {
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  width: PropTypes.string,
}

export default TableCell
