import React from 'react'
import PropTypes from 'prop-types'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

import './vjsPlayToggleButton'
import './vjsLiveLabel'
import './vjsActions'
import { useTheme } from '../Theme'

const defaultOptions = {
  controls: true,
  preload: 'auto',
  responsive: true,
  controlBar: {
    volumePanel: false,
    pictureInPictureToggle: false,
    fullscreenToggle: false,
    subsCapsButton: false,
  },
}

const Video = ({ options, onReady, liveLabel, actions }) => {
  const theme = useTheme()
  const videoRef = React.useRef(null)
  const playerRef = React.useRef(null)

  React.useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current
      if (!videoElement) return

      const player = (playerRef.current = videojs(
        videoElement,
        {
          ...defaultOptions,
          ...options,
        },
        () => {
          onReady && onReady(player)
        }
      ))

      player.controlBar
        .addChild('vjsPlayToggleButton', {}, 0)
        .addClass('cui-video__play-toggle')
      player.controlBar.playToggle.dispose() // remove default play toggle button

      if (options.liveui) {
        player.controlBar.progressControl.dispose()
        player.controlBar.remainingTimeDisplay.dispose()
        player.controlBar.seekToLive.hide()
        player.controlBar
          .addChild('vjsLiveLabel', { label: liveLabel })
          .addClass('cui-video__live-label')
      }

      if (actions) {
        player.controlBar
          .addChild('vjsActions', { actions })
          .addClass('cui-video__actions')
      }
    }

    return () => {
      if (playerRef.current) {
        if (!playerRef.current.isDisposed()) {
          playerRef.current.dispose()
        }
        playerRef.current = null
      }
    }
  }, [options, onReady, actions, liveLabel])

  const styles = {
    '--cuiVideoBg': theme.colors.modal.background,
    '--cuiVideoText': theme.colors.text.primary,
  }

  return (
    <div className="cui-video" style={styles}>
      <div data-vjs-player>
        <video ref={videoRef} className="video-js vjs-big-play-centered" />
      </div>
    </div>
  )
}

Video.propTypes = {
  options: PropTypes.object,
  onReady: PropTypes.func,
  liveLabel: PropTypes.node,
  actions: PropTypes.node,
}

Video.defaultProps = {
  options: {},
  liveLabel: 'LIVE',
}

export default Video
