import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const Node = ({ label, data, render }) => {
  const _Node = classnames('cui-detail_node')
  return (
    <div className={_Node}>
      <div className="cui-detail_node__label">{label}</div>
      <div className="cui-detail_node__data">{render(data)}</div>
    </div>
  )
}

Node.defaultProps = {
  data: {},
  render: () => null,
}

Node.propTypes = {
  label: PropTypes.node,
  data: PropTypes.object,
  render: PropTypes.func,
}

export default Node
