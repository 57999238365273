import React from 'react'
import classnames from 'classnames'

import FooterContent from './FooterContent'
import { useTheme } from '../Theme'

import './styles.scss'

const Footer = ({ children }) => {
  const theme = useTheme()

  const _Footer = classnames('lm--footer cui-footer')
  return (
    <footer
      className={_Footer}
      style={{
        '--cuiFooterBg': theme.colors.background.level1,
      }}
    >
      <div className="u-contain cui-footer__container">
        <div className="lm--footer-flex">{children}</div>
      </div>
    </footer>
  )
}

Footer.Content = FooterContent

Footer.propTypes = {
  children: (props, propName, componentName) => {
    let error
    const prop = props[propName]
    React.Children.forEach(prop, child => {
      if (!isValidChildType(child.type)) {
        error = new Error(
          `${componentName} only accepts children of type FooterContent. Please use Footer.Content or FooterContent components instead.`
        )
      }
    })
    return error
  },
}

function isValidChildType(type) {
  const validChildren = [FooterContent]
  return validChildren.some(t => t === type)
}

export default Footer
