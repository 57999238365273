import React from 'react'
import { context } from './context'
import classnames from 'classnames'
import { useHistory } from 'react-router-dom'
import { parseQueryParams } from '../helpers'

const TabNav = ({ tabId, children, demarcated }) => {
  const history = useHistory()
  const { selectedTabId, tabSectionId, actions } = React.useContext(context)

  const _TabNav = classnames('cui--tab-nav', {
    'is-active': tabId === selectedTabId,
    'is-demarcated-left': demarcated && demarcated === 'left',
    'is-demarcated-right': demarcated && demarcated === 'right',
  })
  const _TabLink = classnames('cui--tab-link', {
    'is-active': tabId === selectedTabId,
  })

  const handleClick = () => {
    const queryParams = parseQueryParams(window.location.search)
    let tabIds = []
    if (queryParams.tabId) {
      tabIds = queryParams.tabId.split(',')
      tabIds = tabIds.filter(id => {
        return id.split('.')[0] !== tabSectionId
      })
    }
    tabIds.push(tabSectionId + '.' + tabId)
    const newQueryParams = {
      ...queryParams,
      tabId: tabIds.join(','),
    }
    const queryString = Object.keys(newQueryParams)
      .map(key => {
        return `${key}=${newQueryParams[key]}`
      })
      .join('&')

    history.push(window.location.pathname + '?' + queryString)
  }

  return (
    <li
      className={_TabNav}
      id={'tab-nav-id-' + tabId}
      onClick={() => actions.selectTab(tabId)}
      data-testid={'tab-nav-test-id-' + tabId}
    >
      <button
        onClick={handleClick}
        className={_TabLink}
        id={'tab-link-id-' + tabId}
      >
        {children}
      </button>
    </li>
  )
}

export default TabNav
