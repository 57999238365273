import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import NavTreeList from './NavTreeList'
import Icons from '../Icons'

function NavTreeListItem({
  children,
  id,
  active,
  onClick,
  color,
  subMenuExpanded,
  ...props
}) {
  const [openSubMenu, setOpenSubMenu] = React.useState(subMenuExpanded)

  React.useState(() => {
    setOpenSubMenu(subMenuExpanded)
  }, [subMenuExpanded])

  const renderItem = () => {
    if (typeof children === 'function') {
      return children()
    }

    let hasSubMenu = false
    const subMenu = React.Children.map(children, child => {
      if (child && child.type === NavTreeList) {
        hasSubMenu = true
        return child
      }
      return null
    })

    const child = React.Children.map(children, child => {
      if (child && child.type !== NavTreeList) {
        return child
      }
      return null
    })

    const _NavTreeListItem = classnames('cui-navtree__item', {
      active,
      'has-submenu': hasSubMenu,
    })

    return (
      <>
        <div className={_NavTreeListItem} style={{ color }}>
          {hasSubMenu && (
            <div
              className={classnames('cui-navtree__item-arrow', {
                open: openSubMenu,
              })}
              onClick={() => setOpenSubMenu(!openSubMenu)}
            >
              <Icons.Caret.Right width={16} />
            </div>
          )}
          <div onClick={onClick}>{child}</div>
        </div>
        {openSubMenu && subMenu}
      </>
    )
  }

  return <li {...props}>{renderItem()}</li>
}

NavTreeListItem.propTypes = {
  id: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
}

export default NavTreeListItem
