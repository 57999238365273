import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'
import { useTheme } from '../Theme'

/**
 * Pill component
 */
const Pill = ({ children, pillBg, pillColor, id, variant }) => {
  const theme = useTheme()

  const getStyles = () => {
    switch (variant) {
      case 'success':
        return {
          color: theme.colors.primary.main,
          backgroundColor: theme.colors.primary.fade,
        }
      case 'grey':
        return {
          color: theme.colors.text.primary,
          backgroundColor: theme.colors.grey[100],
        }
      case 'warning':
        return {
          color: theme.colors.warning.main,
          backgroundColor: theme.colors.warning.fade,
        }
      case 'error':
        return {
          color: theme.colors.error.main,
          backgroundColor: theme.colors.error.fade,
        }
      default:
        return null
    }
  }

  const pillStyle = getStyles() || {
    color: pillColor,
    backgroundColor: pillBg,
  }
  return (
    <div className="cui-pill" id={id} style={pillStyle}>
      {children}
    </div>
  )
}

Pill.defaultProps = {
  pillBg: '#f3f6f7',
  pillColor: '#4f5a60',
}

Pill.propTypes = {
  // Pill children
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  pillBg: PropTypes.string,
  pillColor: PropTypes.string,
  id: PropTypes.string,
  variant: PropTypes.oneOf(['grey', 'success', 'error']),
}

export default Pill
