import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ArticleHeader = ({ title, titleVariant, children }) => {
  const _ArticleHeader = classnames('cui-article__header')

  function renderTitle() {
    if (titleVariant === 'small') {
      return <h5>{title}</h5>
    }
    if (titleVariant === 'medium') {
      return <h4>{title}</h4>
    }
    if (titleVariant === 'large') {
      return <h3>{title}</h3>
    }
    if (titleVariant === 'larger') {
      return <h2>{title}</h2>
    }

    return <h1> {title} </h1>
  }
  return (
    <div className={_ArticleHeader}>
      {title && renderTitle()}
      {children}
    </div>
  )
}

ArticleHeader.defaultProps = {
  titleVariant: 'largest',
}

ArticleHeader.propTypes = {
  titleVariant: PropTypes.oneOf([
    'largest',
    'larger',
    'larger',
    'medium',
    'small',
  ]),
}

export default ArticleHeader
