import React from 'react'
import classnames from 'classnames'

import Typo from '../Typo'

const DetailNode = ({ label, children, labelProps, ...props }) => {
  return (
    <div className="cui-detail_node" {...props}>
      {!!label && (
        <div className="cui-detail_node__title">
          <Typo
            size="small"
            weight={500}
            variantColor="secondary"
            allCaps
            {...labelProps}
          >
            {label}
          </Typo>
        </div>
      )}
      <div className="cui-detail_node__content">{children}</div>
    </div>
  )
}

DetailNode.defaultProps = {
  label: '',
}

const DetailNodeSection = ({
  children,
  direction,
  className,
  numberOfItems,
}) => {
  const _DetailNodeSection = classnames(
    'cui-detail_node__section',
    `direction-${direction}`,
    className
  )
  return (
    <div className={_DetailNodeSection}>
      {React.Children.map(
        children,
        child =>
          child &&
          child.type === DetailNode &&
          React.cloneElement(child, {
            style:
              direction === 'row'
                ? {
                    flexBasis: numberOfItems
                      ? `${100 / numberOfItems}%`
                      : 'auto',
                  }
                : null,
          })
      )}
    </div>
  )
}

DetailNodeSection.defaultProps = {
  direction: 'row',
  className: '',
  numberOfItems: 2,
}

const DetailNodeItem = ({ label, data, render, ...props }) => {
  return (
    <div {...props}>
      {label !== '' && <div className="label">{label}</div>}
      {label !== '' ? (
        <div className="data">{render(data)}</div>
      ) : (
        <div className="">{render(data)}</div>
      )}
    </div>
  )
}

DetailNodeItem.defaultProps = {
  data: {},
  render: () => null,
}

DetailNode.Section = DetailNodeSection
DetailNode.Item = DetailNodeItem

export default DetailNode
