// organize-imports-ignore
import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from '../Button'
import Icons from '../Icons'

const AccordionItem = ({
  id,
  children,
  className,
  style,
  active,
  isActive,
  index,
  multiple,
  onToggleAccordion,
  isDefaultExpand,
  setDefaultExpand,
}) => {
  const [expand, setExpand] = React.useState(active)

  const [defaultActive, setDefaultActive] = React.useState(active)

  const _AccordionItem = classnames('cui-accordion__item', className)

  const toggleExpandContent = () => {
    setDefaultActive(false)
    setExpand(v => !v)
    onToggleAccordion()
  }

  React.useEffect(() => {
    if (defaultActive) {
      setDefaultExpand(index)
    }
  }, [])

  const isActiveAccordionItem = () => {
    if (defaultActive && !multiple) {
      return isDefaultExpand
    }
    return multiple ? expand : isActive
  }

  const renderAccordionChildren = () => {
    if (!children) return null
    return React.Children.map(children, (child, childIdx) => {
      return React.cloneElement(child, {
        ...child.props,
        isExpand: isActiveAccordionItem(),
        toggleExpandContent: toggleExpandContent,
      })
    })
  }

  return (
    <div id={id} className={_AccordionItem} style={style}>
      <Button
        onClickHandler={() => toggleExpandContent()}
        className="accordion-btn__icon"
        type="button"
        style={{
          transform: `rotate(${isActiveAccordionItem() ? 180 : 0}deg)`,
        }}
      >
        <Icons.Caret.Down width={14} height={14} />
      </Button>
      {renderAccordionChildren()}
    </div>
  )
}

AccordionItem.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node,
  className: PropTypes.string,
  isActive: PropTypes.bool,
  multiple: PropTypes.bool,
  onToggleAccordion: PropTypes.func,
  style: PropTypes.object,
  active: PropTypes.bool,
  index: PropTypes.number,
  isDefaultExpand: PropTypes.bool,
  setDefaultExpand: PropTypes.func,
}

AccordionItem.defaultProps = {
  active: false,
}

export default AccordionItem
