import React from 'react'

const UsingApp = () => {
  return (
    <g className="illus-usingapp">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.9914 171.065C65.0937 172.68 65.2013 174.299 65.3268 175.9H65.3286C65.178 174.008 65.0452 172.099 64.9322 170.192C64.848 168.782 64.7762 167.363 64.7081 165.944H56.4705C56.8328 167.715 57.1915 169.487 57.5466 171.259V171.261C58.0021 171.196 58.4631 171.13 58.924 171.065H64.9914Z"
        fill="#FFCBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.5358 173.864L58.6851 172.582L54.5358 173.864C54.4798 173.949 54.4343 174.035 54.3889 174.132C54.298 174.343 54.2577 174.569 54.256 174.795C54.2577 174.569 54.2997 174.341 54.3906 174.129C54.4326 174.035 54.4798 173.948 54.5358 173.864Z"
        fill="#FFACEA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64.4107 136.897C64.7811 130.461 65.196 124.037 65.3263 117.591C65.3863 114.366 64.2581 105.261 64.313 102.974L45.3978 102.886L48.8973 107.795L49.1373 116.852L49.2333 116.883C49.2453 117.074 49.259 117.266 49.271 117.455C50.3512 133.664 53.3534 150.003 56.4843 165.944H56.8803H64.7571H65.28C65.1771 162.618 64.3627 153.453 64.3284 150.101C64.2787 145.716 64.2787 141.311 64.313 136.897H64.4107Z"
        fill="url(#usingapp-paint0_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.2159 170.192C47.1316 168.782 47.0599 167.363 46.9918 165.944H38.7542C39.1147 167.715 39.477 169.487 39.8303 171.259V171.261C40.2858 171.196 40.7468 171.13 41.2077 171.065H47.2751C47.3774 172.68 47.485 174.295 47.6105 175.9H47.6123C47.4634 174.007 47.3289 172.099 47.2159 170.192Z"
        fill="#FFCBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.712 173.857L39.8613 172.582L35.712 173.857C35.656 173.943 35.6106 174.028 35.5669 174.125C35.4725 174.338 35.4322 174.568 35.4322 174.795C35.4322 174.566 35.4742 174.336 35.5669 174.121C35.6088 174.028 35.656 173.941 35.712 173.857Z"
        fill="#FFACEA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.6321 137.699H46.7329L46.8268 123.367C46.8615 121.64 46.8911 119.915 46.9258 118.19C46.9606 116.311 46.9919 114.443 47.0266 112.568C46.0082 96.9453 41.9154 102.651 37.5706 103.447C36.036 103.728 30.2331 98.3898 29.9915 111.501C28.8271 132.621 38.6985 167.05 38.6985 167.05H47.6123C47.508 163.689 46.6825 154.428 46.6478 151.041C46.5974 146.61 46.5974 142.159 46.6321 137.699Z"
        fill="url(#usingapp-paint1_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.2561 174.461C54.2578 174.247 54.2977 174.03 54.3858 173.828C54.4241 173.74 54.469 173.657 54.5222 173.577L58.4686 172.359V172.358C58.3605 171.766 58.2574 171.17 58.1493 170.575H58.1509C58.5734 170.507 59.0008 170.436 59.4282 170.369H65.0542C65.1374 171.847 65.2305 173.323 65.3286 174.794H54.2877C54.2661 174.684 54.2544 174.573 54.2561 174.461Z"
        fill="#5694DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.4325 174.47C36.4325 174.252 36.4724 174.032 36.5622 173.827C36.6021 173.738 36.6453 173.655 36.6985 173.575L40.6444 172.359V172.358C40.5363 171.764 40.4349 171.168 40.3268 170.573C40.7491 170.506 41.1781 170.436 41.6038 170.369H47.2308C47.3139 171.847 47.4054 173.323 47.5051 174.794H36.4641C36.4424 174.686 36.4325 174.58 36.4325 174.47Z"
        fill="#5694DC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.3633 48.6787H46.5051V38.7222H55.3633V48.6787Z"
        fill="#FFCBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M28.4543 111.735H65.4051V73.1394H78.6157C76.8618 66.881 73.8356 61.0111 69.6169 55.9852C66.7689 52.5975 63.5188 49.7524 59.709 48.1313C50.9578 44.4184 40.7584 43.7884 33.1472 50.2882C27.273 55.309 25.1187 64.0941 22.1451 71.4036H33.9394L28.4543 111.735Z"
        fill="#E4EBF2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.613 71.9084L63.8281 74.5762L64.265 80.0076L56.8576 110.593C56.0126 112.091 56.6002 114.012 58.1447 114.76C59.4538 115.394 61.0236 114.971 61.8501 113.77L68.7528 99.2713L71.6622 93.1528C73.608 89.0715 74.973 84.7554 75.7249 80.3435C76.2008 77.5592 76.4277 74.7338 76.3989 71.9084H63.613Z"
        fill="#FFCBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5596 71.5659L22.5478 71.5945L10.5589 50.6964C9.43248 48.6029 6.67832 48.0269 4.79648 49.4922C3.38552 50.5903 2.9248 52.512 3.68533 54.1272L18.342 85.1965C19.5378 87.7312 22.9763 88.1708 24.7802 86.02L34.325 71.5945L22.5596 71.5659Z"
        fill="#FFCBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.34548 52.8226L8.03304 53.0861C7.61582 53.1716 7.21043 52.8979 7.12767 52.4752L4.44366 38.8112C4.36089 38.3885 4.63115 37.9779 5.04836 37.8941L6.36081 37.6289C6.77633 37.545 7.18172 37.8188 7.26618 38.2431L9.95018 51.9072C10.033 52.3281 9.76269 52.7387 9.34548 52.8226Z"
        fill="#00878A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.2099 34.9332C43.5605 39.1974 47.3029 42.3643 51.5765 42.014C53.2724 41.8746 54.8001 41.1972 55.9969 40.166C56.3117 39.8944 56.6069 39.5941 56.8752 39.2653C58.1382 37.7694 58.8322 35.7749 58.6587 33.6607C58.4029 30.5671 56.3672 28.0472 53.6391 27.041C53.1955 26.8677 52.7303 26.7479 52.2563 26.6639L52.2384 26.6586C51.6087 26.5567 50.9611 26.5245 50.3028 26.5781C50.14 26.5924 49.9755 26.6121 49.8109 26.6371C48.4531 26.8355 47.2206 27.377 46.1937 28.1687C44.192 29.7182 42.9863 32.2149 43.2099 34.9332Z"
        fill="#FFCBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.2105 29.8289C44.2393 29.8334 44.2681 29.84 44.2991 29.8444C44.4873 29.9882 44.2504 29.871 44.2105 29.8289Z"
        fill="#6F2677"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.144 24.1371C50.1125 22.2748 46.9154 23.7559 45.0777 24.459C43.2393 25.162 40.7674 28.2283 40.9821 29.8315C40.9821 29.8315 42.1204 28.9354 42.0025 29.2465C42.0025 29.2465 41.7545 31.5 42.0399 32.0809C42.327 32.6618 44.1386 33.1643 45.6648 33.4944C47.191 33.8228 49.7474 34.3657 50.2256 34.2453C50.5549 34.1619 50.8037 33.8863 50.8573 33.5464L50.8744 33.4424C50.8866 33.4391 50.8826 33.3896 50.8826 33.3896L50.9655 32.8656L53.7187 34.657C53.6967 34.7082 53.6699 34.756 53.6528 34.8088C53.4105 35.5746 53.8 36.3857 54.1789 37.0929C54.4407 37.583 54.7172 38.0897 55.1595 38.4205C55.6019 38.7514 56.2613 38.8529 56.689 38.5014C57.3655 37.9461 59.5008 35.5119 59.6975 33.0521C59.8951 30.5923 60.3553 27.007 54.144 24.1371Z"
        fill="#4A4A7C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M48.7554 33.2769C48.4291 35.3699 50.3818 37.0248 52.3943 36.3603C53.3407 36.0468 54.068 35.1932 54.2199 34.2077C54.5462 32.1148 52.596 30.4623 50.5811 31.1268C49.6346 31.4379 48.9098 32.2915 48.7554 33.2769Z"
        fill="#FFCBBB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.2113 26.3631L33.0238 26.3634C35.5671 26.3634 37.647 24.288 37.647 21.7502V4.61321C37.647 2.07542 35.5671 0 33.0238 0H4.62479C2.08155 0 -8.7738e-05 2.07542 -8.7738e-05 4.61321V21.7502C-8.7738e-05 24.2863 2.08155 26.3617 4.62305 26.3617L19.4791 26.3626C19.8083 27.0361 20.2363 27.6687 20.7204 28.2202C23.5459 31.4446 28.2944 32.8456 32.4211 31.6723C30.3221 30.6711 28.2439 29.2319 27.4217 27.0608C27.3356 26.8331 27.2655 26.5999 27.2113 26.3631Z"
        fill="url(#usingapp-paint2_linear)"
      />
      <rect
        width="4.42905"
        height="12.1691"
        rx="2.21453"
        transform="matrix(-1 0 0 1 33.2178 9.95703)"
        fill="white"
      />
      <rect
        width="4.42907"
        height="15.4879"
        rx="2.21453"
        transform="matrix(-1 0 0 1 25.4668 6.6377)"
        fill="white"
      />
      <rect
        width="4.42907"
        height="15.4879"
        rx="2.21453"
        transform="matrix(-1 0 0 1 9.96509 6.6377)"
        fill="white"
      />
      <rect
        width="4.42908"
        height="18.8068"
        rx="2.21454"
        transform="matrix(-1 0 0 1 17.7161 3.31836)"
        fill="white"
      />
      <defs>
        <linearGradient
          id="usingapp-paint0_linear"
          x1="73.4522"
          y1="177.892"
          x2="27.5682"
          y2="183.776"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#65A1DB" />
          <stop offset="1" stopColor="#3176DE" />
        </linearGradient>
        <linearGradient
          id="usingapp-paint1_linear"
          x1="54.8333"
          y1="179.417"
          x2="13.8735"
          y2="183.928"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#65A1DB" />
          <stop offset="1" stopColor="#3176DE" />
        </linearGradient>
        <linearGradient
          id="usingapp-paint2_linear"
          x1="56.2984"
          y1="15.8947"
          x2="24.9113"
          y2="52.7254"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D4EDA7" />
          <stop offset="1" stopColor="#B0CF7A" />
        </linearGradient>
      </defs>
    </g>
  )
}

export default UsingApp
