import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const ProgressItem = ({ children, active, complete }) => {
  const _ProgressItem = classnames('cui-progress__item', {
    'is-active': active,
    'is-complete': complete,
  })
  return <li className={_ProgressItem}>{children}</li>
}

ProgressItem.displayName = 'CuiProgressItem'

ProgressItem.propTypes = {
  active: PropTypes.bool,
}

ProgressItem.defaultProps = {
  active: false,
}

export default ProgressItem
