import React from 'react'

function NextArrowIcon() {
  return (
    <g className="icon-next-icon">
      <mask
        id="mask0_12717_13756"
        maskUnits="userSpaceOnUse"
        x="2"
        y="4"
        width="12"
        height="9"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.79719 12.3005C9.05518 12.5632 9.47728 12.567 9.73996 12.309L13.1339 8.97563C13.2615 8.85028 13.3334 8.67889 13.3334 8.5C13.3334 8.32111 13.2615 8.14972 13.1339 8.02437L9.73996 4.69103C9.47728 4.43304 9.05518 4.43684 8.79719 4.69953C8.5392 4.96221 8.543 5.38431 8.80568 5.6423L11.0366 7.83333L3.33341 7.83333C2.96523 7.83333 2.66675 8.13181 2.66675 8.5C2.66675 8.86819 2.96523 9.16667 3.33341 9.16667L11.0366 9.16667L8.80568 11.3577C8.543 11.6157 8.5392 12.0378 8.79719 12.3005Z"
        />
      </mask>
      <g mask="url(#mask0_12717_13756)">
        <rect width="16" height="16" transform="translate(0 0.5)" />
      </g>
    </g>
  )
}

export default NextArrowIcon
