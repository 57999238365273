import React from 'react'
import classnames from 'classnames'
import PropTypes, { instanceOf } from 'prop-types'

import TextBlockTitle from './TextBlockTitle'
import TextBlockContent from './TextBlockContent'
import TextBlockInline from './TextBlockInline'

import Icons from '../Icons'

import './styles.scss'

class TextBlock extends React.PureComponent {
  render() {
    const _TextBlock = classnames('cui-textblock', {
      'cui-textblock-heading': this.props.heading,
    })

    const displayIcon = () => {
      const icon = React.Children.map(
        this.props.children,
        (child, childIdx) => {
          if (
            child.type === Icons.Gas ||
            child.type === Icons.Water ||
            child.type === Icons.Electricity ||
            child.type === Icons.Alert ||
            child.type === Icons.Checked ||
            child.type === Icons.Pdf
          ) {
            return <>{child}</>
          }
        }
      )
      return icon
    }

    const displayChildren = () => {
      const children = React.Children.map(
        this.props.children,
        (child, childIdx) => {
          if (
            child.type !== Icons.Gas &&
            child.type !== Icons.Water &&
            child.type !== Icons.Electricity &&
            child.type !== Icons.Alert &&
            child.type !== Icons.Checked &&
            child.type !== Icons.Pdf
          ) {
            return <>{child}</>
          }
        }
      )
      return children
    }

    if (this.props.heading) {
      return (
        <>
          <div className={_TextBlock} id={this.props.id}>
            <div className={_TextBlock + '-icon'} data-testid="test-icon">
              {displayIcon()}
            </div>
            <div
              className={_TextBlock + '-description'}
              data-testid="test-description"
            >
              {displayChildren()}
            </div>
          </div>
        </>
      )
    } else {
      return (
        <div className={_TextBlock} id={this.props.id}>
          {this.props.children}
        </div>
      )
    }
  }
}

TextBlock.Title = TextBlockTitle
TextBlock.Content = TextBlockContent
TextBlock.Inline = TextBlockInline

TextBlock.propTypes = {
  heading: PropTypes.bool,
  id: PropTypes.string,
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
}

export default TextBlock
