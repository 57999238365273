/** React Packages */
import React from 'react'
import PropTypes from 'prop-types'

/** Styling Packages */
import classnames from 'classnames'

/** Component Packages */
import Logo from './Logo'

/** Logo Varaint - SPgroup
 *  @param {object} logo                   logo's properties name
 *  @param {SVGElement|string} logo.source logo's source
 *  @param {string} logo.target            logo's target tab
 */
const SPgroup = ({ logo = {}, className, style }) => {
  const _SPgroup = classnames('lm--logo__spgroup cui-logo__spgroup')

  const spgroupSVG = () => {
    return (
      <svg
        width="170"
        viewBox="0 0 150 64"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M41.2016 33.1305C40.8465 37.1905 39.2964 41.0539 36.7474 44.2321C34.1984 47.4104 30.7649 49.7608 26.8812 50.9861C22.9974 52.2115 18.8379 52.2567 14.9284 51.1161C11.019 49.9755 7.53536 47.7002 4.91797 44.5781C2.30058 41.456 0.667016 37.6273 0.223861 33.576C-0.219295 29.5247 0.547859 25.433 2.42831 21.818C4.30876 18.2031 7.21805 15.2275 10.7883 13.2674C14.3585 11.3072 18.4294 10.4507 22.486 10.806C25.1796 11.0418 27.8004 11.8063 30.1988 13.0558C32.5972 14.3054 34.7262 16.0155 36.4641 18.0886C38.2021 20.1616 39.515 22.557 40.3278 25.1379C41.1407 27.7189 41.4376 30.4348 41.2016 33.1305Z"
          fill="white"
        />
        <path
          d="M12.2437 50.1309C15.9451 51.7948 20.0551 52.3274 24.0579 51.6619C28.0608 50.9964 31.778 49.1624 34.7431 46.3902C37.7082 43.6179 39.789 40.0309 40.7244 36.0793C41.6599 32.1277 41.4082 27.9877 40.0011 24.1787C39.5227 28.6117 37.3066 38.5963 26.7764 41.0241C14.3875 43.8612 14.4288 47.2893 12.2437 50.1309Z"
          fill="url(#paint0_radial)"
        />
        <path
          d="M22.486 10.8076C18.9989 10.503 15.4919 11.0928 12.2958 12.5214C9.09982 13.95 6.32032 16.1703 4.21973 18.9725C2.11915 21.7747 0.766794 25.0665 0.290339 28.537C-0.186116 32.0076 0.229051 35.5424 1.49664 38.8078C2.49325 35.1328 5.81972 26.9362 15.9261 25.2236C29.0459 23.0071 32.3901 19.1046 33.3483 15.3632L33.4059 15.1356C30.2655 12.6581 26.4698 11.1537 22.486 10.8076Z"
          fill="url(#paint1_radial)"
        />
        <path
          d="M3.69367 19.7593C7.60777 13.7777 15.2233 9.76146 23.806 10.9584C10.5917 10.9362 1.37268 22.5994 7.53099 31.9752C10.6995 36.7968 17.3598 40.1688 19.4608 42.0144C22.0638 44.2989 22.3104 46.4695 22.0756 47.863C21.8113 49.4337 20.5194 50.4976 19.731 50.8538C17.6536 51.795 15.3016 51.2158 15.247 51.201C2.7074 47.9191 -4.83583 32.8041 3.69367 19.7593Z"
          fill="url(#paint2_radial)"
        />
        <path
          d="M37.7273 42.9069C33.8147 48.8885 26.1814 53.0112 17.615 51.7094C30.8278 51.7315 40.0483 40.0684 33.89 30.6926C30.7215 25.871 24.0612 22.499 21.9587 20.6534C19.3543 18.3689 19.1062 16.1997 19.341 14.8048C19.6053 13.234 20.8957 12.1701 21.6856 11.814C23.763 10.8727 26.0869 11.5022 26.1445 11.5185C38.6841 14.8003 46.2539 29.8636 37.7273 42.9069Z"
          fill="url(#paint3_radial)"
        />
        <path
          d="M3.62121 19.7946C7.53236 13.813 15.238 9.65635 23.8059 10.9582C8.85674 10.9257 0.575278 23.0203 7.12928 33.0699C10.2815 37.9018 17.8986 41.197 19.7412 42.7249C21.8186 44.4449 22.3043 46.4693 22.0755 47.8628C21.9563 48.5375 21.6697 49.1714 21.242 49.7064C20.8143 50.2413 20.2591 50.6602 19.6275 50.9245C17.463 51.8111 15.4285 51.2466 15.1745 51.1786C2.68071 47.845 -4.89205 32.8128 3.62121 19.7946ZM37.7968 42.8727C33.8886 48.8558 26.1786 53.0109 17.6121 51.7091C32.5598 51.7416 40.8428 39.647 34.2873 29.5974C31.135 24.7654 23.5195 21.4703 21.6754 19.9424C19.5995 18.2224 19.1137 16.1994 19.3411 14.8045C19.4603 14.1293 19.7471 13.4948 20.175 12.9594C20.603 12.4239 21.1585 12.0046 21.7905 11.7399C23.9535 10.8533 25.9881 11.4177 26.2435 11.4857C38.7373 14.8223 46.3086 29.8545 37.7968 42.8727Z"
          fill="url(#paint4_radial)"
        />
        <path
          d="M73.4379 40.6715C74.5005 41.2522 75.6894 41.5631 76.9002 41.577C78.8696 41.577 80.6096 40.855 80.6096 38.6178V37.8262C80.3389 38.2109 79.1596 39.6595 76.8292 39.658C73.4379 39.658 71.177 37.1027 71.177 33.4805C71.177 29.8584 73.5281 27.2661 76.8765 27.2661C79.096 27.2661 80.3611 28.5978 80.7236 29.1438V27.5532H83.0525V37.7996C83.0525 42.2384 80.3625 43.7033 76.9268 43.7033C75.4286 43.687 73.9581 43.298 72.6478 42.5714L73.4379 40.6715ZM77.1473 29.4515C75.1128 29.4515 73.6406 31.0791 73.6406 33.4569C73.6406 35.8775 75.1572 37.4607 77.171 37.4607C79.2957 37.4607 80.6777 35.7873 80.6777 33.4569C80.6777 31.0362 79.1833 29.4515 77.1473 29.4515Z"
          fill="#9BA6AA"
        />
        <path
          d="M90.1562 29.746C87.5772 29.746 86.83 31.7139 86.83 34.2722V39.3961H84.3665V27.5591H86.7842V29.4131C87.2384 28.2294 88.4828 27.2632 90.1562 27.2632V29.746Z"
          fill="#9BA6AA"
        />
        <path
          d="M96.1502 27.2573C99.7946 27.2573 102.418 29.9044 102.418 33.457C102.418 37.0095 99.7546 39.6566 96.1502 39.6566C92.5459 39.6566 89.9092 37.0525 89.9092 33.4777C89.9092 29.9029 92.6006 27.2573 96.1502 27.2573ZM96.1502 37.4667C98.346 37.4667 99.9529 35.7696 99.9529 33.4629C99.9529 31.1561 98.346 29.4575 96.1502 29.4575C93.9545 29.4575 92.3964 31.1769 92.3964 33.4836C92.3964 35.8096 93.9574 37.4608 96.1502 37.4608V37.4667Z"
          fill="#9BA6AA"
        />
        <path
          d="M103.399 34.6569V27.5547H105.84V34.8611C105.84 36.4887 106.86 37.4401 108.218 37.4401C109.778 37.4401 110.796 36.3097 110.796 34.6791V27.5532H113.264V39.3902H110.84V37.8233C110.321 38.7732 109.098 39.6566 107.514 39.6566C104.461 39.6566 103.399 37.2329 103.399 34.6569Z"
          fill="#9BA6AA"
        />
        <path
          d="M114.576 27.5533H116.906V29.1128C117.35 28.5266 117.925 28.053 118.586 27.7307C119.247 27.4083 119.974 27.2462 120.709 27.2574C124.081 27.2574 126.48 29.8378 126.48 33.4333C126.48 37.0984 124.238 39.6566 120.869 39.6566C118.627 39.6566 117.359 38.2983 117.045 37.7997V43.591H114.576V27.5533ZM120.478 37.4668C122.538 37.4668 123.986 35.8155 123.986 33.4393C123.986 31.0201 122.47 29.4576 120.456 29.4576C118.285 29.4576 116.948 31.2686 116.948 33.4393C116.948 35.9901 118.51 37.4609 120.478 37.4609V37.4668Z"
          fill="#9BA6AA"
        />
        <path
          d="M49.5657 35.1228C50.0466 36.2547 51.0941 37.363 53.0546 37.363C54.6586 37.363 55.8289 36.5714 55.8289 35.2368C55.8289 31.8884 47.7028 32.6356 47.7028 27.5427C47.7028 25.0762 49.7669 23.2666 53.0546 23.2666C56.443 23.2666 57.8945 25.3928 58.328 26.5011L55.9813 27.4288C55.573 26.6594 54.7606 25.5733 52.9999 25.5733C51.4226 25.5733 50.4446 26.433 50.4446 27.5205C50.4446 30.6411 58.6047 30.0773 58.6047 35.0533C58.6047 37.7003 56.443 39.6904 52.9259 39.6904C49.2831 39.6904 47.7028 37.5198 47.1702 36.1157L49.5657 35.1228Z"
          fill="#666E76"
        />
        <path
          d="M60.0371 23.564H65.3756C68.3615 23.564 70.4418 25.8086 70.4418 28.6583C70.4418 31.4696 68.2697 33.8133 65.3963 33.8133H62.4563V39.3989H60.0371V23.564ZM65.0782 31.5658C66.7324 31.5658 67.8643 30.2415 67.8643 28.6583C67.8643 27.0751 66.7324 25.8041 65.1034 25.8041H62.4563V31.5658H65.0782Z"
          fill="#666E76"
        />
        <path
          d="M47.5005 46.9458H49.9611V47.656H48.2891V49.041H49.7377V49.7438H48.2891V51.2071H50.0292V51.9159H47.5005V46.9458Z"
          fill="#0B3763"
        />
        <path
          d="M55.9033 51.9158H55.1398V49.6964C55.1398 49.1208 54.8912 48.8086 54.4296 48.8086C53.996 48.8086 53.6764 49.1282 53.6764 49.6964V51.9158H52.9026V49.6224C52.9026 49.1179 52.6318 48.8116 52.1924 48.8116C51.7529 48.8116 51.4526 49.1534 51.4526 49.686V51.9158H50.6787V48.2168H51.4378V48.7006C51.5351 48.5214 51.6803 48.3727 51.8572 48.2711C52.034 48.1695 52.2356 48.1189 52.4395 48.125C52.9573 48.125 53.3272 48.3662 53.5048 48.742C53.6211 48.5447 53.7891 48.3829 53.9907 48.2741C54.1923 48.1654 54.4198 48.1138 54.6485 48.125C55.486 48.125 55.9121 48.7006 55.9121 49.5026L55.9033 51.9158Z"
          fill="#0B3763"
        />
        <path
          d="M56.6816 48.2169H57.4126V48.7067C57.5519 48.5227 57.7326 48.3742 57.9401 48.2732C58.1475 48.1721 58.3759 48.1214 58.6066 48.1252C59.6646 48.1252 60.4177 48.9346 60.4177 50.0635C60.4177 51.1925 59.7134 52.0166 58.6555 52.0166C58.4235 52.0226 58.1935 51.973 57.9847 51.8718C57.776 51.7706 57.5945 51.6209 57.4555 51.4351V53.2477H56.6816V48.2169ZM58.543 51.3241C59.1881 51.3241 59.6439 50.8048 59.6439 50.0591C59.6439 49.3133 59.16 48.8088 58.5282 48.8088C57.8461 48.8088 57.4274 49.377 57.4274 50.0576C57.4274 50.8595 57.9171 51.3241 58.543 51.3241Z"
          fill="#0B3763"
        />
        <path
          d="M62.7437 48.1179C63.8875 48.1179 64.7116 48.9494 64.7116 50.0636C64.7172 50.3225 64.6699 50.5798 64.5727 50.8199C64.4755 51.0599 64.3304 51.2777 64.1462 51.4598C63.962 51.6418 63.7427 51.7845 63.5016 51.879C63.2604 51.9734 63.0026 52.0178 62.7437 52.0093C61.6074 52.0093 60.7832 51.1925 60.7832 50.071C60.7773 49.8122 60.824 49.555 60.9206 49.3149C61.0171 49.0748 61.1614 48.8568 61.3448 48.6741C61.5281 48.4915 61.7466 48.348 61.9871 48.2524C62.2276 48.1567 62.485 48.111 62.7437 48.1179ZM62.7437 51.3213C63.4332 51.3213 63.9378 50.7886 63.9378 50.0636C63.9378 49.3386 63.4332 48.8074 62.7437 48.8074C62.0542 48.8074 61.56 49.346 61.56 50.071C61.56 50.796 62.0542 51.3242 62.7437 51.3242V51.3213Z"
          fill="#0B3763"
        />
        <path
          d="M67.6515 49.2615L66.8126 51.916H65.9603L64.7766 48.208H65.6141L66.4234 51.0489C66.4234 51.0489 66.4308 51.0356 67.2757 48.208H68.0155C68.8545 51.0356 68.8619 51.0489 68.8619 51.0489L69.6786 48.208H70.5279L69.3442 51.916H68.486L67.6515 49.2615Z"
          fill="#0B3763"
        />
        <path
          d="M72.4025 48.1177C73.5241 48.1177 74.1855 48.8915 74.1855 50.056C74.1867 50.1202 74.1842 50.1844 74.1781 50.2483H71.386C71.4008 50.9082 71.8773 51.3344 72.5372 51.3344C72.7371 51.3392 72.9351 51.2945 73.1136 51.2041C73.292 51.1138 73.4452 50.9806 73.5596 50.8165L74.0715 51.1997C73.9069 51.4582 73.6773 51.669 73.4057 51.811C73.1342 51.953 72.8301 52.0213 72.5238 52.0091C71.3653 52.0091 70.6003 51.1997 70.6003 50.0782C70.5989 48.927 71.386 48.1177 72.4025 48.1177ZM73.3968 49.7157C73.3984 49.5873 73.3737 49.4599 73.3242 49.3414C73.2747 49.2229 73.2014 49.1158 73.109 49.0267C73.0165 48.9376 72.9068 48.8683 72.7866 48.8232C72.6664 48.7781 72.5382 48.758 72.4099 48.7643C72.1522 48.7595 71.903 48.8568 71.7167 49.0351C71.5304 49.2133 71.4222 49.458 71.4156 49.7157H73.3968Z"
          fill="#0B3763"
        />
        <path
          d="M76.5707 48.8991C75.7613 48.8991 75.526 49.5175 75.526 50.3195V51.916H74.7522V48.217H75.5127V48.7999C75.5999 48.5935 75.7477 48.4184 75.9367 48.2979C76.1256 48.1774 76.3467 48.1172 76.5707 48.1252V48.8991Z"
          fill="#0B3763"
        />
        <path
          d="M77.3681 46.8115C77.4634 46.8118 77.5565 46.8404 77.6357 46.8936C77.7148 46.9467 77.7764 47.0222 77.8127 47.1103C77.8489 47.1985 77.8583 47.2954 77.8395 47.3889C77.8207 47.4824 77.7747 47.5682 77.7072 47.6355C77.6396 47.7028 77.5537 47.7486 77.4602 47.7671C77.3667 47.7856 77.2697 47.776 77.1817 47.7394C77.0936 47.7029 77.0184 47.641 76.9655 47.5617C76.9125 47.4824 76.8843 47.3892 76.8843 47.2939C76.8839 47.2303 76.8961 47.1673 76.9203 47.1085C76.9445 47.0497 76.9802 46.9963 77.0252 46.9514C77.0702 46.9065 77.1237 46.871 77.1826 46.847C77.2415 46.823 77.3045 46.811 77.3681 46.8115ZM76.9849 48.2172H77.7587V51.9163H76.9849V48.2172Z"
          fill="#0B3763"
        />
        <path
          d="M81.6412 49.6876V51.9159H80.8733V49.6225C80.8733 49.1105 80.5537 48.8116 80.1335 48.8116C79.6437 48.8116 79.3241 49.1741 79.3241 49.6787V51.9159H78.5503V48.2168H79.3093V48.7007C79.4188 48.5226 79.5726 48.376 79.7557 48.2751C79.9388 48.1742 80.1449 48.1225 80.354 48.1251C81.3068 48.1177 81.6412 48.8767 81.6412 49.6876Z"
          fill="#0B3763"
        />
        <path
          d="M82.8989 52.329C83.2324 52.5107 83.6052 52.6082 83.985 52.6131C84.6035 52.6131 85.1494 52.3868 85.1494 51.6839V51.4353C85.0122 51.6185 84.8331 51.7661 84.6272 51.866C84.4213 51.9659 84.1945 52.0151 83.9657 52.0094C82.9004 52.0094 82.1902 51.2075 82.1902 50.0711C82.1902 48.9348 82.93 48.118 83.9791 48.118C84.213 48.113 84.4447 48.1641 84.6547 48.2671C84.8648 48.37 85.0472 48.5219 85.1864 48.7099V48.2172H85.9262V51.428C85.9262 52.8203 85.0814 53.2819 84.0027 53.2819C83.5329 53.2762 83.0718 53.1542 82.6607 52.9268L82.8989 52.329ZM84.0634 48.8075C83.4242 48.8075 82.9625 49.318 82.9625 50.0637C82.9625 50.8095 83.4375 51.3214 84.0708 51.3214C84.7381 51.3214 85.1716 50.7962 85.1716 50.0637C85.1761 49.3032 84.7026 48.809 84.0634 48.809V48.8075Z"
          fill="#0B3763"
        />
        <path
          d="M88.6015 47.4355H89.368V48.2168H90.1211V48.856H89.368V50.5827C89.368 51.1583 89.5884 51.2855 90.0013 51.2855C90.0649 51.2855 90.1492 51.2781 90.1492 51.2781V51.9454C90.0247 51.9674 89.8983 51.9774 89.7719 51.975C88.8693 51.975 88.6001 51.3714 88.6001 50.4614V48.8486H88.1887V48.2168H88.6001L88.6015 47.4355Z"
          fill="#0B3763"
        />
        <path
          d="M93.7729 49.6877V51.916H93.005V49.6226C93.005 49.1107 92.6854 48.8118 92.2652 48.8118C91.7754 48.8118 91.4484 49.1743 91.4484 49.6788V51.916H90.6746V46.7817H91.4484V48.6934C91.556 48.5156 91.7082 48.369 91.8898 48.2681C92.0715 48.1671 92.2763 48.1154 92.4841 48.1178C93.4385 48.1178 93.7729 48.8769 93.7729 49.6877Z"
          fill="#0B3763"
        />
        <path
          d="M96.1254 48.1177C97.2484 48.1177 97.9009 48.8915 97.9009 50.056C97.9046 50.12 97.9046 50.1843 97.9009 50.2483H95.1119C95.1252 50.9082 95.6016 51.3344 96.2615 51.3344C96.4617 51.3395 96.66 51.2949 96.8387 51.2045C97.0174 51.1141 97.1709 50.9808 97.2854 50.8165L97.7959 51.1997C97.6312 51.4582 97.4017 51.669 97.1301 51.811C96.8585 51.953 96.5544 52.0213 96.2482 52.0091C95.0897 52.0091 94.3247 51.1997 94.3247 50.0782C94.3232 48.927 95.1119 48.1177 96.1254 48.1177ZM97.1197 49.7157C97.1212 49.5873 97.0965 49.4599 97.047 49.3414C96.9975 49.2229 96.9243 49.1158 96.8318 49.0267C96.7394 48.9376 96.6297 48.8683 96.5095 48.8232C96.3892 48.7781 96.261 48.758 96.1328 48.7643C95.875 48.7595 95.6259 48.8568 95.4396 49.0351C95.2533 49.2133 95.1451 49.458 95.1385 49.7157H97.1197Z"
          fill="#0B3763"
        />
        <path
          d="M100.403 46.9458H102.853V47.656H101.19V49.1046H102.648V49.8089H101.19V51.9159H100.403V46.9458Z"
          fill="#0B3763"
        />
        <path
          d="M103.263 50.4362V48.2168H104.03V50.5117C104.03 51.0222 104.349 51.3211 104.769 51.3211C105.261 51.3211 105.58 50.9659 105.58 50.454V48.2168H106.354V51.9159H105.595V51.4335C105.488 51.6117 105.336 51.7586 105.154 51.8594C104.972 51.9602 104.767 52.0113 104.559 52.0076C103.598 52.0091 103.263 51.25 103.263 50.4362Z"
          fill="#0B3763"
        />
        <path
          d="M107.223 47.4355H107.989V48.2168H108.742V48.856H107.989V50.5827C107.989 51.1583 108.21 51.2855 108.622 51.2855C108.686 51.2855 108.77 51.2781 108.77 51.2781V51.9455C108.646 51.9675 108.52 51.9774 108.394 51.975C107.492 51.975 107.223 51.3714 107.223 50.4614V48.8486H106.811V48.2168H107.223V47.4355Z"
          fill="#0B3763"
        />
        <path
          d="M109.188 50.4362V48.2168H109.954V50.5117C109.954 51.0222 110.274 51.321 110.694 51.321C111.184 51.321 111.503 50.9659 111.503 50.454V48.2168H112.278V51.9159H111.518V51.4335C111.409 51.6122 111.256 51.7593 111.072 51.86C110.889 51.9608 110.682 52.0116 110.473 52.0076C109.52 52.0091 109.188 51.25 109.188 50.4362Z"
          fill="#0B3763"
        />
        <path
          d="M114.878 48.8991C114.069 48.8991 113.834 49.5175 113.834 50.3195V51.916H113.06V48.217H113.82V48.7999C113.907 48.5935 114.055 48.4184 114.244 48.2979C114.433 48.1774 114.654 48.1172 114.878 48.1252V48.8991Z"
          fill="#0B3763"
        />
        <path
          d="M116.853 48.1177C117.975 48.1177 118.629 48.8915 118.629 50.056C118.633 50.1201 118.633 50.1843 118.629 50.2483H115.837C115.852 50.9083 116.328 51.3344 116.988 51.3344C117.188 51.3393 117.386 51.2945 117.565 51.2041C117.743 51.1138 117.896 50.9806 118.011 50.8165L118.522 51.1997C118.358 51.4585 118.128 51.6694 117.856 51.8114C117.584 51.9535 117.28 52.0216 116.973 52.0091C115.816 52.0091 115.05 51.1997 115.05 50.0782C115.048 48.927 115.837 48.1177 116.853 48.1177ZM117.848 49.7157C117.849 49.5873 117.825 49.4599 117.775 49.3414C117.726 49.2229 117.652 49.1158 117.56 49.0267C117.467 48.9376 117.358 48.8683 117.238 48.8232C117.117 48.7781 116.989 48.758 116.861 48.7643C116.603 48.7595 116.354 48.8569 116.168 49.0351C115.981 49.2133 115.873 49.458 115.867 49.7157H117.848Z"
          fill="#0B3763"
        />
        <path
          d="M122.776 48.1179C123.92 48.1179 124.744 48.9494 124.744 50.0636C124.75 50.3225 124.703 50.5798 124.605 50.8199C124.508 51.0599 124.363 51.2777 124.179 51.4598C123.995 51.6418 123.775 51.7845 123.534 51.879C123.293 51.9734 123.035 52.0178 122.776 52.0093C121.64 52.0093 120.816 51.1925 120.816 50.071C120.81 49.8122 120.857 49.555 120.953 49.3149C121.05 49.0748 121.194 48.8568 121.377 48.6741C121.561 48.4915 121.779 48.348 122.02 48.2524C122.26 48.1567 122.518 48.111 122.776 48.1179ZM122.776 51.3213C123.466 51.3213 123.969 50.7886 123.969 50.0636C123.969 49.3386 123.466 48.8074 122.776 48.8074C122.087 48.8074 121.593 49.346 121.593 50.071C121.593 50.796 122.087 51.3242 122.776 51.3242V51.3213Z"
          fill="#0B3763"
        />
        <path
          d="M125.394 48.8486H124.989V48.2168H125.394V48.0541C125.394 47.1086 125.998 46.7964 126.658 46.7964C126.75 46.7966 126.843 46.8015 126.936 46.8112V47.4711C126.874 47.4632 126.813 47.4588 126.751 47.4578C126.373 47.4578 126.168 47.6487 126.168 48.0674V48.2153H126.885V48.8545H126.162V51.9159H125.388L125.394 48.8486Z"
          fill="#0B3763"
        />
        <path
          d="M129.321 46.9458H131.784V47.656H130.115V49.041H131.564V49.7438H130.115V51.2071H131.855V51.9159H129.327L129.321 46.9458Z"
          fill="#0B3763"
        />
        <path
          d="M135.587 49.6875V51.9159H134.821V49.6224C134.821 49.1105 134.501 48.8116 134.081 48.8116C133.591 48.8116 133.271 49.1741 133.271 49.6787V51.9159H132.498V48.2168H133.258V48.7006C133.366 48.5236 133.519 48.3775 133.7 48.2767C133.882 48.1758 134.086 48.1236 134.294 48.1251C135.253 48.1177 135.587 48.8767 135.587 49.6875Z"
          fill="#0B3763"
        />
        <path
          d="M137.943 48.1177C139.064 48.1177 139.726 48.8915 139.726 50.056C139.727 50.1202 139.724 50.1844 139.718 50.2483H136.926C136.941 50.9082 137.416 51.3344 138.077 51.3344C138.277 51.3393 138.475 51.2945 138.654 51.2041C138.832 51.1138 138.985 50.9806 139.1 50.8165L139.612 51.1997C139.447 51.4582 139.217 51.669 138.945 51.811C138.673 51.953 138.369 52.0213 138.062 52.0091C136.905 52.0091 136.139 51.1997 136.139 50.0782C136.137 48.927 136.926 48.1177 137.943 48.1177ZM138.937 49.7157C138.938 49.5872 138.913 49.4598 138.864 49.3413C138.814 49.2228 138.741 49.1157 138.648 49.0266C138.556 48.9375 138.446 48.8683 138.325 48.8232C138.205 48.7781 138.077 48.758 137.949 48.7643C137.691 48.7599 137.442 48.8574 137.256 49.0355C137.07 49.2137 136.961 49.4581 136.954 49.7157H138.937Z"
          fill="#0B3763"
        />
        <path
          d="M142.111 48.8991C141.3 48.8991 141.066 49.5176 141.066 50.3195V51.916H140.292V48.217H141.051V48.7999C141.139 48.5934 141.287 48.4182 141.476 48.2978C141.665 48.1773 141.887 48.1171 142.111 48.1252V48.8991Z"
          fill="#0B3763"
        />
        <path
          d="M142.996 52.329C143.33 52.5108 143.703 52.6083 144.083 52.6131C144.7 52.6131 145.248 52.3867 145.248 51.6839V51.4353C145.11 51.6182 144.931 51.7658 144.725 51.8656C144.519 51.9655 144.293 52.0147 144.064 52.0094C142.999 52.0094 142.288 51.2074 142.288 50.0711C142.288 48.9347 143.028 48.118 144.079 48.118C144.313 48.1134 144.544 48.1647 144.754 48.2677C144.964 48.3706 145.146 48.5222 145.286 48.7098V48.2171H146.017V51.4279C146.017 52.8202 145.172 53.2818 144.093 53.2818C143.624 53.276 143.163 53.154 142.751 52.9267L142.996 52.329ZM144.16 48.8075C143.521 48.8075 143.059 49.3179 143.059 50.0637C143.059 50.8094 143.536 51.3213 144.167 51.3213C144.836 51.3213 145.268 50.7961 145.268 50.0637C145.268 49.3031 144.805 48.8089 144.16 48.8089V48.8075Z"
          fill="#0B3763"
        />
        <path
          d="M147.321 53.1883L147.846 51.8567L146.371 48.2168H147.202L148.204 50.9156L149.191 48.2168H150L148.06 53.1883H147.321Z"
          fill="#0B3763"
        />
        <defs>
          <radialGradient
            id="paint0_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(5.66127 57.8789) rotate(5.91092) scale(16.9426 16.9561)"
          >
            <stop stopColor="white" />
            <stop offset="0.26" stopColor="#FCFEFE" />
            <stop offset="0.48" stopColor="#F2F9FB" />
            <stop offset="0.67" stopColor="#E1F2F6" />
            <stop offset="0.85" stopColor="#C9E7EF" />
            <stop offset="1" stopColor="#B0DCE7" />
          </radialGradient>
          <radialGradient
            id="paint1_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(715947 566104) scale(19019.4 16033.5)"
          >
            <stop stopColor="white" />
            <stop offset="0.26" stopColor="#FCFEFE" />
            <stop offset="0.48" stopColor="#F2F9FB" />
            <stop offset="0.67" stopColor="#E1F2F6" />
            <stop offset="0.85" stopColor="#C9E7EF" />
            <stop offset="1" stopColor="#B0DCE7" />
          </radialGradient>
          <radialGradient
            id="paint2_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(45.9349 -11.4871) rotate(-174.089) scale(26.9224 26.9438)"
          >
            <stop stopColor="#86CCD2" />
            <stop offset="1" stopColor="#2A376C" />
          </radialGradient>
          <radialGradient
            id="paint3_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(124314 257002) rotate(5.91092) scale(4327.21 7409.37)"
          >
            <stop stopColor="#86CCD2" />
            <stop offset="1" stopColor="#2A376C" />
          </radialGradient>
          <radialGradient
            id="paint4_radial"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(29.4717 15.5997) scale(35.8009 35.83)"
          >
            <stop offset="0.01" stopColor="#EAF5F5" />
            <stop offset="0.5" stopColor="#00B0B2" />
            <stop offset="0.75" stopColor="#2D779E" />
            <stop offset="1" stopColor="#063266" />
          </radialGradient>
        </defs>
      </svg>
    )
  }

  /** Render Component */
  const renderContent = () => {
    return (
      <Logo
        logo={{
          name: 'SPgroup',
          link: 'https://www.spgroup.com.sg',
          source: logo.source === undefined ? spgroupSVG() : logo.source,
          target: logo.target === undefined ? '_blank' : logo.target,
          className: className,
          style: style,
        }}
      />
    )
  }
  return <div className={_SPgroup}>{renderContent()}</div>
}

/** Required and Type Checks */
SPgroup.propTypes = {
  className: PropTypes.string,
  style: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  logo: PropTypes.shape({
    source: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(SVGElement),
    ]),
    target: PropTypes.string,
  }),
}

/** Export */
export default SPgroup
