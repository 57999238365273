import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useScreenSize } from '../../ScreenSize'
import ChartLegends from '../ChartLegends'
import Typo from '../../Typo'
import Portal from '../../Portal'
import { useChart } from '../Chart'

const Marker = ({ label, setMarker, style: styleProp, markerRef }) => {
  const [style, setStyle] = React.useState(styleProp)

  React.useEffect(() => {
    setStyle(styleProp)
  }, [styleProp])

  React.useEffect(() => {
    if (!markerRef.current) {
      return
    }
    const markerRect = markerRef.current.getBoundingClientRect()
    if (markerRect.right > window.innerWidth - 5) {
      setStyle({
        right: 10,
        left: 'auto',
      })
    } else if (markerRect.left < 5) {
      setStyle({
        left: 10,
        right: 'auto',
      })
    }
  }, [markerRef, style.left, style.right])

  return (
    <Portal>
      <div
        className="cui-chart-doughnut__marker"
        style={{ ...styleProp, ...style }}
        ref={markerRef}
        onMouseLeave={() => {
          setMarker(null)
        }}
      >
        {label}
      </div>
    </Portal>
  )
}

const ChartDoughnut = ({
  options,
  fields,
  showLegend,
  doughnutWidth,
  className,
  style,
  numberResolver,
}) => {
  const chartRef = React.useRef()
  const markerRef = React.useRef()

  const screenSize = useScreenSize()
  const { chartData } = useChart()

  const [doughnutSize, setDoughnutSize] = React.useState(0)
  const [marker, setMarker] = React.useState(null)

  fields = fields || Object.keys(chartData)
  const total = fields.reduce((sum, field) => sum + chartData[field], 0)

  React.useEffect(() => {
    if (doughnutWidth) {
      setDoughnutSize(doughnutWidth)
      return
    }
    if (!chartRef.current) {
      return
    }
    const rect = chartRef.current.getBoundingClientRect()
    setDoughnutSize(rect.width)
  }, [screenSize, doughnutWidth])

  const renderDoughnut = () => {
    let curPoint = 0
    return (
      <svg width={doughnutSize} height={doughnutSize}>
        {fields.map(field => {
          if (doughnutSize < 1) {
            return null
          }
          const ratio = chartData[field] / total
          const strokeWidth = options.width || 16
          const radius = doughnutSize / 2 - strokeWidth / 2
          const perimeter = 2 * Math.PI * radius
          const strokeDasharray = perimeter / 4 - curPoint

          const cx = doughnutSize / 2
          const cy = doughnutSize / 2
          const radians =
            ((curPoint + (ratio * perimeter) / 2) / perimeter - 1 / 4) *
            Math.PI *
            2
          const x = Math.cos(radians) * radius + cx
          const y = Math.sin(radians) * radius + cy

          curPoint += ratio * perimeter

          return (
            <circle
              key={field}
              className={classnames('cui-chart-doughnut__circle', {})}
              cx={cx}
              cy={cy}
              r={radius}
              fill="transparent"
              strokeWidth={strokeWidth}
              strokeDasharray={`${ratio * perimeter} ${
                (1 - ratio) * perimeter
              }`}
              strokeDashoffset={strokeDasharray}
              stroke={options.color[field]}
              onMouseEnter={() => {
                setMarker({
                  field,
                  x,
                  y,
                })
              }}
              onMouseLeave={e => {
                if (e.relatedTarget !== markerRef.current) {
                  setMarker(null)
                }
              }}
            />
          )
        })}
      </svg>
    )
  }

  const renderTotal = () => {
    return (
      <div className="cui-chart-doughnut__total">
        <Typo variant="h4">{numberResolver(total)}</Typo>
        <Typo variant="body1">{options.unit}</Typo>
      </div>
    )
  }

  const renderMarker = () => {
    if (!marker) {
      return null
    }
    const { field, x, y } = marker

    const chartRect = chartRef.current.getBoundingClientRect()

    const middle = doughnutSize / 2
    const style = {
      top: y + chartRect.y,
    }
    if (x > middle) {
      style.left = x + chartRect.x
    } else {
      style.right = doughnutSize - x + (window.innerWidth - chartRect.right)
    }

    return (
      <Marker
        label={`${numberResolver(chartData[field])} ${options.label[field]}`}
        setMarker={setMarker}
        style={style}
        markerRef={markerRef}
      />
    )
  }

  return (
    <div className={classnames('cui-chart-doughnut', className)} style={style}>
      <div
        className="cui-chart-doughnut__svg-wrapper"
        ref={chartRef}
        style={{ width: doughnutWidth }}
      >
        {renderDoughnut()}
        {renderTotal()}
        {renderMarker()}
      </div>
      {showLegend && (
        <ChartLegends
          items={fields.map(field => ({
            color: options.color[field],
            field: 'circle',
            value: options.label[field],
          }))}
        />
      )}
    </div>
  )
}

ChartDoughnut.defaultProps = {
  options: {},
  numberResolver: number => number,
}

ChartDoughnut.propTypes = {
  options: PropTypes.shape({
    width: PropTypes.number,
    color: PropTypes.object,
    label: PropTypes.object,
    unit: PropTypes.string,
  }),
  fields: PropTypes.array,
  showLegend: PropTypes.bool,
  doughnutWidth: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
  numberResolver: PropTypes.func,
}

export default ChartDoughnut
