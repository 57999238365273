import React from 'react'

function CubeIcon() {
  return (
    <g className="icon-cube">
      <mask
        id="icon-cube-mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="2"
        y="2"
        width="20"
        height="20"
      >
        <path d="M21 7.58128C21 7.38119 20.8868 7.19827 20.7078 7.10914L12.8619 3.20254C12.3222 2.93379 11.6778 2.93379 11.1381 3.20254L3.29224 7.10914C3.11316 7.19827 3 7.38119 3 7.58128V15.9842C3 16.4541 3.26134 16.8763 3.68198 17.0857L11.1381 20.7982C11.4045 20.9309 11.7025 21.0009 12 21.0009C12.2975 21.0009 12.5955 20.9309 12.8619 20.7982L20.3179 17.0857C20.7387 16.8763 21 16.4541 21 15.9842V7.58128ZM11.6082 4.14668C11.8536 4.02446 12.1464 4.02446 12.3918 4.14668L19.2896 7.58128L12.3917 11.0157C12.1464 11.138 11.8536 11.138 11.6082 11.0157L4.71043 7.58128L11.6082 4.14668ZM4.15205 16.1416C4.09204 16.1116 4.05469 16.0513 4.05469 15.9842V8.43396L4.28101 8.5456L11.1381 11.9599C11.2435 12.0123 11.3528 12.0542 11.4646 12.0862L11.4727 12.0902V19.7865L4.15205 16.1416ZM19.9453 15.9842C19.9453 16.0513 19.908 16.1116 19.8479 16.1416L12.5273 19.7865V12.0902L12.5356 12.0861C12.6474 12.0542 12.7565 12.0123 12.8619 11.9599L19.719 8.5456L19.9453 8.43409V15.9842Z" />
        <path
          d="M21 7.58128C21 7.38119 20.8868 7.19827 20.7078 7.10914L12.8619 3.20254C12.3222 2.93379 11.6778 2.93379 11.1381 3.20254L3.29224 7.10914C3.11316 7.19827 3 7.38119 3 7.58128V15.9842C3 16.4541 3.26134 16.8763 3.68198 17.0857L11.1381 20.7982C11.4045 20.9309 11.7025 21.0009 12 21.0009C12.2975 21.0009 12.5955 20.9309 12.8619 20.7982L20.3179 17.0857C20.7387 16.8763 21 16.4541 21 15.9842V7.58128ZM21 7.58128V7.58128ZM11.6082 4.14668C11.8536 4.02446 12.1464 4.02446 12.3918 4.14668L19.2896 7.58128L12.3917 11.0157C12.1464 11.138 11.8536 11.138 11.6082 11.0157L4.71043 7.58128L11.6082 4.14668ZM4.15205 16.1416C4.09204 16.1116 4.05469 16.0513 4.05469 15.9842V8.43396L4.28101 8.5456L11.1381 11.9599C11.2435 12.0123 11.3528 12.0542 11.4646 12.0862L11.4727 12.0902V19.7865L4.15205 16.1416ZM19.9453 15.9842C19.9453 16.0513 19.908 16.1116 19.8479 16.1416L12.5273 19.7865V12.0902L12.5356 12.0861C12.6474 12.0542 12.7565 12.0123 12.8619 11.9599L19.719 8.5456L19.9453 8.43409V15.9842Z"
          stroke="#818A91"
          strokeWidth="0.8"
        />
      </mask>
      <g mask="url(#icon-cube-mask0)">
        <rect width="24" height="24" />
      </g>
    </g>
  )
}

export default CubeIcon
