import React from 'react'
import PropTypes from 'prop-types'

import { useFormikContext } from 'formik'

function FieldError(props) {
  const { errors } = useFormikContext()

  if (props.name !== undefined) {
    return (
      <span
        id={`error-${props.name}`}
        className="lm--field-error cui-field_error"
      >
        {errors[props.name]}
      </span>
    )
  } else {
    return (
      <div
        id={`error-${props.name}`}
        className="lm--field-error cui-field_error"
      >
        {props.children}
      </div>
    )
  }
}

FieldError.propTypes = {
  name: PropTypes.string,
}

export default FieldError
