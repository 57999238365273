import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import LayoutItem from './LayoutItem'

const validTypes = [LayoutItem]
export const isValidType = (type, checkWith) => {
  if (checkWith) {
    return type === checkWith
  }
  return validTypes.some(t => t === type)
}

function Layout({
  children,
  cols,
  style,
  id,
  noGaps,
  autoRows,
  className,
  align,
  minRowHeight,
  gap,
}) {
  const _Layout = classnames('cui-layout', className, {
    [`align-${align}`]: align,
    'with-gaps': !noGaps,
    'with-rows': !autoRows,
    'auto-rows': autoRows,
    'min-height': minRowHeight,
  })
  const styles = {
    ...style,
    '--cuiCols': cols,
    '--cuiRowHeight': minRowHeight,
    '--cuiLayoutGap': gap,
  }

  const renderLayout = () => {
    return React.Children.map(children, child => {
      if (!isValidType(child.type)) {
        return null
      }

      return React.cloneElement(child, { ...child.props })
    })
  }

  return (
    <div id={id} className={_Layout} style={styles}>
      {renderLayout()}
    </div>
  )
}

Layout.propTypes = {
  cols: PropTypes.number,
  style: PropTypes.object,
  id: PropTypes.string,
  className: PropTypes.string,
  noGaps: PropTypes.bool,
  align: PropTypes.string,
  autoRows: PropTypes.bool,
  minRowHeight: PropTypes.string,
  children: (props, propName, componentName) => {
    const prop = props[propName]
    let error = null
    React.Children.forEach(prop, child => {
      if (!isValidType(child.type)) {
        console.log(child.type)
        error = new Error(
          `${componentName} children should be of type 'CuiLayoutItem'`
        )
      }
    })
    return error
  },
  gap: PropTypes.string,
}

Layout.defaultProps = {
  cols: 12,
  style: {},
  noGaps: false,
  autoRows: true,
  align: 'center',
  minRowHeight: '2rem',
  gap: '1.5rem',
}

Layout.Item = LayoutItem

export default Layout
