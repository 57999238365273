import React, { useContext } from 'react'

import * as Config from './config'

export const ThemeConfig = {
  ...Config,
}

export const defaultTheme = {
  themeId: 'cui-light-theme',
  type: 'light',
  typography: {
    h1: { tag: 'h1', size: '6rem' },
    h2: { tag: 'h2', size: '3.75rem' },
    h3: { tag: 'h3', size: '3rem' },
    h4: { tag: 'h4', size: '2rem' },
    h5: { tag: 'h5', size: '1.5rem' },
    h6: { tag: 'h6', size: '1.25rem' },
    subtitle1: { tag: 'div', size: '1rem' },
    subtitle2: { tag: 'div', size: '0.875rem' },
    body1: { tag: 'div', size: '1rem' },
    body2: { tag: 'div', size: '0.875rem' },
    button: { tag: 'div', size: '0.875rem' },
    caption: { tag: 'div', size: '0.75rem' },
    overline: { tag: 'div', size: '0.75rem' },
  },
  colors: {
    primary: {
      main: '#00B0B2',
      contrastText: '#fff',
      hover: '#158793',
      fade: 'rgba(0, 176, 178, 0.2)',
    },
    background: {
      body: '#f8fcff',
      level1: '#fff',
      level2: '#f3f6f7',
      level3: '#e7ecef',
      disabled: '#e7ecef',
      overlayLoader: 'rgba(248, 252, 255, 0.5)',
    },
    text: {
      primary: '#4F5A60',
      secondary: '#818A91',
      disabled: '#bfc9cf',
    },
    disabled: {
      button: {
        background: '#e7ecef',
        text: '#bfc9cf',
      },
    },
    grey: {
      100: '#f3f6f7',
      200: '#e7ecef',
      300: '#cfd6dd',
      400: '#bfc9cf',
    },
    error: {
      main: '#e44444',
      hover: '#b73838',
      contrastText: '#fff',
      fade: 'rgb(228, 68, 68, 0.2)',
    },
    success: {
      main: '#00B0B2',
      contrastText: '#fff',
    },
    warning: {
      main: '#c27f52',
      fade: 'rgb(194, 127, 82, 0.2)',
    },
    divider: '#e7ecef',
    boxShadow: '0px 0px 8px rgba(206, 213, 220, 0.65)',
    input: {
      border: '#ced5dc',
      background: '#fff',
      disabled: '#f3f6f7',
    },
    icon: '#818A91',
    tooltip: {
      background: '#f4f4f4',
      text: '#4F5A60',
      borderRadius: '10px',
    },
    modal: {
      background: '#fff',
    },
    tabs: {
      background: '#fff',
      itemBackground: '#e7ecef',
      text: '#818A91',
    },
    chart: {
      division: '#e7ecef',
      text: '#818b92',
    },
    datePicker: {
      itemHoverBackground: '#f0f8ff',
      disabled: '#DCE0E0',
    },
    shell: {
      color1: '#e7ecef',
      color2: '#f3f6f7',
    },
    menuList: {
      background: '#fff',
      itemHover: '#f3f6f7',
      divider: '#e7ecef',
    },
    header: {
      background: '#fff',
      divider: '#e7ecef',
      boxShadow: '0px 0px 8px rgba(206, 213, 220, 0.65)',
    },
    navTree: {
      background: '#fff',
      boxShadow: '0px 0px 8px rgba(206, 213, 220, 0.65)',
      divider: '#e7ecef',
    },
    card: {
      background: '#fff',
      boxShadow: '0px 0px 8px rgba(206, 213, 220, 0.65)',
      divider: '#e7ecef',
    },
    green: {
      main: '#00B0B2',
      fade: 'rgba(0, 176, 178, 0.2)',
    },
  },
}

const ThemeContext = React.createContext()

function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item)
}

function mergeDeep(source, target) {
  if (!isObject(source) || !isObject(target)) {
    return source
  }
  for (const key in source) {
    if (target[key]) {
      if (isObject(source[key]) && isObject(target[key])) {
        mergeDeep(source[key], target[key])
      } else {
        source[key] = target[key]
      }
    }
  }
  return source
}

export const ThemeProvider = ({ children, theme: themeProp }) => {
  const [theme, setTheme] = React.useState(themeProp)

  React.useEffect(() => {
    setTheme(themeProp)
  }, [themeProp])

  const mergedTheme = mergeDeep(JSON.parse(JSON.stringify(defaultTheme)), theme)

  return (
    <ThemeContext.Provider
      value={{
        ...mergedTheme,
        changeTheme: theme => {
          setTheme(theme)
        },
      }}
    >
      {children}
    </ThemeContext.Provider>
  )
}

export const useTheme = () => useContext(ThemeContext) || defaultTheme

export const withTheme = Component => {
  return props => {
    const theme = useTheme()
    return <Component theme={theme} {...props} />
  }
}
