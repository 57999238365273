import React from 'react'
import PropTypes from 'prop-types'

const ToastTitle = ({ children }) => {
  return <div className="cui-toast__title">{children}</div>
}

ToastTitle.propTypes = {
  children: PropTypes.node,
}

export default ToastTitle
