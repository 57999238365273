import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

const CardContent = ({ children, className, id, direction }) => {
  const _CardContent = classnames('cui-card__content', `direction-${direction}`, className)
  return (
    <div className={_CardContent} id={id}>
      {children}
    </div>
  )
}

CardContent.propTypes = {
  // CardTitle children
  children: PropTypes.any([PropTypes.object, PropTypes.string]),
  className: PropTypes.string,
  id: PropTypes.string,
}

CardContent.defaultProps = {
  direction: 'col',
}

export default CardContent
